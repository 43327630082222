import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import {
  VerticalBarSeries, XAxis, Crosshair, HorizontalGridLines,
  VerticalGridLines, YAxis, LabelSeries, FlexibleWidthXYPlot,
} from 'react-vis';
import PropTypes from 'prop-types';
import { getQuizResults } from '../../actions';
import Loader from '../loading';
import CustomAxisLabel from '../testOverview/customAxisLabel';
import '../../styles/instituteOnBoarding.css';
import '../../static/tools.css';

const BAR_CHART_COLOR = ['#FF5F5F', '#FFC352', '#01D28E'];

class QuizResult extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      showLoader: true,
      crosshairValues: [],
    };
    this.onMouseOverUpdateGraph = this.onMouseOverUpdateGraph.bind(this);
    this.onMouseLeaveUpdateGraph = this.onMouseLeaveUpdateGraph.bind(this);
  }

  componentDidMount() {
    const { id } = this.props;
    const { classroom } = this.props;
    this.props.getQuizResults({ id, classroom_id: classroom.id }).then(() => this.setState({ showLoader: false }));
  }

  onMouseLeaveUpdateGraph() {
    this.setState({ crosshairValues: [] });
  }

  onMouseOverUpdateGraph(value, { index }) {
    this.setState({ crosshairValues: [{ x: value.x, y: value.y }] });
  }

  renderOverallScoreDistribution(labelAngle, scoreData, crosshairValues) {
    return (
      <>
        <div style={{
          fontSize: 20, fontWeight: 'bold', padding: '0 30px', marginTop: 15,
        }}
        >
          Overall Score Distribution
        </div>
        <div style={{ paddingRight: '10%', paddingLeft: 15 }}>
          <div className="Graph-Card-View" style={{ padding: 30, marginTop: 10 }}>
            <FlexibleWidthXYPlot
              margin={{ top: 30, left: 80, bottom: 70 }}
              onMouseLeave={this.onMouseLeaveUpdateGraph}
              xType="ordinal"
              height={300}
            >
              <XAxis title="" tickLabelAngle={labelAngle} style={{ ticks: { fontSize: 14, fontWeight: 'bold' } }} />
              <YAxis title="" style={{ ticks: { fontWeight: 'bold', fontSize: 14 } }} />
              <CustomAxisLabel title="No. of Students" style={{ fontSize: 12 }} />
              <CustomAxisLabel title="Score Range" style={{ fontSize: 12 }} innerHeight={240} xAxis />
              <VerticalBarSeries
                data={scoreData}
                barWidth={0.25}
                colorType="literal"
              />
              <HorizontalGridLines />
              <VerticalGridLines />
              <LabelSeries
                onNearestX={this.onMouseOverUpdateGraph}
                data={scoreData}
              />
              <Crosshair
                values={crosshairValues}
                titleFormat={(data) => ({ title: 'Marks', value: data[0].x })}
                itemsFormat={(data) => {
                  return [{ title: 'No of Students', value: `${data[0].y}` }];
                }}
              />
            </FlexibleWidthXYPlot>
          </div>
        </div>
      </>
    );
  }

  render() {
    const {
      quizResult, overallScore, labelAngle,
    } = this.props;
    const { crosshairValues, showLoader } = this.state;
    if (showLoader) {
      return <Loader />;
    }
    const maxValue = Math.max(...Object.values(quizResult));
    const scoreData = [];
    const barChartColor = [];
    let roundedValue = maxValue / 5;
    roundedValue = (roundedValue * 5) / 100;
    const incrementer = Math.ceil(Object.keys(quizResult).length / 3);
    const incrementerLength = Object.keys(quizResult).length % 3;
    let colorState = 0;
    for (let index1 = 0; index1 <= BAR_CHART_COLOR.length - 1; index1 += 1) {
      colorState = ((index1 === 1 && incrementerLength === 2)
      || (index1 === 2 && incrementerLength === 1)) ? incrementer + 1 : incrementer;
      colorState = incrementerLength === 0 ? colorState : colorState - 1;
      for (let index = 0; index < colorState; index += 1) {
        barChartColor.push(BAR_CHART_COLOR[index1]);
      }
    }
    for (let index = 20; index <= 100; index += 20) {
      let yvalue = quizResult[`score_${index}`];
      yvalue = yvalue && yvalue !== 'undefined' ? yvalue : 0;
      yvalue = (yvalue > roundedValue || yvalue === 0 || roundedValue < 1) ? yvalue : roundedValue;
      const data = {
        x: (index === 20) ? ` < ${index}%` : `${index - 20}% - ${index}%`,
        y: yvalue,
        color: barChartColor[index / 20],
      };
      scoreData.push(data);
    }
    if (!quizResult || Object.keys(quizResult).length <= 0) {
      return (
        <div style={{
          fontSize: 18, fontWeight: 'bold', padding: '0 30px', marginTop: 100, textAlign: 'center',
        }}
        >
          No scores to show
        </div>
      );
    }
    return (
      <div>
        <div style={{ display: 'flex' }}>
          <div style={{
            border: '2px solid #CDCFD6', borderRadius: 4, minWidth: '25%', margin: 15,
          }}
          >
            <div style={{
              fontSize: 60, color: '#44474A', fontWeight: 'bold', display: 'flex', justifyContent: 'center',
            }}
            >
              {quizResult.total_responses || 0}
            </div>
            <div style={{
              fontWeight: 'bold', display: 'flex', justifyContent: 'center', paddingBottom: 10,
            }}
            >
              No of responses
            </div>
          </div>
          <div style={{
            border: '2px solid #CDCFD6', borderRadius: 4, minWidth: '25%', margin: 15,
          }}
          >
            <div style={{
              fontSize: 60, color: '#3994AE', fontWeight: 'bold', display: 'flex', justifyContent: 'center',
            }}
            >
              {parseInt(overallScore, 10)}
              %
            </div>
            <div style={{
              fontWeight: 'bold', display: 'flex', justifyContent: 'center', paddingBottom: 10,
            }}
            >
              Average
            </div>
          </div>
          <div style={{
            border: '2px solid #CDCFD6', borderRadius: 4, minWidth: '25%', margin: 15,
          }}
          >
            <div style={{
              fontSize: 60, color: '#3994AE', fontWeight: 'bold', display: 'flex', justifyContent: 'center',
            }}
            >
              {quizResult.students_attended}
            </div>
            <div style={{
              fontWeight: 'bold', display: 'flex', justifyContent: 'center', paddingBottom: 10,
            }}
            >
              Student attended
            </div>
          </div>
        </div>
        <div />
        {/* { this.questionWisePerformance()} */}
        {this.renderOverallScoreDistribution(labelAngle, scoreData, crosshairValues)}
      </div>
    );
  }
}

QuizResult.propTypes = {
  labelAngle: PropTypes.number,
  quizResult: PropTypes.object,
  overallScore: PropTypes.number,
  classroom: PropTypes.object,
};

QuizResult.defaultProps = {
  labelAngle: 0,
  overallScore: 0,
  quizResult: {},
  classroom: { id: null },
};

const mapStateToProps = ({ quiz }) => ({
  quizResult: quiz.quizResult,
  totalQuestions: quiz.totalQuestions,
  questionScores: quiz.questionScores,
  overallScore: quiz.overallScore,
});

export default connect(mapStateToProps, {
  getQuizResults,
})(QuizResult);
