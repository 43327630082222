import React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { getDashboardDetails } from "../../actions";
import Loader from "../loading";

class ApplicantsDashboard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    this.props.getDashboardDetails();
  }

  renderCards() {
    const { newLeadsOverTime, newAdmissionsOverTime } = this.props;
    return (
      <div className="card-row">
        <div className="card">
          <div className="card-title">New Leads Over Time</div>
          <div className="card-content">{newLeadsOverTime.length}</div>
        </div>
        <div className="card">
          <div className="card-title">New Admissions Over Time</div>
          <div className="card-content">{newAdmissionsOverTime.length}</div>
        </div>
      </div>
    );
  }

  render() {
    if (this.props.isLoading) {
      return <Loader />;
    }
    return <div style={{ marginLeft: "20%" }}>{this.renderCards()}</div>;
  }
}

ApplicantsDashboard.propTypes = {
  leadsOverTime: PropTypes.array,
  admissionsOverTime: PropTypes.array,
};

ApplicantsDashboard.defaultProps = {
  leadsOverTime: [],
  admissionsOverTime: [],
};

const mapStateToProps = ({ admissionBoard }) => ({
  newLeadsOverTime: admissionBoard.newLeadsOverTime,
  newAdmissionsOverTime: admissionBoard.newAdmissionsOverTime,
  isLoading: admissionBoard.isLoading
});

export default connect(mapStateToProps, { getDashboardDetails })(
  ApplicantsDashboard
);
