import {
  GET_LIST,
  UPDATE_QUICK_POINTS,
  DELETE_QUICK_POINTS,
  ORDER_QUICK_POINTS,
  UPDATE_PAGE_NUMBER,
  CHECKPOINT_CHAPTER_LIST,
  UPDATE_LOADER,
} from '../actions';
import {
  genericError,
}
  from '../constants';

const initialState = {
  items: {
    ids: [],
    byId: {},
  },
  fetching: false,
  updating: false,
  error: null,
  page: 1,
  editId: null,
  testType: 0,
  selectedSubject: 0,
  selectedChapter: 0,
  selectedCheckpoint: 0,
  order: [],
  checkpointChapterMap: {},
  total: 0,
};

function generateList(response) {
  if (!response.success) {
    return { error: genericError };
  }
  if (response.data.quickpoints) {
    const items = {
      ids: [],
      byId: {},
    };
    items.ids = response.data.quickpoints.map((quickpoint) => {
      items.byId[quickpoint.id] = quickpoint;
      return quickpoint.id;
    });
    return { items };
  }
  return {};
}

function updateQuickpoints(oldQuickpoints, response) {
  if (!response.success) {
    return { error: genericError };
  }
  const { ids, byId } = { ...oldQuickpoints };
  byId[response.data.id] = response.data;
  if (response.data && oldQuickpoints.ids.indexOf(response.data.id) < 0) {
    ids.unshift(response.data.id);
  }
  return { items: { ids, byId } };
}

function removeQuickpoint({ items: oldQuickpoints }, quickpointId) {
  const oldRecordIndex = oldQuickpoints.ids.indexOf(quickpointId);
  if (oldRecordIndex > -1) {
    const ids = [...oldQuickpoints.ids];
    ids.splice(oldRecordIndex, 1);
    const { [quickpointId]: omit, ...byId } = oldQuickpoints.byId;
    return {
      items: { ids, byId },
      editId: 0,
    };
  }
  return {};
}

function createCheckpointDropdown(response, subjectId) {
  if (!response.success) {
    return { error: response.error || genericError };
  }
  if (response.data) {
    const { chapters, checkpoints } = response.data;
    const checkpointChapterMap = {};
    checkpoints.map(({ chapter_id: chapterId, name, id }) => {
      if (!checkpointChapterMap[chapterId]) {
        checkpointChapterMap[chapterId] = [];
      }
      checkpointChapterMap[chapterId].push({ value: id, label: name });
    });
    const chapterList = chapters.map(({ id, name }) => ({ value: id, label: name }));
    return { chapterList, checkpointChapterMap, selectedSubject: chapterList.length && subjectId };
  }
  return {};
}

export default function (state = initialState, action) {
  switch (action.type) {
    case GET_LIST: return {
      ...state,
      ...generateList(action.payload),
      ...action.additional,
      fetching: false,
    };
    case UPDATE_QUICK_POINTS: return {
      ...state,
      ...updateQuickpoints(state.items, action.payload),
      updating: false,
    };
    case DELETE_QUICK_POINTS: return {
      ...state,
      ...removeQuickpoint(state, action.payload),
      fetching: false,
      updating: false,
    };
    case UPDATE_LOADER: return {
      ...state,
      error: null,
      ...action.payload,
    };
    case UPDATE_PAGE_NUMBER: return {
      ...state,
      ...action.payload,
    };
    case CHECKPOINT_CHAPTER_LIST: return {
      ...state,
      ...createCheckpointDropdown(action.payload, action.subjectId),
      updating: false,
    };
    case ORDER_QUICK_POINTS: return {
      ...state,
      items: {
        ...state.items,
        ...action.payload,
      },
      updating: false,
    };
    default:
      return state;
  }
}

