import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { getQuizRankListData } from '../../actions';
import Loader from '../loading';

class QuizRankList extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      showLoader: true,
    };
  }

  componentDidMount() {
    const { id, classroom } = this.props;
    this.props.getQuizRankListData(id, classroom.id || 0).then(() => {
      this.setState({ showLoader: false });
    });
  }

  render() {
    const { showLoader } = this.state;
    const { rankListData, classroom, classrooms } = this.props;
    if (showLoader) {
      return <Loader />;
    }
    if (rankListData.length === 0) {
      return (
        <div style={{ textAlignLast: 'center', marginTop: 100, fontSize: 20 }}>No Scores To Display. No one attempted the Quiz </div>
      );
    }
    return (
      <table className="table table-bordered" style={{ textAlign: 'center', marginTop: 30 }}>
        <thead>
          <tr style={{ fontFamily: 'Nunito', fontWeight: '400' }}>
            <th rowSpan="2" style={{ verticalAlign: 'middle' }}> Rank</th>
            <th rowSpan="2" style={{ verticalAlign: 'middle' }}> Name </th>
            {!classroom.id && <th rowSpan="2" style={{ verticalAlign: 'middle' }}> Classroom </th>}
            <th rowSpan="2" style={{ verticalAlign: 'middle' }}> Marks Obtained </th>
          </tr>
        </thead>
        {rankListData.map((data) => {
          return (
            <tr>
              <td>{data.rank}</td>
              <td style={{ overflowWrap: 'anywhere' }}>
                {data.name}
              </td>
              {!classroom.id && (
                <td style={{ overflowWrap: 'anywhere' }}>
                  { data.classroom_id && classrooms[data.classroom_id.toString()]
                    ? classrooms[data.classroom_id.toString()].name : ''}
                </td>
              )}
              <td>{data.result || 0}</td>
            </tr>
          );
        })}
      </table>
    );
  }
}

QuizRankList.propTypes = {
  id: PropTypes.number,
  rankListData: PropTypes.array,
  updatedCount: PropTypes.number,
  classrooms: PropTypes.object,
  classroom: PropTypes.object,
};

QuizRankList.defaultProps = {
  id: 0,
  rankListData: [],
  classrooms: {},
  updatedCount: 0,
  classroom: { id: 0 },
};

const mapStateToProps = ({ quiz }) => ({
  rankListData: quiz.rankListData,
  classrooms: quiz.classrooms,
});

export default connect(mapStateToProps, {
  getQuizRankListData,
})(QuizRankList);
