import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import MathJax from 'react-mathjax2';
import PropTypes from 'prop-types';
import { ImageToText } from '.';
import blueCross from '../../static/images/blue-cross.svg';
import { MATHJAX_SCRIPT } from '../../constants';
import { asciiForMathJax } from '../../helpers/util';

const OPTION_TYPES = ['OPTION 1', 'OPTION 2', 'OPTION 3', 'OPTION 4'];

class QuizQuestionView extends PureComponent {
  constructor(props) {
    super(props);
    this.setText = this.setText.bind(this);
    this.renderTextArea = this.renderTextArea.bind(this);
    this.addImageOption = this.addImageOption.bind(this);
  }

  setText(text, type) {
    const { textChanges } = this.props;
    const textData = `${textChanges[type]}${text}`;
    this.props.handleChange(type, textData);
  }

  getData(optionNumber, type, textType, isCorrectOption) {
    const {
      imageChanges, textChanges, isNewImage,
    } = this.props;
    const ref = `${textChanges.id}-${type}`;
    const imageOptionType = `image_option_${optionNumber + 1}`;
    let image = imageChanges[imageOptionType];
    image = isNewImage[imageOptionType] ? URL.createObjectURL(image) : image && image.url ? image.url : '';
    if (image) {
      return (
        <div style={{ display: 'flex', marginBottom: 20 }}>
          <img
            alt={image}
            src={image}
            style={{
              height: '150px', marginLeft: '0', width: '350px', border: `5px solid ${isCorrectOption ? '#0080004A' : '#3994AE4D'}`,
            }}
          />
          <img
            role="presentation"
            onClick={() => { this.props.removeImage(optionNumber + 1); }}
            style={{
              margin: -8, cursor: 'pointer',
            }}
            alt=""
            height="15px"
            width="15px"
            src={blueCross}
          />
        </div>
      );
    }
    return (
      <textarea
        className="New-Input"
        onMouseOver={(event) => this.updateTextAreaSize(event)}
        onFocus={(event) => this.updateTextAreaSize(event)}
        onClick={(event) => this.updateTextAreaSize(event)}
        value={textChanges[type]}
        ref={ref}
        type="text"
        placeholder={textType}
        style={{
          fontSize: 16, width: optionNumber > -1 ? '90%' : '95%', lineHeight: '2em', minHeight: 'fit-content', overflow: 'hidden',
        }}
        onChange={(event) => {
          this.updateTextAreaSize(event);
          this.props.handleChange(type, event.target.value);
        }}
      />
    );
  }

  addImageOption(image, number) {
    const type = number > 0 ? `image_option_${number}` : 'supporting_picture';
    this.props.handleChangeOnImages(type, image);
    number > 0 && this.props.handleChange(`option_${number}`, '');
  }

  updateTextAreaSize(element) {
    element.target.style.height = '5px';
    element.target.style.height = `${element.target.scrollHeight}px`;
  }

  renderData(optionNumber, type, isCorrectOption, optionText) {
    const { imageChanges, isNewImage, textChanges } = this.props;
    const imageOptionType = `image_option_${optionNumber + 1}`;
    let image = imageChanges[imageOptionType];
    image = isNewImage[imageOptionType] ? URL.createObjectURL(image) : image && image.url ? image.url : '';
    if (image) {
      return (
        <img
          alt={image}
          src={image}
          style={{
            height: '150px', marginLeft: 20, marginBottom: 20, width: '350px', border: isCorrectOption && '5px solid #0080004A',
          }}
        />
      );
    }
    return (
      <div style={{
        marginBottom: type !== 'answer' && 20,
        marginRight: optionText && 40,
        marginLeft: 10,
        overflowWrap: 'anywhere',
        color: optionText && !isCorrectOption ? '#514d4dc2' : '#454545',
        fontWeight: isCorrectOption && 'bold',
        fontFamily: isCorrectOption && 'Nunito',
        display: 'flex',
      }}
      >
        &nbsp;&nbsp;
        <MathJax.Context
          input={asciiForMathJax()}
          onError={() => {
            MathJax.Hub && MathJax.Hub.Queue(
              MathJax.Hub.Typeset(),
            );
          }}
          script={MATHJAX_SCRIPT}
          options={{
            asciimath2jax: {
              useMathMLspacing: true,
              delimiters: [['$$', '$$']],
              preview: 'none',
            },
          }}
        >
          <MathJax.Text text={textChanges[type]} />
        </MathJax.Context>
      </div>
    );
  }

  renderTextArea(textType, optionNumber = -1) {
    const type = textType.toLowerCase().split(' ').join('_');
    const { textChanges, isEditable, hasTextExtractionAccess } = this.props;
    const optionText = optionNumber > -1 && `${optionNumber + 1})`;
    const isCorrectOption = optionText && optionNumber + 1 === parseInt(textChanges.answer, 10);
    if (!isEditable) {
      return (
        <div style={{ display: 'flex' }}>
          {optionNumber > -1 && (
          <div style={{ whiteSpace: 'nowrap' }}>
            {optionNumber + 1}
            .
          </div>
          )}
          {this.renderData(optionNumber, type, isCorrectOption, optionText)}
        </div>
      );
    }
    return (
      <div style={{ display: 'flex', alignItems: 'center' }}>
        { optionNumber > -1 && (
        <input
          type="checkbox"
          style={{ transform: 'scale(1.0)', marginRight: 15, cursor: 'pointer' }}
          onClick={() => this.props.handleChange('answer', optionNumber + 1)}
          checked={isCorrectOption ? 'checked' : ''}
        />
        )}
        { this.getData(optionNumber, type, textType, isCorrectOption)}
        <ImageToText
          option={type}
          hasTextExtractionAccess={hasTextExtractionAccess}
          number={optionNumber + 1}
          addImageOption={this.addImageOption}
          image={optionNumber > -1 && textChanges[`image_option_${optionNumber + 1}`]}
          isImageOption={optionNumber > -1}
          status="Text"
          setText={this.setText}
          clear=""
        />
      </div>
    );
  }

  renderSupportingPicture() {
    const {
      imageChanges, isNewImage, isEditable,
    } = this.props;
    let image = imageChanges.supporting_picture;
    image = isNewImage.supporting_picture ? URL.createObjectURL(image) : image && image.url ? image.url : '';
    if (!image) {
      return <div />;
    }
    if (isEditable) {
      return (
        <div style={{ display: 'flex', marginBottom: 20 }}>
          <img
            alt={image}
            src={image}
            style={{
              height: '150px', marginLeft: '0', width: '350px', border: '5px solid #3994AE4D',
            }}
          />
          <img
            role="presentation"
            onClick={() => { this.props.removeImage(0); }}
            style={{
              margin: -8, cursor: 'pointer',
            }}
            alt=""
            height="15px"
            width="15px"
            src={blueCross}
          />
        </div>
      );
    }
    return (
      <img
        alt={image}
        src={image}
        style={{
          height: '150px', marginLeft: 20, marginBottom: 30, width: '350px',
        }}
      />
    );
  }

  renderQuizQuestion(index, isEditable) {
    return (
      <>
        {index > 0 && (
        <div style={{ fontWeight: 'bold' }}>
          Question: &nbsp;
            {index}
        </div>
        )}
        {this.renderTextArea('QUESTION TEXT')}
        {this.renderSupportingPicture()}
        <div style={{ marginLeft: 25 }}>
          {OPTION_TYPES.map((type, i) => {
            return this.renderTextArea(type, i);
          })}
        </div>
      </>
    );
  }

  render() {
    const { index, isEditable } = this.props;
    return this.renderQuizQuestion(index, isEditable);
  }
}

QuizQuestionView.propTypes = {
  textChanges: PropTypes.object,
  hasTextExtractionAccess: PropTypes.bool,
  index: PropTypes.number,
  isEditable: PropTypes.bool,
  imageChanges: PropTypes.object,
  quizQuestion: PropTypes.object,
  isNewImage: PropTypes.object,
};

QuizQuestionView.defaultProps = {
  textChanges: {},
  index: 0,
  hasTextExtractionAccess: false,
  isEditable: false,
  quizQuestion: {},
  isNewImage: {},
  imageChanges: {},
};

const mapStateToProps = ({ quiz }) => ({
  hasTextExtractionAccess: quiz.hasTextExtractionAccess,
});

export default connect(mapStateToProps, {})(QuizQuestionView);