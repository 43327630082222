import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Loading from '../loading';
import NavBar from '../navbar';
import Footer from '../footer';
import SnackBar from '../snackBar';
import { googleAnalyticsPage } from '../../helpers/Analytics';

class Layout extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      displayHeight: '',
    };
    this.printDate = this.printDate.bind(this);
  }

  componentDidMount() {
    const { title } = this.props;
    if (typeof window !== 'undefined') {
      googleAnalyticsPage((window.location.pathname.split('/')[1] || '/home'), window.location.pathname, title);
    }
    this.setState({ displayHeight: global.innerHeight });
  }

  printDate() {
    const date = new Date();
    const year = date.getFullYear();
    return (<b>{year}</b>);
  }

  render() {
    const {
      children, title, device, feedback, footerShow, institute, hideHeader, isMobile,
    } = this.props;
    const { loading, displayHeight } = this.state;
    return (
      <div style={{
        backgroundColor: '#FCFCFC', minHeight: displayHeight, fontFamily: 'Montserrat', maxWidth: '100%', overflowX: 'hidden', width: '100%',
      }}
      >
        {title && (
        <title>
          {title}
          &nbsp;-&nbsp;IdeaBoard
        </title>
        )}
        {!hideHeader && <NavBar globalHistory={this.props.globalHistory} institute={institute} showLogin={feedback} isMobile={isMobile} />}
        <div style={{ paddingTop: hideHeader ? 0 : 70 }}>
          <SnackBar />
          {loading ? <Loading /> : children}
        </div>

        {footerShow ? (
          <Footer device={device}>
            <b>
              &copy;
              {this.printDate()}
              www.ideaboard.xyz
            </b>
          </Footer>
        ) : ''}
      </div>
    );
  }
}

Layout.propTypes = {
  title: PropTypes.string,
  children: PropTypes.node,
};
Layout.defaultProps = {
  title: 'Question Board',
  children: null,
};

export default Layout;
