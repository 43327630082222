import { UPDATE_ZONE_DETAILS } from '../actions';

const initialState = {
  overall: {},
  trend: {},
  maxStudent: 100,
  yIndex: '',
  studentsData: {},
  isSampleData: false,
  testTypeName: 'NEET',
  hasAccessToParentPortal: true,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case UPDATE_ZONE_DETAILS: {
      return {
        ...state,
        overall: action.payload.data.overall,
        trend: action.payload.data.trend,
        maxStudent: action.payload.data.max_students,
        yIndex: action.payload.data.y_index,
        thresholdPercentage: action.payload.data.threshold_percentage,
        studentsData: action.payload.data.students_data,
        testTypeName: action.payload.testTypeName,
        isSampleData: action.payload.isSampleData,
        hasAccessToParentPortal: action.payload.hasAccessToParentPortal,
      };
    }
    default:
      return state;
  }
}
