import * as Sentry from '@sentry/browser';

import {
  CLASSROOM_SELECTED,
  CAMPUS_SELECTED,
  START_DATE_SELECTED,
  END_DATE_SELECTED,
} from '../constants';
import {
  GET_ALL_CLASSROOMS,
  GET_CLASSROOM_STUDENT,
  GET_ATTENDANCE_REPORT,
  UPDATE_FILTER_DATA,
  GET_TODAY_PERIODS,
} from '../actions';
import { getStringFromISODate } from '../helpers/util';

const initialState = {
  allClassrooms: [],
  studentList: [],
  currentAbsentStudent: [],
  report: {},
  lastPeriods: [],
  filterData: {
    campusID: '',
    classroomID: '',
    startDate: '',
    endDate: '',
    campuses: [],
    classrooms: [],
  },
};

export default function (state = initialState, action) {
  switch (action.type) {
    case GET_ALL_CLASSROOMS: {
      return { ...state, allClassrooms: action.payload };
    }
    case GET_CLASSROOM_STUDENT: {
      const newState = { ...state };
      const { studentList, currentAbsentStudent } = action.payload;
      newState.studentList = studentList;
      newState.currentAbsentStudent = currentAbsentStudent;
      return newState;
    }
    case GET_ATTENDANCE_REPORT: {
      const newState = { ...state };
      const {
        campuses, classrooms, filterData, report,
      } = action.payload;
      newState.report = report;
      newState.filterData = filterData;
      newState.filterData.campuses = campuses;
      newState.filterData.classrooms = classrooms;
      return newState;
    }
    case UPDATE_FILTER_DATA: {
      const newState = { ...state };
      const { item, itemType } = action.payload;
      switch (itemType) {
        case CLASSROOM_SELECTED:
          newState.filterData.classroomID = item;
          break;
        case CAMPUS_SELECTED:
          newState.filterData.campusID = item;
          break;
        case START_DATE_SELECTED:
          newState.filterData.startDate = getStringFromISODate(item);
          break;
        case END_DATE_SELECTED:
          newState.filterData.endDate = getStringFromISODate(item);
          break;
        default:
          Sentry.captureMessage(`UPDATE_FILTER_DATA ${itemType}`);
      }
      return newState;
    }
    case GET_TODAY_PERIODS: {
      return { ...state, lastPeriods: action.payload };
    }
    default: {
      return state;
    }
  }
}
