import React, { Component } from 'react';
import { connect } from 'react-redux';
import backButton from '../../static/images/back_button.svg';
import {
  getLastAttendanceSession,
} from '../../actions';
import Constants from '../../shared/constants';

class AttendanceSession extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isOnline: true,
    };
  }

  componentDidMount() {
    this.props.getLastAttendanceSession();
    this.setState({ isOnline: navigator.onLine });
  }

  goBack() {
    window.location.replace('/attendance/get_all_classes');
  }

  editPeriod(periodID, classroomID, classroomName) {
    window.location.replace(`/attendance/${classroomID}/get_student_list?name=${classroomName}&period_id=${periodID}`);
  }

  renderPeriods(item) {
    return (
      <div style={{ width: '100%', marginTop: '10px' }}>
        <div
          className="d-flex flex-column"
          style={{
            backgroundColor: 'white', padding: '15px', paddingBottom: '0px', height: '80px',
          }}
        >
          <p style={{ fontSize: '12px' }}>
            {item.classroom_name}
&nbsp;&nbsp;&nbsp;
            <span style={{ color: '#9EA0A5' }}>{item.time_taken}</span>
          </p>
          <div className="d-flex flex-row justify-content-between">
            <p style={{ fontSize: '12px', marginBottom: '0px' }}>
              Total :
              {item.total}
            </p>
            <p style={{ fontSize: '12px', marginBottom: '0px', color: '#58A851' }}>
              Present :
              {item.present}
            </p>
            <p style={{ fontSize: '12px', marginBottom: '0px', color: '#FF5F5F' }}>
              Absentees :
              {item.total - item.present}
            </p>
          </div>
        </div>
        <div role="presentation" className="d-flex flex-row cursor" style={{ width: '100%', padding: '10px', backgroundColor: Constants().color.primary }} onClick={() => this.editPeriod(item.period_id, item.classroom_id, item.classroom_name)}>
          <div style={{ width: '50%', textAlign: 'center' }}>
            <p style={{ fontSize: '12px', marginBottom: '0px', color: 'white' }}>Edit</p>
          </div>
          <div role="presentation" className="cursor" style={{ width: '50%', textAlign: 'center', borderLeft: '1px solid white' }} onClick={() => { this.sendSMS(item.period_id); }}>
            <p style={{ fontSize: '12px', marginBottom: '0px', color: 'white' }}>Send SMS</p>
          </div>
        </div>
      </div>
    );
  }

  render() {
    const { lastPeriods } = this.props;
    if (!this.state.isOnline) {
      return (
        <div>
          <div className="row">
            <div style={{
              display: 'flex', justifyContent: 'space-between', backgroundColor: Constants().color.primary, width: '100%', height: '50px',
            }}
            >
              <div role="presentation" className="cursor" style={{ marginLeft: '5%', display: 'flex', alignItems: 'center' }} onClick={() => { this.goBack(); }}>
                <img alt="" src={backButton} width="30px" />
                <p style={{
                  color: 'white', fontSize: '15px', marginBottom: '0px', fontWeight: 'bold', marginLeft: '20px',
                }}
                >
                  Today's Session
                </p>
              </div>
            </div>
          </div>
          <div className="row" style={{ marginLeft: '10%' }}>
            <p>Network is not there. Please Try Again.</p>
          </div>
        </div>
      );
    }
    if (lastPeriods.length === 0) {
      return (
        <div>
          <div className="row">
            <div style={{
              display: 'flex', justifyContent: 'space-between', backgroundColor: Constants().color.primary, width: '100%', height: '50px',
            }}
            >
              <div role="presentation" className="cursor" style={{ marginLeft: '5%', display: 'flex', alignItems: 'center' }} onClick={() => { this.goBack(); }}>
                <img alt="" src={backButton} width="30px" />
                <p style={{
                  color: 'white', fontSize: '15px', marginBottom: '0px', fontWeight: 'bold', marginLeft: '20px',
                }}
                >
                  Today's Session
                </p>
              </div>
            </div>
          </div>
          <div className="row" style={{ marginLeft: '10%' }}>
            <p>No attendances for today.</p>
          </div>
        </div>
      );
    }
    return (
      <div style={{ backgroundColor: '#FAFAFA' }}>
        <div className="row">
          <div style={{
            display: 'flex', justifyContent: 'space-between', backgroundColor: Constants().color.primary, width: '100%', height: '50px',
          }}
          >
            <div role="presentation" className="cursor" style={{ marginLeft: '5%', display: 'flex', alignItems: 'center' }} onClick={() => { this.goBack(); }}>
              <img alt="" src={backButton} width="30px" />
              <p style={{
                color: 'white', fontSize: '15px', marginBottom: '0px', fontWeight: 'bold', marginLeft: '20px',
              }}
              >
                Today's Session
              </p>
            </div>
          </div>
        </div>
        <div
          className="row flex flex-column"
          style={{
            marginRight: '20px', marginLeft: '20px', alignItems: 'center', marginTop: '10px',
          }}
        >
          {lastPeriods.map((item) => this.renderPeriods(item))}
        </div>
      </div>
    );
  }
}

const mapStateToProps = ({ attendance }) => ({
  lastPeriods: attendance.lastPeriods,
});

export default connect(mapStateToProps, {
  getLastAttendanceSession,
})(AttendanceSession);
