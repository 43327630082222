import React from 'react';
import _ from 'lodash';
import ReactTooltip from 'react-tooltip';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Constants from '../../shared/constants';
import Layout from '../layout';
import Loading from '../loading';
import MobileHeader from '../mobileHeader';
import { isMobileDevice } from '../../helpers/util';
import { getTestChapters, updateTestChaptersData } from '../../actions';
import { sendEvent } from '../../helpers/Analytics';

const IS_MOBILE = isMobileDevice();

class TestChapterSelection extends React.Component {
  constructor(props) {
    super(props);
    const { match } = props;
    const query = new URLSearchParams(window.location.search);
    this.state = {
      showPageLoading: true,
      showButtonLoader: false,
      selectedChapterIds: [],
      errorMessage: '',
      subjectId: query.get('subject_id'),
      id: match.params.id,
      track: query.get('track'),
    };
  }

  componentDidMount() {
    const { selectedChapters } = this.props;
    const { id, subjectId, track } = this.state;
    this.props.getTestChapters(id, subjectId && subjectId !== 'undefined' ? subjectId : '', track).then(() => {
      this.setState({
        selectedChapterIds: selectedChapters,
        errorMessage: selectedChapters.length > 0 ? '' : 'You have to select atleast one chapter.',
      });
      this.setState(() => ({ showPageLoading: false }));
    });
  }

  updateTestChapters(isAutoGenerateTest) {
    const { id, subjectId, track } = this.state;
    const { selectedChapterIds } = this.state;
    const payload = {
      id,
      track,
      subjectId: subjectId && subjectId !== 'undefined' ? subjectId : '',
      chapters: JSON.stringify(selectedChapterIds),
    };
    const { history } = this.props;
    this.setState({ showButtonLoader: true });
    sendEvent('Assignment', 'updateTestChapters', 'log', { payload });
    this.props.updateTestChaptersData(payload).then((response) => {
      if (response.success) {
        if (isAutoGenerateTest) {
          sendEvent('Assignment', 'AutoTestGeneration', 'open', { payload });
          history.push(`/distribution_selection/${id}?subject_id=${subjectId}&track=${track}`);
        } else {
          sendEvent('Assignment', 'PickQuestions', 'open', { payload });
          history.push(`/new_assignment/${id}?subject_id=${subjectId}&track=${track}`);
        }
      } else {
        alert(response.data && response.data.message ? response.data.message : 'Something went wrong' );
        this.setState({ loadingState: false, showButtonLoader: false });
      }
    });
  }

  toggleChapter(id, selected) {
    const { subjectId, track } = this.state;
    const testId = this.state.id;
    const { approved, selectedChapters } = this.props;
    let { selectedChapterIds, errorMessage } = this.state;
    if (selected) {
      selectedChapterIds = _.difference(selectedChapterIds, _.flatten([id]));
      if (approved) {
        selectedChapterIds = _.flatten([...selectedChapterIds, ..._.flatten([selectedChapters])]);
      }
      errorMessage = selectedChapterIds.length > 0 ? '' : 'You have to select atleast one chapter.';
    } else {
      selectedChapterIds = _.flatten([...selectedChapterIds, ..._.flatten([id])]);
      errorMessage = '';
    }
    sendEvent('Assignment', 'updateChapters', 'log', {
      id: testId, subjectId, track, selectedChapterIds,
    });
    this.setState({ selectedChapterIds, errorMessage });
  }

  renderChapters(chapter, selected, isUnit = false) {
    const { approved, selectedChapters, isTrial } = this.props;
    const preSelectedChapter = selectedChapters.includes(chapter.id);
    const borderColor = isUnit ? Constants().color.primary : Constants().color.inactive;
    const color = isUnit ? Constants().color.primary : selected ? 'white' : 'black';
    const backgroundColor = selected && !isUnit ? Constants().color.primary : 'white';
    let errorMessage = '';
    if (approved && preSelectedChapter) {
      errorMessage = 'You cannot remove chapter of approved test.';
    } else if (chapter.count > 0) {
      errorMessage = `Removing this chapter will remove all ${chapter.count} selected questions of this chapter.`;
    }
    return (
      <div
        role="presentation"
        data-tip={errorMessage}
        className="cursor"
        style={{
          paddingTop: 5, paddingBottom: 5, paddingLeft: 10, paddingRight: 10, alignSelf: 'center', border: '1px solid', borderColor, borderRadius: 5, color, backgroundColor, width: isUnit && 100, textAlign: isUnit && 'center',
        }}
        onClick={() => (!approved || (approved && !preSelectedChapter))
          && this.toggleChapter(chapter.id, selected)}
      >
        {(errorMessage || isTrial) && <ReactTooltip />}
        {chapter.name}
      </div>
    );
  }

  renderUnits(unitId) {
    const { unitData, approved, selectedChapters } = this.props;
    const { selectedChapterIds } = this.state;
    const checkpoint_ids = [];
    unitData[unitId].chapters.map((chapter) => (checkpoint_ids.push(chapter.id)));
    const allCheckpointSelected = _.isEqual(checkpoint_ids.sort(),
      _.intersection(selectedChapterIds, checkpoint_ids).sort());
    const preSelectedChapter = selectedChapters.includes(checkpoint_ids);
    return (
      <div key={`UnitId_${unitId}`} className="m-4" style={{ fontSize: 14 }}>
        <div style={{ display: 'flex' }}>
          <input
            type="checkbox"
            style={{
              width: 20, height: 20, marginTop: 3, marginRight: 10, cursor: 'pointer',
            }}
            onChange={() => (!approved || (approved && !preSelectedChapter))
              && this.toggleChapter(checkpoint_ids, allCheckpointSelected)}
            checked={allCheckpointSelected}
          />
          <div className="header_text" style={{ fontSize: 18 }}>{unitData[unitId].name}</div>
        </div>
        <div className="row m-1">
          {unitData[unitId].chapters.map((chapter) => (
            <div style={{ margin: 5 }} key={`chapterId_${chapter.id}`}>
              {this.renderChapters(chapter, selectedChapterIds.includes(chapter.id))}
            </div>
          ))}
        </div>
      </div>
    );
  }

  renderBody() {
    const {
      unitData, isAssignment,
    } = this.props;
    let screenWidth = window.innerWidth;
    screenWidth = IS_MOBILE ? screenWidth - 30 : screenWidth * 0.85;
    return (
      <div className="pt-5 mt-5" style={{ marginBottom: 100, display: 'flex', justifyContent: 'center' }}>
        <div style={{ width: screenWidth }}>
          <p style={{
            fontSize: 28, fontWeight: 'bold', color: '#112F50', marginBottom: 0,
          }}
          >
            Let's define the syllabus for the&nbsp;
            {isAssignment ? 'assignment' : 'test'}
            .
          </p>
          <p style={{ fontSize: 16, fontWeight: 'bold', color: '#112F50' }}> Select one or more chapters from below. </p>
          {Object.keys(unitData).map((unitId) => this.renderUnits(unitId))}
        </div>
      </div>
    );
  }

  render() {
    const { approved } = this.props;
    const {
      errorMessage, showPageLoading, showButtonLoader, id, isTrial,
    } = this.state;
    if (showPageLoading) {
      return <Loading />;
    }
    if (IS_MOBILE === '') {
      return <div />;
    }
    if (IS_MOBILE) {
      return (
        <div>
          <MobileHeader
            color="white"
            backgroundColor={Constants().color.primary}
            name="Chapter Selection"
            showBackButton
            onImageClick={() => {
              this.props.history.push(`/test_creation_overview/${id}`);
              this.setState({ showPageLoading: true });
            }}
          />
          {this.renderBody()}
          {(errorMessage !== '' || approved) && <ReactTooltip />}
          <div
            role="presentation"
            id="auto-pick-questions-mobile"
            data-tip={approved ? 'Auto Genration will not work for already approved Test' : errorMessage}
            onClick={errorMessage === '' && !approved ? () => { this.updateTestChapters(true); } : () => {}}
            style={{
              display: 'flex', justifyContent: 'center', alignItems: 'center', height: 50, position: 'fixed', bottom: 0, backgroundColor: Constants().color.primary, width: '100%', color: 'white',
            }}
          >
            {showButtonLoader ? <i className="fa fa-spinner fa-spin" /> : 'Auto Pick Questions'}
          </div>
        </div>
      );
    }
    return (
      <Layout globalHistory={this.props.history}>
        {this.renderBody()}
        <div
          className="d-flex p-4"
          style={{
            backgroundColor: Constants().color.primary, left: 0, bottom: 0, position: 'fixed', width: '100%', justifyContent: 'center',
          }}
        >
          {(errorMessage !== '' || approved) && <ReactTooltip />}
          <div
            role="presentation"
            data-tip={isTrial ? 'Trial version does not let you pick questions manually' : errorMessage}
            className="pl-5 pr-5 primary_button"
            style={{
              borderColor: 'white', fontWeight: 'bold', fontSize: 16, width: 300,
            }}
            onClick={errorMessage === '' && !isTrial ? () => { this.updateTestChapters(false); } : () => {}}
          >
            Manually Pick Questions
          </div>
          <div role="presentation" id="auto-pick-questions-web" data-tip={approved ? 'Auto Genration will not work for already approved Test' : errorMessage} className="secondary_button ml-5 pl-5 pr-5" style={{ fontWeight: 'bold', fontSize: 16, width: 300 }} onClick={() => { (errorMessage === '' && !approved) && this.updateTestChapters(true); }}> Auto Pick Questions </div>
        </div>
      </Layout>
    );
  }
}

const mapStateToProps = ({ testConfiguration }) => ({
  unitData: testConfiguration.unitData,
  selectedChapters: testConfiguration.selectedChapters,
  approved: testConfiguration.approved,
  isAssignment: testConfiguration.isAssignment,
  isTrial: testConfiguration.isTrial,
});

export default connect(mapStateToProps, { getTestChapters, updateTestChaptersData })(TestChapterSelection);
