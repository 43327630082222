import React, { Component } from 'react';
import { connect } from 'react-redux';
import Modal from './baseModal';
import '../../styles/feedbackmodal.css';
import { sendExperienceFeedback } from '../../actions';
import { FEEDBACK_FEATURES_TEXT_POSITIVE, FEEDBACK_FEATURES_TEXT_NEGATIVE } from '../../constants';
import closeMark from '../../static/images/close-mark.svg';
class FeedbackModal extends Component {
  constructor(props) {
    super(props);
    const { referenceData } = this.props;
    this.state = {
      starRating: 0,
      selectedFeatures: [],
      comment: '',
      referenceData: {
        id: referenceData.id,
        subjectId: referenceData.subjectId,
        track: referenceData.track,
      },
    };
  }

  displaystars() {
    return (
      <fieldset className="rating" style={{ marginBottom: '0px' }}>
        <input type="radio" id="star5" name="rating" onClick={() => { this.setState({ starRating: 5, selectedFeatures: [], comment: '' }); }} />
        <label className="star" htmlFor="star5" />
        <input type="radio" id="star4" name="rating" onClick={() => { this.setState({ starRating: 4, selectedFeatures: [], comment: '' }); }} />
        <label className="star" htmlFor="star4" />
        <input type="radio" id="star3" name="rating" onClick={() => { this.setState({ starRating: 3, selectedFeatures: [], comment: '' }); }} />
        <label className="star" htmlFor="star3" />
        <input type="radio" id="star2" name="rating" onClick={() => { this.setState({ starRating: 2, selectedFeatures: [], comment: '' }); }} />
        <label className="star" htmlFor="star2" />
        <input type="radio" id="star1" name="rating" onClick={() => { this.setState({ starRating: 1, selectedFeatures: [], comment: '' }); }} />
        <label className="star" htmlFor="star1" />
      </fieldset>
    );
  }

  updatefeatures() {
    const {
      starRating, selectedFeatures, comment, referenceData,
    } = this.state;
    this.props.sendExperienceFeedback(starRating, comment,
      JSON.stringify(selectedFeatures),
      JSON.stringify(referenceData)).then((response) => {
      this.setState({ starRating: 0 });
      this.props.toggleFeedbackModal(false, response.success);
    });
  }

  handleComments(event) {
    this.setState({ comment: event.target.value });
  }

  displayfeatures() {
    const { selectedFeatures, starRating, comment } = this.state;
    let featuresList = [];
    featuresList = (starRating > 3)
      ? (FEEDBACK_FEATURES_TEXT_POSITIVE) : (FEEDBACK_FEATURES_TEXT_NEGATIVE);
    if (starRating > 0) {
      return (
        <div>
          <div style={{ textAlign: 'center', marginBottom: '6%' }}>{(starRating <= 3) ? 'What can we improve?' : 'What did you like?'}</div>
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <div className="features-container">
              {featuresList.map((feature, id) => (
                <div
                  role="presentation"
                  style={{ cursor: 'pointer', paddingLeft: '10px' }}
                  className={(selectedFeatures.includes(id)) ? 'features-selected' : 'features'}
                  onClick={() => {
                    if (selectedFeatures.includes(id)) {
                      selectedFeatures.splice(selectedFeatures.indexOf(id), 1);
                    } else {
                      selectedFeatures.push(id);
                    }
                    this.setState({ selectedFeatures });
                  }}
                >
                  <div style={{ padding: '3% 1%', color: '#7C7C7C' }}>{feature}</div>
                </div>
              ))}
            </div>
          </div>
          <div>
            <textarea className="comments-box" placeholder="Comments... " value={comment} onChange={(event) => this.handleComments(event)} />
          </div>
        </div>
      );
    }
    return <div />;
  }

  render() {
    const { starRating } = this.state;
    return (
      <Modal showModal={this.props.showModal} style={{ width: '30%' }} handleCloseModal={() => this.props.toggleFeedbackModal(false, false)}>
        <div style={{ display: 'flex', flexDirection: 'column', padding: '10px 20px 20px 20px' }}>
          <div role="presentation" style={{ display: 'flex', justifyContent: 'flex-end' }} onClick={() => { this.props.toggleFeedbackModal(false, false); }}>
            <img src={closeMark} alt="x-mark" style={{ cursor: 'pointer' }} />
          </div>
          <div style={{ textAlign: 'center' }}> How was your experience?</div>
          <div style={{ margin: 'auto' }}>
            {this.displaystars()}
          </div>
          {this.displayfeatures()}
          <div role="presentation" style={{ marginTop: 20, cursor: (starRating < 1) ? 'no-drop' : 'pointer' }}>
            <div role="presentation" className="submit-button" onClick={() => starRating > 0 && this.updatefeatures()} style={{ opacity: (starRating < 1) ? 0.6 : 1 }}> Submit </div>
          </div>
        </div>
      </Modal>
    );
  }
}

export default connect(null, { sendExperienceFeedback })(FeedbackModal);
