import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Select from 'react-select';
import CompactLoader from '../loading';
import Modal from './baseModal';
import Constants from '../../shared/constants';
import {
  uploadAttachmentDetails, updateAttachmentDetails, hideFile,
  handleDeletedVideo, getSubjectData, showToast,
} from '../../actions';
import '../../styles/instituteOnBoarding.css';
import uploadImage from '../../static/images/uploadFile.svg';
import { isMobileDevice } from '../../helpers/util';

const { Vimeo } = require('vimeo');
const IS_MOBILE = isMobileDevice();

const constants = Constants();
let client = '';

const style = {
  resize: 'none', minHeight: 45, marginBottom: 30, outline: 'none', border: '1px solid #454545', borderRadius: 0,
};
const border = {
  border: '1px solid #454545', width: 105, height: 38, borderRadius: 0,
};

class VirtualMeetingRecordings extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      showLoader: true,
      fileTitle: '',
      fileDescription: '',
      fileUploaded: false,
      userFile: null,
      recordingUrl: props.recordingUrl,
      percentage: '0%',
      selectedChapter: {},
      selectedCheckpoints: [],
      bytesTotal: 0,
      isVimeoUploadStarted: false,
      success: true,
    };
    this.uploadFiles = this.uploadFiles.bind(this);
    this.publishFiles = this.publishFiles.bind(this);
    this.renderFileData = this.renderFileData.bind(this);
  }

  componentDidMount() {
    const { meeting } = this.props;
    client = new Vimeo(constants.vimeoClientId, constants.vimeoClientSecret,
      constants.vimeoAccessToken);
    this.props.getSubjectData(meeting.id).then((response) => {
      this.setState({ showLoader: false, selectedChapter: response.selected_chapter || {}, selectedCheckpoints: response.selected_checkpoints || [] });
    });
  }

  getCheckpointIds() {
    const { selectedCheckpoints, selectedChapter } = this.state;
    const { videoCheckpoints } = this.props;
    const chapter = selectedChapter && selectedChapter.value;
    const checkpointIds = [];
    if (selectedChapter && selectedChapter.value) {
      const filteredCheckpoints = [...videoCheckpoints].filter((checkpoints) => (!chapter
      || checkpoints.chapter_ids.includes(chapter)));
      const data = selectedCheckpoints && selectedCheckpoints.length > 0
        ? selectedCheckpoints : (filteredCheckpoints || []);
      data.map((checkpoint) => {
        checkpointIds.push(checkpoint.value);
      });
    }
    return checkpointIds;
  }

  deleteUploadedVideo() {
    const { recordingUrl, meeting } = this.props;
    const splittedArray = recordingUrl.split('/');
    const videoUri = splittedArray[splittedArray.length - 1];

    if (recordingUrl.includes('vimeo')) {
      client.request({
        method: 'DELETE',
        path: `/videos/${videoUri}`,
      }, (error, body, statusCode, headers) => {
        if (!error) {
          this.props.handleDeletedVideo(meeting.id);
        }
      });
    } else {
      this.props.handleDeletedVideo(meeting.id);
    }
    this.setState({ recordingUrl: '', userFile: '' });
  }

  uploadVideoToVimeo() {
    const { userFile, selectedChapter } = this.state;
    const { meeting, remainingRecordingVideoStorageInMb } = this.props;
    const file = userFile;
    const checkpointIds = this.getCheckpointIds();
    if (remainingRecordingVideoStorageInMb >= (userFile.size / (1024 * 1024))) {
      this.setState({ isVimeoUploadStarted: true });
      client.upload(
        file,
        {
          name: `${meeting.subject_title}_virtual_meeting_id_${meeting.id}`,
        },
        (uri) => {
          const currentUrl = `https://player.vimeo.com${uri.replace('videos', 'video')}`;
          const data = {
            recordingUrl: currentUrl,
            fileSize: userFile.size,
            chapterId: selectedChapter && selectedChapter.value,
            checkpointIds,
          };
          this.setState({
            recordingUrl: currentUrl,
            videoUri: currentUrl,
          }, () => this.props.addRecordingUrl(meeting,
            data));
        },
        (bytesUploaded, bytesTotal) => {
          const percentage = parseInt((bytesUploaded / bytesTotal) * 100, 10);
          this.setState({ percentage: `${percentage}%`, bytesTotal, isVimeoUploadStarted: true });
        },
        (error) => {
          this.setState({ success: false });
          this.props.showToast(error);
        },
      );
    } else {
      return this.props.showToast('You have exceeded your upload limit. Please contact customer support.');
    }
  }

  uploadFiles(event) {
    const file = event.target.files[0];
    this.setState({ fileUploaded: true, userFile: file });
    client.request({
      method: 'GET',
      path: '/tutorial',
    });
  }

  publishFiles() {
    const { fileTitle, fileDescription, userFile } = this.state;
    const { meeting } = this.props;
    const classroomIds = meeting.classroom_ids;
    this.props.uploadAttachmentDetails(fileTitle, fileDescription, userFile, classroomIds, '', '', '', meeting.subject_id, meeting.id);
  }

  displayVideo(url) {
    if (url.length > 10) {
      return (
        <iframe
          id="embed-recorded-video"
          width="100%"
          height="100%"
          title="video"
          src={url}
          frameBorder="0"
          allowFullScreen
        />
      );
    }
    return <div />;
  }

  domainSearch(filterItems, selectedItem, selectedFunction, item, isMulti = false) {
    const { selectedChapter } = this.state;
    if (!filterItems || filterItems.length === 0) {
      return true;
    }
    const items = filterItems;
    const height = isMulti ? { maxHeight: 100, overflowY: 'scroll' } : { height: 45, maxHeight: 45 };
    const customStyles = {
      control: (base) => ({
        ...base,
        backgroundColor: 'white',
        width: IS_MOBILE ? 150 : 250,
        ...height,
        border: '1px solid #343737 !important',
      }),
      indicatorsContainer: (base) => ({
        ...base,
      }),
      valueContainer: (base) => ({
        ...base,
        alignContent: 'center',
        height: 'inherit',
      }),
    };
    return (
      <div style={{ marginBottom: 20, display: 'flex', justifyContent: 'flex-end' }}>
        <style>
          {`
          input[type=text]:focus {
            outline: none !important;
          }
      `}
        </style>
        <Select
          isMulti={isMulti}
          isClearable
          key={`${item}-${selectedChapter && selectedChapter.value}`}
          styles={customStyles}
          value={isMulti ? selectedItem && selectedItem.length > 0 && selectedItem : selectedItem && selectedItem.value ? selectedItem : ''}
          onChange={selectedFunction}
          options={[...items]}
          placeholder={item}
        />
      </div>
    );
  }

  renderUrlOrProgressBar() {
    const {
      userFile, percentage, success, isVimeoUploadStarted,
    } = this.state;
    const { recordingUrl } = this.props;
    const showDelete = recordingUrl;
    if (userFile) {
      if (!isVimeoUploadStarted) return <div />;
      return (
        <div style={{ width: '100%' }}>
          Uploading
          <div style={{
            display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%', height: 45, marginRight: 15,
          }}
          >
            <div style={{
              height: 5, width: '100%', backgroundColor: '#EFEFEF', marginLeft: 10, marginRight: 10, borderRadius: 2,
            }}
            >
              <div style={{
                width: percentage, height: '100%', backgroundColor: success ? '#20D000' : 'red', borderRadius: 2,
              }}
              />
            </div>
            <div>
              {percentage}
            </div>
          </div>
          <div style={{ color: 'red' }}>
            *Please stay in this page, untill upload is over
          </div>
        </div>
      );
    }
    return (
      <div style={{
        display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: 15, width: '100%',
      }}
      >
        <textarea
          type="text"
          value={this.state.recordingUrl}
          style={{
            ...style, height: recordingUrl ? 'auto' : 45, width: showDelete ? 320 : '100%', marginBottom: 0,
          }}
          placeholder="Add url"
          onChange={(event) => this.setState({ recordingUrl: event.target.value })}
        />
        <style>
          {`
          textarea::placeholder {
            color: #ababab !important;
          }
          `}
        </style>

      </div>
    );
  }

  renderFileData() {
    const { userFile, recordingUrl } = this.state;
    const url = userFile ? URL.createObjectURL(userFile) : (recordingUrl ? recordingUrl.replace('/view', '/preview').replace('watch?v=', 'embed/').replace('/vimeo.com', '/player.vimeo.com/video') : '');
    if (userFile || url) {
      return (
        <div style={{
          borderRadius: 4, height: 150, width: '100%', textAlign: 'center', border: '1px solid #343737', backgroundColor: '#FFFFFF', display: 'flex', flexDirection: 'column', position: 'relative', cursor: 'pointer', marginBottom: 15,
        }}
        >
          {this.displayVideo(url)}
        </div>
      );
    }
    return (
      <div style={{
        borderRadius: 4, height: IS_MOBILE ? 180 : 150, width: IS_MOBILE ? 150 : '100%', textAlign: 'center', border: '1px solid #343737', backgroundColor: '#FFFFFF', display: 'flex', flexDirection: 'column', position: 'relative', cursor: 'pointer', marginBottom: 15,
      }}
      >
        <div style={{
          margin: 20, height: '100%', textAlign: 'center', backgroundColor: '#FFFFFF', display: 'flex', flexDirection: 'column', alignItems: ' center', justifyContent: 'center', border: 'none',
        }}
        >
          <input
            style={{
              position: 'absolute', opacity: '0', textAlign: 'center', zIndex: '1', height: '100%', width: '100%', cursor: 'pointer',
            }}
            type="file"
            accept="video/mp4,video/x-m4v,video/*"
            onChange={(event) => this.uploadFiles(event)}
          />
          <div style={{
            height: '35px', position: 'absolute', textAlign: 'center', width: '35px', background: `url(${uploadImage}) no-repeat`, opacity: 1, top: 40,
          }}
          />
          <div style={{
            fontSize: '12', color: Constants().color.primary, padding: '8px', marginTop: 60,
          }}
          >
            Drag file or browse to select video
          </div>
        </div>
      </div>
    );
  }

  renderTagging() {
    const { videoChapters, videoCheckpoints } = this.props;
    const { selectedChapter, selectedCheckpoints } = this.state;
    const chapter = selectedChapter && selectedChapter.value;
    const filteredCheckpoints = [...videoCheckpoints].filter((checkpoints) => (!chapter
      || checkpoints.chapter_ids.includes(chapter)));
    return (
      <div style={{ width: 300, marginLeft: 20 }}>
        {this.domainSearch([...videoChapters], selectedChapter,
          (selectedOption) => this.setState({
            selectedChapter: selectedOption,
            selectedCheckpoints: [],
          }), 'Select Chapter')}
        {chapter && this.domainSearch([...filteredCheckpoints], selectedCheckpoints,
          (selectedOption) => {
            this.setState({ selectedCheckpoints: selectedOption });
          }, 'Select Checkpoint', true)}
      </div>
    );
  }

  renderMain() {
    const {
      showLoader, bytesTotal, userFile, fileUploaded, isVimeoUploadStarted,
      recordingUrl, selectedChapter,
    } = this.state;
    const { meeting } = this.props;
    if (showLoader) {
      return <CompactLoader mainHeight={350} />;
    }
    const checkpointIds = this.getCheckpointIds();
    const data = {
      recordingUrl,
      fileSize: bytesTotal,
      chapterId: selectedChapter && selectedChapter.value,
      checkpointIds,
    };
    return (
      <div style={{
        placeItems: 'center', background: '#FFFFFF',
      }}
      >
        <style>
          {`
          textarea::placeholder {
            color: #000000 !important;
          }
          `}
        </style>

        <div style={{
          fontSize: 28, fontWeight: 600, marginBottom: 20, whiteSpace: 'nowrap',
        }}
        >
          Video Recording
        </div>
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <div style={{
            width: 350, display: 'flex', flexDirection: 'column', alignItems: 'center',
          }}
          >
            {!userFile && this.renderUrlOrProgressBar()}
            {this.renderFileData()}
            {userFile && this.renderUrlOrProgressBar()}
            {recordingUrl && (
            <div
              role="presentation"
              className="Button"
              style={{ ...border, marginLeft: 15, height: 45 }}
              onClick={() => this.deleteUploadedVideo()}
            >
              DELETE
            </div>
            )}
          </div>
          {this.renderTagging()}
        </div>
        <div style={{
          display: 'flex', justifyContent: 'space-between', marginTop: 25, opacity: isVimeoUploadStarted ? 0.4 : 1, pointerEvents: isVimeoUploadStarted ? 'none' : 'auto',
        }}
        >
          <div
            role="presentation"
            className="Button"
            style={{ ...border }}
            onClick={() => !isVimeoUploadStarted && this.props.handleCloseModal()}
          >
            Cancel
          </div>
          <div
            role="presentation"
            className="Button"
            style={{ ...border }}
            onClick={() => {
              if (fileUploaded) {
                this.uploadVideoToVimeo();
              } else {
                this.props.addRecordingUrl(meeting, data);
              }
            }}
          >
            Submit
          </div>
        </div>
      </div>
    );
  }

  render() {
    const { isVimeoUploadStarted } = this.state;
    const { showModal } = this.props;
    return (
      <Modal showModal={showModal} handleCloseModal={() => !isVimeoUploadStarted && this.props.handleCloseModal()} style={{ padding: IS_MOBILE ? 10 : 30, borderRadius: 0 }} width={IS_MOBILE ? '95%' : '650px'} minHeight="350px">
        {this.renderMain()}
      </Modal>
    );
  }
}

VirtualMeetingRecordings.propTypes = {
  showModal: PropTypes.bool,
  meeting: PropTypes.object,
  videoChapters: PropTypes.array,
  videoCheckpoints: PropTypes.array,
  recordingUrl: PropTypes.string,
  remainingRecordingVideoStorageInMb: PropTypes.number,
};

VirtualMeetingRecordings.defaultProps = {
  showModal: false,
  meeting: {},
  recordingUrl: '',
  videoChapters: [],
  videoCheckpoints: [],
  remainingRecordingVideoStorageInMb: 0,
};

const mapStateToProps = ({ virtualMeeting }) => ({
  videoCheckpoints: virtualMeeting.videoCheckpoints,
  videoChapters: virtualMeeting.videoChapters,
});

export default connect(mapStateToProps, {
  uploadAttachmentDetails,
  showToast,
  updateAttachmentDetails,
  hideFile,
  handleDeletedVideo,
  getSubjectData,
})(VirtualMeetingRecordings);
