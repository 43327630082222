import React from "react";
import Constants from "../../../shared/constants";
import { convertToCurrencyFormat } from "../../../helpers/util";
import Modal from "../../modal/baseModal";
import "../bdeTools.css";
import moment from "moment";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import Select from "react-select";
import { createPayment, showToast } from "../../../actions";

const paymentModes = [
  { label: "CHEQUE", value: 0 },
  { label: "RAZOR PAY", value: 1 },
  { label: "BANK TRANSFER", value: 2 },
  { label: "TRIAL", value: 3 },
  { label: "WRITE OFF", value: 4 },
];

class BdeInstitutePayments extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showRecordPaymentModal: false,
      showEditPaymentModal: false,
      newPayment: {
        paymentDate: "",
        paymentAmount: 0,
        paymentMode: null,
        transactionId: "",
        nextPaymentDate: "",
        nextPaymentAmount: 0,
        comments: "",
        invoice_id: "",
      },
    };
  }

  componentDidMount() {}

  resetNewPayment = () => {
    this.setState({
      newPayment: {
        dealId: null,
        paymentDate: "",
        paymentAmount: 0,
        paymentMode: null,
        transactionId: "",
        nextPaymentDate: "",
        nextPaymentAmount: null,
        comments: "",
        invoice_id: "",
      },
    });
  };

  renderRecordPayment() {
    const { institute } = this.props;
    return (
      <div
        style={{
          marginBottom: "15px",
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
        }}
      >
        <div
          className="Button primary-Button"
          onClick={() => {
            this.setState({
              showRecordPaymentModal: true,
              newPayment: {
                ...this.state.newPayment,
                paymentDate: moment().format("YYYY-MM-DD"),
                paymentAmount: institute.payments[0]?.next_payment_amount,
              },
            });
          }}
          style={{ margin: "auto 0" }}
        >
          Record Payment
        </div>
        <div style={{ display: "flex", flexDirection: "row" }}>
          <div
            className="extended-row-background"
            style={{
              margin: "auto 10px auto 0",
              display: "flex",
              flexDirection: "column",
            }}
          >
            <div style={{ margin: 10 }}>
              <div className="extended-row-content">
                {convertToCurrencyFormat(institute.paymentsDue) || "-"}
              </div>
              <div className="extended-row-title">Dues</div>
            </div>
          </div>
          <div
            className="extended-row-background"
            style={{ display: "flex", flexDirection: "column", margin: "auto" }}
          >
            <div style={{ margin: 10 }}>
              <div className="extended-row-content">
                {moment(institute.paymentsDueDate).format("DD/MM/YYYY") || "-"}
              </div>
              <div className="extended-row-title">Due Date</div>
            </div>
          </div>
        </div>
      </div>
    );
  }
  renderRecentPayment() {
    const { institute } = this.props;
    return (
      <div>
        <div
          style={{
            color: Constants().color.primary,
            fontSize: 20,
            marginBottom: 5,
          }}
        >
          Recent Payment
        </div>
        <div className="extended-row-background">
          <div style={{ margin: 10 }}>
            <div className="extended-row-content">
              {institute.payments[0]?.deal_id
                ? convertToCurrencyFormat(institute.payments[0]?.deal_id)
                : "-"}
            </div>
            <div className="extended-row-title">Deal ID</div>
          </div>
          <div style={{ margin: 10 }}>
            <div className="extended-row-content">
              {institute.payments[0]?.bde_name || "-"}
            </div>
            <div className="extended-row-title">Deal Owner</div>
          </div>
          <div style={{ margin: 10 }}>
            <div className="extended-row-content">
              {institute.payments[0]?.next_payment_amount
                ? convertToCurrencyFormat(
                    institute.payments[0]?.next_payment_amount
                  )
                : "-"}
            </div>
            <div className="extended-row-title">Next Payment Amount</div>
          </div>
          <div style={{ margin: 10 }}>
            <div className="extended-row-content">
              {moment(institute.payments[0]?.created_at).format("DD/MM/YYYY")}
            </div>
            <div className="extended-row-title">Last Payment Date</div>
          </div>
          <div style={{ margin: 10 }}>
            <div className="extended-row-content">
              {institute.payments[0]?.invoice_id || "-"}
            </div>
            <div className="extended-row-title">Invoice Number</div>
          </div>
          {/* <div
            className="Button primary-Button"
            style={{ margin: "auto 0", width: 80 }}
            onClick={() => {
              this.setState({ showEditPaymentModal: true });
            }}
          >
            Edit
          </div> */}
        </div>
      </div>
    );
  }
  renderPaymentsTable() {
    const { institute } = this.props;
    if (institute.payments.length === 0) {
      return (
        <div
          style={{
            margin: "20px 0 20px 0",
            fontSize: 23,
            fontWeight: 600,
            color: "#1d4c7f",
            display: "flex",
            justifyContent: "center",
          }}
        >
          No Payments
        </div>
      );
    }
    return (
      <table className="table table-bordered">
        <thead>
          <tr>
            <th style={{ textAlign: "right" }}>Deal ID</th>
            <th>Product Name</th>
            <th style={{ textAlign: "right" }}>Contract Value</th>
            <th style={{ textAlign: "right" }}>Amount Received</th>
            <th style={{ textAlign: "right" }}>Next Payment Amount</th>
            <th style={{ textAlign: "right" }}>Payment Date</th>
            <th style={{ textAlign: "right" }}>Invoice ID</th>
            <th>Mode</th>
          </tr>
        </thead>
        <tbody>
          {institute.payments.map((payment, index) => {
            return (
              <tr key={index}>
                <td style={{ textAlign: "right" }}>
                  {convertToCurrencyFormat(payment.deal_id, "")}
                </td>
                <td>{payment.name}</td>
                <td style={{ textAlign: "right" }}>
                  {convertToCurrencyFormat(payment.final_price)}
                </td>
                <td style={{ textAlign: "right" }}>
                  {convertToCurrencyFormat(payment.amount)}
                </td>
                <td style={{ textAlign: "right" }}>
                  {convertToCurrencyFormat(payment.next_payment_amount)}
                </td>
                <td style={{ textAlign: "right" }}>
                  {moment(payment.created_at).format("DD/MM/YYYY")}
                </td>
                <td style={{ textAlign: "right" }}>
                  {payment.invoice_id || "-"}
                </td>
                <td>{payment.payment_mode}</td>
              </tr>
            );
          })}
        </tbody>
      </table>
    );
  }

  renderRecordPaymentModal() {
    const { institute } = this.props;
    return (
      <Modal
        showModal={this.state.showRecordPaymentModal}
        showRecordPaymentModal
        handleCloseModal={() => {
          this.resetNewPayment();
          this.setState({ showRecordPaymentModal: false });
        }}
        header={`New Payment`}
      >
        <div style={{ padding: 15 }}>
          <div
            style={{ marginBottom: 5, display: "flex", flexDirection: "row" }}
          >
            <span className="input-row-elements">Deal</span>
            <div style={{ width: 200 }}>
              <Select
                defaultValue={this.state.newPayment.dealId}
                onChange={(selectedDeal) => {
                  this.setState({
                    newPayment: {
                      ...this.state.newPayment,
                      dealId: selectedDeal.value,
                    },
                  });
                }}
                options={institute.subscriptions?.map((deal) => {
                  return { label: deal.id, value: deal.id };
                })}
                placeholder={"Deal ID"}
                isSearchable={false}
              />
            </div>
          </div>
          <div
            style={{ marginBottom: 5, display: "flex", flexDirection: "row" }}
          >
            <span className="input-row-elements">Payment Date</span>
            <input
              type="date"
              style={{ margin: "auto 0", width: 200 }}
              defaultValue={moment().format("YYYY-MM-DD")}
              onChange={(event) => {
                this.setState({
                  newPayment: {
                    ...this.state.newPayment,
                    paymentDate: event.target.value,
                  },
                });
              }}
            />
          </div>
          <div
            style={{ marginBottom: 5, display: "flex", flexDirection: "row" }}
          >
            <span className="input-row-elements">Amount</span>
            <input
              type="text"
              className="input-row-elements"
              onChange={(event) => {
                this.setState({
                  newPayment: {
                    ...this.state.newPayment,
                    paymentAmount: event.target.value,
                  },
                });
              }}
            />
          </div>
          <div
            style={{ marginBottom: 5, display: "flex", flexDirection: "row" }}
          >
            <span className="input-row-elements">Payment Type</span>
            <div style={{ width: 200 }}>
              <Select
                defaultValue={this.state.newPayment.paymentMode}
                onChange={(selectedMode) => {
                  this.setState({
                    newPayment: {
                      ...this.state.newPayment,
                      paymentMode: selectedMode.value,
                    },
                  });
                }}
                options={paymentModes}
                placeholder={"Payment Type"}
                isSearchable={false}
              />
            </div>
          </div>
          <div
            style={{ marginBottom: 5, display: "flex", flexDirection: "row" }}
          >
            <span className="input-row-elements">Transaction ID</span>
            <input
              type="text"
              className="input-row-elements"
              onChange={(event) => {
                this.setState({
                  newPayment: {
                    ...this.state.newPayment,
                    transactionId: event.target.value,
                  },
                });
              }}
            />
          </div>
          <div
            style={{ marginBottom: 5, display: "flex", flexDirection: "row" }}
          >
            <span className="input-row-elements">Next Payment Date</span>
            <input
              type="date"
              style={{ margin: "auto 0", width: 200 }}
              onChange={(event) => {
                this.setState({
                  newPayment: {
                    ...this.state.newPayment,
                    nextPaymentDate: event.target.value,
                  },
                });
              }}
            />
          </div>
          <div
            style={{ marginBottom: 5, display: "flex", flexDirection: "row" }}
          >
            <span className="input-row-elements">Next Payment Amount</span>
            <input
              type="text"
              className="input-row-elements"
              onChange={(event) => {
                this.setState({
                  newPayment: {
                    ...this.state.newPayment,
                    nextPaymentAmount: event.target.value,
                  },
                });
              }}
            />
          </div>
          <div
            style={{ marginBottom: 5, display: "flex", flexDirection: "row" }}
          >
            <span className="input-row-elements">Invoice ID</span>
            <input
              type="text"
              className="input-row-elements"
              onChange={(event) => {
                this.setState({
                  newPayment: {
                    ...this.state.newPayment,
                    invoiceId: event.target.value,
                  },
                });
              }}
            />
          </div>
          <input
            type="text-area"
            style={{ margin: "auto 0", width: 400 }}
            placeholder="Comments"
            onChange={(event) => {
              this.setState({
                newPayment: {
                  ...this.state.newPayment,
                  comments: event.target.value,
                },
              });
            }}
          />

          <div
            style={{
              display: "flex",
              flexDirection: "row",
              marginTop: 10,
              justifyContent: "right",
            }}
          >
            <div
              className="Button secondary-Button"
              style={{ width: 100, margin: "auto 10px" }}
              onClick={() => {
                this.resetNewPayment();
                this.setState({ showRecordPaymentModal: false });
              }}
            >
              Cancel
            </div>
            <div
              className="Button primary-Button"
              style={{ width: 100, margin: "auto 10px" }}
              onClick={() => {
                const { newPayment } = this.state;
                if (
                  !newPayment.dealId ||
                  !newPayment.paymentAmount ||
                  (!newPayment.paymentMode && newPayment.paymentMode !== 0) ||
                  !newPayment.transactionId
                ) {
                  this.props.showToast(
                    "Please provide mandatory values :(DEAL_ID, AMOUNT, TRANSACTION_ID, PAYMENT_DATE)"
                  );
                } else {
                  this.props.createPayment(this.state.newPayment);
                  this.resetNewPayment();
                  this.setState({ showRecordPaymentModal: false });
                }
              }}
            >
              Create
            </div>
          </div>
        </div>
      </Modal>
    );
  }

  renderEditPaymentModal() {
    const { institute } = this.props;
    return (
      <Modal
        showModal={this.state.showEditPaymentModal}
        handleCloseModal={() => this.setState({ showEditPaymentModal: false })}
        header={`Edit Payment`}
      >
        <div style={{ padding: 15 }}>
          <div
            style={{ marginBottom: 5, display: "flex", flexDirection: "row" }}
          >
            <span className="input-row-elements">Deal Owner</span>
            <input
              type="text"
              className="input-row-elements"
              value={institute.payments[0].bde_name}
            />
          </div>
          <div
            style={{ marginBottom: 5, display: "flex", flexDirection: "row" }}
          >
            <span className="input-row-elements">Contract Value</span>
            <input
              type="text"
              className="input-row-elements"
              value={institute.payments[0].quotation}
            />
          </div>
          <div
            style={{ marginBottom: 5, display: "flex", flexDirection: "row" }}
          >
            <span className="input-row-elements">Amount Received</span>
            <input
              type="text"
              className="input-row-elements"
              value={institute.payments[0].amount_received}
            />
          </div>
          <div
            style={{ marginBottom: 5, display: "flex", flexDirection: "row" }}
          >
            <span className="input-row-elements">Payment Date</span>
            <input type="date" className="input-row-elements" />
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              marginTop: 10,
              justifyContent: "right",
            }}
          >
            <div
              className="Button secondary-Button"
              style={{ width: 100, margin: "auto 10px" }}
              onClick={() => {
                this.setState({ showEditPaymentModal: false });
              }}
            >
              Cancel
            </div>
            <div
              className="Button primary-Button"
              style={{ width: 100, margin: "auto 10px" }}
              onClick={() => {
                // POST request for update
                this.setState({ showEditPaymentModal: false });
              }}
            >
              Update
            </div>
          </div>
        </div>
      </Modal>
    );
  }

  render() {
    const { showEditPaymentModal, showRecordPaymentModal } = this.state;
    return (
      <div>
        {showEditPaymentModal && this.renderEditPaymentModal()}
        {showRecordPaymentModal && this.renderRecordPaymentModal()}
        {this.renderRecordPayment()}
        {this.renderRecentPayment()}
        {this.renderPaymentsTable()}
      </div>
    );
  }
}

BdeInstitutePayments.propTypes = {
  isLoading: PropTypes.bool,
  institute: PropTypes.object,
};

BdeInstitutePayments.defaultProps = {
  institute: {},
};

const mapStateToProps = ({ bdeTools }) => ({
  isLoading: bdeTools.isLoading,
  institute: bdeTools.institute,
});

export default withRouter(
  connect(mapStateToProps, { createPayment, showToast })(BdeInstitutePayments)
);
