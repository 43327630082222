import {
  UPDATE_BDE_TOOLS_DETAILS,
  UPDATE_BDE_TOOLS_PAYMENTS,
  UPDATE_BDE_TOOLS_INSTITUTE,
  UPDATE_BDE_TOOLS_DEALS_AND_SUBSCRIPTIONS,
} from "../actions";

const INITIAL_STATE = {
  dashboardData: [],
  dashboardSummary: {
    activeInstitutesCount: null,
    activeStudentCounts: null,
    dealsCount: null,
    dealsWonCount: null,
    dealsPendingCount: null,
    testsCount: null,
    pausedInstitutesCount: null,
    aboutToExpireDealsCount: null,
    expiredDealsCount: null,
    institutesWithLessQuestions: [],
  },
  institutesList: [],
  products: [],
  institute: {
    id: "",
    name: "",
    created_at: "",
    updated_at: "",
    phone: "",
    contact_email: "",
    contact_name: "",
    student_count: "",
    subscriptions: [],
    campuses: [],
    questions_count: "",
    remaining_question_count: "",
    active_students_count: "",
    payments: [],
    paymentsDue: "",
    paymentsDueDate: "",
    deals: {
      active_deals: [],
      other_deals: [],
    },
  },
  isLoading: false,
  isDashboardLoading: false,
  isDashboardMoadalDataLoading: false,
};

const updateInstitutePayments = (state, payload) => {
  state.institute.payments = payload.payments;
  state.institute.paymentsDue = payload.dues;
  state.institute.paymentsDueDate = payload.due_date;
  return state;
};

const updateInstituteDealsAndSubscriptions = (state, payload) => {
  state.institute.subscriptions = payload.subscriptions;
  state.institute.deals.active_deals = payload.active_deals;
  state.institute.deals.other_deals = payload.other_deals;
  return state;
};

const updateInstitute = (state, payload) => {
  state.institute = { ...state.institute, ...payload };
  return state;
};

export default function (state = INITIAL_STATE, action) {
  switch (action.type) {
    case UPDATE_BDE_TOOLS_DETAILS: {
      return { ...state, ...action.payload };
    }

    case UPDATE_BDE_TOOLS_INSTITUTE: {
      return {
        ...state,
        ...updateInstitute(state, action.payload),
      };
    }

    case UPDATE_BDE_TOOLS_PAYMENTS: {
      return {
        ...state,
        ...updateInstitutePayments(state, action.payload),
      };
    }

    case UPDATE_BDE_TOOLS_DEALS_AND_SUBSCRIPTIONS: {
      return {
        ...state,
        ...updateInstituteDealsAndSubscriptions(state, action.payload),
      };
    }

    default: {
      return state;
    }
  }
}
