import React, { Component } from 'react';
import { connect } from 'react-redux';
import Cookies from 'universal-cookie';
import StudentsAttendance from '../studentsAttendance';
import TeacherAttendance from '../teacherAttendance';

const cookies = new Cookies();
const roles = cookies.get('roles') || {};
const TOOLS_TYPES = {
  attendance: 'Students Attendance',
  teacher_attendance: 'Teacher Attendance',
};
const endingDate = new Date();
endingDate.setDate(new Date().getDate() - 7);
const yesterday = new Date();
yesterday.setDate((yesterday).getDate() - 1);
class Attendance extends Component {
  constructor(props) {
    super(props);
    const { type } = this.props;
    this.state = {
      selectedType: type ? TOOLS_TYPES[type.toLowerCase()] : TOOLS_TYPES.attendance,
    };
  }

  render() {
    const { selectedType } = this.state;
    const { quizId } = this.props;
    const isStudentsAttendance = selectedType === TOOLS_TYPES.attendance;
    const isTeacherAttendance = selectedType === TOOLS_TYPES.teacher_attendance;
    return (
      <div>
        { (!quizId && !roles.is_teacher) ? (
          <div style={{ height: typeof window !== 'undefined' && window.innerHeight }}>
            <div className="hide-in-print" style={{ justifyContent: 'space-between', alignItems: 'center' }}>
              <div style={{ display: 'flex', padding: '25px 60px' }}>
                {Object.keys(TOOLS_TYPES).map((type, index) => {
                  const selected = selectedType === TOOLS_TYPES[type];
                  return (
                    <div
                      role="presentation"
                      className={selected ? 'Button Primary-Button' : 'Button Secondary-Button'}
                      style={{
                        borderRadius: 0, borderLeft: (index !== 0) && 0, display: 'flex', padding: '0px 15px',
                      }}
                      onClick={() => this.setState({ selectedType: TOOLS_TYPES[type] })}
                    >
                      {TOOLS_TYPES[type]}
                    </div>
                  );
                })}
              </div>
              {isStudentsAttendance && <StudentsAttendance />}
              {isTeacherAttendance && <TeacherAttendance />}
            </div>
          </div>
        ) : <StudentsAttendance quizId={quizId} />}
      </div>
    );
  }
}
const mapStateToProps = ({ virtualMeeting }) => ({
  virtualMeeting,
});
export default connect(mapStateToProps, {})(Attendance);
