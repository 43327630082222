import React from "react";
import Layout from "../layout";
import { isMobileDevice } from "../../helpers/util";
import MenuBar from "../menubar";
import BdeInstitutes from "./institutes";
import BdeDashboard from "./dashboard";

const IS_MOBILE = isMobileDevice();

const MENU_DATA = {
  DASHBOARD: { label: "Dashboard", route: "/bde_tools/dashboard" },
  INSTITUTES: { label: "Institutes", route: "/bde_tools/institutes" },
};

class BdeTools extends React.Component {
  constructor(props) {
    const { type } = props.match.params;
    super(props);
    this.state = { type };
  }

  componentDidMount() {}

  render() {
    const { history } = this.props;
    const { type, id } = this.props.match.params;

    const dataType = type && type.toUpperCase();
    const types = Object.keys(MENU_DATA);
    const index = types.indexOf(dataType);

    return (
      <Layout hideHeader globalHistory={history} isMobile={IS_MOBILE}>
        <MenuBar
          history={history}
          isQuestionBoard
          menuList={Object.values(MENU_DATA)}
          selectedOption={index}
          redirectToRoot
        />
        {type === "dashboard" && <BdeDashboard />}
        {type === "institutes" && <BdeInstitutes instituteId={id} />}
      </Layout>
    );
  }
}

export default BdeTools;
