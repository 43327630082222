import React, { Component } from 'react';
import { connect } from 'react-redux';
import trialExpired from '../../static/images/trial-expired.svg';
import Layout from '../layout';
import { showUserDetails, sendUserFeedback } from '../../actions';
import Constants from '../../shared/constants';
import Modal from '../modal/baseModal';

class AccessDenied extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showContactUs: false,
    };
    this.showContactInfo = this.showContactInfo.bind(this);
  }

  componentDidMount() {
    this.props.showUserDetails();
  }

  showContactInfo() {
    const showContactUs = !this.state.showContactUs;
    if (showContactUs) {
      this.props.sendUserFeedback(`Subscription Requested by - ${this.props.userName}`, 'Subscription Requested');
    }
    this.setState({ showContactUs });
  }

  render() {
    return (
      <Layout globalHistory={this.props.history}>
        <Modal showModal={this.state.showContactUs} handleCloseModal={this.showContactInfo} header="Response Submitted">
          <div className="m-5" style={{ fontSize: 16 }}>
            <div className="mb-4" style={{ fontSize: 20 }}>We'll call you soon with further information.</div>
            <div>
              You can also call us at
              <strong>{Constants().contact}</strong>
            </div>
            <div>
              or send an email to
              <strong>{Constants().email}</strong>
            </div>
            <div
              role="presentation"
              className="btn cursor mt-5"
              style={{
                backgroundColor: Constants().color.primary, color: 'white', fontSize: 24, padding: 10, width: 150, marginLeft: '30%',
              }}
              onClick={this.showContactInfo}
            >
              Dismiss
            </div>
          </div>
        </Modal>
        <div style={{
          position: 'fixed',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          textAlign: 'center',
          width: '100%',
        }}
        >
          <img alt="" className="center" src={trialExpired} width="15%" />
          <h3 className="mt-5">
            Hi
            {this.props.userName}
            ,
          </h3>
          <h3> Your Free Trail is over. Please subscribe to continue. </h3>
          <p
            role="presentation"
            className="btn cursor mt-5"
            style={{
              backgroundColor: Constants().color.primary, color: 'white', fontSize: 24, padding: 10, width: 250,
            }}
            onClick={this.showContactInfo}
          >
            Contact Us
          </p>
        </div>
      </Layout>
    );
  }
}

const mapStateToProps = ({ institute }) => ({
  userName: institute.userName,
});

export default connect(mapStateToProps, { showUserDetails, sendUserFeedback })(AccessDenied);
