import React from "react";
import { connect } from "react-redux";
import "./bdeTools.css";
import Loader from "../loading";
import Modal from "../modal/baseModal";
import Pagination from "react-js-pagination";
import Constants from "../../shared/constants";
import moment from "moment";
import PropTypes from "prop-types";
import {
  ExcelExport,
  ExcelExportColumn,
} from "@progress/kendo-react-excel-export";
import { getBdeDashboardDetails, getBdeDashboardSummary } from "../../actions";

class BdeDashboard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showModal: false,
      type: "",
      page: 1,
      perPage: 6,
      items: [],
      searchText: "",
      data: [],
    };
    this.dashboardDataSave = React.createRef();
  }

  componentDidMount() {
    this.props.getBdeDashboardSummary();
  }

  loadModalData(type) {
    this.setState({ showModal: true, type: type });
    this.props.getBdeDashboardDetails(type).then((resp) => {
      if (resp.success) {
        this.setState({
          items: this.props.dashboardData,
          showModal: true,
        });
      }
    });
  }

  renderCards() {
    const { dashboardSummary } = this.props;
    return (
      <div style={{ paddingTop: 30 }}>
        <div className="card-row">
          <div
            className="card"
            onClick={() => this.loadModalData("ACTIVE_INSTITUTES")}
          >
            <div className="card-title">Active Institutes</div>
            <div className="card-content">
              {dashboardSummary.activeInstitutesCount}
            </div>
          </div>
          <div
            className="card"
            onClick={() => this.loadModalData("ACTIVE_STUDENTS")}
          >
            <div className="card-title">Students B2B</div>
            <div className="card-content">
              {dashboardSummary.activeStudentsCount}
            </div>
          </div>
          <div
            className="card"
            onClick={() => this.loadModalData("ABOUT_TO_EXPIRE")}
          >
            <div className="card-title">About To Expire</div>
            <div className="card-content">
              {dashboardSummary.aboutToExpireDealsCount}
            </div>
          </div>
          <div
            className="card"
            onClick={() => this.loadModalData("EXPIRED_DEALS")}
          >
            <div className="card-title">Renewals</div>
            <div className="card-content">
              {dashboardSummary.expiredDealsCount}
            </div>
          </div>
          <div
            className="card"
            onClick={() => this.loadModalData("PAUSED_INSTITUTES")}
          >
            <div className="card-title">Paused</div>
            <div className="card-content">
              {dashboardSummary.pausedInstitutesCount}
            </div>
          </div>
        </div>
        <div className="card-row">
          <div className="card" onClick={() => this.loadModalData("DEALS")}>
            <div className="card-title">Deals Created</div>
            <div className="card-content">{dashboardSummary.dealsCount}</div>
          </div>
          <div className="card" onClick={() => this.loadModalData("DEALS_WON")}>
            <div className="card-title">Deals Won</div>
            <div className="card-content">{dashboardSummary.dealsWonCount}</div>
          </div>
          <div
            className="card"
            onClick={() => this.loadModalData("DEALS_PAUSED")}
          >
            <div className="card-title">Deals Paused</div>
            <div className="card-content">
              {dashboardSummary.dealsPendingCount}
            </div>
          </div>
          <div className="card" onClick={() => this.loadModalData("TESTS")}>
            <div className="card-title">Tests Created</div>
            <div className="card-content">{dashboardSummary.testsCount}</div>
          </div>
          <div
            className="card"
            onClick={() =>
              this.setState({
                showModal: true,
                type: "QUESTIONS",
                items: dashboardSummary.institutesWithLessQuestions,
              })
            }
          >
            <div className="card-title">{"Questions < 2000"}</div>
            <div className="card-content">
              {dashboardSummary.institutesWithLessQuestions.length}
            </div>
          </div>
        </div>
      </div>
    );
  }

  createExport() {
    const { type } = this.state;
    const fields =
      type === "ACTIVE_STUDENTS"
        ? ["Badge", "ID", "Name", "Classroom", "Campus"]
        : type === "ACTIVE_INSTITUTES" || type === "PAUSED_INSTITUTES"
        ? ["ID", "Name", "Contact"]
        : [
            "Deal_ID",
            "Institute ID",
            "Institute name",
            "Email",
            "Phone",
            "Subscription start date",
            "Subscription end date",
          ];
    const fieldIds =
      type === "ACTIVE_STUDENTS"
        ? ["badge_id", "id", "first_name", "classroom_name", "campus_name"]
        : type === "ACTIVE_INSTITUTES" || type === "PAUSED_INSTITUTES"
        ? ["id", "name", "phone"]
        : [
            "id",
            "institute_id",
            "name",
            "email",
            "phone",
            "subscription_start_date",
            "subscription_end_date",
          ];
    return {
      fields,
      fieldIds: fieldIds,
    };
  }

  renderDownloadButton() {
    return (
      <div
        role="presentation"
        className="Button"
        onClick={() => this.dashboardDataSave.current.save()}
        style={{
          fontWeight: "bold",
          color: "#3994AE",
          border: "1px solid #3994AE",
          width: 100,
        }}
      >
        Export
      </div>
    );
  }

  renderFilters() {
    const { searchText } = this.state;
    return (
      <input
        type="text"
        value={searchText}
        placeholder="Search ID/ Name"
        onChange={(event) =>
          this.setState({ searchText: event.target.value, page: 1 })
        }
        style={{ width: 300 }}
      ></input>
    );
  }

  renderModal() {
    const { showModal, type, page, perPage, items, searchText } = this.state;
    const { isModalDataLoading } = this.props;
    const metaData = this.createExport();
    const { fields } = metaData;
    const fieldIds = metaData.fieldIds;
    let filteredItems = !!searchText
      ? items.filter((item) => {
          return (
            (!!item.id && item.id == parseInt(searchText)) ||
            (!!item.name &&
              item.name.toLowerCase().includes(searchText.toLowerCase())) ||
            (!!item.badge_id &&
              item.badge_id.toLowerCase().includes(searchText.toLowerCase())) ||
            (!!item.first_name &&
              item.first_name.toLowerCase().includes(searchText.toLowerCase()))
          );
        })
      : items;
    filteredItems = filteredItems.map((item) => {
      return {
        ...item,
        email: item.email?.join(","),
        phone: item.phones ? item.phones.join(",") : item.phone,
      };
    });
    return (
      <Modal
        showModal={showModal}
        handleCloseModal={() =>
          this.setState({
            showModal: false,
            type: "",
            page: 1,
            searchText: "",
          })
        }
        header={type.replaceAll("_", " ")}
        width={"60%"}
        maxHeight={"90%"}
        minHeight={"20%"}
      >
        {isModalDataLoading ? (
          <div style={{ padding: 30 }}>
            <div style={{ display: "flex", justifyContent: "center" }}>
              Loading...
            </div>
          </div>
        ) : (
          <div style={{ padding: 30 }}>
            {this.renderFilters()}
            {(type === "ACTIVE_INSTITUTES" || type === "PAUSED_INSTITUTES") && (
              <table className="table-bordered">
                <thead>
                  <tr>
                    <th>S.No</th>
                    <th>#ID</th>
                    <th>Name</th>
                    <th>Contact</th>
                  </tr>
                </thead>
                <tbody>
                  {filteredItems
                    .slice((page - 1) * perPage, page * perPage)
                    .map((institute, index) => (
                      <tr>
                        <td>{(page - 1) * perPage + index + 1}</td>
                        <td>{institute.id}</td>
                        <td>{institute.name}</td>
                        <td>{institute.phone}</td>
                      </tr>
                    ))}
                </tbody>
              </table>
            )}
            {type === "ACTIVE_STUDENTS" && (
              <table className="table-bordered">
                <thead>
                  <tr>
                    <th>S.No</th>
                    <th>Badge ID</th>
                    <th>Name</th>
                    <th>Classroom</th>
                    <th>Campus</th>
                    <th>Institute ID</th>
                    <th>Institute Name</th>
                  </tr>
                </thead>
                <tbody>
                  {filteredItems
                    .slice((page - 1) * perPage, page * perPage)
                    .map((student, index) => (
                      <tr>
                        <td>{(page - 1) * perPage + index + 1}</td>
                        <td>{student.badge_id}</td>
                        <td>{student.first_name}</td>
                        <td>{student.classroom_name}</td>
                        <td>{student.campus_name}</td>
                        <td>{student.institute_id}</td>
                        <td>{student.institute_name}</td>
                      </tr>
                    ))}
                </tbody>
              </table>
            )}
            {(type === "ABOUT_TO_EXPIRE" ||
              type === "EXPIRED_DEALS" ||
              type === "DEALS" ||
              type === "DEALS_PAUSED" ||
              type === "QUESTIONS" ||
              type === "DEALS_WON") && (
              <table className="table-bordered">
                <thead>
                  <tr>
                    <th>S.No</th>
                    <th>#Deal_ID</th>
                    <th>Institute ID</th>
                    <th>Institute Name</th>
                    <th>Emails</th>
                    <th>Phone</th>
                    <th>Subscription start date</th>
                    <th>Subscription end date</th>
                  </tr>
                </thead>
                <tbody>
                  {filteredItems
                    .slice((page - 1) * perPage, page * perPage)
                    .map((deal, index) => {
                      return (
                        <tr>
                          <td>{(page - 1) * perPage + index + 1}</td>
                          <td>{deal.id}</td>
                          <td>{deal.institute_id}</td>
                          <td>{deal.name}</td>
                          <td>{deal.email}</td>
                          <td>{deal.phone}</td>
                          <td>
                            {deal.subscription_start_date
                              ? moment(deal.subscription_start_date).format(
                                  "DD/MM/YYYY"
                                )
                              : "-"}
                          </td>
                          <td>
                            {deal.subscription_end_date
                              ? moment(deal.subscription_end_date).format(
                                  "DD/MM/YYYY"
                                )
                              : "-"}
                          </td>
                        </tr>
                      );
                    })}
                </tbody>
              </table>
            )}
            {type === "TESTS" && (
              <table className="table-bordered">
                <thead>
                  <tr>
                    <th>S.No</th>
                    <th>#ID</th>
                    <th>Name</th>
                    <th>Type</th>
                    <th>Created at</th>
                  </tr>
                </thead>
                <tbody>
                  {filteredItems
                    .slice((page - 1) * perPage, page * perPage)
                    .map((test, index) => (
                      <tr>
                        <td>{(page - 1) * perPage + index + 1}</td>
                        <td>{test.id}</td>
                        <td>{test.name}</td>
                        <td>{test.is_module ? "Assignment" : "Test"}</td>
                        <td>{moment(test.created_at).format("DD-MM-YYYY")}</td>
                      </tr>
                    ))}
                </tbody>
              </table>
            )}
            <div style={{ display: "flex", justifyContent: "center" }}>
              <style>
                {`
          .pagination>li.active>a{
            background-color: ${Constants().color.primary} !important;
            color: #FFFFFF !important;
          }
          .pagination > li > a, .pagination > li > span{
            color: ${Constants().color.primary} !important;
          }
          .pagination > li > a:hover{
            background-color: #3d73ad !important;
            color: #FFFFFF !important;
          }
          `}
              </style>
              <Pagination
                activePage={page}
                itemsCountPerPage={perPage}
                totalItemsCount={filteredItems.length}
                pageRangeDisplayed={5}
                onChange={(pageNumber) => this.setState({ page: pageNumber })}
              />
            </div>
            {this.renderDownloadButton()}
          </div>
        )}
        <ExcelExport
          data={filteredItems}
          fileName="dashboardDetails.xlsx"
          ref={this.dashboardDataSave}
        >
          {fields.map((field, index) => (
            <ExcelExportColumn field={fieldIds[index]} title={field} />
          ))}
        </ExcelExport>
      </Modal>
    );
  }

  render() {
    if (this.props.isLoading) return <Loader />;
    return (
      <div style={{ marginLeft: "20%" }}>
        {this.renderCards()}
        {this.state.showModal && this.renderModal()}
      </div>
    );
  }
}

BdeDashboard.propTypes = {
  isLoading: PropTypes.bool,
  dashboardData: PropTypes.object,
  dashboardSummary: PropTypes.object,
};

BdeDashboard.defaultProps = {
  dashboardData: {},
  dashboardSummary: {},
};

const mapStateToProps = ({ bdeTools }) => ({
  dashboardData: bdeTools.dashboardData,
  dashboardSummary: bdeTools.dashboardSummary,
  isLoading: bdeTools.isLoading,
  isModalDataLoading: bdeTools.isDashboardMoadalDataLoading,
});

export default connect(mapStateToProps, {
  getBdeDashboardDetails,
  getBdeDashboardSummary,
})(BdeDashboard);
