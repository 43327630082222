import React, { Component } from 'react';
import { connect } from 'react-redux';
import ReactTooltip from 'react-tooltip';
import PropTypes from 'prop-types';
import Cookies from 'universal-cookie';
import {
  XAxis,
  YAxis,
  VerticalGridLines,
  HorizontalGridLines,
  LineMarkSeries,
  FlexibleWidthXYPlot,
} from 'react-vis';
import Constants from '../../shared/constants';
import Loader from '../loading';
import Modal from '../modal/baseModal';
import FeedbackTable from '../feedbackTable';
import FeedbackProgressBar from '../feedbackProgressBar';
import { ApplyFilter, DateRange, CustomAxisLabel } from '../common';
import { applyInstitueFilters, getReport, showToast } from '../../actions';
import LocaleDate from '../localeDate';
import '../../../node_modules/react-vis/dist/style.css';
import '../../styles/instituteOnBoarding.css';

const endingDate = new Date();
endingDate.setMonth(new Date().getMonth() - 1);
const yesterday = new Date();
yesterday.setDate((yesterday).getDate() - 1);
let SELECTED_RANGE = {
  startDate: endingDate,
  endDate: new Date(),
  key: 'selection',
};
const cookies = new Cookies();
const roles = cookies.get('roles');

class VirtualMeetingFeedback extends Component {
  constructor(props) {
    super(props);
    SELECTED_RANGE = {
      startDate: new Date(props.academicStartDate),
      endDate: new Date(),
      key: 'selection',
    };
    this.state = {
      showLoader: true,
      selectedRange: SELECTED_RANGE,
      showDateRange: false,
      showFilter: false,
      teacher: {},
      defaultDepartment: {},
      department: {},
      campus: {},
      startDate: new Date(props.academicStartDate),
      endDate: new Date(),
    };
    this.getReport = this.getReport.bind(this);
    this.selectDateRange = this.selectDateRange.bind(this);
    this.handleSelect = this.handleSelect.bind(this);
    this.showFilterIcons = this.showFilterIcons.bind(this);
    this.onMouseOverUpdateGraphPerformance = this.onMouseOverUpdateGraphPerformance.bind(this);
  }

  componentDidMount() {
    const { selectedRange } = this.state;
    this.props.applyInstitueFilters({
      selectedCampus: {},
      selectedClassroom: null,
      selectedDepartment: {},
      selectedSemester: {},
      selectedSubject: {},
      selectedTeacher: {},
    }).then(() => {
      this.props.getReport(selectedRange).then(() => {
        const { selectedDepartment } = this.props;
        this.setState({
          department: selectedDepartment,
          defaultDepartment: selectedDepartment,
        });
        this.setState(() => ({ showLoader: false }));
      });
    });
  }

  onMouseOverUpdateGraphPerformance(value, { index }) {
    const crosshairValuesPerformance = [value];
    this.setState({ crosshairValuesPerformance: [...crosshairValuesPerformance] });
  }

  getReport(date = null) {
    const {
      selectedClassroom, selectedCampus, selectedDepartment, selectedTeacher,
    } = this.props;
    const { selectedRange } = this.state;
    const finalDate = date || { ...selectedRange };
    this.setState({
      page: 1,
      showDateRange: false,
      showFilter: false,
      teacher: { ...selectedTeacher },
      department: { ...selectedDepartment },
      campus: { ...selectedCampus },
      classroom: { ...selectedClassroom },
      startDate: finalDate ? finalDate.startDate : endingDate,
      endDate: finalDate ? finalDate.endDate : new Date(),
      showLoader: true,
    });
    this.props.getReport(finalDate, (selectedClassroom && selectedClassroom.id) || '',
      (selectedCampus && selectedCampus.id) || '',
      (selectedDepartment && selectedDepartment.id) || '', (selectedTeacher && selectedTeacher.id) || '').then(() => {
      this.setState(() => ({ showLoader: false }));
    });
  }

  filterView() {
    const {
      campuses, filterClassrooms, departments, teachers,
      isHigherEducation, selectedCampus, selectedDepartment, selectedTeacher,
      selectedClassroom,
    } = this.props;
    const { showFilter, defaultDepartment } = this.state;
    return (
      <Modal
        header="Apply Filters"
        showModal={showFilter}
        handleCloseModal={() => this.setState({ showFilter: false })}
        style={{
          padding: 30, overflowY: 'initial', overflowX: 'initial', fontFamily: 'Nunito',
        }}
      >
        <ApplyFilter
          hideSubject
          hideClassroom
          hideSemester
          campuses={campuses}
          classrooms={filterClassrooms}
          hideDepartment={!roles.is_dean}
          departments={departments}
          hideTeacher={(roles.is_teacher && !roles.is_hod && !roles.is_dean) || !(selectedDepartment && selectedDepartment.id)}
          teachers={teachers}
          defaultDepartment={defaultDepartment}
          isHigherEducation={isHigherEducation}
          selectedCampus={{ ...selectedCampus }}
          selectedDepartment={{ ...selectedDepartment }}
          selectedClassroom={{ ...selectedClassroom }}
          selectedTeacher={{ ...selectedTeacher }}
          applyDataFilters={() => this.getReport()}
          ignoreRequired
        />
      </Modal>
    );
  }

  handleSelect(date) {
    this.setState({ selectedRange: { ...date.selection } });
  }

  selectDateRange() {
    const { selectedRange, showDateRange } = this.state;
    return (
      <Modal
        showModal={showDateRange}
        overrideCloseIcon
        handleCloseModal={() => this.setState({ showDateRange: false })}
        style={{
          padding: 30, overflowY: 'initial', overflowX: 'initial',
        }}
      >
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
          <DateRange
            showSelectionPreview={false}
            selectedRange={{ ...selectedRange }}
            color="#1c3e6b"
            maxDate={new Date()}
            showPreview={false}
            handleSelect={this.handleSelect}
          />
          <div
            className="Button"
            style={{ width: 150 }}
            role="presentation"
            onClick={() => this.getReport(selectedRange)}
          >
            SET
          </div>
        </div>
      </Modal>
    );
  }

  showFilterIcons() {
    return (
      <div style={{
        display: 'flex', width: 'fit-content', marginLeft: 'auto', marginBottom: 30,
      }}
      >
        {!(roles.is_teacher && !roles.is_hod && !roles.is_dean) && (
        <div
          role="presentation"
          className="Button"
          style={{ width: 150, height: 40, marginRight: 30 }}
          onClick={() => this.setState({ showFilter: true })}
        >
          Filters
        </div>
        )}
        <div
          role="presentation"
          className="Button"
          style={{ width: 150, height: 40 }}
          onClick={() => this.setState({ showDateRange: true })}
        >
          Date Range
        </div>
      </div>
    );
  }

  noDataHeader(text = 'No feedback yet!') {
    return (
      <div style={{
        display: 'flex', justifyContent: 'center', alignItems: 'center', color: '#454545', height: 80, backgroundColor: '#FFFFFF', marginTop: 100, border: '1px solid', fontSize: 24,
      }}
      >
        {text}
      </div>
    );
  }

  renderFeedbackRatingGraph() {
    const { teacherFeedbackRating } = this.props;
    return (
      <div style={{
        marginTop: 30, marginBottom: 40, display: 'flex', flexDirection: 'column', alignItems: 'center', width: 'fit-content', height: 330, marginLeft: 'auto',
      }}
      >
        <FlexibleWidthXYPlot height={330} width={500} yDistance="1" yDomain={[0, 5]} xType="ordinal" margin={{ left: 80, bottom: 60 }}>
          <HorizontalGridLines style={{ stroke: '#e6e6e9' }} />
          <VerticalGridLines style={{ stroke: '#e6e6e9' }} />
          <XAxis
            tickLabelAngle={-28}
            style={{
              text: {
                stroke: 'none',
                fill: '#1d4c7fBF',
                fontWeight: 500,
              },
              ticks: { fontSize: 14, fontWeight: 'bold' },
            }}
          />
          <YAxis title="" style={{ ticks: { fontWeight: 'bold', fontSize: 14, color: Constants().color.primary } }} />
          <CustomAxisLabel title="Avg Rating" style={{ fontSize: 14 }} />
          <LineMarkSeries
            data={teacherFeedbackRating}
            style={{
              strokeLinejoin: 'round',
              strokeWidth: 3,
              fill: 'none',
            }}
            color="#F2A46F"
            fill="#F2A46F"
            size={2}
            onNearestX={this.onMouseOverUpdateGraphPerformance}
          />
        </FlexibleWidthXYPlot>
        <div style={{ textAlign: 'center', marginBottom: 30, marginTop: 20 }}>Date Range</div>
      </div>
    );
  }

  renderDate(date) {
    if (date.toDateString() === new Date().toDateString()) {
      return (
        <div>today&nbsp;</div>
      );
    }
    if (date.toDateString() === yesterday.toDateString()) {
      return (
        <div>yesterday&nbsp;</div>
      );
    }
    return (
      <div>
        <LocaleDate date={date} />
        &nbsp;
      </div>
    );
  }

  renderDateStatus() {
    const { startDate, endDate } = this.state;
    const { academicStartDate, isHigherEducation } = this.props;
    const thisMonthStart = new Date();
    thisMonthStart.setDate(1);
    const lastMonthStart = new Date();
    lastMonthStart.setDate(1);
    lastMonthStart.setMonth(lastMonthStart.getMonth() - 1);
    const thisMonthEnd = new Date(new Date().getFullYear(), new Date().getMonth() + 1, 0);
    const lastMonthEnd = new Date(new Date().getFullYear(), new Date().getMonth(), 0);
    const thisWeekStart = new Date(new Date().setDate(new Date().getDate() - new Date().getDay()));
    const thisWeekEnd = new Date(new Date().setDate(new Date().getDate() - new Date().getDay() + 6));
    const lastWeek = new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate() - 7);
    const lastWeekStart = new Date(lastWeek.setDate(lastWeek.getDate() - lastWeek.getDay()));
    const lastWeekEnd = new Date(lastWeek.setDate(lastWeek.getDate() - lastWeek.getDay() + 6));
    if (startDate.toDateString() === endDate.toDateString()) {
      return (
        <div style={{ display: 'flex' }}>
          On&nbsp;
          {this.renderDate(startDate)}
        </div>
      );
    }
    if (new Date(academicStartDate).toDateString() === startDate.toDateString()
    && endDate.toDateString() === new Date().toDateString()) {
      return (
        <div>
          For&nbsp;
          {isHigherEducation ? 'This Semester' : 'This Academic Year'}
        </div>
      );
    }
    if (startDate.toDateString() === thisMonthStart.toDateString()
    && endDate.toDateString() === thisMonthEnd.toDateString()) {
      return (
        <div>
          on this month
        </div>
      );
    }
    if (startDate.toDateString() === lastMonthStart.toDateString()
    && endDate.toDateString() === lastMonthEnd.toDateString()) {
      return (
        <div>
          on last month
        </div>
      );
    }
    if (startDate.toDateString() === thisWeekStart.toDateString()
    && endDate.toDateString() === thisWeekEnd.toDateString()) {
      return (
        <div>
          on this week
        </div>
      );
    }
    if (startDate.toDateString() === lastWeekStart.toDateString()
    && endDate.toDateString() === lastWeekEnd.toDateString()) {
      return (
        <div>
          on last week
        </div>
      );
    }
    return (
      <div style={{ display: 'flex', alignItems: 'center' }}>
        {startDate && (
        <div style={{ display: 'flex' }}>
          from&nbsp;
          {this.renderDate(startDate)}
        </div>
        )}
        {endDate && (
        <div style={{ display: 'flex' }}>
          to&nbsp;
          {this.renderDate(endDate)}
        </div>
        )}
      </div>
    );
  }

  renderFilterHeader() {
    const { isHigherEducation } = this.props;
    const {
      teacher, campus,
    } = this.state;
    const style = {
      display: 'flex', alignItems: 'center', width: 'max-content', paddingBottom: 2, fontWeight: 500, color: Constants().color.primary, fontSize: 24,
    };
    const isTeacherData = teacher && teacher.id && teacher.name;
    if (isHigherEducation) {
      return (
        <div style={{
          ...style,
        }}
        >
          <div> Feedback&nbsp;</div>
          {isTeacherData && (
          <div>
            for&nbsp;
            {teacher.name}
            &nbsp;
          </div>
          )}
          {this.renderDateStatus()}
        </div>
      );
    }
    return (
      <div style={{
        ...style,
      }}
      >
        <div>Feedback&nbsp;&nbsp;</div>
        {campus && campus.id && (
        <div>
          For&nbsp;&nbsp;
          {campus.name}
        &nbsp;
        </div>
        )}
        {this.renderDateStatus()}
      </div>
    );
  }

  renderTable() {
    const {
      feedbacks, isAnonymousFeedback, isHigherEducation,
    } = this.props;
    return (
      <div style={{ marginTop: 20, marginBottom: 40 }}>
        {isHigherEducation && (
        <div style={{
          display: 'flex', marginBottom: 60, boxShadow: '1px 2px 6px #8B9DAF26', background: '#FFFFFF', padding: '30px 20px',
        }}
        >
          <div style={{ width: '40%' }}>
            {this.renderWidgetGraph()}
          </div>
          <div style={{ width: '60%' }}>
            {this.renderFeedbackRatingGraph()}
          </div>
        </div>
        )}
        <ReactTooltip effect="solid" />
        <FeedbackTable feedbacks={feedbacks} isAnonymousFeedback={!isAnonymousFeedback && roles.is_Dean} />
      </div>
    );
  }

  renderTeacherList(department, counter) {
    return (
      <tr style={{ fontSize: '14px', height: 50 }}>
        <td style={{
          width: '5%', verticalAlign: 'middle', paddingLeft: 30,
        }}
        >
          {counter + 1}

        </td>
        <td style={{ width: '10%', verticalAlign: 'middle', paddingLeft: 30 }}>{department.name}</td>
        <td
          style={{ width: '10%', verticalAlign: 'middle', paddingLeft: 30 }}
        >
          {department.no_of_ratings}
        </td>
        <td style={{ width: '30%', verticalAlign: 'middle', paddingLeft: 30 }}>{department.average}</td>
      </tr>
    );
  }

  renderDepartmentTable() {
    const { departmentData } = this.props;
    const style = {
      borderTop: 'none', textAlign: 'left', paddingLeft: 30, border: '1px solid #c5c6c7', borderRight: '1px solid #dedfe0', whiteSpace: 'nowrap', width: '25%',
    };
    if (departmentData && departmentData.length > 0) {
      return (
        <div style={{ marginTop: 20 }}>
          <ReactTooltip effect="solid" />
          <table
            className="table table-hover"
            style={{
              boxShadow: '1px 2px 6px #8B9DAF33', borderRadius: 4, backgroundColor: '#FFFFFF', border: '1px solid #CDCFD6', marginTop: 30,
            }}
          >
            <thead style={{ background: '#F6F8FA', color: '#23394C' }}>
              <tr
                className="table-header"
                style={{
                  fontSize: '18px', fontWeight: '700', height: 60, color: '#11426C', width: '100%', borderRight: '1px solid #DFE3EA',
                }}
              >
                <th
                  className="table-header"
                  style={{
                    ...style, paddingLeft: 30, width: '10%',
                  }}
                />
                <th
                  className="table-header"
                  style={style}
                >
                  Name
                </th>
                <th
                  className="table-header"
                  style={style}
                >
                  No of ratings
                </th>
                <th
                  className="table-header"
                  style={{
                    ...style,
                  }}
                >
                  Average rating
                </th>
              </tr>
            </thead>
            <tbody>
              {departmentData.map((department, index) => this.renderTeacherList(department, index))}
            </tbody>
          </table>
        </div>
      );
    }
    return this.noDataHeader();
  }

  renderContent() {
    const { department, teacher } = this.state;
    const { isHigherEducation } = this.props;
    if ((department && department.id) && !(teacher && teacher.id) && isHigherEducation) {
      return this.renderDepartmentTable();
    }
    if (roles.is_teacher || (teacher && teacher.id) || !isHigherEducation) {
      return this.renderTable();
    }
    return this.noDataHeader('Select Department');
  }

  renderWidgetGraph() {
    const { feedbackWidgetData } = this.props;
    return <FeedbackProgressBar feedbackWidgetData={feedbackWidgetData} />;
  }

  render() {
    const { showLoader } = this.state;
    if (showLoader) {
      return <Loader />;
    }
    let innerHeight = null;
    if (typeof window !== 'undefined') {
      innerHeight = window.innerHeight;
    }
    return (
      <div style={{
        background: '#F5F8FA', height: typeof window !== 'undefined' && window.innerHeight, fontFamily: 'Roboto', color: Constants().color.primary,
      }}
      >
        {this.selectDateRange()}
        {this.filterView()}
        <div style={{
          fontWeight: 600, fontSize: 28, padding: '0 60px', height: 70, display: 'flex', alignItems: 'center', background: '#FFFFFF', color: Constants().color.primary, boxShadow: '0px 3px 6px #343B4014', position: 'fixed', width: '100%', zIndex: 1000,
        }}
        >
          Student Feedback
        </div>
        <div style={{
          padding: '25px 65px', backgroundColor: '#F5F8FA', width: '100%', height: innerHeight, paddingTop: 100,
        }}
        >
          {this.showFilterIcons()}
          {this.renderFilterHeader()}
          {this.renderContent()}
        </div>
      </div>
    );
  }
}

VirtualMeetingFeedback.propTypes = {
  isAnonymousFeedback: PropTypes.bool,
  selectedClassroom: PropTypes.object,
  selectedDepartment: PropTypes.object,
  selectedCampus: PropTypes.object,
  campuses: PropTypes.array,
  filterClassrooms: PropTypes.array,
  departments: PropTypes.array,
  departmentData: PropTypes.array,
  teachers: PropTypes.array,
  isHigherEducation: PropTypes.bool,
  selectedTeacher: PropTypes.object,
  feedbackWidgetData: PropTypes.object,
  teacherFeedbackRating: PropTypes.array,
  feedbacks: PropTypes.array,
  academicStartDate: PropTypes.string,
};

VirtualMeetingFeedback.defaultProps = {
  isAnonymousFeedback: false,
  selectedClassroom: {},
  selectedDepartment: {},
  teacherFeedbackRating: [],
  departmentData: [],
  selectedCampus: {},
  campuses: [],
  filterClassrooms: [],
  departments: [],
  teachers: [],
  isHigherEducation: false,
  feedbackWidgetData: {},
  academicStartDate: '',
  selectedTeacher: {},
  feedbacks: [],
};

const mapStateToProps = ({ virtualMeeting }) => ({
  isAnonymousFeedback: virtualMeeting.isAnonymousFeedback,
  selectedClassroom: virtualMeeting.selectedClassroom,
  selectedDepartment: virtualMeeting.selectedDepartment,
  selectedCampus: virtualMeeting.selectedCampus,
  campuses: virtualMeeting.campuses,
  selectedSemester: virtualMeeting.selectedSemester,
  filterClassrooms: virtualMeeting.filterClassrooms,
  departments: virtualMeeting.departments,
  semesters: virtualMeeting.semesters,
  teacherFeedbackRating: virtualMeeting.teacherFeedbackRating,
  teachers: virtualMeeting.teachers,
  isHigherEducation: virtualMeeting.isHigherEducation,
  selectedTeacher: virtualMeeting.selectedTeacher,
  feedbacks: virtualMeeting.feedbacks,
  feedbackWidgetData: virtualMeeting.feedbackWidgetData,
  departmentData: virtualMeeting.departmentData,
  academicStartDate: virtualMeeting.academicStartDate,
});

export default connect(mapStateToProps, {
  getReport,
  showToast,
  applyInstitueFilters,
})(VirtualMeetingFeedback);
