import {
  INSTITUTE_DETAILS, USER_DETAILS, MEMBER_DETAILS, SUBJECT_DETAILS,
  CAMPUSES_CLASSROOMS, CLASSROOMS_AND_STUDENTS, UPDATE_STUDENT_DETAILS,
  PERMISSION_DENIED, TEST_LOCK_OTP, SAVE_STUDENT_DETAILS, TEACHER_DETAILS,
  GET_TEST_GROUP_DATA, GET_TEAM_MANAGEMENT, GET_CAMPUS_FOR_INSTITUTE,
} from '../actions';

const initialState = {
  instituteName: '',
  instituteLogo: '',
  instituteEmail: '',
  smsCountNeeded: '',
  phone: '',
  address: '',
  academicYearStartDate: '',
  mailingList: '',
  watermarkText: '',
  watermarkImage: '',
  campuses: [],
  classrooms: [],
  courses: [],
  errors: [],
  verification: [],
  campusesClassrooms: [],
  departmentCourseClassrooms: [],
  classroomsAndStudents: [],
  studentDetails: [],
  adminFirstName: '',
  adminLastName: '',
  adminEmail: '',
  adminPhone: '',
  permissionedDenied: false,
  preferences: {},
  studentDetailsLength: 0,
  studentDetailsSave: [],
  studentsMembershipIds: [],
  isZoomIntegrated: false,
  attendeesCount: 0,
  departments: [],
  subjectData: {},
  deptCourses: [],
  isHigherEducationInstitute: false,
  teacherDetails: [],
  teacherDetailsLength: 0,
  costPerSms: 0,
  teacherDetailsSave: {},
  testGroupDetails: [],
  subjectList: {},
  teacherMembershipIds: [],
  subjects: [],
  electives: {},
  subjectDetailsSave: {},
  remainingSmsCount: 0,
  testTypes: [],
  smsDetails: [],
  smsUsed: 0,
  isMeetThroughZoom: false,
  canSendBulkCredentials: false,
  hasFeeBoardAccess: false,
  isAccountant: false,
  isBusinessDeveloper: false,
  institutesTags: [],
  institutesTagGroups: [],
  studentDetailsFb: [],
  hasAdmissionBoardAccess: false
};

export default function (state = initialState, action) {
  switch (action.type) {
    case INSTITUTE_DETAILS: {
      return { ...state, ...action.payload };
    }
    case USER_DETAILS: {
      return {
        ...state,
        userName: action.userName,
        newUser: action.newUser,
        gender: action.gender,
        hasContentBoardAccess: action.contentBoardAccess,
        hasFeeBoardAccess: action.feeBoardAccess,
        isDtpAdmin: action.isDtpAdmin,
        isAccountant: action.isAccountant,
        isBusinessDeveloper: action.isBusinessDeveloper,
        hasAdmissionBoardAccess: action.hasAdmissionBoardAccess,
      };
    }
    case MEMBER_DETAILS: {
      return {
        ...state,
        campuses: action.campuses,
        courses: action.courses,
        classrooms: action.classrooms,
        studentDetails: action.studentDetails,
        preferences: action.preferences,
        studentDetailsLength: action.studentDetailsLength,
        studentsMembershipIds: action.studentsMembershipIds,
        departments: action.departments,
        isHigherEducationInstitute: action.isHigherEducationInstitute,
        electives: action.electives,
        canSendBulkCredentials: action.canSendBulkCredentials,
        institutesTags: action.institutesTags,
        institutesTagGroups: action.institutesTagGroups,
        isAdmin: action.isAdmin
      };
    }
    case GET_TEAM_MANAGEMENT: {
      return { ...state, ...action.payload };
    }
    case GET_TEST_GROUP_DATA: {
      return { ...state, ...action.payload };
    }
    case GET_CAMPUS_FOR_INSTITUTE: {
      return { ...state, ...action.payload };
    }
    case TEACHER_DETAILS: {
      return { ...state, ...action.payload };
    }
    case SUBJECT_DETAILS: {
      return {
        ...state,
        subjectData: action.subjectData,
        subjectDetailsSave: action.subjectDetailsSave,
        subjectDetailsLength: action.subjectDetailsLength,
        courses: action.courses,
        campuses: action.campuses,
        classrooms: action.classrooms,
        departments: action.departments,
        deptCourses: action.deptCourses,
        isHigherEducationInstitute: action.isHigherEducationInstitute,
      };
    }
    case CAMPUSES_CLASSROOMS: {
      return {
        ...state,
        campusesClassrooms: action.campusesClassrooms,
        departmentCourseClassrooms: action.departmentCourseClassrooms,
        isHigherEducationInstitute: action.isHigherEducationInstitute,
      };
    }
    case PERMISSION_DENIED: {
      return {
        ...state,
        permissionedDenied: action.status,
      };
    }
    case TEST_LOCK_OTP: {
      return {
        ...state,
        testLockOtp: action.otp,
      };
    }
    case UPDATE_STUDENT_DETAILS: {
      return {
        ...state,
        studentDetails: [
          ...state.studentDetails.slice(0, action.payload.index - 1),
          { ...state.studentDetails[action.payload.index], ...action.payload.data },
          ...state.studentDetails.slice(action.payload.index + 1),
        ],
      };
    }
    case CLASSROOMS_AND_STUDENTS: {
      return {
        ...state,
        classroomsAndStudents: action.classroomsAndStudents,
      };
    }
    case SAVE_STUDENT_DETAILS: {
      return {
        ...state,
        studentDetailsSave: action.studentDetailsSave,
      };
    }
    default:
      return state;
  }
}
