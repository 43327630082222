import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Cookies from 'universal-cookie';
import { logout, updateImage, getUserTypes } from '../../actions';
import Modal from '../modal/baseModal';
import Feedback from '../ClassboardFeedback';
import Help from '../help';
import info from '../../static/images/info.svg';
import uploadIssues from '../../static/images/upload_issues.svg';
import Constants from '../../shared/constants';
import { isMobileDevice } from '../../helpers/util';

const cookies = new Cookies();
const feedbackStyle = {
  height: 28, display: 'flex', alignItems: 'center', padding: '0 15px', borderRadius: 25, cursor: 'pointer', fontSize: 13, color: '#FBFBFB', whiteSpace: 'nowrap', background: '#025786', fontWeight: 'bold',
};

const IS_MOBILE = isMobileDevice();

class Menubar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      hoverMenuIndex: false,
      showUploadImageModal: false,
      userPhoto: '',
      showFeedBack: false,
      showHelp: false,
      isProfilePicturePresent: false,
    };
    this.logout = this.logout.bind(this);
    this.handleCloseUploadImageModal = this.handleCloseUploadImageModal.bind(this);
  }

  componentDidMount() {
    this.props.getUserTypes().then(() => this.setState({ isProfilePicturePresent: this.props.userDetails.picture && this.props.userDetails.picture.url !== 'default_female.jpg' }));
  }

  handleCloseUploadImageModal() {
    this.setState({ showUploadImageModal: false, isProfilePicturePresent: this.props.userDetails.picture && this.props.userDetails.picture.url !== 'default_female.jpg' });
  }

  showLoader(loaderStyles) {
    return (
      <div
        className="loader"
        style={{
          width: 20, height: 20, borderWidth: 3, borderTopWidth: 2, borderBottomWidth: 2, ...loaderStyles,
        }}
      />
    );
  }

  handleClick() {
    const { userPhoto, isProfilePicturePresent } = this.state;
    if (isProfilePicturePresent) {
      this.props.updateImage(true).then(() => this.setState({ isProfilePicturePresent: false, userPhoto: '' }));
    } else {
      this.props.updateImage(false, userPhoto).then(() => this.setState({ isProfilePicturePresent: true }));
    }
  }

  toggleSideBar() {
    const sidebar = document.querySelector('.sidebar');
    sidebar.classList.toggle('active');
  }

  updateImage() {
    const { userPhoto, isProfilePicturePresent } = this.state;
    const { userDetails, isUpdating } = this.props;

    if (isUpdating) {
      return this.showLoader({ margin: '130px auto auto auto' });
    }
    if (userPhoto || isProfilePicturePresent) {
      return (
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          <div style={{
            height: 'auto', width: 'fit-content', textAlign: 'center', margin: '10px auto auto auto',
          }}
          >
            <img
              alt="profile_picture"
              src={isProfilePicturePresent ? userDetails.picture.url : URL.createObjectURL(userPhoto)}
              style={{
                height: 150, width: 150, borderRadius: 75,
              }}
            />
            <div
              className="Button Secondary-Button"
              role="presentation"
              style={{ margin: '20px 20px 0px 20px', width: 100 }}
              onClick={() => this.setState({ userPhoto: '', isProfilePicturePresent: false })}
            >
              Clear
            </div>
          </div>
          <div role="presentation" className="Button Primary-Button" style={{ width: 350, margin: '20px auto auto auto' }} onClick={() => this.handleClick()}>{isProfilePicturePresent ? 'Remove' : 'Update'}</div>
        </div>
      );
    }
    return (
      <>
        <div
          role="presentation"
          style={{
            display: 'flex', flexDirection: 'column', borderRadius: 5, height: 220, width: 550, border: '1px solid #A2A2A2', cursor: 'pointer', margin: 'auto',
          }}
          onClick={() => document.getElementById('image-input').click()}
        >
          <input
            style={{
              display: 'none',
            }}
            id="image-input"
            type="file"
            accept="image/*"
            onChange={(event) => this.setState({ userPhoto: event.target.files[0] })}
          />
          <img src={uploadIssues} alt="upload_image" style={{ marginTop: 80 }} />
          <div style={{ textAlign: 'center', color: '#454545' }}>Drag or browse to upload your profile picture.</div>
        </div>
        <div style={{ display: 'flex', margin: '10px 0px 0px 10px' }}>
          <img src={info} alt="info" />
          <div style={{ marginLeft: 5, color: '#454545' }}>Upload only .jpg or .png files</div>
        </div>
      </>
    );
  }

  logout() {
    const { feedback } = this.props;
    return (
      <div
        role="presentation"
        onClick={() => { this.props.logout(); }}
        style={{
          color: '#8D9FB4', padding: 20, fontWeight: '400', fontSize: feedback && feedback.url ? 18 : 16, cursor: 'pointer', letterSpacing: 0.2, paddingLeft: 32,
        }}
      >
        Log out
      </div>
    );
  }

  routePage(link, type = '') {
    const { testId, history, intimateOnChange } = this.props;
    if (testId) {
      history.push(`${link}${testId}`);
    } else {
      history.push(link);
    }
    if (intimateOnChange) {
      this.props.onChange(type);
    }
  }

  showMenu(menu, index) {
    const { hoverMenuIndex } = this.state;
    const { selectedOption } = this.props;
    const isSelected = selectedOption === index;
    const isHover = hoverMenuIndex === index;
    let textStyle = {
      color: isHover ? '#fbfbfb' : '#8D9FB4', fontWeight: '400', fontSize: 16, marginBottom: 0, letterSpacing: 0.2, paddingLeft: 25, paddingTop: 12, paddingBottom: 12,
    };
    let boxStyle = {
      backgroundColor: isHover ? '#003b61' : '', justifyContent: 'center', cursor: 'pointer', borderRadius: 2, marginTop: 5, marginBottom: 5, ':hover': { backgroundColor: 'red' },
    };
    if (isSelected) {
      textStyle = {
        color: '#fbfbfb', fontSize: 16, marginBottom: 0, fontWeight: '600', letterSpacing: 0.2, paddingLeft: 25, paddingTop: 12, paddingBottom: 12,
      };
      boxStyle = {
        backgroundColor: '#003b61', justifyContent: 'end', cursor: 'pointer', borderRadius: 2, marginTop: 5, marginBottom: 5,
      };
    }
    return (
      <div
        role="presentation"
        onMouseEnter={() => this.setState({ hoverMenuIndex: index })}
        onMouseLeave={() => this.setState({ hoverMenuIndex: false })}
        onClick={() => { this.routePage(menu.route, menu.type); }}
        key={index}
        style={boxStyle}
      >
        <div style={{
          display: 'flex', height: 50, alignItems: 'center',
        }}
        >
          <div style={{ backgroundColor: isSelected ? '#007fd2' : (isHover && '#003b61'), width: 7, height: '100%' }} />
          <div style={textStyle}>{menu.label}</div>
        </div>
      </div>
    );
  }

  openFeedbackModalView() {
    this.setState({ showFeedBack: true });
  }

  openHelpModalView() {
    this.setState({ showHelp: true });
  }

  renderUploadImageModal(showUploadImageModal) {
    const { userPhoto, isProfilePicturePresent } = this.state;
    return (
      <Modal
        header="Upload Photo"
        showModal={showUploadImageModal}
        handleCloseModal={this.handleCloseUploadImageModal}
        height={userPhoto || isProfilePicturePresent ? 330 : 310}
        width={userPhoto || isProfilePicturePresent ? 400 : 600}
      >
        {this.updateImage()}
      </Modal>
    );
  }

  renderMenu() {
    const user = cookies.get('user');
    const { showUploadImageModal, isProfilePicturePresent } = this.state;
    const {
      menuList, redirectToRoot, headerText, redirectTo, renderHeader, feedback, showProfileIcon, userData, userDetails,
    } = this.props;
    const { showFeedBack, showHelp } = this.state;
    const isUserData = userData && (userData.badge_id || userData.title);
    const isUserName = userData && (userData.first_name || userData.last_name);
    const link = redirectToRoot ? '/' : redirectTo || '/test_overview/';
    const imageSource = isProfilePicturePresent ? userDetails.picture.url : '../../../static/images/user.svg';
    return (
      <div style={{
        display: 'flex', flexDirection: 'column', minHeight: '100vh', backgroundColor: Constants().color.sideBar,
      }}
      >
        <Modal showModal={showFeedBack} handleCloseModal={() => this.setState({ showFeedBack: false })} style={{ padding: 30, borderRadius: 0 }} width="410px">
          <Feedback isClassboardProduct handleCloseModal={() => this.setState({ showFeedBack: false })} />
        </Modal>
        <Modal header="Contact Us" showModal={showHelp} handleCloseModal={() => this.setState({ showHelp: false })} style={{ padding: 30, borderRadius: 10 }} width="370px">
          <Help />
        </Modal>
        <div>
          {showProfileIcon ? (
            <div style={{
              display: 'flex', flexDirection: 'column', alignItems: 'center', marginTop: 30, color: '#8D9FB4',
            }}
            >
              <img role="presentation" src={imageSource} alt="profile_pic" height="80px" width="80px" style={{ cursor: 'pointer', borderRadius: 50 }} onClick={() => this.setState({ showUploadImageModal: true })} />
              {isUserName && (
              <div style={{ marginTop: 10, marginBottom: !userData && 30, color: '#8D9FB4' }}>
                {((isUserData && `${userData.first_name ? userData.first_name : ''} ${userData.last_name ? userData.last_name : ''}`)) || (user && user.name) || ''}
              </div>
              )}
              {isUserData && (
              <div style={{
                marginTop: 10, marginBottom: 30, display: 'flex', alignItems: 'center',
              }}
              >
                {userData.badge_id && <div style={{ marginRight: 6, fontSize: 14 }}>{userData.badge_id}</div>}
                {(userData.badge_id && userData.title) && <div style={{ marginRight: 6, fontSize: 18 }}>|</div>}
                {userData.title && <div style={{ marginRight: 6, fontSize: 14 }}>{userData.title}</div>}
              </div>
              )}
            </div>
          )
            : (
              <div
                role="presentation"
                style={{
                  color: 'rgb(232, 50, 66)', padding: '20px 0px 20px 30px', fontSize: 28, fontWeight: 'bolder', userSelect: 'none', cursor: 'pointer', display: 'flex',
                }}
                onClick={() => this.routePage(link)}
              >
                {(redirectToRoot || renderHeader) && headerText.first }
                <div style={{ color: '#fff', fontWeight: 'bold', marginLeft: 5 }}>{(redirectToRoot || renderHeader) && headerText.last }</div>
              </div>
            )}
          {menuList.map((menu, index) => this.showMenu(menu, index))}
        </div>
        {feedback
          ? (
            <div
              style={{
                display: 'flex', flexDirection: 'column', color: '#FFFFFF',
              }}
            >
              {this.logout()}
              <div style={{
                display: 'flex', justifyContent: 'space-between', width: '100%', padding: 30, background: '#003D60',
              }}
              >
                <div
                  role="presentation"
                  style={feedbackStyle}
                  onClick={() => this.openFeedbackModalView()}
                >
                  Give FeedBack
                </div>
                <div
                  role="presentation"
                  style={feedbackStyle}
                  onClick={() => this.openHelpModalView()}
                >
                  Help
                </div>
              </div>
            </div>
          )
          : (
            <div style={{
              display: 'flex', flexDirection: 'column', alignItems: 'center',
            }}
            >
              <div style={{
                width: 60, height: 60, padding: 5, borderRadius: 30, background: '#FFFFFF',
              }}
              >
                <div style={{
                  width: 50, height: 50, background: '#4F5F6F', position: 'absolute', borderRadius: 25, display: 'flex', justifyContent: 'center', alignItems: 'center', color: '#FFFFFF',
                }}
                >
                  { user ? user.name.charAt(0) : ''}
                </div>
              </div>
              {this.logout()}
            </div>
          )}
        {showUploadImageModal && this.renderUploadImageModal(showUploadImageModal)}
      </div>
    );
  }

  render() {
    return (
      !IS_MOBILE ? (
        <div style={{
          minWidth: '20%', backgroundColor: Constants().color.sideBar, minHeight: '100vh', position: 'fixed', padding: 0, display: 'flex', justifyContent: 'space-between', flexDirection: 'column', userSelect: 'none',
        }}
        >
          {this.renderMenu()}
        </div>
      ) : (
        <div>
          <i
            className="fa fa-bars"
            onClick={() => this.toggleSideBar()}
            style={{
              fontSize: 20, color: Constants().color.sideBar, position: 'absolute', top: 20, left: 20, zIndex: 15,
            }}
          />

          <div
            style={{
              top: 0, left: 0, overflow: 'scroll', height: '100vh', width: '70%', position: 'fixed', zIndex: 20,
            }}
            className="sidebar"
          >
            <div
              onClick={() => this.toggleSideBar()}
              style={{
                color: '#FFFFFF',
              }}
            >
              <i
                className="fa fa-chevron-left"
                style={{
                  fontSize: 20, color: '#FFFFFF', position: 'absolute', top: 20, left: 20,
                }}
              />

              {this.renderMenu()}
            </div>
          </div>

        </div>

      )
    );
  }
}

Menubar.propTypes = {
  menuList: PropTypes.array,
  selectedOption: PropTypes.number,
  redirectToRoot: PropTypes.bool,
  testId: PropTypes.number,
  renderHeader: PropTypes.bool,
  feedback: PropTypes.object,
  redirectTo: PropTypes.string,
  showProfileIcon: PropTypes.bool,
  headerText: PropTypes.object,
  userData: PropTypes.object,
  userDetails: PropTypes.object,
  isUpdating: PropTypes.bool,
  intimateOnChange: PropTypes.bool,
};

Menubar.defaultProps = {
  menuList: [],
  renderHeader: false,
  showProfileIcon: false,
  feedback: {},
  testId: null,
  selectedOption: 0,
  redirectToRoot: false,
  redirectTo: null,
  intimateOnChange: false,
  userData: {},
  userDetails: {},
  headerText: { first: 'IDEA', last: 'BOARD' },
  isUpdating: false,
};

const mapStateToProps = ({ auth }) => ({
  userDetails: auth.userDetails,
  isUpdating: auth.isUpdating,
});

export default connect(mapStateToProps, { logout, updateImage, getUserTypes })(Menubar);
