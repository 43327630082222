import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import moment from 'moment';
import Pagination from 'react-js-pagination';
import { CustomizedFilter } from '../common';

const PAGE_COUNT = 5;
const RATING_TYPES = { Bad: [1, 2], Average: [3], Good: [4, 5] };

class FeedbackTable extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      page: 1,
      selectedFilter: {},
    };
    this.renderPagination = this.renderPagination.bind(this);
    this.applyFilter = this.applyFilter.bind(this);
  }

  getSelectedFeedbacks() {
    const { feedbacks } = this.props;
    const { selectedFilter } = this.state;
    const filter = selectedFilter && selectedFilter.value;
    let filteredData = [];
    filteredData = feedbacks.filter((feedback) => {
      return (!filter || RATING_TYPES[selectedFilter.label].includes(feedback.rating));
    });
    return filteredData;
  }

  applyFilter() {
    const { selectedFilter } = this.state;
    const filterItems = [];
    Object.keys(RATING_TYPES).map((type, index) => {
      filterItems.push({ value: index + 1, label: type });
    });
    return (
      <div style={{ display: 'flex', justifyContent: 'flex-end', marginBottom: 30 }}>
        <CustomizedFilter selectedItem={{ ...selectedFilter }} filterItems={[...filterItems]} item="Select Ratings" minWidth={160} selectedFunction={(selectedItem) => { this.setState({ selectedFilter: { ...selectedItem }, page: 1 }); }} />
      </div>
    );
  }

  noData() {
    return (
      <div
        style={{
          display: 'flex', justifyContent: 'center', alignItems: 'center', color: '#454545', height: 80, backgroundColor: '#FFFFFF', marginTop: 100, border: '1px solid', fontSize: 24, fontFamily: 'Roboto',
        }}
      >
        No Feedback Yet
      </div>
    );
  }

  renderPagination() {
    const { page } = this.state;
    const { feedbacks } = this.props;
    if (feedbacks && feedbacks.length > 0 && feedbacks.length > PAGE_COUNT) {
      return (
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <Pagination
            activePage={page}
            itemsCountPerPage={PAGE_COUNT}
            totalItemsCount={feedbacks.length}
            pageRangeDisplayed={5}
            onChange={(pageno) => this.setState({ page: pageno })}
          />
        </div>
      );
    }
    return <div />;
  }

  renderFeedbackList(feedback, counter) {
    const unknownValue = '---';
    const subjectName = feedback.subject_name || unknownValue;
    const { isAnonymousFeedback } = this.props;
    const { page } = this.state;
    return (
      <tr style={{ fontSize: '14px', height: 50 }}>
        <td style={{
          verticalAlign: 'middle', paddingLeft: 30, paddingRight: 10,
        }}
        >
          {(page - 1) * PAGE_COUNT + counter + 1}

        </td>
        <td style={{ verticalAlign: 'middle', paddingRight: 10 }}>{moment(feedback.created_at).format('DD MMM')}</td>
        <td data-tip={subjectName.length > 15 ? subjectName : null} style={{ verticalAlign: 'middle', paddingRight: 10 }}>{subjectName}</td>
        {!isAnonymousFeedback && (
        <td
          style={{ verticalAlign: 'middle', paddingRight: 10 }}
          data-tip={feedback.student_name || '-'}
        >
          {feedback.badge_id || '-'}
        </td>
        )}
        <td style={{ verticalAlign: 'middle', paddingRight: 10 }}>{feedback.rating}</td>
        <td style={{ verticalAlign: 'middle', paddingRight: 10 }}>{feedback.feedback_message || '- -'}</td>
      </tr>
    );
  }

  renderTable() {
    const { isAnonymousFeedback } = this.props;
    const { page } = this.state;

    const minCount = page >= 2 ? (page - 1) * PAGE_COUNT : 0;
    const maxCount = (page) * PAGE_COUNT;
    const style = {
      width: '10%', borderTop: 'none', textAlign: 'left', paddingRight: 10, border: '1px solid #c5c6c7', borderRight: '1px solid #dedfe0', whiteSpace: 'nowrap',
    };
    const filteredItems = this.getSelectedFeedbacks();
    if (filteredItems && filteredItems.length > 0) {
      return (
        <div>
          <table
            className="table table-hover"
            style={{
              boxShadow: '1px 2px 6px #8B9DAF33', borderRadius: 4, backgroundColor: '#FFFFFF', border: '1px solid #CDCFD6', marginBottom: 0,
            }}
          >
            <thead style={{ background: '#F6F8FA', color: '#23394C' }}>
              <tr
                className="table-header"
                style={{
                  fontSize: '18px', fontWeight: '700', height: 60, color: '#11426C', width: '100%', borderRight: '1px solid #DFE3EA',
                }}
              >
                <th
                  className="table-header"
                  style={{
                    ...style, width: '5%', paddingLeft: 30,
                  }}
                />
                <th
                  className="table-header"
                  style={style}
                >
                  Date
                </th>
                <th
                  className="table-header"
                  style={{
                    ...style, width: '15%',
                  }}
                >
                  Subject
                </th>
                {!isAnonymousFeedback && (
                  <th
                    className="table-header"
                    style={style}
                  >
                    Student ID
                  </th>
                )}
                <th
                  className="table-header"
                  style={style}
                >
                  Rating
                </th>
                <th
                  className="table-header"
                  style={{
                    width: '25%', ...style,
                  }}
                >
                  Comments
                </th>
              </tr>
            </thead>
            <tbody>
              {filteredItems.slice(minCount, maxCount).map((feedback, index) => this.renderFeedbackList(feedback, index))}
            </tbody>
          </table>
          {this.renderPagination()}
        </div>
      );
    }
    return this.noData();
  }

  render() {
    const { feedbacks } = this.props;
    if (feedbacks && feedbacks.length > 0) {
      return (
        <div>
          {this.applyFilter()}
          {this.renderTable()}
        </div>
      );
    }
    return this.noData();
  }
}

FeedbackTable.propTypes = {
  feedbacks: PropTypes.array,
  isAnonymousFeedback: PropTypes.bool,
};

FeedbackTable.defaultProps = {
  feedbacks: [],
  isAnonymousFeedback: true,
};

export default connect(null, {})(FeedbackTable);
