import React from 'react';
import { connect } from 'react-redux';
import Modal from './baseModal';
import { reportedError, sendErrorReported } from '../../actions';
import { sendEvent } from '../../helpers/Analytics';
import Constants from '../../shared/constants';

const REPORT_ISSUE_TYPES = {
  6: 'Incorrect Answer Key',
  7: 'Mistake in question or options',
  2: 'Mistake in Diagram',
  8: 'Question does not belong to the chapter or topic',
  1: 'Spelling or grammar is incorrect',
  4: 'Any other issues',
};

class ReportError extends React.Component {
  constructor(props) {
    super(props);
    const { questionId } = this.props;
    this.state = {
      questionFile: null,
      showSubmitQuestion: false,
      showThanksMessage: false,
      active: 0,
      pictureUploaded: false,
      showReporterModal: false,
      showReportErrorModal: false,
      questionId: questionId || '',
      value: '',
    };
    this.toggleModal = this.toggleModal.bind(this);
    this.questionUploadChange = this.questionUploadChange.bind(this);
    this.reportError = this.reportError.bind(this);
    this.handleCommentChange = this.handleCommentChange.bind(this);
    this.handleCloseModal = this.handleCloseModal.bind(this);
  }

  getFile() {
    document.getElementById('uploadFile').click();
  }

  toggleModal() {
    const { questionId, reporterMode, showReportErrorModal } = this.props;
    const { showReporterModal } = this.state;
    if (reporterMode) {
      this.setState({
        showReporterModal: !showReporterModal,
        questionFile: null,
        showSubmitQuestion: true,
        showThanksMessage: false,
      });
    } else {
      sendEvent('Assignment', !showReportErrorModal ? 'ShowReportError' : 'CloseReportError', 'log', { questionId });
      this.setState({
        showReportErrorModal: !showReportErrorModal,
        questionId,
      });
    }
  }

  handleActive(activeIndex) {
    const { active } = this.state;
    this.setState({ active: activeIndex === active ? 0 : activeIndex });
  }

  handleCommentChange(event) {
    this.setState({
      value: event.target.value,
    });
  }

  questionUploadChange(event) {
    this.setState({
      pictureUploaded: true,
      questionFile: event.target.files[0],
    });
  }

  reportError() {
    const { reporterMode } = this.props;
    const { questionFile, active, value } = this.state;
    if (reporterMode) {
      this.setState({ showSubmitQuestion: false });
      this.props.reportedError(questionFile, this.props.questionId, active).then((result) => {
        if (result.success) {
          sendEvent('Assignment', 'ReportSubmitted', 'log', { questionId: this.props.questionId, reportType: active, reporterType: 'Question Reporter' });
          this.setState({ showThanksMessage: true });
        }
      });
    } else {
      if (active === 0 || (active === 4 && !value)) {
        if (active === 0) {
          return alert('Atleast one option should be selected.');
        }
        return alert("For 'Any other issues' kindly specify the problem so that we can fix it at the earliest.");
      }
      this.setState({ showReportErrorModal: false, value: '' });
      const query = new URLSearchParams(window.location.search);
      const id = this.props.match.params.id;
      this.props.sendErrorReported(id, this.state.questionId, active, value).then((result) => {
        if (result.success) {
          sendEvent('Assignment', 'ReportSubmitted', 'log', { questionId: this.state.questionId, reportType: active, id });
          this.setState({ showThanksMessage: true });
          if (this.props.questionId) {
            this.props.closeReportModal();
          }
        }
      });
    }
  }

  handleCloseModal() {
    this.setState({
      showReportErrorModal: false, showThanksMessage: false, active: 0, value: '', showSubmitQuestion: false, showReporterModal: false,
    });
  }

  showSubmitQuestion() {
    const { showSubmitQuestion, active, pictureUploaded, questionFile } = this.state;
    if (showSubmitQuestion) {
      return (
        <div>
          <p style={{
            color: '#535353', fontSize: '18px', fontFamily: 'Raleway', margin: '3%',
          }}
          >
            Select the correct answer (2 points)
          </p>
          <ul className="list-group cursor" style={{ marginLeft: '2%', marginRight: '2%' }}>
            <div className="row">
              <div className="col-sm-6">
                <li role="presentation" className={active === 1 ? 'list-group-item mb-2 active' : 'list-group-item mb-2'} id="listItem" onClick={() => this.handleActive(1)}>Option 1</li>
                <li role="presentation" className={active === 2 ? 'list-group-item mb-2 active' : 'list-group-item mb-2'} id="listItem" onClick={() => this.handleActive(2)}>Option 2</li>
              </div>
              <div className="col-sm-6">
                <li role="presentation" className={active === 3 ? 'list-group-item mb-2 active' : 'list-group-item mb-2'} id="listItem" onClick={() => this.handleActive(3)}>Option 3</li>
                <li role="presentation" className={active === 4 ? 'list-group-item mb-2 active' : 'list-group-item mb-2'} id="listItem" onClick={() => this.handleActive(4)}>Option 4</li>
              </div>
            </div>
            <li role="presentation" className={active === 5 ? 'list-group-item mb-2 active' : 'list-group-item mb-2'} id="listItem" onClick={() => this.handleActive(5)}>Answer is not present.</li>
          </ul>
          <p style={{
            color: '#535353', fontSize: '18px', fontFamily: 'Raleway', margin: '4%',
          }}
          >
            Upload a solution(image) of the question.
            <br />
            <span style={{ color: '#999999', fontSize: '15px', fontFamily: 'Raleway' }}>Get 3 extra points.</span>

          </p>
          {pictureUploaded
            && (
            <div className="container justify-content-center">
              <p style={{
                fontSize: '14px', fontStyle: 'italic', fontFamily: 'Raleway', textAlign: 'left',
              }}
              >
                Photo Uploaded

              </p>
              <img alt="" src={URL.createObjectURL(questionFile)} width="200px" height="auto" style={{ textAlign: 'center' }} />
            </div>
            )}
          <div className="row justify-content-between mt-4">
            <div style={{ height: '0px', width: '0px', overflow: 'hidden' }}><input id="uploadFile" type="file" name="pic" accept="image/*" onChange={this.questionUploadChange} /></div>
            <div
              role="presentation"
              className="btn btn-lg mb-5"
              style={{
                backgroundColor: '#94ABD6', color: 'white', borderColor: Constants().color.primary, fontSize: '14px', fontWeight: 'bold',
              }}
              onClick={() => this.getFile()}
            >
              Upload Solution

            </div>
            <div
              role="presentation"
              className="btn btn-lg mb-5"
              style={{
                backgroundColor: '#94ABD6', color: 'white', borderColor: Constants().color.primary, fontSize: '14px', fontWeight: 'bold',
              }}
              onClick={() => this.reportError()}
            >
              Submit
            </div>
          </div>
        </div>
      );
    }

    return (
      <div>
        <style jsx>
          {`
            position: fixed;
            left: 0px;
            top: 0px;
            width: 100%;
            height: 100%;
            z-index: 9999;
            background: url('/static/images/loader.gif') center no-repeat #fff;
          `}
        </style>
      </div>
    );
  }

  showThanksMessage() {
    const { reporterMode } = this.props;
    return (
      <div>
        <p style={{
          margin: '5%', fontSize: '18px', fontFamily: 'Raleway', color: '#535353',
        }}
        >
          {reporterMode ? 'Your points will be added to your account after confirmation.' : 'Thank you for submitting the report our expert will rectify the issue at the earliest.'}
        </p>
        <div style={{ textAlign: 'center' }}>
          <div
            role="presentation"
            className="btn mb-5"
            style={{
              backgroundColor: '#20CB90', color: 'white', fontFamily: 'Raleway', fontWeight: 'bold', fontSize: '18px',
            }}
            onClick={this.handleCloseModal}
          >
            Close
          </div>
        </div>
      </div>
    );
  }

  renderReportError() {
    const { isMobile } = this.props;
    const { questionId, value, active } = this.state;
    return (
      <div>
        <ul className="list-group cursor" style={{ margin: '2%' }}>
          {Object.keys(REPORT_ISSUE_TYPES).map((reportIssueKey) => <li role="presentation" id="listItem" className="list-group-item mb-2" style={{ backgroundColor: active === reportIssueKey && Constants().color.primary, color: active === reportIssueKey && 'white' }} onClick={() => this.handleActive(reportIssueKey)}>{REPORT_ISSUE_TYPES[reportIssueKey]}</li>)}
        </ul>
        <div className="col">
          <textarea rows="8" style={{ height: '50%', width: '100%' }} cols="44" value={value} onChange={this.handleCommentChange} placeholder="Please share your valuable comments here..." />
        </div>
        <div className="d-flex flex-column">
          <div className="p-2 ml-2" style={{ color: '#dddddd', textAlign: 'left', marginTop: '-2%' }}>
            Question ID:
            {questionId}
          </div>
          {!isMobile && (
          <div
            role="presentation"
            className="p-2 btn btn-lg"
            style={{
              backgroundColor: Constants().color.primary, color: 'white', fontSize: '1vw', margin: '2%',
            }}
            onClick={() => this.reportError()}
          >
            Submit
          </div>
          )}
        </div>
        {isMobile
          && (
          <div
            role="presentation"
            onClick={() => this.reportError()}
            style={{
              display: 'flex', bottom: 0, left: 0, position: 'fixed', height: 50, justifyContent: 'center', alignItems: 'center', width: window.innerWidth, backgroundColor: '#FF5F5F', color: 'white',
            }}
          >
            Submit
          </div>
          )}
      </div>
    );
  }

  render() {
    const buttonWidth = 100;
    const color = Constants().color.error;
    const { isMobile } = this.props;
    const { showReporterModal, showReportErrorModal, showThanksMessage } = this.state;
    if (isMobile) {
      return this.renderReportError();
    }
    return (
      <div>
        <Modal showModal={showReporterModal} handleCloseModal={this.handleCloseModal} header="Report Error">
          {this.showSubmitQuestion()}
        </Modal>
        <Modal showModal={showReportErrorModal} handleCloseModal={this.handleCloseModal} header="Report Error">
          {this.renderReportError()}
        </Modal>

        <Modal showModal={showThanksMessage} handleCloseModal={this.handleCloseModal} header="Error Reported Successfully">
          {this.showThanksMessage()}
        </Modal>
        <div
          role="presentation"
          className="Button"
          style={{
            width: buttonWidth, border: `1px solid ${color}`, color, whiteSpace: 'nowrap', fontSize: 12,
          }}
          onClick={() => this.toggleModal()}
        >
          Report Error
        </div>
      </div>
    );
  }
}

export default connect(null, { reportedError, sendErrorReported })(ReportError);
