import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Select from 'react-select';
import Modal from './baseModal';
import closeMark from '../../static/images/close-mark.svg';

class EditFileDetails extends Component {
  constructor(props) {
    super(props);
    const { file, classrooms } = this.props;
    this.state = {
      fileTitle: file.title,
      fileDescription: file.description,
      fileId: file.id,
      selectedClassrooms: file.selected_classrooms,
      classrooms,
    };
    this.toggleModal = this.toggleModal.bind(this);
    this.renderEdit = this.renderEdit.bind(this);
  }

  toggleModal() {
    const { showEditModal } = this.state;
    this.setState({
      showEditModal: !showEditModal,
    });
  }

  handleFormSubmit(e) {
    e.preventDefault();
  }

  updateFieldValueChanges(type, value) {
    this.setState({ [type]: value });
  }

  updateFileDetails() {
    const {
      fileDescription, fileTitle, fileId, selectedClassrooms,
    } = this.state;
    const classroomIds = [];
    if (selectedClassrooms) {
      selectedClassrooms.forEach((e) => classroomIds.push(e.value));
    }
    this.props.updateDetails(fileId, fileTitle, fileDescription, classroomIds.join(','));
  }

  renderInput(type) {
    return (
      <input
        type="text"
        value={this.state[type]}
        style={{
          border: 'none', borderBottom: '1px solid #E4E7EE', width: '90%', outline: 'none', margin: '5px 0px 0px 0px', fontSize: 16,
        }}
        onChange={(event) => this.updateFieldValueChanges(type, event.target.value)}
        required
      />
    );
  }

  renderSubInfo(label, type) {
    return (
      <div style={{ marginBottom: 10 }}>
        <div className="heading-3">{label}</div>
        {(type !== 'fileDescription')
          ? this.renderInput(type)
          : (
            <textarea
              value={this.state[type]}
              style={{
                border: 'none', borderBottom: '1px solid #E4E7EE', width: '90%', outline: 'none', marginTop: 5, height: 60, marginBottom: 0,
              }}
              onChange={(event) => this.updateFieldValueChanges(type, event.target.value)}
              required
            />
          )}
      </div>
    );
  }

  renderEdit() {
    const {
      fileDescription, fileTitle, selectedClassrooms, classrooms,
    } = this.state;
    const customStyles = {
      control: (base, state) => ({
        ...base,
        height: 60,
        width: '100%',
        backgroundColor: state.isDisabled ? '#ddd' : '#ffffff',
        cursor: state.isDisabled ? 'not-allowed' : 'pointer',
        textAlign: 'center',
        border: '1px solid var(--#ddd) !important',
      }),
      indicatorsContainer: (base, state) => ({
        ...base,
        height: 60,
      }),
      valueContainer: (base, state) => ({
        ...base,
        height: 58,
        overflow: 'scroll',
      }),
    };
    const classroomList = [];
    classrooms.map((classroom) => {
      classroomList.push({ value: classroom.id, label: classroom.name });
    });
    const items = [...classroomList, { id: null, value: '', label: 'All' }];
    return (
      <div style={{ padding: 25 }}>
        <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: 40 }}>
          <div className="heading-1">File Details</div>
          <div
            role="presentation"
            onClick={this.props.handleCloseModal}
            style={{ cursor: 'pointer' }}
          >
            <img src={closeMark} alt="x-mark" />
          </div>
        </div>
        <form
          style={{ marginBottom: 0 }}
          onSubmit={fileDescription && fileTitle ? () => this.updateFileDetails() : () => {}}
        >
          <div style={{ display: 'flex', justifyContent: 'center' }}>
            <div style={{ width: '100%' }}>
              {this.renderSubInfo('Title', 'fileTitle')}
              {this.renderSubInfo('Description', 'fileDescription')}
              <div className="heading-3">Classrooms</div>
              <Select
                isMulti
                styles={customStyles}
                value={selectedClassrooms}
                onChange={(selectedOption) => this.setState({ selectedClassrooms: selectedOption })}
                options={items}
                maxMenuHeight={100}
                placeholder="All"
              />
            </div>
          </div>
          <div style={{ display: 'flex', justifyContent: 'center', marginTop: 20 }}>
            <button
              type="submit"
              className="class_board_primary_button"
              style={{
                width: 180, height: 35, margin: 0, borderRadius: 4, display: 'flex', justifyContent: 'center', alignItems: 'center', lineHeight: 0, fontSize: 14, color: '#FFFFFF',
              }}
            >
              Update
            </button>
          </div>
        </form>
      </div>
    );
  }

  render() {
    return (
      <div>
        <Modal showModal={this.props.showModal} handleCloseModal={this.props.handleCloseModal} height="480px" width="380px">
          <div>
            {this.renderEdit()}
          </div>
        </Modal>
      </div>
    );
  }
}
export default connect()(EditFileDetails);
