import React, { Component } from 'react';
import { connect } from 'react-redux';
import Layout from '../layout';
import Constants from '../../shared/constants';
import '../../styles/questionSuggestionImport.css';
import { getQuestionSuggestions, uploadQuestionSuggestion } from '../../actions';
import Menubar from '../menubar';

const StyleConstants = Constants();

class QuestionImagesList extends Component {
  componentDidMount() {
    this.props.getQuestionSuggestions();
  }

  renderButton(detail, imageDetail, statusNumbers) {
    return (
      <button
        type="submit"
        style={{
          height: 30, width: '60%', margin: 14, backgroundColor: detail.backgroundColors[statusNumbers[imageDetail.status]], color: statusNumbers[imageDetail.status] === 0 ? StyleConstants.color.primary : '#FFF', border: `solid 1px ${StyleConstants.color.primary}`, borderRadius: 4, display: 'flex', justifyContent: 'center', alignItems: 'center', lineHeight: 0, fontSize: 14,
        }}
        onClick={() => { (statusNumbers[imageDetail.status] === 0) && this.props.uploadQuestionSuggestion(imageDetail.id, detail.index); }}
      >
        { statusNumbers[imageDetail.status] === detail.index ? `${detail.type}d` : detail.type }
      </button>
    );
  }

  renderListHeader() {
    return (
      <>
        <th
          scope="col"
          className="table-header"
          style={{
            width: '10%', borderRight: '1px solid #DFE3EA', textAlign: 'left', paddingLeft: 20,
          }}
        >
            S.No
        </th>
        <th scope="col" className="table-header" style={{ width: '70%', borderRight: '1px solid #DFE3EA', textAlign: 'center' }}>Image</th>
        <th scope="col" className="table-header" style={{ width: '15%', borderRight: '1px solid #DFE3EA', paddingLeft: 20 }}>Resolve</th>
        <th scope="col" className="table-header" style={{ width: '15%', borderRight: '1px solid #DFE3EA', paddingLeft: 20 }}>Ignore</th>
      </>
    );
  }

  render() {
    const { questionSuggestionMenu } = Constants();
    const imagesList = this.props.questionSuggestions;
    const statusNumbers = { UNRESOLVED: 0, RESOLVED: 1, IGNORED: 2 };
    const statusDetails = [{ index: 1, type: 'Resolve', backgroundColors: ['#FFF', StyleConstants.color.success, StyleConstants.color.inactive] }, { index: 2, type: 'Ignore', backgroundColors: ['#FFF', StyleConstants.color.inactive, StyleConstants.color.error] }];
    return (
      <Layout globalHistory={this.props.history} hideHeader>
        <div style={{ width: '100%', display: 'flex' }}>
          <div style={{ width: '20%', padding: 0, zIndex: 1 }}>
            <Menubar history={this.props.history} menuList={questionSuggestionMenu} selectedOption={1} redirectToRoot />
          </div>
          <div style={{ width: '80%' }}>
            <div style={{ width: '80%', margin: 'auto' }}>
              <div className="heading-1" style={{ margin: 30 }}>Question Suggestions</div>
              <table
                className="table table-hover table-fixed"
                style={{
                  margin: '30px 30px 70px 30px', boxShadow: '1px 2px 6px #8B9DAF33', borderRadius: 4, backgroundColor: '#FFFFFF', border: '1px solid #CDCFD6',
                }}
              >
                <thead style={{ background: '#F6F8FA', color: '#23394C' }}>
                  <tr
                    className="table-header"
                    style={{
                      fontSize: '18px', fontWeight: 700, height: 60, color: '#11426C',
                    }}
                  >
                    {this.renderListHeader()}
                  </tr>
                </thead>
                <tbody>
                  {imagesList.map((imageDetail, index) => {
                    if (imageDetail.image) {
                      return (
                        <tr style={{ fontSize: '14px', height: 50 }}>
                          <th
                            scope="col"
                            className="table-header"
                            style={{
                              width: '10%', borderTop: 'none', textAlign: 'left', verticalAlign: 'middle', paddingLeft: 50,
                            }}
                          >
                            {index + 1}
                          </th>
                          <th scope="col" className="table-header" style={{ width: '70%', borderTop: 'none', textAlign: 'center' }}>
                            <img src={imageDetail.image.url} alt="written_question_image" width="400px" height="auto" style={{ margin: 50, verticalAlign: 'middle' }} />
                          </th>
                          {statusDetails.map((detail) => {
                            return (
                              <th scope="col" className="table-header" style={{ width: '15%', borderTop: 'none', verticalAlign: 'middle' }}>{this.renderButton(detail, imageDetail, statusNumbers)}</th>
                            );
                          })}
                        </tr>
                      );
                    }
                  })}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </Layout>
    );
  }
}

const mapStateToProps = ({ questionSuggestionsList }) => ({
  questionSuggestions: questionSuggestionsList.questionSuggestions,
});

export default connect(mapStateToProps,
  { getQuestionSuggestions, uploadQuestionSuggestion })(QuestionImagesList);
