import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { PAGETYPE } from '../../constants';
import TestAssignmentModal from '../modal/testAssignmentModal';
import overView from '../../static/images/assignmentPage/overview.svg';
import EditSelected from '../../static/images/assignmentPage/editSelected.svg';
import Edit from '../../static/images/assignmentPage/edit.svg';
import pickQuestion from '../../static/images/assignmentPage/pickQuestion.svg';
import pickQuestionSelected from '../../static/images/assignmentPage/pickQuestionSelected.svg';
import review from '../../static/images/assignmentPage/review.svg';
import reviewSelected from '../../static/images/assignmentPage/reviewSelected.svg';
import printHidden from '../../static/images/assignmentPage/printHidden.svg';
import printShow from '../../static/images/assignmentPage/print.svg';
import Constants from '../../shared/constants';

class SideNavigationBar extends React.Component {
  renderIcon(url, text, type) {
    const {
      name, testDate, selectedClassroomList, selectedTestGroup,
      progressiveSearchSelectedValues, classroomList,
      hasAccess, isAssignment, dispatch, selectedPage,
      isReviewed, showProofread, assignmentQuestionLimit, isOnline,
      hasMultipleTestSets, subjectQuestionLimit, isTestDataEditable, globalHistory,
      isTrial, testDuration, showTest, cannotPickQuestion, previousPage, id, subjectId, track,
    } = this.props;
    const selected = selectedPage === type;
    if (type === PAGETYPE.EDIT && selected) {
      return (
        <TestAssignmentModal
          globalHistory={globalHistory}
          hasMultipleTestSets={hasMultipleTestSets}
          isTestPage={!isAssignment}
          name={name}
          subjectId={subjectId}
          track={track}
          id={id}
          testDuration={testDuration}
          onCloseEdit={() => this.props.toggleSelectedPage(previousPage)}
          testDate={testDate}
          selectedClassroomList={selectedClassroomList}
          selectedTestGroup={selectedTestGroup}
          selectedSubject={progressiveSearchSelectedValues.subject}
          subjectList={[]}
          isTestDataEditable={isTestDataEditable}
          classroomList={classroomList}
          isReviewed={isReviewed}
          testGroupList={[]}
          dispatch={dispatch}
          homePage={false}
          hasAccess={hasAccess}
          questionLimit={subjectQuestionLimit}
          assignmentQuestionLimit={assignmentQuestionLimit}
          showModal={selected}
          previousPage={previousPage}
          url={url}
          text={text}
          isOnline={isOnline}
          isTrial={isTrial}
          showTest={showTest}
        />
      );
    }
    let errorMessage = '';
    const showError = (((!showProofread && !isReviewed)
    && type === PAGETYPE.PRINT) || ((isReviewed || isTrial || cannotPickQuestion)
    && type === PAGETYPE.PICKQUESTION));
    if (showError) {
      if (type === PAGETYPE.PICKQUESTION) {
        if (isReviewed) {
          errorMessage = `You can only pick questions after ${isAssignment ? 'assignment' : 'test'} is unapproved.`;
        } else if (isTrial) {
          errorMessage = 'Trial version does not let you pick questions manually.';
        } else if (cannotPickQuestion) {
          errorMessage = 'Once You upload your institute questions, You cannot pick the questions';
        }
      } else if ((!showProofread && !isReviewed) && type === PAGETYPE.PRINT) {
        errorMessage = `${isAssignment ? 'Assignment' : 'Test'} has to be approved`;
      }
    }
    let color = 'white';
    if (selected) {
      color = Constants().color.primary;
    }
    if (!showProofread && !isReviewed && type === PAGETYPE.PRINT) {
      color = '#aaa';
    }
    return (
      <div role="presentation" data-tip={showError ? errorMessage : ''} className="mb-2 cursor pt-3 pb-3" style={{ backgroundColor: selected ? 'white' : Constants().color.primary, textAlign: 'center' }} onClick={() => !showError && this.props.toggleSelectedPage(type)}>
        <img alt="" src={url} height={30} width={30} color="#ddd" />
        <div className="mt-1" style={{ color, fontSize: 12, fontWeight: 'bold' }}>
          {text}
        </div>
      </div>
    );
  }

  render() {
    const {
      selectedPage, showProofread, isReviewed, isAssignment, showOverview,
    } = this.props;
    return (
      <div>
        {(showOverview) && this.renderIcon(overView, 'Change Subject', PAGETYPE.OVERVIEW)}
        {this.renderIcon(selectedPage === PAGETYPE.EDIT ? EditSelected : Edit, 'Edit', PAGETYPE.EDIT)}
        {this.renderIcon(selectedPage === PAGETYPE.PICKQUESTION ? pickQuestionSelected : pickQuestion, 'Pick Questions', PAGETYPE.PICKQUESTION)}
        {this.renderIcon(selectedPage === PAGETYPE.REVIEW ? reviewSelected : review, 'Review', PAGETYPE.REVIEW)}
        {(isAssignment || showProofread) && this.renderIcon((!showProofread && !isReviewed) ? printHidden : printShow, 'Print', PAGETYPE.PRINT)}
      </div>
    );
  }
}

SideNavigationBar.propTypes = {
  previousPage: PropTypes.number,
};

SideNavigationBar.defaultProps = {
  previousPage: 2,
};

const mapStateToProps = ({ assignment }) => ({
  name: assignment.testInfo.name,
  testDate: assignment.testInfo.test_date,
  testDuration: assignment.testInfo.duration,
  isTestDataEditable: assignment.isTestDataEditable,
  chapters_list: assignment.chapters_list,
  checkpoints_list: assignment.checkpoints_list,
  classroomList: assignment.classroomList,
  selectedClassroomList: assignment.selectedClassroomList,
  isAssignment: assignment.testInfo.is_assignment,
  selectedTestGroup: assignment.selectedTestGroup,
  hasAccess: assignment.hasAccess,
  isReviewed: assignment.testInfo.is_reviewed,
  progressiveSearchSelectedValues: assignment.progressiveSearchSelectedValues,
  assignmentQuestionLimit: assignment.assignmentQuestionLimit,
  subjectQuestionLimit: assignment.subjectQuestionLimit,
  showProofread: assignment.showProofread,
  isOnline: assignment.testInfo.is_online,
  showOverview: assignment.showOverview,
  hasMultipleTestSets: assignment.hasMultipleTestSets,
  isTrial: assignment.isTrial,
  showTest: assignment.showTest,
});

export default connect(mapStateToProps)(SideNavigationBar);
