import React from "react";
import Layout from "../layout";
import { isMobileDevice } from "../../helpers/util";
import MenuBar from "../menubar";
import ApplicantsList from "./applicantsList";
import Applicant from "./applicant";
import ApplicantDashboard from "./dashboard";

const IS_MOBILE = isMobileDevice();

const MENU_DATA = {
  DASHBOARD: { label: "Dashboard", route: "/admission_board/dashboard" },
  APPLICANTS: { label: "Applicants", route: "/admission_board/applicants" },
};

class BdeTools extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {}

  render() {
    const { history } = this.props;
    const { type, id } = this.props.match.params;
    const params = new URLSearchParams(window.location.search);
    const applicantId = params.get("id");
    const dataType = type && type.toUpperCase();
    const types = Object.keys(MENU_DATA);
    const index = types.indexOf(dataType);
    return (
      <Layout
        hideHeader
        globalHistory={history}
        isMobile={IS_MOBILE}
        title="ADMISSION BOARD"
      >
        <MenuBar
          history={history}
          menuList={Object.values(MENU_DATA)}
          selectedOption={index}
          redirectToRoot
          headerText={{ first: "Admission", last: "Board" }}
        />
        {type === "applicants" && <ApplicantsList />}
        {type === "applicant_profile" && (
          <Applicant applicantId={applicantId} />
        )}
        {type === "dashboard" && <ApplicantDashboard />}
      </Layout>
    );
  }
}

export default BdeTools;
