import {
  UPDATE_TEST_DETAILS, GET_CHAPTERS_FOR_TEST, GET_TOPIC_CHAPTER_DISTRIBUTION_FOR_TEST,
  UPDATE_TEST_OVERVIEW, UPDATE_TEST_REVIEW_STATUS,
} from '../actions';

const initialState = {
  hasAccess: false,
  approved: false,
  testDetails: {},
  paperSetters: {},
  subjectList: [],
  unitData: {},
  distribution: {},
  selectedChapters: [],
  questionLimit: 0,
  isTestDataEditable: false,
  selectedDistributionRanges: {},
  isAssignment: true,
  isTrial: true,
  isTestLockedByOtp: false,
  hasQuestionUploadAccess: false,
  testOtpType: 'None',
  classroomList: [],
  selectedTestGroup: {},
};

export default function (state = initialState, action) {
  switch (action.type) {
    case UPDATE_TEST_OVERVIEW: {
      return { ...state, ...action.payload };
    }
    case UPDATE_TEST_DETAILS: {
      return { ...state, ...action.payload };
    }
    case GET_CHAPTERS_FOR_TEST: {
      return { ...state, ...action.payload };
    }
    case GET_TOPIC_CHAPTER_DISTRIBUTION_FOR_TEST: {
      return { ...state, ...action.payload };
    }
    case UPDATE_TEST_REVIEW_STATUS: {
      return {
        ...state,
        testDetails: { ...state.testDetails, approved: action.reviewStatus },
      };
    }
    default:
      return state;
  }
}
