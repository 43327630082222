import {
  UPDATE_HR_BOARD_DETAILS,
  UPDATE_HR_BOARD_EMPLOYEE,
  UPDATE_HR_BOARD_EMPLOYEE_SALARY_SPLIT,
} from "../actions";

const INITIAL_STATE = {
  isLoading: false,
  employee: {},
  employeesList: [],
  totalEmployeesCount: null,
};

export default function (state = INITIAL_STATE, action) {
  switch (action.type) {
    case UPDATE_HR_BOARD_DETAILS: {
      return { ...state, ...action.payload };
    }

    case UPDATE_HR_BOARD_EMPLOYEE: {
      const newEmployeeDetails = {
        ...action.payload.employeeDetails.employee_data,
        bank_accounts: action.payload.employeeDetails.bank_accounts,
        current_bank_accounts_split:
          action.payload.employeeDetails.bank_accounts,
        campuses: action.payload.employeeDetails.campuses,
      };
      return { ...state, ...{ employee: newEmployeeDetails } };
    }

    case UPDATE_HR_BOARD_EMPLOYEE_SALARY_SPLIT: {
      const newEmployeeDetails = {
        ...state.employee,
        current_bank_accounts_split: action.payload.newBankAccountsSplit,
      };
      return { ...state, ...{ employee: newEmployeeDetails } };
    }

    default: {
      return state;
    }
  }
}
