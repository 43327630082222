import React, { PureComponent } from 'react';
import Modal from './baseModal';
import hurray from '../../static/images/hurray.svg';

class ThankyouModal extends PureComponent {
  render() {
    return (
      <Modal
        showModal={this.props.showModal}
        handleCloseModal={() => this.props.toggleThankyouModal(false)}
      >
        <div style={{ margin: 30 }}>
          <div style={{ display: 'flex', justifyContent: 'center' }}>
            <img alt="" src={hurray} />
          </div>
          <div style={{ alignSelf: 'center', marginTop: '5%' }}>
            Thank you for helping us improve our content!
          </div>
          <div style={{ alignSelf: 'center', margin: '5% 35% 0% 35%', width: '30%' }}>
            <div role="presentation" className="primary_button" style={{ padding: 10 }} onClick={() => this.props.toggleThankyouModal(false)}> Done </div>
          </div>
        </div>
      </Modal>
    );
  }
}

export default ThankyouModal;
