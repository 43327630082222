export const STUDENT_DASHBOARD_TYPE = Object.freeze({
  SCORE: 'score',
  ANSWER: 'answer',
  INCORRECT: 'incorrect',
  MISC: 'misc',
});

export const STUDENT_DASHBOARD_TYPE_COLOR = {
  score: '#20CB90',
  answer: '#F2C94C',
  incorrect: '#ED7161',
  misc: '#F3F3F3',
};
