import Api from '../helpers/Api';

export const SEND_NOTIFICATIONS = '@notification/SEND_NOTIFICATIONS';
export const GET_NOTIFICATIONS = '@notification/GET_NOTIFICATIONS';

export function sendNotifications(classroomId, message, title) {
  return (dispatch) => Api({
    method: 'post',
    url: '/notifications/send_notifications_to_students',
    data: { classroom_ids: JSON.stringify(classroomId), message, title },
  }).then((response) => {
    if (response.success) {
      dispatch({
        type: SEND_NOTIFICATIONS,
        notifications: response.notifications,
        classroomListNotification: response.classroom_list,
      });
    }
    return response;
  });
}

export function getNotifications() {
  return (dispatch) => Api({
    method: 'get',
    url: '/notifications/get_notifications_for_institute',
  }).then((response) => {
    if (response.success) {
      dispatch({
        type: GET_NOTIFICATIONS,
        notifications: response.notifications,
        classroomListNotification: response.classroom_list,
      });
    }
    return response;
  });
}
