import React, { PureComponent } from "react";
import { connect } from "react-redux";
import Select from "react-select";
import Modal from "react-responsive-modal";
import closeMark from "../../static/images/close-mark.svg";

const customDropDownStyles = {
  control: (base, state) => ({
    ...base,
    height: 35,
    width: 150,
    backgroundColor: "white",
    border: "1px solid #CDCFD6 !important",
  }),
  indicatorsContainer: (base, state) => ({
    ...base,
    height: 35,
  }),
  valueContainer: (base, state) => ({
    ...base,
    height: 40,
  }),
  option: (base, state) => ({
    ...base,
    textAlign: "left",
  }),
};

const customDropDownStylesMultiSelect = {
  control: (base, state) => ({
    ...base,
    height: 35,
    width: 500,
    backgroundColor: "white",
    border: "1px solid #CDCFD6 !important",
  }),
  indicatorsContainer: (base, state) => ({
    ...base,
    height: 35,
  }),
  valueContainer: (base, state) => ({
    ...base,
    height: 35,
  }),
  option: (base, state) => ({
    ...base,
    textAlign: "left",
  }),
};

const BLOOD_GROUP = {
  "O POSITIVE": 0,
  "O NEGATIVE": 1,
  "A POSITIVE": 2,
  "A NEGATIVE": 3,
  "B POSITIVE": 4,
  "B NEGATIVE": 5,
  "AB POSITIVE": 6,
  "AB NEGATIVE": 7,
};

const EMPLOYMENT_STATUS = {
  APPLIED: 0,
  "OFFER PLACED": 1,
  EMPLOYED: 2,
  TERMINATED: 3,
};

class AddEmployee extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      employeeBadgeId: props.employee.employee_badge_id || "",
      firstName: props.employee.first_name || "",
      lastName: props.employee.last_name || "",
      jobTitle: props.employee.job_title || "",
      subject: props.employee.subject || "",
      organization: props.employee.organization || "",
      campuses:
        (props.employee !== "new" &&
          props.employee.campuses.map((campus) => {
            return { value: campus.id, label: campus.campus_name };
          })) ||
        [],
      selectedCampuses:
        (props.employee !== "new" &&
          props.employee.campuses.map((campus) => {
            return campus.id;
          })) ||
        [],
      personalPhone:
        (props.employee.personal_phone &&
          props.employee.personal_phone.replaceAll("-", "")) ||
        "",
      department: props.employee.department || "",
      gender:
        props.employee.gender && props.employee.gender === true
          ? { label: "Female", value: 1 }
          : { label: "Male", value: 0 } || {},
      pan: props.employee.pan || "",
      fatherName: props.employee.father_name || "",
      currentAddressLineOne:
        (props.employee.current_address &&
          props.employee.current_address.line_one) ||
        "",
      currentAddressLineTwo:
        (props.employee.current_address &&
          props.employee.current_address.line_two) ||
        "",
      currentAddressCity:
        (props.employee.current_address &&
          props.employee.current_address.city) ||
        "",
      currentAddressPinCode:
        (props.employee.current_address &&
          props.employee.current_address.pin_code) ||
        "",
      permanentAddressLineOne:
        (props.employee.permanent_address &&
          props.employee.permanent_address.line_one) ||
        "",
      permanentAddressLineTwo:
        (props.employee.permanent_address &&
          props.employee.permanent_address.line_two) ||
        "",
      permanentAddressCity:
        (props.employee.permanent_address &&
          props.employee.permanent_address.city) ||
        "",
      permanentAddressPinCode:
        (props.employee.permanent_address &&
          props.employee.permanent_address.pin_code) ||
        "",
      bloodGroup:
        (props.employee.blood_group && {
          value:
            BLOOD_GROUP[
              props.employee.blood_group.toUpperCase().replaceAll("_", " ")
            ],
          label: props.employee.blood_group.toUpperCase().replaceAll("_", " "),
        }) ||
        "",
      dateOfJoining: props.employee.date_of_joining || "",
      dateOfBirth: props.employee.date_of_birth || "",
      workEmail: props.employee.work_email || "",
      workPhone:
        (props.employee.work_phone &&
          props.employee.work_phone.replaceAll("-", "")) ||
        "",
      personalEmail: props.employee.personal_email || "",
      emergencyContactName: props.employee.emergency_contact_name || "",
      emergencyContactNumber:
        (props.employee.emergency_contact_number &&
          props.employee.emergency_contact_number.replaceAll("-", "")) ||
        "",
      emergencyContactRelationship:
        props.employee.emergency_contact_relationship || "",
      currentSalary: props.employee.current_salary || "",
      status:
        (props.employee.status && {
          label: props.employee.status.toUpperCase().replaceAll("_", " "),
          value:
            EMPLOYMENT_STATUS[
              props.employee.status.toUpperCase().replaceAll("_", " ")
            ],
        }) ||
        null,
      validPersonalPhone:
        props.employee.personal_phone &&
        props.employee.personal_phone.replaceAll("-", "").length === 10,
      validWorkPhone:
        props.employee.work_phone &&
        props.employee.work_phone.replaceAll("-", "").length === 10,
      validEmergencyContactNumber:
        props.employee.emergency_contact_number &&
        props.employee.emergency_contact_number.replaceAll("-", "").length ===
          10,
      newEmployee: props.employee === "new",
      showAlert: false,
      pictureUploaded: false,
      picture: props.employee.picture || null,
    };
    this.renderEdit = this.renderEdit.bind(this);
    this.allowNewEmployeeCreation = this.allowNewEmployeeCreation.bind(this);
    this.allowUpdateCurrentEmployeeDetails =
      this.allowUpdateCurrentEmployeeDetails.bind(this);
  }

  getPicture() {
    document.getElementById("uploadFile").click();
  }

  allowNewEmployeeCreation() {
    const {
      employeeBadgeId,
      firstName,
      lastName,
      jobTitle,
      subject,
      organization,
      selectedCampuses,
      personalPhone,
      department,
      gender,
      pan,
      fatherName,
      currentAddressLineOne,
      currentAddressLineTwo,
      currentAddressCity,
      currentAddressPinCode,
      permanentAddressLineOne,
      permanentAddressLineTwo,
      permanentAddressCity,
      permanentAddressPinCode,
      bloodGroup,
      dateOfJoining,
      workEmail,
      emergencyContactName,
      emergencyContactNumber,
      emergencyContactRelationship,
      currentSalary,
      status,
      personalEmail,
      workPhone,
      // picture,
      dateOfBirth,
    } = this.state;
    this.props.newEmployeeDetails(
      employeeBadgeId,
      firstName,
      lastName,
      jobTitle,
      subject,
      organization,
      selectedCampuses,
      personalPhone,
      department,
      gender,
      pan,
      fatherName,
      currentAddressLineOne,
      currentAddressLineTwo,
      currentAddressCity,
      currentAddressPinCode,
      permanentAddressLineOne,
      permanentAddressLineTwo,
      permanentAddressCity,
      permanentAddressPinCode,
      bloodGroup,
      dateOfJoining,
      workEmail,
      emergencyContactName,
      emergencyContactNumber,
      emergencyContactRelationship,
      currentSalary,
      status,
      personalEmail,
      workPhone,
      // picture,
      dateOfBirth
    );
  }

  allowUpdateCurrentEmployeeDetails() {
    const {
      employeeBadgeId,
      firstName,
      lastName,
      jobTitle,
      subject,
      organization,
      selectedCampuses,
      personalPhone,
      department,
      gender,
      pan,
      fatherName,
      currentAddressLineOne,
      currentAddressLineTwo,
      currentAddressCity,
      currentAddressPinCode,
      permanentAddressLineOne,
      permanentAddressLineTwo,
      permanentAddressCity,
      permanentAddressPinCode,
      bloodGroup,
      dateOfJoining,
      workEmail,
      emergencyContactName,
      emergencyContactNumber,
      emergencyContactRelationship,
      currentSalary,
      status,
      personalEmail,
      workPhone,
      // picture,
      dateOfBirth,
    } = this.state;
    this.props.updateEmployeeDetails(
      employeeBadgeId,
      firstName,
      lastName,
      jobTitle,
      subject,
      organization,
      selectedCampuses,
      personalPhone,
      department,
      gender,
      pan,
      fatherName,
      currentAddressLineOne,
      currentAddressLineTwo,
      currentAddressCity,
      currentAddressPinCode,
      permanentAddressLineOne,
      permanentAddressLineTwo,
      permanentAddressCity,
      permanentAddressPinCode,
      bloodGroup,
      dateOfJoining,
      workEmail,
      emergencyContactName,
      emergencyContactNumber,
      emergencyContactRelationship,
      currentSalary,
      status,
      personalEmail,
      workPhone,
      // picture,
      dateOfBirth
    );
  }

  updateFieldValueChanges(type, value) {
    if (type === "personalPhone") {
      value = value.replace(" ", "");
      value =
        value && value.match(/^[0-9]*$/g)
          ? value.match(/^[0-9]*$/g).join("")
          : "";
      this.setState({
        validPersonalPhone: value.length === 10,
        personalPhone: value,
      });
    }
    if (type === "workPhone") {
      value = value.replace(" ", "");
      value =
        value && value.match(/^[0-9]*$/g)
          ? value.match(/^[0-9]*$/g).join("")
          : "";
      this.setState({
        validWorkPhone: value.length === 10,
        workPhone: value,
      });
    }
    if (type === "emergencyContactNumber") {
      value = value.replace(" ", "");
      value =
        value && value.match(/^[0-9]*$/g)
          ? value.match(/^[0-9]*$/g).join("")
          : "";
      this.setState({
        validEmergencyContactNumber: value.length === 10,
        emergencyContactNumber: value,
      });
    }
    this.setState({ [type]: value });
  }

  renderSubInfo(
    label,
    type,
    value,
    input = false,
    marginBottom = 10,
    required = true,
    inputType = "text"
  ) {
    return (
      <div style={{ marginBottom, paddingRight: 20 }}>
        <div className="heading-3">{label}</div>
        {!input && (
          <div className="content-text" style={{ marginTop: 5 }}>
            {value || "-"}
          </div>
        )}
        {input && (
          <input
            type={inputType}
            value={this.state[type]}
            pattern={
              label === "Address"
                ? "[a-zA-Z0-9,- ]"
                : inputType === "text"
                ? "[a-zA-Z0-9 ]+"
                : "[a-z0-9._%+-]+@[a-z0-9.-]+.[a-z]{2,}$"
            }
            style={{
              border: "none",
              borderBottom: "1px solid #E4E7EE",
              width: 150,
              outline: "none",
            }}
            default
            onChange={(event) =>
              this.updateFieldValueChanges(type, event.target.value)
            }
            required={required}
          />
        )}
      </div>
    );
  }

  renderDropDown(
    filterItems,
    selectedItem,
    selectedFunction,
    item,
    defaultValue = ""
  ) {
    const defaultSelectedValue = defaultValue || { label: item, value: 0 };
    return (
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "flex-start",
          paddingBottom: 20,
          paddingRight: 20,
        }}
      >
        <div style={{ marginBottom: 5 }} className="heading-3">
          {item}
        </div>
        <Select
          styles={customDropDownStyles}
          value={selectedItem || defaultSelectedValue}
          onChange={selectedFunction}
          options={filterItems}
          placeholder="All"
          maxMenuHeight={150}
          required
        />
      </div>
    );
  }

  renderMultiSelect(
    options,
    selectedOptions,
    handleChangeOfMultiSelect,
    placeholder
  ) {
    return (
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "flex-start",
          paddingBottom: 20,
          paddingRight: 20,
        }}
      >
        <div style={{ marginBottom: 5 }} className="heading-3">
          {placeholder}
        </div>
        <Select
          value={selectedOptions}
          onChange={handleChangeOfMultiSelect}
          options={options}
          placeholder={placeholder}
          styles={customDropDownStylesMultiSelect}
          maxMenuHeight={200}
          isMulti
        />
      </div>
    );
  }

  renderEdit() {
    const { employee } = this.props;
    const {
      emergencyContactNumber,
      workPhone,
      personalPhone,
      newEmployee,
      showAlert,
      firstName,
      personalEmail,
      status,
      campuses,
    } = this.state;
    const allowPersonalPhone = this.state.validPersonalPhone;
    const allowWorkPhone = this.state.validWorkPhone;
    const allowEmergencyContactPhone = this.state.validEmergencyContactNumber;
    const noerror =
      (!allowPersonalPhone && !!personalPhone) ||
      (!allowWorkPhone && !!workPhone) ||
      (!allowEmergencyContactPhone && !!emergencyContactNumber) ||
      !firstName ||
      !personalEmail ||
      !personalPhone ||
      !status;

    return (
      <>
        <div
          style={{
            padding: "50px 30px 50px 40px",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <div className="form-row">
            <div>
              <div className="form-row">
                <div>
                  {this.renderSubInfo(
                    "First Name *",
                    "firstName",
                    employee.first_name,
                    true
                  )}
                  {showAlert && !firstName && (
                    <div
                      style={{ color: "red", marginBottom: 5, fontSize: 10 }}
                    >
                      This field should not be empty
                    </div>
                  )}
                </div>
                {this.renderSubInfo(
                  "Last Name",
                  "lastName",
                  employee.last_name,
                  true,
                  10,
                  false
                )}
                {this.renderSubInfo(
                  "Employee Badge ID",
                  "employeeBadgeId",
                  employee.employee_badge_id,
                  true,
                  10,
                  false
                )}
              </div>
              <div className="form-row">
                {this.renderSubInfo(
                  "Job Title",
                  "jobTitle",
                  employee.job_title,
                  true,
                  10,
                  false
                )}
                {this.renderSubInfo(
                  "Subject",
                  "subject",
                  employee.subject,
                  true,
                  10,
                  false
                )}
                {this.renderSubInfo(
                  "Organization",
                  "organization",
                  employee.organization,
                  true,
                  10,
                  false
                )}
              </div>
            </div>
            {/* <div style={{ height: 150, width: 150 }}>
              <div style={{ height: "0px", overflow: "hidden" }}>
                <input
                  id="uploadFile"
                  type="file"
                  name="pic"
                  accept="image/*"
                  onChange={(event) => {
                    this.setState({
                      pictureUploaded: true,
                      picture: event.target.files[0],
                    });
                  }}
                />
              </div>
              <div style={{ cursor: "pointer", height: "100%" }}>
                <div
                  style={{
                    borderRadius: "4px",
                    border: "solid 1px #cdcdce",
                    height: "100%",
                    display: "flex",
                    justifyContent: "center",
                    padding: 5,
                    position: "relative",
                  }}
                >
                  {this.state.pictureUploaded ? (
                    <img
                      alt=""
                      src={URL.createObjectURL(this.state.picture)}
                      style={{ maxWidth: "60%", width: "fit-content" }}
                      height="fit-content"
                    />
                  ) : (
                    <img
                      src={this.state.picture || ""}
                      width="50px"
                      height="auto"
                      style={{ margin: 50 }}
                    />
                  )}
                  <div
                    role="presentation"
                    style={{
                      height: 35,
                      backgroundColor: "#E4E7EE",
                      width: "100%",
                      position: "absolute",
                      bottom: 0,
                      left: 0,
                      color: "#40A7FF",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                    onClick={this.getPicture}
                  >
                    Upload
                  </div>
                  <div
                    role="presentation"
                    style={{
                      position: "absolute",
                      top: 5,
                      right: 10,
                      cursor: "pointer",
                    }}
                    onClick={(event) => {
                      event.stopPropagation();
                      event.preventDefault();
                      document.getElementById("uploadFile").value = null;
                      this.setState({
                        picture: null,
                        pictureUploaded: false,
                      });
                    }}
                  >
                    <img src={closeMark} alt="x-mark" />
                  </div>
                </div>
              </div>
            </div> */}
          </div>
          <div className="form-row">
            {this.renderSubInfo(
              "Department",
              "department",
              employee.department,
              true,
              10,
              false
            )}
            {this.renderMultiSelect(
              this.props.campuses,
              this.state.campuses,
              (event) => {
                this.setState({
                  campuses: event,
                  selectedCampuses:
                    (event &&
                      event.length > 0 &&
                      event.map((campus) => {
                        return campus.value;
                      })) ||
                    [],
                });
              },
              "Campuses"
            )}
          </div>
          <div className="form-row">
            <div>
              {this.renderSubInfo(
                "Personal Phone *",
                "personalPhone",
                employee.personal_phone,
                true,
                10,
                true
              )}
              {!allowPersonalPhone &&
                personalPhone &&
                newEmployee &&
                showAlert && (
                  <div style={{ color: "red", marginBottom: 5, fontSize: 10 }}>
                    The phone number must be length of 10*
                  </div>
                )}
            </div>
            <div>
              {this.renderSubInfo(
                "Personal Email *",
                "personalEmail",
                employee.personal_email,
                true,
                10,
                true
              )}
              {showAlert && !personalEmail && (
                <div style={{ color: "red", marginBottom: 5, fontSize: 10 }}>
                  This field should not be empty
                </div>
              )}
            </div>
          </div>
          <div className="form-row">
            <div>
              {this.renderSubInfo(
                "Work Phone",
                "workPhone",
                employee.work_phone,
                true,
                10,
                false
              )}
              {!allowWorkPhone && workPhone && newEmployee && showAlert && (
                <div style={{ color: "red", marginBottom: 5, fontSize: 10 }}>
                  The phone number must be length of 10*
                </div>
              )}
            </div>
            {this.renderSubInfo(
              "Work Email",
              "workEmail",
              employee.work_email,
              true,
              10,
              false
            )}
          </div>
          <div className="form-row">
            {this.renderDropDown(
              [
                { label: "Male", value: 0 },
                { label: "Female", value: 1 },
              ],
              this.state.gender,
              (event) => {
                this.setState({ gender: event });
              },
              "Gender"
            )}
            {this.renderSubInfo(
              "Date Of Birth",
              "dateOfBirth",
              employee.date_of_birth,
              true,
              10,
              false,
              "date"
            )}
            {this.renderDropDown(
              Object.keys(BLOOD_GROUP).map((group) => {
                return {
                  value: BLOOD_GROUP[group],
                  label: group,
                };
              }),
              this.state.bloodGroup,
              (event) => {
                this.setState({ bloodGroup: event });
              },
              "Blood Group"
            )}
          </div>
          <div className="heading-2">Current Address </div>
          <div className="form-row">
            {this.renderSubInfo(
              "Line 1",
              "currentAddressLineOne",
              employee.current_address && employee.current_address.line_one,
              true,
              5,
              false
            )}
            {this.renderSubInfo(
              "Line 2",
              "currentAddressLineTwo",
              employee.current_address && employee.current_address.line_two,
              true,
              5,
              false
            )}
            {this.renderSubInfo(
              "City",
              "currentAddressCity",
              employee.current_address && employee.current_address.city,
              true,
              5,
              false
            )}
            {this.renderSubInfo(
              "Pincode",
              "currentAddressPinCode",
              employee.current_address && employee.current_address.pin_code,
              true,
              10,
              false
            )}
          </div>
          <div className="heading-2">Permanent Address </div>
          <div className="form-row">
            {this.renderSubInfo(
              "Line 1",
              "permanentAddressLineOne",
              employee.permanent_address && employee.permanent_address.line_one,
              true,
              5,
              false
            )}
            {this.renderSubInfo(
              "Line 2",
              "permanentAddressLineTwo",
              employee.permanent_address && employee.permanent_address.line_two,
              true,
              5,
              false
            )}
            {this.renderSubInfo(
              "City",
              "permanentAddressCity",
              employee.permanent_address && employee.permanent_address.city,
              true,
              5,
              false
            )}
            {this.renderSubInfo(
              "Pincode",
              "permanentAddressPinCode",
              employee.permanent_address && employee.permanent_address.pin_code,
              true,
              10,
              false
            )}
          </div>
          <div className="heading-2">Emergency Contact </div>
          <div className="form-row">
            {this.renderSubInfo(
              "Name",
              "emergencyContactName",
              employee.emergency_contact_name,
              true,
              5,
              false
            )}
            <div>
              {this.renderSubInfo(
                "Number",
                "emergencyContactNumber",
                employee.emergency_contact_number,
                true,
                5,
                false
              )}
              {!allowEmergencyContactPhone &&
                emergencyContactNumber &&
                newEmployee &&
                showAlert && (
                  <div style={{ color: "red", marginBottom: 5, fontSize: 10 }}>
                    The phone number must be length of 10*
                  </div>
                )}
            </div>
            {this.renderSubInfo(
              "Relationship",
              "emergencyContactRelationship",
              employee.emergency_contact_relationship,
              true,
              5,
              false
            )}
          </div>
          <div className="form-row">
            {this.renderSubInfo(
              "Date Of Joining",
              "dateOfJoining",
              employee.date_of_joining,
              true,
              10,
              false,
              "date"
            )}
            {this.renderSubInfo("Pan", "pan", employee.pan, true, 10, false)}
            {this.renderSubInfo(
              "Father's Name",
              "fatherName",
              employee.father_name,
              true,
              10,
              false
            )}
          </div>
          {newEmployee && (
            <div className="form-row">
              {this.renderSubInfo(
                "Current Salary",
                "currentSalary",
                employee.current_salary,
                true,
                10,
                false,
                "number"
              )}
            </div>
          )}
          <div>
            {this.renderDropDown(
              Object.keys(EMPLOYMENT_STATUS).map((group) => {
                return {
                  value: EMPLOYMENT_STATUS[group],
                  label: group,
                };
              }),
              this.state.status,
              (event) => {
                this.setState({ status: event });
              },
              "Employment Status *"
            )}
            {showAlert && !status && (
              <div style={{ color: "red", marginBottom: 5, fontSize: 10 }}>
                This field should not be empty
              </div>
            )}
          </div>
          <div className="form-row">
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                margin: 15,
                marginLeft: "auto",
              }}
            >
              <div
                className="Button secondary-Button"
                style={{ marginRight: 10, width: 150 }}
                onClick={() => this.props.handleCloseModal()}
              >
                Cancel
              </div>
              <div
                className="Button primary-Button"
                style={{ width: 150 }}
                onClick={
                  !noerror
                    ? this.state.newEmployee
                      ? () => this.allowNewEmployeeCreation()
                      : () => this.allowUpdateCurrentEmployeeDetails()
                    : (event) => {
                        this.setState(
                          { showAlert: true },
                          event.preventDefault()
                        );
                      }
                }
              >
                {this.state.newEmployee ? "Add Employee" : "Update Employee"}
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }

  render() {
    return (
      <Modal open={this.props.showModal} onClose={this.props.handleCloseModal}>
        <div>{this.renderEdit()}</div>
      </Modal>
    );
  }
}

AddEmployee.propTypes = {};

AddEmployee.defaultProps = {};

const mapStateToProps = ({ hrBoard }) => ({});

export default connect(mapStateToProps, {})(AddEmployee);
