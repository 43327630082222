import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { DateRangePicker } from 'react-date-range';
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';

class DateRange extends PureComponent {
  render() {
    const {
      showSelectionPreview, color, showPreview, selectedRange,
    } = this.props;
    return (
      <>
        <style>
          {`
          .rdrInputRanges{
            display: none;
          }
        `}
        </style>
        <DateRangePicker
          showSelectionPreview={showSelectionPreview}
          ranges={[{ ...selectedRange }]}
          rangeColors={[color]}
          maxDate={new Date()}
          showPreview={showPreview}
          fixedHeight
          onChange={(data) => this.props.handleSelect(data)}
        />
      </>
    );
  }
}

DateRange.propTypes = {
  color: PropTypes.array,
  showSelectionPreview: PropTypes.bool,
  showPreview: PropTypes.bool,
  selectedRange: PropTypes.object,
};

DateRange.defaultProps = {
  color: '#1c3e6b',
  showSelectionPreview: false,
  showPreview: false,
  selectedRange: {
    startDate: new Date(),
    endDate: new Date(),
    key: 'selection',
  },
};

export default DateRange;
