import Constants from '../shared/constants';

const constants = Constants();

export const getLocalTime = () => {
  const date = new Date();
  const timeZoneOffset = date.getTimezoneOffset() * -1;
  return new Date(date.getTime() + (timeZoneOffset * 60000));
};

export const numberWithCommas = (number) => {
  return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
};

export const validateContact = (phone) => {
  const intRegex = /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/;
  return !((phone.length < 10) || (!intRegex.test(phone)));
};

export const validateEmail = (email) => {
  const emailRegex = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return !((email.length < 6) || (!emailRegex.test(email)));
};

export const validateCommaSeperatedEmails = (emails) => {
  const emailRegex = /^(([a-zA-Z0-9_\-\.]+)@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.)|(([a-zA-Z0-9\-]+\.)+))([a-zA-Z]{2,4}|[0-9]{1,3})(\]?)(\s*,\s*|\s*$))*$/;
  return (emailRegex.test(emails));
};

export const returnCurrentDate = () => {
  const now = new Date();
  const day = (`0${now.getDate()}`).slice(-2);
  const month = (`0${now.getMonth() + 1}`).slice(-2);
  const today = `${now.getFullYear()}-${month}-${day}`;
  return today;
};

export const getAccountsDomain = () => {
  const protocolScheme = constants.cookie.secure ? 'https://' : 'http://';
  return protocolScheme + constants.cookie.accountsDomain;
};

export const getLogoutUrl = () => {
  return `${getAccountsDomain()}/logout`;
};

export const getParentPortalDomain = () => {
  const protocolScheme = constants.cookie.secure ? 'https://' : 'http://';
  return protocolScheme + constants.cookie.parentPortalDomain;
};

export const getInsightsDomain = () => {
  const protocolScheme = constants.cookie.secure ? 'https://' : 'http://';
  return protocolScheme + constants.cookie.insightsDomain;
};

export const getContentDomain = () => {
  const protocolScheme = constants.cookie.secure ? 'https://' : 'http://';
  return protocolScheme + constants.cookie.contentDomain;
};

export const getFeeDomain = () => {
  const protocolScheme = constants.cookie.secure ? 'https://' : 'http://';
  return protocolScheme + constants.cookie.feeDomain;
};

export const getHomeDomain = () => {
  const protocolScheme = constants.cookie.secure ? 'https://' : 'http://';
  return protocolScheme + constants.cookie.homeDomain;
};

export const getLiveQuizDomain = () => {
  const protocolScheme = constants.cookie.secure ? 'https://' : 'http://';
  return protocolScheme + constants.cookie.liveQuizDomain;
};

export const isValidNumber = (number) => {
  const data = number && number.toString();
  const isNumber = data && !isNaN(data) && data.indexOf('-') <= 0;
  return isNumber;
};

export const lengthOfNumber = (number) => {
  return Math.log(number) * Math.LOG10E + 1 | 0;
};

export const mathjax = () => {
  return typeof window !== 'undefined' && window && window.MathJax && window.MathJax.Hub && window.MathJax.Hub.Queue(['Typeset', window.MathJax.Hub]);
};

export const checkDevice = () => {
  if (navigator.userAgent.match(/mobile/i)) {
    global.sessionStorage.setItem('device', 'Mobile');
  } else if (navigator.userAgent.match(/iPad|Android|Touch/i)) {
    global.sessionStorage.setItem('device', 'Tablet');
  } else {
    global.sessionStorage.setItem('device', 'Desktop');
  }
};

export const isMobileDevice = () => {
  if (typeof window === 'undefined') {
    return '';
  }
  return typeof window.orientation !== 'undefined' || navigator.userAgent.indexOf('IEMobile') !== -1;
};

export function keyValueArray(value) {
  return Object.keys(value).map((key) => {
    return { title: key, value: value[key] };
  });
}

export const toTitleCase = (string) => {
  return string[0].toUpperCase() + string.slice(1, string.length);
};

export const UUID = () => {
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, (c) => {
    let r = Math.random() * 16 | 0, v = c === 'x' ? r : (r&0x3|0x8); // eslint-disable-line
    return v.toString(16);
  });
};

export const secondsToHm = (seconds) => {
  seconds = seconds ? parseInt(seconds, 10) : 0;
  const hours = Math.floor(seconds / 3600);
  const minute = Math.floor((seconds % 3600) / 60);

  const hourDisplay = hours > 0 ? hours + (hours === 1 ? ' hour' : ' hours') : '';
  const minuteDisplay = minute > 0 ? minute + (minute === 1 ? ' minute' : ' minutes') : '';
  const isBoth = hourDisplay && minuteDisplay ? ', ' : '';

  return `${hourDisplay}${isBoth}${minuteDisplay}`;
};

export const getNumberFromString = (string) => {
  const data = string && string.match(/\d+/);
  return (data && data[0]) || '';
};

export const getDateString = (date) => {
  return date.toString().split(' ').splice(1, 3).join(' ');
};

export const getStringFromISODate = (date) => {
  return date.toISOString().split('T')[0];
};

export const jsonToQueryString = (json) => {
  return `?${
    Object.keys(json).map((key) => {
      return `${encodeURIComponent(key)}=${
        encodeURIComponent(json[key])}`;
    }).join('&')}`;
};

export const asciiForMathJax = () => {
  const asciiMathJax = 'U = 1/(R_(si) + sum_(i=1)^n(s_n/lambda_n) + R_(se))';
  return asciiMathJax;
};

export const convertToCurrencyFormat = (num, currency = "₹ ") => {
  return (currency + num).replace(/(\d)(?=(\d\d)+\d$)/g, "$1,");
};
