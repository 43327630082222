import React, { Component } from 'react';
import { connect } from 'react-redux';
import ReactPlayer from 'react-player';
import PropTypes from 'prop-types';
import Cookies from 'universal-cookie';
import Loader from '../loading';
import Constants from '../../shared/constants';
import { DurationPicker } from '../common';
import videoFolder from '../../static/images/common/video-folder.svg';
import { getVideoRecordings, updateVirtualMeetingConcepts } from '../../actions';

const endingDate = new Date();
endingDate.setMonth(new Date().getMonth() - 1);
const yesterday = new Date();
yesterday.setDate((yesterday).getDate() - 1);
const cookies = new Cookies();
const roles = cookies.get('roles');

class VideoRecordings extends Component {
  constructor(props) {
    super(props);
    const query = new URLSearchParams(window.location.search);
    this.state = {
      showLoader: true,
      isSubject: query.get('is_subject'),
      isChapter: query.get('is_chapter'),
      isTopic: query.get('is_topic'),
      isVideo: query.get('is_video'),
      id: query.get('id'),
      next: '',
      back: '',
      isEditable: 0,
      timeData: {},
      isPlay: -1,
    };
    this.renderData = this.renderData.bind(this);
    this.renderVideo = this.renderVideo.bind(this);
  }

  componentDidMount() {
    const {
      isSubject, isChapter, isTopic, id, isVideo,
    } = this.state;
    this.setState({ showLoader: true, id });
    this.props.getVideoRecordings({
      is_subject: isSubject,
      is_chapter: isChapter,
      is_checkpoint: isTopic,
      is_data: isVideo,
      id,
    }).then(() => {
      let next = isChapter ? 'is_topic' : 'is_video';
      let back = isChapter ? 'is_subject' : 'is_chapter';
      next = isSubject ? 'is_chapter' : next;
      back = isVideo ? 'is_topic' : back;
      const { meetings } = this.props;
      const timeData = {};
      if (isVideo) {
        meetings.map((meeting) => {
          timeData[meeting.concept_id] = { start_at: meeting.starts_at, ends_at: meeting.ends_at };
        });
      }
      this.setState({
        showLoader: false, next, back, timeData,
      });
    });
  }

  renderData(id, name, count) {
    const { next } = this.state;
    return (
      <div
        role="presentation"
        style={{
          padding: 20,
          marginBottom: 20,
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          opacity: count ? 1 : 0.5,
          boxShadow: '0px 1px 4px #0000003d',
          cursor: count ? 'pointer' : 'not-allowed',
          width: '100%',
          background: '#FFFFFF',
        }}
        onClick={() => window.location.replace(`/classboard/video_recordings?id=${id}&${next}=true`)}
      >
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <img alt="" src={videoFolder} height="40px" width="40px" />
          <div style={{ marginRight: 30, marginLeft: 30 }}>{name}</div>
        </div>
        <div style={{ whiteSpace: 'nowrap' }}>
          {count > 1 ? `${count} Files` : `${count} File`}
        </div>
      </div>
    );
  }

  renderVideo(meeting, index) {
    const { isEditable, timeData, isPlay } = this.state;
    const url = meeting.recording_url ? meeting.recording_url.replace('/view', '/preview').replace('watch?v=', 'embed/').replace('/vimeo.com', '/player.vimeo.com/video') : '';
    const isEdit = isEditable === meeting.concept_id;
    const start = timeData[meeting.concept_id].start_at;
    const end = timeData[meeting.concept_id].ends_at;
    const startSeconds = ((start.hours || 0) * 60 * 60)
    + ((start.minutes || 0) * 60) + (start.seconds || 0);
    const endSeconds = ((end.hours || 0) * 60 * 60)
    + ((end.minutes || 0) * 60) + (end.seconds || 0);
    const startText = start && (start.hours || start.minutes || start.seconds) ? `${start.hours ? `${start.hours} hours` : ''}  ${start.minutes ? `${start.minutes} minutes` : ''} ${start.seconds ? `${start.seconds} seconds` : ''}` : '0 minutes';
    const endText = end && (end.hours || end.minutes || end.seconds) ? `${end.hours ? `${end.hours} hours` : ''}  ${end.minutes ? `${end.minutes} minutes` : ''} ${end.seconds ? `${end.seconds} seconds` : ''}` : '0 minutes';
    if (url.length > 10) {
      return (
        <div style={{ marginBottom: 60, display: 'flex' }}>
          <ReactPlayer
            url={`${url}?start=${startSeconds || null}&end=${endSeconds || null}`}
            playing={index === isPlay}
            loop={index === isPlay}
            disabledHours
            hideDisabledOptions
            placement="bottomLeft"
            width="50%"
            height="300px"
          />
          <div style={{
            marginLeft: 50, width: '50%', display: 'flex', justifyContent: 'space-between', height: 300, flexDirection: 'column',
          }}
          >
            <div>
              {meeting.title && <div style={{ marginBottom: 10 }}>{meeting.title}</div>}
              {meeting.description && <div style={{ marginBottom: 10 }}>{meeting.description}</div>}
              <div style={{ marginBottom: 10, display: 'flex', alignItems: 'center' }}>
                <div style={{
                  marginRight: 10, paddingBottom: isEdit ? 15 : 0, width: 85,
                }}
                >
                  Start Time :
                </div>
                {isEdit
                  ? (
                    <DurationPicker
                      setData={(time) => {
                        timeData[meeting.concept_id].start_at = time.time;
                        this.setState({ timeData });
                      }}
                      data={start}
                    />
                  )
                  : <div>{startText}</div> }
              </div>
              <div style={{ marginBottom: 10, display: 'flex', alignItems: 'center' }}>
                <div style={{
                  marginRight: 10, paddingBottom: isEdit ? 15 : 0, width: 85,
                }}
                >
                  End Time :
                </div>
                {isEdit ? (
                  <DurationPicker
                    setData={(time) => {
                      timeData[meeting.concept_id].ends_at = time.time;
                      this.setState({ timeData });
                    }}
                    data={end}
                  />
                )
                  : <div>{endText}</div>}
              </div>
            </div>
            <div
              role="presentation"
              style={{
                padding: 10, border: 'none', width: 100, marginLeft: 'auto',
              }}
              className="Button Primary-Button"
              onClick={() => {
                if (isEdit) {
                  this.props.updateVirtualMeetingConcepts(
                    JSON.stringify(timeData[meeting.concept_id].start_at
                    || {}),
                    JSON.stringify(timeData[meeting.concept_id].ends_at || {}),
                    meeting.concept_id,
                  );
                }
                this.setState({
                  isPlay: index === isPlay ? -1 : index,
                  isEditable:
                isEditable === meeting.concept_id ? 0 : meeting.concept_id,
                });
              }}
            >
              { isEdit ? 'UPDATE' : 'EDIT'}
            </div>
          </div>
        </div>
      );
    }
    return <div />;
  }

  renderHeader() {
    const { meetings } = this.props;
    const { back } = this.state;
    return (
      <div style={{
        fontWeight: 600, fontSize: 24, padding: '0 80px', height: 70, display: 'flex', alignItems: 'center', background: '#FFFFFF', color: Constants().color.primary, boxShadow: '0px 3px 6px #343B4014', position: 'fixed', width: '80%', zIndex: 10, justifyContent: 'space-between',
      }}
      >
        <div>
          {(meetings[0] && meetings[0].name) || 'Video Library'}
        </div>
        {meetings[0] && meetings[0].id && (
          <div
            role="presentation"
            style={{ fontSize: 22, cursor: 'pointer' }}
            onClick={() => window.location.replace(`/classboard/video_recordings?id=${meetings[0].id}&${back}=true`)}
          >
            &#x276E;&nbsp;&nbsp;BACK
          </div>
        )}
      </div>
    );
  }

  render() {
    const {
      showLoader, isSubject, isChapter, isTopic, isVideo,
    } = this.state;
    const { meetings } = this.props;
    if (showLoader) {
      return <div style={{ display: 'flex', placeContent: 'center', height: '100%' }}><Loader left="20%" width="80%" /></div>;
    }
    if (meetings.length === 0) {
      return (
        <div style={{
          background: '#F5F8FA', height: typeof window !== 'undefined' && window.innerHeight, fontFamily: 'Roboto', color: Constants().color.primary,
        }}
        >
          {this.renderHeader()}
          <div style={{ padding: 80, paddingTop: 100 }}>
            <div
              style={{
                display: 'flex', justifyContent: 'center', alignItems: 'center', color: '#454545', height: 80, backgroundColor: '#FFFFFF', marginTop: 100, border: '1px solid', fontSize: 24, fontFamily: 'Roboto',
              }}
            >
              No Files To Display
            </div>
          </div>
        </div>
      );
    }
    return (
      <div style={{
        background: '#F5F8FA', height: typeof window !== 'undefined' && window.innerHeight, fontFamily: 'Roboto', color: Constants().color.primary,
      }}
      >
        {this.renderHeader()}
        <div style={{ padding: 80, paddingTop: 100 }}>
          {isSubject && meetings.map((subject) => this.renderData(subject.subject_id,
            subject.subject_name, subject.file_count))}
          {isChapter && meetings.map((chapter) => this.renderData(chapter.chapter_id,
            chapter.chapter_name, chapter.file_count))}
          {isTopic && meetings.map((checkpoint) => this.renderData(checkpoint.checkpoint_id,
            checkpoint.checkpoint_name, checkpoint.file_count))}
          {isVideo && meetings.map((meeting, index) => this.renderVideo(meeting, index))}
        </div>
      </div>
    );
  }
}

VideoRecordings.propTypes = {
  meetings: PropTypes.array,
};

VideoRecordings.defaultProps = {
  meetings: [],
};

const mapStateToProps = ({ virtualMeeting }) => ({
  meetings: virtualMeeting.meetings,
});

export default connect(mapStateToProps, {
  getVideoRecordings,
  updateVirtualMeetingConcepts,
})(VideoRecordings);
