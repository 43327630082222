import _ from 'lodash';
import React, { Component } from 'react';
import Select from 'react-select';
import makeAnimated from 'react-select/animated';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Modal from './baseModal';
import {
  createNewAssignment, createNewClassroom,
  updateAssignment, DEFAULT_NUMBER_OF_SETS,
} from '../../actions';
import { sendEvent } from '../../helpers/Analytics';
import Constants from '../../shared/constants';

const animatedComponents = makeAnimated();

class TestAssignmentModal extends Component {
  constructor(props) {
    super(props);
    const {
      name, testDuration, showModal, testDate, selectedClassroomList,
      selectedTestGroup, selectedSubject, questionLimit,
      isOnline, hasMultipleTestSets,
    } = props;
    const duration = parseInt(testDuration, 0);
    this.state = {
      buttonLoader: false,
      newAssignmentName: name,
      isPreviousYearPaper: false,
      selectedPreviousYearPaper: {},
      showCreateAssignmentModal: showModal,
      testDate: testDate || new Date().toISOString().slice(0, 10),
      selectedClassroom: selectedClassroomList,
      selectedTestGroup,
      selectedSubject: selectedSubject || [],
      questionLimit: questionLimit || {},
      isOnline: isOnline || false,
      setCount: hasMultipleTestSets,
      testDuration: { hours: parseInt(duration / 60, 0), minutes: parseInt(duration % 60, 0) },
      showCustomPlaceholder: false,
      classroomPlaceholder: 'Type to search',
    };
    this.toggleAssignmentModal = this.toggleAssignmentModal.bind(this);
    this.createAssignment = this.createAssignment.bind(this);
    this.redirectToConfigPage = this.redirectToConfigPage.bind(this);
    this.updateFieldValueChanges = this.updateFieldValueChanges.bind(this);
  }

  getQuestionCount() {
    const {
      homePage, assignmentQuestionLimit,
      subjectMapping, isTestDataEditable,
    } = this.props;
    const { questionLimit, selectedSubject } = this.state;
    if (homePage) {
      const subjectIds = selectedSubject && selectedSubject.value ? selectedSubject.value : [];
      const width = Object.keys(subjectIds).length === 1 ? '100%' : '48%';
      return (
        <div style={{ display: 'flex', width: '100%', justifyContent: 'space-between' }}>
          {subjectIds.map((id) => (
            <input
              id="questions-count"
              key={`subjectId_${id}`}
              type="number"
              className="mt-4"
              max={assignmentQuestionLimit}
              min="0"
              value={questionLimit[`${subjectMapping[id]}_1`]}
              onChange={(event) => this.updateFieldValueChanges('questionLimit', event.target.value, `${subjectMapping[id]}_1`)}
              placeholder={`${subjectMapping[id] || ''} Q count`}
              style={{ width, height: 50 }}
            />
          ))}
        </div>
      );
    }
    const ids = Object.keys(questionLimit);
    const width = ids.length === 1 ? '100%' : '48%';
    return (
      <div style={{ display: 'flex', width: '100%', justifyContent: 'space-between' }}>
        {ids.map((name) => (
          <input
            key={`subjectId_${name}`}
            type="number"
            className="mt-4"
            max={assignmentQuestionLimit}
            min="0"
            value={questionLimit[name]}
            onChange={(event) => this.updateFieldValueChanges('questionLimit', event.target.value, name)}
            placeholder={`${name.split('_')[0]} question count`}
            style={{
              width, height: 50, cursor: !isTestDataEditable && 'not-allowed', backgroundColor: !isTestDataEditable && '#ddd',
            }}
            readOnly={!isTestDataEditable}
          />
        ))}
      </div>
    );
  }

  createOrUpdate(data, payload) {
    const {
      isPreviousYearPaper, selectedSubject,
    } = this.state;
    const {
      isTestPage, globalHistory, homePage, id,
    } = this.props;
    if (data.success) {
      if (homePage) {
        if (isTestPage && !isPreviousYearPaper) {
          sendEvent('Assignment', 'TestCreated', 'log', payload);
          globalHistory.push(`/test_config/${data.id}`);
        } else {
          sendEvent('Assignment', 'AssignmentCreated', 'log', payload);
          // if (Object.keys(questionLimit).length > 1) {
          globalHistory.push(`/test_creation_overview/${data.id}`);
          // } else {
          // globalHistory.push(`/test_chapter_selection/${data.id}?subject_id=${selectedSubject.value}&track=1`);
          // }
        }
      } else if (selectedSubject !== this.props.selectedSubject && !isTestPage) {
        globalHistory.push(`/test_chapter_selection/${id}?subject_id=${selectedSubject.value}&track=1`);
      } else {
        alert('Fields were updated');
        this.toggleAssignmentModal();
      }
    } else {
      const message = data.message ? data.message : data.data.message;
      alert(message);
      sendEvent('Assignment', 'DuplicateAssignmentNameAlert', 'log', { payload });
    }
  }

  createAssignment() {
    if (this.state.buttonLoader) return;
    this.setState({ buttonLoader: true });
    const {
      newAssignmentName, selectedTestGroup, selectedClassroom, questionLimit,
      selectedSubject, testDate, isOnline, testDuration, setCount,
      isPreviousYearPaper, selectedPreviousYearPaper,
    } = this.state;
    const {
      isTestPage, assignmentQuestionLimit,
      isTrial, classroomList, homePage, id,
    } = this.props;
    let totalQuestionCount = 0;
    const newData = {};
    let hasNegativeValues = false;
    Object.keys(questionLimit).forEach((subject) => {
      const count = parseInt(questionLimit[subject], 10) || 0;
      totalQuestionCount += count;
      if (count < 0) {
        hasNegativeValues = true;
      } else if (count > 0) {
        newData[subject] = questionLimit[subject];
      }
    });
    const classroomId = [];
    const duration = parseInt(testDuration.hours * 60, 10) + parseInt(testDuration.minutes, 10);
    if (selectedClassroom) {
      selectedClassroom.forEach((e) => { classroomId.push(e.value); });
    }
    if (newAssignmentName === '') {
      alert('Name cannot be empty');
      this.setState({ buttonLoader: false })
    } else if (!isPreviousYearPaper && !selectedTestGroup) {
      alert(isTestPage ? 'Test must have a test group' : 'Course should be selected');
      this.setState({ buttonLoader: false });
    } else if (isPreviousYearPaper && _.isEmpty(selectedPreviousYearPaper)) {
      alert('Select a Previous Year Paper');
      this.setState({ buttonLoader: false });
    } else if (!isTestPage && selectedSubject.length === 0) {
      alert('Subject must be selected.');
      this.setState({ buttonLoader: false });
    } else if (isTestPage && (duration <= 0 || !duration)) {
      alert('Test Duration cannot be 0 minutes.');
      this.setState({ buttonLoader: false });
    } else if (!testDate) {
      alert('Date should be specified');
      this.setState({ buttonLoader: false });
    } else if ((classroomId.length === 0) && !isTrial && classroomList.length) {
      alert('Classroom should be selected');
      this.setState({ buttonLoader: false });
    } else if (!isTestPage && (hasNegativeValues
      || totalQuestionCount === 0 || totalQuestionCount > assignmentQuestionLimit)) {
      let message = '';
      if (hasNegativeValues) {
        message = 'Question count cannot not be negative';
      } else if (totalQuestionCount === 0) {
        message = 'Question count has to be specified it can be altered later.';
      } else if (totalQuestionCount > assignmentQuestionLimit) {
        message = `Total Question count should be less than or equal to ${assignmentQuestionLimit}.`;
      }
      alert(message);
      this.setState({ buttonLoader: false });
    } else {
      const payload = {
        isOnline,
        name: newAssignmentName,
        testDate,
        testDuration: duration,
        classroomId: JSON.stringify(classroomId),
        testGroupId: selectedTestGroup ? selectedTestGroup.value : '',
        previousYearPaperId: selectedPreviousYearPaper.value ? selectedPreviousYearPaper.value : '',
        isAssignment: !isTestPage,
        setCount: setCount ? DEFAULT_NUMBER_OF_SETS : 1,
        questionLimit: JSON.stringify(newData),
      };
      if (!homePage) {
        payload.id = id;
      }
      sendEvent('Assignment', 'CreateAssignmentSubmit', 'click', payload);
      if (homePage) {
        this.props.createNewAssignment(payload).then((data) => {
          this.setState({ buttonLoader: false });
          this.createOrUpdate(data, payload);
        });
      } else {
        this.props.updateAssignment(payload).then((data) => {
          this.setState({ buttonLoader: false });
          this.createOrUpdate(data, payload);
        });
      }
    }
  }

  createOption(label) {
    const { createdClassroomId } = this.props;
    return {
      label,
      value: createdClassroomId,
    };
  }

  newClassroomCreate(inputValue) {
    const { hasAccess } = this.props;
    const { selectedClassroom } = this.state;
    if (hasAccess) {
      this.props.createNewClassroom(inputValue).then((data) => {
        if (data.success) {
          const newOption = this.createOption(inputValue);
          this.setState({
            selectedClassroom: [...selectedClassroom, newOption],
          }, this.updateFieldValueChanges('selectedClassroom', selectedClassroom));
        }
      });
    } else {
      alert('You dont have permission');
    }
  }

  updateFieldValueChanges(type, value, subType = '', clearData = false) {
    if (type === 'selectedClassroom' && !value) {
      this.setState({ [type]: [] });
      return;
    }
    if (!value.is_test || value.is_test) {
      if (subType) {
        if (clearData) {
          this.setState({ [subType]: [], [type]: value });
        } else {
          const stateType = this.state[type];
          stateType[subType] = value;
          this.setState({ [type]: stateType });
        }
      } else {
        this.setState({ [type]: value });
      }
    }
  }

  redirectToConfigPage() {
    const { showCreateAssignmentModal } = this.state;
    const { globalHistory, id } = this.props;
    this.setState({
      showCreateAssignmentModal: !showCreateAssignmentModal,
    });
    globalHistory.push(`/test_config/${id}`);
  }

  toggleAssignmentModal() {
    const { showCreateAssignmentModal } = this.state;
    const {
      previousPage, refreshOnClose,
    } = this.props;
    sendEvent('Assignment', !showCreateAssignmentModal ? 'CreateAssignmentModalOpened' : 'CreateAssignmentModalClosed', 'log');
    this.setState({
      showCreateAssignmentModal: !showCreateAssignmentModal,
    });
    if (refreshOnClose) {
      window.location.reload();
    }
    if (previousPage) {
      this.props.onCloseEdit();
    }
  }

  renderPreviousYearPaperSlider() {
    const { isPreviousYearPaper } = this.state;
    return (
      <label style={{
        display: 'flex',
        marginBottom: 10,
        alignItems: 'center',
      }}
      >
        Previous Year Paper
        <div
          style={{ marginLeft: 10 }}
          className={`react-toggle ${isPreviousYearPaper && 'react-toggle--checked'}`}
        >
          <div className="react-toggle-track">
            <div className="react-toggle-track-check" />
            <div className="react-toggle-track-x" />
          </div>
          <div className="react-toggle-thumb" />
          <input className="react-toggle-screenreader-only" type="checkbox" style={{ backgroundColor: 'white' }} onClick={() => this.setState({ isPreviousYearPaper: !isPreviousYearPaper })} />
        </div>
      </label>
    );
  }

  render() {
    const {
      classroomList, testGroupList, previousYearPaperList, isTestPage, homePage, hasAccess,
      subjectList, isReviewed, isTestDataEditable, inactive, isTrial,
      isTestLockedByOtp, testOtpType, showTest, isSearchable,
      url, text, isPreviousYearPaperCopy,
    } = this.props;
    const {
      selectedTestGroup, showCustomPlaceholder,
      classroomPlaceholder, setCount, isOnline,
      newAssignmentName, testDate, testDuration, selectedSubject, selectedPreviousYearPaper,
      selectedClassroom, showCreateAssignmentModal, isPreviousYearPaper,
    } = this.state;
    let subjects = [];
    const testGroups = [];
    if (homePage) {
      if (!isTestPage) {
        subjects = subjectList[selectedTestGroup.value];
      }
      testGroupList.forEach((testGroup) => {
        if ((isTestPage && testGroup.is_test) || (!isTestPage && !testGroup.is_test)) {
          testGroups.push(testGroup);
        }
      });
    }

    const style = {
      backgroundColor: Constants().color.primary, color: Constants().primaryBackgroundColor, fontSize: 14, width: 180, height: 40, display: 'flex', justifyContent: 'center', alignItems: 'center', borderRadius: 4,
    };
    let modalText = '';
    if (homePage) {
      modalText = (isTestPage) ? 'New Test' : 'New Assignment';
    } else {
      modalText = (isTestPage) ? 'Edit Test' : 'Edit Assignment';
    }
    const customStyles = {
      control: (base, state) => {
        return ({
          ...base,
          minHeight: 50,
          maxHeight: 70,
          overflow: 'scroll',
          width: 375,
          backgroundColor: state.isDisabled ? '#ddd' : '#ffffff',
          cursor: state.isDisabled ? 'not-allowed' : 'pointer',
          textAlign: 'center',
          border: '1px solid var(--#ddd) !important',
        });
      },
      option: (base, state) => {
        if (state.data.__isNew__) {
          return ({
            ...base,
            color: Constants().color.primary,
            fontWeight: 'bold',
            textAlign: 'center',
            backgroundColor: '#FFFFF',
            cursor: hasAccess ? 'pointer' : 'not-allowed',
            opacity: hasAccess ? '1' : '.5',
            fontSize: 18,
          });
        }
        return ({
          ...base,
          border: '1px solid #F8F8F8',
          color: '#343B40',
          backgroundColor: state.isFocused ? '#DEEBFF' : '#FFFFFF',
        });
      },
    };
    const classroomCurrentPlaceholder = (showCustomPlaceholder) ? classroomPlaceholder : 'Classrooms';
    return (
      <div>
        {(homePage && (!isTestPage || hasAccess))
          && (
          <div
            role="presentation"
            id="new-assignment"
            className="btn"
            style={{ ...style, opacity: (inactive) ? '.5' : '1', cursor: (inactive) ? 'not-allowed' : 'pointer' }}
            onClick={() => (!inactive) && this.toggleAssignmentModal()}
          >
            {modalText}
          </div>
          )}
        {(url && !homePage && (!isTestPage || hasAccess))
          && (
          <div
            role="presentation"
            className="mb-2 cursor pt-3 pb-3"
            style={{ backgroundColor: 'white', textAlign: 'center' }}
          >
            <img alt="" src={url} height={30} width={30} color="#ddd" />
            <div className="mt-1" style={{ color: Constants().color.primary, fontSize: 12, fontWeight: 'bold' }}>
              {text}
            </div>
          </div>
          )}
        <Modal
          showModal={showCreateAssignmentModal}
          handleCloseModal={() => this.toggleAssignmentModal()}
        >
          <div style={{ margin: 30 }}>
            <input
              id="AssignmentName"
              onChange={(event) => this.updateFieldValueChanges('newAssignmentName', event.target.value.replace(/[&\/\\#,+^@$~%.'":*?<>{}!]/g, ''))}
              type="text"
              placeholder={isTestPage ? 'Test Name' : 'Assignment Name'}
              value={newAssignmentName}
              style={{
                width: '100%', height: 50, cursor: !isTestDataEditable && 'not-allowed', backgroundColor: !isTestDataEditable && '#ddd', marginBottom: 15,
              }}
              autoFocus
              readOnly={!isTestDataEditable}
            />
            <input
              id="TestDate"
              onChange={(event) => this.updateFieldValueChanges('testDate', event.target.value)}
              type="date"
              value={testDate}
              style={{
                width: '100%', height: 50, paddingLeft: 10, cursor: !isTestDataEditable && 'not-allowed', backgroundColor: !isTestDataEditable && '#ddd', marginBottom: 15,
              }}
              readOnly={!isTestDataEditable}
            />
            {(homePage && isTestPage) && this.renderPreviousYearPaperSlider()}
            {(!isReviewed && homePage && !isPreviousYearPaper)
              && (
              <Select
                id="testgroup-course"
                styles={customStyles}
                className="selectDropDownList"
                value={selectedTestGroup}
                options={testGroups}
                placeholder={isTestPage ? 'Test Group' : 'Course'}
                isSearchable={false}
                onChange={(selectedTest) => {
                  this.updateFieldValueChanges('selectedTestGroup', selectedTest);
                  this.setState({ selectedSubject: '', testDuration: { hours: parseInt(selectedTest.test_duration / 60, 10), minutes: parseInt(selectedTest.test_duration % 60, 10) } });
                }}
              />
              )}
            {(homePage && isTestPage && isPreviousYearPaper) && (
              <Select
                id="previous-year-paper"
                styles={customStyles}
                className="selectDropDownList"
                value={selectedPreviousYearPaper}
                options={previousYearPaperList}
                placeholder="Previous Paper"
                isSearchable={false}
                onChange={(selectedPaper) => {
                  this.updateFieldValueChanges('selectedPreviousYearPaper', selectedPaper);
                  this.setState({ selectedSubject: '', testDuration: { hours: parseInt(selectedPaper.test_duration / 60, 10), minutes: parseInt(selectedPaper.test_duration % 60, 10) } });
                }}
              />
            )}
            {isTestPage
              && (
              <div style={{ marginTop: 10 }}>
                Test Duration:
                <div style={{ display: 'flex', marginTop: 5 }}>
                  <div>
                    <input
                      type="number"
                      value={testDuration.hours}
                      style={{ width: 75, height: 50, backgroundColor: !isTestDataEditable && '#ddd' }}
                      onChange={(event) => this.updateFieldValueChanges('testDuration', (((parseInt(event.target.value, 10) < 10 && parseInt(event.target.value, 10) >= 0) || !event.target.value) ? event.target.value : testDuration.hours), 'hours')}
                      readOnly={!isTestDataEditable}
                    />
                    &nbsp;&nbsp;Hours
                  </div>
                  <div style={{ paddingLeft: 30 }}>
                    <input
                      type="number"
                      value={testDuration.minutes}
                      style={{ width: 75, height: 50, backgroundColor: !isTestDataEditable && '#ddd' }}
                      onChange={(event) => this.updateFieldValueChanges('testDuration', (((parseInt(event.target.value, 10) < 60 && parseInt(event.target.value, 10) >= 0) || !event.target.value) ? event.target.value : testDuration.minutes), 'minutes')}
                      readOnly={!isTestDataEditable}
                    />
                    &nbsp;&nbsp;Minutes
                  </div>
                </div>
              </div>
              )}
            {(subjects && !isTestPage && !isReviewed && homePage)
              && (
              <Select
                id="subject-dropdown"
                isDisabled={!selectedTestGroup}
                styles={customStyles}
                value={selectedSubject}
                options={subjects}
                placeholder="Subject"
                className="mt-4"
                isSearchable={false}
                maxMenuHeight={200}
                onChange={(selectedSubjects) => this.updateFieldValueChanges('selectedSubject', selectedSubjects, 'questionLimit', true)}
              />
              )}
            {(!isTestPage && !isReviewed)
              && <div>{this.getQuestionCount()}</div>}
            {!isTrial && classroomList.length > 0
              && (
              <Select
                id="classroom-selected"
                isMulti
                styles={customStyles}
                value={selectedClassroom}
                closeMenuOnSelect={false}
                components={animatedComponents}
                isSearchable={isSearchable}
                options={classroomList}
                className={homePage && 'mt-3'}
                placeholder={classroomCurrentPlaceholder}
                onFocus={() => this.setState({ showCustomPlaceholder: true })}
                onBlur={() => this.setState({ showCustomPlaceholder: false })}
                onChange={(selectedClassrooms) => this.updateFieldValueChanges('selectedClassroom', selectedClassrooms)}
                onCreateOption={(selectedClassrooms) => this.newClassroomCreate(selectedClassrooms)}
                isDisabled={showTest}
              />
              )}
            {(!isTestPage)
              && (
              <div className="mt-3 d-flex flex-row">
                <input id="multiple-sets-assignment" type="checkbox" checked={setCount} onChange={() => this.setState({ setCount: !setCount })} style={{ fontSize: 'x-large', marginTop: 10, cursor: 'pointer' }} />
                <label className="ml-3" style={{ fontSize: 16, fontWeight: 'bold', margin: 5 }}> Multiple Sets </label>
              </div>
              )}
            {hasAccess
              && (
              <div className="mt-3 d-flex flex-row">
                <input id="is-online" type="checkbox" checked={isOnline} onChange={() => this.setState({ isOnline: !isOnline })} style={{ fontSize: 'x-large', marginTop: 10, cursor: 'pointer' }} />
                <label className="ml-3" style={{ fontSize: 16, fontWeight: 'bold', margin: 5 }}> Online Test </label>
              </div>
              )}
            {(isTestPage && selectedTestGroup && !homePage && !isPreviousYearPaperCopy)
              && <button type="button" className="btn btn-link" onClick={this.redirectToConfigPage}>Config</button>}
            {(isOnline) && isTestPage && (isTestLockedByOtp)
              && (
              <div style={{ fontSize: 12 }}>
                <span>
                  Preference: The test will be locked if a student moves out
                </span>
                <br />
                <span>
                  of the screen
                </span>
              </div>
              )}
            {isOnline && isTestPage && testOtpType
              && (
              <>
                Test OTP Type:
                {testOtpType}
              </>
              )}
            <div
              role="presentation"
              id="confirm-create"
              className="btn mt-4"
              style={{
                backgroundColor: Constants().color.primary,
                color: 'white',
                fontSize: 16,
                width: '100%',
                height: 50,
                textAlign: 'center',
                padding: 12,
                opacity: this.state.buttonLoader ? 0.5 : 1,
              }}
              onClick={this.createAssignment}
            >
              <strong>{homePage ? 'Next' : 'Done'}</strong>
            </div>
          </div>
        </Modal>
      </div>
    );
  }
}

TestAssignmentModal.propTypes = {
  previousPage: PropTypes.number,
  isTrial: PropTypes.bool,
  isSearchable: PropTypes.bool,
  refreshOnClose: PropTypes.bool,
};

TestAssignmentModal.defaultProps = {
  previousPage: null,
  isTrial: true,
  isSearchable: false,
  refreshOnClose: false,
};

const mapStateToProps = ({ home, assignment }) => ({
  createdClassroomId: home.createdClassroomId,
  isSearchable: home.hasMentorBoardAccess || assignment.hasMentorBoardAccess,
});

export default connect(mapStateToProps, {
  createNewClassroom,
  updateAssignment,
  createNewAssignment,
})(TestAssignmentModal);
