import Api from '../helpers/Api';

export const GET_CHAPTER_LIST = 'imageQuestionView/GET_CHAPTER_LIST';
export const GET_QUESTION_FROM_CHAPTER = 'imageQuestionView/GET_QUESTION_FROM_CHAPTER';
export const UPDATE_QUESTION_IMAGE_SIZE = 'imageQuestionView/UPDATE_QUESTION_IMAGE_SIZE';

export function getChapterListFromSubject(subjectId) {
  return (dispatch) => Api({
    method: 'get',
    url: `/questions/${subjectId}/get_chapters_from_subject`,
  }).then((response) => {
    if (response.success) {
      dispatch({
        type: GET_CHAPTER_LIST,
        payload: response.chapters,
      });
    }
  });
}

export function getQuestionFromChapter(chapterId, showOffline, showRoadmap, page) {
  return (dispatch) => Api({
    method: 'get',
    url: `/questions/${chapterId}/get_question_from_chapter?show_offline=${showOffline}&show_roadmap=${showRoadmap}&page=${page}`,
  }).then((response) => {
    if (response.success) {
      dispatch({
        type: GET_QUESTION_FROM_CHAPTER,
        payload: response.question,
      });
      return response;
    }
  });
}
export function getQuestionFromTest(testId, page) {
  return (dispatch) => Api({
    method: 'get',
    url: `/questions/${testId}/get_question_from_test?page=${page}`,
  }).then((response) => {
    if (response.success) {
      dispatch({
        type: GET_QUESTION_FROM_CHAPTER,
        payload: response.question,
      });
      return response;
    }
  });
}

export function updateQuestionImageSize(questionId, attributeType, value) {
  return () => Api({
    method: 'post',
    url: '/questions/update_question_image_size',
    data: { question_id: questionId, attribute_type: attributeType, size_value: value },
  }).then((response) => {
    if (response.success) {
      return response.success;
    }
  });
}
