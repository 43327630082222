import React from 'react';
import ReactTooltip from 'react-tooltip';
import { connect } from 'react-redux';
import Cookies from 'universal-cookie';
import Layout from '../layout';
import {
  isMobileDevice, getInsightsDomain, getContentDomain, getFeeDomain,
} from '../../helpers/util';
import { showUserDetails, getUserTypes } from '../../actions';
import Constants from '../../shared/constants';
import ReferalModal from '../modal/referalModal';
import TermsForReferalModal from '../modal/termsForReferalModal';
import './home.css';
import '../modal/referalModal.css';
import Loader from '../loading';
import questionsBoard from '../../static/images/questionsBoard.svg';
import insightsBoard from '../../static/images/insightsBoard.svg';
import filesBoard from '../../static/images/filesBoard.svg';
import virtualMeeting from '../../static/images/virtualMeeting.svg';
import quizBoard from '../../static/images/quizBoard.svg';
import contentBoard from '../../static/images/contentBoard.svg';
import feeBoard from '../../static/images/rupee-symbol.svg';
import interactionBoard from '../../static/images/interactionBoard.svg';
import surveysBoard from '../../static/images/surveysBoard.svg';
import admissionBoard from '../../static/images/admission_board.png'
import hrBoard from "../../static/images/hr-board.svg"

const IS_MOBILE = isMobileDevice();

class Home extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showLoader: true,
    };
  }

  componentDidMount() {
    this.props.getUserTypes().then(() => {
      this.props.showUserDetails().then(() => {
        this.setState(() => ({ showLoader: false }));
      });
    });
  }

  renderSpecificDomain(img, text, info, backgroundColor, fontColor, access) {
    const boxShadow = (!access) ? '0px 3px 6px 2px #343B4014' : '';
    const textStyle = { fontSize: IS_MOBILE ? '18px' : '24px', fontWeight: 'bold', color: fontColor };
    const infoStyle = {
      marginRight: 5, marginLeft: 5, alignItems: 'center', fontSize: IS_MOBILE ? '14px' : '19px', color: fontColor,
    };
    return (
      <div
        role="presentation"
        style={{
          display: 'flex', flexDirection: 'column', cursor: 'pointer', alignItems: 'center', justifyContent: 'space-between', borderWidthColor: 'black', borderWidth: 3, height: 'fit-content', width: IS_MOBILE ? 155 : 300, boxShadow, userSelect: 'none', margin: IS_MOBILE ? 5 : 10,
        }}
      >
        <div style={{ padding: 17, height: '50%' }}>
          <img alt="" src={img} style={{ height: IS_MOBILE ? '45px' : '90px' }} />
        </div>
        <div style={{
          backgroundColor, height: '50%', width: '100%', paddingTop: 8, minHeight: IS_MOBILE ? '100px' : '125px', display: 'flex', flexDirection: 'column', textAlign: 'center',
        }}
        >
          <div style={textStyle}>{text}</div>
          <div style={infoStyle}>{info}</div>
        </div>
      </div>
    );
  }

  renderDomain(img, text, type, info, backcolor, fontColor, linkDisabled, access, toolTipMessage) {
    if (linkDisabled) {
      return (
        <div data-place="right" data-tip={toolTipMessage} style={{ opacity: 0.4 }}>
          <ReactTooltip effect="solid" />
          {this.renderSpecificDomain(img, text, info, backcolor, fontColor, access)}
        </div>
      );
    }
    return (
      <a
        style={{ textDecoration: 'none' }}
        href={type}
      >
        {this.renderSpecificDomain(img, text, info, backcolor, fontColor, access)}
      </a>
    );
  }

  render() {
    const { showLoader } = this.state;
    if (showLoader) {
      return <Loader type="welcome" />;
    }
    const { newUser, hasContentBoardAccess, hasFeeBoardAccess, isDtpAdmin, isAccountant,isAdmin, hasAdmissionBoardAccess } = this.props;
    const user = new Cookies().get('user');
    const referralBannerWidth = 1000;
    const isHigherEducation = user && user.is_higher_education;
    const pagesData = [
      {
        url: "/list/assignment",
        name: "questionsBoard",
        img: questionsBoard,
        text: "Questions Board",
        info: "Create tests and assignments in 10 min.",
        backColor: "#D0ECE6",
        fontColor: "#4B7B7A",
        isDisabled: false,
        show: !isHigherEducation,
        access: newUser,
      },
      {
        url: getInsightsDomain(),
        name: "insightsBoard",
        img: insightsBoard,
        text: "Insights Board",
        info: "Get to know your students better than ever before.",
        backColor: "#EFEFE7",
        fontColor: "#817A75",
        isDisabled: false,
        show: !isHigherEducation,
        access: false,
      },
      {
        url: `${getFeeDomain()}${
          !isAdmin && isAccountant ? "/student_list" : "/dashboard"
        }`,
        name: "feeBoard",
        img: feeBoard,
        text: "Fee Board",
        info: "Automate your institute’s fee management",
        backColor: "#2ff5e1",
        fontColor: "#198a7e",
        isDisabled: !hasFeeBoardAccess,
        show: !isHigherEducation,
        access: false,
        toolTipMessage: `Contact customer support - ${Constants().contact}`,
      },
      {
        url: "/files_board",
        name: "filesBoard",
        img: filesBoard,
        text: "Files Board",
        info: "Share files with your students.",
        backColor: "#E3F7FF",
        fontColor: "#0476BE",
        isDisabled: IS_MOBILE,
        show: !isHigherEducation,
        access: false,
      },
      {
        url: isDtpAdmin
          ? "/classboard/dashboard"
          : "/classboard/virtual_meeting",
        name: "virtualMeeting",
        img: virtualMeeting,
        text: "Class Board",
        info: "Conduct Live Classes for your Students",
        backColor: "#ffe3fd",
        fontColor: "#bf65b9",
        isDisabled: false,
        show: true,
        access: false,
      },
      {
        url: "/quizboard",
        name: "quizBoard",
        img: quizBoard,
        text: "Quiz Board",
        info: "Interact with your students, parents and teachers.",
        backColor: "#FFE3E3",
        fontColor: "#BE0404A3",
        isDisabled: false,
        show: true,
        access: false,
      },
      {
        url: getContentDomain(),
        name: "contentBoard",
        img: contentBoard,
        text: "Content Board",
        info: "Import your own content",
        backColor: "#22222224",
        fontColor: "#6C757D",
        isDisabled: !hasContentBoardAccess,
        show: !isHigherEducation,
        access: false,
        toolTipMessage: `Contact customer support - ${Constants().contact}`,
      },
      {
        url: "/admission_board/applicants",
        img: admissionBoard,
        name: "admissionBoard",
        text: "Admission Board",
        info: "Manage your admissions.",
        backColor: "#F5DEB3",
        fontColor: "#8B4513",
        isDisabled: !hasAdmissionBoardAccess,
        show: !isHigherEducation,
        access: false,
        toolTipMessage: `Contact customer support - ${Constants().contact}`,
      },
      {
        url: "/hr_board/employees_list",
        img: hrBoard,
        name: "hrBoard",
        text: "HR Board",
        info: "Manage your staff.",
        backColor: "#8aa5e6",
        fontColor: "#102961",
        isDisabled: false,
        show: !isHigherEducation,
        access: false,
      },
      {
        url: "/interaction",
        img: interactionBoard,
        name: "interactionBoard",
        text: "Interaction Board",
        info: "Interact with your students, parents and teachers.",
        backColor: "#E3F7FF",
        fontColor: "#0476BE",
        isDisabled: true,
        show: !isHigherEducation,
        access: false,
      },
      {
        url: "/survey",
        img: surveysBoard,
        name: "surveysBoard",
        text: "Surveys Board",
        info: "Conduct surveys and get feedback about your institute.",
        backColor: "#F6E2E6",
        fontColor: "#B16693",
        isDisabled: true,
        show: !isHigherEducation,
        access: false,
      },
    ];
    if (IS_MOBILE === '') {
      return <Layout />;
    }
    return (
      <Layout globalHistory={this.props.history} isMobile={IS_MOBILE}>
        <div style={{
          backgroundColor: '#FAFAFA', display: 'flex', flexDirection: 'column', alignItems: 'center',
        }}
        >
          <div
            className="nunito-body"
            style={{
              padding: 10, paddingTop: 30, display: 'flex', flexFlow: 'row wrap', width: IS_MOBILE ? 350 : 1000, alignItems: 'center', justifyContent: 'space-around', marginBottom: 20,
            }}
          >
            {pagesData.map((pageData) => {
              if (pageData.show) {
                return this.renderDomain(pageData.img, pageData.text, pageData.url, pageData.info, pageData.backColor, pageData.fontColor, pageData.isDisabled, pageData.access, pageData.toolTipMessage || 'Coming Soon!');
              }
              return <div />;
            })}
          </div>
          {/* {!IS_MOBILE
            && (
            <div style={{
              display: 'flex', justifyContent: 'center', maxWidth: '50%', margin: 'auto', marginBottom: 20,
            }}
            >
              <div
                className="refer-now-container"
                style={{
                  display: 'flex', justifyContent: 'space-between', position: isHigherEducation ? 'absolute' : 'relative', padding: 25, cursor: 'default', width: referralBannerWidth, bottom: 20,
                }}
              >
                <div className="refer-now-container-text">
                  <div>For Every Institute you refer,</div>
                  <div>Earn upto ₹ 10,000/- *</div>
                </div>
                <div style={{
                  display: 'flex', flexDirection: 'column', justifyContent: 'space-between', marginRight: 50,
                }}
                >
                  <ReferalModal />
                  <div style={{ textAlign: 'center', color: '#FFFFFF', fontSize: 16 }}>
                    Call &nbsp;
                    {Constants().contact}
                  </div>
                </div>
                <div style={{ left: 28, bottom: 5, position: 'absolute' }}>
                  <TermsForReferalModal />
                </div>
              </div>
            </div>
            )} */}
        </div>
      </Layout>
    );
  }
}

const mapStateToProps = ({ institute, auth }) => ({
  newUser: institute.newUser,
  hasContentBoardAccess: institute.hasContentBoardAccess,
  hasFeeBoardAccess: institute.hasFeeBoardAccess,
  isDtpAdmin: institute.isDtpAdmin,
  isAccountant: institute.isAccountant,
  isAdmin: auth.isAdmin,
  hasAdmissionBoardAccess: institute.hasAdmissionBoardAccess
});

export default connect(mapStateToProps, { showUserDetails, getUserTypes })(Home);
