import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import Select from 'react-select';
import PropTypes from 'prop-types';
import ReactTooltip from 'react-tooltip';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import deleteIcon from '../../static/images/deleteIcon.svg';
import google from '../../static/images/google.png';
import moreMenu from '../../static/images/more-menu.svg';
import Modal from '../modal/baseModal';
import Loader from '../loading';
import {
  getVirtualMeetingList, createVirtualMeeting, applyInstitueFilters,
  createMeetingSignature, toggleMeetingStatus, hideVirtualMeeting, updateShowStatusQuizQuestion,
  updateVirtualMeeting, createLiveQuiz, updateSubjectsFilter,
  updateQuizQuestions, setHostedTime, updateGAuthuStatus, deleteVirtualMeeting,
  endOnGoingMeetings, updateTeacherFilter, showToast,
} from '../../actions';
import { toTitleCase, isMobileDevice } from '../../helpers/util';
import { DropDown, ApplyFilter } from '../common';
import constants from '../../shared/constants';
import {
  TRACKS, RECURRENCE_TYPES,
  DAYS_OF_THE_WEEK,
} from '../../constants';
import { LIVE_QUIZ_RESULT_UPDATE_CHANNEL } from '../common/showQuizQuestion';
import Navigator from '../dayNavigator';
import LocaleDate from '../localeDate';
import CurrentClasses from '../currentClasses';
import VirtualMeetingFiles from '../modal/virtualMeetingFilesModal';
import VirtualMeetingRecordings from '../modal/virtualMeetingRecordingsModal';

const MINUTES_BEFORE_STUDENTS_ALLOWED_TO_JOIN = 10;

const inputStyle = {
  width: '100%', height: 44, border: '1px solid #7C94BA', borderRadius: 4, marginBottom: 0,
};
const IS_MOBILE = isMobileDevice();
class VirtualMeeting extends PureComponent {
  constructor(props) {
    super(props);
    this.ZoomMeeting = window.ZoomMtg;
    this.state = {
      startDate: '',
      startTime: '',
      endDate: '',
      daysAgo: 0,
      showLoader: true,
      showFilterModal: false,
      selectedClassrooms: [],
      showUrlModal: false,
      selectedDaysOfWeek: [],
      pageContentHeight: 0,
      meetingLaunched: false,
      leaveUrl: constants().leaveUrl,
      virtualMeetingData: {},
      showVirtualMeetingModal: false,
      isEditVirtualMeetingModal: false,
      newQuizIds: {},
      teachersMailIDs: [],
      selectedSubjectName: '',
      currentMenu: 0,
    };
    this.onLoad = this.onLoad.bind(this);
    this.handleUrlChange = this.handleUrlChange.bind(this);
    this.addRecordingUrl = this.addRecordingUrl.bind(this);
    this.renderFilter = this.renderFilter.bind(this);
    this.applyDataFilters = this.applyDataFilters.bind(this);
    this.joinZoomMeeting = this.joinZoomMeeting.bind(this);
    this.reloadPage = this.reloadPage.bind(this);
    this.filterView = this.filterView.bind(this);
    this.handleCloseFiltermodal = this.handleCloseFiltermodal.bind(this);
  }

  componentDidMount() {
    this.props.applyInstitueFilters({
      selectedCampus: {},
      selectedClassroom: null,
      selectedDepartment: {},
      selectedSemester: {},
      selectedSubject: {},
    });
    const script = window.document.createElement('script');
    script.type = 'text/javascript';
    script.src = 'https://source.zoom.us/zoom-meeting-1.8.0.min.js';
    window.document.getElementsByTagName('body')[0].appendChild(script);
    script.addEventListener('load', this.onLoad);
    window.addEventListener('click', (e) => {
      const { currentMenu } = this.state;
      const ele = document.getElementById(`navbar-${currentMenu}`);
      if (!(ele && ele.contains(e.target))) {
        this.setState({ currentMenu: 0 });
      }
    });

    const { showCurrentClass } = this.props;
    if (!showCurrentClass) {
      this.props.getVirtualMeetingList().then(() => {
        this.setState({
          pageContentHeight: window.innerHeight,
        });
        this.setState(() => ({ showLoader: false }));
      });
    } else {
      this.setState({
        pageContentHeight: window.innerHeight,
      });
      this.setState(() => ({ showLoader: false }));
    }
  }

  onLoad() {
    this.ZoomMeeting = window.ZoomMtg;
    this.ZoomMeeting.setZoomJSLib('https://source.zoom.us/1.8.0/lib', '/av');
    this.ZoomMeeting.preLoadWasm();
    this.ZoomMeeting.prepareJssdk();
    this.turnZoomMeetingOff();
  }

  turnZoomMeetingOn() {
    if (typeof window !== 'undefined') {
      const zoomMeeting = window.document.getElementById('zmmtg-root');
      if (zoomMeeting) {
        if (zoomMeeting.style.removeProperty) {
          zoomMeeting.style.removeProperty('display');
        } else {
          zoomMeeting.style.removeAttribute('display');
        }
      }
    }
  }

  turnZoomMeetingOff() {
    if (typeof window !== 'undefined') {
      const zoomMeeting = window.document.getElementById('zmmtg-root');
      if (zoomMeeting) {
        zoomMeeting.style.display = 'none';
      }
    }
  }

  deleteVirtualMeeting() {
    const { virtualMeetingData, daysAgo } = this.state;
    if (!virtualMeetingData.id) {
      return;
    }
    this.toggleVirtualMeeting();
    const includeFutureOccurences = (virtualMeetingData.is_recurring_edit);
    this.props.deleteVirtualMeeting(virtualMeetingData.id, includeFutureOccurences).then(() => {
      this.reloadPage(daysAgo);
    });
  }

  joinZoomMeeting(meeting, isJoinee = true) {
    const timeToAllowHostStart = new Date(meeting.start_time);
    const timeToAllowHostEnd = new Date(meeting.start_time);
    timeToAllowHostStart.setMinutes(timeToAllowHostStart.getMinutes() - MINUTES_BEFORE_STUDENTS_ALLOWED_TO_JOIN);
    timeToAllowHostEnd.setMinutes(timeToAllowHostEnd.getMinutes() + meeting.duration);
    const isAllowedToHost = new Date() < timeToAllowHostEnd && timeToAllowHostStart < new Date();
    const { newQuizIds } = this.state;
    const quizId = meeting.quiz_id || newQuizIds[meeting.id];
    if (!isAllowedToHost) {
      return this.props.showToast('Please join the meeting during the allocated time');
    }
    const virtualMeet = this;
    const {
      userName, showCurrentClass, useZoom,
    } = this.props;
    const { leaveUrl, meetingLaunched } = this.state;
    if (useZoom) {
      if (isJoinee || showCurrentClass) {
        window.open(meeting.join_url);
        return;
      }
      virtualMeet.props.setHostedTime(meeting.id);
      virtualMeet.props.toggleMeetingStatus(meeting.id, true);
      window.open(meeting.start_url);
      return;
    }

    if (!window.ZoomMtg) {
      return;
    }
    const meetConfig = {
      meetingNumber: meeting.meeting_id,
      leaveUrl,
      userName: userName || 'user',
      passWord: meeting.password,
      // role 0 is joinee, role 1 is host
      role: (showCurrentClass || isJoinee) ? 0 : 1,
    };

    this.props.createMeetingSignature(meetConfig, meeting.id).then((response) => {
      if (response.success) {
        this.setState({ meetingLaunched: !meetingLaunched }, () => {
          this.turnZoomMeetingOn();
        });
        this.ZoomMeeting.init({
          leaveUrl: (showCurrentClass || isJoinee) ? meetConfig.leaveUrl : `${meetConfig.leaveUrl}?id=${meeting.id}`,
          success() {
            window.ZoomMtg.join({
              meetingNumber: meetConfig.meetingNumber,
              userName: meetConfig.userName,
              signature: response.signature,
              apiKey: response.api_key,
              passWord: meetConfig.passWord,
              success() {
                if (!(showCurrentClass || isJoinee)) {
                  virtualMeet.props.setHostedTime(meeting.id);
                  virtualMeet.props.toggleMeetingStatus(meeting.id, true);
                } else {
                  window.removeEventListener('beforeunload', this.cleanupWhenLeave);
                }
                if (quizId) {
                  const actionCable = require('actioncable');
                  const cable = actionCable.createConsumer(constants().api.ws_url);
                  const subscription = cable.subscriptions.create({
                    channel: LIVE_QUIZ_RESULT_UPDATE_CHANNEL,
                    virtual_meeting_id: meeting.id,
                    quiz_id: quizId,
                  }, {
                    received: (data) => {
                      virtualMeet.props.updateQuizQuestions(data.questions, data.id);
                    },
                  });
                  virtualMeet.setState({ subscription, cable });
                }
              },
              error(e) {
                if (e.errorCode === 3000) {
                  alert('Please Wait. Exiting Meetings in Progress');
                  virtualMeet.props.endOnGoingMeetings(meeting.meeting_id, meetConfig.role, meeting.id);
                }
              },
            });
          },
        });
      } else {
        alert('Api Key for the created meeting is not present or has been disabled');
      }
    });
  }

  hideMeeting(meetingId) {
    this.props.hideVirtualMeeting(meetingId).then(() => {
      this.setState({ showLoader: true }, () => {
        this.props.getVirtualMeetingList().then(() => this.setState({ showLoader: false }));
      });
    });
  }

  toggleVirtualMeeting(virtualMeetingData = {}, time, isEditVirtualMeetingModal = false,
    selectedClassrooms = []) {
    let data = virtualMeetingData;
    const {
      recurrenceTypes, emails,
    } = this.props;
    const { showVirtualMeetingModal } = this.state;
    const startTime = time || '';
    const startDate = time || '';
    const endDate = '';
    const selectedDaysOfWeek = new Array();
    if (Object.keys(data).length === 0) {
      data = {
        subject: '',
        track: '',
        selectedRecurrence: recurrenceTypes[0],
        selectedDaysOfWeek,
        email: emails[0],
      };
    }
    this.setState({
      selectedClassrooms,
      virtualMeetingData: data,
      showVirtualMeetingModal: !showVirtualMeetingModal,
      isEditVirtualMeetingModal,
      startTime,
      startDate,
      selectedDaysOfWeek,
      endDate,
    });
  }

  updateVirtualMeetingData(fieldName, fieldValue) {
    const { virtualMeetingData, selectedClassrooms } = this.state;
    const { teachers, emails } = this.props;
    virtualMeetingData[fieldName] = fieldValue;
    if (fieldName === 'subject') {
      this.props.updateTeacherFilter(fieldValue.id);
      const teachersMailIDs = [];
      if (virtualMeetingData.teacher) {
        virtualMeetingData.teacher = null;
      }
      if (teachers) {
        teachers.map((teacher) => {
          selectedClassrooms.map((classroom) => {
            if (teacher && teacher.subject_ids.length && teacher.subject_ids.includes(fieldValue.id)) {
              if (teacher.classroom_ids.length && teacher.classroom_ids.includes(classroom.value)) {
                virtualMeetingData.teacher = teacher;
                const hostEmail = emails.find((email) => email.name === teacher.host_email);
                if (hostEmail) {
                  virtualMeetingData.email = hostEmail;
                }
              }
            }
          });
        });
      }
      this.setState({ teachersMailIDs, selectedSubjectName: fieldValue.name });
    }
    if (fieldName === 'teacher') {
      const hostEmail = emails.find((email) => email.teacher_id === fieldValue.id);
      if (hostEmail) {
        virtualMeetingData.email = hostEmail;
      }
    }
    this.setState({ virtualMeetingData: { ...virtualMeetingData } });
  }

  handleMultiSelect(key, value) {
    const { isHigherEducation } = this.props;
    this.setState({ [key]: value });
    if (isHigherEducation && value && value.length) {
      this.props.updateSubjectsFilter(value);
    }
  }

  // updateGoogleCalenderStatus(subjectName, startTime, emailIds, minutes) {
  //   const attendeesdMail = [];
  //   emailIds.map((mail) => {
  //     attendeesdMail.push({ email: mail });
  //   });
  //   if (gapi.auth2.getAuthInstance().isSignedIn.get()) {
  //     const event = {
  //       summary: `${subjectName}`,
  //       location: constants().liveMeetingUrl,
  //       description: 'Classes',
  //       start: {
  //         dateTime: moment(startTime).format('YYYY-MM-DDTHH:mm:ss'),
  //         timeZone: 'Asia/Calcutta',
  //       },
  //       end: {
  //         dateTime: moment(startTime).add(minutes, 'minutes').format('YYYY-MM-DDTHH:mm:ss'),
  //         timeZone: 'Asia/Calcutta',
  //       },
  //       attendees: attendeesdMail,
  //       reminders: {
  //         useDefault: false,
  //         overrides: [
  //           { method: 'popup', minutes: 10 },
  //         ],
  //       },
  //     };
  //     if (gapi.client && gapi.client.calendar) {
  //       const request = gapi.client.calendar.events.insert({
  //         calendarId: 'primary',
  //         resource: event,
  //       });
  //       return request;
  //     }
  //     alert('Calender event not added, please contact us 1');
  //   } else {
  //     alert('Calender event not added, please contact us 2');
  //   }
  //   return true;
  // }

  updateVirtualMeeting() {
    const {
      virtualMeetingData, selectedClassrooms, startTime, startDate,
      daysAgo, selectedDaysOfWeek, endDate,
    } = this.state;
    const includeFutureOccurences = (virtualMeetingData.is_recurring_edit);
    // const { gAuthStatus } = this.props;
    const isRecurringMeeting = virtualMeetingData.selectedRecurrence
    && virtualMeetingData.selectedRecurrence.id !== 1;
    const recordingData = '';
    const isEdit = !!virtualMeetingData.id;
    const isRecording = false;
    const selectedClassroomIds = selectedClassrooms
      ? selectedClassrooms.map((selectedClassroom) => selectedClassroom.value) : [];
    if (selectedClassroomIds.length === 0) {
      alert('Please select at least one classroom');
    } else if ((!virtualMeetingData.subject || !virtualMeetingData.subject.id) && !isEdit) {
      alert('Please specify Subject');
    } else if (!startTime || !startDate) {
      alert('Please specify valid start date and time');
    } else if (isRecurringMeeting && (!endDate || endDate < startDate)) {
      alert('Please Specify End Date greater than start date');
    } else if (isRecurringMeeting && !isEdit && (selectedDaysOfWeek.length === 0)) {
      alert('Please Specify Days when the meeting repeats');
    } else if (!isEdit && (!virtualMeetingData.teacher || !virtualMeetingData.teacher.id)) {
      alert('Please Specify the teacher who will be hosting the meeting');
    } else {
      this.toggleVirtualMeeting();
      if (virtualMeetingData.id) {
        this.props.updateVirtualMeeting(virtualMeetingData, recordingData,
          isRecording, startTime, startDate, selectedClassroomIds,
          includeFutureOccurences).then(() => {
          this.reloadPage(daysAgo);
        });
      } else {
        this.props.createVirtualMeeting(virtualMeetingData, selectedClassroomIds,
          startTime, startDate, endDate, selectedDaysOfWeek).then(() => {
          this.reloadPage(daysAgo);
        });
      }
    }
  }

  editVirtualMeeting(meeting, is_recurring_edit = false) {
    const {
      quizzes, tracks, classrooms, modules, lectures,
    } = this.props;
    const { newQuizIds } = this.state;
    const quizId = meeting.quiz_id || newQuizIds[meeting.id];
    const virtualMeetingData = {
      id: meeting.id,
      quiz: quizzes[quizzes.findIndex((quiz) => quiz.id === quizId)] || '',
      topic: meeting.title,
      track: tracks[tracks.findIndex((track) => track.name === meeting.track)] || '',
      duration: `${meeting.duration}`,
      is_recurring_edit,
      module_no: modules[modules.findIndex((module) => module.id === meeting.module)] || '',
      lecture_no: lectures[lectures.findIndex((lecture) => lecture.id === meeting.lecture)] || '',
      attendanceBasedOnQuiz: meeting.attendance_based_on_quiz_results,
    };
    const selectedClassrooms = classrooms.filter((classroom) => {
      return meeting.classroom_ids.includes(classroom.value);
    });
    this.toggleVirtualMeeting(virtualMeetingData,
      new Date(meeting.start_time), true, selectedClassrooms);
  }

  reloadPage(daysAgo, isFilter) {
    const {
      selectedClassroom, selectedCampus, selectedDepartment, selectedSemester,
    } = this.props;
    this.setState({ showFilterModal: false });
    this.setState({ daysAgo, showLoader: !isFilter && true }, () => {
      this.props.getVirtualMeetingList(daysAgo, (selectedClassroom && selectedClassroom.id) || '',
        (selectedCampus && selectedCampus.id) || '',
        (selectedDepartment && selectedDepartment.id) || '',
        (selectedSemester && selectedSemester.id) || '').then(() => {
        this.setState({ showLoader: false });
      });
    });
  }

  updateMeet(meeting) {
    const { showFilters, isHigherEducation } = this.props;
    const { newQuizIds, currentMenu } = this.state;
    const quizId = meeting.quiz_id || newQuizIds[meeting.id];
    const dropDownElementStyle = {
      display: 'flex', padding: '8px 25px',
    };
    const isCompleted = meeting.status === 'COMPLETED';
    return (
      <nav
        className="navbar navbar-expand-lg navbar-light"
        style={{
          marginBottom: 0, marginLeft: 0, marginRight: 20, padding: 0,
        }}
      >
        <style jsx>
          {`
              .main-${meeting.id} {
                display: ${currentMenu === meeting.id ? 'block !important' : 'none !important'};
              }
              .dropdown-item:hover{
                background: #F2F2F2;
              }
          `}
        </style>
        <div className="nav-item dropdown">
          <div
            role="presentation"
            className="navbar-toggler"
            type="button"
            data-target={`#nav${meeting.id}`}
            aria-controls={`nav${meeting.id}`}
            aria-label="Toggle navigation"
            style={{
              height: 31, width: 57, border: '1px solid', display: 'flex', alignItems: 'center', cursor: 'pointer', justifyContent: 'center',
            }}
            id={`navbar-${meeting.id}`}
            onClick={() => this.setState({ currentMenu: currentMenu === meeting.id ? 0 : meeting.id })}
          >
            <img alt="" className="cursor" src={moreMenu} width="25px" height="25px" />
          </div>
          <div
            style={{
              cursor: 'pointer', fontSize: 18, padding: 0, width: 180, marginTop: 10, marginBottom: 100,
            }}
            id={`nav${meeting.id}`}
            className={`dropdown-menu dropdown-menu-right main-${meeting.id}`}
            role="menu"
            aria-labelledby={`nav${meeting.id}`}
          >
            {(showFilters || !isHigherEducation)
            && (
            <div
              role="presentation"
              className="dropdown-item"
              onClick={() => this.joinZoomMeeting(meeting, true)}
              style={dropDownElementStyle}
            >
              Join Class
            </div>
            )}
            <div
              role="presentation"
              className="dropdown-item"
              onClick={() => this.editVirtualMeeting(meeting)}
              style={dropDownElementStyle}
            >
              Edit Class
            </div>
            {meeting.is_recurring && (
              <div
                role="presentation"
                className="dropdown-item"
                onClick={() => this.editVirtualMeeting(meeting, true)}
                style={dropDownElementStyle}
              >
                Edit Series
              </div>
            )}
            {!isCompleted && (
            <div
              role="presentation"
              className="dropdown-item"
              onClick={quizId ? () => window.open(`/quiz/quiz_questions/${quizId}`) : () => this.createQuiz(meeting.id)}
              style={dropDownElementStyle}
            >
              Quiz
            </div>
            )}
            <div
              role="presentation"
              className="dropdown-item"
              onClick={() => this.setState({ showFilesModal: true, meetingDetails: meeting })}
              style={dropDownElementStyle}
            >
              Files
            </div>
            <div
              role="presentation"
              className="dropdown-item"
              onClick={() => this.setState({
                showUrlModal: true,
                urlModalMeeting: { ...meeting },
                meetingDetails: { ...meeting },
              })}
              style={dropDownElementStyle}
            >
              Recording
            </div>
            {isCompleted && (
            <a
              href={(quizId) ? `/conduct_quiz/show_questions/${meeting.id}` : ''}
              className="dropdown-item"
              onClick={() => this.joinZoomMeeting(meeting, false)}
              style={dropDownElementStyle}
            >
              Host Again
            </a>
            )}
          </div>
        </div>
      </nav>
    );
  }

  handleUrlChange(id, value) {
    const { recordingUrl } = this.props;
    recordingUrl[id] = value;
    this.setState({ recordingUrl: { ...recordingUrl } });
  }

  addRecordingUrl(meeting, recordingData) {
    const { daysAgo } = this.state;
    this.props.updateVirtualMeeting(meeting, recordingData, true).then(() => {
      this.reloadPage(daysAgo);
    });
    this.setState({ showUrlModal: false });
  }

  createQuiz(meetingId) {
    const { newQuizIds } = this.state;
    const { createLiveQuiz, showToast } = this.props;
    createLiveQuiz(meetingId).then((response) => {
      if (response.success) {
        newQuizIds[meetingId] = response.quiz_id;
        this.setState({ newQuizIds: { ...newQuizIds } });
        window.open(`/quiz/quiz_questions/${response.quiz_id}`);
      } else {
        showToast('There was an error creating a quiz for the class');
      }
    });
  }

  handleCloseFiltermodal() {
    const { showFilterModal } = this.state;
    this.setState({ showFilterModal: !showFilterModal });
  }

  applyDataFilters() {
    const {
      daysAgo,
    } = this.state;
    this.reloadPage(daysAgo, true);
    this.setState({ showFilterModal: false });
  }

  filterView() {
    const {
      campuses, filterClassrooms, departments, semesters,
      isHigherEducation, selectedCampus, selectedDepartment,
      selectedClassroom, selectedSemester,
    } = this.props;
    return (
      <ApplyFilter
        campuses={[...campuses]}
        classrooms={[...(filterClassrooms || [])]}
        semesters={[...semesters]}
        departments={[...departments]}
        isHigherEducation={isHigherEducation}
        applyDataFilters={this.applyDataFilters}
        selectedCampus={{ ...selectedCampus }}
        selectedDepartment={{ ...selectedDepartment }}
        selectedClassroom={{ ...selectedClassroom }}
        selectedSemester={{ ...selectedSemester }}
        hideSubject
        hideTeacher
        ignoreRequired
      />
    );
  }

  handleChangeDays(day) {
    const { selectedDaysOfWeek } = this.state;
    const indexToRemove = selectedDaysOfWeek.indexOf(day);
    if (indexToRemove > -1) {
      selectedDaysOfWeek.splice(indexToRemove, 1);
    } else {
      selectedDaysOfWeek.push(day);
    }
    this.setState({ selectedDaysOfWeek });
  }

  // gapiAuthentication() {
  //   gapi.load('client:auth2', () => {
  //     gapi.client.init({
  //       apiKey: API_KEY,
  //       clientId: CLIENT_ID,
  //       discoveryDocs: DISCOVERY_DOCS,
  //       scope: SCOPES,
  //     });
  //     gapi.client.load('calendar', 'v3', () => { });
  //     gapi.auth2.getAuthInstance().signIn().then(() => {
  //       this.props.updateGAuthuStatus(true);
  //     });
  //   });
  // }

  renderDaysOfTheWeek(day) {
    return (
      <>
        <input
          type="checkbox"
          id={`virtual-meeting-weekday-${day}`}
        />
        <label
          role="presentation"
          htmlFor={`virtual-meeting-weekday-${day}`}
          onClick={() => {
            this.handleChangeDays(day);
          }}
        >
          {DAYS_OF_THE_WEEK[day][0]}
        </label>
      </>
    );
  }

  renderMultiSelectClassrooms(placeholder) {
    const { selectedClassrooms } = this.state;
    const { classrooms } = this.props;
    const customStyles = {
      control: (base, state) => {
        return ({
          ...base,
          marginBottom: 5,
          overflow: 'scroll',
          width: 360,
          backgroundColor: state.isDisabled ? '#ddd' : '#ffffff',
          cursor: state.isDisabled ? 'not-allowed' : 'pointer',
          textAlign: 'center',
          border: '1px solid #7C94BA !important',
        });
      },
      valueContainer: (base, state) => {
        return ({
          ...base,
          paddingLeft: 20,
        });
      },
      option: (base, state) => {
        if (state.data.__isNew__) {
          return ({
            ...base,
            color: constants().color.primary,
            fontWeight: 'bold',
            textAlign: 'center',
            backgroundColor: '#FFFFF',
            cursor: 'pointer',
            fontSize: 14,
          });
        }
        return ({
          ...base,
          border: '1px solid #F8F8F8',
          color: '#343B40',
          backgroundColor: state.isFocused ? '#DEEBFF' : '#FFFFFF',
        });
      },
    };
    return (
      <div>
        <style>
          {`
          input[type=text]:focus {
            outline: none !important;
          }
      `}
        </style>
        <Select
          isMulti
          value={selectedClassrooms}
          options={classrooms}
          className="basic-multi-select"
          classNamePrefix="select"
          styles={customStyles}
          placeholder={placeholder}
          onChange={(selectedClassroom) => this.handleMultiSelect('selectedClassrooms', selectedClassroom)}
        />
      </div>
    );
  }

  renderTimePicker(placeholder) {
    const { startTime } = this.state;
    return (
      <DatePicker
        selected={startTime}
        onChange={(date) => this.setState({ startTime: date })}
        timeInputLabel="Time:"
        showTimeSelect
        showTimeSelectOnly
        timeIntervals={60}
        dateFormat="h:mm aa"
        placeholderText={placeholder}
      />
    );
  }

  renderDropDownValues(fieldName, fieldValue, dropDownValues, placeholder,
    dropDownPlaceholder, updateValue) {
    const { selectedClassrooms } = this.state;
    const { filteredTeachers } = this.props;
    const filterItems = [];
    for (let i = 0; i < dropDownValues.length; i += 1) {
      filterItems[i] = dropDownValues[i];
    }
    return (
      <>
        {dropDownPlaceholder
          ? (
            <DropDown
              defaultText={placeholder}
              key={`${fieldValue.name}_${fieldName}_${selectedClassrooms && selectedClassrooms.length}_${filteredTeachers}`}
              selectedItem={fieldValue}
              optionsList={[...filterItems]}
              width="100%"
              height={40}
              color="#DDDDDD"
              placeholder={placeholder}
              onValueChange={updateValue}
            />
          )
          : (
            <DropDown
              key={`${fieldValue.name}_${fieldName}_${selectedClassrooms && selectedClassrooms.length}`}
              selectedItem={fieldValue}
              optionsList={[...filterItems]}
              width="100%"
              height={40}
              color="#DDDDDD"
              placeholder={placeholder}
              onValueChange={updateValue}
            />
          )}
      </>
    );
  }

  renderInputTag(fieldName, fieldValue, placeholder) {
    return (
      <input
        style={{ ...inputStyle, paddingLeft: 20 }}
        value={fieldValue}
        placeholder={placeholder}
        onChange={(e) => this.updateVirtualMeetingData(fieldName, e.target.value)}
      />
    );
  }

  renderCheckBox() {
    const { virtualMeetingData } = this.state;
    return (
      <div style={{ width: '100%', marginTop: IS_MOBILE ? '4%' : '1%' }}>
        <label style={{
          fontSize: 16, fontWeight: 'normal', display: 'flex', alignItems: 'center',
        }}
        >
          <input
            onChange={() => this.setState({ virtualMeetingData: { ...virtualMeetingData, attendanceBasedOnQuiz: !virtualMeetingData.attendanceBasedOnQuiz } })}
            type="checkbox"
            checked={virtualMeetingData.attendanceBasedOnQuiz}
            style={{ margin: 0, marginRight: '2%' }}
          />
          Attendance based on quiz results
        </label>
      </div>
    );
  }

  renderFieldInput(fieldName, fieldValue, placeholder, dropDownValues,
    showTimePicker, isMultiSelect, dropDownPlaceholder, isCheckbox) {
    if (isCheckbox) {
      return this.renderCheckBox();
    }
    if (isMultiSelect) {
      return this.renderMultiSelectClassrooms(placeholder);
    }
    if (showTimePicker) {
      return this.renderTimePicker(placeholder);
    }
    if (dropDownValues) {
      return this.renderDropDownValues(fieldName, { ...fieldValue },
        dropDownValues, placeholder, dropDownPlaceholder,
        (id, name) => this.updateVirtualMeetingData(fieldName,
          { id, name }));
    }

    return this.renderInputTag(fieldName, fieldValue, placeholder);
  }

  renderFieldEntry(fieldName, dropDownValues, showTimePicker = false, isMultiSelect = false,
    dropDownPlaceholder = true, isCheckbox = false) {
    const { virtualMeetingData } = this.state;
    let placeholder = toTitleCase(fieldName.split('_').join(' '));
    if (placeholder === 'Duration') {
      placeholder += ' (min)';
    }
    const fieldValue = virtualMeetingData[fieldName];
    return (
      <div
        className="virtual-meeting"
        style={{
          display: 'flex', width: '100%', justifyContent: 'center', alignItems: 'center', marginBottom: 10, paddingTop: 8,
        }}
      >
        {this.renderFieldInput(fieldName, fieldValue,
          placeholder, dropDownValues, showTimePicker, isMultiSelect,
          dropDownPlaceholder, isCheckbox)}
      </div>
    );
  }

  renderStartAndEndTime() {
    const { startDate, endDate, virtualMeetingData } = this.state;
    const isRecurringMeeting = virtualMeetingData.selectedRecurrence
    && virtualMeetingData.selectedRecurrence.id !== 1;
    return (
      <div style={{
        display: 'flex', width: '100%', alignItems: 'center', marginBottom: 10, paddingTop: 8,
      }}
      >
        <div style={{
          display: 'flex',
          flexDirection: 'row',
        }}
        >
          <div style={{ marginRight: 10, borderRadius: 4 }}>
            <DatePicker
              selected={startDate}
              onChange={(date) => this.setState({ startDate: date })}
              timeInputLabel="Time:"
              dateFormat="dd/MM/yyyy"
              placeholderText="Start Date"
            />
          </div>
          {isRecurringMeeting && !virtualMeetingData.id
            && (
              <div style={{ borderRadius: 4 }}>
                <DatePicker
                  selected={endDate}
                  onChange={(date) => this.setState({ endDate: date })}
                  timeInputLabel="Time:"
                  dateFormat="dd/MM/yyyy"
                  placeholderText="End Date"
                />
              </div>
            )}
        </div>
      </div>
    );
  }

  renderAdditionalInfoForRecurringMeeting() {
    return (
      <>
        <div style={{
          display: 'flex', width: '100%', justifyContent: 'center', alignItems: 'center', marginBottom: 10, paddingTop: 8,
        }}
        >
          <div className="weekDays-selector">
            {Array.from(Array(7).keys()).map((day) => { return this.renderDaysOfTheWeek(day); })}
          </div>

        </div>
      </>
    );
  }

  renderFieldModuleAndLecture(fields) {
    const { virtualMeetingData, selectedClassrooms } = this.state;
    return (
      <div style={{
        display: 'flex', width: '100%', justifyContent: 'space-between', alignItems: 'center', marginBottom: 10, paddingTop: 8,
      }}
      >
        {
         fields.map((field) => {
           const fieldValue = virtualMeetingData[field.fieldName];
           const placeholder = toTitleCase(field.fieldName.split('_').join(' '));
           return (
             <DropDown
               defaultText={placeholder}
               key={`${field.fieldName}_${selectedClassrooms && selectedClassrooms.length}`}
               selectedItem={fieldValue}
               optionsList={[...field.range]}
               color="#DDDDDD"
               placeholder={placeholder}
               onValueChange={(id, name) => this.updateVirtualMeetingData(field.fieldName,
                 { id, name })}
               style={{
                 ...inputStyle, width: 175,
               }}
               value={fieldValue}
             />
           );
         })
        }
      </div>
    );
  }

  renderVirtualMeetingModal() {
    const {
      classrooms, filteredSubjects, recurrenceTypes, emails,
      isHigherEducation, modules, lectures, filteredTeachers,
    } = this.props;
    const {
      showVirtualMeetingModal, virtualMeetingData, isEditVirtualMeetingModal, selectedClassrooms,
    } = this.state;
    const isEdit = !!virtualMeetingData.id;
    const includeFutureOccurences = (virtualMeetingData.is_recurring_edit);
    const isRecurringMeeting = virtualMeetingData.selectedRecurrence
    && virtualMeetingData.selectedRecurrence.id !== 1;
    return (
      <Modal
        header={isEdit ? includeFutureOccurences ? 'Edit Series' : 'Edit Live Class' : 'New Live Class'}
        headerStyle={{ fontSize: 20 }}
        showModal={showVirtualMeetingModal}
        overrideCloseIcon={isEdit ? (
          <img
            role="presentation"
            src={deleteIcon}
            alt="Delete Icon"
            style={{ width: 20, height: 20, cursor: 'pointer' }}
            onClick={() => this.deleteVirtualMeeting()}
          />
        ) : <></>}
        handleCloseModal={() => this.toggleVirtualMeeting()}
        style={{
          padding: 30, fontFamily: 'Roboto', overflowY: 'initial', overflowX: 'initial',
        }}
        width={IS_MOBILE ? '95%' : ''}
      >
        <div style={{ marginTop: 20, width: 360 }}>
          {this.renderFieldEntry('classrooms', classrooms, false, true)}
          {selectedClassrooms && selectedClassrooms.length > 0 && !isEdit && this.renderFieldEntry('subject', filteredSubjects)}
          {virtualMeetingData && virtualMeetingData.subject && virtualMeetingData.subject.id && !isEdit && this.renderFieldEntry('teacher', filteredTeachers)}
          {isHigherEducation && isEdit && !includeFutureOccurences
          && this.renderFieldModuleAndLecture(
            [
              { fieldName: 'module_no', range: modules },
              { fieldName: 'lecture_no', range: lectures }],
          )}
          {isEdit && !includeFutureOccurences && this.renderFieldEntry('topic')}
          <div style={{ display: !isEdit && 'flex', width: IS_MOBILE ? '80%' : '100%' }}>
            {this.renderFieldEntry('time', [], true)}
            <div style={{ width: '100%', marginLeft: !isEdit && 10 }}>
              {!isEdit && this.renderFieldEntry('duration')}
            </div>
          </div>
          {!(virtualMeetingData.quiz && virtualMeetingData.quiz.id) && !isEditVirtualMeetingModal && this.renderFieldEntry('selectedRecurrence', recurrenceTypes.map((recurrenceType) => {
            recurrenceType.name = toTitleCase(recurrenceType.name.split('_').join(' ').toLowerCase());
            return recurrenceType;
          }), false, false, false)}
          <div style={{ display: isEdit && 'flex', width: '100%' }}>
            {!virtualMeetingData.is_recurring && this.renderStartAndEndTime()}
            {isEdit && this.renderFieldEntry('duration')}
          </div>
          {isRecurringMeeting && !isEdit && this.renderAdditionalInfoForRecurringMeeting()}
          {!isEdit && this.renderFieldEntry('email', emails)}
          {this.renderFieldEntry('attendance', [], false, false, false, true)}
        </div>
        {
          // to-do , once project for oAuth is verified remove hardcoded condition
          true ? <div />
            : (
              <div style={{ display: 'flex', justifyContent: 'center', padding: 10 }}>
                <div
                  role="presentation"
                  style={{
                    display: 'flex', justifyContent: 'center', flexDirection: 'row', cursor: 'pointer',
                  }}
                >
                  <div style={{ backgroundColor: '#ECECEC', padding: '8px 22px', borderRadius: 20 }}>
                    <img src={google} alt="google" width="25px" />
                  </div>
                  <div
                    role="presentation"
                    style={{ marginLeft: 10, padding: '8px 4px' }}
                  >
                    Sign In to get updates in calender
                  </div>
                </div>
              </div>
            )
        }

        <div style={{ display: 'flex', justifyContent: 'space-between', paddingTop: 15 }}>
          <div
            role="presentation"
            className="black-white-button"
            style={{ width: 100, marginRight: 20 }}
            onClick={() => this.toggleVirtualMeeting()}
          >
            CANCEL
          </div>
          <div
            role="presentation"
            style={{ width: 100, backgroundColor: '#005786', color: '#FFFFFF' }}
            className="black-white-button"
            onClick={() => this.updateVirtualMeeting()}
          >
            {isEdit ? 'SAVE' : 'CREATE'}
          </div>
        </div>
      </Modal>
    );
  }

  renderInstructionsForIntegration() {
    return (
      <div style={{
        display: 'flex', paddingTop: 150, paddingLeft: 150, flexDirection: 'column', height: '100%',
      }}
      >
        <div style={{
          color: '#343737', fontFamily: 'Roboto',
        }}
        >
          <div style={{ display: 'flex', fontSize: 24 }}>
            Live Classes is not Integrated With Zoom
          </div>
          <div style={{ marginTop: 30, fontSize: 20 }}>
            Please do the following to enable Live Classes:
          </div>
          <div style={{ marginTop: 15, fontSize: 18 }}>
            1. Login to Zoom and &nbsp;
            <a
              className="black-white-buton"
              target="_blank"
              href="https://marketplace.zoom.us/develop/create"
              style={{
                fontWeight: 'bold', color: '#428bca', textDecoration: 'none',
              }}
            >
              create a JWT app key and secret.
            </a>
          </div>
          <div style={{ marginTop: 10, fontSize: 18 }}>
            2. Enter your Zoom JWT app's API key and secret in&nbsp;
            <a
              className="black-white-buton"
              target="_blank"
              href="/institute/settings"
              style={{
                fontWeight: 'bold', color: '#428bca', textDecoration: 'none',
              }}
            >
              <>
                Institute Settings
              </>
            </a>
          </div>
        </div>
      </div>
    );
  }

  renderMeetingUI(meeting) {
    const timeToAllowHostStart = new Date(meeting.start_time);
    const timeToAllowHostEnd = new Date(meeting.start_time);
    timeToAllowHostStart.setMinutes(timeToAllowHostStart.getMinutes()
    - MINUTES_BEFORE_STUDENTS_ALLOWED_TO_JOIN);
    timeToAllowHostEnd.setMinutes(timeToAllowHostEnd.getMinutes() + meeting.duration);
    const isLive = new Date() < timeToAllowHostEnd && timeToAllowHostStart < new Date();
    const isCompleted = meeting.status === 'LIVE' && new Date() >= timeToAllowHostEnd;
    const isTitlePresent = meeting.module || meeting.lecture || meeting.title;
    const { newQuizIds } = this.state;
    const quizId = meeting.quiz_id || newQuizIds[meeting.id];
    return (
      <div
        style={{
          display: 'flex', minHeight: 80, marginBottom: 20, fontFamily: 'Roboto', color: isCompleted ? '#ABABAB' : '#343737', backgroundColor: isLive ? '#AED580' : '#FFFFFF', border: isLive ? '1px solid #4F7827' : '1px solid #000000', boxSizing: 'border-box', padding: '2px 0', width: IS_MOBILE ? '90vw' : '', marginLeft: IS_MOBILE ? '5vw' : '',
        }}
      >
        <ReactTooltip />
        {meeting.start_time && (
          <div
            style={{
              display: 'flex', fontWeight: 'bold', alignItems: 'center', width: IS_MOBILE ? 120 : 150, paddingRight: 30, fontSize: IS_MOBILE ? 18 : 22, paddingLeft: 20, justifyContent: 'flex-start',
            }}
          >
            {new Date(meeting.start_time).toLocaleString('en-US', {
              timeZone: 'Asia/Kolkata', hour12: true, hour: 'numeric', minute: '2-digit',
            }).toLowerCase()}
          </div>
        )}
        <div
          style={{
            display: 'flex', alignItems: 'center', width: IS_MOBILE ? '' : '50%', paddingRight: 20,
          }}
        >
          <div style={{ display: 'flex', flexDirection: 'column', marginRight: 20 }}>
            <div style={{ fontSize: 18, fontWeight: 'bolder' }}>
              {meeting.subject_title}
            </div>
            {isTitlePresent
              ? (
                <div style={{ marginTop: 2, fontSize: 12 }}>
                  {meeting.module ? `Module ${meeting.module} ${(meeting.lecture || meeting.title) ? ' | ' : ' '}` : ''}
                  {meeting.lecture ? `Lecture ${meeting.lecture} ${meeting.title ? ' | ' : ' '}` : ''}
                  {meeting.title ? meeting.title : ''}
                </div>
              )
              : <></>}
            <div style={{ marginTop: 2, fontSize: 12 }}>
              {meeting.classroom_names && meeting.classroom_names.join(', ')}
            </div>
          </div>
        </div>
        <div style={{
          display: 'flex', width: '38%', alignItems: 'center', justifyContent: 'flex-end',
        }}
        >
          {isCompleted
            && (
              <div
                role="presentation"
                className=" black-white-button"
                style={{
                  border: '1px solid #77B33D', marginRight: 25, height: 30,
                }}
                onClick={() => window.open(`/quiz/feedback/${meeting.id}`)}
              >
                Report
              </div>
            )}
          {!isCompleted && (meeting.is_not_hostable
            ? (
              <div
                role="presentation"
                className="black-white-button"
                onClick={() => this.joinZoomMeeting(meeting, true)}
                style={{
                  fontWeight: isLive ? 'bold' : 'normal', marginRight: 25, height: 30, padding: 5,
                }}
              >
                Join
              </div>
            )
            : (
              <a
                href={(quizId) ? `/conduct_quiz/show_questions/${meeting.id}` : 'javascript:void(0)'}
                className="black-white-button"
                onClick={() => this.joinZoomMeeting(meeting, false)}
                style={{
                  fontWeight: isLive ? 'bold' : 'normal', marginRight: 25, height: 30, textDecoration: 'none',
                }}
              >
                Host
              </a>
            )
          )}
          {this.updateMeet(meeting)}
        </div>
      </div>
    );
  }

  renderBody() {
    const { virtualMeetings, recordingUrl, remainingRecordingVideoStorageInMb } = this.props;
    const {
      meetingDetails, showFilesModal, showUrlModal, urlModalMeeting,
    } = this.state;
    const mobileBodyStyles = {
      display: 'flex', marginTop: '5%', flexDirection: 'column', justifyContent: 'center', width: IS_MOBILE ? '90vw' : '',
    };
    const webBodyStyles = {
      display: 'flex', flexDirection: 'column', justifyContent: 'center', padding: '30px 100px',
    };
    if (virtualMeetings.length > 0) {
      return (
        <div style={IS_MOBILE ? mobileBodyStyles : webBodyStyles}>
          {meetingDetails && showFilesModal
            && (
              <VirtualMeetingFiles
                key={meetingDetails.classroom_ids}
                meeting={meetingDetails}
                showModal={showFilesModal}
                handleCloseModal={() => this.setState({ showFilesModal: false, meetingDetails: '' })}
              />
            )}
          { urlModalMeeting && showUrlModal
            && (
            <VirtualMeetingRecordings
              showModal={showUrlModal}
              meeting={urlModalMeeting}
              recordingUrl={recordingUrl[urlModalMeeting.id]}
              remainingRecordingVideoStorageInMb={remainingRecordingVideoStorageInMb}
              addRecordingUrl={this.addRecordingUrl}
              handleCloseModal={() => this.setState({ showUrlModal: false, meetingDetails: '' })}
              handleUrlChange={this.handleUrlChange}
            />
            )}
          {virtualMeetings.map((meeting) => { return this.renderMeetingUI(meeting); })}
        </div>
      );
    }
    if (IS_MOBILE) {
      return (
        <div style={{
          height: 40, fontFamily: 'Roboto', color: '#343737', backgroundColor: '#FFFFFF', border: '1px solid #000000', boxSizing: 'border-box', fontSize: 14, width: '90vw', margin: '5% 5vw', textAlign: 'center', display: 'flex', alignItems: 'center', padding: 5, justifyContent: 'center',
        }}
        >
          No classes scheduled for &nbsp;
          {this.renderDate()}
        </div>
      );
    }
    return (
      <div style={{
        display: 'flex', height: 80, marginBottom: 20, fontFamily: 'Roboto', color: '#343737', backgroundColor: '#FFFFFF', border: '1px solid #000000', boxSizing: 'border-box', fontSize: 24, margin: 70, alignItems: 'center', justifyContent: 'center',
      }}
      >
        No classes scheduled for &nbsp;
        {this.renderDate()}
      </div>
    );
  }

  renderDate() {
    const { daysAgo } = this.state;
    const date = new Date();
    date.setDate(date.getDate() - daysAgo);
    return (
      <LocaleDate date={date} />
    );
  }

  renderFilter() {
    return (
      <div
        role="presentation"
        className="black-white-button"
        style={{
          marginRight: 20, display: 'flex', padding: '5px 10px', width: 'inherit', height: '75%', alignItems: 'center', fontSize: IS_MOBILE ? 12 : 18,
        }}
        onClick={() => this.handleCloseFiltermodal()}
      >
        Apply Filters
      </div>
    );
  }

  renderFilterHeader() {
    const {
      selectedDepartment, selectedClassroom, selectedSemester, selectedSubject,
    } = this.props;
    const isFilter = (selectedDepartment && selectedDepartment.id) || (selectedSemester && selectedSemester.id) || (selectedClassroom && selectedClassroom.id) || (selectedSubject && selectedSubject.id);
    return (
      <div style={{
        marginTop: 100, display: 'flex', fontFamily: 'Roboto', fontSize: 18, fontWeight: 'bold', marginLeft: 100, borderBottom: isFilter && '1px solid', paddingBottom: 5, width: 'max-content',
      }}
      >
        {(isFilter)
          && <div>Classes For</div>}
        {selectedDepartment && selectedDepartment.id && (
          <div>
            &nbsp;
            {selectedDepartment.name || ''}
          </div>
        )}
        {selectedSemester && selectedSemester.id && (
          <div>
            {selectedDepartment && selectedDepartment.id ? ',' : ''}
            &nbsp;
            {selectedSemester.name || ''}
          </div>
        )}
        {selectedSubject && selectedSubject.id
          && (
            <div>
              {((selectedDepartment && selectedDepartment.id) || (selectedSemester && selectedSemester.id)) ? ',' : ''}
              &nbsp;
              {selectedSubject.name || ''}
            </div>
          )}
        {selectedClassroom && selectedClassroom.id && (
          <div>
            {((selectedDepartment && selectedDepartment.id) || (selectedSemester && selectedSemester.id) || (selectedSubject && selectedSubject.id)) ? ', ' : ''}
            &nbsp;
            {(selectedClassroom.name || '')}
          </div>
        )}
      </div>
    );
  }

  renderNavigator() {
    const { daysAgo } = this.state;
    return (
      <Navigator
        daysAgo={daysAgo}
        text="Today"
        reloadPage={this.reloadPage}
      />
    );
  }

  renderHeader() {
    const { showFilterModal } = this.state;
    const { showFilters } = this.props;
    return (
      <div style={{
        display: 'flex', flexDirection: IS_MOBILE ? 'column' : 'row', justifyContent: 'space-between', alignItems: 'center', padding: '20px 25px', boxShadow: '0px 3px 6px #343B4014', height: IS_MOBILE ? 100 : 80, background: '#FFFFFF', width: IS_MOBILE ? '100%' : '80%', position: 'fixed', zIndex: 1,
      }}
      >
        <div style={{ fontSize: 24, fontWeight: 'bold' }}>
          {this.renderDate()}
        </div>
        <div style={{ display: 'flex', height: '100%', alignItems: 'center' }}>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <Modal header="Apply Filters" key={showFilterModal} showModal={showFilterModal} handleCloseModal={() => { this.handleCloseFiltermodal(); }} isMobile={IS_MOBILE} style={{ padding: IS_MOBILE ? 10 : 30, fontFamily: 'Roboto' }}>
              {this.filterView()}
            </Modal>
          </div>
          {showFilters && this.renderFilter()}
          <div
            role="presentation"
            className="black-white-button"
            style={{
              display: 'flex', padding: '5px 10px', width: 'inherit', height: '75%', alignItems: 'center', fontSize: IS_MOBILE ? 12 : 18,
            }}
            onClick={() => this.toggleVirtualMeeting()}
          >
            NEW CLASS
          </div>
          <div style={{ display: 'flex', paddingLeft: 25 }}>
            {this.renderNavigator()}
          </div>
        </div>
      </div>
    );
  }

  render() {
    const textColor = '#4C5E6F';
    const { isZoomIntegrated, showCurrentClass } = this.props;
    const {
      pageContentHeight, showLoader, meetingLaunched, urlModalMeeting,
    } = this.state;
    if (showLoader) {
      return (
        <Loader />
      );
    }
    if (!isZoomIntegrated && !showCurrentClass) {
      return this.renderInstructionsForIntegration();
    }
    if (meetingLaunched) {
      this.turnZoomMeetingOn();
      return (
        <div />
      );
    }
    if (showCurrentClass) {
      return <CurrentClasses joinZoomMeeting={this.joinZoomMeeting} />;
    }
    return (
      <div style={{
        display: 'flex', flexDirection: 'column', backgroundColor: '#F5F8FA', height: pageContentHeight, fontFamily: 'Roboto', color: textColor,
      }}
      >
        <style>
          {`
            a:hover, a:visited, a:active {
              color: #454545 !important;
              text-decoration: none;
            }  
            input[type=checkbox]:after {
              background-color: #3994AE;
            }
            .react-datepicker__time-list {
              font-size: 14px;
            }
          `}
        </style>
        {this.renderVirtualMeetingModal()}
        {this.renderHeader()}
        {this.renderFilterHeader()}
        {this.renderBody()}
      </div>
    );
  }
}

VirtualMeeting.propTypes = {
  virtualMeetings: PropTypes.array,
  classrooms: PropTypes.array,
  subjects: PropTypes.array,
  filteredSubjects: PropTypes.array,
  filteredTeachers: PropTypes.array,
  tracks: PropTypes.array,
  quizzes: PropTypes.array,
  showFilters: PropTypes.bool,
  userName: PropTypes.string,
  recordingUrl: PropTypes.object,
  isZoomIntegrated: PropTypes.bool,
  recurrenceTypes: PropTypes.array,
  emails: PropTypes.array,
  departments: PropTypes.array,
  semesters: PropTypes.array,
  filterClassrooms: PropTypes.array,
  modules: PropTypes.array,
  lectures: PropTypes.array,
  isHigherEducation: PropTypes.bool,
  remainingRecordingVideoStorageInMb: PropTypes.number,
  campuses: PropTypes.array,
  selectedCampus: PropTypes.object,
  selectedDepartment: PropTypes.object,
  selectedClassroom: PropTypes.object,
  useZoom: PropTypes.bool,
  selectedSemester: PropTypes.object,
  selectedSubject: PropTypes.object,
  teachers: PropTypes.array.isRequired,
  showCurrentClass: PropTypes.object,
  gAuthStatus: PropTypes.bool.isRequired,
};

VirtualMeeting.defaultProps = {
  virtualMeetings: [],
  classrooms: [],
  subjects: [],
  filteredSubjects: [],
  filteredTeachers: [],
  useZoom: true,
  tracks: TRACKS,
  quizzes: [],
  userName: '',
  isZoomIntegrated: true,
  recordingUrl: {},
  recurrenceTypes: RECURRENCE_TYPES,
  emails: [],
  showFilters: false,
  campuses: [],
  filterClassrooms: [],
  semesters: [],
  isHigherEducation: false,
  selectedCampus: {},
  selectedDepartment: {},
  selectedSubject: {},
  selectedClassroom: {},
  remainingRecordingVideoStorageInMb: 0,
  selectedSemester: {},
  departments: [],
  showCurrentClass: false,
  modules: [],
  lectures: [],
};

const mapStateToProps = ({ virtualMeeting }) => ({
  gAuthStatus: virtualMeeting.gAuthStatus,
  selectedSubject: virtualMeeting.selectedSubject,
  teachers: virtualMeeting.teachers,
  virtualMeetings: virtualMeeting.virtualMeetings,
  classrooms: virtualMeeting.classrooms,
  filterClassrooms: virtualMeeting.filterClassrooms,
  subjects: virtualMeeting.subjects,
  recordingUrl: virtualMeeting.recordingUrl,
  remainingRecordingVideoStorageInMb: virtualMeeting.remainingRecordingVideoStorageInMb,
  userName: virtualMeeting.userName,
  isZoomIntegrated: virtualMeeting.isZoomIntegrated,
  liveMeetingId: virtualMeeting.liveMeetingId,
  showFilters: virtualMeeting.showFilters,
  quizzes: virtualMeeting.quizzes,
  recurrenceTypes: virtualMeeting.recurrenceTypes,
  emails: virtualMeeting.emails,
  isHigherEducation: virtualMeeting.isHigherEducation,
  departments: virtualMeeting.departments,
  useZoom: virtualMeeting.useZoom,
  campuses: virtualMeeting.campuses,
  semesters: virtualMeeting.semesters,
  selectedClassroom: virtualMeeting.selectedClassroom,
  selectedDepartment: virtualMeeting.selectedDepartment,
  selectedSemester: virtualMeeting.selectedSemester,
  selectedCampus: virtualMeeting.selectedCampus,
  filteredSubjects: virtualMeeting.filteredSubjects,
  modules: virtualMeeting.modules,
  lectures: virtualMeeting.lectures,
  filteredTeachers: virtualMeeting.filteredTeachers,
});

export default connect(mapStateToProps, {
  createVirtualMeeting,
  applyInstitueFilters,
  updateGAuthuStatus,
  deleteVirtualMeeting,
  createMeetingSignature,
  showToast,
  createLiveQuiz,
  updateVirtualMeeting,
  getVirtualMeetingList,
  updateSubjectsFilter,
  updateQuizQuestions,
  setHostedTime,
  toggleMeetingStatus,
  updateShowStatusQuizQuestion,
  hideVirtualMeeting,
  endOnGoingMeetings,
  updateTeacherFilter,
})(VirtualMeeting);
