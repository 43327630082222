import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import ReactTooltip from 'react-tooltip';
import moment from 'moment';
import PropTypes from 'prop-types';
import moreMenu from '../../static/images/more-menu.svg';
import CompactLoader from '../loading/compactLoader';
import {
  getSignedUrlAndUploadToS3, getInstituteFiles, updateAttachmentDetails,
  hideFile, fileUploadPercentage, applyInstitueFilters, showToast
} from '../../actions';
import search from '../../static/images/search.svg';
import crossMark from '../../static/images/close-mark.svg';
import { FILE_IMAGES } from '../../constants';
import left from '../../static/images/left.svg';
import '../../styles/instituteOnBoarding.css';
import EditFileDetails from '../modal/editInstituteFilesModal';
import excel from '../../static/images/fileTypes/excel.svg';
import file from '../../static/images/fileTypes/file.svg';
import folder from '../../static/images/fileTypes/folder.svg';
import image from '../../static/images/fileTypes/image.svg';
import pdf from '../../static/images/fileTypes/powerpoint.svg';
import video from '../../static/images/fileTypes/video.svg';
import word from '../../static/images/fileTypes/word.svg';
import uploadImage from '../../static/images/uploadFile.svg';

const images = {
  excel,
  file,
  folder,
  image,
  pdf,
  video,
  word,
};

class SharedFile extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      showModal: false,
      editableFile: null,
      searchString: '',
      fileShareSelectedSubject: { id: null, name: null },
    };
    this.renderTable = this.renderTable.bind(this);
    this.updateFileDetails = this.updateFileDetails.bind(this);
  }

  updateFileDetails(fileId, fileTitle, fileDescription, classroomIds) {
    this.props.updateAttachmentDetails(fileId, fileTitle, fileDescription, classroomIds);
    this.setState({ showModal: false });
  }

  renderDropDown(file) {
    const dropDownElementStyle = {
      display: 'flex', padding: '8px 25px',
    };
    return (
      <nav
        className="navbar navbar-expand-lg navbar-light"
        style={{
          marginBottom: 0, marginLeft: 0, marginRight: 20, padding: 0,
        }}
      >
        <style jsx>
          {`
            .dropdown-item:hover{
              background: #F2F2F2;
              border: 1px solid;
            }
        `}
        </style>
        <div key="navbar-login" className="nav-item dropdown">
          <div
            className="nav-link"
            href="#"
            id="navbar"
            role="button"
            data-toggle="dropdown"
            aria-haspopup="false"
            aria-expanded="false"
            style={{
              height: 31, width: 57, border: '1px solid', display: 'flex', alignItems: 'center', cursor: 'pointer', justifyContent: 'center', marginLeft: 20, borderRadius: 1,
            }}
          >
            <img alt="" className="cursor" src={moreMenu} width="25px" height="25px" />
          </div>
          <div
            style={{
              cursor: 'pointer', marginTop: 10, fontSize: 18, padding: 0, width: 180,
            }}
            className="dropdown-menu dropdown-menu-right"
            aria-labelledby="navbar"
          >
            <div
              role="presentation"
              className="dropdown-item"
              style={dropDownElementStyle}
              onClick={() => this.setState({ editableFile: file, showModal: true })}
            >
              Edit
            </div>
            <div
              role="presentation"
              className="dropdown-item"
              style={dropDownElementStyle}
              onClick={() => this.props.hideFile(file.id)}
            >
              {file.show ? 'Hide' : 'Unhide'}
            </div>
          </div>
        </div>
      </nav>
    );
  }

  renderFileList(file) {
    const fileExtension = file.file_url.split('.')[file.file_url.split('.').length - 1];
    const fileImage = FILE_IMAGES[fileExtension] || FILE_IMAGES.default;
    return (
      <tr style={{ fontSize: '16px' }}>
        <td style={{ verticalAlign: 'middle', paddingTop: 25, paddingBottom: 15 }}>
          <div style={{ display: 'flex', flexDirection: 'row' }}>
            <div
              className="ellipsis clickable"
              style={{
                width: 'fit-content', cursor: 'pointer', paddingLeft: 20, maxWidth: 550, textOverflow: 'ellipsis', alignSelf: 'center',
              }}
            >
              <img src={images[fileImage]} alt="file" style={{ height: 25, width: 25, marginRight: 15 }} />
            </div>
            <div style={{ display: 'flex', flexDirection: 'column', marginLeft: 20 }}>
              <div>
                <a target="_blank" href={file.file_url} data-tip={file.description}>{file.title}</a>
                <ReactTooltip effect="solid" />
              </div>
              {(file.module || file.lecture) && (
              <div>
                <div style={{
                  display: 'flex', flexDirection: 'row', marginTop: 10,
                }}
                >
                  {file.module && (
                  <div style={{ marginRight: 5 }}>
                    {`Module ${file.module}`}
                  </div>
                  )}
                  {file.module && file.lecture && (
                  <div style={{ marginRight: 5 }}>
                    |
                  </div>
                  )}
                  {file.lecture && (
                  <div>
                    {`Lecture ${file.lecture}`}
                  </div>
                  )}
                  <div />
                </div>
              </div>
              )}
            </div>
          </div>

        </td>
        <td className="ellipsis" style={{ verticalAlign: 'middle', textAlign: 'left', paddingLeft: 20 }}>
          <div className="ellipsis">
            {file.shared_date ? moment(file.shared_date).format('DD MMM YYYY') : '-'}
          </div>
        </td>
        <td style={{
          verticalAlign: 'middle', textAlign: 'left', paddingLeft: 20,
        }}
        >
          {this.renderDropDown(file)}
        </td>
      </tr>
    );
  }

  renderTable() {
    const { instituteFiles, classrooms, selectedClassroom } = this.props;
    const {
      editableFile, showModal, searchString, fileShareSelectedSubject,
    } = this.state;
    let counter = 0;
    const searchResultList = [];
    instituteFiles.forEach((file) => {
      if (
        ((file.title.toUpperCase().indexOf(searchString.toUpperCase()) >= 0)
        || (file.module && file.module.toUpperCase().indexOf(searchString.toUpperCase()) >= 0)
        || (file.lecture && file.lecture.toUpperCase().indexOf(searchString.toUpperCase()) >= 0)
        || (file.shared_date
            && file.shared_date.toUpperCase().indexOf(searchString.toUpperCase()) >= 0))
        && file.subject_id === (fileShareSelectedSubject.id || null)
      ) {
        if (!selectedClassroom || !selectedClassroom.value
          || selectedClassroom.value === file.value) {
          searchResultList.push(file);
        } else {
          file.selected_classrooms.forEach((classroom) => {
            if (classroom.value === selectedClassroom.value) {
              searchResultList.push(file);
            }
          });
        }
      }
    });

    return (
      <div style={{ marginTop: 20 }}>
        {editableFile
        && (
        <EditFileDetails
          showModal={showModal}
          file={editableFile}
          classrooms={classrooms}
          updateDetails={this.updateFileDetails}
          handleCloseModal={() => this.setState({ editableFile: null })}
        />
        )}
        {searchResultList.length > 0 && (
        <table
          className="table table-hover"
          style={{
            boxShadow: '1px 2px 6px #8B9DAF33', borderRadius: 4, backgroundColor: '#FFFFFF', border: '1px solid #CDCFD6',
          }}
        >
          <thead style={{ background: '#F6F8FA', color: '#23394C' }}>
            <tr
              className="table-header"
              style={{
                fontSize: '18px', fontWeight: '700', height: 60, color: '#11426C', width: '100%',
              }}
            >
              <th
                className="table-header"
                style={{
                  width: '70%', borderRight: '1px solid #DFE3EA', paddingLeft: 20,
                }}
              >
                Name
              </th>
              <th
                className="table-header"
                style={{
                  width: '20%', borderRight: '1px solid #DFE3EA', paddingLeft: 20,
                }}
              >
                Shared on
              </th>
              <th
                className="table-header"
                style={{
                  width: '10%', borderRight: '1px solid #DFE3EA', paddingLeft: 20,
                }}
              >
                Action
              </th>
            </tr>
          </thead>
          <tbody>
            {searchResultList
              && searchResultList.map((file) => this.renderFileList(file, counter++))}
          </tbody>
        </table>
        )}
        {searchResultList.length === 0
          && (
          <div style={{
            display: 'flex', justifyContent: 'center', alignItems: 'center', color: '#CDCFD6', height: 60, backgroundColor: '#FFFFFF',
          }}
          >
            <img
              src={folder}
              alt="folder"
              style={{
                height: 40, width: 40, marginRight: 10, opacity: 0.5,
              }}
            />
            No Files to show.
          </div>
          )}
      </div>
    );
  }

  renderSharedFiles() {
    const { searchString, fileShareSelectedSubject } = this.state;
    const { classrooms, selectedClassroom } = this.props;
    return (
      <div>
        <div style={{ marginBottom: 20, fontSize: 26 }}>
          <img
            role="presentation"
            data-tip="Change subject"
            alt=""
            src={left}
            width="24"
            height="24"
            style={{ marginRight: 15, cursor: 'pointer' }}
            onClick={() => {
              this.setState({
                fileShareSelectedSubject:
                { id: null, name: null },
              });
            }}
          />
          {fileShareSelectedSubject.name}
          <ReactTooltip effect="solid" />
        </div>
        <div style={{
          color: '#11426C', marginTop: 5, display: 'flex',
        }}
        >
          <div style={{ display: 'flex', width: 400 }}>
            {this.props.domainSearch(classrooms, selectedClassroom,
              (selectedOption) => this.props.applyInstitueFilters({
                selectedClassroom: selectedOption,
              }),
              false)}
          </div>
          <div style={{
            display: 'flex', justifyContent: 'space-between', width: 400, border: '1px solid #9EA0A5', borderRadius: 4, height: 40, marginLeft: 15,
          }}
          >
            <div style={{ paddingLeft: 15, display: 'flex', alignItems: 'center' }}>
              <img src={search} alt="" width="16" height="16" />
            </div>
            <div style={{ width: 530, display: 'flex', justifyContent: 'space-between' }}>
              <div style={{ width: '100%', display: 'flex', alignItems: 'center' }}>
                <input
                  className="input-field"
                  id="Search"
                  onChange={(event) => this.setState({ searchString: event.target.value })}
                  type="text"
                  placeholder="Search"
                  value={searchString}
                  style={{
                    width: '100%', border: 'none', marginBottom: 0, height: 35,
                  }}
                  autoFocus
                />
              </div>
              <div
                role="presentation"
                style={{
                  width: 25, display: (searchString) ? 'flex' : 'none', alignItems: 'center', cursor: 'pointer',
                }}
                onClick={() => this.setState({ searchString: '' })}
              >
                <img alt="" src={crossMark} width="16" height="16" />
              </div>
            </div>
          </div>
        </div>
        {this.renderTable()}
      </div>
    );
  }

  renderFolderWithSubjectName(subjectFileData) {
    const fileSize = subjectFileData.totalFiles;
    return (
      <div
        role="presentation"
        style={{
          padding: 20,
          marginBottom: 20,
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          alignSelf: 'center',
          opacity: fileSize ? 1 : 0.5,
          boxShadow: '0px 1px 4px #0000003d',
          cursor: fileSize ? 'pointer' : 'not-allowed',
          width: '100%',
          background: '#FFFFFF',
        }}
        onClick={() => {
          this.setState({
            fileShareSelectedSubject:
            { id: subjectFileData.id, name: subjectFileData.name },
          });
          window.scrollTo(0, 0);
        }}
      >
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <img alt="fileIcon" src={folder} height="40px" width="40px" />
          <div style={{ marginRight: 30, marginLeft: 30 }}>{subjectFileData.name}</div>
        </div>
        <div style={{ whiteSpace: 'nowrap' }}>
          {fileSize > 1 ? `${fileSize} files` : `${fileSize} file` }
        </div>
      </div>
    );
  }

  render() {
    const { fileShareSelectedSubject } = this.state;
    const { subjectFiles, showLoader } = this.props;
    if (showLoader) {
      return <CompactLoader height={typeof window !== 'undefined' && window.innerHeight - 150} />;
    }
    if (!Object.keys(subjectFiles).length) {
      return (
        <div style={{
          display: 'flex', justifyContent: 'center', alignItems: 'center', height: typeof window !== 'undefined' && window.innerHeight - 150, flexDirection: 'column',
        }}
        >
          <div>
            <img
              src={folder}
              alt="folder"
              style={{
                height: 65, width: 65, marginBottom: 10, opacity: 0.7,
              }}
            />

          </div>
          <div style={{ fontSize: 22 }}> No Files shared yet</div>
        </div>
      );
    }
    if (fileShareSelectedSubject.id !== null) {
      return this.renderSharedFiles();
    }
    return (
      Object.keys(subjectFiles).map((subjectId) => {
        return this.renderFolderWithSubjectName(subjectFiles[subjectId]);
      })
    );
  }
}

SharedFile.propTypes = {
  instituteFiles: PropTypes.array,
  selectedClassroom: PropTypes.array,
  classrooms: PropTypes.array,
  subjectFiles: PropTypes.object.isRequired,
  showLoader: PropTypes.bool,
};

SharedFile.defaultProps = {
  instituteFiles: [],
  selectedClassroom: [],
  classrooms: [],
  showLoader: true,
};

const mapStateToProps = ({ filesBoard, virtualMeeting }) => ({
  instituteFiles: filesBoard.instituteFiles,
  classrooms: filesBoard.classrooms,
  selectedClassroom: virtualMeeting.selectedClassroom,
  subjectFiles: filesBoard.subjectFiles,
});

export default connect(mapStateToProps, {
  getInstituteFiles,
  getSignedUrlAndUploadToS3,
  applyInstitueFilters,
  fileUploadPercentage,
  showToast,
  updateAttachmentDetails,
  hideFile,
})(SharedFile);
