import React, { Component } from 'react';
import { SortableContainer, SortableElement } from 'react-sortable-hoc';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Constants from '../../shared/constants';
import { updateCheckpointOrder, showToast } from '../../actions';

const arrayMoveMutate = (array, from, to) => {
  array.splice(to < 0 ? array.length + to : to, 0, array.splice(from, 1)[0]);
};

const arrayMove = (array, from, to) => {
  const finalArray = array.slice();
  arrayMoveMutate(finalArray, from, to);
  return finalArray;
};

const styles = {
  title: {
    color: Constants().primaryHeaderTextColor,
    fontSize: 14,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    marginTop: 5,
  },
  titleContainer: {
    backgroundColor: Constants().primaryBackgroundColor,
    padding: 10,
    width: '100%',
    marginBottom: 15,
    boxShadow: '0px 3px 6px #343B4014',
    borderRadius: 4,
    cursor: 'pointer',
    maxHeight: 200,
    overflow: 'hidden',
  },
  updateBtn: {
    width: '100%',
    fontSize: 12,
    padding: 10,
    marginRight: 10,
  },
};

const SortableItem = SortableElement(({ item, onItemClick }) => {
  return (
    <div
      key={item.id}
      style={styles.titleContainer}
      onClick={() => { onItemClick(item); }}
      role="presentation"
    >
      <div style={styles.title}>{item.title}</div>
      <div style={{ fontSize: 12, marginTop: 5, color: '#aaa' }}>{item.text}</div>
    </div>
  );
});

const SortableList = SortableContainer(({ items, quickpointMap, onItemClick }) => {
  return (
    <ul>
      {items.map((quickpointId, index) => (
        <SortableItem
          key={`${quickpointId}`}
          index={index}
          item={quickpointMap[quickpointId]}
          onItemClick={onItemClick}
        />
      ))}
    </ul>
  );
});

class QuickpointList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      items: props.quickpointsItems.ids,
      showSave: false,
    };
    this.onSortEnd = this.onSortEnd.bind(this);
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.quickpointsItems.ids.length !== prevState.items.length) {
      return {
        items: nextProps.quickpointsItems.ids,
      };
    }
    return {};
  }

  onSortEnd({ oldIndex, newIndex }) {
    this.setState(({ items }) => ({
      items: arrayMove(items, oldIndex, newIndex),
      showSave: true,
    }));
  }

  render() {
    const {
      quickpointsItems, fetching, onItemClick, dispatch,
    } = this.props;
    if (fetching) {
      return <div>Loading...</div>;
    }
    if (!quickpointsItems.ids.length) {
      return <div>No quickpoints available in this section</div>;
    }
    const { items, showSave } = this.state;
    return (
      <div>
        <div style={{ overflow: 'auto', maxHeight: 'calc(100vh - 140px)', marginTop: 10 }}>
          <SortableList
            items={items}
            quickpointMap={quickpointsItems.byId}
            onSortEnd={this.onSortEnd}
            pressDelay={150}
            onItemClick={onItemClick}
          />
        </div>
        { showSave && (
          <div
            role="presentation"
            className="btn btn-primary"
            style={styles.updateBtn}
            onClick={() => this.props.updateCheckpointOrder(items).then((isSuccess) => {
              if (isSuccess) {
                this.props.showToast('Order successfully updated!!');
                this.setState({ showSave: false });
              }
            })}
          >
            Update Order
          </div>
        )}
      </div>
    );
  }
}

const mapStateToProps = ({ quickpoints }) => ({
  fetching: quickpoints.fetching,
  quickpointsItems: quickpoints.items,
});

QuickpointList.propTypes = {
  fetching: PropTypes.bool.isRequired,
  quickpointsItems: PropTypes.object.isRequired,
  onItemClick: PropTypes.func,
};

QuickpointList.defaultProps = {
  onItemClick: () => {},
};

export default connect(mapStateToProps, { updateCheckpointOrder, showToast })(QuickpointList);
