import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import DatePicker from 'react-datepicker';
import ReactTooltip from 'react-tooltip';
import moment from 'moment';
import PropTypes from 'prop-types';
import Select from 'react-select';
import CompactLoader from '../loading/compactLoader';
import ProgressBar from '../progressBar';
import Layout from '../layout';
import Constants from '../../shared/constants';
import leftArrow from '../../static/images/left-arrow.svg';
import close from '../../static/images/close-mark.svg';
import download from '../../static/images/download.svg';
import rightArrow from '../../static/images/right-arrow.svg';
import calender from '../../static/images/calendar.svg';
import testPaper from '../../static/images/offlineTest/test-paper.gif';
import clock from '../../static/images/clock.svg';
import test from '../../static/images/offlineTest/test.png';
import folerWithPages from '../../static/images/offlineTest/folder-with-papers.png';
import {
  getSignedUrl, getMetaData, getOfflineTestListSubjects, getSubjectsOfflineTest,
  updateAttachmentDetails, hideFile, fileUploadPercentage, applyInstitueFilters, showToast,
  uploadFileTo3, createOfflineTest, getOfflineTestStudentResponse, updateStudentMark,
} from '../../actions';
import { ApplyFilter } from '../common';
import { BYTES_TO_MEGA_BYTE, FILE_IMAGES } from '../../constants';
import '../../styles/instituteOnBoarding.css';
import excel from '../../static/images/fileTypes/excel.svg';
import file from '../../static/images/fileTypes/file.svg';
import folder from '../../static/images/fileTypes/folder.svg';
import image from '../../static/images/fileTypes/image.svg';
import pdf from '../../static/images/fileTypes/powerpoint.svg';
import video from '../../static/images/fileTypes/video.svg';
import word from '../../static/images/fileTypes/word.svg';
import uploadImage from '../../static/images/uploadFile.svg';

import 'react-datepicker/dist/react-datepicker.css';

const images = {
  excel,
  file,
  folder,
  image,
  pdf,
  video,
  word,
};

const offLineTestPageType = {
  subject: 'OFFLINE_TEST_SUBJECT',
  test: 'OFFLINE_TEST',
  student_responses: 'OFFLINE_TEST_STUDENT_RESPONSE',
};

class OfflineTest extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      fileTypes: 'image/*,.pdf,.ppt,.doc,.docx,.pptx,application/vnd.ms-powerpoint,text/plain,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel',
      testListPageType: '',
      newTestLoader: true,
      testListLoader: true,
      testMarks: '',
      testName: '',
      testInstruction: '',
      testDate: '',
      testStartTime: '',
      testEndTime: '',
      testDurationInMinutes: '',
      questionPaper: null,
      searchString: '',
      isCreateTest: false,
      questionPaperUploading: false,
      fileExtension: 'file',
      offlineTestCreatedBy: '',
      fileShareSelectedSubject: { id: null, name: null },
      updateMarkStudentResponseId: '',
      studentMark: '',
    };
    this.setPageType = this.setPageType.bind(this);
  }

  componentDidMount() {
    this.setPageType();
  }

  setPageType() {
    const query = new URLSearchParams(window.location.search);
    let pageType = null;
    if (query.get('is_subject')) {
      pageType = offLineTestPageType.subject;
    } else if (query.get('is_test')) {
      pageType = offLineTestPageType.test;
    } else if (query.get('is_student_responses')) {
      pageType = offLineTestPageType.student_responses;
    }
    this.props.getMetaData().then(() => {
      this.props.applyInstitueFilters({ selectedClassroom: [] }).then(() => {
        this.setState({ newTestLoader: false });
      });
    });
    this.setState({ testListPageType: pageType, isCreateTest: pageType === null }, () => {
      const { testListPageType } = this.state;
      if (testListPageType === offLineTestPageType.subject) {
        this.props.getOfflineTestListSubjects().then(() => {
          this.setState({ testListLoader: false });
        });
      } else if (testListPageType === offLineTestPageType.test) {
        this.props.getSubjectsOfflineTest(query.get('subject_id')).then((response) => {
          if (response.success) {
            this.setState({ testListLoader: false });
          } else {
            this.props.history.push('/classboard/offline_test?is_subject=true');
            this.setPageType();
          }
        });
      } else if (testListPageType === offLineTestPageType.student_responses) {
        this.props.getOfflineTestStudentResponse(query.get('test_id')).then(() => {
          this.setState({ testListLoader: false });
        });
      }
    });
  }

  getLogo() {
    document.getElementById('uploadFile').click();
  }

  getClassroomsList() {
    const {
      selectedCampus, selectedSemester,
      selectedSubject, selectedDepartment, subjects,
    } = this.props;
    const { classrooms, isHigherEducation } = this.props;
    let filteredData = [];
    const campus = selectedCampus && selectedCampus.id;
    const department = selectedDepartment && selectedDepartment.id;
    const semester = selectedSemester && selectedSemester.id;
    let subjectSelected = selectedSubject && selectedSubject.id;
    if (subjectSelected) {
      [subjectSelected] = subjects.filter((subject) => subject.id === selectedSubject.id);
    }
    filteredData = classrooms.filter((classroom) => {
      if (isHigherEducation) {
        return ((!semester && !department && !subjectSelected)
          || ((semester && !subjectSelected) && (classroom.course_id === semester))
          || ((semester && subjectSelected) && (classroom.course_id === semester && subjectSelected.course_id_list.includes(classroom.course_id)))
          || ((!semester && department && !subjectSelected) && (classroom.department_id === department))
          || ((!semester && department && subjectSelected) && (classroom.department_id === department && subjectSelected.department_id_list.includes(classroom.department_id)))
          || (!semester && !department && subjectSelected && (subjectSelected.course_id_list.includes(classroom.course_id) || subjectSelected.department_id_list.includes(classroom.department_id)))
        );
      }
      return (!campus || classroom.campus_id === campus);
    });
    return filteredData;
  }

  updateFieldValueChanges(type, value) {
    if (type === 'selectedClassroom' && !value) {
      this.setState({ [type]: null });
      return;
    }
    this.setState({ [type]: value });
  }

  handleFormSubmit(e) {
    e.preventDefault();
  }

  uploadQuestionPaper(event) {
    const { maxFileSize } = this.props;
    const file = event.target.files[0];
    if (file.size > (maxFileSize * BYTES_TO_MEGA_BYTE)) {
      this.props.showToast(`File should be less than ${maxFileSize} MB.`);
    } else {
      const fileExtension = file.name.split('.')[(file.name.split('.').length) - 1];
      const fileimage = FILE_IMAGES[fileExtension];
      this.setState({
        questionPaper: file, fileExtension: fileimage || FILE_IMAGES.default,
      });
    }
  }

  domainSearch(filterItems, selectedItem, selectedFunction, isMulti) {
    const { isCreateTest } = this.state;
    if (!filterItems || filterItems.length === 0) {
      return true;
    }
    let items = [];
    filterItems.map((item) => {
      items = [...items, { value: item.id, label: item.name }];
    });
    const customHeight = isCreateTest ? {} : { height: 40 };
    const customStyles = {
      control: (base) => ({
        ...base,
        backgroundColor: 'white',
        width: isCreateTest ? '100%' : 400,
        ...customHeight,
        border: '1px solid #343737 !important',
      }),
      indicatorsContainer: (base) => ({
        ...base,
      }),
      valueContainer: (base) => ({
        ...base,
        height: 'inherit',
        alignContent: 'center',
      }),
    };
    items = [...items, { id: null, value: null, label: 'All' }];
    return (
      <div style={{ display: 'flex', width: '100%' }}>
        <div style={{ width: '100%' }}>
          <Select
            isMulti={isMulti}
            isClearable
            styles={customStyles}
            value={selectedItem}
            onChange={selectedFunction}
            options={items}
            placeholder="Select classroom"
          />
        </div>
        <div style={{ marginLeft: 10, color: 'red', width: 10 }}>{(!selectedItem || !selectedItem.length) ? '*' : ''}</div>
      </div>
    );
  }

  uploadAndCreateOfflineTest() {
    const {
      testName, testInstruction, testDate, testStartTime, testDurationInMinutes,
      questionPaper, testMarks,
    } = this.state;
    const {
      selectedCampus, selectedDepartment, selectedSemester, selectedClassroom, selectedSubject,
    } = this.props;
    let subjectId = '';
    subjectId = selectedSubject ? selectedSubject.id : '';
    const classroomIds = [];
    const semesterId = selectedSemester ? selectedSemester.id : '';
    const departmentId = selectedDepartment ? selectedDepartment.id : '';
    const campusId = selectedCampus ? selectedCampus.id : '';
    selectedClassroom && selectedClassroom.forEach((e) => classroomIds.push(e.value));
    this.setState({ questionPaperUploading: true });
    const fileExtension = questionPaper.name.split('.').slice(-1).pop();
    this.props.getSignedUrl(fileExtension).then((response) => {
      if (response.success) {
        this.props.uploadFileTo3(response, questionPaper).then((res) => {
          const questionPaperS3Url = `${res.config.url}/${response.key}`;
          const daysDifference = moment(testDate).diff(moment(testStartTime), 'days');
          const testDateTime = moment(testStartTime).add(daysDifference + 1, 'days');
          this.props.createOfflineTest(testName, testDateTime, testDurationInMinutes, testMarks, testInstruction, questionPaperS3Url, classroomIds, campusId, semesterId, departmentId, subjectId).then((result) => {
            if (result.success) {
              this.setState({
                questionPaperUploading: false, testName: '', testMarks: '', testInstruction: '', testDate: '', testStartTime: '', testEndTime: '', testDurationInMinutes: '', questionPaper: null,
              });
              this.props.applyInstitueFilters({
                selectedDepartment: {},
                selectedSubject: null,
                selectedClassroom: null,
                selectedSemester: {},
                selectedTeacher: {},
              });
              this.props.fileUploadPercentage(0);
              this.props.showToast(result.message);
            } else {
              this.setState({ questionPaperUploading: false });
              this.props.showToast(result.message || 'Something went wrong please contact us!');
            }
          });
        });
      }
    });
  }

  renderInput(type, placeholderText, style = {}, required) {
    return (
      <div style={{ display: 'flex', ...style }}>
        <input
          type="text"
          placeholder={placeholderText}
          value={this.state[type]}
          style={{
            width: '100%', outline: 'none', margin: '0px', paddingLeft: 20, fontSize: 16, border: '1px solid #343737',
          }}
          onChange={(event) => this.updateFieldValueChanges(type, event.target.value)}
          required
        />
        <div style={{ marginLeft: 15, color: 'red', width: 10 }}>{required ? '*' : ''}</div>
      </div>

    );
  }

  renderFilters() {
    const {
      campuses, isHigherEducation, departments,
      selectedCampus, semesters, subjects,
      selectedDepartment, selectedSemester, selectedSubject,
    } = this.props;
    return (
      <ApplyFilter
        campuses={campuses}
        departments={departments}
        semesters={semesters}
        subjects={subjects}
        isHigherEducation={isHigherEducation}
        applyDataFilters={this.applyDataFilters}
        selectedCampus={{ ...selectedCampus }}
        selectedDepartment={{ ...selectedDepartment }}
        selectedSemester={{ ...selectedSemester }}
        selectedSubject={{ ...selectedSubject }}
        hideClassroom
        hideTeacher
        width={375}
        hideApply
        hideClear
        dropDownColor="1px solid #343737"
        semesterRequired={!selectedSemester || !selectedSemester.id}
        departmentRequired={!selectedDepartment || !selectedDepartment.id}
        subjectRequired={!selectedSubject || !selectedSubject.id}
      />
    );
  }

  renderQuestionPaperData() {
    const {
      questionPaper, fileTypes, fileExtension,
    } = this.state;
    const isImage = questionPaper && questionPaper.type.includes('image');
    const isPdf = questionPaper && questionPaper.type.includes('pdf');
    const fileFormat = questionPaper && questionPaper.name.split('.').slice(-1).pop();
    const isPpt = questionPaper && ((fileFormat === 'ppt') || (fileFormat === 'pptx'));
    const isExcel = questionPaper && ((fileFormat === 'xls') || (fileFormat === 'xlsx'));
    const isDoc = questionPaper && ((fileFormat === 'doc') || (fileFormat === 'docx'));
    if (questionPaper) {
      return (
        <div style={{
          borderRadius: 4, height: 200, width: '100%', textAlign: 'center', display: 'flex', flexDirection: 'column', position: 'relative', cursor: 'pointer',
        }}
        >
          <div style={{ height: '0px', overflow: 'hidden' }}>
            <input id="uploadFile" type="file" name="file" accept={fileTypes} onChange={(event) => this.uploadQuestionPaper(event)} />
          </div>
          <div style={{ cursor: 'pointer', height: '99%' }}>
            <div style={{
              borderRadius: '4px', border: 'solid 1px #cdcdce', height: '100%', display: 'flex', justifyContent: 'center', padding: 5, position: 'relative',
            }}
            >
              {isImage && <img alt="" src={URL.createObjectURL(questionPaper)} style={{ maxWidth: '60%', width: 'fit-content' }} height="fit-content" />}
              {isPdf && <embed style={{ maxWidth: '60%', width: 'fit-content' }} height="fit-content" name="plugin" src={URL.createObjectURL(questionPaper)} scrolling="no" />}
              {(isPpt || isDoc || isExcel) && (
                <div style={{
                  alignSelf: 'center', overflow: 'hidden', textOverflow: 'ellipsis', textAlign: 'center',
                }}
                >
                  {questionPaper.name}
                </div>
              )}
              <div
                role="presentation"
                style={{
                  height: 35, backgroundColor: '#E4E7EE', width: '100%', position: 'absolute', bottom: 0, left: 0, color: '#40A7FF', display: 'flex', justifyContent: 'center', alignItems: 'center',
                }}
                onClick={this.getLogo}
              >
                <img src={images[fileExtension]} alt="file" style={{ height: 20, width: 20, marginRight: 10 }} />
                Change
              </div>
              <div
                role="presentation"
                style={{
                  position: 'absolute', top: 5, right: 10, cursor: 'pointer',
                }}
                onClick={(event) => {
                  event.stopPropagation(); event.preventDefault(); document.getElementById('uploadFile').value = null; this.setState({ questionPaper: null, testName: '', testInstruction: '' });
                }}
              >
                <img src={close} alt="x-mark" />
              </div>
            </div>
          </div>
        </div>
      );
    }
    return (
      <>
        <div style={{
          borderRadius: 4, height: 200, width: '100%', textAlign: 'center', border: '1px solid #343737', backgroundColor: '#FFFFFF', display: 'flex', flexDirection: 'column', position: 'relative', cursor: 'pointer',
        }}
        >
          <div style={{
            margin: 20, height: '100%', textAlign: 'center', backgroundColor: '#FFFFFF', display: 'flex', flexDirection: 'column', alignItems: ' center', justifyContent: 'center', border: 'none',
          }}
          >
            <input
              style={{
                position: 'absolute', opacity: '0', textAlign: 'center', zIndex: '1', height: '100%', width: '100%', cursor: 'pointer',
              }}
              type="file"
              accept={fileTypes}
              onChange={(event) => this.uploadQuestionPaper(event)}
            />
            <div style={{
              height: '35px', position: 'absolute', textAlign: 'center', width: '35px', background: `url(${uploadImage}) no-repeat`, opacity: 1,
            }}
            />
            <div style={{ display: 'flex' }}>
              <div style={{
                fontSize: '12', color: Constants().color.primary, padding: '8px', marginTop: 60,
              }}
              >
                Upload question paper
              </div>
              <div style={{
                color: 'red', marginTop: 65, marginLeft: -2, width: 10,
              }}
              >
                *
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }

  renderDateAndTimePickers() {
    const {
      testDate, testStartTime, testEndTime,
    } = this.state;
    return (
      <div>
        <style>
          {`
            #TestDate .react-datepicker__input-container input {
              border: 1px solid;
              width: 330px;
            }
            #StartTime .react-datepicker__input-container input, #EndTime .react-datepicker__input-container input {
              border: 1px solid;
              width: 130px;
            }
          `}
        </style>
        <div style={{ display: 'flex', marginBottom: 35 }}>
          <img alt="fileIcon" src={calender} height="25px" width="25px" style={{ marginRight: 20, alignSelf: 'center' }} />
          <div id="TestDate">
            <DatePicker
              selected={testDate}
              onChange={(date) => this.setState({ testDate: date })}
              dateFormat="dd/MM/yyyy"
              placeholderText="Test date"
            />
          </div>
          <div style={{ marginLeft: 15, color: 'red', width: 10 }}>{!testDate ? '*' : ''}</div>
        </div>
        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
          <div style={{ display: 'flex' }}>
            <img alt="fileIcon" src={clock} height="25px" width="25px" style={{ marginRight: 20, alignSelf: 'center' }} />
            <div id="StartTime">
              <DatePicker
                selected={testStartTime}
                onChange={(time) => this.setState({ testStartTime: time, testEndTime: '', testDurationInMinutes: '' })}
                timeInputLabel="Time:"
                showTimeSelect
                showTimeSelectOnly
                timeIntervals={30}
                dateFormat="h:mm aa"
                placeholderText="Start time"
              />
            </div>
            <div style={{ marginLeft: 15, color: 'red', width: 10 }}>{!testStartTime ? '*' : ''}</div>
          </div>
          {testStartTime && testEndTime && <div style={{ marginLeft: -15, marginRight: 10, alignSelf: 'center' }}> to </div>}
          <div style={{ display: 'flex' }}>
            <div id="EndTime">
              <DatePicker
                selected={testEndTime}
                onChange={(time) => {
                  let duration = moment(time).diff(moment(testStartTime), 'minutes');
                  duration = ((duration % 2) !== 0) ? duration + 1 : duration;
                  this.setState({ testEndTime: time, testDurationInMinutes: duration });
                }}
                timeInputLabel="Time:"
                showTimeSelect
                showTimeSelectOnly
                timeIntervals={30}
                dateFormat="h:mm aa"
                placeholderText="End time"
                minTime={testStartTime || moment().startOf('day')._d}
                maxTime={moment().endOf('day')._d}
              />
            </div>
            <div style={{ marginLeft: 15, color: 'red', width: 10 }}>{!testEndTime ? '*' : ''}</div>
          </div>
        </div>
      </div>
    );
  }

  renderTestMetaDataFilters() {
    const { testName, testMarks } = this.state;
    const { selectedClassroom } = this.props;
    const classroomsList = this.getClassroomsList();
    return (
      <div>
        {this.renderInput('testName', 'Test Name', { marginBottom: 35, height: 45 }, !testName)}
        {this.renderDateAndTimePickers()}
        {this.renderInput('testMarks', 'Total Marks', { marginTop: 35, height: 45 }, !testMarks)}
        {this.renderFilters()}
        {this.domainSearch(classroomsList, selectedClassroom,
          (selectedOption) => this.props.applyInstitueFilters({ selectedClassroom: selectedOption }), true)}
      </div>
    );
  }

  renderOfflineTestCreatingStatus() {
    const {
      testDate, testName, questionPaper, testStartTime, questionPaperUploading,
      testDurationInMinutes, testMarks,
    } = this.state;
    const {
      fileUploadedPercentage, selectedClassroom,
    } = this.props;
    const allowSubmit = questionPaper && testName && testStartTime && testDate
    && testMarks && (selectedClassroom && selectedClassroom.length);
    if (questionPaperUploading) {
      return (
        <div style={{
          width: '70%', borderRadius: 4, marginBottom: 0, maxWidth: 300, display: 'flex', flexDirection: 'row',
        }}
        >
          <div><img alt="fileTransfer" src={testPaper} height="90px" width="90px" /></div>
          <div style={{ disply: 'flex', width: '100%', alignSelf: 'center' }}>
            <div style={{ marginBottom: 10, textAlign: 'center', fontSize: 16 }}>Creating offline test..</div>
            <ProgressBar
              height={4}
              width="100%"
              fillColor={Constants().color.primary}
              fillPercentage={`${fileUploadedPercentage}%`}
            />
          </div>
        </div>
      );
    }
    return (
      <div
        role="presentation"
        className="Button"
        style={{
          height: 40, width: '50%', borderRadius: 4, display: 'flex', fontSize: 18, border: '1px solid #343737', marginBottom: 0, maxWidth: 300,
        }}
        onClick={(() => {
          if (!questionPaperUploading) {
            if (allowSubmit) {
              if (!testDurationInMinutes) {
                this.props.showToast('Test Start time and End time cannot be same!');
              } else {
                this.uploadAndCreateOfflineTest();
              }
            } else {
              this.props.showToast('The required fields are not filled');
            }
          } else {
            this.props.showToast('File is uploading please wait');
          }
        })}
      >
        Create
      </div>
    );
  }

  renderCreateTest() {
    const {
      fileTitle, testInstruction, questionPaper, newTestLoader, testDurationInMinutes,
    } = this.state;
    const { selectedDepartment, selectedSemester } = this.props;
    const allowSubmit = questionPaper && fileTitle && Object.keys(selectedDepartment).length > 0
    && Object.keys(selectedSemester).length > 0;
    if (newTestLoader) {
      return <CompactLoader height={window !== 'undefined' && window.innerHeight - 150} />;
    }
    return (
      <div style={{
        width: '100%', backgroundColor: '#FFFFFF', padding: '5% 5% 1% 5%', border: '1px solid #343737',
      }}
      >
        <form onSubmit={(event) => { this.setState({ fileTitle: allowSubmit ? '' : fileTitle, questionPaper: allowSubmit ? null : questionPaper }); this.handleFormSubmit(event); }}>
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <div style={{ width: '100%', display: 'flex' }}>
              <div role="presentation" style={{ fontSize: 22, fontWeight: 'bold', paddingBottom: 20 }}> New Test </div>
            </div>
            <div style={{ display: 'flex', flexDirection: 'row' }}>
              <div style={{ width: '50%' }}>
                <div style={{
                  width: '90%', display: 'flex', flexDirection: 'column',
                }}
                >
                  <div>
                    {this.renderTestMetaDataFilters()}
                  </div>
                </div>
              </div>
              <div style={{ width: '50%', display: 'flex', flexDirection: 'column' }}>
                <div style={{
                  display: 'flex', flexDirection: 'column', width: '90%', height: '100%',
                }}
                >
                  <textarea
                    value={testInstruction}
                    className="fill-remaining"
                    placeholder="Instructions"
                    style={{
                      border: '1px solid #343737', width: '100%', outline: 'none', padding: 15, fontsize: 16, marginBottom: 35,
                    }}
                    onChange={(event) => this.updateFieldValueChanges('testInstruction', event.target.value)}
                    required
                  />
                  <div style={{ marginTop: -20, textAlign: 'right', marginBottom: 15 }}>
                    Test Duration:
                    {testDurationInMinutes ? ` ${testDurationInMinutes} minutes` : ' -'}
                  </div>
                  {this.renderQuestionPaperData()}
                </div>
              </div>
            </div>
            <div style={{
              width: '100%', marginTop: 50, display: 'flex', justifyContent: 'center',
            }}
            >
              {this.renderOfflineTestCreatingStatus()}
            </div>
          </div>
        </form>
      </div>
    );
  }

  renderSubject(subject) {
    const testCount = subject.test_count;
    return (
      <div
        role="presentation"
        style={{
          padding: 20,
          marginBottom: 20,
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          alignSelf: 'center',
          boxShadow: '0px 1px 4px #0000003d',
          cursor: 'pointer',
          width: '100%',
          background: '#FFFFFF',
        }}
        onClick={() => {
          this.props.history.push(`/classboard/offline_test?is_test=true&subject_id=${subject.id}`);
          this.setPageType();
        }}
      >
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <img alt="fileIcon" src={folerWithPages} height="40px" width="40px" />
          <div style={{ marginRight: 30, marginLeft: 30 }}>{subject.name}</div>
        </div>
        <div style={{ whiteSpace: 'nowrap' }}>
          {testCount > 1 ? `${testCount} tests` : `${testCount} test`}
        </div>
      </div>
    );
  }

  renderOfflineTestSubjects() {
    const { testListLoader } = this.state;
    const { offlineTestListSubjectList } = this.props;
    if (testListLoader) {
      return <CompactLoader height={window !== 'undefined' && window.innerHeight - 150} />;
    }
    if (!offlineTestListSubjectList.length) {
      return (
        <div style={{
          display: 'flex', justifyContent: 'center', alignItems: 'center', height: window !== 'undefined' && window.innerHeight - 150, flexDirection: 'column',
        }}
        >
          <div>
            <img
              src={test}
              alt="folder"
              style={{
                height: 65, width: 65, marginBottom: 10, opacity: 0.7,
              }}
            />

          </div>
          <div style={{ fontSize: 22 }}> No test created yet</div>
        </div>
      );
    }
    return (
      offlineTestListSubjectList.map((subject) => {
        return this.renderSubject(subject);
      })
    );
  }

  renderTableHeader() {
    const style = { padding: 20, whiteSpace: 'nowrap', borderRight: '1px solid #DFE3EA' };
    return (
      <thead style={{ background: '#F6F8FA', color: '#23394C' }}>
        <tr>
          <th style={{ width: '20%', ...style }}>Name</th>
          <th style={{ width: '8%', ...style }}>Max mark</th>
          <th style={{ width: '30%', ...style }}>Start Time</th>
          <th style={{ width: '12%', ...style }}>Duration</th>
          <th style={{ width: '5%', ...style }}>Status</th>
          <th style={{ width: '15%', ...style, borderRight: 0 }}>Created by</th>
          <th style={{ width: '5%', ...style, borderRight: 0 }} />
        </tr>
      </thead>
    );
  }

  renderOfflineStudentResponseTableHeader() {
    const style = { padding: 20, whiteSpace: 'nowrap', borderRight: '1px solid #DFE3EA' };
    return (
      <thead style={{ background: '#F6F8FA', color: '#23394C' }}>
        <tr>
          <th style={{ width: '20%', ...style }}>Student ID</th>
          <th style={{ width: '20%', ...style }}>Student Name</th>
          <th style={{ width: '20%', ...style }}>Status</th>
          <th style={{ width: '20%', ...style }}>Time</th>
          <th style={{ width: '5%', ...style }}>Download</th>
          <th style={{ width: '15%', ...style }}>Marks</th>
        </tr>
      </thead>
    );
  }

  renderEachTest(test) {
    const style = { padding: 20, height: '100%', verticalAlign: 'middle' };
    const hour = test.duration > 59 ? test.duration / 60 : 0;
    const minutes = test.duration - (hour * 60);
    return (
      <tr onClick={() => {
        this.props.history.push(`/classboard/offline_test?is_student_responses=true&test_id=${test.id}`);
        this.setPageType();
      }}
      >
        <td style={style}>
          <div data-tip={test.instruction}>{test.name}</div>
        </td>
        <td style={style}>
          <div>{test.summary.total_mark || '-'}</div>
        </td>
        <td style={style}>
          <div>{moment(test.start_date).format('MMM Do, h:mm a')}</div>
        </td>
        <td style={style}>
          <div data-tip={hour > 0 ? `${hour} hour: ${minutes} mins` : `${minutes} minutes`}>{`${test.duration} mins`}</div>
          <ReactTooltip effect="solid" />
        </td>
        <td style={style}>
          <div>St</div>
        </td>
        <td style={style}>
          <div>{test.creator_name}</div>
        </td>
        <td style={style}>
          <img alt="" src={rightArrow} height="20px" width="20px" />
        </td>
      </tr>
    );
  }

  renderSubjectTest() {
    const { subjectOfflineTestList, offlineTestSubjectName } = this.props;
    if (!subjectOfflineTestList.length) {
      return <div>No Test available in this subject</div>;
    }
    return (
      <div>
        <div style={{ display: 'flex', flexDirection: 'row' }}>
          <div
            role="presentation"
            style={{ cursor: 'pointer', alignSelf: 'center' }}
            onClick={() => {
              this.props.history.push('/classboard/offline_test?is_subject=true');
              this.setPageType();
            }}
          >
            <img src={leftArrow} alt="back-icon" style={{ height: 20, width: 20, marginRight: 10 }} />
          </div>
          <div style={{ marginLeft: 15, fontSize: 24 }}>{offlineTestSubjectName}</div>
        </div>
        <table
          className="table table-hover"
          style={{
            boxShadow: '1px 2px 6px #8B9DAF33', width: '100%', marginTop: 30, borderRadius: 4, backgroundColor: '#FFFFFF', border: '1px solid #CDCFD6',
          }}
        >
          {this.renderTableHeader()}
          <tbody>
            {subjectOfflineTestList.map((test) => {
              return this.renderEachTest(test);
            })}
          </tbody>
        </table>
      </div>
    );
  }

  renderEachOfflineStudentResponse(studentResponse) {
    const { updateMarkStudentResponseId, studentMark } = this.state;
    const style = { padding: 20, height: '100%', verticalAlign: 'middle' };
    const query = new URLSearchParams(window.location.search);
    const studentTestStatus = {
      NOT_STARTED: {
        name: 'Not started',
        status: 'NOT_STARTED',
        color: '#BA1B1B',
      },
      QP_DOWNLOADED: {
        name: 'QP Downloaded',
        status: 'QP_DOWNLOADED',
        color: '#DBAF1A',
      },
      SUBMITTED: {
        name: 'Submitted',
        status: 'SUBMITTED',
        color: '#14B536',
      },
      MISSED: {
        name: 'Missed',
        status: 'MISSED',
        color: '#F73A3A',
      },
    };

    return (
      <tr>
        <td style={style}>
          <div>{studentResponse.badge_id}</div>
        </td>
        <td style={style}>
          <div>{studentResponse.first_name}</div>
        </td>
        <td style={{ color: studentTestStatus[studentResponse.status].color, ...style }}>
          <div>{studentTestStatus[studentResponse.status].name}</div>
        </td>
        <td style={style}>
          <div>{moment(studentResponse.updated_at).format('MMM Do, h:mm a')}</div>
        </td>
        <td style={{ textAlign: 'center', ...style }}>
          <img
            role="presentation"
            onClick={() => {
              if (studentResponse.student_answer_sheet_url) {
                window.open(studentResponse.student_answer_sheet_url);
              } else {
                this.props.showToast(`${studentResponse.first_name} not submitted the test yet!`);
              }
            }}
            src={download}
            alt="Download"
            style={{ width: 35, cursor: 'pointer', opacity: studentResponse.student_answer_sheet_url ? 1 : 0.5 }}
          />
        </td>
        <td style={style}>
          {(updateMarkStudentResponseId === studentResponse.id) ? (
            <input
              type="text"
              placeholder="marks"
              style={{
                width: '100%', outline: 'none', margin: '0px', fontSize: 16,
              }}
              onChange={(event) => {
                this.setState({ studentMark: event.target.value });
              }}
              onKeyPress={(event) => event.key === 'Enter' && this.props.updateStudentMark(studentResponse.id, studentMark, query.get('test_id')) && this.setState({ updateMarkStudentResponseId: '', studentMark: '' })}
              required
            />
          ) : <div role="presentation" style={{ cursor: 'pointer' }} onClick={() => { this.setState({ updateMarkStudentResponseId: studentResponse.id }); }}>{studentResponse.summary.marks || '-'}</div>}
        </td>
      </tr>
    );
  }

  renderStudentOfflineTestResponses() {
    const { offlineStudentResponses, offlineTest } = this.props;
    const { offlineTestCreatedBy } = this.state;
    if (!Object.keys(offlineTest).length) {
      return <div>No offline test present in this ID, if some issue persists please contact us!</div>;
    }
    if (!offlineStudentResponses.length) {
      return <div>No students mapped to the test</div>;
    }
    return (
      <div>
        <div style={{ display: 'flex', flexDirection: 'row' }}>
          <div
            role="presentation"
            style={{ cursor: 'pointer', alignSelf: 'center' }}
            onClick={() => {
              this.props.history.push(`/classboard/offline_test?is_test=true&subject_id=${offlineTest.subject_id}`);
              this.setPageType();
            }}
          >
            <img src={leftArrow} alt="back-icon" style={{ height: 20, width: 20, marginRight: 10 }} />
          </div>
          <div style={{ marginLeft: 15, fontSize: 24 }}>{offlineTest.name}</div>
        </div>
        <div style={{
          backgroundColor: 'white', borderRadius: 5, display: 'flex', padding: 15, marginTop: 15, flexDirection: 'row',
        }}
        >
          <div style={{ display: 'flex', flexDirection: 'column', width: '50%' }}>
            <div style={{ display: 'flex', margin: 10 }}>
              <div style={{ fontSize: 20, color: Constants().color.primary }}>Start Time:</div>
              <div style={{ marginLeft: 15, fontSize: 20, color: '#636363' }}>{moment(offlineTest.start_date).format('MMM Do, h:mm a')}</div>
            </div>
            <div style={{ display: 'flex', margin: 10 }}>
              <div style={{ fontSize: 20, color: Constants().color.primary }}>Max marks:</div>
              <div style={{ marginLeft: 15, fontSize: 20, color: '#636363' }}>{offlineTest.summary.total_mark}</div>
            </div>
          </div>
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <div style={{ display: 'flex', margin: 10 }}>
              <div style={{ fontSize: 20, color: Constants().color.primary }}>Duration:</div>
              <div style={{ marginLeft: 15, fontSize: 20, color: '#636363' }}>{`${offlineTest.duration} mins`}</div>
            </div>
            {offlineTestCreatedBy && (
            <div style={{ display: 'flex', margin: 10 }}>
              <div style={{ fontSize: 20, color: Constants().color.primary }}> Created By:</div>
              <div style={{ marginLeft: 15, fontSize: 20, color: '#636363' }}>{offlineTestCreatedBy}</div>
            </div>
            )}
          </div>
        </div>
        <table
          className="table table-hover"
          style={{
            boxShadow: '1px 2px 6px #8B9DAF33', width: '100%', marginTop: 30, borderRadius: 4, backgroundColor: '#FFFFFF', border: '1px solid #CDCFD6',
          }}
        >
          {this.renderOfflineStudentResponseTableHeader()}
          <tbody>
            {offlineStudentResponses.map((studentResponse) => {
              return this.renderEachOfflineStudentResponse(studentResponse);
            })}
          </tbody>
        </table>
      </div>
    );
  }

  renderTestList() {
    const { isCreateTest, testListPageType, testListLoader } = this.state;
    if (testListLoader) {
      return <CompactLoader height={window !== 'undefined' && window.innerHeight - 150} />;
    }
    if (!isCreateTest) {
      if (testListPageType === offLineTestPageType.subject) {
        return this.renderOfflineTestSubjects();
      } if (testListPageType === offLineTestPageType.test) {
        return this.renderSubjectTest();
      }
      return this.renderStudentOfflineTestResponses();
    }
    return this.renderCreateTest();
  }

  renderContent() {
    const { isCreateTest, newTestLoader } = this.state;
    const { onClass } = this.props;
    const innerHeight = typeof window !== 'undefined' && window.innerHeight;
    const selected = {
      borderBottom: `3px solid ${Constants().color.primary}`, color: Constants().color.primary, fontSize: 20, height: 60, paddingTop: 15, cursor: 'pointer',
    };
    const unselected = {
      fontSize: 20, color: '#c4c4c4', height: 60, paddingTop: 15, cursor: 'pointer',
    };
    return (
      <div style={{
        padding: '100px 85px 50px', backgroundColor: newTestLoader ? '#FFFFFF' : '#F5F8FA', width: '100%', height: '100%', minHeight: innerHeight,
      }}
      >
        <div style={{
          marginBottom: 10, position: 'fixed', left: onClass ? '20%' : 0, right: 0, top: 0, zIndex: 10,
        }}
        >
          <div style={{
            backgroundColor: Constants().primaryBackgroundColor, height: 60, padding: '20px 85px', display: 'flex', justifyContent: 'space-between', alignItems: 'center', borderBottom: '1px solid #EAEDF5',
          }}
          >
            <div style={{ display: 'flex' }}>
              <div
                role="presentation"
                className="heading-1"
                style={isCreateTest ? selected : unselected}
                onClick={() => {
                  this.props.history.push('/classboard/offline_test');
                  this.setPageType();
                  this.props.applyInstitueFilters({ selectedClassroom: [] });
                }}
              >
                New Test
              </div>
              <div
                role="presentation"
                className="ml-5 heading-1"
                style={isCreateTest ? unselected : selected}
                onClick={() => {
                  this.props.history.push('/classboard/offline_test?is_subject=true');
                  this.setPageType();
                  this.props.applyInstitueFilters({ selectedClassroom: [] });
                }}
              >
                Test list
              </div>
            </div>
          </div>
        </div>
        {isCreateTest ? this.renderCreateTest() : this.renderTestList()}
      </div>
    );
  }

  render() {
    let innerHeight = '';
    if (typeof window !== 'undefined') {
      innerHeight = window.innerHeight;
    }
    return (
      <Layout hideHeader title="Test">
        <div style={{
          width: '100%', display: 'flex', height: '100', fontFamily: 'Roboto',
        }}
        >
          {this.renderContent()}
        </div>
      </Layout>
    );
  }
}

OfflineTest.propTypes = {
  maxFileSize: PropTypes.number,
  semesters: PropTypes.array,
  subjects: PropTypes.array,
  selectedClassroom: PropTypes.array,
  departments: PropTypes.array,
  isHigherEducation: PropTypes.bool,
  classrooms: PropTypes.array,
  campuses: PropTypes.array,
  onClass: PropTypes.bool,
  selectedCampus: PropTypes.object,
  selectedDepartment: PropTypes.object,
  selectedSemester: PropTypes.object,
  selectedSubject: PropTypes.object,
  showToast: PropTypes.func.isRequired,
  fileUploadedPercentage: PropTypes.string.isRequired,
  offlineTestListSubjectList: PropTypes.array.isRequired,
  createOfflineTest: PropTypes.func.isRequired,
  getSubjectsOfflineTest: PropTypes.func.isRequired,
  subjectOfflineTestList: PropTypes.array.isRequired,
  offlineTestSubjectName: PropTypes.string.isRequired,
  offlineStudentResponses: PropTypes.array.isRequired,
  offlineTest: PropTypes.object.isRequired,
};

OfflineTest.defaultProps = {
  maxFileSize: 1,
  semesters: [],
  subjects: [],
  departments: [],
  selectedClassroom: [],
  isHigherEducation: false,
  classrooms: [],
  onClass: false,
  campuses: [],
  selectedCampus: {},
  selectedDepartment: {},
  selectedSemester: {},
  selectedSubject: {},
};

const mapStateToProps = ({ filesBoard, virtualMeeting }) => ({
  instituteFiles: filesBoard.instituteFiles,
  maxFileSize: filesBoard.maxFileSize,
  semesters: filesBoard.semesters,
  departments: filesBoard.departments,
  subjects: filesBoard.subjects,
  isHigherEducation: filesBoard.isHigherEducation,
  classrooms: filesBoard.classrooms,
  campuses: filesBoard.campuses,
  selectedClassroom: virtualMeeting.selectedClassroom,
  selectedDepartment: virtualMeeting.selectedDepartment,
  selectedSemester: virtualMeeting.selectedSemester,
  selectedSubject: virtualMeeting.selectedSubject,
  selectedCampus: virtualMeeting.selectedCampus,
  fileUploadedPercentage: filesBoard.fileUploadedPercentage,
  subjectFiles: filesBoard.subjectFiles,
  modulesAndLectures: filesBoard.modulesAndLectures,
  testTypes: filesBoard.testTypes,
  offlineTestListSubjectList: filesBoard.offlineTestListSubjectList,
  subjectOfflineTestList: filesBoard.subjectOfflineTestList,
  offlineTestSubjectName: filesBoard.offlineTestSubjectName,
  offlineStudentResponses: filesBoard.offlineStudentResponses,
  offlineTest: filesBoard.offlineTest,
});

export default connect(mapStateToProps, {
  getMetaData,
  getSignedUrl,
  uploadFileTo3,
  createOfflineTest,
  applyInstitueFilters,
  getOfflineTestListSubjects,
  getOfflineTestStudentResponse,
  getSubjectsOfflineTest,
  fileUploadPercentage,
  showToast,
  updateAttachmentDetails,
  hideFile,
  updateStudentMark,
})(OfflineTest);
