import { STUDENT_OVERVIEW } from '../actions';

const initialState = {
  testReport: {},
  subjects: [],
};

export default function (state = initialState, action) {
  switch (action.type) {
    case STUDENT_OVERVIEW: {
      return { ...state, ...action.payload };
    }
    default:
      return state;
  }
}
