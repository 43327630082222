import Api from '../helpers/Api';

export const QUIZ_LIST = '@quiz/QUIZ_LIST';
export const QUIZ_DATA = '@quiz/QUIZ_DATA';
export const QUIZ_QUESTION_LIST = '@quiz/QUIZ_QUESTION_LIST';
export const QUIZ_QUESTION_DATA_LIST = '@quiz/QUIZ_QUESTION_DATA_LIST';
export const APPEND_NEW_QUESTION = '@quiz/APPEND_NEW_QUESTION';
export const UPDATE_SHOW_QUESTION_LIST = '@quiz/UPDATE_SHOW_QUESTION_LIST';
export const QUIZ_RESULT = '@quiz/QUIZ_RESULT';
export const REMOVE_QUESTIONS = '@quiz/REMOVE_QUESTIONS';

export function getQuizDetails() {
  return (dispatch) => Api({
    method: 'get',
    url: '/quiz/get_quiz_list',
  }).then((response) => {
    if (response.success) {
      const payload = {
        quizList: response.quiz_list,
      };
      dispatch({
        type: QUIZ_LIST,
        payload,
      });
    }
    return response;
  });
}

export function createQuiz(quizName, quizDate) {
  return (dispatch) => Api({
    method: 'post',
    url: '/quiz/create',
    data: { quiz_name: quizName, quiz_date: quizDate },
  }).then((response) => {
    if (response.success) {
      const payload = {
        quizData: response.quiz,
      };
      dispatch({
        type: QUIZ_DATA,
        payload,
      });
    }
    return response;
  });
}

export function updateCompleteQuizState(quizId, status) {
  return () => Api({
    method: 'post',
    url: '/quiz/complete_quiz',
    data: { quiz_id: quizId, quiz_status: status },
  }).then((response) => {
    return response;
  });
}

export function getOrUpdateQuiz(quizId, quizName = '', quizDate = '') {
  return (dispatch) => Api({
    method: 'post',
    url: '/quiz/update',
    data: { quiz_id: quizId, quiz_name: quizName, quiz_date: quizDate },
  }).then((response) => {
    if (response.success) {
      const payload = {
        quizData: response.quiz,
        quizDate: response.quiz_date,
        setDate: response.set_date,
      };
      dispatch({
        type: QUIZ_DATA,
        payload,
      });
    }
    return response;
  });
}

export function getQuizQuestions(filters) {
  const backendUrl = '/quiz/get_quiz_questions';
  const queryParams = Object.keys(filters).map((key) => `${encodeURIComponent(key)}=${encodeURIComponent(filters[key])}`).join('&');
  return (dispatch) => Api({
    method: 'get',
    url: `${backendUrl}?${queryParams}`,
  }).then((response) => {
    if (response.success) {
      const payload = {
        hasTextExtractionAccess: response.has_text_extraction_access,
        quizQuestionList: response.quiz_questions,
      };
      dispatch({
        type: QUIZ_QUESTION_LIST,
        payload,
      });
    }
    return response;
  });
}

export function getQuizResults(filters) {
  const backendUrl = '/quiz/get_quiz_results';
  const queryParams = Object.keys(filters).map((key) => `${encodeURIComponent(key)}=${encodeURIComponent(filters[key])}`).join('&');
  return (dispatch) => Api({
    method: 'get',
    url: `${backendUrl}?${queryParams}`,
  }).then((response) => {
    let payload = {};
    if (response.success) {
      payload = {
        quizResult: response.quiz_results,
        questionScores: response.question_scores,
        totalQuestions: response.total_questions,
        overallScore: response.overall_score,
        classrooms: response.classroom_details,
      };
    } else {
      payload = { quizResult: {} };
    }
    dispatch({
      type: QUIZ_RESULT,
      payload,
    });
    return response;
  });
}

export function getQuizRankListData(id, classroomId) {
  return (dispatch) => Api({
    method: 'post',
    url: '/quiz/get_quiz_rank_list',
    data: { id, classroom_id: classroomId },
  }).then((response) => {
    dispatch({
      type: QUIZ_DATA,
      payload: {
        rankListData: [...response.rank_list],
        classrooms: { ...response.classroom_details },
      },
    });
    return response;
  });
}

export function updateQuizQuestion(id, filters, imageFile = {}, removedImages = {}) {
  return (dispatch) => Api({
    method: 'post',
    url: '/quiz/update_quiz_question',
    attachment: imageFile.length > 1 ? imageFile : imageFile[0],
    type: 'reported_image_file',
    data: {
      quiz_question: JSON.stringify(filters),
      quiz_id: id,
      removed_images: JSON.stringify(removedImages),
    },
  }).then((response) => {
    if (!(filters.id > 0)) {
      dispatch({
        type: APPEND_NEW_QUESTION,
        textChanges: filters,
      });
    }
    return response;
  });
}

export function getQuizQuestionData(id) {
  return (dispatch) => Api({
    method: 'post',
    url: '/quiz/get_quiz_question_data',
    data: { id },
  }).then((response) => {
    dispatch({
      type: QUIZ_QUESTION_DATA_LIST,
      payload: { quizQuestionData: response.quiz_question_data },
    });
    return response;
  });
}

export function updateShowQuestionStatus(questionIds, showQuestion, quizId) {
  return (dispatch) => Api({
    method: 'post',
    url: '/quiz/update_show_question',
    data: {
      question_ids: JSON.stringify(questionIds),
      show_question: showQuestion,
      quiz_id: quizId,
    },
  }).then((response) => {
    dispatch({
      type: UPDATE_SHOW_QUESTION_LIST,
      ids: questionIds,
    });
    return response;
  });
}

export function convertImage(image) {
  return (dispatch) => Api({
    method: 'post',
    url: '/quiz/get_text_from_image',
    attachment: image,
    type: 'image_file',
    data: { image: image && URL.createObjectURL(image) },
  }).then((response) => {
    return response;
  });
}

export function removeQuizQuestion(quizId) {
  return (dispatch) => Api({
    method: 'post',
    url: '/quiz/remove_quiz_question',
    data: { id: quizId },
  }).then((response) => {
    dispatch({
      type: REMOVE_QUESTIONS,
      id: quizId,
    });
    return response;
  });
}

export const getQuizFeedback = (id, meetId, classroomId = '') => async (dispatch) => {
  const response = await Api({
    method: 'get',
    url: `/live_classrooms/get_quiz_feedback?id=${id}&meetId=${meetId}&classroom_id=${classroomId}`,
  });
  if (response.success) {
    dispatch({
      type: QUIZ_DATA,
      payload: {
        feedbacks: response.feedbacks,
        feedbackWidgetData: response.feedback_widget_data,
        isHigherEducation: response.is_higher_education,
        isAnonymousFeedback: response.is_anonymous_feedback,
        classrooms: response.classrooms,
      },
    });
  }
  return response;
};
