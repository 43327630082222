import React from 'react';
import Layout from '../layout';
import Testimonial from '../testimonial';
import GetInvite from '../modal/getInviteModal';
import Constants from '../../shared/constants';
import Counter from '../counter';
import { lengthOfNumber, checkDevice } from '../../helpers/util';
import { sendEvent } from '../../helpers/Analytics';

class InstituteLandingPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      sliderValue: 2000,
      device: 'Desktop',
      displayHeight: '',
    };
    this.renderSliderValues = this.renderSliderValues.bind(this);
  }

  componentDidMount() {
    checkDevice();
    this.setState({ displayHeight: global.innerHeight, device: global.sessionStorage.getItem('device') });
    sendEvent('InstituteLandingPage', 'InstituteLandingPage', 'Open');
  }

  renderTestimonial(img, content, name) {
    const { device } = this.state;
    return (
      <div
        className="row m-5"
        style={{
          backgroundColor: 'white', height: device === 'Desktop' && '40%', borderRadius: '5px', boxShadow: '0 2px 2px 0 rgba(0,0,0,0.16), 0 0 0 1px rgba(0,0,0,0.08)', textAlign: !(device === 'Desktop') && 'center',
        }}
      >
        <div className={device === 'Desktop' ? 'col-sm-4 m-3' : 'col-sm-3'}>
          <img src={img} width={device === 'Desktop' ? '128px' : '64px'} height="auto" style={{ margin: '5%', borderRadius: '5px' }} />
        </div>
        <div className={device === 'Desktop' ? 'col-sm-7 mt-5' : 'col-sm-3'}>
          <p style={{ fontSize: device === 'Desktop' ? '18px' : '14px' }}>{content}</p>
          <p style={{ textAlign: device === 'Desktop' ? 'right' : 'center', fontSize: device === 'Desktop' ? '14px' : '10px' }}>{name}</p>
        </div>
      </div>
    );
  }

  renderFeatureBox(image, heading, content) {
    const { device } = this.state;
    return (
      <div className={device === 'Desktop' ? 'col-sm mr-4 justify-content-center' : 'col-sm-12 justify-content-center'} style={{ backgroundColor: 'white', borderRadius: '10px', margin: device === 'Desktop' ? ' ' : '5%' }}>
        <div style={{ margin: '10%' }}>
          <div style={{ textAlign: 'center' }}>
            <div style={{ height: '100px', marginBottom: '30px' }}><img src={image} /></div>
            <p style={{ fontWeight: 'bold', fontSize: device === 'Desktop' ? '24px' : '16px' }}>{heading}</p>
          </div>
          <p style={{ fontSize: device === 'Desktop' ? '18px' : '12px' }}>{content}</p>
        </div>
      </div>
    );
  }

  renderSliderValues(e) {
    this.setState({ sliderValue: e.target.value });
  }

  renderTreeData(count, type) {
    const { device } = this.state;
    return (
      <div style={{ color: '#219653', width: '33%' }}>
        <span style={{ fontSize: device === 'Desktop' ? '58px' : '24px', fontFamily: 'sans-serif', fontWeight: '100' }}>{count}</span>
        <hr width={device === 'Desktop' ? '90px' : '80x'} style={{ marginTop: '0px', marginBottom: '12px' }} />
        <span style={{ fontSize: device === 'Desktop' ? '16px' : '12px' }}>{type}</span>
      </div>
    );
  }

  value(number) {
    const length = lengthOfNumber(number);
    if (length === 6 || length === 7) {
      return `${number / 100000}L`;
    }
    if (length > 7) {
      return `${(number / 10000000).toFixed(1)}Cr`;
    }

    return number;
  }

  instituteExpenditure() {
    const money = 1200000 + 5000 * this.state.sliderValue;
    const paper = 6000 * this.state.sliderValue;
    const hours = (2 * 40 * 6 * 0.33 * this.state.sliderValue) / 60 + 15;
    return [this.value(money), this.value(paper), hours];
  }

  render() {
    const { device } = this.state;

    return (
      <Layout globalHistory={this.props.history} footerShow institute device={this.state.device}>
        <div className="jumbotron" style={{ background: 'linear-gradient(151.16deg, #F2C94C 20.78%, #F2994A 79.58%)' }}>
          <div className="row" style={{ margin: '2% 5% 5% 5%', textAlign: device === 'Desktop' ? '' : 'center' }}>
            <div className={device === 'Desktop' ? 'col-sm-6 mt-5' : 'col mt-5'}>
              <p style={{ color: 'white', fontWeight: 'bold', fontSize: device === 'Desktop' ? '36px' : '24px' }}>Rankers aren’t born. They’re made.</p>
              <p style={{ color: '#535353', fontSize: device === 'Desktop' ? '24px' : '14px', fontWeight: '100' }}>Streamline the NEET/JEE experience for the teachers, students and you with the IdeaBoard suite.</p>
              <GetInvite modal={false} bgcolor="#535353" width={device === 'Desktop' ? '250px' : 'auto'} product={Constants().products.ideaboard} header="Request free demo" />
            </div>
            <div className="col-sm-6 mt-5">
              <iframe
                className="player"
                type="text/html"
                width="85%"
                height="110%"
                src="https://www.youtube.com/embed/9pUeFBNdLWQ?rel=0"
                frameBorder="0"
              />
            </div>
          </div>
        </div>
        <div style={{ margin: '4%' }}><img alt="" src={device === 'Desktop' ? '/static/images/how_it_works.jpg' : '/static/images/how_it_works_image.svg'} width="100%" /></div>
        <div className="row" style={{ background: 'linear-gradient(151.16deg, #F2C94C 20.78%, #F2994A 79.58%)' }}>
          <div className="row" style={{ margin: '5%' }}>
            {this.renderFeatureBox('/static/images/icon1.png', 'Go smart, go paperless', 'Get useful insights for both students and teachers, while eliminating any DTP/printing costs.')}
            {this.renderFeatureBox('/static/images/icon2.png', 'Use your time better', 'Teachers create question papers in minutes. So they spend less time on paperwork, more time with students.')}
            {this.renderFeatureBox('/static/images/icon3.png', 'Do more than just teach', 'Students get a highly personalised report telling them exactly what to work on, and our goal-based learning app takes them further.')}
          </div>
        </div>
        <div id="imageContainer">
          <img alt="" src={device === 'Desktop' ? '/static/images/tree.png' : '/static/images/tree_mobile.svg'} width="100%" />
          <div className={device === 'Desktop' ? 'textBlock' : 'testBlockMobile'}>
            <p style={{
              fontSize: device === 'Desktop' ? '24px' : '16px', textAlign: 'center', color: '#535353', fontWeight: 'normal',
            }}
            >
              How much will your institute
              <br />
              <span style={{ fontSize: device === 'Desktop' ? '40px' : '24px' }}>SAVE EVERY YEAR? </span>
            </p>
            <p style={{ textAlign: 'left', fontSize: device === 'Desktop' ? '18px' : '12px' }}>
              No. of students:
              <b>{this.state.sliderValue}</b>
            </p>
            <div className="slidecontainer">
              <input type="range" min="100" max="5000" value={this.state.sliderValue} className="slider" id="myRange" step="100" onChange={this.renderSliderValues} />
            </div>
            <div className="row">
              {this.renderTreeData(this.instituteExpenditure()[0], 'RUPEES')}
              {this.renderTreeData(this.instituteExpenditure()[1], 'PAPER')}
              {this.renderTreeData(this.instituteExpenditure()[2], 'HOURS')}
            </div>
          </div>
        </div>
        <Testimonial device={this.state.device} />
        <Counter device={this.state.device} />
        <GetInvite modal text="Join a growing family of smart institutions and take the community forward." bgcolor="#F2994A" product={Constants().products.ideaboard} device={this.state.device} header="Request free demo" />
        <style jsx>
          {// language=CSS
          `
            #imageContainer {
              position: relative;
            }

            .textBlock {
              width: 35%;
              position: absolute;
              bottom: 25%;
              right: 10%;
              color: black;
              padding-left: 10px;
              padding-right: 10px;
              text-align: center;
            }

            .testBlockMobile {
              position: absolute;
              bottom: 17%;
              right: 5.5%;
              text-align: center;
              width: 90%;
              background-color: rgba(255, 255, 255, 0.9);
              padding: 5%;
            }

            .slidecontainer {
                width: 100%;
            }

            .slider {
                -webkit-appearance: none;
                width: 100%;
                height: 2px;
                border-radius: 5px;
                background: #d3d3d3;
                outline: none;
                opacity: 0.7;
                -webkit-transition: .2s;
                transition: opacity .2s;
            }

            .slider:hover {
                opacity: 1;
            }

            .slider::-webkit-slider-thumb {
                -webkit-appearance: none;
                appearance: none;
                width: 20px;
                height: 20px;
                border-radius: 15px;
                background: #4CAF50;
                cursor: pointer;
            }
          `
      }
        </style>
      </Layout>
    );
  }
}

export default InstituteLandingPage;
