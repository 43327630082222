import React from 'react';

export default function CompactLoader(props = '') {
  const { height} = props;
  return (
    <div>
      <style jsx>
        {/* language=CSS */ `
          height: ${height}px;
          width: 100%;
          background: url('/static/images/loader.gif') center no-repeat #fff;
        `}
      </style>
    </div>
  );
}

