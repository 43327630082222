import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import CountUp from 'react-countup';
import { landingPageData } from '../../actions';
import { numberWithCommas } from '../../helpers/util';

class Counter extends React.Component {
  componentDidMount() {
    this.props.landingPageData(this.props);
    this.interval = setInterval(() => this.props.landingPageData(this.props), 10000);
  }

  componentWillUnmount() {
    clearInterval(this.interval);
  }

  renderData(count, type) {
    return (
      <div
        className="col-sm"
        style={{
          color: '#F2C94C', textAlign: 'center', fontFamily: 'Fira Sans Condensed', marginTop: '7%', marginBottom: '7%',
        }}
      >
        <span style={{ fontWeight: '100', fontSize: this.props.device === 'Desktop' ? '72px' : '36px' }}>
          {count.newValue && <CountUp start={count.oldValue} end={count.newValue} duration={count.oldValue > 0 ? 10 : 5} formattingFn={numberWithCommas} />}
        </span>
        <p style={{ fontFamily: 'Montserrat', fontSize: this.props.device === 'Desktop' ? '18px' : '12px', fontWeight: 'bold' }}>{type}</p>
      </div>
    );
  }

  render() {
    const { numberOfQuestions, numberOfTests, numberOfAnswers } = this.props;
    return (
      <div className="container" style={{ background: 'linear-gradient(90.18deg, #863F36 0%, #5F3071 53.59%, #283483 100%)', maxWidth: '100%', width: '100%' }}>
        <div className="row">
          {this.renderData(numberOfQuestions, 'QUESTIONS')}
          {this.renderData(numberOfTests, 'TESTS TAKEN')}
          {this.renderData(numberOfAnswers, 'QUESTIONS ANSWERED')}
        </div>
      </div>
    );
  }
}

const mapStateToProps = ({ assignment }) => ({
  numberOfQuestions: assignment.numberOfQuestions,
  numberOfAnswers: assignment.numberOfAnswers,
  numberOfTests: assignment.numberOfTests,
});

export default connect(mapStateToProps, { landingPageData })(Counter);
