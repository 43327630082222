import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import StarRatingComponent from 'react-star-rating-component';

class FeedbackProgressBar extends PureComponent {
  renderProgressBar(data, index) {
    return (
      <div style={{ display: 'flex', marginBottom: 15 }}>
        <div style={{
          marginRight: 25, fontWeight: 'bold', color: '#556080',
        }}
        >
          {index}
      &nbsp;&nbsp;&#9733;
        </div>
        <div style={{
          background: '#F4F4F4', width: 280, display: 'flex', height: 31,
        }}
        >
          <div style={{
            background: '#F2A46F', width: `${data.x}%`, height: 31,
          }}
          />
        </div>
        <div style={{
          marginLeft: 15, width: 50, color: '#556080', fontWeight: 'bold',
        }}
        >
          {data.x}
            %
        </div>
      </div>
    );
  }

  render() {
    const { feedbackWidgetData } = this.props;
    const ratingArray = [1, 2, 3, 4, 5];
    const graphData = [];
    ratingArray.map((index) => {
      graphData.push({ x: feedbackWidgetData[`count_${index}`] || 0, y: index });
    });
    const average = (feedbackWidgetData.average || 0).toFixed(1); return (
      <div style={{ width: '100%' }}>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <StarRatingComponent
            name="Rating"
            value={average}
            starCount={5}
            onStarClick={() => {}}
            onStarHover={() => {}}
            onStarHoverOut={() => {}}
            renderStarIcon={() => <div style={{ fontSize: 20, marginRight: 5 }}>&#9733;</div>}
            renderStarIconHalf={() => {}}
            starColor="#F2A46F"
            emptyStarColor="#CECECECF"
            editing={false}
          />
          <div style={{ fontSize: 20, marginLeft: 15 }}>
            {average}
            &nbsp;out of 5
          </div>
        </div>
        <div style={{ fontSize: 18 }}>
          {feedbackWidgetData.no_of_ratings}
          &nbsp;ratings
        </div>
        <div style={{ marginTop: 30, width: '100%', marginRight: 30 }}>
          {graphData.map((data, index) => this.renderProgressBar(data, index + 1)) }
        </div>
      </div>
    );
  }
}

FeedbackProgressBar.propTypes = {
  feedbackWidgetData: PropTypes.object,
};

FeedbackProgressBar.defaultProps = {
  feedbackWidgetData: {},
};

export default connect(null)(FeedbackProgressBar);
