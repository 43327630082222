import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import dropdownArrow from '../../static/images/drop-down-arrow.svg';
import searchDropdown from '../../static/images/search_dropdown.svg';
import xMark from '../../static/images/x-mark.svg';


class DropDown extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      showOptions: false,
      searchString: '',
      width: props.width,
      defaultText: props.defaultText,
      optionsList: props.optionsList,
    };
    this.closeComponents = this.closeComponents.bind(this);
    this.renderContent = this.renderContent.bind(this);
  }

  getSearchResults() {
    const { optionsList } = this.props;
    const { searchString } = this.state;
    const searchResultList = [];
    optionsList.forEach((option) => {
      if (option.name.toUpperCase().indexOf(searchString.toUpperCase()) >= 0) {
        searchResultList.push(option);
      }
    });
    this.setState({ optionsList: searchResultList });
  }

  showComponents(event) {
    event.preventDefault();
    this.setState({ showOptions: true }, () => {
      document.addEventListener('click', this.closeComponents);
    });
  }

  closeComponents(event) {
    if (event && (event.target.className !== 'show-options')) {
      this.setState({ showOptions: false }, () => document.removeEventListener('click', this.closeComponents));
    }
  }

  hideOption() {
    const { defaultText } = this.state;
    const {
      color, height, selectedItems, selectedItem,
    } = this.props;
    const selectedData = selectedItem && selectedItem.name ? selectedItem.name : defaultText;
    return (
      <div style={{
        height, alignItems: 'center', marginLeft: 20, display: 'flex', justifyContent: 'space-between',
      }}
      >
        <div
          color={color}
          style={{
            whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', marginRight: 5,
          }}
        >
          {selectedItems && selectedItems.length > 0
            ? (this.renderSelectedItemsInDropdown(selectedItems)) : selectedData}
        </div>
        <img src={dropdownArrow} alt="drop-down" style={{ marginRight: 10, height: 10, width: 10 }} />
      </div>
    );
  }

  showOption() {
    const { optionType } = this.props;
    return (
      <div style={{
        display: 'flex', padding: 5, background: '#FFFFFF', border: '1px solid #7C94BA', borderRadius: 4,
      }}
      >
        <img src={searchDropdown} alt="search" style={{ marginRight: 10 }} />
        <input
          id="searchString"
          type="text"
          autoComplete="off"
          placeholder={`${optionType}`}
          autoFocus
          style={{
            border: 0, marginBottom: 0, height: '100%', width: '100%', backgroundColor: 'inherit', margin: 0, outline: 'none', color: 'black', fontSize: 14,
          }}
          onChange={(e) => {
            this.setState({ searchString: e.target.value }, () => this.getSearchResults());
          }}
        />
      </div>
    );
  }

  showSelectedItems(option) {
    const { selectedItems, optionsList } = this.props;
    const length = selectedItems && selectedItems.length > 0 && selectedItems.filter((item) => {
      return item.id === option.id || item.name === option.name;
    }).length;
    return (
      <div
        role="presentation"
        style={{
          padding: '10px 30px',
          background: '#FFFFFF',
          alignItems: 'center',
          cursor: 'pointer',
          borderBottom: '1px solid #E2E2E2',
          display: 'flex',
          justifyContent: 'space-between',
        }}
        onClick={() => this.setState({ showOptions: 'false', optionsList }, () => this.props.onValueChange(option.id, option.name))}
      >
        <div
          key={option.id}
          style={{ color: length === 1 && '#225989' }}
        >
          {option.name}
        </div>
        {length === 1 && (
        <div
          role="presentation"
          onClick={(event) => {
            event.stopPropagation(); this.setState({
              optionsList,
            }, () => this.props.onValueChange(option.id, option.name));
          }}
        >
          <img src={xMark} alt="close" style={{ marginLeft: 10 }} />
        </div>
        )}
      </div>
    );
  }

  showSelectedValue() {
    const { optionsList } = this.props;
    return (
      <div
        role="presentation"
        onClick={(event) => {
          event.stopPropagation(); this.setState({ optionsList },
            () => this.props.onValueChange(null));
        }}
      >
        <img src={xMark} alt="close" style={{ marginLeft: 10 }} />
      </div>
    );
  }


  renderOptions(option) {
    const { selectedItem, selectedItems, optionsList } = this.props;
    if (selectedItems) {
      this.showSelectedItems(option);
    }
    return (
      <div
        role="presentation"
        key={option.id}
        style={{
          padding: '10px 30px',
          background: '#FFFFFF',
          alignItems: 'center',
          cursor: 'pointer',
          borderBottom: '1px solid #E2E2E2',
          display: 'flex',
          justifyContent: 'space-between',
        }}
        onClick={() => this.setState({ showOptions: 'false', optionsList }, () => this.props.onValueChange(option.id, option.name))}
      >
        <div
          key={option.id}
          style={{ color: selectedItem && (option.id === selectedItem.id) && '#225989' }}
        >
          {option.name}
        </div>
        {selectedItem && (option.id === selectedItem.id) && this.showSelectedValue()}
      </div>
    );
  }

  renderSelectedItemsInDropdown(selectedItems) {
    return selectedItems.map((item) => { return item.name; }).join(', ').substring(0, 50);
  }

  renderOptionsList(optionsList) {
    const { width } = this.state;
    return (
      <div style={{
        maxHeight: 130, minHeight: 35, overflow: 'scroll', zIndex: 1, backgroundColor: 'white', borderRadius: '0px 0px 4px 4px', width, maxWidth: this.props.maxWidth, position: 'absolute', border: '1px solid #7C94BA',
      }}
      >
        {optionsList.map((option) => this.renderOptions(option))}
      </div>
    );
  }

  renderContent() {
    const {
      showOptions, width, optionsList,
    } = this.state;
    const { style, notEditable, maxWidth, dropDownColor } = this.props;
    const dropDownStyle = {
      width: width + 1, maxWidth, border: optionsList.length > 0 ? dropDownColor : '1px solid #D0D0D0', borderRadius: 4, pointerEvents: notEditable || optionsList.length === 0 ? 'none' : '', opacity: notEditable || optionsList.length === 0 ? 0.5 : 1, position: 'relative',
    };
    return (
      <div role="presentation" className="show-options" style={{ ...dropDownStyle, ...style }} onClick={(event) => this.showComponents(event)}>
        {showOptions ? this.showOption() : this.hideOption()}
        {showOptions && this.renderOptionsList(optionsList)}
      </div>
    );
  }

  render() {
    const { optionsList, width } = this.state;
    const { notEditable, maxWidth } = this.props;
    return (
      <div style={{ cursor: notEditable || optionsList.length === 0 ? 'not-allowed' : 'pointer', height: 40, width, maxWidth }}>
        {this.renderContent()}
      </div>
    );
  }
}
DropDown.propTypes = {
  onValueChange: PropTypes.func.isRequired,
  optionsList: PropTypes.array,
  notEditable: PropTypes.bool,
  selectedItem: PropTypes.object,
  style: PropTypes.object,
  selectedItems: PropTypes.array,
  color: PropTypes.string,
  height: PropTypes.number,
  defaultText: PropTypes.string,
  optionType: PropTypes.string,
  dropDownColor: PropTypes.string,
};
DropDown.defaultProps = {
  optionsList: [],
  selectedItem: {},
  style: {},
  notEditable: false,
  selectedItems: [],
  color: '#454545',
  height: 40,
  defaultText: '',
  optionType: '',
  dropDownColor: '1px solid #7C94BA',
};
export default DropDown;

