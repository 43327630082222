import React, { Component } from 'react';
import { connect } from 'react-redux';
import Pagination from 'react-js-pagination';
import Cookies from 'universal-cookie';
import { ExcelExport, ExcelExportColumn } from '@progress/kendo-react-excel-export';
import Loader from '../loading';
import Modal from '../modal/baseModal';
import { ApplyFilter, DateRange } from '../common';
import Constants from '../../shared/constants';
import {
  getStudentsReport, markPresent, markAbsent, applyInstitueFilters, showToast,
} from '../../actions';

const cookies = new Cookies();
const roles = cookies.get('roles');

const studentWidth = 55;
const ATTENDANCE = {
  A: '#ef9a9a',
  P: '#a5d6a7',
};

const today = new Date();
const semesterStartDate = new Date(today.getFullYear(), today.getMonth() > 6 ? 6 : 0, 1);
const semesterEndDate = new Date(today.getFullYear(), today.getMonth() > 6 ? 11 : 5, 1);
const endingDate = new Date();
endingDate.setDate(new Date().getDate() - 7);
const yesterday = new Date();
yesterday.setDate((yesterday).getDate() - 1);
const SELECTED_RANGE = {
  startDate: endingDate,
  endDate: new Date(),
  key: 'selection',
};

const calculateWidth = (index, isStudent) => {
  let width = !index ? '20%' : '10%';
  if (isStudent) {
    width = index === 1 ? (5 * studentWidth) : (index === 0 ? (4 * studentWidth) : studentWidth);
  }
  return width;
};
class StudentsAttendance extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedRange: SELECTED_RANGE,
      showDateRange: false,
      showAttendanceShortage: false,
    };
    this.selectDateRange = this.selectDateRange.bind(this);
    this.handleSelect = this.handleSelect.bind(this);
    this.attendanceDetailsToSave = React.createRef();
  }

  componentDidMount() {
    const { quizId } = this.props;
    const { showAttendanceShortage, selectedRange } = this.state;
    if (!quizId) {
      this.props.getStudentsReport(selectedRange, showAttendanceShortage);
    }
  }

  getStudentsReport(pageNumber) {
    const {
      campuses, filterClassrooms, departments, semesters, subjects,
      selectedClassroom, selectedCampus, selectedDepartment, selectedSemester,
      page, total_page: totalPage, selectedSubject,
    } = this.props.virtualMeeting;
    const { quizId } = this.props;
    const { showAttendanceShortage, selectedRange } = this.state;
    if (pageNumber > totalPage || pageNumber < 1) {
      this.props.showToast('Page limit reached');
      return;
    }
    if (quizId) {
      this.props.getStudentsReport(selectedRange, showAttendanceShortage,
        pageNumber || page, quizId);
    } else {
      const classroom = (selectedClassroom && selectedClassroom.id) || !filterClassrooms.length;
      const campus = (selectedCampus && selectedCampus.id) || !campuses.length;
      const department = (selectedDepartment && selectedDepartment.id || !departments.length);
      const semester = (selectedSemester && selectedSemester.id || !semesters.length);
      const subject = (selectedSubject && selectedSubject.id || !subjects.length);
      if (!(subject && classroom)) {
        if (!semester) {
          if (!department) {
            this.props.showToast('No filter selected');
            return;
          }
          if ((department && roles.is_dean) || roles.is_hod) {
            this.props.showToast('Select semester');
            return;
          }
        }
        if (subject || classroom) {
          this.props.showToast('Select subject and section');
          return;
        }
      }
      this.props.getStudentsReport(
        selectedRange,
        showAttendanceShortage,
        pageNumber || page,
        '',
        Number.isInteger(classroom) ? classroom : '',
        Number.isInteger(campus) ? campus : '',
        Number.isInteger(department) ? department : '',
        Number.isInteger(semester) ? semester : '',
        Number.isInteger(subject) ? subject : '',
      );
    }
  }

  selectDateRange() {
    const { selectedRange, showDateRange } = this.state;
    return (
      <Modal
        showModal={showDateRange}
        overrideCloseIcon
        handleCloseModal={() => this.setState({ showDateRange: false })}
        style={{
          padding: 30, overflowY: 'initial', overflowX: 'initial',
        }}
      >
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
          <DateRange
            showSelectionPreview={false}
            selectedRange={{ ...selectedRange }}
            color="#1c3e6b"
            maxDate={new Date()}
            showPreview={false}
            handleSelect={this.handleSelect}
          />
          <div
            className="Button"
            style={{ width: 150 }}
            role="presentation"
            onClick={() => this.setState({ showDateRange: false })}
          >
            SET
          </div>
        </div>
      </Modal>
    );
  }

  handleSelect(date) {
    this.setState({ selectedRange: date.selection });
  }

  filterView() {
    const {
      campuses, filterClassrooms, departments, semesters, teachers, subjects,
      isHigherEducation, selectedCampus, selectedDepartment, selectedTeacher, selectedSubject,
      selectedClassroom, selectedSemester,
    } = this.props.virtualMeeting;

    return (
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <ApplyFilter
          inline
          hideClear
          hideTeacher
          hideDepartment={!roles.is_dean}
          hideSemester={roles.is_teacher && !roles.is_dean && !roles.is_hod}
          classrooms={filterClassrooms}
          semesters={semesters}
          departments={departments}
          teachers={teachers}
          subjects={subjects}
          isHigherEducation={isHigherEducation}
          selectedCampus={{ ...selectedCampus }}
          selectedDepartment={{ ...selectedDepartment }}
          selectedClassroom={{ ...selectedClassroom }}
          selectedSemester={{ ...selectedSemester }}
          selectedTeacher={{ ...selectedTeacher }}
          selectedSubject={{ ...selectedSubject }}
          applyDataFilters={() => this.getStudentsReport()}
        />
        <div
          role="presentation"
          className="Button Primary-Button"
          style={{ width: 150, marginLeft: 20 }}
          onClick={() => this.setState({ showDateRange: true })}
        >
          Date Range
        </div>
      </div>
    );
  }

  renderTable(isStudent) {
    const {
      attendances, page, per_page: perPage, total_items: totalItems, table_header: tableConfig,
    } = this.props.virtualMeeting;
    return (
      <div style={{ marginTop: 20 }}>
        <div style={{ overflowX: isStudent ? 'auto' : 'hidden' }}>
          { (isStudent ? tableConfig.length > 4 : tableConfig.length > 1)
            && (
            <table
              className={`table table-hover ${isStudent ? 'table-bordered' : ''} table-fixed`}
              style={{
                boxShadow: '1px 2px 6px #8B9DAF33', borderRadius: 4, backgroundColor: '#FFFFFF', width: '100%', border: '1px solid #CDCFD6',
              }}
            >
              <thead style={{ background: '#F6F8FA', color: '#23394C' }}>
                <tr
                  className="table-header"
                  style={{
                    fontSize: '18px', fontWeight: '700', height: 60, color: '#11426C', width: '100%',
                  }}
                >
                  { tableConfig.map((title, index) => (
                    <th
                      key={index}
                      className="table-header"
                      style={{
                        width: calculateWidth(index, isStudent), minWidth: isStudent && (index === 0 ? (4 * studentWidth) : (index === 1 ? (5 * studentWidth) : studentWidth)), textAlign: isStudent && (index > 1) ? 'center' : 'left', paddingRight: 10, paddingLeft: index < 1 ? 30 : 10, borderRight: '1px solid #DFE3EA', border: '1px solid #CDCFD6',
                      }}
                    >
                      {title}
                    </th>
                  ))}
                </tr>
              </thead>
              <tbody>
                { attendances
                  && attendances.map((attendance, index) => this.renderAttendanceList(attendance, tableConfig, index, isStudent))}
              </tbody>
            </table>
            )}
        </div>
        {attendances.length === 0 ? (
          <div style={{
            display: 'flex', justifyContent: 'center', alignItems: 'center', color: '#CDCFD6', height: 60, backgroundColor: '#FFFFFF',
          }}
          >
            No reports available yet!
          </div>
        ) : (
          <div style={{
            display: 'flex', marginTop: 10, justifyContent: 'center', width: '100%',
          }}
          >
            <Pagination
              activePage={page}
              itemsCountPerPage={perPage}
              totalItemsCount={totalItems || 1}
              pageRangeDisplayed={5}
              onChange={(pageNumber) => this.getStudentsReport(pageNumber)}
            />
          </div>
        )}
      </div>
    );
  }

  renderAttendanceData(tableConfig, attendance, isStudent, counter) {
    if (isStudent) {
      return tableConfig.map((title, index) => {
        if (index > 3) {
          return (
            <td
              style={{
                width: calculateWidth(index + 4, true), minWidth: (index === 0 ? (4 * studentWidth) : (index === 1 ? (5 * studentWidth) : studentWidth)), cursor: 'pointer', verticalAlign: 'middle', paddingRight: 10, paddingLeft: 10, textAlign: 'center', backgroundColor: attendance.period_details[title].attendance && ATTENDANCE[attendance.period_details[title].attendance],
              }}
              onDoubleClick={() => (attendance.period_details[title].attendance_id ? this.props.markAbsent(attendance.period_details[title].attendance_id, title, counter, index) : this.props.markPresent(attendance.student_id, attendance.period_details[title].period_id, title, counter, index))}
            >
              {attendance.period_details[title].attendance}
            </td>
          );
        }
      });
    }
    return (
      tableConfig.map((title, index) => {
        if (index > 0) {
          return (
            <td
              style={{
                width: calculateWidth(index, false), verticalAlign: 'middle', paddingRight: 10, paddingLeft: 10, textAlign: 'left',
              }}
            >
              {attendance.period_details[title] >= 0 ? `${attendance.period_details[title]} %` : '--'}
            </td>
          );
        }
      })
    );
  }

  renderAttendanceList(attendance, tableConfig, counter, isStudent) {
    const basicDetailIndexes = isStudent ? [0, 1, 2, 3] : [0];
    return (
      <tr style={{ fontSize: '14px', height: 50 }}>
        {basicDetailIndexes.map((index) => {
          return (
            <td
              style={{
                width: calculateWidth(index, isStudent), verticalAlign: 'middle', paddingRight: 10, paddingLeft: 30, textAlign: 'left', backgroundColor: ATTENDANCE[attendance[tableConfig[index]]],
              }}
            >
              {index === 3 ? `${attendance[tableConfig[index]]} %` : attendance[tableConfig[index]]}
            </td>
          );
        })}
        {this.renderAttendanceData(tableConfig, attendance, isStudent, counter)}
      </tr>
    );
  }

  renderDownloadButton() {
    return (
      <div
        role="presentation"
        className="Button"
        onClick={() => this.attendanceDetailsToSave.current.save()}
        style={{
          fontWeight: 'bold', color: '#3994AE', border: '1px solid #3994AE', width: 100,
        }}
      >
        Export
      </div>
    );
  }

  renderContent(isStudent, isQuizBoard) {
    const {
      page, total_page: totalPage, loading, attendanceDetailsToSave,
    } = this.props.virtualMeeting;
    const { showAttendanceShortage } = this.state;

    if (loading) {
      return (
        <Loader />
      );
    }
    return (
      <>
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          {!isQuizBoard && (
          <div style={{ display: 'flex', flex: 1, alignItems: 'center' }}>
            {this.filterView()}
          </div>
          )}
          <div style={{
            display: 'flex', justifyContent: isStudent ? 'space-between' : 'flex-end', alignItems: 'center', marginTop: 20,
          }}
          >
            {!isQuizBoard && isStudent && (
            <div style={{ display: 'flex' }}>
              <input
                checked={showAttendanceShortage}
                onChange={() => this.setState({ showAttendanceShortage: !showAttendanceShortage }, () => { if (this.state.showAttendanceShortage) { this.setState({ selectedRange: { startDate: semesterStartDate, endDate: semesterEndDate, key: 'selection' } }, () => this.getStudentsReport(1)); } else { this.setState({ selectedRange: SELECTED_RANGE }, () => this.getStudentsReport(1)); } })}
                id="react-switch-new"
                type="checkbox"
                style={{ marginRight: 10, height: 15 }}
              />
              Students with attendance shortage
            </div>
            )}
            {!isQuizBoard && attendanceDetailsToSave && attendanceDetailsToSave.length > 0 && this.renderDownloadButton()}
          </div>
        </div>
        {this.selectDateRange()}
        {this.renderTable(isStudent)}
      </>
    );
  }

  renderName(name) {
    return name.length > 40 ? `${name.substr(0, 40)}...` : name;
  }

  render() {
    const {
      attendances, selectedSubject, attendanceDetailsToSave,
      selectedClassroom, table_header: tableConfig,
    } = this.props.virtualMeeting;
    const { quizId } = this.props;
    const isStudent = tableConfig.includes('Student');
    const showName = isStudent && selectedSubject && selectedSubject.name && selectedClassroom && selectedClassroom.name;
    const isQuizBoard = !!quizId;
    let innerHeight = null;
    if (typeof window !== 'undefined') {
      innerHeight = window.innerHeight;
    }
    return (
      <div style={{ background: isQuizBoard ? '#FFFFFF' : '#F5F8FA', height: typeof window !== 'undefined' && window.innerHeight }}>
        {!isQuizBoard && (
        <div style={{
          fontWeight: 600, fontSize: 24, padding: '15px 60px', background: '#FFFFFF', color: Constants().color.primary, boxShadow: '0px 3px 6px #343B4014',
        }}
        >
          {showName ? `Attendance for ${this.renderName(selectedClassroom.name)} for ${this.renderName(selectedSubject.name)}` : 'Attendance'}
        </div>
        )}
        {isQuizBoard ? this.renderContent(isStudent, isQuizBoard) : (
          <div style={{
            padding: '25px 65px', backgroundColor: '#F5F8FA', width: '100%',
          }}
          >
            {this.renderContent(isStudent, isQuizBoard)}
          </div>
        )}
        <ExcelExport
          data={attendanceDetailsToSave}
          fileName="attendanceDetails.xlsx"
          ref={this.attendanceDetailsToSave}
        >
          {tableConfig.map((field) => (<ExcelExportColumn field={field} title={field} />))}
        </ExcelExport>
      </div>
    );
  }
}

const mapStateToProps = ({ virtualMeeting }) => ({
  virtualMeeting,
});

export default connect(mapStateToProps, {
  getStudentsReport,
  markPresent,
  markAbsent,
  showToast,
  applyInstitueFilters,
})(StudentsAttendance);
