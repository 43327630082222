import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Pagination from 'react-js-pagination';
import Constants from '../../shared/constants';
import Layout from '../layout';
import Menubar from '../menubar';
import TeamManagementModal from '../modal/teamManagementModal';
import { getTeamManagementDetails } from '../../actions';

const PAGE_COUNT = 10;

class TeamManagement extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      page: 1,
      editTeam: 0,
      showTeamManagementModal: false,
      hasAccess: true,
    };
    this.renderPagination = this.renderPagination.bind(this);
  }

  componentDidMount() {
    this.props.getTeamManagementDetails().then((data) => {
      this.setState({ hasAccess: data.success });
    });
  }

  noPermission() {
    return (
      <div style={{
        paddingTop: 300,
        paddingLeft: 300,
        width: '100%',
        backgroundColor: '#F5F8FA',
        fontSize: '20px',
      }}
      >
        <span style={{ borderStyle: 'solid', padding: '10px' }}>You dont have permission to access this page </span>
      </div>
    );
  }

  renderPagination(team) {
    const { page } = this.state;
    if (team.length > 0 && team.length > PAGE_COUNT) {
      return (
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <Pagination
            activePage={page || 1}
            itemsCountPerPage={PAGE_COUNT}
            totalItemsCount={team.length}
            pageRangeDisplayed={5}
            onChange={(pageNumber) => this.setState({ page: pageNumber })}
          />
        </div>
      );
    }
    return <div />;
  }

  renderTeamManagementListHeader() {
    const headerStyle = {
      border: '1px solid #c5c6c7', borderRight: '1px solid #dedfe0', paddingLeft: 20,
    };
    return (
      <>
        <th style={{ ...headerStyle, textAlign: 'left', width: '20%' }}>Email</th>
        <th style={{
          ...headerStyle, textAlign: 'left', width: '20%',
        }}
        >
          Type
        </th>
        <th style={{ ...headerStyle, textAlign: 'left', width: '40%' }}>Permissions</th>
      </>
    );
  }

  renderTeamData(team) {
    const notEditable = false;
    return (
      <tr style={{ textAlign: 'center' }}>
        <td
          role="presentation"
          style={{ cursor: notEditable ? 'not-allowed' : 'pointer', color: '#1589FF', textAlign: 'left' }}
          onClick={() => !notEditable
            && this.setState({ editTeam: team.id }, () => {
              this.setState({ showTeamManagementModal: true });
            })}
        >
          {team.email}
        </td>
        <td style={{ textAlign: 'left' }}>
          {team.user_type}
        </td>
        <td style={{ textAlign: 'left' }}>
          {team.permissions ? team.permissions.join(', ') : ''}
        </td>
      </tr>
    );
  }

  renderOverviewList() {
    const { page } = this.state;
    const { teamManagementDetails } = this.props;
    const minCount = page >= 2 ? (page - 1) * PAGE_COUNT : 0;
    const maxCount = (page) * PAGE_COUNT;
    return (
      <div>
        <div style={{ backgroundColor: '#F5F8FA', width: '1000px' }}>

          <div className="heading-2" style={{ marginTop: 60 }}>
            <div className="heading-1" style={{ paddingBottom: 20, fontSize: 20 }}>
              Team Management
            </div>
            Total Members :&nbsp;
            {teamManagementDetails.length}
          </div>
          <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: 20 }}>
            <div
              role="presentation"
              style={{
                height: 35, width: 140, display: 'flex', justifyContent: 'center', alignItems: 'center', border: '1px solid #CDCFD6', borderRadius: 4, cursor: 'pointer',
              }}
              onClick={() => this.setState({ showTeamManagementModal: true, editTeam: 0 })}
            >
              Add Member
            </div>
          </div>
          <div className="mt-5">
            <table className="table table-hover" style={{ boxShadow: '1px 2px 6px #8B9DAF33', borderRadius: 4, border: '1px solid #c5c6c7' }}>
              <thead style={{ background: '#F6F8FA', color: '#23394C' }}>
                <tr style={{
                  fontSize: '18px', fontWeight: 700, height: 60, color: '#11426C',
                }}
                >
                  {this.renderTeamManagementListHeader()}
                </tr>
                {teamManagementDetails.slice(minCount, maxCount).map((team) => {
                  return this.renderTeamData(team);
                })}
              </thead>
              <tbody />
            </table>
          </div>
        </div>
      </div>
    );
  }

  render() {
    const { instituteOnBoardingMenu } = Constants();
    const { showTeamManagementModal, editTeam, hasAccess } = this.state;
    const { teamManagementDetails } = this.props;
    const query = new URLSearchParams(window.location.search);
    const instituteId = query.get('institute_id');
    instituteOnBoardingMenu.forEach((page) => {
      page.route = `${page.route}${instituteId !== null ? `?institute_id=${instituteId}` : ''}`;
    });
    const teamData = teamManagementDetails.find((team) => parseInt(team.id, 10) === parseInt(editTeam, 10));
    return (
      <Layout globalHistory={this.props.history} hideHeader>
        <div
          className="custom-checkbox"
          style={{
            width: '100%', display: 'flex', minHeight: '100vh', backgroundColor: '#F5F8FA',
          }}
        >
          <div style={{ width: '20%', padding: 0, zIndex: 1 }}>
            <Menubar
              history={this.props.history}
              menuList={instituteOnBoardingMenu}
              selectedOption={7}
              redirectToRoot
            />
          </div>
          <div style={{ backgroundColor: '#F5F8FA', paddingLeft: '50px' }}>
            {hasAccess
              ? (
                <div>
                  <TeamManagementModal
                    visible={showTeamManagementModal}
                    isEdit={editTeam > 0}
                    key={editTeam}
                    teamManagementData={{ ...teamData }}
                    header={editTeam > 0 ? 'Edit Details' : 'Add New Member'}
                    submitButton={editTeam > 0 ? 'Update' : 'Add'}
                    onClose={() => { this.setState({ showTeamManagementModal: false, editTeam: 0 }); }}
                  />
                  {this.renderOverviewList()}
                  {this.renderPagination(teamManagementDetails)}
                </div>
              ) : this.noPermission()}
          </div>
        </div>
      </Layout>
    );
  }
}
TeamManagement.propTypes = {
  teamManagementDetails: PropTypes.array,
};

TeamManagement.defaultProps = {
  teamManagementDetails: [],
};

const mapStateToProps = ({ institute }) => ({
  teamManagementDetails: institute.teamManagementDetails,
  campuses: institute.campuses,
});

export default connect(mapStateToProps,
  { getTeamManagementDetails })(TeamManagement);
