import React, { Component } from 'react';
import PropTypes from 'prop-types';
import DatePicker from 'react-datepicker';
import moment from 'moment';
import Modal from './baseModal';
import TimePicker from '../timePicker';
import 'react-datepicker/dist/react-datepicker.css';

class ConfirmationModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      testDate: new Date(props.testDate),
      showSyllabus: props.showTestSyllabus,
      startTime: moment().startOf('day'),
      showStartTime: false,
    };
  }

  render() {
    const {
      testDate, startTime, showSyllabus, showStartTime,
    } = this.state;
    const {
      visible, title, description, buttons, onConfirm, onClose, isMobile,
      classrooms, studentsCount, isTest, isOnline, isMockTest,
    } = this.props;
    const classroomNames = classrooms.map((classroom) => classroom.label);
    return (
      <Modal showModal={visible} handleCloseModal={onClose} width={isMobile ? window.innerWidth - 60 : 'auto'}>
        <div style={{ margin: 30 }}>
          {title ? <div style={{ marginBottom: 10, fontSize: 20 }}>{title}</div> : null}
          <div>{description}</div>
          <div>
            <b> Classrooms: </b>
            {classroomNames.join(' , ') || '-'}
          </div>
          <div>
            <b> Total students: </b>
            {studentsCount || '-'}
          </div>
          {isTest
            && (
              <>
                <div style={{ display: 'flex' }}>
                  <b>Date:</b>
                  <div style={{ marginLeft: 'auto' }}>
                    <DatePicker
                      selected={testDate}
                      onChange={(date) => this.setState({ testDate: date })}
                      dateFormat="dd/MM/yyyy"
                      placeholderText="Enter date"
                    />
                  </div>
                </div>
                <div style={{ display: 'flex', marginBottom: 10 }}>
                  <input
                    type="checkbox"
                    checked={showStartTime}
                    onChange={() => this.setState({ showStartTime: !showStartTime, startTime: moment().startOf('day') })}
                    style={{ fontSize: 'x-large' }}
                  />
                  <label style={{ fontSize: 16, fontWeight: 'bold', marginLeft: 20 }}>Mention Start Time</label>
                </div>
                {showStartTime
                  && (
                    <div style={{ display: 'flex' }}>
                      <b>Start Time:</b>
                      <div style={{ marginLeft: 'auto' }}>
                        <TimePicker
                          use12Hours
                          minuteStep={1}
                          onChange={(time) => this.setState({ startTime: time })}
                          value={startTime}
                        />
                      </div>
                    </div>
                  )}
              </>
            )}
          {isTest && isOnline && isMockTest
            && (
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <b> Show test syllabus in test instruction: </b>
                <input type="checkbox" checked={showSyllabus} onChange={() => this.setState({ showSyllabus: !showSyllabus })} style={{ fontSize: 'x-large', margin: '5px 0px 0px 5px' }} />
              </div>
            )}
          <div className="row" style={{ justifyContent: 'flex-end', marginTop: 20 }}>
            {buttons.map((buttonConfig) => (
              <div
                key={buttonConfig.text}
                role="presentation"
                id={buttonConfig.primary ? 'confirm-show-test' : 'cancel-show-test'}
                className={`${buttonConfig.primary ? 'primary_button' : 'secondary_button mr-3'}`}
                style={{ padding: 10, width: 100 }}
                onClick={() => (buttonConfig.action || (buttonConfig.primary && onConfirm) ? (!isTest ? onConfirm() : onConfirm(testDate, startTime, showSyllabus, showStartTime, true)) : onClose())}
              >
                {buttonConfig.text}
              </div>
            ))}
          </div>
        </div>
      </Modal>
    );
  }
}

ConfirmationModal.propTypes = {
  isTest: PropTypes.bool,
  visible: PropTypes.bool,
  isOnline: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
  title: PropTypes.string,
  isMockTest: PropTypes.bool,
  buttons: PropTypes.array,
  onConfirm: PropTypes.func.isRequired,
  description: PropTypes.string.isRequired,
  isMobile: PropTypes.bool,
  classrooms: PropTypes.array,
  studentsCount: PropTypes.number,
  testDate: PropTypes.string,
  showTestSyllabus: PropTypes.bool,
};

ConfirmationModal.defaultProps = {
  isTest: false,
  visible: false,
  testDate: null,
  isOnline: false,
  isMockTest: false,
  title: '',
  isMobile: false,
  showTestSyllabus: false,
  classrooms: [],
  studentsCount: null,
  buttons: [{ text: 'Cancel', action: null }, { text: 'Ok', action: null, primary: true }],
};

export default ConfirmationModal;
