import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import ReactTooltip from 'react-tooltip';
import PropTypes from 'prop-types';
import Loader from '../loading';
import ProgressBar from '../progressBar';
import Modal from './baseModal';
import Constants from '../../shared/constants';
import uploadFiles from '../../static/images/uploadFile.svg';
import {
  getSignedUrlAndUploadToS3, getInstituteFiles, updateAttachmentDetails,
  hideFile, fileUploadPercentage, applyInstitueFilters, showToast,
} from '../../actions';
import { isMobileDevice } from '../../helpers/util';
import { BYTES_TO_MEGA_BYTE, FILE_IMAGES } from '../../constants';
import '../../styles/instituteOnBoarding.css';
import closeMark from '../../static/images/close-mark.svg';
import excel from '../../static/images/fileTypes/excel.svg';
import file from '../../static/images/fileTypes/file.svg';
import image from '../../static/images/fileTypes/image.svg';
import pdf from '../../static/images/fileTypes/powerpoint.svg';
import video from '../../static/images/fileTypes/video.svg';
import folder from '../../static/images/fileTypes/folder.svg';
import word from '../../static/images/fileTypes/word.svg';

const images = {
  excel,
  file,
  folder,
  image,
  pdf,
  video,
  word,
};

const IS_MOBILE = isMobileDevice();

class VirtualMeetingFiles extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      fileTypes: 'image/*,video/*,.pdf,.ppt,.doc,.docx,.pptx,application/vnd.ms-powerpoint,text/plain,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel',
      showLoader: true,
      showModal: false,
      fileTitle: '',
      fileDescription: '',
      fileUploaded: false,
      userFile: null,
      editableFile: null,
      searchString: '',
      isUploadPage: true,
      fileUploading: false,
      fileExtension: 'file',
    };
    this.renderContent = this.renderContent.bind(this);
    this.renderFileUploader = this.renderFileUploader.bind(this);
    this.renderSubInfo = this.renderSubInfo.bind(this);
    this.updateFieldValueChanges = this.updateFieldValueChanges.bind(this);
    this.handleCloseModal = this.handleCloseModal.bind(this);
    this.uploadFiles = this.uploadFiles.bind(this);
    this.updateFileDetails = this.updateFileDetails.bind(this);
    this.publishFiles = this.publishFiles.bind(this);
    this.renderTable = this.renderTable.bind(this);
    this.renderFileData = this.renderFileData.bind(this);
  }

  componentDidMount() {
    this.props.getInstituteFiles(this.props.meeting.id).then(() => {
      this.setState({ showLoader: false });
    });
  }

  getLogo() {
    document.getElementById('uploadFile').click();
  }

  updateFieldValueChanges(type, value) {
    if (type === 'selectedClassroom' && !value) {
      this.setState({ [type]: null });
      return;
    }
    this.setState({ [type]: value });
  }

  handleCloseModal() {
    this.setState({ showModal: false });
  }

  handleFormSubmit(e) {
    e.preventDefault();
  }

  updateFileDetails(fileId, fileTitle, fileDescription, classroomIds) {
    this.props.updateAttachmentDetails(fileId, fileTitle, fileDescription, classroomIds);
    this.setState({ showModal: false });
  }

  uploadFiles(event) {
    const { maxFileSize } = this.props;
    const file = event.target.files[0];
    if (!file) {
      return;
    }
    if (file.size > (maxFileSize * BYTES_TO_MEGA_BYTE)) {
      this.props.showToast(`File should be less than ${maxFileSize} MB.`);
    } else {
      const fileExtension = file.name.split('.')[(file.name.split('.').length) - 1];
      const fileimage = FILE_IMAGES[fileExtension];
      this.setState({
        fileUploaded: true, userFile: file, fileTitle: file.name.split('.')[0], fileExtension: fileimage || FILE_IMAGES.default,
      });
    }
  }

  publishFiles() {
    const { fileTitle, fileDescription, userFile } = this.state;
    const { meeting } = this.props;
    const classroomIds = meeting.classroom_ids;
    this.setState({ fileUploading: true });
    this.props.getSignedUrlAndUploadToS3(fileTitle, fileDescription, userFile, classroomIds, '', '', '', meeting.subject_id, meeting.id, false).then(() => {
      this.setState({
        fileUploading: false, fileTitle: '', fileDescription: '', userFile: null,
      });
      this.props.fileUploadPercentage(0);
    });
  }

  renderFileList(file) {
    const fileExtension = file.file_url.split('.')[file.file_url.split('.').length - 1];
    const fileImage = FILE_IMAGES[fileExtension] || FILE_IMAGES.default;
    return (
      <tr style={{ fontSize: '16px', height: 50 }}>
        <td style={{ verticalAlign: 'middle' }}>
          <div
            data-tip={file.description}
            className="ellipsis clickable"
            style={{
              width: 'fit-content', cursor: 'pointer', paddingLeft: 20, maxWidth: 550, textOverflow: 'ellipsis',
            }}
          >
            <img src={images[fileImage]} alt="file" style={{ height: 25, width: 25, marginRight: 15 }} />
            <a target="_blank" href={file.file_url}>{file.title}</a>
            <ReactTooltip effect="solid" />
          </div>
        </td>
        <td style={{ display: 'flex', justifyContent: 'left' }}>
          <div style={{ display: 'flex', cursor: 'pointer', marginLeft: 10 }}>
            <div
              role="presentation"
              className={`${file.show ? 'black-white-button' : 'white-black-button'}`}
              style={{
                height: 35, width: 150, marginTop: 10, marginBottom: 10,
              }}
              onClick={() => this.props.hideFile(file.id)}
            >
              {file.show ? 'Hide' : 'Unhide'}
            </div>
          </div>
        </td>
      </tr>
    );
  }

  renderTable() {
    const { instituteFiles } = this.props;
    const counter = 0;

    return (
      <div style={{ marginTop: 80 }}>
        {instituteFiles.length > 0 && (
        <table
          className="table table-hover"
          style={{
            boxShadow: '1px 2px 6px #8B9DAF33', borderRadius: 4, backgroundColor: '#FFFFFF', border: '1px solid #CDCFD6',
          }}
        >
          <thead style={{ background: '#F6F8FA', color: '#23394C' }}>
            <tr
              className="table-header"
              style={{
                fontSize: '18px', fontWeight: '700', height: 60, color: '#11426C', width: '100%', paddingLeft: 20, borderRight: '1px solid #DFE3EA',
              }}
            >
              <th
                className="table-header"
                style={{
                  width: '43%', borderTop: 'none', paddingLeft: 20, borderRight: '1px solid #DFE3EA',
                }}
              >
                Name
              </th>
              <th
                className="table-header"
                style={{
                  width: '23%', borderTop: 'none', textAlign: 'left', paddingLeft: 20, borderRight: '1px solid #DFE3EA',
                }}
              >
                Action
              </th>
            </tr>
          </thead>
          <tbody>
            {instituteFiles
              && instituteFiles.map((file) => this.renderFileList(file))}
          </tbody>
        </table>
        ) }
        {instituteFiles.length === 0
          && (
          <div style={{
            display: 'flex', justifyContent: 'center', alignItems: 'center', color: '#CDCFD6', height: 60, backgroundColor: '#FFFFFF',
          }}
          >
            No Files to show.
          </div>
          )}
      </div>
    );
  }

  renderInput(type) {
    return (
      <input
        type="text"
        value={this.state[type]}
        style={{
          border: 'none', borderBottom: '1px solid #E4E7EE', width: '100%', outline: 'none', margin: '5px 0px 0px 0px', fontSize: 16,
        }}
        onChange={(event) => this.updateFieldValueChanges(type, event.target.value)}
        required
      />
    );
  }

  renderSubInfo(label, type, required = false) {
    return (
      <div style={{ marginBottom: 10 }}>
        <div className="heading-3">
          {label}
          {required && <span style={{ color: '#F96161' }}> * </span>}
        </div>
        {(type !== 'fileDescription')
          ? this.renderInput(type)
          : (
            <textarea
              value={this.state[type]}
              style={{
                border: 'none', borderBottom: '1px solid #E4E7EE', width: '100%', outline: 'none', marginTop: 5, marginBottom: 0,
              }}
              onChange={(event) => this.updateFieldValueChanges(type, event.target.value)}
              required
            />
          )}
      </div>
    );
  }

  renderFileData() {
    const { userFile, fileTypes, fileExtension } = this.state;
    const isImage = userFile && userFile.type.includes('image');
    const isPdf = userFile && userFile.type.includes('pdf');
    const isVideo = userFile && userFile.type.includes('video');
    const fileFormat = userFile && userFile.name.split('.').slice(-1).pop();
    const isPpt = userFile && ((fileFormat === 'ppt') || (fileFormat === 'pptx'));
    const isExcel = userFile && ((fileFormat === 'xls') || (fileFormat === 'xlsx'));
    const isDoc = userFile && ((fileFormat === 'doc') || (fileFormat === 'docx'));
    if (userFile) {
      return (
        <div style={{ height: 200, width: '100%' }}>
          <div style={{ height: '0px', overflow: 'hidden' }}>
            <input id="uploadFile" type="file" name="file" accept=".pdf" onChange={(event) => this.uploadFiles(event)} />
          </div>
          <div style={{ cursor: 'pointer', height: '100%' }}>
            <div style={{
              borderRadius: '4px', border: 'solid 1px #cdcdce', height: '100%', display: 'flex', justifyContent: 'center', padding: 5, position: 'relative',
            }}
            >
              {isImage && <img alt="" src={URL.createObjectURL(userFile)} style={{ maxWidth: '60%', width: 'fit-content' }} height="fit-content" />}
              {isPdf && <embed style={{ maxWidth: '60%', width: 'fit-content' }} height="fit-content" name="plugin" src={URL.createObjectURL(userFile)} scrolling="no" />}
              {isVideo && (
                <video width="100%" controls>
                  <source src={URL.createObjectURL(userFile)} type="video/mp4" />
                  <source src={URL.createObjectURL(userFile)} type="video/ogg" />
                  Your browser does not support HTML video.
                </video>
              )}
              {(isPpt || isDoc || isExcel) && (
              <div style={{
                alignSelf: 'center', overflow: 'hidden', textOverflow: 'ellipsis', textAlign: 'center',
              }}
              >
                {userFile.name}
              </div>
              )}
              <div
                role="presentation"
                style={{
                  height: 35, backgroundColor: '#E4E7EE', width: '100%', position: 'absolute', bottom: 0, left: 0, color: '#40A7FF', display: 'flex', justifyContent: 'center', alignItems: 'center',
                }}
                onClick={this.getLogo}
              >
                <img src={`../../static/images/fileTypes/${fileExtension}.svg`} alt="file" style={{ height: 20, width: 20, marginRight: 10 }} />
                Change
              </div>
              <div
                role="presentation"
                style={{
                  position: 'absolute', top: 5, right: 10, cursor: 'pointer',
                }}
                onClick={(event) => { event.stopPropagation(); event.preventDefault(); document.getElementById('uploadFile').value = null; this.setState({ userFile: null, fileUploaded: false }); }}
              >
                <img src={closeMark} alt="x-mark" />
              </div>
            </div>
          </div>
        </div>
      );
    }
    return (
      <>
        <div style={{
          borderRadius: 4, height: 200, width: '100%', textAlign: 'center', border: '1px solid #E4E7EE', backgroundColor: '#FFFFFF', display: 'flex', flexDirection: 'column', position: 'relative', cursor: 'pointer',
        }}
        >
          <div style={{
            margin: 20, borderRadius: '5px', height: '100%', textAlign: 'center', border: '1px solid #E4E7EE', backgroundColor: '#FFFFFF', display: 'flex', flexDirection: 'column', borderStyle: 'dashed', alignItems: ' center', justifyContent: IS_MOBILE ? '' : 'center',
          }}
          >
            <input
              style={{
                position: 'relative', opacity: '0', textAlign: 'center', zIndex: '1', height: '100%', width: '100%', cursor: 'pointer',
              }}
              type="file"
              accept={fileTypes}
              onChange={(event) => this.uploadFiles(event)}
            />
            <div style={{
              height: '35px', position: 'absolute', textAlign: 'center', width: '35px', background: `url(${uploadFiles}) no-repeat`, opacity: 1,
            }}
            />
            <div style={{
              color: Constants().color.primary, padding: '8px', fontSize: IS_MOBILE ? 12 : 18,
            }}
            >
              {' '}
              Drag or browse the file to upload.
            </div>
          </div>
        </div>
      </>
    );
  }

  renderFileUploadStatus() {
    const {
      fileTitle, userFile, fileUploading,
    } = this.state;
    const { fileUploadedPercentage } = this.props;
    const allowSubmit = userFile && fileTitle;
    if (fileUploading) {
      return (
        <>
          <div style={{ textAlign: 'center' }}>
            Uploading...
          </div>
          <ProgressBar
            height={4}
            width="100%"
            fillColor={Constants().color.primary}
            style={{ marginTop: 2 }}
            fillPercentage={`${fileUploadedPercentage}%`}
          />
        </>
      );
    }
    return (
      <div
        role="presentation"
        className={!fileUploading ? 'Button' : ''}
        style={{
          height: 30, width: '100%', borderRadius: 4, display: 'flex', justifyContent: 'center', alignItems: 'center', lineHeight: 0, fontSize: 14, border: fileUploading ? '0px' : '1px solid #343737', marginBottom: 0, alignContent: 'flex-end',
        }}
        onClick={(() => {
          if (!fileUploading) {
            if (allowSubmit) {
              this.publishFiles();
            } else {
              this.props.showToast('The required fields are not filled');
            }
          } else {
            this.props.showToast('File is uploading please wait');
          }
        })}
      >
        Share
      </div>
    );
  }

  renderFileUploader() {
    const {
      fileTitle, fileDescription, userFile,
    } = this.state;
    const allowSubmit = userFile && fileTitle && fileDescription;
    return (
      <div style={{
        width: '100%', backgroundColor: '#FFFFFF', padding: '5% 5% 1% 5%', marginTop: 100, height: '90%',
      }}
      >
        <form onSubmit={(event) => { this.setState({ fileTitle: allowSubmit ? '' : fileTitle, fileDescription: allowSubmit ? '' : fileDescription, userFile: allowSubmit ? null : userFile }); this.handleFormSubmit(event); }} style={{ display: 'flex' }}>
          <div style={{ width: '50%', display: 'flex', flexDirection: 'column' }}>
            <div style={{ width: '90%' }}>
              <div className="heading-2">
                File
                <span style={{ color: '#F96161' }}> * </span>
              </div>
              {this.renderFileData()}
            </div>
          </div>
          <div style={{ width: '50%', display: 'flex', justifyContent: 'center' }}>
            <div style={{ width: '90%' }}>
              {this.renderSubInfo('Name', 'fileTitle', true)}
              {this.renderSubInfo('Description', 'fileDescription')}
              {this.renderFileUploadStatus()}
            </div>
          </div>
        </form>
      </div>
    );
  }

  renderContent() {
    const { isUploadPage } = this.state;
    const { onClass } = this.props;
    const selected = {
      borderBottom: '3px solid #4C5E6F', color: '#4C5E6F', fontSize: 20, opacity: 1, height: 60, paddingTop: 15, cursor: 'pointer',
    };
    const unselected = {
      fontSize: 20, color: '#c4c4c4', opacity: 1, height: 60, paddingTop: 15, cursor: 'pointer',
    };
    return (
      <div style={{
        padding: '0px 20px', backgroundColor: '#F5F8FA', width: '100%',
      }}
      >
        <div style={{
          marginBottom: 10, position: 'fixed', left: onClass ? '20%' : 0, right: 0, zIndex: 2, top: 0,
        }}
        >
          <div style={{
            backgroundColor: Constants().primaryBackgroundColor, height: 60, padding: '20px 35px', display: 'flex', justifyContent: 'space-between', alignItems: 'center', borderBottom: '1px solid #EAEDF5', fontSize: IS_MOBILE ? 14 : 20, whiteSpace: 'nowrap',
          }}
          >
            <div style={{ display: 'flex' }}>
              <div
                role="presentation"
                className="heading-1"
                style={isUploadPage ? selected : unselected}
                onClick={() => this.setState({ isUploadPage: true })}
              >
                Upload File
              </div>
              <div
                role="presentation"
                className="ml-5 heading-1"
                style={!isUploadPage ? selected : unselected}
                onClick={() => this.setState({ isUploadPage: false })}
              >
                Shared Files
              </div>
            </div>
          </div>
        </div>
        {isUploadPage && this.renderFileUploader()}
        {!isUploadPage && (
        <div>
          {this.renderTable()}
        </div>
        )}
      </div>
    );
  }

  renderMain() {
    const { showLoader } = this.state;
    let innerHeight = '';
    if (typeof window !== 'undefined') {
      innerHeight = window.innerHeight;
    }
    if (showLoader) {
      return <Loader mainHeight={510} />;
    }
    return (
      <div style={{ width: '100%', display: 'flex', height: '100%' }}>
        {this.renderContent()}
      </div>
    );
  }

  render() {
    return (
      <Modal showModal={this.props.showModal} handleCloseModal={this.props.handleCloseModal} height={IS_MOBILE ? '70%' : '520px'} width={IS_MOBILE ? '95%' : '880px'} style={{ backgroundColor: '#F5F8FA' }}>
        {this.renderMain()}
      </Modal>
    );
  }
}

VirtualMeetingFiles.propTypes = {
  instituteFiles: PropTypes.array,
  maxFileSize: PropTypes.number,
  onClass: PropTypes.bool,
  fileUploadedPercentage: PropTypes.string.isRequired,
};

VirtualMeetingFiles.defaultProps = {
  instituteFiles: [],
  maxFileSize: 1,
  onClass: false,
};

const mapStateToProps = ({ filesBoard, virtualMeeting }) => ({
  instituteFiles: filesBoard.instituteFiles,
  maxFileSize: filesBoard.maxFileSize,
  selectedCampus: virtualMeeting.selectedCampus,
  fileUploadedPercentage: filesBoard.fileUploadedPercentage,
});

export default connect(mapStateToProps, {
  getInstituteFiles,
  getSignedUrlAndUploadToS3,
  applyInstitueFilters,
  showToast,
  updateAttachmentDetails,
  hideFile,
  fileUploadPercentage,
})(VirtualMeetingFiles);
