import Dexie from 'dexie';

const db = new Dexie('AttendanceDB');
db.version(1).stores({
  campuses: '&name, *classrooms', // multi-entry index
  classrooms: '&id, name, *students',
  recentClassrooms: '&id, name',
  offlineAttendance: 'id, time, *student_ids, [id+time]',
});

export default db;
