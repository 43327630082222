import React, { Component } from 'react';
import { connect } from 'react-redux';
import Layout from '../layout';
import AccessDenied from '../accessDenied';
import { getTestLockOtp } from '../../actions';
import { isMobileDevice } from '../../helpers/util';

const IS_MOBILE = isMobileDevice();

class TestLockOtp extends Component {
  componentDidMount() {
    this.props.getTestLockOtp();
  }

  render() {
    const { testLockOtp, permissionedDenied } = this.props;
    if (permissionedDenied) {
      return (
        <AccessDenied />
      );
    }
    return (
      <Layout globalHistory={this.props.history}>
        <div style={{
          display: 'flex', justifyContent: 'center', alignItems: 'center', height: IS_MOBILE ? 500 : 600,
        }}
        >
          <div style={{
            display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', border: '1px solid #E4E7EE', padding: IS_MOBILE ? 50 : 70, borderRadius: 4,
          }}
          >
            <div style={{ color: '#11426C', fontSize: 24, fontWeight: 600 }}>OTP to unlock students test</div>
            <div style={{ fontSize: 20, padding: '20px 0px' }}>{testLockOtp || '-'}</div>
            <div
              role="presentation"
              className="primary_button"
              style={{
                fontSize: 18, padding: '5px 10px', width: 130, userSelect: 'none',
              }}
              onClick={() => this.props.getTestLockOtp()}
            >
              Refresh
            </div>
          </div>
        </div>
      </Layout>
    );
  }
}
const mapStateToProps = ({ institute }) => ({
  testLockOtp: institute.testLockOtp,
  permissionedDenied: institute.permissionedDenied,
});

export default connect(mapStateToProps, { getTestLockOtp })(TestLockOtp);
