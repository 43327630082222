import React, { Component } from 'react';
import { connect } from 'react-redux';
import Layout from '../layout';
import QuestionView from '../questionView';
import {
  getQuestionsToRetag, updateRetagIndex, updateQuestionMapping,
  updateHighlightTopic, updateRetagComplexity,
} from '../../actions';
import { mathjax } from '../../helpers/util';

const QUESTION_SWITCHING = {
  NEXT: 1,
  PREVIOUS: -1,
};

class RetagQuestions extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showOnlyQuestionText: true,
      id: 0,
    };
    this.questionTimer = {};
    this.timer = null;
  }

  componentDidMount() {
    const { match } = this.props;
    this.props.getQuestionsToRetag(match.params.id);
    mathjax();
    this.timer = setInterval(() => {
      const questionId = this.props.question.id;
      if (this.questionTimer[questionId]) {
        this.questionTimer[questionId] += 100;
      } else {
        this.questionTimer[questionId] = 100;
      }
    }, 100);
  }

  componentWillUnmount() {
    clearInterval(this.timer);
  }

  getTable() {
    const { isHighlight } = this.props;
    const { id } = this.state;
    const background = isHighlight ? '#dfffd7' : 'white';
    return (
      <table className="table table-bordered" style={{ background: '#fff' }}>
        <thead>
          <tr>
            <th>
              Topics
            </th>
          </tr>
        </thead>
        <tbody>
          {
              this.props.parentCheckpoints.map((parentCheckpoint) => {
                return (
                  <tr
                    key={parentCheckpoint.id}
                    onClick={() => {
                      this.setState({ id: parentCheckpoint.id });
                      this.props.updateHighlightTopic(true);
                      this.props.updateQuestionMapping(parentCheckpoint.id, this.props.question.id, this.questionTimer[this.props.question.id]).then(() => {
                        if (typeof window !== 'undefined') {
                          window.scrollTo(0, 0);
                        }
                        mathjax();
                      });
                    }}
                  >
                    <td style={{ padding: 20, background: id === parentCheckpoint.id ? background : 'white' }}>
                      { parentCheckpoint.name }
                    </td>
                  </tr>
                );
              })
            }
        </tbody>
      </table>
    );
  }

  switchQuestion(offset) {
    if (this.props.index + offset >= 0) {
      this.props.updateRetagIndex(offset).then(() => {
        mathjax();
        window.scrollTo(0, 0);
      });
    }
  }

  render() {
    const { showOnlyQuestionText } = this.state;
    const { question } = this.props;
    return (
      <Layout globalHistory={this.props.history}>
        <div style={{ position: 'relative', height: '100vh' }}>
          <div style={{ height: '92vh' }}>
            <div
              role="presentation"
              id="question-item"
              style={{ width: '100%', background: '#fff', borderTop: '1px solid #aaa' }}
              onClick={() => {
                this.setState({ showOnlyQuestionText: !showOnlyQuestionText }, () => {
                  mathjax();
                });
              }}
            >
              { question && <QuestionView question={question} showAnswer printPage={false} showOnlyQuestionText={showOnlyQuestionText} mobileView />}
            </div>
            <div
              className="row"
              style={{
                width: '100%', marginLeft: 2, position: 'fixed', background: '#fff', bottom: 60,
              }}
            >
              <div
                role="presentation"
                style={{
                  background: '#fff', padding: 10, float: 'left', width: '15%', fontSize: 30,
                }}
                onClick={() => {
                  this.switchQuestion(QUESTION_SWITCHING.PREVIOUS);
                }}
              >
                &lt;
              </div>
              <div style={{ width: '70%' }} />
              <div
                role="presentation"
                style={{
                  background: '#fff', padding: 10, float: 'right', width: '15%', fontSize: 30,
                }}
                onClick={() => {
                  this.switchQuestion(QUESTION_SWITCHING.NEXT);
                }}
              >
                &gt;
              </div>
            </div>
            <div style={{ width: '100%', paddingBottom: 200 }}>
              {this.getTable()}
            </div>
          </div>
          <div
            className="row"
            style={{
              width: '100%', height: '8vh', position: 'fixed', bottom: 0, marginLeft: '0px', display: 'table',
            }}
          >
            { ['JUNK', '1', '2', '3', '4', '5'].map((item, itemIndex) => {
              return (
                <div
                  role="presentation"
                  key={item}
                  onClick={() => {
                    this.props.updateRetagComplexity(this.props.index,
                      itemIndex, question.complexity,
                      this.questionTimer[question.id], this).then(() => {
                      mathjax();
                    });
                  }}
                  style={{
                    alignItem: 'center',
                    justifyContent: 'center',
                    color: `${question.complexity !== itemIndex ? '#fff' : 'rgb(71, 134, 255)'}`,
                    backgroundColor: `${question.complexity !== itemIndex ? 'rgb(71, 134, 255)' : '#fff'}`,
                    fontWeight: 'bolder',
                    fontSize: 'larger',
                    height: '60px',
                    border: '1px solid',
                    textAlign: 'center',
                    display: 'table-cell',
                    verticalAlign: 'middle',
                    width: item === 'JUNK' ? '' : '15%',
                  }}
                >
                  {item}
                </div>
              );
            })}
          </div>
        </div>
      </Layout>
    );
  }
}

const mapStateToProps = ({ retagQuestions }) => ({
  retagQuestions,
  question: retagQuestions.questions[retagQuestions.index],
  parentCheckpoints: retagQuestions.parentCheckpoints,
  index: retagQuestions.index,
  isHighlight: retagQuestions.isHighlight,
});

export default connect(mapStateToProps, {
  updateRetagComplexity,
  getQuestionsToRetag,
  updateRetagIndex,
  updateQuestionMapping,
  updateHighlightTopic,
})(RetagQuestions);
