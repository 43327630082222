import {
  GET_ASSIGNMENT_LIST, CREATE_NEW_ASSIGNMENT,
  UPDATE_TEST_SUMMARY, UPDATE_TEST_OTP, CREATE_NEW_CLASSROOM,
  UPDATE_TEST_PUBLISH_STATE, FILTER_CLASSROOM_LIST, TOGGLE_TEST_ARCHIVE_STATE,
} from '../actions';

import { UpdateStatus } from '../constants';

const initialState = {
  assignmentsList: [],
  classroomList: [],
  filteredClassroomList: [],
  testTaken: [],
  testsList: [],
  testGroupList: [],
  previousYearPaperList: [],
  created: false,
  message: '',
  hasAccess: false,
  subjects: [],
  assignmentQuestionLimit: '',
  uploadPermission: false,
  testOtp: {},
  createdClassroomId: 0,
  classroomSearchString: '',
  isTrial: true,
  remainingQuestionCount: 0,
  alertMessage: false,
  otpValidationType: 'none',
  isTestLockedByOtp: false,
  testOtpType: 'None',
  smsBalance: 0,
  showSentSms: false,
  hasMentorBoardAccess: false,
  status:''
};

export default function (state = initialState, action) {
  switch (action.type) {
    case GET_ASSIGNMENT_LIST:
      return { ...state, ...action.payload };
    case TOGGLE_TEST_ARCHIVE_STATE: {
      let position = null;
      const list = action.payload.isTest ? [...state.testsList] : [...state.assignmentsList];
      list.map((test, index) => {
        if (test.id === action.payload.testId) {
          position = index;
        }
      });
      const currentItem = list[position];
      list[position].archived = !currentItem.archived;
      if (action.payload.isTest) {
        return { ...state, testsList: list };
      }
      return { ...state, assignmentsList: list };
    }
    case CREATE_NEW_ASSIGNMENT:
      return { ...state, created: action.created, message: action.message };
    case CREATE_NEW_CLASSROOM:
      return { ...state, createdClassroomId: action.payload.createdClassroomId, classroomList: [...state.classroomList, action.payload.newClassroom] };
    case UPDATE_TEST_SUMMARY: {
      const newState = { ...state };
      const index = newState.testsList.map((obj) => { return obj.id; }).indexOf(parseInt(action.id, 10));
      newState.testsList[index].summary.status = action.status;
      if (action.status === UpdateStatus.COMPLETE) {
        const testTakenIndex = newState.testTaken.map((obj) => { return obj.id; }).indexOf(parseInt(action.id, 10));
        newState.testTaken[testTakenIndex].test_taken = true;
      }
      return newState;
    }
    case UPDATE_TEST_OTP: {
      return { ...state, testOtp: { ...state.testOtp, ...action.payload } };
    }
    case UPDATE_TEST_PUBLISH_STATE: {
      const { testsList, testTaken } = state;
      testsList.forEach((test, index) => {
        if (test.id === action.payload.testId) {
          testsList[index].is_published = action.payload.currentState;
        }
      });
      testsList.forEach((test, index) => {
        if (test.id === action.payload.testId) {
          testsList[index].test_taken = action.payload.testTaken
            || testsList[index].test_taken;
          testsList[index].test_taken_count = action.payload.testTakenCount
            || testsList[index].test_taken_count;
        }
      });
      return { ...state, testsList, testTaken };
    }
    case FILTER_CLASSROOM_LIST: {
      const filteredClassroomList = [];
      state.classroomList.forEach((classroom) => {
        if ((!action.name) || (classroom.label.toLowerCase().indexOf(action.name.toLowerCase()) > -1)) {
          filteredClassroomList.push(classroom);
        }
      });
      return { ...state, filteredClassroomList, classroomSearchString: action.name };
    }
    default: {
      return state;
    }
  }
}

