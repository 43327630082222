export const CAMPUS_SELECTED = 'campusSelected';
export const CLASSROOM_SELECTED = 'classroomSelected';
export const END_DATE_SELECTED = 'endDateSeletected';
export const START_DATE_SELECTED = 'startDateSeletected';

export const PRESENT_BORDER_COLOR = '#81DE79';
export const PRESENT_BACKGROUND_COLOR = '#EBFFEA';
export const ABSENT_BACKGROUND_COLOR = '#FFE0E0';
export const ABSENT_BORDER_COLOR = '#FF5F5F';
export const ALL_PRESENT_COLOR = '#56C900';
export const ALL_ABSENT_COLOR = '#FF7676';
