import Api from '../helpers/Api';

import { showToast } from '.';

export const GET_SORT_QUESTIONS = 'sortQuestions/GET_SORT_QUESTIONS';
export const PLACE_QUESTION = 'sortQuestions/PLACE_QUESTION';
export const GET_PICKED_QUESTIONS = 'sortQuestions/GET_PICKED_QUESTIONS';
export const GET_SORT_QUESTION_TOPICS = 'sortQuestions/GET_SORT_QUESTION_TOPICS';
export const UPDATE_SORT_QUESTION_COMPLEXITY = 'sortQuestions/UPDATE_SORT_QUESTION_COMPLEXITY';
export const UPDATE_SIMILARITY = 'sortQuestions/UPDATE_SIMILARITY';

export const getTopicsToSort = () => async (dispatch, getState) => {
  const response = await Api({
    method: 'get',
    url: '/question_tagging/get_topics_to_sort',
  });
  dispatch({
    type: GET_SORT_QUESTION_TOPICS,
    payload: response.success ? response.topics : [],
  });
  return Promise.resolve();
};

export const getSortQuestionData = (checkpointId) => {
  return (dispatch) => Api({
    method: 'get',
    url: `/question_tagging/${checkpointId}/get_sorted_questions`,
  }).then((response) => {
    if (response.success) {
      dispatch({
        type: GET_SORT_QUESTIONS,
        payload: {
          questions: response.questions,
          topic: response.topic,
          similarQuestionIds: response.similars,
        },
      });
    }
    return response;
  });
};

export const getQuestionToSort = (checkpointId, showOnlyOffline = false) => {
  return (dispatch) => Api({
    method: 'get',
    url: `/question_tagging/${checkpointId}/get_unsorted_question_to_sort?show_only_offline=${showOnlyOffline}`,
  }).then((response) => {
    if (response.success) {
      dispatch({
        type: GET_PICKED_QUESTIONS,
        payload: {
          pickedQuestions: response.picked_questions,
        },
      });
    }
  });
};

export const placeQuestionItem = (index, questionId, checkpointId, time_spent) => {
  return (dispatch) => Api({
    method: 'post',
    url: `/question_tagging/${checkpointId}/update_question_position`,
    data: {
      index,
      questionId,
      time_spent,
    },
  }).then((response) => {
    if (response && response.success) {
      dispatch({
        type: PLACE_QUESTION,
        payload: { index },
      });
    } else {
      dispatch(showToast('Failed to place question. try again!'));
    }
    return response;
  });
};

export const pickedQuestionBack = (questionId, index, checkpointId) => {
  return (dispatch) => Api({
    method: 'post',
    url: `/question_tagging/${checkpointId}/remove_question_position`,
    data: {
      questionId,
    },
  }).then((response) => {
    if (response.success) {
      dispatch(showToast('Remove Successful!'));
    } else {
      dispatch(showToast('Failed to remove! Please try again!'));
    }
    return response;
  });
};

export const reportSortIssue = (questionId, problemType, message) => {
  return (dispatch) => Api({
    method: 'post',
    url: '/report_issue/create',
    data: { question_id: questionId, problem_type: problemType, message },
  }).then((response) => {
    if (response && response.success) {
      dispatch(showToast('Thank you! we will look into it'));
    } else {
      dispatch(showToast('Failed to report error. Try later!'));
    }
    return response;
  });
};

export const updateComplexityInSort = (questionId, complexity) => {
  return (dispatch) => Api({
    method: 'post',
    url: `/question_tagging/${questionId}/update_question_complexity_in_sort`,
    data: { complexity },
  }).then((response) => {
    if (response.success) {
      dispatch({
        type: UPDATE_SORT_QUESTION_COMPLEXITY,
        payload: { complexity },
      });
      dispatch(showToast('Updated Successfully!'));
    } else {
      dispatch(showToast('Failed to update! Please try again!'));
    }
    return response;
  });
};

export const updateSimilarity = (checkpointId, parentId, questionId, groupingTime) => {
  return (dispatch) => Api({
    method: 'post',
    url: `/question_tagging/${parentId}/update_similarity`,
    data: { question_id: questionId, checkpoint_id: checkpointId, time: groupingTime },
  }).then((response) => {
    if (response.success) {
      dispatch({
        type: UPDATE_SIMILARITY,
        payload: { similarQuestionIds: response.similars },
      });
    } else {
      dispatch(showToast('Failed to group! Please try again!'));
    }
    return response;
  });
};

export const ungroupSimilarity = (parentId, questionId, newParent, checkpointId) => {
  return (dispatch) => Api({
    method: 'post',
    url: `/question_tagging/${parentId}/remove_similarity`,
    data: { question_id: questionId, new_parent: newParent, checkpoint_id: checkpointId },
  }).then((response) => {
    if (response.success) {
      dispatch({
        type: UPDATE_SIMILARITY,
        payload: { similarQuestionIds: response.similars },
      });
    } else {
      dispatch(showToast('Failed to ungroup! Please try again!'));
    }
    return response;
  });
};
