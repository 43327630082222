import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import MobileHeader from '../mobileHeader';
import {
  createNewAssignment, createNewClassroom, classroomSearchOrCreation,
  updateAssignment, DEFAULT_NUMBER_OF_SETS,
} from '../../actions';
import { sendEvent } from '../../helpers/Analytics';
import Constants from '../../shared/constants';

class TestAssignmentEditView extends Component {
  constructor(props) {
    super(props);
    const {
      name, showModal, testDate, selectedClassroomList, selectedTestGroup,
      selectedSubject, questionLimit, isOnline, hasMultipleTestSets, testDuration,
    } = this.props;
    const duration = parseInt(testDuration, 0);
    this.state = {
      showButtonLoader: false,
      newAssignmentName: name,
      selectedTestType: '',
      showCreateAssignmentModal: showModal,
      testDate: testDate || new Date().toISOString().slice(0, 10),
      selectedClassroom: selectedClassroomList || [],
      selectedTestGroup,
      selectedSubject: selectedSubject || [],
      questionLimit: questionLimit || {},
      isOnline: isOnline || false,
      setCount: hasMultipleTestSets,
      testDuration: { hours: parseInt(duration / 60, 0), minutes: parseInt(duration % 60, 0) },
      showCustomPlaceholder: false,
      showMultiSelectView: false,
      fields: '',
      type: '',
      page: 1,
    };
    this.toggleAssignmentModal = this.toggleAssignmentModal.bind(this);
    this.createAssignment = this.createAssignment.bind(this);
    this.createAssignmentData = this.createAssignmentData.bind(this);
    this.redirectToConfigPage = this.redirectToConfigPage.bind(this);
    this.updateFieldValueChanges = this.updateFieldValueChanges.bind(this);
  }

  getQuestionCount() {
    const {
      homePage, assignmentQuestionLimit, subjectMapping, isTestDataEditable,
    } = this.props;
    const { selectedSubject, questionLimit } = this.state;
    if (homePage) {
      const subjectIds = selectedSubject && selectedSubject.value ? selectedSubject.value : [];
      const width = Object.keys(subjectIds).length === 2 ? '48%' : '100%';
      if (Object.keys(subjectIds).length === 0) {
        return (
          <input
            type="number"
            className="mt-4"
            placeholder="Question Count"
            style={{ width, height: 50 }}
            value=""
          />
        );
      }
      return (
        <div style={{ display: 'flex', width: '100%', justifyContent: 'space-between' }}>
          {subjectIds.map((id) => (
            <input
              key={`subjectId_${id}`}
              type="number"
              className="mt-4"
              max={assignmentQuestionLimit}
              min="0"
              value={questionLimit[`${subjectMapping[id]}_1`]}
              onChange={(event) => this.updateFieldValueChanges('questionLimit', event.target.value, `${subjectMapping[id]}_1`)}
              placeholder={`${subjectMapping[id]} question count`}
              style={{ width, height: 50 }}
            />
          ))}
        </div>
      );
    }
    const ids = Object.keys(questionLimit);
    const width = ids.length === 1 ? '100%' : '48%';
    return (
      <div style={{ display: 'flex', width: '100%', justifyContent: 'space-between' }}>
        {ids.map((name) => (
          <input
            key={`subjectId_${name}`}
            type="number"
            className="mt-4"
            max={assignmentQuestionLimit}
            min="0"
            value={questionLimit[name]}
            onChange={(event) => this.updateFieldValueChanges('questionLimit', event.target.value, name)}
            placeholder={`${name.split('_')[0]} question count`}
            style={{
              width, height: 50, cursor: !isTestDataEditable && 'not-allowed', backgroundColor: !isTestDataEditable && '#ddd',
            }}
            readOnly={!isTestDataEditable}
          />
        ))}
      </div>
    );
  }

  createAssignmentData(data, payload, id) {
    const { history, isTestPage, homePage } = this.props;
    const { selectedSubject } = this.state;
    if (data.success) {
      if (homePage) {
        if (isTestPage) {
          sendEvent('/list/assignment', 'Assignment', 'TestCreated', payload);
          history.push(`/test_config/${data.id}`);
        } else {
          sendEvent('/list/assignment', 'Assignment', 'AssignmentCreated', payload);
          history.push(`/test_creation_overview/${data.id}`);
        }
      } else if (selectedSubject !== this.props.selectedSubject && !isTestPage) {
        history.push(`/test_chapter_selection/${id}?subject_id=${selectedSubject.value}&track=1`);
      } else {
        alert('Fields were updated');
        this.toggleAssignmentModal();
      }
    } else {
      const message = data.message ? data.message : data.data.message;
      alert(message);
      sendEvent('/list/assignment', 'Assignment', 'DuplicateAssignmentNameAlert', { payload });
      this.setState({ showButtonLoader: false });
    }
  }

  createAssignment() {
    this.setState({ showButtonLoader: true });
    const {
      newAssignmentName, selectedTestGroup, selectedClassroom, setCount,
      selectedSubject, testDate, questionLimit, isOnline, testDuration,
    } = this.state;
    const {
      isTestPage, assignmentQuestionLimit, isTrial, classroomList, homePage,
    } = this.props;
    let totalQuestionCount = 0;
    const newData = {};
    let hasNegativeValues = false;
    Object.keys(questionLimit).forEach((subject) => {
      const count = parseInt(questionLimit[subject], 10) || 0;
      totalQuestionCount += count;
      if (count < 0) {
        hasNegativeValues = true;
      } else if (count > 0) {
        newData[subject] = questionLimit[subject];
      }
    });
    const classroomId = [];
    const duration = parseInt(testDuration.hours * 60, 10) + parseInt(testDuration.minutes, 10);
    if (selectedClassroom) {
      selectedClassroom.forEach((e) => { classroomId.push(e.value); });
    }
    if (newAssignmentName === '') {
      alert('Name cannot be empty');
    } else if (!selectedTestGroup) {
      alert(isTestPage ? 'Test must have a test group' : 'Course should be selected');
    } else if (!isTestPage && selectedSubject.length === 0) {
      alert('Subject must be selected.');
    } else if (isTestPage && (duration <= 0 || !duration)) {
      alert('Test Duration cannot be 0 minutes.');
    } else if (!testDate) {
      alert('Date should be specified');
    } else if ((classroomId.length === 0) && !isTrial && classroomList.length) {
      alert('Classroom should be selected');
    } else if (!isTestPage && (hasNegativeValues || totalQuestionCount === 0
      || totalQuestionCount > assignmentQuestionLimit)) {
      let message = '';
      if (hasNegativeValues) {
        message = 'Question count cannot not be negative';
      } else if (totalQuestionCount === 0) {
        message = 'Question count has to be specified it can be altered later.';
      } else if (totalQuestionCount > assignmentQuestionLimit) {
        message = `Total Question count should be less than or equal to ${assignmentQuestionLimit}.`;
      }
      alert(message);
      this.setState({ showButtonLoader: false });
      return;
    }
    const { id } = this.props.match.params;
    const payload = {
      isOnline,
      name: newAssignmentName,
      testDate,
      testDuration: duration,
      classroomId: JSON.stringify(classroomId),
      testGroupId: selectedTestGroup ? selectedTestGroup.value : '',
      isAssignment: !isTestPage,
      setCount: setCount ? DEFAULT_NUMBER_OF_SETS : 1,
      questionLimit: JSON.stringify(newData),
    };
    if (!homePage) {
      payload.id = id;
    }
    sendEvent('/list/assignment', 'Assignment', 'CreateAssignmentSubmitClicked', payload);
    if (homePage) {
      this.props.createNewAssignment(payload).then((data) => this.createAssignmentData(data, payload, id));
    } else {
      this.props.updateAssignment(payload);
    }
  }

  updateFieldValueChanges(type, value, subType = '', clearData = false) {
    if (type === 'selectedClassroom' && !value) {
      this.setState({ [type]: [] });
      return;
    }
    if (subType) {
      if (clearData) {
        this.setState({ [subType]: [], [type]: value });
      } else {
        const stateType = this.state[type];
        stateType[subType] = value;
        this.setState({ [type]: stateType });
      }
    } else {
      this.setState({ [type]: value });
    }
  }

  redirectToConfigPage() {
    const query = new URLSearchParams(window.location.search);
    const id = this.props.match.params;
    this.setState({
      showCreateAssignmentModal: !this.state.showCreateAssignmentModal,
    });
    this.props.history.push(`/test_config/${id}`);
  }

  toggleAssignmentModal() {
    const { previousPage } = this.props;
    const query = new URLSearchParams(window.location.search);
    const { id } = this.props.match.params;
    sendEvent('/list/assignment', 'Assignment', !this.state.showCreateAssignmentModal ? 'CreateAssignmentModalOpened' : 'CreateAssignmentModalClosed');
    this.setState({
      showCreateAssignmentModal: !this.state.showCreateAssignmentModal,
    });
    if (previousPage) this.props.history.push(`/new_assignment/${id}?page_type=${previousPage}`);
  }

  updateTestDuration(selectedTestGroup) {
    if (selectedTestGroup) {
      this.setState({ testDuration: { hours: parseInt(selectedTestGroup.test_duration / 60), minutes: parseInt(selectedTestGroup.test_duration % 60) } });
    }
  }

  updatePage() {
    const {
      newAssignmentName, selectedTestGroup, selectedClassroom, testDate, selectedTestType,
    } = this.state;
    const { isTestPage, classroomList, isTrial } = this.props;
    const totalQuestionCount = 0;
    const classroomId = [];
    let message = '';
    selectedClassroom && selectedClassroom.forEach((e) => { classroomId.push(e.value); });
    if (newAssignmentName === '') {
      message = 'Name cannot be empty';
    } else if (isTestPage ? !selectedTestType : !selectedTestGroup) {
      message = isTestPage ? 'Test must have a test type' : 'Course should be selected';
    } else if (!testDate) {
      message = 'Date should be specified';
    } else if ((classroomId.length === 0) && !isTrial && classroomList.length) {
      message = 'Classroom should be selected';
    }
    if (message) {
      alert(message);
      return;
    }
    this.setState({ page: 2 });
  }

  renderPage2() {
    const {
      testGroupList, isTestPage, homePage, hasAccess, subjectList,
      isReviewed, isTestDataEditable,
    } = this.props;
    const {
      selectedTestType, testDuration, setCount, isOnline,
    } = this.state;
    let subjects = [];
    const testGroups = [];
    if (homePage) {
      if (!isTestPage) {
        subjects = subjectList[this.state.selectedTestGroup.value];
      } else {
        testGroupList.forEach((testGroup) => {
          if (testGroup.is_test && selectedTestType.value === testGroup.test_type_id) {
            testGroups.push(testGroup);
          }
        });
      }
    }
    return (
      <div>
        {isTestPage && (
          <div>
            Test Group:
            <div style={{ display: 'flex', justifyContent: 'space-between', flexDirection: 'column' }}>
              {this.renderInputBox(testGroups, 'selectedTestGroup', true)}
            </div>
          </div>
        )}
        {isTestPage
          && (
          <div style={{ marginTop: 10 }}>
            Test Duration:
            <div style={{ display: 'flex', marginTop: 5 }}>
              <div>
                <input
                  type="number"
                  value={testDuration.hours}
                  style={{ width: 60, height: 50, backgroundColor: !isTestDataEditable && '#ddd' }}
                  onChange={(event) => this.updateFieldValueChanges('testDuration', (((parseInt(event.target.value, 10) < 10 && parseInt(event.target.value, 10) >= 0) || !event.target.value) ? event.target.value : testDuration.hours), 'hours')}
                  readOnly={!isTestDataEditable}
                />
                hours
              </div>
              <div style={{ paddingLeft: 30 }}>
                <input
                  type="number"
                  value={testDuration.minutes}
                  style={{ width: 60, height: 50, backgroundColor: !isTestDataEditable && '#ddd' }}
                  onChange={(event) => this.updateFieldValueChanges('testDuration', (((parseInt(event.target.value, 10) < 60 && parseInt(event.target.value, 10) >= 0) || !event.target.value) ? event.target.value : testDuration.minutes), 'minutes')}
                  readOnly={!isTestDataEditable}
                />
                min
              </div>
            </div>
          </div>
          )}
        {(subjects && !isTestPage && !isReviewed && homePage)
          && (
          <div style={{ display: 'flex', justifyContent: 'space-between', flexFlow: 'row wrap' }}>
            {this.renderInputBox(subjects, 'selectedSubject')}
          </div>
          )}
        {(!isTestPage && !isReviewed)
          && <div>{this.getQuestionCount()}</div>}
        {(!isTestPage)
          && (
          <div className="mt-3 d-flex flex-row">
            <input type="checkbox" checked={setCount} onChange={() => this.setState({ setCount: !setCount })} style={{ fontSize: 'x-large', marginTop: 10, cursor: 'pointer' }} />
            <label className="ml-3" style={{ fontSize: 16, fontWeight: 'bold', margin: 5 }}> Multiple Sets </label>
          </div>
          )}
        {hasAccess
          && (
          <div className="mt-3 d-flex flex-row">
            <input type="checkbox" checked={isOnline} onChange={() => this.setState({ isOnline: !isOnline })} style={{ fontSize: 'x-large', marginTop: 10, cursor: 'pointer' }} />
            <label className="ml-3" style={{ fontSize: 16, fontWeight: 'bold', margin: 5 }}> Online Test </label>
          </div>
          )}
        {(isTestPage && this.props.selectedTestGroup)
          && <button type="button" className="btn btn-link" onClick={this.redirectToConfigPage}>Config</button>}
      </div>
    );
  }

  renderMultiSelectList(field) {
    const { selectedKey } = this.state;
    let selectedValues = this.state[selectedKey];
    const selectedIndex = selectedValues.indexOf(field);
    const isSelected = selectedIndex > -1;
    return (
      <div
        role="presentation"
        key={`FieldKey${field.value}`}
        onClick={() => {
          if (isSelected) {
            selectedValues.splice(selectedIndex, 1);
          } else {
            selectedValues = [...selectedValues, field];
          }
          this.setState({ [selectedKey]: selectedValues });
        }}
        style={{
          display: 'flex', alignItems: 'center', borderBottom: '1px solid #ddd', padding: 20,
        }}
      >
        <input type="checkbox" value={field.value} style={{ margin: 0 }} checked={isSelected} />
        <div style={{ marginLeft: 10, fontSize: 16 }}>
          {field.label}
        </div>
      </div>
    );
  }

  renderPage1() {
    const {
      classroomList, testGroupList, testTypeList, isTestPage, homePage,
      subjectList, isReviewed, isTestDataEditable,
    } = this.props;
    const {
      newAssignmentName, testDate, selectedClassroom, selectedTestGroup,
    } = this.state;
    let subjects = [];
    const testGroups = [];
    if (homePage) {
      if (!isTestPage) {
        subjects = subjectList[selectedTestGroup.value];
      }
      testGroupList.forEach((testGroup) => {
        if (!isTestPage && !testGroup.is_test) {
          testGroups.push(testGroup);
        }
      });
    }
    const selectedClassrooms = [];
    selectedClassroom.forEach((classroom) => selectedClassrooms.push(classroom.label));
    return (
      <div>
        <b> Name </b>
        <input
          autoFocus
          id="AssignmentName"
          onChange={(event) => this.updateFieldValueChanges('newAssignmentName', event.target.value.replace(/[&\/\\#,+^@$~%.'":*?<>{}!]/g, ''))}
          type="text"
          placeholder={isTestPage ? 'Test Name' : 'Assignment Name'}
          value={newAssignmentName}
          style={{
            width: '100%', height: 50, marginBottom: 15, cursor: !isTestDataEditable && 'not-allowed', backgroundColor: !isTestDataEditable && '#ddd',
          }}
          readOnly={!isTestDataEditable}
        />
        <b> Date </b>
        <input
          id="TestDate"
          onChange={(event) => this.updateFieldValueChanges('testDate', event.target.value)}
          type="date"
          value={testDate}
          style={{
            width: '100%', height: 50, marginBottom: 15, paddingLeft: 10, cursor: !isTestDataEditable && 'not-allowed', backgroundColor: !isTestDataEditable && '#ddd',
          }}
          readOnly={!isTestDataEditable}
        />
        {classroomList.length > 0
       && (
       <>
         <b> Classroom </b>
         <input
           id="ClassroomList"
           type="text"
           value={(selectedClassrooms.length > 0 ? (`${selectedClassrooms[0]} ${selectedClassrooms.length > 1 ? `and ${selectedClassrooms.length - 1} other` : ''}`) : '')}
           onClick={() => {
             this.props.classroomSearchOrCreation('');
             this.setState({ showMultiSelectView: true, type: 'Classroom', selectedKey: 'selectedClassroom' });
           }}
           style={{
             width: '100%', height: 50, marginBottom: 15, paddingLeft: 10, cursor: !isTestDataEditable && 'not-allowed', backgroundColor: !isTestDataEditable && '#ddd',
           }}
           readOnly={!isTestDataEditable}
         />
       </>
       )}
        {!this.props.selectedTestGroup && !isReviewed && homePage && (
          <div>
            Course:
            <div style={{ display: 'flex', justifyContent: 'space-between', flexFlow: 'row wrap' }}>
              {isTestPage && this.renderInputBox(testTypeList, 'selectedTestType')}
              {!isTestPage && this.renderInputBox(testGroups, 'selectedTestGroup')}
            </div>
          </div>
        )}
      </div>
    );
  }

  renderInputBox(fields, selectedKey, shouldUpdateTestDuration = false) {
    const availableWidth = window.innerWidth - 40;
    const width = fields.length < 4 ? ((availableWidth / (fields.length)) - 10) : 'fit-content';
    return fields.map((field) => {
      const selectedValue = this.state[selectedKey];
      const isSelected = selectedValue && (selectedValue.value === field.value);
      return (
        <div
          role="presentation"
          onClick={() => {
            if (shouldUpdateTestDuration) {
              this.updateTestDuration(isSelected ? '' : field);
            }
            this.setState({ [selectedKey]: isSelected ? {} : field });
          }}
          style={{
            height: 50, display: 'flex', width, justifyContent: 'center', alignItems: 'center', border: '1px solid #ddd', borderRadius: 4, color: isSelected && 'white', backgroundColor: isSelected && Constants().color.primary, marginTop: 10, padding: '0px 10px 0px 10px', minWidth: 'fit-content',
          }}
        >
          {field.label}
        </div>
      );
    });
  }

  renderMultiSelectView() {
    const { type } = this.state;
    const { classroomList, classroomSearchString } = this.props;
    let hasExactMatch = classroomSearchString.length < 3;
    classroomList.forEach((classroom) => {
      if (classroom.label.toLowerCase() === classroomSearchString.toLowerCase()) {
        hasExactMatch = true;
      }
    });
    return (
      <div>
        <MobileHeader
          name={type}
          showCloseButton
          onImageClick={() => this.setState({ showMultiSelectView: false })}
        />
        <div style={{
          display: 'flex', flexDirection: 'column', marginBottom: 50, marginTop: 60,
        }}
        >
          {classroomList.map((field) => this.renderMultiSelectList(field))}
        </div>
        <div
          role="presentation"
          onClick={() => this.setState({ showMultiSelectView: false })}
          style={{
            backgroundColor: Constants().color.primary, color: 'white', fontSize: 16, width: '100%', height: 50, textAlign: 'center', padding: 12, display: 'flex', position: 'fixed', bottom: 0, justifyContent: 'center', fontWeight: 'bold',
          }}
        >
          Done
        </div>
      </div>
    );
  }

  render() {
    const { page, showMultiSelectView, showButtonLoader } = this.state;
    if (showMultiSelectView) {
      return this.renderMultiSelectView();
    }
    const { isTestPage, homePage } = this.props;
    let modalText = '';
    if (homePage) {
      modalText = (isTestPage) ? 'New Test' : 'New Assignment';
    } else {
      modalText = (isTestPage) ? 'Edit Test' : 'Edit Assignment';
    }
    return (
      <div>
        <MobileHeader
          name={modalText}
          showCloseButton
          onImageClick={() => {
            this.props.closeCreateView();
            this.setState({ page: 1 });
          }}
        />
        <div style={{ marginTop: 60, padding: 20, marginBottom: 50 }}>
          {page === 1 && this.renderPage1()}
          {page === 2 && this.renderPage2()}
        </div>
        <div
          role="presentation"
          onClick={() => !showButtonLoader && ((page === 1) ? this.updatePage() : this.createAssignment())}
          style={{
            backgroundColor: Constants().color.primary, color: 'white', fontSize: 16, width: '100%', height: 50, padding: 12, display: 'flex', position: 'fixed', bottom: 0, justifyContent: 'center', alignItems: 'center', fontWeight: 'bold',
          }}
        >
          {page === 1 ? 'Next' : showButtonLoader ? <i className="fa fa-spinner fa-spin" /> : 'Create'}
        </div>
      </div>
    );
  }
}

TestAssignmentEditView.propTypes = {
  previousPage: PropTypes.number,
};

TestAssignmentEditView.defaultProps = {
  previousPage: null,
};

export default connect(null, {
  classroomSearchOrCreation,
  createNewClassroom,
  createNewAssignment,
  updateAssignment,
})(TestAssignmentEditView);
