import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Select from 'react-select';
import Layout from '../layout';
import Constants from '../../shared/constants';
import UploadFile from '../uploadFile';
import SharedFile from '../sharedFile';
import Loader from '../loading';
import {
  getInstituteFiles, applyInstitueFilters,
} from '../../actions';
import '../../styles/instituteOnBoarding.css';

class FilesBoard extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      showLoader: true,
      type: 'UPLOAD',
    };
    this.renderContent = this.renderContent.bind(this);
    this.domainSearch = this.domainSearch.bind(this);
  }

  componentDidMount() {
    this.props.getInstituteFiles().then(() => {
      this.props.applyInstitueFilters({ selectedClassroom: [] });
      this.setState({ showLoader: false });
    });
  }

  domainSearch(filterItems, selectedItem, selectedFunction, isMulti) {
    const { type } = this.state;
    if (!filterItems || filterItems.length === 0) {
      return true;
    }
    let items = [];
    filterItems.map((item) => {
      items = [...items, { value: item.id, label: item.name }];
    });
    const customHeight = type === 'UPLOAD' ? {} : { height: 40 };
    const customStyles = {
      control: (base) => ({
        ...base,
        backgroundColor: 'white',
        width: type === 'UPLOAD' ? '100%' : 400,
        ...customHeight,
        border: '1px solid #343737 !important',
      }),
      indicatorsContainer: (base) => ({
        ...base,
      }),
      valueContainer: (base) => ({
        ...base,
        height: 'inherit',
        alignContent: 'center',
      }),
    };
    items = [...items, { id: null, value: null, label: 'All' }];
    return (
      <div>
        <Select
          isMulti={isMulti}
          isClearable
          styles={customStyles}
          value={selectedItem}
          onChange={selectedFunction}
          options={items}
          placeholder="Select classroom"
        />
      </div>
    );
  }

  renderContent() {
    const { showLoader, type } = this.state;
    const { onClass } = this.props;
    const innerHeight = typeof window !== 'undefined' && window.innerHeight;

    if (showLoader) {
      return <div style={{ width: '100%' }}><Loader /></div>;
    }
    const selected = {
      borderBottom: `3px solid ${Constants().color.primary}`, color: Constants().color.primary, fontSize: 20, opacity: 1, height: 60, paddingTop: 15, cursor: 'pointer',
    };
    const unselected = {
      fontSize: 20, color: '#c4c4c4', opacity: 1, height: 60, paddingTop: 15, cursor: 'pointer',
    };
    return (
      <div style={{
        padding: '100px 85px 50px', backgroundColor: showLoader ? '#FFFFFF' : '#F5F8FA', width: '100%', height: '100%', minHeight: innerHeight,
      }}
      >
        <div style={{
          marginBottom: 10, position: 'fixed', left: onClass ? '20%' : 0, right: 0, zIndex: 2, top: 0,
        }}
        >
          <div style={{
            backgroundColor: Constants().primaryBackgroundColor, height: 60, padding: '20px 85px', display: 'flex', justifyContent: 'space-between', alignItems: 'center', borderBottom: '1px solid #EAEDF5',
          }}
          >
            <div style={{ display: 'flex' }}>
              <div
                role="presentation"
                className="heading-1"
                style={type === 'UPLOAD' ? selected : unselected}
                onClick={() => {
                  this.setState({ type: 'UPLOAD' });
                  this.props.applyInstitueFilters({ selectedClassroom: [] });
                }}
              >
                Upload File
              </div>
              <div
                role="presentation"
                className="ml-5 heading-1"
                style={type === 'SHARED' ? selected : unselected}
                onClick={() => {
                  this.setState({ type: 'SHARED' });
                  this.props.applyInstitueFilters({ selectedClassroom: [] });
                }}
              >
                Shared Files
              </div>
            </div>
          </div>
        </div>
        {type === 'UPLOAD' && <UploadFile showLoader={showLoader} domainSearch={this.domainSearch} />}
        {type === 'SHARED' && <SharedFile showLoader={showLoader} domainSearch={this.domainSearch} />}
      </div>
    );
  }

  render() {
    let innerHeight = '';
    if (typeof window !== 'undefined') {
      innerHeight = window.innerHeight;
    }
    return (
      <Layout globalHistory={this.props.history} hideHeader title="Files Board">
        <div style={{
          width: '100%', display: 'flex', height: '100', fontFamily: 'Roboto',
        }}
        >
          {this.renderContent()}
        </div>
      </Layout>
    );
  }
}

FilesBoard.propTypes = {
  onClass: PropTypes.bool,
};

FilesBoard.defaultProps = {
  onClass: false,
};

export default connect(null, {
  getInstituteFiles,
  applyInstitueFilters,
})(FilesBoard);
