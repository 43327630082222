import {
  GET_SORT_QUESTIONS, PLACE_QUESTION, GET_SORT_QUESTION_TOPICS,
  GET_PICKED_QUESTIONS, UPDATE_SORT_QUESTION_COMPLEXITY, UPDATE_SIMILARITY,
} from '../actions';

const initialState = {
  questions: [],
  topics: [],
  pickedQuestions: [],
  pickedQuestion: '',
  unSortedQuestionCount: 0,
  index: 0,
  topic: '',
  similarQuestionIds: {},
};

export default function (state = initialState, action) {
  switch (action.type) {
    case GET_SORT_QUESTIONS: {
      return {
        ...state,
        ...action.payload,
      };
    }
    case GET_PICKED_QUESTIONS: {
      return {
        ...state,
        pickedQuestions: action.payload.pickedQuestions,
        pickedQuestion: action.payload.pickedQuestions ? action.payload.pickedQuestions[0] : '',
        unSortedQuestionCount: action.payload.pickedQuestions ? action.payload.pickedQuestions.length : 0,
      };
    }
    case GET_SORT_QUESTION_TOPICS: {
      return {
        topics: action.payload,
      };
    }
    case PLACE_QUESTION: {
      const { questions } = state;
      const { pickedQuestions } = state;
      questions.splice(action.payload.index, 0, pickedQuestions[0]);
      pickedQuestions.splice(0, 1);
      return {
        ...state,
        questions,
        pickedQuestions,
        pickedQuestion: action.payload.pickedQuestions ? action.payload.pickedQuestions[0] : '',
        unSortedQuestionCount: action.payload.pickedQuestions ? action.payload.pickedQuestions.length : 0,
      };
    }
    case UPDATE_SORT_QUESTION_COMPLEXITY: {
      const { pickedQuestions } = state;
      pickedQuestions[0].complexity_2 = action.payload.complexity;
      return {
        ...state,
        pickedQuestions,
        pickedQuestion: action.payload.pickedQuestions ? action.payload.pickedQuestions[0] : '',
        unSortedQuestionCount: action.payload.pickedQuestions ? action.payload.pickedQuestions.length : 0,
      };
    }
    case UPDATE_SIMILARITY: {
      return {
        ...state,
        similarQuestionIds: action.payload.similarQuestionIds,
      };
    }
    default:
      return state;
  }
}
