import axios from 'axios';
import Constants from '../shared/constants';

export const STUDENT_OVERVIEW = '@studentReport/STUDENT_OVERVIEW';

export function getStudentTestReport(id) {
  return (dispatch) => axios({
    method: 'GET',
    url: `${Constants().api.url}/dashboard/get_student_ranks_for_parent_dashboard?id=${id}`,
  }).then((response) => {
    if (response.data.success === true) {
      const payload = {
        subjects: response.data.subjects,
        testReport: response.data.test_report,
      };
      dispatch({
        type: STUDENT_OVERVIEW,
        payload,
      });
    }
    return response.data;
  });
}
