import Api, { getToken } from '../helpers/Api';
import { mathjax } from '../helpers/util';
import Constants from '../shared/constants';
import { UpdateStatus, REPORT_ISSUE_TOAST_MESSAGES } from '../constants';
import { UPDATE_TEST_SUMMARY, updateQuestionPaperComplexity, showToast } from '.';

export const FETCH_ASSIGNMENT_DATA = '@assignment/FETCH_ASSIGNMENT_DATA';
export const UPDATE_ASSIGNMENT_DATA = '@assignment/UPDATE_ASSIGNMENT_DATA';
export const UPDATE_SEARCH_LIST = '@assignment/UPDATE_SEARCH_LIST';
export const UPDATE_ASSIGNMENT_QUESTIONS = '@assignment/UPDATE_ASSIGNMENT_QUESTIONS';
export const UPDATE_ASSIGNMENT = '@assignment/UPDATE_ASSIGNMENT';
export const RESET_RENDER_MORE_QUESTION_STATUS = '@assignment/RESET_RENDER_MORE_QUESTION_STATUS';
export const UPDATE_QUESTION_FETCH = '@assignment/UPDATE_QUESTION_FETCH';
export const UPDATE_PRINT_QUESTIONS = '@assignment/UPDATE_PRINT_QUESTIONS';
export const UPDATE_TEST_DETAILS_ASSIGNMENT = '@assignment/UPDATE_TEST_DETAILS_ASSIGNMENT';
export const UPDATE_LANDING_PAGE_DATA = '@assignment/UPDATE_LANDING_PAGE_DATA';
export const RESET_FILTERS = '@assignment/RESET_FILTERS';
export const GET_ANSWER_KEY = '@assignment/GET_ANSWER_KEY';
export const GET_REPORT_ISSUE_QUESTIONS = '@assignment/GET_REPORT_ISSUE_QUESTIONS';
export const STUDENT_RESULT_UPLOAD_STATUS = '@assignment/STUDENT_RESULT_UPLOAD_STATUS';
export const UPDATE_REVIEW_STATUS = '@assignment/UPDATE_REVIEW_STATUS';
export const UPDATE_ASSIGNMENT_QUESTIONS_IDS = '@assignment/UPDATE_ASSIGNMENT_QUESTIONS_IDS';
export const UPDATE_NUMERICAL_QUESTION_LIST = '@assignment/UPDATE_NUMERICAL_QUESTION_LIST';
export const UPDATE_MODAL = '@assignment/UPDATE_MODAL';
export const PERMISSION_DENIED_ASSIGNMENT = '@assignment/PERMISSION_DENIED_ASSIGNMENT';
export const SET_SHOW_TEST = '@assignment/SET_SHOW_TEST';
export const DEFAULT_NUMBER_OF_SETS = 4;

export function resetRenderMoreQuestions() {
  return (dispatch) => new Promise((resolve) => {
    dispatch({ type: RESET_RENDER_MORE_QUESTION_STATUS });
    resolve();
  });
}

function resetFilters() {
  return (dispatch) => new Promise((resolve) => {
    dispatch({ type: RESET_FILTERS });
    resolve();
  });
}

export function updateNumericalQuestions(testId, questionId) {
  return (dispatch) => {
    dispatch({ type: UPDATE_NUMERICAL_QUESTION_LIST, payload: { questionId } });
    return Api({
      method: 'post',
      url: '/membership_tests/add_or_remove_numerical_question_list_for_test',
      data: { id: testId, question_id: questionId },
    }).then((response) => {
      if (!response.success) {
        dispatch({ type: UPDATE_NUMERICAL_QUESTION_LIST, payload: { questionId } });
      }
      return response;
    });
  };
}

export function updateInstitutePaperPreference(payload) {
  return () => Api({
    method: 'post',
    url: '/membership_tests/update_institute_paper_preference',
    data: payload,
  });
}

export function getPrintQuestions(id, subjectId = '', track = '', paperSet = 1) {
  return (dispatch) => Api({
    method: 'get',
    url: `/membership_tests/get_questions_for_print/${id}?test_set_id=${paperSet}&subject_id=${subjectId}&track=${track}`,
  }).then((response) => {
    if (response.success) {
      const payload = {
        testInfo: response.test_info,
        questions: response.questions,
        numericalQuestionIds: response.numerical_question_ids,
        testSets: response.test_sets,
        testsClassrooms: response.test_classrooms,
        selectedTestSet: response.selected_test_set,
        selectedTestGroup: response.selected_test_group,
        instituteLogo: response.institute_logo,
        instituteWatermark: response.institute_watermark,
        instituteTestHeaderImage: response.institute_test_header_image,
        instituteName: response.institute_name,
        subjectGroupMapping: response.subject_group_mapping,
        hasAccess: response.has_permission,
        questionDistribution: response.question_distribution,
        paperPreference: response.paper_preference,
        testMetaData: response.test_meta_data,
        isTrial: response.is_trial,
      };
      dispatch({
        type: UPDATE_PRINT_QUESTIONS,
        payload,
      });
    }
    return response;
  });
}

export function updateTestReviewForAssignment(params) {
  return (dispatch) => Api({
    method: 'post',
    url: '/membership_tests/update_test_review_state',
    data: params,
  }).then((response) => {
    if (response.success) {
      dispatch({
        type: UPDATE_REVIEW_STATUS,
        isReviewed: response.is_reviewed,
      });
    }
    return response;
  });
}

export function getAssignmentQuestions(params, type) {
  const paramsData = params;
  paramsData.subjectId = parseInt(params.subjectId, 10) || '';
  if (type === 'updateQuestions') {
    resetFilters();
  }
  return (dispatch) => Api({
    method: 'post',
    url: '/membership_tests/get_question_for_assignment',
    data: paramsData,
  }).then((response) => {
    if (response.success) {
      let payload = {};
      if (type === 'updateQuestions') {
        payload = {
          questions: response.questions,
          testInfo: response.test_info,
          hasMentorBoardAccess: response.has_mentor_board_access,
          numericalQuestionIds: response.numerical_question_ids,
          assignmentQuestionIds: response.membership_question_ids,
          questionDistribution: response.question_distribution,
          renderMoreQuestions: response.render_more_questions,
          totalQuestionsToBeSelected: response.total_questions_to_be_selected,
          testInsight: {
            similarQuestionsCount: response.similar_questions,
            repeatedQuestions: response.repeated_questions,
          },
          isTrial: response.is_trial,
          studentsCount: response.students_count,
        };
      } else {
        payload = {
          testInfo: response.test_info,
          isUserSuggestionAllowed: response.is_user_suggestion_allowed,
          questions: response.questions,
          numericalQuestionIds: response.numerical_question_ids,
          numericalQuestionCount: response.numerical_question_count,
          totalSelectedQuestionCount: response.total_selected_question_count,
          showTest: response.show_test,
          comprehensionData: response.comprehension_data || [],
          assignmentQuestionIds: response.membership_question_ids,
          isTestDataEditable: response.is_test_data_editable,
          totalQuestionCount: response.total_questions,
          questionDistribution: response.question_distribution,
          chapters: response.chapters,
          checkpoints: response.checkpoints,
          subCheckpoints: response.sub_checkpoints,
          progressiveSearchSelectedValues: response.selected,
          renderMoreQuestions: response.render_more_questions,
          classroomList: response.classroom_list,
          subjectQuestionLimit: response.subject_question_limit,
          selectedQuestionCount: response.selected_questions_count,
          selectedClassroomList: response.selected_classroom_list,
          selectedTestGroup: response.selected_test_group,
          sectionWiseQuestions: response.section_wise_questions,
          testGroupList: response.test_groups,
          testTypeList: response.test_types,
          hasMultipleTestSets: response.test_sets === DEFAULT_NUMBER_OF_SETS,
          hasAccess: response.has_permission,
          testDetails: response.test_details,
          reporterMode: response.reporter_mode,
          appliedFilters: response.applied_filters,
          showProofread: response.proofread_access,
          showOverview: response.show_overview,
          totalQuestionsToBeSelected: response.total_questions_to_be_selected,
          assignmentQuestionLimit: response.assignment_question_limit,
          testInsight: {
            similarQuestionsCount: response.similar_questions,
            repeatedQuestions: response.repeated_questions,
          },
          isFeedbackfilled: response.showFeedbackModal,
          isTrial: response.is_trial,
          studentsCount: response.students_count,
        };
      }
      dispatch({
        type: type === 'updateQuestions' ? UPDATE_ASSIGNMENT_DATA : FETCH_ASSIGNMENT_DATA,
        payload,
      });
      dispatch(updateQuestionPaperComplexity(response));
      mathjax();
    } else {
      dispatch({
        type: PERMISSION_DENIED_ASSIGNMENT,
        status: true,
      });
    }
    return response;
  });
}

export function getSearchList(searchString) {
  return (dispatch) => Api({
    method: 'get',
    url: `/membership_tests/search_chapter_checkpoint?search_string=${searchString}`,
  }).then((response) => {
    if (response.success) {
      dispatch({
        type: UPDATE_SEARCH_LIST,
        chapters_list: response.chapters,
        checkpoints_list: response.checkpoints,
      });
    }
    return response.search_list;
  });
}

export function updateAssignmentQuestions(id, questionId, track, questionType, section) {
  return (dispatch) => Api({
    method: 'post',
    url: '/membership_tests/add_or_remove_questions',
    data: {
      id, track, question_id: questionId, question_type: questionType, section,
    },
  }).then((response) => {
    if (response.success) {
      dispatch({
        type: UPDATE_ASSIGNMENT_QUESTIONS,
        payload: response,
      });
      dispatch(updateQuestionPaperComplexity(response));
    } else {
      alert(response.message);
    }
    return response;
  });
}

export function sendErrorReported(id, questionId, problemType, message) {
  return () => Api({
    method: 'post',
    url: '/report_issue/create',
    data: {
      test_id: id, question_id: questionId, problem_type: problemType, message,
    },
  }).then((response) => {
    return response;
  });
}

export function updateAssignment(payload) {
  return (dispatch) => Api({
    method: 'post',
    url: '/membership_tests/update_assignment_name',
    data: payload,
  }).then((response) => {
    if (response.success) {
      dispatch({
        type: UPDATE_ASSIGNMENT,
        testInfo: response.test_info,
        assignmentQuestionLimit: response.assignment_question_limit,
        selectedClassroomList: response.selected_classroom_list,
        selectedTestGroup: response.selected_test_group,
      });
    } else {
      dispatch({
        type: PERMISSION_DENIED_ASSIGNMENT,
        status: true,
      });
    }
    return response;
  });
}

export function sendUserFeedback(message, subject) {
  const isTokenPresent = getToken();
  return () => Api({
    method: 'post',
    url: isTokenPresent ? '/community_user_feedbacks' : '/community_user_feedbacks/submit_anonymous_user_feedback',
    data: { message, subject },
  }).then((response) => {
    if (response.success) {
      alert('Feedback Submitted');
    }
    return response;
  });
}

export function newLeadData(phone, product, name, email) {
  const { ideaboard, dashboard, mobile } = Constants().products;
  let data = {};
  if (product === dashboard || product === ideaboard) {
    data = {
      phone, product, name, email,
    };
  } else if (product === mobile) {
    data = { phone, product, name };
  } else {
    data = { phone, product };
  }
  return () => Api({
    method: 'post',
    url: '/leads',
    data,
  });
}

export function updateLoader(questionId, status) {
  return {
    type: UPDATE_QUESTION_FETCH,
    payload: {
      questionId,
      status,
    },
  };
}

export function updateAssignmentQuestionsIds(assignmentQuestionIds) {
  return (dispatch) => dispatch({
    type: UPDATE_ASSIGNMENT_QUESTIONS_IDS,
    payload: assignmentQuestionIds,
  });
}

export function uploadQuestionImage(file) {
  return () => Api({
    method: 'post',
    url: '/new_question_requests',
    attachment: file,
  });
}

export function reportedError(file, questionId, suggestedAnswer) {
  return () => Api({
    method: 'post',
    url: '/question_issues',
    attachment: file,
    data: { question_id: questionId, suggested_answer: suggestedAnswer },
  });
}

export function landingPageData({ numberOfQuestions, numberOfAnswers, numberOfTests }) {
  return (dispatch) => Api({
    method: 'get',
    url: '/home/get_landing_page_data',
  }).then((response) => {
    if (response.success) {
      dispatch({
        type: UPDATE_LANDING_PAGE_DATA,
        numberOfQuestions: {
          oldValue: numberOfQuestions.newValue,
          newValue: response.question_count,
        },
        numberOfTests: { oldValue: numberOfTests.newValue, newValue: response.test_count },
        numberOfAnswers: { oldValue: numberOfAnswers.newValue, newValue: response.response_count },
      });
    }
    return response;
  });
}

export function uploadStudentResults(file, testId) {
  return (dispatch) => Api({
    method: 'post',
    url: '/tests/upload_student_result_file',
    attachment: file,
    type: 'spreadsheet',
    data: { test_id: testId },
  }).then((response) => {
    if (response.success) {
      dispatch({
        type: UPDATE_TEST_SUMMARY,
        id: testId,
        status: UpdateStatus.PROCESS,
      });
      dispatch({
        type: UPDATE_MODAL,
        payload: false,
      });
    }
  });
}

export function uploadQuestions(file, testId) {
  return () => Api({
    method: 'post',
    url: '/content_engine/upload_questions',
    attachment: file,
    type: 'spreadsheet',
    data: { test_id: testId },
  }).then((response) => {
    return response;
  });
}

export function updateAssignmentModal(bool) {
  return (dispatch) => new Promise((resolve) => {
    dispatch({ type: UPDATE_MODAL, payload: bool });
    resolve();
  });
}

export function deleteStudentResults(testId) {
  return (dispatch) => Api({
    method: 'post',
    url: '/tests/delete_offline_test_data',
    data: { id: testId },
  }).then((response) => {
    if (response.success) {
      dispatch({
        type: STUDENT_RESULT_UPLOAD_STATUS,
        status: response.status,
      });
    }
  });
}

export function getUploadedStudentResultStatus(testId) {
  return (dispatch) => Api({
    method: 'get',
    url: `/tests/upload_student_result_status?test_id=${testId}`,
  }).then((response) => {
    if (response.success) {
      dispatch({
        type: STUDENT_RESULT_UPLOAD_STATUS,
        errors: response.errors,
        status: response.status,
      });
    }
  });
}

export const sendExperienceFeedback = (rating, feedbackMessage,
  features, referenceData) => async (dispatch) => {
  return Api({
    method: 'post',
    url: '/feedbacks/add_user_product_feedback',
    data: {
      rating, features, feedback_message: feedbackMessage, reference_data: referenceData,
    },
  }).then((response) => {
    if (!response.success) {
      dispatch(showToast('Network error, please try again later..!'));
    }
    return response;
  });
};

export function getQuestionsForReportIssues(id) {
  return (dispatch) => Api({
    method: 'get',
    url: `/membership_tests/${id}/get_questions_for_report_issue`,
  }).then((response) => {
    let payload = {};
    if (response.success) {
      payload = {
        reported_questions: response.questions,
        isUserSuggestionAllowed: response.is_user_suggestion_allowed,
        redirectReportPage: false,
      };
    } else {
      payload = {
        redirectReportPage: true,
      };
    }
    dispatch({
      type: GET_REPORT_ISSUE_QUESTIONS,
      payload,
    });
    return response;
  });
}

export function sendErrorReportedToUpdateQuestion(testId, questionId,
  problemType, textChanges, imageFile) {
  return () => Api({
    method: 'post',
    url: '/report_issue/create_question_update_approvals',
    attachment: imageFile.length > 1 ? imageFile : imageFile[0],
    type: 'reported_image_file',
    data: {
      test_id: testId,
      question_id: questionId,
      problem_type: problemType,
      text_changes: textChanges,
    },
  }).then((response) => {
    return response;
  });
}

export function updateInstituteQuestion(problemType, textChanges, imageFile,
  questionId = 0, testId) {
  return (dispatch) => Api({
    method: 'post',
    url: '/content_engine/update_institute_questions',
    attachment: imageFile.length > 1 ? imageFile : imageFile[0],
    type: 'reported_image_file',
    data: {
      test_id: testId,
      question_id: questionId,
      problem_type: problemType,
      text_changes: textChanges,
    },
  }).then((response) => {
    if (response.success) {
      dispatch(showToast(REPORT_ISSUE_TOAST_MESSAGES.UPDATED));
    } else {
      dispatch(showToast(REPORT_ISSUE_TOAST_MESSAGES.NOT_UPDATED));
    }
    return response;
  });
}

export function updateShowTest(id, testDate = '', showTestSyllabus = '', showStartTime = true) {
  return (dispatch) => Api({
    method: 'post',
    url: `/tests/${id}/update_show_test`,
    data: { test_date: testDate, show_syllabus: showTestSyllabus, show_start_time: showStartTime },
  }).then((response) => {
    if (response.success) {
      const payload = {
        showTest: true,
      };
      dispatch({
        type: SET_SHOW_TEST,
        payload,
      });
    }
    return response;
  });
}
