import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import {
  updateQuizQuestion, removeQuizQuestion, showToast,
} from '../../actions';
import reset from '../../static/images/reset.svg';
import SnackBar from '../snackBar';
import QuizQuestionView from './quizQuestionView';
import '../../static/tools.css';

class NewQuestion extends PureComponent {
  constructor(props) {
    super(props);
    const { question } = props;
    this.state = {
      textChanges: {
        id: question.id,
        question_text: question.question_text,
        option_1: question.option_1,
        option_2: question.option_2,
        option_3: question.option_3,
        option_4: question.option_4,
        answer: question.answer,
        show: question.show,
      },
      imageChanges: {
        image_option_1: question.image_option_1,
        image_option_2: question.image_option_2,
        image_option_3: question.image_option_3,
        image_option_4: question.image_option_4,
        supporting_picture: question.supporting_picture,
      },
      removedImages: {},
      isNewImage: {
        image_option_0: false,
        image_option_1: false,
        image_option_2: false,
        image_option_3: false,
        image_option_4: false,
        supporting_picture: false,
      },
      processing: false,
    };
    this.handleChange = this.handleChange.bind(this);
    this.updateShowQuestion = this.updateShowQuestion.bind(this);
    this.updateQuestion = this.updateQuestion.bind(this);
    this.removeImage = this.removeImage.bind(this);
    this.handleChangeOnImages = this.handleChangeOnImages.bind(this);
  }

  handleChange(type, value) {
    const { textChanges } = this.state;
    textChanges[type] = value;
    this.setState({ textChanges: { ...textChanges } });
  }

  handleChangeOnImages(type, value) {
    const { imageChanges, isNewImage, removedImages } = this.state;
    imageChanges[type] = value;
    removedImages[type] = false;
    isNewImage[type] = true;
    this.setState({
      imageChanges: { ...imageChanges },
      isNewImage: { ...isNewImage },
      removedImages: { ...removedImages },
    });
  }

  updateQuestion() {
    const { textChanges, imageChanges, removedImages } = this.state;
    const imageFiles = [];
    const { id, question } = this.props;
    if (this.isDataPresent(textChanges, imageChanges)) {
      Object.keys(imageChanges).map((file) => {
        if ((imageChanges[file]
          && imageChanges[file].url !== (question[file]
            && question[file].url)) || (!question[file] && imageChanges[file])) {
          imageFiles.push(new File([imageChanges[file]], `${file}.${imageChanges[file].type && imageChanges[file].type.split('/')[1]}`,
            { type: imageChanges[file].type }));
        }
        return imageChanges;
      });
      this.handleCloseModal();
      delete textChanges.show;
      this.props.showToast('Processing Update....');
      this.setState({ processing: true });
      this.props.updateQuizQuestion(id, textChanges, imageFiles, removedImages).then(() => {
        this.props.showToast('Quiz Question Updated Successfully');
        this.setState({ processing: false });
        this.props.resetEdit();
      });
    }
  }

  isDataPresent(textChanges, imageChanges) {
    let optionArray = Array.from(new Set([textChanges.option_1 || imageChanges.image_option_1,
      textChanges.option_2 || imageChanges.image_option_2,
      textChanges.option_3 || imageChanges.image_option_3,
      textChanges.option_4 || imageChanges.image_option_4]));
    optionArray = optionArray.filter((e) => e);
    if (!textChanges.question_text) {
      this.props.showToast('Question Text Should Be Present');
      return false;
    }
    if (optionArray.length !== 4) {
      this.props.showToast('All four options should be present and it should be unique');
      return false;
    }
    if (!textChanges.answer) {
      this.props.showToast('Atleast one option should be marked as correct');
      return false;
    }
    return true;
  }

  removeQuizQuestion(id) {
    this.props.removeQuizQuestion(id);
  }

  updateShowQuestion() {
    const { textChanges } = this.state;
    const { id } = this.props;
    this.props.updateShowStatus([textChanges.id], textChanges.show, id);
  }

  refresh() {
    const { question } = this.props;
    const textChanges = {
      id: question.id,
      question_text: question.question_text,
      option_1: question.option_1,
      option_2: question.option_2,
      option_3: question.option_3,
      option_4: question.option_4,
      answer: question.answer,
      show: question.show,
    };
    const imageChanges = {
      image_option_1: question.image_option_1,
      image_option_2: question.image_option_2,
      image_option_3: question.image_option_3,
      image_option_4: question.image_option_4,
      supporting_picture: question.supporting_picture,
    };
    const isNewImage = {
      image_option_0: false,
      image_option_1: false,
      image_option_2: false,
      image_option_3: false,
      image_option_4: false,
    };
    this.setState({
      textChanges: { ...textChanges },
      imageChanges: { ...imageChanges },
      isNewImage: { ...isNewImage },
    });
  }

  removeImage(option) {
    const { imageChanges, isNewImage, removedImages } = this.state;
    const type = option > 0 ? `image_option_${option}` : 'supporting_picture';
    imageChanges[type] = null;
    removedImages[type] = true;
    isNewImage[type] = false;
    this.setState({
      imageChanges: { ...imageChanges },
      isNewImage: { ...isNewImage },
      removedImages: { ...removedImages },
    });
  }

  handleCloseModal() {
    const { isNewQuestion } = this.props;
    this.setState({ showQuestionModal: false });
    isNewQuestion && this.props.removeNewQuestion();
  }

  renderQuizQuestion(quizQuestion, index) {
    const {
      textChanges, imageChanges, isNewImage, processing,
    } = this.state;
    const {
      isNewQuestion, removeNewQuestion, shownQuizIds, isEditable,
    } = this.props;
    const shown = shownQuizIds.includes(quizQuestion.id) || textChanges.show;
    return (
      <div
        style={{
          display: 'flex',
          justifyContent: 'flex-end',
          flexDirection: 'column',
          marginBottom: 20,
          borderLeft: isEditable ? '10px solid #3994AE' : '1px solid #454545',
          borderRadius: isEditable && 10,
          boxShadow: '0px 3px 15px rgba(103, 58, 183, 0.1)',
        }}
      >
        <div style={{
          display: 'flex', justifyContent: 'space-between', fontFamily: 'Nunito', border: '1px solid #454545', borderBottom: 0, padding: '5px 20px', alignItems: 'center', borderLeft: 0,
        }}
        >
          {!isNewQuestion && (
          <a
            style={{
              width: 'fit-content', textDecoration: 'none', userSelect: 'none', fontWeight: 600, color: '#454545',
            }}
            target="_blank"
          >
            Question:&nbsp;
            {index}
          </a>
          )}
          <div style={{
            display: 'flex', justifyContent: 'space-around', alignItems: 'center', marginLeft: 'auto',
          }}
          >
            {isEditable && !isNewQuestion && (
            <img
              onClick={() => this.refresh()}
              role="presentation"
              src={reset}
              alt="refresh"
              style={{
                marginRight: 20, height: 30, width: 30, padding: 5, borderRadius: '50%', cursor: 'pointer',
              }}
            />
            )}
            {isEditable && (
              processing
                ? (
                  <div className="Button Primary-Button" style={{ padding: '5px 10px', border: 'none', marginRight: 20 }}>
                    Updating &nbsp;
                    <i style={{ fontSize: 18 }} className="fa fa-spinner fa-spin" />
                  </div>
                )
                : (
                  <div
                    className="Button Primary-Button"
                    onClick={() => this.updateQuestion()}
                    role="presentation"
                    style={{
                      marginRight: 20, padding: '5px 10px', cursor: 'pointer', height: 30, border: 'none',
                    }}
                  >
                    SAVE
                  </div>
                )
            )}
            {!isNewQuestion && (
            <div
              role="presentation"
              style={{
                opacity: shown && 0.5,
                cursor: shown ? 'not-allowed' : 'pointer',
                marginRight: 20,
              }}
              onClick={() => { shown ? this.props.showToast('Cant Hide The Question Once It Is Shown') : this.updateShowQuestion(); }}
            >
              <i className={`fa fa-eye${shown ? '-slash' : ''}`} aria-hidden="true" />
            </div>
            )}
            <div
              role="presentation"
              className="New-Button-Hover"
              onClick={isNewQuestion ? () => this.props.removeNewQuestion()
                : () => this.removeQuizQuestion(quizQuestion.id)}
              style={{
                padding: 10, borderRadius: 20, cursor: 'pointer', marginRight: 20,
              }}
            >
              <img alt="Delete Question" src="https://external-content.duckduckgo.com/iu/?u=https%3A%2F%2Fmaxcdn.icons8.com%2FShare%2Ficon%2FEditing%2Fdelete1600.png&f=1&nofb=1" width={20} height={20} />
            </div>
          </div>
        </div>
        <div
          role="presentation"
          style={{ border: '1px solid #343737', padding: 20, borderLeft: 0 }}
          onClick={() => this.props.edit()}
        >
          <QuizQuestionView
            quizQuestion={{ ...quizQuestion }}
            textChanges={{ ...textChanges }}
            imageChanges={{ ...imageChanges }}
            isNewImage={{ ...isNewImage }}
            refresh={this.refresh}
            removeImage={this.removeImage}
            handleChangeOnImages={this.handleChangeOnImages}
            handleChange={this.handleChange}
            removeNewQuestion={removeNewQuestion}
            isEditable={isEditable}
            isNewQuestion={isNewQuestion}
          />
        </div>
      </div>
    );
  }

  render() {
    const { question, index } = this.props;
    return (
      <div style={{ fontFamily: 'Nunito' }}>
        <style>
          {`
            a:hover, a:visited, a:active {
              color: #454545 !important;
            }  
            input[type=checkbox]:after {
              background-color: #3994AE;
            }
        `}
        </style>
        <SnackBar />
        {this.renderQuizQuestion(question, index)}
      </div>
    );
  }
}

NewQuestion.propTypes = {
  question: PropTypes.object,
  index: PropTypes.number,
  removeNewQuestion: PropTypes.bool,
  isEditable: PropTypes.bool,
  isNewQuestion: PropTypes.bool,
  shownQuizIds: PropTypes.array,
};

NewQuestion.defaultProps = {
  question: {},
  removeNewQuestion: false,
  isEditable: false,
  index: 0,
  shownQuizIds: [],
  isNewQuestion: false,
};

export default connect(null, {
  updateQuizQuestion,
  removeQuizQuestion,
  showToast,
})(NewQuestion);
