import React, { Component } from 'react';
import { connect } from 'react-redux';
import Select from 'react-select';
import Pagination from 'react-js-pagination';
import { PDFExport } from '@progress/kendo-react-pdf';
import { ExcelExport, ExcelExportColumn } from '@progress/kendo-react-excel-export';
import uploadCloud from '../../static/images/uploadCloud.svg';
import info from '../../static/images/info.svg';
import downloadCloud from '../../static/images/downloadCloud.svg';
import Layout from '../layout';
import Constants from '../../shared/constants';
import Loader from '../loading';
import uploadCloudSmall from '../../static/images/uploadCloudSmall.svg';
import Menubar from '../menubar';
import AccessDenied from '../accessDenied';
import EditDetailsModal from '../modal/editDetailsModal';
import FileUploaderModal from '../modal/fileUploaderProgress';
import OnBoardingInstructionModal from '../modal/onboardingInstruction';
import '../../styles/instituteOnBoarding.css';
import {
  uploadStudentDetails, createNewStudent, uploadVerifiedClassroomsAndStudents,
  updateCurrentStudentData, updateStudentDetails, getMembershipDetails,
  updateStudentStatus, updateClassroomsAndStudents, sendLoginCredentials
} from '../../actions';
import search from '../../static/images/search.svg';
import StyleConstants from '../../styles/styles.json';

import { FILE_TYPE } from '../../constants';

class StudentDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      importType: 'insert',
      showModal: false,
      studentDetailsFile: null,
      showLoader: true,
      courseCode: '',
      campusId: '',
      courseId: '',
      classroomId: '',
      searchString: '',
      student: null,
      studentIndex: null,
      verified: true,
      activePage: 1,
      itemsCountPerPage: 10,
      uploadSuccess: false,
      fileUploadProgressStatus: true,
      showOverViewList: false,
      downloadType: FILE_TYPE.EXCEL,
      showInstitutePreference: false,
      selectedStudents: [],
      showMoreActions: false,
    };
    this.uploadStudentDetails = this.uploadStudentDetails.bind(this);
    this.renderVerification = this.renderVerification.bind(this);
    this.renderCampusDetails = this.renderCampusDetails.bind(this);
    this.renderOverviewList = this.renderOverviewList.bind(this);
    this.userSearch = this.userSearch.bind(this);
    this.updateSearch = this.updateSearch.bind(this);
    this.updateCurrentStudentDetail = this.updateCurrentStudentDetail.bind(this);
    this.newStudentDetails = this.newStudentDetails.bind(this);
    this.studentDetailsSave = React.createRef();
  }

  componentDidMount() {
    this.props.getMembershipDetails({ itemsCountPerPage: this.state.itemsCountPerPage }).then(() => this.setState({ showLoader: false }));
  }

  getStudentDetailsFile() {
    document.getElementById('uploadStudentDetailsFile').click();
  }

  setDownloadType(downloadType) {
    this.setState({ downloadType }, () => {
      const {
        courseId, campusId, classroomId, departmentId,
      } = this.state;
      const payload = {
        departmentId, courseId, campusId, classroomId, isDownload: true,
      };
      const query = new URLSearchParams(window.location.search);

      this.props.getMembershipDetails(payload, query.get('institute_id')).then(() => {
        this.studentDetailsSave.current.save();
      });
    });
  }

  exportFile(downloadType) {
    if (downloadType === FILE_TYPE.PDF) {
      this.setDownloadType(downloadType);
    } else {
      this.setDownloadType(downloadType);
    }
  }

  uploadStudentDetails(event) {
    const query = new URLSearchParams(window.location.search);
    const instituteId = query.get('institute_id');
    this.setState({
      studentDetailsFile: event.target.files[0],
      showModal: true,
      fileUploadProgressStatus: true,
    }, () => {
      const file = this.state.studentDetailsFile ? this.state.studentDetailsFile
        : event.target.files[0];
      this.props.uploadStudentDetails(file, this.state.importType, instituteId).then((data) => {
        if (data.success) {
          this.setState({
            verified: false,
          });
        }
      });
    });
  }

  userSearch(e) {
    const { courseCode, campusId, classroomId } = this.state;
    const searchString = e.target.value;
    this.setState({ searchString, activePage: 1 });
    const payload = {
      searchString, courseCode, campusId, classroomId,
    };
    const query = new URLSearchParams(window.location.search);
    this.props.getMembershipDetails(payload, query.get('institute_id'));
  }

  updateCurrentStudentDetail(student, studentName, badgeId, phone, studentEmail,
    classroomId, classroomName, studentCampus, courseId, dob, fatherName, fatherPhone, fatherEmail, motherName, motherPhone, motherEmail, address, bloodGroup, doj, active, studentSelectedElectives, instutesTagIds, isHostelite) {
    const {
      activePage, studentIndex,
    } = this.state;
    this.props.updateStudentDetails(student, studentName, badgeId, phone, studentEmail, classroomId,
      courseId, active, fatherName, fatherPhone, dob, fatherEmail, motherName, motherPhone, motherEmail, address, bloodGroup, doj, studentSelectedElectives, activePage, instutesTagIds, isHostelite);
    this.props.updateCurrentStudentData(studentIndex, studentName, badgeId,
      phone, studentEmail, classroomId, classroomName, studentCampus, active, fatherName, fatherPhone, dob, fatherEmail, motherName, motherPhone, motherEmail, address, bloodGroup, doj, instutesTagIds, isHostelite);
  }


  newStudentDetails(studentName, studentId, studentMobile, studentEmail, classroomId,
    classroomName, studentCampus, courseId, currentStatus, dob, fatherName, fatherPhone, fatherEmail, motherName, motherPhone, motherEmail, address, bloodGroup, doj, studentSelectedElectives, instutesTagIds, isHostelite) {
    const { activePage } = this.state;
    this.props.createNewStudent(studentName, studentId, studentMobile, studentEmail, classroomId,
      classroomName, studentCampus, courseId, currentStatus, fatherName, fatherPhone, dob,
      fatherEmail,
      motherName,
      motherPhone,
      motherEmail,
      address,
      bloodGroup,
      doj, studentSelectedElectives,
      activePage, instutesTagIds, isHostelite);
  }

  updateSearch(selectedItem, payloadType, type) {
    const {
      course, campus, classroom, searchString, department,
    } = this.state;
    const payload = {
      searchString,
      courseId: course ? course.value : '',
      campusId: campus ? campus.value : '',
      classroomId: classroom ? classroom.value : '',
      departmentId: department ? department.value : '',
    };
    payload[payloadType] = selectedItem ? selectedItem.value : '';
    this.setState({ [type]: selectedItem, [payloadType]: selectedItem ? selectedItem.value : '' });
    const query = new URLSearchParams(window.location.search);
    this.props.getMembershipDetails(payload, query.get('institute_id')).then(() => this.setState({ [type]: selectedItem }));
  }

  domainSearch(filterItems, selectedItem, selectedFunction, item) {
    if (!filterItems) {
      return true;
    }
    const customStyles = {
      control: (base, state) => ({
        ...base,
        height: 35,
        width: 200,
        backgroundColor: 'white',
        border: '1px solid #CDCFD6 !important',
      }),
      indicatorsContainer: (base, state) => ({
        ...base,
        height: 35,
      }),
      valueContainer: (base, state) => ({
        ...base,
        height: 40,
      }),
      option: (base, state) => ({
        ...base,
        textAlign: 'left',
      }),
    };
    const items = [...filterItems, { id: null, value: '', label: 'All' }];
    return (
      <div style={{ textAlign: 'center', marginLeft: '25px' }}>
        <div style={{ alignItems: 'center', marginLeft: '10px' }}>
          <Select
            styles={customStyles}
            value={selectedItem && selectedItem.value ? selectedItem : { label: item, value: '' }}
            defaultValue={{ label: item, value: 0 }}
            onChange={selectedFunction}
            options={items}
            placeholder="All"
            isClearable={selectedItem && selectedItem.value}
          />
        </div>
      </div>
    );
  }

  handleSelectedStudents(studentId, isAllstudentsSelected = false) {
    let { selectedStudents } = this.state;
    const { studentsMembershipIds } = this.props;
    if (studentId === 'All') {
      if (selectedStudents.length && isAllstudentsSelected) {
        selectedStudents = selectedStudents.filter((selectedStudentId) => studentsMembershipIds.indexOf(selectedStudentId) < 0);
      } else {
        selectedStudents = [...selectedStudents, ...studentsMembershipIds];
      }
    } else if (selectedStudents.indexOf(studentId) > -1) {
      selectedStudents = selectedStudents.filter((selectedStudentId) => selectedStudentId !== studentId);
    } else {
      selectedStudents = [...selectedStudents, studentId];
    }
    this.setState({ selectedStudents });
  }

  renderStudentList(student, ct) {
    const { isHigherEducationInstitute, isAdmin } = this.props;
    const cellStyle = { textAlign: 'left', verticalAlign: 'middle' };
    return (
      <tr style={{ fontSize: '14px', height: 50 }}>
        <td style={{ textAlign: 'center', verticalAlign: 'middle' }}>
          <input
            type="checkbox"
            checked={this.state.selectedStudents.indexOf(student.id) > -1}
            onChange={() => this.handleSelectedStudents(student.id)}
          />
        </td>
        <td className="clickable" style={cellStyle} onClick={() => isAdmin && this.setState({ showModal: true, student, studentIndex: ct })}>{student.student_id}</td>
        <td style={cellStyle}>{student.first_name}</td>
        <td style={cellStyle}>{student.course_name}</td>
        <td style={cellStyle}>{student.classroom_name}</td>
        {!isHigherEducationInstitute && <td style={cellStyle}>{student.campus_name}</td>}
        {isHigherEducationInstitute && <td style={cellStyle}>{student.department_name}</td>}
        {!isHigherEducationInstitute && <td style={{ ...cellStyle, color: !student.phone ? StyleConstants.textColor.inactive : StyleConstants.textColor.mediumDark }}>{student.phone || 'N/A'}</td>}
        <td style={{ ...cellStyle, color: !student.otp ? StyleConstants.textColor.inactive : StyleConstants.textColor.mediumDark }}>{student.otp || 'N/A'}</td>
        <td style={{ ...cellStyle, color: student.student_tags && student.student_tags[0].label === null ? StyleConstants.textColor.inactive : StyleConstants.textColor.mediumDark }}>{student.student_tags && student.student_tags.map((tag) => {return tag.label === null ? "-" : tag.label}).join(", ")}</td>
      </tr>
    );
  }

  renderMemberList(classRoom, student, ct) {
    return (
      <tr style={{ fontSize: '14px', height: 50 }}>
        <td style={{ textAlign: 'center', borderRight: '1px solid #CDCFD6', verticalAlign: 'middle' }}>{classRoom}</td>
        <td style={{ textAlign: 'center', verticalAlign: 'middle' }}>{student}</td>
      </tr>
    );
  }

  renderCampusDetails(campusName, status) {
    const { classroomsAndStudents } = this.props;
    const classroomCount = Object.keys(classroomsAndStudents[campusName]).length;
    const studentsCount = Object.values(classroomsAndStudents[campusName]).reduce(((a, b) => a + b), 0);
    let temp = 0;
    return (
      <div>
        <div className="heading-1" style={{ color: '#11426C', fontSize: 28, margin: '10px 0px' }}>{campusName}</div>
        <div className="campus-count">
          No of Classrooms
          {status}
          :
          {classroomCount}
        </div>
        <div className="campus-count">
          No of Students
          {status}
          :
          {studentsCount}
        </div>
        <div className="table-responsive mt-5">
          <table className="table table-hover" style={{ border: '1px solid #CDCFD6' }}>
            <thead style={{ background: '#F6F8FA', color: '#23394C' }}>
              <tr style={{ fontSize: '16px', fontWeight: 700, height: 50 }}>
                <th style={{ borderRight: '1px solid #DFE3EA' }}>
                  Classrooms
                  {status}
                </th>
                <th>
                  No of Students
                  {status}
                </th>
              </tr>
            </thead>
            <tbody>
              {Object.keys(classroomsAndStudents[campusName]).map((classRoom) => (this.renderMemberList(classRoom, classroomsAndStudents[campusName][classRoom], temp++)))}
            </tbody>
          </table>
          <style jsx>
            {// language=CSS
                    `table, th, td {
                        text-align: center;
                    }
                    td {
                        vertical-align: middle;
                    }
                    `
}
          </style>
        </div>
      </div>
    );
  }

  renderImport() {
    return (
      <div style={{
        width: '100%', backgroundColor: '#FFFFFF', marginTop: 20, display: 'flex',
      }}
      >
        <div style={{
          width: '50%', padding: '5%', display: 'flex', justifyContent: 'center',
        }}
        >
          <div style={{
            border: '1px solid #E4E7EE', height: '100%', width: '90%', borderRadius: 4, padding: '0% 5%',
          }}
          >
            <div style={{ display: 'flex', justifyContent: 'center', marginTop: 35 }}>
              <img src={downloadCloud} width="85px" height="65px" />
            </div>
            <div className="content-text" style={{ textAlign: 'center', marginTop: 35 }}>
              To create Campuses, Classrooms and Students list you can download our sample Excel file.
            </div>
            <div style={{
              textAlign: 'center', marginTop: 15, color: '#40A7FF', marginBottom: 30, fontWeight: 'bold',
            }}
            >
              <a href="/static/assets/Sample_Spreadsheet.xlsx" download>Download sample spreadsheet</a>
            </div>
          </div>
        </div>
        <div style={{
          width: '50%', padding: '5%', display: 'flex', justifyContent: 'center',
        }}
        >
          <div style={{
            border: '1px solid #E4E7EE', height: '100%', width: '90%', borderRadius: 4, padding: '0% 5%',
          }}
          >
            <div style={{ display: 'flex', justifyContent: 'center', marginTop: 35 }}>
              <img src={uploadCloud} width="85px" height="65px" />
            </div>
            <div className="content-text" style={{ textAlign: 'center', marginTop: 35 }}>
              Drag or browse the Excel file to import.
            </div>
            <div style={{
              textAlign: 'center', marginTop: 5, display: 'flex', alignItems: 'center', justifyContent: 'center',
            }}
            >
              <img src={info} width="20px" height="20px" />
              <div style={{ marginLeft: 10 }}>Supported .xls .xlsb .xlsm .xlsx</div>
            </div>
            <div style={{ display: 'flex', justifyContent: 'center', marginTop: 15 }}>
              <div style={{ height: '0px', width: '0px', overflow: 'hidden' }}>
                <input id="uploadStudentDetailsFile" type="file" name="file" accept=".xlsx" onChange={this.uploadStudentDetails} />
              </div>
              <div
                style={{
                  height: 35, width: 280, borderRadius: 4, display: 'flex', justifyContent: 'center', alignItems: 'center', backgroundColor: '#40A7FF', color: '#FFFFFF', cursor: 'pointer',
                }}
                onClick={this.getStudentDetailsFile}
              >
                <img src={uploadCloudSmall} width="20px" height="20px" />
                <div style={{ marginLeft: 10 }}>Start an Import</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  handlePageChange(pageNumber) {
    this.setState({ activePage: pageNumber }, () => {
      const {
        courseCode, campusId, classroomId, searchString, activePage, itemsCountPerPage,
      } = this.state;
      const payload = {
        searchString, courseCode, campusId, classroomId, activePage, itemsCountPerPage,
      };
      const query = new URLSearchParams(window.location.search);
      this.props.getMembershipDetails(payload, query.get('institute_id')).then(() => this.setState({ showLoader: false }));
      if (typeof window !== 'undefined') {
        window.scrollTo(0, 0);
      }
    });
  }

  renderImportButton(importType) {
    const { isHigherEducationInstitute } = this.props;
    return (
      <div
        role="presentation"
        style={{
          height: 35, width: 100, marginLeft: (importType !== 'Insert') ? 30 : 0, display: 'flex', justifyContent: 'center', alignItems: 'center', border: '1px solid #40A7FF', color: '#40A7FF', borderRadius: 4, cursor: 'pointer',
        }}
        onClick={() => {
          (importType === 'Update') ? (this.setState({ importType: 'update' }, () => this.getStudentDetailsFile()))
            : ((importType === 'Insert')
              ? (isHigherEducationInstitute
                ? this.getStudentDetailsFile()
                : this.setState({ importType: 'insert', showInstitutePreference: true })
              )
              : this.exportFile(FILE_TYPE.EXCEL));
        }}
      >
        {importType}
      </div>
    );
  }

  renderOptions(type, onSelect) {
    return (
      <div role="presentation" className="custom-secondary-button" style={{ height: 35, width: 140, marginLeft: 30 }} onClick={onSelect}>
        {type}
      </div>
    );
  }

  modifyStudentAccess(selectedStudents, action) {
    this.props.updateStudentStatus(selectedStudents, action, this.state.searchString).then(() => this.setState({ selectedStudents: [] }));
  }

  renderSearchFilters() {
    const {
      campuses, classrooms, departments, isHigherEducationInstitute, courses,
    } = this.props;
    const {
      campus, course, department,
    } = this.state;
    let filteredClassrooms = isHigherEducationInstitute ? [] : classrooms;
    let filteredCourses = isHigherEducationInstitute ? [] : courses;
    if (department && department.value) {
      filteredCourses = courses.filter((courses) => courses.department_id == department.value);
    }
    if (campus && campus.value) {
      filteredClassrooms = classrooms.filter((classRoom) => classRoom.campus_id == campus.value);
    }
    if (course && course.value) {
      filteredClassrooms = classrooms.filter((classRoom) => classRoom.course_id == course.value);
    }
    if (isHigherEducationInstitute) {
      return (
        <>
          {this.domainSearch(departments, this.state.department, (selectedOption) => { this.updateSearch(selectedOption, 'departmentId', 'department'); this.setState({ course: '', classroom: '', departmentId: selectedOption ? selectedOption.value : '' }); }, 'Departments')}
          {this.domainSearch(filteredCourses, this.state.course, (selectedOption) => { this.updateSearch(selectedOption, 'courseId', 'course'); this.setState({ classroom: '', courseId: selectedOption ? selectedOption.value : '' }); }, 'Semester')}
          {this.domainSearch(filteredClassrooms, this.state.classroom, (selectedOption) => { this.updateSearch(selectedOption, 'classroomId', 'classroom'); this.setState({ classroomId: selectedOption ? selectedOption.value : '' }); }, 'Classroom')}
        </>
      );
    }
    return (
      <>
        {campuses.length > 1 && this.domainSearch(campuses, this.state.campus, (selectedOption) => this.updateSearch(selectedOption, 'campusId', 'campus'), 'Campus')}
        {this.domainSearch(filteredClassrooms, this.state.classroom, (selectedOption) => this.updateSearch(selectedOption, 'classroomId', 'classroom'), 'Classroom')}
      </>
    );
  }


  closeComponents = (event) => {
    if (event && (event.target.className !== 'more-option')) {
      this.setState({ showMoreActions: false }, () => document.removeEventListener('click', this.closeComponents));
    }
  }

  showComponents(event) {
    event.preventDefault();
    this.setState({ showMoreActions: true }, () => {
      document.addEventListener('click', this.closeComponents);
    });
  }

  renderMoreActions(text, onClick, disable = false) {
    return (
      <div className="more__item" style={{ padding: 10, borderBottom: '1px solid #CDCFD6', fontSize: 14, opacity: disable ? 0.5 : 1, cursor: disable ? 'not-allowed' : 'pointer' }} onClick={!disable && onClick}>
        {text}
      </div>
    );
  }

  renderMore() {
    const { course, campus, classroom, department, showMoreActions } = this.state;
    const payload = {
      courseId: course ? course.value : '',
      campusId: campus ? campus.value : '',
      classroomId: classroom ? classroom.value : '',
      departmentId: department ? department.value : '',
    };
    const disableSendingCredentials = !(payload.campusId || payload.departmentId)
    return (
      <div className="more" style={{ paddingLeft: 25, position: 'relative', display: 'flex', alignItems: 'center', userSelect: 'none' }}  onClick={(event) => this.showComponents(event)}>
        <i className="fa fa-ellipsis-v" style={{ fontSize: 24, cursor: 'pointer' }} onClick={() => this.setState({ showMoreActions: !showMoreActions })} />
        <div className="more__actions" style={{ display: showMoreActions ? 'block' : 'none' }}>
        {this.renderMoreActions("Send sms to parents", () => this.props.sendLoginCredentials(payload, "sms"), disableSendingCredentials)}
        {this.renderMoreActions("Send mail to parents", () => this.props.sendLoginCredentials(payload, "email"), disableSendingCredentials)}
        </div>
      </div>
    );
  }

  renderOverviewList() {
    const {
      preferences, studentDetailsLength, isHigherEducationInstitute, canSendBulkCredentials,isAdmin
    } = this.props;
    const {
      campusId, classroomId, searchString, showInstitutePreference, selectedStudents, courseId, departmentId,
    } = this.state;
    const isStudentsSelected = selectedStudents && selectedStudents.length;
    let ct = 0;
    const style = {
      height: 35, width: 140, display: 'flex', justifyContent: 'center', alignItems: 'center', border: '1px solid #CDCFD6',
    };
    return (
      <div>
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <div style={{
            display: 'flex', backgroundColor: '#FFFFFF', height: 60, width: '100%', alignItems: 'center', justifyContent: 'space-between', padding: '40px 50px', zIndex: 2, position: 'fixed', width: '80%',
          }}
          >
            <div style={{
              display: 'flex', justifyContent: 'space-between', width: 350, border: '1px solid #CDCFD6', borderRadius: 4, height: 40,
            }}
            >
              <div style={{ paddingLeft: 15, display: 'flex', alignItems: 'center' }}>
                <img alt="" src={search} width="16" height="16" />
              </div>
              <div style={{ width: 330, display: 'flex', justifyContent: 'space-between' }}>
                <div style={{ width: '100%', display: 'flex', alignItems: 'center' }}>
                  <input
                    className="input-field"
                    id="Search"
                    onChange={this.userSearch}
                    type="text"
                    placeholder="Search..."
                    value={this.state.searchString}
                    style={{
                      width: '99%', border: 'none', marginBottom: 0, height: 35,
                    }}
                  />
                </div>
              </div>
            </div>
            <div style={{ display: 'flex' }}>
              {this.renderSearchFilters()}
              {canSendBulkCredentials && this.renderMore()}
            </div>
          </div>
        </div>
        <div style={{ padding: '40px 50px', backgroundColor: '#F5F8FA' }}>
          {this.state.student && this.state.showModal
                && (
                <EditDetailsModal
                  showModal={this.state.student && this.state.showModal}
                  handleCloseModal={() => this.setState({ showModal: false, student: '' })}
                  updateCurrentStudentDetail={this.updateCurrentStudentDetail}
                  newStudentDetails={this.newStudentDetails}
                  student={this.state.student}
                  campuses={this.props.campuses}
                  classrooms={this.props.classrooms}
                  courses={this.props.courses}
                  departments={this.props.departments}
                  electives={this.props.electives}
                  institutesTags={this.props.institutesTags}
                  institutesTagGroups={this.props.institutesTagGroups}
                  isHigherEducationInstitute={isHigherEducationInstitute}
                />
                )}
          <div className="heading-2" style={{ marginTop: 60 }}>
            Total Students:&nbsp;
            {studentDetailsLength}
          </div>
          { isAdmin && <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: 20 }}>
            {!isStudentsSelected 
              ? (
                <>
                  <div
                    role="presentation"
                    style={{
                      height: 35, width: 140, display: 'flex', justifyContent: 'center', alignItems: 'center', border: '1px solid #CDCFD6', borderRadius: 4, cursor: 'pointer',
                    }}
                    onClick={() => this.setState({ student: 'new', showModal: true })}
                  >
                    Add Student
                  </div>
                  <div style={{ height: '0px', width: '0px', overflow: 'hidden' }}>
                    <input id="uploadStudentDetailsFile" type="file" name="file" accept=".xlsx" onChange={this.uploadStudentDetails} />
                  </div>
                  <div style={{ display: 'flex' }}>
                    {this.renderImportButton('Insert')}
                    {this.renderImportButton('Update')}
                    {(departmentId || campusId || courseId || classroomId) && !searchString && this.renderImportButton('Export')}
                  </div>
                </>
              )
              : (
                <>
                  <div className="heading-2" style={{ display: 'flex' }}>
                    <div>
                      Students selected:
                      {selectedStudents.length}
                    </div>
                    <div role="presentation" className="clickable" style={{ marginLeft: 15 }} onClick={() => this.setState({ selectedStudents: [] })}>
                      CLEAR
                    </div>
                  </div>
                  <div style={{ display: 'flex' }}>
                    {this.renderOptions('Active', () => this.modifyStudentAccess(selectedStudents, true))}
                    {this.renderOptions('InActive', () => this.modifyStudentAccess(selectedStudents, false))}
                  </div>
                </>
              )}
          </div>}
          <OnBoardingInstructionModal showModal={showInstitutePreference} preferences={preferences} insertDetails={() => this.setState({ showInstitutePreference: false }, () => this.getStudentDetailsFile())} handleCloseModal={() => this.setState({ showInstitutePreference: false })} />
          <div className=" mt-5">
            <table
              className="table table-hover"
              style={{
                boxShadow: '1px 2px 6px #8B9DAF33', borderRadius: 4, backgroundColor: '#FFFFFF', border: '1px solid #CDCFD6',
              }}
            >
              <thead style={{ background: '#F6F8FA', color: '#23394C' }}>
                <tr
                  className="table-header"
                  style={{
                    fontSize: '18px', fontWeight: 700, height: 60, color: '#11426C',
                  }}
                >
                  {this.renderStudentListHeader()}
                </tr>
              </thead>
              <tbody>
                {this.props.studentDetails.map((student) => {
                  return this.renderStudentList(student, ct += 1);
                })}

              </tbody>
            </table>
            {this.props.studentDetails.length < 1 && (
            <div style={{
              display: 'flex', justifyContent: 'center', alignItems: 'center', color: '#CDCFD6', height: 60, backgroundColor: '#FFFFFF',
            }}
            >
              No results found
            </div>
            )}
            <style jsx>
              {
                `table, th, td {
                    text-align: center;
                }
                td {
                    vertical-align: middle;
                }
                `
                }
            </style>
          </div>
        </div>

        {this.props.studentDetails.length > 0
            && (
            <div style={{
              display: 'flex', marginTop: 10, justifyContent: 'center', width: '100%',
            }}
            >
              <Pagination
                activePage={this.state.activePage}
                itemsCountPerPage={this.state.itemsCountPerPage}
                totalItemsCount={this.props.studentDetailsLength}
                pageRangeDisplayed={5}
                onChange={(event) => this.handlePageChange(event)}
              />
            </div>
            )}
      </div>
    );
  }

  renderVerification() {
    const { classroomsAndStudents } = this.props;
    const { importType } = this.state;
    const status = importType === 'insert' ? 'created' : 'updated';
    return (
      <div style={{ padding: '45px 80px 75px 80px', backgroundColor: '#F5F8FA' }}>
        <div className="heading-1" style={{ color: '#11426C' }}>
          Confirm Classrooms
          {status}
        </div>
        <div style={{ backgroundColor: '#FFFFFF', padding: 40 }}>
          {Object.keys(classroomsAndStudents).map((campus) => (
            this.renderCampusDetails(campus, status)
          ))}
        </div>
      </div>
    );
  }

  renderContent() {
    return (
      <div style={{ padding: '45px 80px 75px 80px', backgroundColor: '#F5F8FA' }}>
        <div className="heading-1" style={{ color: '#11426C' }}>Import Student Details</div>
        <div>
          {this.renderImport()}
        </div>
      </div>
    );
  }

  createExport() {
    const { isHigherEducationInstitute } = this.props;
    const fields = isHigherEducationInstitute ? ['S.No', 'Name', 'DOB','Father Name',  'Father Phone','Id', 'Semester', 'Classroom', 'Department', 'Email', 'OTP'] : ['S.No', 'Name', 'DOB', 'Father Name', 'Father Phone', 'ID', 'Course', 'Classroom', 'Campus', 'Mobile', 'Email', 'OTP'];
    const fieldIds = isHigherEducationInstitute ? ['index', 'first_name', 'dob','father_name', 'father_phone', 'student_id', 'course_name', 'classroom_name', 'department_name', 'email', 'otp'] : ['index', 'first_name', 'dob', 'father_name', 'father_phone', 'student_id', 'course_name', 'classroom_name', 'campus_name', 'phone', 'email', 'otp'];
    return {
      fields,
      fieldsIds: fieldIds,
    };
  }

  renderStudentListHeader() {
    const { selectedStudents } = this.state;
    const { studentsMembershipIds, campuses, isHigherEducationInstitute } = this.props;
    const headerStyle = { borderRight: '1px solid #DFE3EA', textAlign: 'left', whiteSpace: 'nowrap' };

    const checker = studentsMembershipIds && studentsMembershipIds.length
    && studentsMembershipIds.every((v) => selectedStudents.indexOf(v) >= 0);
    return (
      <>
        <th style={{ width: '5%', borderRight: '1px solid #DFE3EA' }}>
          <input
            type="checkbox"
            checked={checker}
            onChange={() => this.handleSelectedStudents('All', checker)}
          />
        </th>
        <th style={{ ...headerStyle, width: '10%' }}>ID</th>
        <th style={{ ...headerStyle, width: '25%' }}>Name</th>
        <th style={{ ...headerStyle, width: '15%' }}>
          {isHigherEducationInstitute ? 'Semester' : 'Course'}
        </th>
        <th style={{ ...headerStyle, width: '15%' }}>Classroom</th>
        {!isHigherEducationInstitute && <th style={{ ...headerStyle, width: '15%' }}>Campus</th>}
        {isHigherEducationInstitute && <th style={{ ...headerStyle, width: '20%' }}>Department</th>}
        {!isHigherEducationInstitute && <th style={{ ...headerStyle, width: '15%' }}>Mobile</th>}
        <th style={{ ...headerStyle, width: '15%' }}>OTP</th>
        <th style={{ ...headerStyle, width: '15%' }}>Tags</th>
      </>
    );
  }

  downloadPDF() {
    if (this.state.downloadType === FILE_TYPE.PDF) {
      return (
        <div
          className="App-Box-Title hide-in-print"
          style={{
            display: 'flex', justifyContent: 'space-between', alignItems: 'center', padding: '0 0 4px', marginTop: 20,
          }}
        >
          <PDFExport
            paperSize="A4"
            fileName="studentDetails.pdf"
            margin="5mm"
            scale={0.6}
            repeatHeaders
            ref={this.studentDetailsSave}
          >
            <div
              style={{
                height: '100%',
                width: '100%',
              }}
            >
              <div className="row" style={{ width: '100%', justifyContent: 'center', alignItems: 'center' }}>
                <div style={{ width: '20%' }} />
                <div style={{
                  textAlign: 'center', fontWeight: 'bold', fontFamily: 'Nunito Sans', fontSize: 34, width: '80%',
                }}
                >
                  <div>{this.props.instituteName}</div>
                  <div id="download-pdf" navigateBack="test_overview" />
                </div>
              </div>
              <table className="table table-hover" style={{ textAlign: 'center', marginBottom: 0, border: '1px solid #CDCFD6' }}>
                <thead style={{ background: '#F6F8FA', color: '#23394C' }}>
                  <tr
                    className="table-header"
                    style={{
                      fontSize: '18px', fontWeight: 700, height: 60, color: '#11426C',
                    }}
                  >
                    {this.renderStudentListHeader()}
                  </tr>
                  <tr style={{ fontSize: 16, fontFamily: 'Nunito', fontWeight: 400 }} />
                </thead>
                <tbody>
                  {this.props.studentDetailsSave.map((data, index) => this.renderStudentList(data, index))}
                </tbody>
              </table>
            </div>
          </PDFExport>
        </div>
      );
    }
    return <div />;
  }

  render() {
    const {
      verified, uploadSuccess, showOverViewList, showLoader,
    } = this.state;
    const {
      displayVerification, campuses, departments, permissionedDenied,
    } = this.props;
    const displaySample = !((campuses && campuses.length >= 0)
    || (departments && departments.length >= 0));
    const { instituteOnBoardingMenu } = Constants();
    const query = new URLSearchParams(window.location.search);
    const instituteId = query.get('institute_id');
    const metaData = this.createExport();
    const { fields } = metaData;
    const fieldIds = metaData.fieldsIds;
    instituteOnBoardingMenu.forEach((page) => {
      page.route = `${page.route}${instituteId !== null ? `?institute_id=${instituteId}` : ''}`;
    });
    if (showLoader) {
      return <Loader />;
    }
    if (permissionedDenied) {
      return (
        <AccessDenied />
      );
    }
    return (
      <Layout globalHistory={this.props.history} hideHeader>
        <div className="custom-checkbox" style={{ width: '100%', display: 'flex', minHeight: '100vh' }}>
          <div style={{ width: '20%', padding: 0, zIndex: 1 }}>
            <Menubar history={this.props.history} menuList={instituteOnBoardingMenu} selectedOption={1} redirectToRoot />
            <FileUploaderModal
              instituteId={instituteId}
              history={this.props.history}
              showModal={(this.state.studentDetailsFile || uploadSuccess) && this.state.showModal}
              success={this.state.fileUploadProgressStatus}
              route={instituteOnBoardingMenu[1].route}
              handleSuccess={(status) => this.setState({ fileUploadProgressStatus: status })}
              handleCloseModal={(success, data = {}) => {
                this.setState({
                  showModal: false,
                  displayVerification: success,
                  uploadSuccess: success,
                  studentDetailsFile: success ? this.state.studentDetailsFile : null,
                },
                !success ? () => {}
                  : () => {
                    this.props.updateClassroomsAndStudents(data);
                  });
                document.getElementById('uploadStudentDetailsFile').value = null;
              }}
              showExistingStudents={() => this.setState({ verified: true })}
            />
          </div>
          { this.state.downloadType === FILE_TYPE.EXCEL
            && (
            <ExcelExport
              data={this.props.studentDetailsSave}
              fileName="StudentDetails.xlsx"
              ref={this.studentDetailsSave}
            >
              {fields.map((field, index) => (<ExcelExportColumn field={fieldIds[index]} title={field} />))}
            </ExcelExport>
            )}
          { this.state.downloadType === FILE_TYPE.PDF
            && this.downloadPDF()}
          <div style={{ width: '80%', backgroundColor: '#F5F8FA' }}>
            {displaySample && this.renderContent()}
            {displayVerification && !verified && this.renderVerification()}
            {(((verified || !displayVerification) && !displaySample)
            || showOverViewList) && this.renderOverviewList()}
            {displayVerification && !verified
                && (
                <div style={{
                  height: 60, bottom: 0, position: 'fixed', width: '80%', zIndex: 2, display: 'flex', justifyContent: 'center', backgroundColor: '#FFFFFF',
                }}
                >
                  <div style={{
                    display: 'flex', alignItems: 'center', width: '100%', justifyContent: 'space-around',
                  }}
                  >
                    <div style={{
                      height: '0px', width: '0px', overflow: 'hidden', display: 'none',
                    }}
                    >
                      <input id="uploadStudentDetailsFile" type="file" name="file" accept=".xlsx" onChange={this.uploadStudentDetails} />
                    </div>
                    <div role="presentation" className="verify" style={{ width: 400, border: '1px solid #40A7FF' }} onClick={() => this.getStudentDetailsFile()}>Re upload</div>
                    <div
                      role="presentation"
                      className="verify"
                      style={{ width: 400, backgroundColor: '#40A7FF', color: '#FFFFFF' }}
                      onClick={() => {
                        this.props.uploadVerifiedClassroomsAndStudents(this.state.importType, instituteId); this.setState({
                          verified: true, showModal: true, showOverViewList: true,
                        });
                      }}
                    >
                      Verify
                    </div>
                  </div>
                </div>
                )}
          </div>
        </div>
      </Layout>
    );
  }
}
const mapStateToProps = ({ institute }) => ({
  campuses: institute.campuses,
  classrooms: institute.classrooms,
  courses: institute.courses,
  electives: institute.electives,
  campusesClassrooms: institute.campusesClassrooms,
  classroomsAndStudents: institute.classroomsAndStudents,
  studentDetails: institute.studentDetails,
  preferences: institute.preferences,
  displayVerification: Object.keys(institute.classroomsAndStudents).length > 0,
  permissionedDenied: institute.permissionedDenied,
  studentDetailsLength: institute.studentDetailsLength,
  studentDetailsSave: institute.studentDetailsSave,
  studentsMembershipIds: institute.studentsMembershipIds,
  departments: institute.departments,
  isHigherEducationInstitute: institute.isHigherEducationInstitute,
  canSendBulkCredentials: institute.canSendBulkCredentials,
  institutesTags: institute.institutesTags,
  institutesTagGroups: institute.institutesTagGroups,
  isAdmin: institute.isAdmin
});

export default connect(mapStateToProps, {
  uploadStudentDetails,
  createNewStudent,
  uploadVerifiedClassroomsAndStudents,
  updateCurrentStudentData,
  updateStudentDetails,
  getMembershipDetails,
  updateStudentStatus,
  updateClassroomsAndStudents,
  sendLoginCredentials,
})(StudentDetails);
