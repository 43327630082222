import { UPDATE_HISTORY } from '../actions';

const initialState = {
  history: {},
};

export default function (state = initialState, action) {
  switch (action.type) {
    case UPDATE_HISTORY:
      return { ...state, ...action.payload };
    default:
      return state;
  }
}
