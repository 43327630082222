import React, { PureComponent } from 'react';
import Select from 'react-select';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { getChapterCheckpointList, getQuickpointList } from '../../actions';
import { SUBJECT_LIST } from '../../constants';

class Dropdown extends PureComponent {
  render() {
    const {
      selectedSubject, chapterList, testType,
    } = this.props;
    if (selectedSubject) {
      return (
        <Select
          className="checkpointDropDownList"
          options={chapterList}
          placeholder="Chapter"
          isSearchable
          onChange={({ value }) => this.props.getQuickpointList({ chapter: value })}
        />
      );
    }
    return (
      <Select
        className="subjectDropDownList"
        options={SUBJECT_LIST}
        placeholder="Subject"
        onChange={({ value }) => this.props.getChapterCheckpointList(value, testType)}
      />
    );
  }
}

const mapStateToProps = ({ quickpoints }) => ({
  selectedSubject: quickpoints.selectedSubject,
  chapterList: quickpoints.chapterList,
  testType: quickpoints.testType,
});

Dropdown.propTypes = {
  selectedSubject: PropTypes.number.isRequired,
  chapterList: PropTypes.array.isRequired,
  testType: PropTypes.number.isRequired,
};

export default connect(mapStateToProps, { getChapterCheckpointList, getQuickpointList })(Dropdown);
