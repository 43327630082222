import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import CompactLoader from '../loading/compactLoader';
import { getDashboardData } from '../../actions';
import '../../styles/instituteOnBoarding.css';
import Loader from '../loading';
import Constants from '../../shared/constants';
class Dashboard extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      showLoader: true,
    };
  }

  componentDidMount() {
    this.props.getDashboardData().then(() => {
    this.setState(() => ({ showLoader: false }));
    });
  }

  renderHeader() {
    return (
      <div style={{
        fontWeight: 600, fontSize: 24, padding: '0 60px', height: 70, display: 'flex', alignItems: 'center', background: '#FFFFFF', color: Constants().color.primary, position: 'fixed', width: '100%', zIndex: 10, borderBottom: '1px solid black',
      }}
      >
        Dashboard
      </div>
    );
  }

  renderDetails() {
    const { dashBoardData } = this.props;

    return Object.keys(dashBoardData).map((data) => {
      return (
        <div style={{
          display: 'flex', alignItems: 'center', flexDirection: 'column', padding: 20, border: '1px solid black', width: 300,
        }}
        >
          <div style={{
            fontSize: 40, padding: 5, fontWeight: '600',
          }}
          >
            {dashBoardData[data].value}
          </div>
          <div style={{
            fontSize: 20, padding: 5,
          }}
          >
            {dashBoardData[data].name}
          </div>
        </div>
      );
    });
  }

  render() {
    const { showLoader } = this.state;
    let innerHeight = '';
    if (typeof window !== 'undefined') {
      innerHeight = window.innerHeight;
    }
    if (showLoader) {
      return <Loader />;
    }
    return (
      <div style={{ background: '#F5F8FA', height: typeof window !== 'undefined' && window.innerHeight, fontFamily: 'Roboto' }}>
        {this.renderHeader()}
        <div style={{ padding: '70px 60px 0px' }}>
          <div style={{
            border: '1px solid black', backgroundColor: 'white', padding: 30, marginTop: 60,
          }}
          >
            <div style={{ fontSize: 28 }}> Today's Highlights </div>
            <div style={{
              width: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'space-between', padding: '30px 0px', height: 230,
            }}
            >
              {showLoader ? <CompactLoader height="100%" /> : this.renderDetails() }
            </div>
          </div>
        </div>
      </div>
    );
  }
}

Dashboard.propTypes = {
  getDashboardData: PropTypes.func.isRequired,
  dashBoardData: PropTypes.object.isRequired,
};

const mapStateToProps = ({ virtualMeeting }) => ({
  dashBoardData: virtualMeeting.dashBoardData,
});

export default connect(mapStateToProps, {
  getDashboardData,
})(Dashboard);
