import React, { PureComponent } from 'react';
import Layout from '../layout';
import accessDenied from '../../static/images/accessDenied.png';

class AccessDenied extends PureComponent {
  render() {
    return (
      <Layout globalHistory={this.props.history}>
        <div style={{
          position: 'fixed',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          textAlign: 'center',
          width: '100%',
        }}
        >
          <img alt="" className="center" src={accessDenied} width="20%" />
          <h3> Sorry you dont have Permission to Access this page </h3>
        </div>
      </Layout>
    );
  }
}

export default AccessDenied;
