import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';
import { showToast } from '../../actions';

const textDesign = {
  border: '1px solid #4545456B', height: 25, width: 60, overflow: 'auto', whiteSpace: 'pre-wrap',
};

class DurationPicker extends PureComponent {
  constructor(props) {
    super(props);
    this.renderTextArea = this.renderTextArea.bind(this);
  }

  renderTextArea(header, type, min, max) {
    const { data } = this.props;
    const time = data || {};
    return (
      <div style={{ display: 'flex', marginLeft: 20 }}>
        <input
          style={{
            ...textDesign,
          }}
          type="number"
          onChange={(event) => {
            time[type] = event.target.value;
            if (time[type] > max || time[type] < min) {
              time[type] = 0;
              this.props.showToast(`${header} should be between ${min} and ${max}`);
            }
            this.props.setData({ time });
          }}
          value={time[type]}
          min={min}
          max={max}
        />
        <div style={{ marginLeft: 10 }}>{header}</div>
      </div>
    );
  }

  render() {
    return (
      <div style={{ display: 'flex' }}>
        {this.renderTextArea('Hours', 'hours', 0, 12)}
        {this.renderTextArea('Minutes', 'minutes', 0, 60)}
        {this.renderTextArea('Seconds', 'seconds', 0, 60)}
        <div />
      </div>
    );
  }
}

DurationPicker.propTypes = {
  data: PropTypes.object,
};

DurationPicker.defaultProps = {
  data: { hours: 0, seconds: 0, minutes: 0 },

};

export default connect(null, {
  showToast,
})(DurationPicker);
