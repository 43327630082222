import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Cookies from 'universal-cookie';
import ReactTooltip from 'react-tooltip';
import moment from 'moment-timezone';
import SnackBar from '../snackBar';
import { verifyUserPassword, changeUserPassword, showToast } from '../../actions';
import Layout from '../layout';
import showPasswordIcon from '../../static/images/show_password.png';
import hidePasswordIcon from '../../static/images/hide_password.png';

const cookies = new Cookies();
const userProfile = cookies.get('user') || {};

class ChangePassword extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      password: '',
      passwordConfirmation: '',
      showPassword: false,
      showConfirmPassword: false,
      resetToken: '',
      isPasswordVerified: false,
      currentPassword: '',
    };
    this.verifyPassword = this.verifyPassword.bind(this);
    this.changePassword = this.changePassword.bind(this);
  }

  changePassword() {
    const {
      password, passwordConfirmation, showPassword, showConfirmPassword
    } = this.state;
    return (
      <div style={{
        display: 'flex',
        flexDirection: 'column',
        border: '1px solid #DCDCDC',
        boxShadow: '0 0 2px #DCDCDC',
        alignItems: 'center',
        padding: '80px 40px 50px 40px',
      }}
      >
        <div style={{
          marginTop: -25, marginBottom: 10, fontWeight: 'bolder', fontSize: '25px',
        }}
        >
          Change Password
        </div>
        <div>
          <div>
            Password
            <input
              id="password"
              type={(showConfirmPassword) ? 'text' : 'Password'}
              onChange={(event) => {
                ReactTooltip.hide(this.passwordTooltip);
                this.setState({ password: event.target.value });
              }}
              value={password}
              style={{
                width: 400, marginTop: 50, marginBottom: 10, marginLeft: 70,
              }}
              placeholder="Password"
            />
            <img style={{ cursor: 'pointer', paddingLeft: '10px', width: '50px' }} src={(showConfirmPassword) ? showPasswordIcon : hidePasswordIcon} onClick={() => { this.setState({ showConfirmPassword: !showConfirmPassword }); }} aria-hidden="true" width={35} height={35} alt="success" />
          </div>
          <div>
            Confirm Password
            <input
              id="password"
              type={(showConfirmPassword) ? 'text' : 'Password'}
              onChange={(event) => {
                ReactTooltip.hide(this.passwordTooltip);
                this.setState({ passwordConfirmation: event.target.value });
              }}
              value={passwordConfirmation}
              style={{
                width: 400, marginTop: 50, marginBottom: 30, marginLeft: 10,
              }}
              placeholder="Password"
            />
          </div>
        </div>
        <div
          className="Button primary-Button"
          role="presentation"
          onClick={() => {
            this.props.changeUserPassword({
              password,
            }, passwordConfirmation);
          }}
          style={{
            width: 250, marginTop: 20, marginBottom: -10, boxShadow: '3px #1434A4',
          }}
        >
          Change Password
        </div>
      </div>
    );
  }

  verifyPassword() {
    const {
      currentPassword, showPassword, resetToken,
    } = this.state;
    return (
      <div style={{
        display: 'flex',
        flexDirection: 'column',
        border: '1px solid #DCDCDC',
        boxShadow: '0 0 2px #DCDCDC',
        alignItems: 'center',
        padding: '80px 40px 50px 40px',
      }}
      >
        <div style={{
          marginTop: -25, marginBottom: 20, fontWeight: 'bolder', fontSize: '25px',
        }}
        >
          Verify Password

        </div>
        <div>
          <input
            id="password"
            type={(showPassword) ? 'text' : 'Password'}
            onChange={(event) => {
              ReactTooltip.hide(this.passwordTooltip);
              this.setState({ currentPassword: event.target.value });
            }}
            value={currentPassword}
            style={{
              width: 400, marginTop: 50, marginBottom: 30,
            }}
            placeholder="Password"
          />
          <img style={{ cursor: 'pointer', paddingLeft: '10px', width: '50px' }} src={(showPassword) ? showPasswordIcon : hidePasswordIcon} onClick={() => { this.setState({ showPassword: !showPassword }); }} aria-hidden="true" width={35} height={35} alt="success" />
        </div>
        <div
          className="Button primary-Button"
          style={{
            width: 250, marginTop: 20, marginBottom: -10, boxShadow: '3px #1434A4',
          }}
          role="presentation"
          onClick={() => {
            this.props.verifyUserPassword({
              password: currentPassword, email: userProfile.email, phone: userProfile.profile && userProfile.profile.phone, instituteName: userProfile.profile && userProfile.profile.institute_name,
            }).then(({ isPasswordVerified, resetToken }) => {
              this.setState({ isPasswordVerified, resetToken });
            });
          }}
        >
          Continue
        </div>
      </div>
    );
  }

  render() {
    const {
      isPasswordVerified,
    } = this.state;
    return (
      <Layout globalHistory={this.props.history}>
        <SnackBar />
        <div
          style={{
            padding: 40,
            display: 'flex',
            justifyContent: 'center',
          }}
        >
          <div>{isPasswordVerified ? this.changePassword() : this.verifyPassword()}</div>
        </div>
      </Layout>
    );
  }
}

export default connect(null,
  { verifyUserPassword, changeUserPassword, showToast })(ChangePassword);
