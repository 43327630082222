import React, { Component } from 'react';
import { connect } from 'react-redux';
import GetQuestionView from './getQuestionView';
import { updateSearchString, resetQuestionsListReducer, searchQuestions } from '../../actions';
import search from '../../static/images/search.svg';
import '../home/home.css';
import Constants from '../../shared/constants';
import Layout from '../layout';

class GetQuestions extends Component {
  constructor(props) {
    super(props);
    this.scrollRef = React.createRef();
    this.state = {
      economyMode: false,
      showSolution: false,
      highlightAnswer: false,
    };
    this.renderContent = this.renderContent.bind(this);
  }

  renderContent() {
    const { questions } = this.props;
    const { showSolution, highlightAnswer } = this.state;
    let innerWidthOfWindow = null;
    if (typeof window !== 'undefined') {
      innerWidthOfWindow = window.innerWidth;
    }
    return (
      <div style={{ marginTop: 30 }}>
        {questions.length > 0 && questions.map((question) => (
          <div style={{ borderTop: `2px solid ${Constants().color.primary}`, margin: '10px 0px', display: 'flex' }}>
            <div style={{ width: 75, marginTop: 10, position: 'absolute' }}>
              <div style={{ fontWeight: 'bold' }}>
                {question.id}
              </div>
            </div>
            <div style={{ marginTop: 5, marginLeft: 75 }}>
              <GetQuestionView economyMode={this.state.economyMode} question={question} showOnlyQuestionText={false} highlightAnswer={highlightAnswer} showSolution={showSolution} getQuestions />
            </div>
          </div>
        ))}
      </div>
    );
  }

  renderSearchBar() {
    const { searchText } = this.props;
    return (
      <div style={{ display: 'flex' }}>
        <div style={{
          margin: '10px 0px', display: 'flex', padding: 10, border: '1px solid #4C5E6F', borderRadius: 4, width: '100%',
        }}
        >
          <img alt="" src={search} width="25" />
          <input
            id="searchString"
            type="text"
            autoComplete="off"
            placeholder="Search Questions"
            style={{
              border: 0, marginBottom: 0, height: '100%', width: '100%', backgroundColor: 'inherit', margin: 0, outline: 'none',
            }}
            onChange={(e) => {
              this.props.updateSearchString({ searchText: e.target.value, fetching: e.target.value !== '' });
              if (e.target.value === '') {
                this.props.resetQuestionsListReducer();
              }
            }}
            onKeyPress={(event) => event.key === 'Enter' && this.props.searchQuestions()}
            value={searchText}
          />
        </div>
        <div
          className="primary_button"
          style={{
            width: 100, display: 'flex', justifyContent: 'center', alignItems: 'center', borderRadius: 4, margin: 10, cursor: 'pointer',
          }}
          onClick={() => this.props.searchQuestions()}
        >
          Search
        </div>
      </div>
    );
  }

  render() {
    const { showSolution, highlightAnswer } = this.state;
    return (
      <Layout globalHistory={this.props.history}>
        <div style={{ ...this.state.pageStyle, padding: 20, marginTop: 30 }}>
          <div style={{ fontSize: 20, fontWeight: 'bold' }}>
            Get questions:
          </div>
          {this.renderSearchBar()}
          <div style={{ display: 'flex' }}>
            <div className={`${showSolution ? 'primary_button' : 'secondary_button'}`} style={{ width: 'fit-content', padding: '5px 10px' }} onClick={() => this.setState({ showSolution: !showSolution })}>
              {' Show Hint & Solution'}
            </div>
            <div className={`${highlightAnswer ? 'primary_button' : 'secondary_button'}`} style={{ width: 'fit-content', padding: '5px 10px', marginLeft: 10 }} onClick={() => this.setState({ highlightAnswer: !highlightAnswer })}>
              Highlight answer
            </div>
          </div>
          <div style={{ display: 'flex', justifyContent: 'center', marginTop: 5 }}>
            <div className="primary_button" style={{ display: 'flex', padding: '5px 10px', width: 'fit-content' }} onClick={() => this.setState({ economyMode: !this.state.economyMode })}>
              Change Layout
            </div>
          </div>
          {this.renderContent()}
        </div>
      </Layout>
    );
  }
}

const mapStateToProps = ({ getQuestions }) => ({
  questions: getQuestions.questions,
});

export default connect(mapStateToProps, {
  resetQuestionsListReducer,
  updateSearchString,
  searchQuestions,

})(GetQuestions);
