import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Pagination from 'react-js-pagination';
import DatePicker from 'react-datepicker';
import Loader from '../loading';
import { secondsToHm, getNumberFromString } from '../../helpers/util';
import LocaleDate from '../localeDate';
import { DropDown } from '../common';
import { showToast, getDelayedClasses } from '../../actions';
import Constants from '../../shared/constants';
import 'react-datepicker/dist/react-datepicker.css';

const PAGE_COUNT = 10;
class DelayedClasses extends React.Component {
  constructor(props) {
    super(props);
    const query = new URLSearchParams(window.location.search);
    this.state = {
      showLoader: true,
      daysAgo: new Date(),
      page: 1,
      teacher: {},
      threshold: query.get('threshold') || 5,
    };
    this.getData = this.getData.bind(this);
    this.renderPagination = this.renderPagination.bind(this);
    this.changeThreshold = this.changeThreshold.bind(this);
  }

  componentDidMount() {
    this.getData();
  }

  getData() {
    const { daysAgo, teacher, threshold } = this.state;
    this.props.getDelayedClasses(daysAgo, (teacher && teacher.id) || '', threshold).then(() => {
      this.setState(() => ({ showLoader: false }));
    });
  }

  clearFilters() {
    return (
      <div
        role="presentation"
        className="Button"
        style={{
          height: 40, width: 100, background: Constants().color.primary, flexWrap: 'nowrap', fontSize: 14, color: '#FFFFFF',
        }}
        onClick={() => this.setState({ daysAgo: '', teacher: {} }, () => this.getData())}
      >
        Clear
      </div>
    );
  }

  changeThreshold() {
    const { threshold } = this.state;
    const style = {
      resize: 'none', width: 50, marginBottom: 0, outline: 'none', border: '1px solid #454545', borderRadius: 0, display: 'flex', textAlignLast: 'center',
    };
    return (
      <div style={{
        display: 'flex', alignItems: 'center', justifyContent: 'flex-end', marginRight: 60, marginTop: 30,
      }}
      >
        <input
          value={threshold}
          maxLength="3"
          style={{
            ...style, height: 35, marginLeft: 40, marginRight: 10,
          }}
          onChange={(event) => this.setState({ threshold: getNumberFromString(event.target.value) })}
        />
        <div style={{ marginRight: 20 }}>minutes</div>
        <a
          className="Button Primary-Button"
          href={`/classboard/delayed_classes?threshold=${threshold}`}
          style={{ width: 150, textDecoration: 'none' }}
        >
          SET THRESHOLD
        </a>
      </div>
    );
  }

  renderHeader() {
    const { teachers, isHigherEducation } = this.props;
    const { daysAgo, teacher } = this.state;
    const date = new Date();
    date.setDate(date.getDate());
    return (
      <div style={{
        display: 'flex', justifyContent: 'flex-end', alignItems: 'center', paddingBottom: 0, padding: '0 60px', paddingTop: 100,
      }}
      >
        <div style={{ marginLeft: 40 }}>
          {isHigherEducation && this.renderDropDownValues(`${teachers.id}${daysAgo}`, teacher, teachers, 'Select Teacher', (id, name) => { this.setState({ teacher: { id, name }, daysAgo: '' }, () => this.getData()); })}
        </div>
        <div style={{ width: 200, marginLeft: 40, cursor: 'pointer' }}>
          <DatePicker
            selected={daysAgo}
            onChange={(selectedDate) => {
              this.setState({ daysAgo: selectedDate }, () => this.getData());
            }}
            dateFormat="dd/MM/yyyy"
            placeholderText="Select date"
          />
        </div>
        <div style={{ marginLeft: 40 }}>
          {((teacher && teacher.id) || daysAgo) && this.clearFilters()}
        </div>
      </div>
    );
  }

  renderDropDownValues(fieldName, fieldValue, dropDownValues, placeholder, updateValue) {
    const { selectedClassrooms } = this.state;
    const filterItems = [];
    for (let i = 0; i < dropDownValues.length; i += 1) {
      filterItems[i] = dropDownValues[i];
    }
    return (
      <DropDown
        defaultText={placeholder}
        key={`${fieldValue.name}_${fieldName}_${selectedClassrooms && selectedClassrooms.length}`}
        selectedItem={fieldValue}
        optionsList={[...filterItems]}
        width={200}
        height={40}
        color="#DDDDDD"
        placeholder={placeholder}
        onValueChange={updateValue}
      />
    );
  }

  renderClassData(classes) {
    const { teacher, daysAgo } = this.state;
    const { isHigherEducation } = this.props;
    const style = { padding: 20 };
    const date = classes.start_time ? new Date(classes.start_time) : '';
    if (classes.start_time) {
      date.setDate(date.getDate() - daysAgo);
    }
    return (
      <tr>
        {isHigherEducation && !(teacher && teacher.id) && <td style={style}>{classes.teacher_name}</td>}
        {!(daysAgo) && (
        <td style={style}>
          <LocaleDate date={date} />
        </td>
        )}
        {isHigherEducation && <td style={style}>{classes.department_names && classes.department_names.join(', ')}</td>}
        <td style={style}>{classes.subject_name}</td>
        <td style={style}>{classes.classroom_names && classes.classroom_names.join(', ')}</td>
        <td style={style}>
          {new Date(classes.start_time).toLocaleString('en-US', {
            timeZone: 'Asia/Kolkata', hour12: true, hour: 'numeric', minute: '2-digit',
          })}
        </td>
        <td style={{ ...style, whiteSpace: 'nowrap' }}>{classes.not_hosted ? 'Was not conducted' : secondsToHm(classes.delayed_by)}</td>
      </tr>
    );
  }

  renderPagination() {
    const { page } = this.state;
    const { delayedClasses } = this.props;
    if (delayedClasses && delayedClasses.length > 0 && delayedClasses.length > PAGE_COUNT) {
      return (
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <Pagination
            activePage={page}
            itemsCountPerPage={PAGE_COUNT}
            totalItemsCount={delayedClasses.length}
            pageRangeDisplayed={5}
            onChange={(pageNumber) => this.setState({ page: pageNumber })}
          />
        </div>
      );
    }
    return <div />;
  }

  renderTable() {
    const { delayedClasses, isHigherEducation } = this.props;
    const { teacher, page, daysAgo } = this.state;
    const minCount = page >= 2 ? (page - 1) * PAGE_COUNT : 0;
    const maxCount = (page) * PAGE_COUNT;
    const style = { padding: 20, whiteSpace: 'nowrap', borderRight: '1px solid #DFE3EA' };
    if (delayedClasses.length > 0) {
      return (
        <div style={{ padding: '30px 60px' }}>
          <table
            className="table table-hover"
            style={{
              boxShadow: '1px 2px 6px #8B9DAF33', borderRadius: 4, backgroundColor: '#FFFFFF', border: '1px solid #CDCFD6',
            }}
          >
            <thead style={{ background: '#F6F8FA', color: '#23394C' }}>
              <tr>
                {isHigherEducation && !(teacher && teacher.id) && <th style={style}>TEACHER</th>}
                {!daysAgo && <th style={style}>DATE</th>}
                {isHigherEducation && <th style={style}> DEPARTMENT </th>}
                <th style={style}>SUBJECT</th>
                <th style={style}>SECTION</th>
                <th style={style}>SCHEDULED TIME</th>
                <th style={style}>LATE BY </th>
              </tr>
            </thead>
            <tbody>
              {delayedClasses.slice(minCount, maxCount).map((classes) => {
                return this.renderClassData(classes);
              })}
            </tbody>
          </table>
          {this.renderPagination()}
        </div>
      );
    }
    return (
      <div style={{
        margin: '150px 50px', textAlign: 'center', fontSize: 24, padding: 20, border: '1px solid',
      }}
      >
        No delayed classes
      </div>
    );
  }

  renderData() {
    return (
      <div style={{ background: '#FFFFFF', height: typeof window !== 'undefined' && window.innerHeight, fontFamily: 'Roboto' }}>
        <div style={{
          fontWeight: 600, fontSize: 24, padding: '0 60px', height: 70, background: '#FFFFFF', color: Constants().color.primary, boxShadow: '0px 3px 6px #343B4014', width: '100%', position: 'fixed', display: 'flex', alignItems: 'center', zIndex: 10,
        }}
        >
          Delayed Classes
        </div>
        <style>
          {`
            a:hover, a:visited, a:active {
              color: #FFFFFF !important;
            }
        `}
        </style>
        {this.renderHeader()}
        {this.changeThreshold()}
        {this.renderTable()}
      </div>
    );
  }

  render() {
    const { showLoader } = this.state;
    if (showLoader) {
      return <Loader />;
    }
    return this.renderData();
  }
}

DelayedClasses.propTypes = {
  delayedClasses: PropTypes.object,
  teachers: PropTypes.array,
  isHigherEducation: PropTypes.bool,
};

DelayedClasses.defaultProps = {
  delayedClasses: [],
  teachers: [],
  isHigherEducation: false,
};

const mapStateToProps = ({ virtualMeeting }) => ({
  delayedClasses: virtualMeeting.delayedClasses,
  teachers: virtualMeeting.teachers,
  isHigherEducation: virtualMeeting.isHigherEducation,
});

export default connect(mapStateToProps,
  { showToast, getDelayedClasses })(DelayedClasses);
