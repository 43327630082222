import {
  FETCH_ASSIGNMENT_DATA, UPDATE_ASSIGNMENT_DATA, UPDATE_SEARCH_LIST,
  UPDATE_ASSIGNMENT_QUESTIONS, UPDATE_ASSIGNMENT,
  RESET_RENDER_MORE_QUESTION_STATUS, UPDATE_QUESTION_FETCH,
  UPDATE_PRINT_QUESTIONS, UPDATE_LANDING_PAGE_DATA, RESET_FILTERS,
  GET_ANSWER_KEY, STUDENT_RESULT_UPLOAD_STATUS, UPDATE_REVIEW_STATUS,
  UPDATE_ASSIGNMENT_QUESTIONS_IDS, UPDATE_MODAL, UPDATE_NUMERICAL_QUESTION_LIST,
  GET_REPORT_ISSUE_QUESTIONS, PERMISSION_DENIED_ASSIGNMENT, SET_SHOW_TEST,
} from '../actions';

const initialState = {
  testInfo: {},
  batchId: [],
  questions: [],
  testQuestions: [],
  assignmentQuestionIds: [],
  numericalQuestionIds: [],
  studentLimit: 0,
  numericalQuestionCount: {},
  subjects: [],
  units: [],
  chapters_list: [],
  showTest: false,
  checkpoints_list: [],
  searchList: [],
  totalQuestionCount: 0,
  questionDistribution: {},
  progressiveSearchSelectedValues: {},
  comprehensionQuestionIndexMapping: {},
  totalQuestionsToBeSelected: 0,
  subjectGroupMapping: {},
  renderMoreQuestions: false,
  classroomList: [],
  testGroupList: [],
  redirectReportPage: false,
  testTypeList: [],
  testQuestionSize: 0,
  questionIdsLoading: [],
  instituteName: '',
  instituteLogo: '',
  instituteWatermark: '',
  instituteTestHeaderImage: '',
  selectedClassroomList: [],
  selectedTestGroup: '',
  hasAccess: false,
  testDetails: {},
  reporterMode: false,
  hasMentorBoardAccess: false,
  numberOfAnswers: {},
  numberOfQuestions: {},
  numberOfTests: {},
  selectedTestSet: {},
  testSets: [],
  paperPreference: {},
  showProofread: false,
  testMetaData: {},
  appliedFilters: {
    freshness: false,
    difficulty: [],
    previous_paper: [],
    question_type: [],
  },
  assignmentQuestionLimit: '',
  answers: [],
  errors: [],
  status: '',
  showModal: false,
  hasMultipleTestSets: false,
  testInsight: {
    similarQuestionsCount: 0,
    repeatedQuestions: 0,
  },
  isFeedbackFilled: false,
  isTrial: true,
  reported_questions: {},
  permissionedDenied: false,
  testsClassrooms: [],
  studentsCount: 0,
};

const getComprehensionQuestionIndexMapping = (questions) => {
  const existingMapping = {};
  questions.forEach((question, index) => {
    if (question.comprehension_question_id) {
      existingMapping[question.comprehension_question_id] = existingMapping[question.comprehension_question_id] || [];
      existingMapping[question.comprehension_question_id].push(index + 1);
    }
  });
  return existingMapping;
};

export default function (state = initialState, action) {
  switch (action.type) {
    case FETCH_ASSIGNMENT_DATA: {
      return {
        ...state,
        ...action.payload,
        questions: action.payload.questions,
        comprehensionData: action.payload.comprehensionData,
        totalQuestionCount: action.payload.totalQuestionCount,
        comprehensionQuestionIndexMapping:
          getComprehensionQuestionIndexMapping(action.payload.questions),
        testInsight: action.payload.testInsight,
        currentTrack: action.payload.testInfo.currentTrack,
        isTrial: action.payload.isTrial,
        studentsCount: action.payload.studentsCount,
      };
    }
    case UPDATE_ASSIGNMENT_DATA: {
      return {
        ...state,
        questions: [...state.questions, ...action.payload.questions],
        comprehensionQuestionIndexMapping:
          getComprehensionQuestionIndexMapping([...state.questions, ...action.payload.questions]),
        assignmentQuestionIds:
          [...state.assignmentQuestionIds, ...action.payload.assignmentQuestionIds],
        questionDistribution: action.payload.questionDistribution,
        renderMoreQuestions: action.payload.renderMoreQuestions,
        totalQuestionsToBeSelected: action.payload.totalQuestionsToBeSelected,
        isTrial: action.payload.isTrial,
        studentsCount: action.payload.studentsCount,
      };
    }
    case UPDATE_SEARCH_LIST: {
      return {
        ...state,
        chapters_list: action.chapters_list,
        checkpoints_list: action.checkpoints_list,
      };
    }
    case UPDATE_ASSIGNMENT_QUESTIONS: {
      if (action.payload.status === 'added') {
        return {
          ...state,
          selectedQuestionCount: action.payload.selected_questions_count,
          numericalQuestionCount: action.payload.numerical_question_count,
          assignmentQuestionIds: [...state.assignmentQuestionIds,
            parseInt(action.payload.questions_id, 10)],
          questionDistribution: action.payload.question_distribution,
          totalSelectedQuestionCount: action.payload.total_selected_question_count,
          sectionWiseQuestions: action.payload.section_wise_questions,
        };
      }
      return {
        ...state,
        selectedQuestionCount: action.payload.selected_questions_count,
        assignmentQuestionIds: state.assignmentQuestionIds.filter((item) => item !== parseInt(action.payload.questions_id, 10)),
        numericalQuestionCount: action.payload.numerical_question_count,
        questionDistribution: action.payload.question_distribution,
        totalSelectedQuestionCount: action.payload.total_selected_question_count,
        sectionWiseQuestions: action.payload.section_wise_questions,
      };
    }
    case UPDATE_ASSIGNMENT_QUESTIONS_IDS: {
      return {
        ...state,
        assignmentQuestionIds: action.payload,
      };
    }
    case UPDATE_ASSIGNMENT: {
      return { ...state, name: action.testInfo.name, batchId: action.batchId };
    }
    case RESET_RENDER_MORE_QUESTION_STATUS: {
      return { ...state, renderMoreQuestions: true };
    }
    case UPDATE_QUESTION_FETCH: {
      return {
        ...state,
        questionIdsLoading: action.payload.status
          ? [...state.questionIdsLoading, action.payload.questionId]
          : state.questionIdsLoading.filter((e) => e != action.payload.questionId),
      };
    }
    case UPDATE_PRINT_QUESTIONS: {
      return { ...state, ...action.payload };
    }
    case UPDATE_LANDING_PAGE_DATA: {
      return {
        ...state,
        numberOfQuestions: action.numberOfQuestions,
        numberOfTests: action.numberOfTests,
        numberOfAnswers: action.numberOfAnswers,
      };
    }
    case RESET_FILTERS: {
      return initialState;
    }
    case GET_ANSWER_KEY: {
      return { ...state, ...action.payload };
    }
    case STUDENT_RESULT_UPLOAD_STATUS: {
      let { errors, status } = action;
      if (!errors) {
        errors = [];
      }
      if (!status) {
        status = '';
      }
      return {
        ...state,
        errors,
        status,
      };
    }
    case UPDATE_NUMERICAL_QUESTION_LIST: {
      let {
        numericalQuestionIds,
      } = state;
      const { testInfo, assignmentQuestionIds, numericalQuestionCount } = state;
      const questionIndex = numericalQuestionIds.indexOf(action.payload.questionId);
      const isQuestionInTest = assignmentQuestionIds.indexOf(action.payload.questionId);

      if (questionIndex === -1) {
        numericalQuestionIds.push(action.payload.questionId);
        if (isQuestionInTest !== -1) {
          numericalQuestionCount[testInfo.current_track] += 1;
        }
      } else {
        numericalQuestionIds.splice(questionIndex, 1);
        if (isQuestionInTest !== -1) {
          numericalQuestionCount[testInfo.current_track] -= 1;
        }
      }
      numericalQuestionIds = Array.from(new Set(numericalQuestionIds));
      return { ...state, numericalQuestionIds, numericalQuestionCount };
    }
    case UPDATE_REVIEW_STATUS: {
      const { testInfo } = state;
      testInfo.is_reviewed = action.isReviewed;
      return { ...state, testInfo };
    }
    case UPDATE_MODAL: {
      return { ...state, showModal: action.payload };
    }
    case GET_REPORT_ISSUE_QUESTIONS: {
      return { ...state, ...action.payload };
    }
    case SET_SHOW_TEST: {
      return { ...state, ...action.payload };
    }
    case PERMISSION_DENIED_ASSIGNMENT: {
      return { ...state, permissionedDenied: action.status };
    }
    default:
      return state;
  }
}

