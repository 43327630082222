import { UPDATE_QUESTION_PAPER_COMPLEXITY } from '../actions';

const initialState = {
  difficultyDistribution: {},
  difficultyLevel: '',
  repeatedQuestionsCount: 0,
  previousYearQuestions: 0,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case UPDATE_QUESTION_PAPER_COMPLEXITY: {
      return {
        ...state,
        difficultyDistribution: action.payload.difficulty_insights.difficulty_distribution,
        difficultyLevel: action.payload.difficulty_insights.paper_difficulty,
        repeatedQuestionsCount: action.payload.repeated_questions_count,
        previousYearQuestions: action.payload.previous_questions,
      };
    }
    default:
      return state;
  }
}
