import React from "react";
import { withRouter } from "react-router-dom";
import axios from "axios";
import { isMobileDevice } from "../../helpers/util";
import Modal from "../modal/baseModal";
import NOCFormat1 from "./nocFormat1";
import NOCFormat2 from "./nocFormat2";

const IS_MOBILE = isMobileDevice();
const NUMBERS = [
  '9242881899', '9886725516', '7349744115', '7349744107', '9398087347', '9743452486',
  '7349744119', '7032819855', '7625040734', '7411268907', '8431904427', '9008798844',
  '7349744119', '7625040738', '7349744104', '7625040739',
];

class NOC extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      email: "",
      address: "",
      proofNumber: "",
      phoneNumber: "",
      affliation: "",
      poi: "",
      format: "",
      showModal: false,
      fullName: "",
      errorMessage: "",
    };
  }

  handleFormSubmit(event) {
    event.preventDefault();
    const {
      email,
      fullName,
      address,
      proofNumber,
      phoneNumber,
      affliation,
      poi,
    } = this.state;
    if (NUMBERS.includes(phoneNumber)) {
      this.setState({ errorMessage: "Please provide valid phone number" });
      return;
    }
    this.setState({ showModal: true });
    const data = {
      FullName: fullName,
      Document: poi,
      Phone: phoneNumber,
      Email: email,
      Affiliation: affliation,
      Address: address,
      Number: proofNumber,
    };

    axios
      .post(
        "https://sheet.best/api/sheets/b14aa86b-b0dd-44af-a5db-beee16cfd582",
        data
      )
      .then((response) => {});
  }

  renderForm() {
    const labelStyle = {
    };
    const containerStyle = {
      margin: "10px 0px",
      alignItems: "center",
    };
    const {
      email,
      fullName,
      address,
      proofNumber,
      phoneNumber,
      affliation,
      poi,
      format,
    } = this.state;
    return (
      <div style={{ margin: "0 70px" }}>
        <form onSubmit={(event) => this.handleFormSubmit(event)}>
          <div style={containerStyle}>
            <label style={labelStyle}>
              Email
              <sup style={{ color: "red", fontSize: 14 }}>*</sup>
            </label>
            <input
              type="email"
              value={email}
              onChange={(event) => {
                this.setState({ email: event.target.value });
              }}
              style={{
                marginLeft: 10,
                width: IS_MOBILE ? 150 : 200,
              }}
              required
              aria-required="true"
            />
          </div>
          <div style={containerStyle}>
            <label style={labelStyle}>
              Full Name as per Proof Of Identity
              <sup style={{ color: "red", fontSize: 14 }}>*</sup>
            </label>
            <input
              value={fullName}
              onChange={(event) => {
                this.setState({ fullName: event.target.value });
              }}
              style={{
                marginLeft: 10,
                width: IS_MOBILE ? 150 : 200,
              }}
              required
              maxLength={25}
              aria-required="true"
            />
          </div>
          <div style={containerStyle}>
            <label style={labelStyle}>
              Address as per Proof Of Identity Document
              <sup style={{ color: "red", fontSize: 14 }}>*</sup>
            </label>
            <input
              value={address}
              onChange={(event) => {
                this.setState({ address: event.target.value });
              }}
              style={{
                marginLeft: 10,
                width: IS_MOBILE ? 150 : 200,
              }}
              type="text"
              maxLength={100}
              required
              aria-required="true"
            />
          </div>
          <div style={containerStyle}>
            <label style={labelStyle}>
              Type of Proof Of Identity Document (pls note PAN CARD is not a
              valid Proof Of Identity Document )
              <sup style={{ color: "red", fontSize: 14 }}>*</sup>
            </label>
            <div style={{ display: "flex" }}>
              <input
                type="radio"
                onChange={(event) => this.setState({ poi: event.target.value })}
                name="poi_type"
                value="AADHAR_CARD"
                required
                checked={poi === "AADHAR_CARD"}
              />
                <label>AADHAR CARD</label>
            </div>
            <div style={{ display: "flex" }}>
              <input
                type="radio"
                onChange={(event) => this.setState({ poi: event.target.value })}
                name="poi_type"
                value="DRIVING_LICENSE"
                checked={poi === "DRIVING_LICENSE"}
              />
                <label>DRIVING LICENSE</label>
            </div>
            <div style={{ display: "flex" }}>
              <input
                type="radio"
                onChange={(event) => this.setState({ poi: event.target.value })}
                name="poi_type"
                value="VOTER_ID"
                checked={poi === "VOTER_ID"}
              />
                <label>VOTER's ID</label>
            </div>
            <div style={{ display: "flex" }}>
              <input
                type="radio"
                onChange={(event) => this.setState({ poi: event.target.value })}
                name="poi_type"
                value="PASSPORT"
                checked={poi === "PASSPORT"}
              />
                <label>PASSPORT</label>
            </div>
          </div>
          <div style={containerStyle}>
            <label style={labelStyle}>
              Number of Proof Of Identity Document (pls note PAN CARD is not a
              valid Proof Of Identity Document )
              <sup style={{ color: "red", fontSize: 14 }}>*</sup>
            </label>
            <input
              value={proofNumber}
              onChange={(event) => {
                this.setState({ proofNumber: event.target.value });
              }}
              style={{
                marginLeft: 10,
                width: IS_MOBILE ? 150 : 200,
              }}
              type="text"
              required
              aria-required="true"
            />
          </div>
          <div style={containerStyle}>
            <label style={labelStyle}>
              Phone number to be migrated
              <sup style={{ color: "red", fontSize: 14 }}>*</sup>
            </label>
            <input
              value={phoneNumber}
              onChange={(event) => {
                this.setState({ phoneNumber: event.target.value });
              }}
              style={{
                marginLeft: 10,
                width: IS_MOBILE ? 150 : 200,
              }}
              type="tel"
              pattern="[1-9]{1}[0-9]{9}"
              title="The phone number must be 10 digits"
              required
              aria-required="true"
            />
          </div>
          <div style={containerStyle}>
            <label style={labelStyle}>
              Affiliation<sup style={{ color: "red", fontSize: 14 }}>*</sup>
            </label>
            <div style={{ display: "flex" }}>
              <input
                onChange={(event) =>
                  this.setState({ affliation: event.currentTarget.value })
                }
                type="radio"
                name="affliation"
                value="Student"
                required
                checked={affliation === "Student"}
              />
                <label>Student</label>
            </div>
            <div style={{ display: "flex" }}>
              <input
                onChange={(event) =>
                  this.setState({ affliation: event.target.value })
                }
                type="radio"
                name="affliation"
                value="Teacher"
                checked={affliation === "Teacher"}
              />
                <label>Teacher</label>
            </div>
          </div>
          <div style={containerStyle}>
            <label style={labelStyle}>
              NOC Format<sup style={{ color: "red", fontSize: 14 }}>*</sup>
            </label>
            <div style={{ display: "flex" }}>
              <input
                onChange={(event) =>
                  this.setState({ format: event.currentTarget.value })
                }
                type="radio"
                name="noc_format"
                value="NOC"
                checked={format === "NOC"}
              />
                <label>NOC</label>
            </div>
            <div style={{ display: "flex" }}>
              <input
                onChange={(event) =>
                  this.setState({ format: event.currentTarget.value })
                }
                type="radio"
                name="noc_format"
                value="ENOC"
                checked={format === "ENOC"}
              />
                <label>ENOC</label>
            </div>
          </div>
          {this.state.errorMessage && (
            <div style={{...containerStyle,color:'red'}}>{this.state.errorMessage} </div>
          )}
          <div style={containerStyle}>
            By clicking on the submit button ,I undertake to indemnify IDEABOARD
            or ENVISION.BLR.LLP against any claims or disputes arising out of
            the transfer of the said connection, including but not restricted to
            legal cases, claims for damages etc.I therefore request you to
            transfer the said mobile connection from the name of Blr Envision
            Academy LLP to my name. I also declare that i have not used the sim
            card provided for any marketing related or any unlawful activities.
          </div>
          <div
            style={{
              display: "flex",
              marginTop: 20,
              marginLeft: IS_MOBILE ? 50 : 110,
            }}
          >
            <button
              type="submit"
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                width: IS_MOBILE ? 100 : 222,
                height: 35,
                backgroundColor: "#152a53",
                border: "none",
                color: "#FFFFFF",
                borderRadius: 4,
                lineHeight: 0,
                fontSize: 14,
                cursor: "pointer",
              }}
            >
              Submit
            </button>
          </div>
        </form>
      </div>
    );
  }

  renderModal() {
    return (
      <Modal
        showModal={this.state.showModal}
        handleCloseModal={() => this.setState({ showModal: false })}
        height={"90%"}
      >
        {this.state.format === "NOC" ? (
          <NOCFormat1 data={this.state} />
        ) : (
          <NOCFormat2 data={this.state} />
        )}
      </Modal>
    );
  }

  render() {
    return (
      <div>
        <div style={{ textAlign: "center", fontSize: 23, fontWeight: 400 }}>
          JIO DLT NOC
        </div>
        {this.renderForm()}
        {this.state.showModal && this.renderModal()}
      </div>
    );
  }
}

export default NOC;
