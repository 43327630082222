import React from 'react';
import PropTypes from 'prop-types';
import ResizeObserver from 'react-resize-observer';
import { QUESTION_PAPER_VIEW_TYPE } from '../../constants';
import { sendEvent } from '../../helpers/Analytics';
import Constants from '../../shared/constants';

const SOLUTION_TYPES = {
  TEXT: 'Text',
  VIDEO: 'Video',
};
const optionsArray = [1, 2, 3, 4];

class QuestionView extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showSolution: '',
      betterFit: null,
    };
    this.changeShowSolutionState = this.changeShowSolutionState.bind(this);
  }

  resizeObserver(question, maxAllowedWidthForOptions) {
    return (
      <ResizeObserver
        onResize={(rect) => {
          if (rect.width > (maxAllowedWidthForOptions)) {
            this.renderBetterFit(question);
          }
        }}
      />
    );
  }

  showNumericalNotes() {
    const { isNumericalQuestion, question } = this.props;
    if (isNumericalQuestion && question.numerical_notes && question.numerical_notes.length > 0) {
      return (
        <div style={{ color: '#9EA0A5' }}>
          (Note : The answer should be
          {question.numerical_notes}
          )
        </div>
      );
    }
    return <div />;
  }

  changeShowSolutionState(value) {
    const { showSolution } = this.state;
    const { pageType, question } = this.props;
    let showSolutionText = '';
    if (showSolution !== value) {
      showSolutionText = value;
    }
    this.setState({ showSolution: showSolutionText }, () => {
      if (showSolution) {
        this.props.questionSolutionViewed(question.id);
        sendEvent('Assignment', 'ShowSolution', 'Click', { pageType });
      }
    });
  }

  showHideSolution(value) {
    const { showSolution } = this.state;
    const text = (showSolution === value)? `Hide ${value} Solution` : `Show ${value} Solution`;
    return (
      <div style={{ marginTop: 20 }}>
        <u className="cursor" style={{ color: '#6FBF8F', fontWeight: '500' }} onClick={() => this.changeShowSolutionState(value)}>{text}</u>
      </div>
    );
  }

  solution() {
    const { printPage, index, question } = this.props;
    const { solution_text, solution_supporting_picture, video_solution_uri } = question;
    if (!solution_text && !solution_supporting_picture) {
      return <div />;
    }
    if (printPage) {
      const { showSolution } = this.props;
      return (
        <tr>
          {this.renderIndex(index)}
          <td>
            {this.solutionView(showSolution)}
          </td>
        </tr>
      );
    }
    const { showSolution } = this.state;
    return (
      <div>
        <div style={{ display: 'flex', marginBottom: 15 }}>
          <div style={{ marginRight: 50 }}>
            {this.showHideSolution(SOLUTION_TYPES.TEXT)}
          </div>
          {video_solution_uri && (
            <div>
              {this.showHideSolution(SOLUTION_TYPES.VIDEO)}
            </div>
          )}
        </div>
        {this.solutionView(showSolution)}
      </div>
    );
  }

  solutionView(showSolution) {
    const {
      mobileView, question, fontSize, fontFamily, columnFormat,
    } = this.props;
    const oneColumnViewSolutionStyle = {
      height: showSolution ? 'auto' : 0, backgroundColor: '#000000080', fontSize: fontSize || 16, fontFamily: fontFamily || 'sans-serif',
    };
    const twoColumnViewSolutionStyle = {
      maxWidth: 400, eight: showSolution ? 'auto' : 0, margin: 0, fontSize: fontSize || 16, fontFamily: fontFamily || 'sans-serif', whiteSpace: 'pre-line', borderBottom: '1px solid #ddd', marginTop: 15, paddingBottom: 15,
    };
    const videoId = question.video_solution_uri ? question.video_solution_uri.split('/').pop() : '';
    const videoHeight = 150;
    const videoWidth = 335;
    return (
      <>
        <div className={(showSolution === SOLUTION_TYPES.TEXT || showSolution === true) ? 'row' : 'row d-none'} style={columnFormat === QUESTION_PAPER_VIEW_TYPE.TWO_COLUMN ? twoColumnViewSolutionStyle : oneColumnViewSolutionStyle}>
          <div className="ml-5" style={{ pageBreakInside: 'auto', whiteSpace: 'pre-line', marginBottom: 10 }}>{question.solution_text}</div>
          {(question.solution_supporting_picture)
            && (
              <div className="d-flex justify-content-center mt-3">
                <div>
                  <img
                    src={question.solution_supporting_picture}
                    alt="Solution Supporting Picture"
                    style={{
                      display: 'block', width: (question.image_widths.solution_supporting_picture_width && !mobileView) ? question.image_widths.solution_supporting_picture_width : '140', height: 'auto', maxHeight: 300, objectFit: 'contain', maxWidth: 250,
                    }}
                  />

                </div>
              </div>
            )}
        </div>
        <div className={(showSolution === SOLUTION_TYPES.VIDEO) ? '' : 'row d-none'}>
          {question.video_solution_uri && showSolution
            && (
              <div style={{ borderRadius: 4, height: videoHeight, width: videoWidth }}>
                <iframe title="Question Video Solution" src={`https://player.vimeo.com/video/${videoId}#toolbar=0`} frameBorder="0" allow="autoplay; fullscreen" style={{ width: videoWidth, height: videoHeight, borderRadius: 4 }} allowFullScreen />
              </div>
            )}
          <div style={{ marginTop: 5, display: 'flex', padding: '15px 0px' }}>
            Are you interested in creating video solutions for us ?
            <div style={{ fontWeight: 'bold' }}>
              &nbsp;Call&nbsp;
              {Constants().hrContact}
              ‬.
            </div>
          </div>
        </div>
      </>
    );
  }

  optionsLayout(question) {
    const { mobileView } = this.props;
    const { betterFit } = this.state;
    const layout = question.options_layout;
    const imageWidth = (question.image_widths && !mobileView) ? question.image_widths : {};
    if (mobileView || layout === 4 || betterFit === 4) {
      return this.renderFourLayout(question, imageWidth);
    }
    if ((!layout || layout < 2) && !betterFit) {
      return this.renderSingleLineLayout(question, imageWidth);
    }
    if (layout === 2 || betterFit === 2) {
      this.renderDoubleLineLayout(question, imageWidth);
    }
    return this.renderFourLayout(question, imageWidth);
  }

  renderSingleLineLayout(question, imageWidth) {
    const { mobileView } = this.props;
    return (
      <div className="d-flex justify-content-start" style={{ marginTop: mobileView ? '30px' : '0px' }}>
        {optionsArray.map((i) => <div className="mr-4" key={`option_${i}_${question.id}`}>{this.renderOptions(question[`option_${i}`], question[`image_option_${i}`], imageWidth[`image_option_width_${i}`] ? imageWidth[`image_option_width_${i}`] : 140, i, question.answers.includes(i))}</div>)}
      </div>
    );
  }

  renderDoubleLineLayout(question, imageWidth) {
    return (
      <>
        <tr>
          {optionsArray.map((i) => {
            if (i <= 2) {
              return (<td style={{ paddingRight: 20 }} key={`option_${i}_${question.id}`}>{this.renderOptions(question[`option_${i}`], question[`image_option_${i}`], imageWidth[`image_option_width_${i}`] ? imageWidth[`image_option_width_${i}`] : 140, i, question.answers.includes(i))}</td>);
            }
          })}
        </tr>
        <tr>
          {optionsArray.map((i) => {
            if (i > 2) {
              return (<td key={`option_${i}_${question.id}`}>{this.renderOptions(question[`option_${i}`], question[`image_option_${i}`], imageWidth[`image_option_width_${i}`] ? imageWidth[`image_option_width_${i}`] : 140, i, question.answers.includes(i))}</td>);
            }
          })}
        </tr>
      </>
    );
  }

  renderFourLayout(question, imageWidth) {
    const { mobileView } = this.props;
    return (
      <div style={{ marginTop: mobileView ? '30px' : '0px' }}>
        {optionsArray.map((i) => <div key={`option_${i}_${question.id}`}>{this.renderOptions(question[`option_${i}`], question[`image_option_${i}`], imageWidth[`image_option_width_${i}`] ? imageWidth[`image_option_width_${i}`] : 140, i, question.answers.includes(i))}</div>)}
      </div>
    );
  }

  renderBetterFit(question) {
    const { betterFit } = this.state;
    let nextBetterFit = null;
    const currentLayout = betterFit || question.options_layout;
    if (!currentLayout || currentLayout < 2) nextBetterFit = 2;
    else nextBetterFit = 4;
    this.setState({ betterFit: nextBetterFit });
  }

  renderOptions(text, image, imageWidth, optionIndex, highlightAnswer) {
    const { mobileView, showAnswer } = this.props;
    const margin = 5;
    if (image) {
      return (
        <div className="row" style={{ margin }}>
          <span style={{ fontWeight: showAnswer && highlightAnswer ? 'bold' : 'normal' }}>
            {optionIndex}
            &#41;
          </span>
          <img alt={`Option-${optionIndex}`} src={image} width={imageWidth} height="auto" />
        </div>
      );
    }
    return (
      <div className={mobileView ? 'card-view' : ''} style={{ margin }}>
        <span style={{ fontWeight: showAnswer && highlightAnswer ? 'bold' : 'normal' }}>
          {optionIndex}
          &#41;
          {text}
        </span>
      </div>
    );
  }

  renderIndex(index, rowSpan = 1) {
    const {
      mobileView, showAnswer, question, printPage,
    } = this.props;
    if (index || index === 0) {
      return (
        <td
          rowSpan={rowSpan}
          style={{
            verticalAlign: 'top',
            width: `${mobileView ? 20 : 35}`,
            borderTop: 0,
            paddingTop: `${mobileView ? 20 : 0}`,
            paddingRight: 5,
          }}
        >
          {(showAnswer && printPage) && `${question.id} - `}
          {index + 1}
          )
        </td>
      );
    }
    return <div />;
  }

  renderQuestionViewComponents(question, index) {
    const {
      mobileView, maxAllowedWidthForOptions, showOnlyQuestionText, isNumericalQuestion,
    } = this.props;
    if (question.image_widths.supporting_picture_position === 'center' || mobileView) {
      return (
        <>
          <tr>
            {this.renderIndex(index, 3)}
            <td className="question-analysis" style={{ whiteSpace: 'pre-line', borderTop: 0 }}>
              <div className={mobileView ? 'card-view' : ''}>
                {question.question_text}
              </div>
              {this.showNumericalNotes()}
            </td>
          </tr>
          <tr>
            <td className="question-analysis" style={{ textAlign: 'center' }}>
              <img alt="Supporting Picture" src={question.supporting_picture} width={(question.image_widths.supporting_picture_width && !mobileView) ? question.image_widths.supporting_picture_width : '140'} height="auto" />
            </td>
          </tr>
          <tr style={{ display: (showOnlyQuestionText || isNumericalQuestion) && 'none' }}>
            <td className="question-analysis">
              <div style={{ width: 'fit-content', position: 'relative' }}>
                {this.optionsLayout(question)}
                {maxAllowedWidthForOptions
                  && this.resizeObserver(question, maxAllowedWidthForOptions)}
              </div>
            </td>
          </tr>
        </>
      );
    }
    return (
      <tr>
        {this.renderIndex(index)}
        <td style={{ borderTop: 0, verticalAlign: 'top' }}>
          <div className={mobileView ? 'card-view' : ''}>
            {question.question_text}
          </div>
          {this.showNumericalNotes()}
          <div style={{ display: (isNumericalQuestion || showOnlyQuestionText) && 'none' }}>
            {this.optionsLayout(question)}
            {maxAllowedWidthForOptions && this.resizeObserver(
              question, maxAllowedWidthForOptions - question.image_widths.supporting_picture_width,
            )}
          </div>
        </td>
        <td style={{ verticalAlign: 'top', paddingLeft: 20, display: showOnlyQuestionText && 'none' }}>
          <img alt="Supporting Picture" src={question.supporting_picture} width={(question.image_widths.supporting_picture_width && !mobileView) ? question.image_widths.supporting_picture_width : '140'} height="auto" />
        </td>
      </tr>
    );
  }

  renderQuestionView() {
    const {
      mobileView, question, index, showOnlyQuestionText,
      maxAllowedWidthForOptions, isNumericalQuestion,
    } = this.props;
    if (question.supporting_picture) {
      return this.renderQuestionViewComponents(question, index);
    }
    return (
      <>
        <tr>
          {this.renderIndex(index, 2)}
          <td className="question-analysis" style={{ verticalAlign: 'top', whiteSpace: 'pre-line' }}>
            <div className={mobileView && 'card-view'}>
              {question.question_text}
            </div>
            {this.showNumericalNotes()}
          </td>
        </tr>
        <tr style={{ marginTop: mobileView ? '30px' : '0px', display: (showOnlyQuestionText || isNumericalQuestion) && 'none' }}>
          <td className="question-analysis">
            <div style={{ width: 'fit-content', position: 'relative' }}>
              {this.optionsLayout(question)}
              {maxAllowedWidthForOptions && this.resizeObserver(question, maxAllowedWidthForOptions)}
            </div>
          </td>
        </tr>
      </>
    );
  }

  renderMobileView(question) {
    const {
      index, showOnlyQuestionText, showSolution, isNumericalQuestion,
    } = this.props;
    const optionsArray = [1, 2, 3, 4];
    const imageWidth = question.image_widths ? question.image_widths : {};
    return (
      <>
        <tr>
          {this.renderIndex(index, 2)}
          <td style={{ verticalAlign: 'top', whiteSpace: 'pre-line' }}>
            {question.question_text}
            {question.supporting_picture && (
              <div style={{ display: 'flex', justifyContent: 'center' }}>
                <img alt="Supporting Picture" src={question.supporting_picture} width={(imageWidth.supporting_picture_width < (window.innerWidth / 2)) ? imageWidth.supporting_picture_width : (window.innerWidth / 2)} height="auto" />
              </div>
            )}
          </td>
        </tr>
        <tr style={{ display: (showOnlyQuestionText || isNumericalQuestion) && 'none' }}>
          {optionsArray.map((i) => (
            <div style={{ display: 'flex' }} key={`option_${i}_${question.id}`}>
              {this.renderOptions(question[`option_${i}`], question[`image_option_${i}`], imageWidth[`image_option_width_${i}`] ? imageWidth[`image_option_width_${i}`] : 140, i, question.answers.includes(i))}
            </div>
          ))}
        </tr>
        <tr style={{ display: !showSolution && 'none' }}>
          <td colSpan="2">
            <div style={{ fontWeight: 'bold', marginTop: 10 }}> Solution: </div>
            {this.solutionView(showSolution)}
          </td>
        </tr>
      </>
    );
  }

  render() {
    const {
      showSolution, fontSize, printPage, question, marginValue,
      fontFamily, subjectNameDetails, isMobileView, sortPage, sectionInfoData,
    } = this.props;
    const { id } = question;
    if (isMobileView) {
      return (
        <table key={`QuestionView${id}`} className={marginValue}>
          {this.renderMobileView(question)}
        </table>
      );
    }
    return (
      <React.Fragment key={`QuestionView${id}`}>
        <div style={{ fontSize, fontFamily }} className={`font-${fontFamily.split(' ').join('-').toLowerCase()}`}>
          {((printPage && !showSolution) || !printPage)
            && (
              <>
                {subjectNameDetails && subjectNameDetails.subjectName
                  && (
                    <div style={{
                      fontSize: fontSize ? fontSize + 10 : 26, fontWeight: 'bold', textDecoration: 'underline', pageBreakBefore: subjectNameDetails.addPageBreak && 'always',
                    }}
                    >
                      {subjectNameDetails.subjectName}
                    </div>
                  )}
                {sectionInfoData.showSectionInfo && (
                  <div
                    style={{
                      border: '1px solid black',
                      borderRadius: 10,
                      padding: 5,
                      margin: 5,
                      textAlign: 'center',
                    }}
                  >
                    <div style={{ fontSize: 20, fontWeight: 'bold' }}>
                      {sectionInfoData.sectionHeaderTitle}
                    </div>
                    <div>{sectionInfoData.sectionInstruction}</div>
                  </div>
                )}
                <table className={marginValue}>
                  <tbody>
                    {this.renderQuestionView()}
                  </tbody>
                </table>
              </>
            )}
        </div>
        {(sortPage || showSolution) && this.solution()}
      </React.Fragment>
    );
  }
}

QuestionView.propTypes = {
  questionSolutionViewed: PropTypes.func.isRequired,
  question: PropTypes.object,
  subjectNameDetails: PropTypes.object,
  showSolution: PropTypes.bool,
  fontSize: PropTypes.number,
  printPage: PropTypes.bool,
  marginValue: PropTypes.string,
  fontFamily: PropTypes.string,
  isMobileView: PropTypes.bool,
  sortPage: PropTypes.bool,
  index: PropTypes.number,
  showOnlyQuestionText: PropTypes.bool,
  isNumericalQuestion: PropTypes.bool,
  mobileView: PropTypes.bool,
  maxAllowedWidthForOptions: PropTypes.number,
  showAnswer: PropTypes.bool,
  pageType: PropTypes.number,
  columnFormat: PropTypes.number,
  sectionInfoData: PropTypes.object,
};

QuestionView.defaultProps = {
  question: { image_widths: {} },
  subjectNameDetails: {},
  showSolution: false,
  fontSize: 16,
  printPage: false,
  marginValue: 'mt-2 mb-2',
  fontFamily: 'sans-serif',
  isMobileView: false,
  sortPage: false,
  index: 0,
  pageType: 0,
  showOnlyQuestionText: false,
  isNumericalQuestion: false,
  mobileView: false,
  maxAllowedWidthForOptions: null,
  showAnswer: false,
  columnFormat: 1,
  sectionInfoData: {},
};
export default QuestionView;
