import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { createBrowserHistory } from "history";
import { withRouter } from "react-router-dom";
import {
  getEmployeeData,
  terminateEmployee,
  addEmployeeNotes,
  updateEmployeeNotes,
  updateEmployeeData,
  addEmployeeAccounts,
  updateEmployeeAccounts,
  updateEmployeeSalary,
  updateSalarySplitProps,
  updateEmployeeSalarySplit,
} from "../../actions";
import "./hrBoard.css";
import AddEmployee from "./addEmployee";
import Modal from "react-responsive-modal";
import Loader from "../loading";
import moment from "moment";
import { convertToCurrencyFormat } from "../../helpers/util";
import { ContinuousSizeLegend } from "react-vis";

const history = createBrowserHistory({
  forceRefresh: true,
});

class EmployeeProfile extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showEditModal: false,
      showAccountsModal: false,
      showNotesModal: false,
      newNotes: "",
      selectedNote: null,
      newAccountHolderName: "",
      newAccountNumber: "",
      newIfscCode: "",
      newBankName: "",
      selectedBankAccount: null,
      showUpdateSalaryModal: false,
      newSalary: null,
      newSalaryEffectedOn: null,
      showTerminationModal: false,
      terminationDate: null,
      showSalarySplitModal: false,
    };
    this.updateEmployeeDetails = this.updateEmployeeDetails.bind(this);
  }

  componentDidMount() {
    this.props.getEmployeeData(this.props.employeeId);
  }

  updateEmployeeDetails(
    employeeBadgeId,
    firstName,
    lastName,
    jobTitle,
    subject,
    organization,
    campuses,
    personalPhone,
    department,
    gender,
    pan,
    fatherName,
    currentAddressLineOne,
    currentAddressLineTwo,
    currentAddressCity,
    currentAddressPinCode,
    permanentAddressLineOne,
    permanentAddressLineTwo,
    permanentAddressCity,
    permanentAddressPinCode,
    bloodGroup,
    dateOfJoining,
    workEmail,
    emergencyContactName,
    emergencyContactNumber,
    emergencyContactRelationship,
    currentSalary,
    status,
    personalEmail,
    workPhone,
    // picture,
    dateOfBirth
  ) {
    this.props
      .updateEmployeeData(
        employeeBadgeId,
        firstName,
        lastName,
        jobTitle,
        subject,
        organization,
        campuses,
        personalPhone,
        department,
        gender,
        pan,
        fatherName,
        currentAddressLineOne,
        currentAddressLineTwo,
        currentAddressCity,
        currentAddressPinCode,
        permanentAddressLineOne,
        permanentAddressLineTwo,
        permanentAddressCity,
        permanentAddressPinCode,
        bloodGroup,
        dateOfJoining,
        workEmail,
        emergencyContactName,
        emergencyContactNumber,
        emergencyContactRelationship,
        currentSalary,
        status,
        personalEmail,
        workPhone,
        this.props.employeeId,
        // picture,
        dateOfBirth
      )
      .then((response) => {
        if (response.success) {
          this.setState({ showEditModal: false });
        }
      });
  }

  renderEmployeeNotes() {
    const { employee } = this.props;
    return (
      <>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <div className="heading-1">Notes:</div>
          {this.renderNotesButton()}
        </div>
        {employee.notes && employee.notes.length > 0 ? (
          <div style={{ padding: "15px 0" }}>
            {employee.notes.map((note) => {
              return (
                <div className="notes-card" key={note.id}>
                  <div className="bank-account-container">
                    <div className="bank-account-row">
                      <i
                        className="fa fa-edit"
                        style={{ cursor: "pointer", fontSize: 20 }}
                        onClick={() =>
                          this.setState({
                            showNotesModal: true,
                            selectedNote: note.id,
                            newNotes: note.notes,
                          })
                        }
                      />
                    </div>
                    <div>
                      <span className="heading-2" style={{ marginRight: 5 }}>
                        Created at:{" "}
                      </span>
                      {moment(note.created_at).format("DD-MM-YYYY")}
                    </div>
                    <div>
                      <span className="heading-2" style={{ marginRight: 5 }}>
                        Creator:{" "}
                      </span>
                      {note.creator_name}
                    </div>
                    <div>{note.notes}</div>
                  </div>
                </div>
              );
            })}
          </div>
        ) : (
          <span
            className="heading-2"
            style={{
              display: "flex",
              justifyContent: "center",
              margin: "30px",
            }}
          >
            No notes present for employee
          </span>
        )}
      </>
    );
  }

  renderEmployeeBankAccounts() {
    const { employee } = this.props;
    return (
      <>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <div className="heading-1">Bank Accounts:</div>
          <div style={{ display: "flex" }}>
            {employee.bank_accounts &&
              employee.bank_accounts.length > 1 &&
              this.renderSalarySplitButton()}
            {this.renderManageAccountsButton()}
          </div>
        </div>
        {employee.bank_accounts && employee.bank_accounts.length > 0 ? (
          <div
            style={{
              padding: "15px 0",
              display: "flex",
              flexDirection: "row",
              flexWrap: "wrap",
            }}
          >
            {employee.bank_accounts.map((account) => {
              return (
                <div className="bank-account-card" key={account.id}>
                  <div className="bank-account-container">
                    <div className="bank-account-row">
                      <i
                        className="fa fa-edit"
                        style={{ cursor: "pointer", fontSize: 20 }}
                        onClick={() =>
                          this.setState({
                            showAccountsModal: true,
                            selectedBankAccount: account.id,
                            newAccountHolderName: account.account_holder_name,
                            newAccountNumber: account.account_number,
                            newIfscCode: account.ifsc_code,
                            newBankName: account.bank_name,
                          })
                        }
                      />
                    </div>
                    <div className="bank-account-row">
                      <span className="heading-2" style={{ marginRight: 5 }}>
                        Account Holder:{" "}
                      </span>
                      {account.account_holder_name}
                    </div>
                    <div className="bank-account-row">
                      <span className="heading-2" style={{ marginRight: 5 }}>
                        Account Number:{" "}
                      </span>
                      {account.account_number}
                    </div>
                    <div className="bank-account-row">
                      <span className="heading-2" style={{ marginRight: 5 }}>
                        IFSC Code:{" "}
                      </span>
                      {account.ifsc_code}
                    </div>
                    <div className="bank-account-row">
                      <span className="heading-2" style={{ marginRight: 5 }}>
                        Bank Name:{" "}
                      </span>
                      {account.bank_name}
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        ) : (
          <span
            className="heading-2"
            style={{
              display: "flex",
              justifyContent: "center",
              margin: "30px",
            }}
          >
            No bank accounts present for employee
          </span>
        )}
      </>
    );
  }

  renderSalaryRevisionHistory() {
    const { employee } = this.props;
    return (
      <div style={{ paddingBottom: 30 }}>
        <div className="heading-1" style={{ marginBottom: 10 }}>
          Salary Revision History:
        </div>
        {employee.salary_revision_history &&
        employee.salary_revision_history.length > 0 ? (
          <table className="table-bordered">
            <thead>
              <tr>
                <th style={{ textAlign: "right" }}>S. no.</th>
                <th>Updated On</th>
                <th>Updated By</th>
                <th style={{ textAlign: "right" }}>Old Salary</th>
                <th style={{ textAlign: "right" }}>New Salary</th>
                <th>Effective From</th>
              </tr>
            </thead>
            <tbody>
              {employee.salary_revision_history.map((revision, index) => {
                return (
                  <tr key={revision.id}>
                    <td style={{ textAlign: "right" }}>{index + 1}</td>
                    <td>{moment(revision.created_at).format("DD/MM/YYYY")}</td>
                    <td>{revision.modifier_name}</td>
                    <td style={{ textAlign: "right" }}>
                      {convertToCurrencyFormat(revision.old_salary)}
                    </td>
                    <td style={{ textAlign: "right" }}>
                      {convertToCurrencyFormat(revision.new_salary)}
                    </td>
                    <td>
                      {revision.effective_from
                        ? moment(revision.effective_from).format("DD/MM/YYYY")
                        : "-"}
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        ) : (
          <span
            className="heading-2"
            style={{
              display: "flex",
              justifyContent: "center",
              margin: "30px",
            }}
          >
            No salary revisions present for employee
          </span>
        )}
      </div>
    );
  }

  renderEmployeeDetails() {
    const { employee } = this.props;
    return (
      <div style={{ margin: "15px 30px" }}>
        <table style={{ width: "100%" }}>
          <style>{`
          .td {
            width: 50%;
          }
          `}</style>
          <tbody>
            {/* <tr>
              <td></td>
              <td>
                <div className="profile-category">
                  <img
                    src={employee.picture || ""}
                    width="150px"
                    height="200px"
                    style={{ margin: 50, border: "1px solid gray" }}
                  />
                </div>
              </td>
            </tr> */}
            <tr>
              <td>
                <div className="profile-category">
                  <span className="heading-1">Employee ID: </span>
                  {employee.employee_badge_id || "-"}
                </div>
              </td>
              <td>
                <div className="profile-category">
                  <span className="heading-1">Name: </span>
                  {employee.first_name + " " + employee.last_name}
                </div>
              </td>
            </tr>
            <tr>
              <td>
                <div className="profile-category">
                  <span className="heading-1">Job Title: </span>
                  {employee.job_title || "-"}
                </div>
              </td>
              <td>
                <div className="profile-category">
                  <span className="heading-1">Subject: </span>
                  {employee.subject || "-"}
                </div>
              </td>
            </tr>
            <tr>
              <td>
                <div className="profile-category">
                  <span className="heading-1">Organization: </span>
                  {employee.organization || "-"}
                </div>
              </td>
              <td>
                <div className="profile-category">
                  <span className="heading-1">Department: </span>
                  {employee.department || "-"}
                </div>
              </td>
            </tr>
            <tr>
              <td>
                <div className="profile-category">
                  <span className="heading-1">Campuses: </span>
                  {employee.campuses
                    ? employee.campuses
                        .map((campus) => {
                          return campus.campus_name;
                        })
                        .join(", ")
                    : "-"}
                </div>
              </td>
              <td>
                <div className="profile-category">
                  <span className="heading-1">Status: </span>
                  {employee.status || "-"}
                </div>
              </td>
            </tr>
            <tr>
              <td>
                <div className="profile-category">
                  <span className="heading-1">Date of Joining: </span>
                  {employee.date_of_joining || "-"}
                </div>
              </td>
              <td>
                <div className="profile-category">
                  <span className="heading-1">Date of Termination: </span>
                  {employee.date_of_termination || "-"}
                </div>
              </td>
            </tr>
            <tr>
              <td>
                <div className="profile-category">
                  <span className="heading-1">Personal Phone: </span>
                  {employee.personal_phone || "-"}
                </div>
              </td>
              <td>
                <div className="profile-category">
                  <span className="heading-1">Personal Email: </span>
                  {employee.personal_email || "-"}
                </div>
              </td>
            </tr>
            <tr>
              <td>
                <div className="profile-category">
                  <span className="heading-1">Work Phone: </span>
                  {employee.work_phone || "-"}
                </div>
              </td>
              <td>
                <div className="profile-category">
                  <span className="heading-1">Work Email: </span>
                  {employee.work_email || "-"}
                </div>
              </td>
            </tr>
            <tr>
              <td>
                <div className="profile-category">
                  <span className="heading-1">Current Salary: </span>
                  {employee.current_salary
                    ? convertToCurrencyFormat(employee.current_salary)
                    : "-"}
                </div>
              </td>
              <td>
                <div className="profile-category">
                  <span className="heading-1">Father's Name: </span>
                  {employee.father_name || "-"}
                </div>
              </td>
            </tr>
            <tr>
              <td>
                <div className="profile-category">
                  <span className="heading-1">PAN Number: </span>
                  {employee.pan || "-"}
                </div>
              </td>
              <td>
                <div className="profile-category">
                  <span className="heading-1">Gender: </span>
                  {employee.gender === false
                    ? "male"
                    : employee.gender === true
                    ? "female"
                    : "-"}
                </div>
              </td>
            </tr>
            <tr>
              <td>
                <div className="profile-category">
                  <span className="heading-1">Current Address: </span>
                  {employee.current_address ? (
                    <div style={{ display: "flex", flexDirection: "column" }}>
                      <span>{employee.current_address.line_one}</span>
                      <span>{employee.current_address.line_two}</span>
                      <span>{employee.current_address.city}</span>
                      <span>{employee.current_address.pin_code}</span>
                    </div>
                  ) : (
                    "-"
                  )}
                </div>
              </td>
              <td>
                <div className="profile-category">
                  <span className="heading-1">Permanent Address: </span>
                  {employee.permanent_address ? (
                    <div style={{ display: "flex", flexDirection: "column" }}>
                      <span>{employee.permanent_address.line_one}</span>
                      <span>{employee.permanent_address.line_two}</span>
                      <span>{employee.permanent_address.city}</span>
                      <span>{employee.permanent_address.pin_code}</span>
                    </div>
                  ) : (
                    "-"
                  )}
                </div>
              </td>
            </tr>
            <tr>
              <td>
                <div className="profile-category">
                  <span className="heading-1">Emergency Contact Name: </span>
                  {employee.emergency_contact_name || "-"}
                </div>
              </td>
              <td>
                <div className="profile-category">
                  <span className="heading-1">Emergency Contact Phone: </span>
                  {employee.emergency_contact_number || "-"}
                </div>
              </td>
            </tr>
            <tr>
              <td>
                <div className="profile-category">
                  <span className="heading-1">
                    Emergency Contact Relationship:{" "}
                  </span>
                  {employee.emergency_contact_relationship || "-"}
                </div>
              </td>
              <td>
                <div className="profile-category">
                  <span className="heading-1">Blood Group: </span>
                  {employee.blood_group || "-"}
                </div>
              </td>
            </tr>
            <tr>
              <td>
                <div className="profile-category">
                  <span className="heading-1">Date of Birth: </span>
                  {employee.date_of_birth || "-"}
                </div>
              </td>
            </tr>
          </tbody>
        </table>
        {this.renderEmployeeBankAccounts()}
        {this.renderEmployeeNotes()}
        {this.renderSalaryRevisionHistory()}
      </div>
    );
  }

  renderEditButton() {
    return (
      <div
        className="Button primary-Button"
        style={{ width: 150, marginLeft: 30 }}
        onClick={() => {
          this.setState({ showEditModal: true });
        }}
      >
        Edit
      </div>
    );
  }

  renderUpdateSalaryButton() {
    return (
      <div
        className="Button primary-Button"
        style={{ width: 150, marginLeft: 30 }}
        onClick={() => {
          this.setState({ showUpdateSalaryModal: true });
        }}
      >
        Revise Salary
      </div>
    );
  }

  handleCloseUpdateSalaryModal() {
    this.setState({
      showUpdateSalaryModal: false,
      newSalary: null,
      newSalaryEffectedOn: null,
    });
  }

  renderUpdateSalaryModal() {
    const { showUpdateSalaryModal, newSalary, newSalaryEffectedOn } =
      this.state;
    const { employee } = this.props;
    return (
      <Modal
        open={showUpdateSalaryModal}
        onClose={() => this.handleCloseUpdateSalaryModal()}
      >
        <div style={{ padding: "30px 15px", height: "100%" }}>
          <div className="heading-1" style={{ marginBottom: 15 }}>
            Salary Revision:
          </div>
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <span style={{ marginRight: 5 }}>New Salary:</span>
            <input
              type="Number"
              defaultValue={newSalary}
              style={{ width: 200 }}
              onChange={(event) =>
                this.setState({ newSalary: event.target.value })
              }
            ></input>
          </div>
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <span style={{ marginRight: 5 }}>Effective from:</span>
            <input
              type="Date"
              defaultValue={newSalaryEffectedOn}
              style={{ width: 200 }}
              onChange={(event) =>
                this.setState({ newSalaryEffectedOn: event.target.value })
              }
            ></input>
          </div>
          <div style={{ float: "right", margin: "10px 0", display: "flex" }}>
            <div
              className="Button secondary-Button"
              style={{ width: 100 }}
              onClick={() => this.handleCloseUpdateSalaryModal()}
            >
              Cancel
            </div>
            <div
              className="Button primary-Button"
              style={{ width: 100, marginLeft: 15 }}
              onClick={() => {
                this.props
                  .updateEmployeeSalary(
                    this.props.employeeId,
                    newSalary,
                    employee.current_salary,
                    newSalaryEffectedOn
                  )
                  .then((response) => {
                    if (response.success) {
                      this.handleCloseUpdateSalaryModal();
                      employee.current_bank_accounts_split &&
                        employee.current_bank_accounts_split.length > 0 &&
                        this.setState({ showSalarySplitModal: true });
                    }
                  });
              }}
            >
              Update
            </div>
          </div>
        </div>
      </Modal>
    );
  }

  renderTerminateButton() {
    const { status } = this.props.employee;
    return (
      <div
        className="Button primary-Button"
        style={{
          width: 150,
          marginLeft: 30,
          backgroundColor: "red",
          border: "none",
          opacity: status === "terminated" && 0.5,
        }}
        onClick={() => {
          if (status !== "terminated") {
            this.setState({ showTerminationModal: true });
          }
        }}
      >
        Terminate
      </div>
    );
  }

  handleCloseTerminationModal() {
    this.setState({ showTerminationModal: false, terminationDate: null });
  }

  renderTerminationModal() {
    const { terminationDate, showTerminationModal } = this.state;
    const { employee } = this.props;
    return (
      <Modal
        open={showTerminationModal}
        onClose={() => this.handleCloseTerminationModal()}
      >
        <div style={{ padding: "30px 15px", height: "100%" }}>
          <div className="heading-1" style={{ marginBottom: 15 }}>
            Terminate Employee:
          </div>
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <span style={{ marginRight: 5 }}>Effective from:</span>
            <input
              type="Date"
              defaultValue={terminationDate}
              style={{ width: 200 }}
              onChange={(event) =>
                this.setState({ terminationDate: event.target.value })
              }
            ></input>
          </div>
          <div style={{ float: "right", margin: "10px 0", display: "flex" }}>
            <div
              className="Button secondary-Button"
              style={{ width: 100 }}
              onClick={() => this.handleCloseTerminationModal()}
            >
              Cancel
            </div>
            <div
              className="Button primary-Button"
              style={{
                width: 100,
                marginLeft: 15,
                backgroundColor: "red",
                borderColor: "red",
              }}
              onClick={() => {
                this.props
                  .terminateEmployee(this.props.employeeId, terminationDate)
                  .then((response) => {
                    if (response.success) {
                      this.handleCloseTerminationModal();
                    }
                  });
              }}
            >
              Terminate
            </div>
          </div>
        </div>
      </Modal>
    );
  }

  renderNotesButton() {
    const { employee } = this.props;
    return (
      <div
        className="Button primary-Button"
        style={{ width: 150, marginLeft: 30 }}
        onClick={() => {
          this.setState({
            showNotesModal: true,
          });
        }}
      >
        Add Note
      </div>
    );
  }

  handleCloseNotesModal() {
    this.setState({ showNotesModal: false, newNotes: "" });
  }

  renderNotesModal() {
    const { showNotesModal, newNotes, selectedNote } = this.state;
    return (
      <Modal
        open={showNotesModal}
        onClose={() => this.handleCloseNotesModal()}
        styles={{ modal: { height: "50vh", width: "60vw" } }}
      >
        <div style={{ padding: "30px 15px", height: "100%" }}>
          <div className="heading-1">Employee Notes:</div>
          <textarea
            defaultValue={newNotes}
            onChange={(event) =>
              this.setState({ newNotes: event.target.value })
            }
            placeholder="Enter notes for employee"
            style={{ height: "60%", width: "100%", marginTop: 15 }}
          ></textarea>
          <div style={{ float: "right", marginTop: 15, display: "flex" }}>
            <div
              className="Button secondary-Button"
              style={{ width: 100 }}
              onClick={() => this.handleCloseNotesModal()}
            >
              Cancel
            </div>
            <div
              className="Button primary-Button"
              style={{ width: 100, marginLeft: 15 }}
              onClick={() => {
                selectedNote
                  ? this.props
                      .updateEmployeeNotes(selectedNote, newNotes)
                      .then((response) => {
                        if (response.success) {
                          this.handleCloseNotesModal();
                        }
                      })
                  : this.props
                      .addEmployeeNotes(this.props.employeeId, newNotes)
                      .then((response) => {
                        if (response.success) {
                          this.handleCloseNotesModal();
                        }
                      });
              }}
            >
              {!selectedNote ? "Add" : "Update"}
            </div>
          </div>
        </div>
      </Modal>
    );
  }

  renderManageAccountsButton() {
    const { employee } = this.props;
    return (
      <div
        className="Button primary-Button"
        style={{ width: 150, marginLeft: 10 }}
        onClick={() => {
          this.setState({
            showAccountsModal: true,
          });
        }}
      >
        Add Account
      </div>
    );
  }

  handleCloseAccountsModal() {
    this.setState({
      showAccountsModal: false,
      newAccountHolderName: "",
      newAccountNumber: "",
      newBankName: "",
      newIfscCode: "",
      selectedBankAccount: null,
    });
  }

  renderManageAccountsModal() {
    const {
      showAccountsModal,
      newAccountHolderName,
      newAccountNumber,
      newBankName,
      newIfscCode,
      selectedBankAccount,
    } = this.state;
    const { employee } = this.props;

    return (
      <Modal
        open={showAccountsModal}
        onClose={() => this.handleCloseAccountsModal()}
      >
        <div style={{ padding: "30px 15px", height: "100%" }}>
          <div className="heading-1" style={{ marginBottom: 15 }}>
            Employee Bank Account:
          </div>
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <span style={{ marginRight: 5 }}>Account Holder Name:</span>
            <input
              type="text"
              defaultValue={newAccountHolderName}
              onChange={(event) =>
                this.setState({ newAccountHolderName: event.target.value })
              }
            ></input>
          </div>
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <span style={{ marginRight: 5 }}>Account Number:</span>
            <input
              type="text"
              defaultValue={newAccountNumber}
              onChange={(event) =>
                this.setState({ newAccountNumber: event.target.value })
              }
            ></input>
          </div>
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <span style={{ marginRight: 5 }}>IFSC Code:</span>
            <input
              type="text"
              defaultValue={newIfscCode}
              onChange={(event) =>
                this.setState({ newIfscCode: event.target.value })
              }
            ></input>
          </div>
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <span style={{ marginRight: 5 }}>Bank Name:</span>
            <input
              type="text"
              defaultValue={newBankName}
              onChange={(event) =>
                this.setState({ newBankName: event.target.value })
              }
            ></input>
          </div>

          <div style={{ float: "right", marginTop: 15, display: "flex" }}>
            <div
              className="Button secondary-Button"
              style={{ width: 100 }}
              onClick={() => this.handleCloseAccountsModal()}
            >
              Cancel
            </div>
            <div
              className="Button primary-Button"
              style={{ width: 100, marginLeft: 15 }}
              onClick={() => {
                selectedBankAccount
                  ? this.props
                      .updateEmployeeAccounts(
                        selectedBankAccount,
                        newAccountHolderName,
                        newAccountNumber,
                        newIfscCode,
                        newBankName
                      )
                      .then((response) => {
                        if (response.success) {
                          this.handleCloseAccountsModal();
                        }
                      })
                  : this.props
                      .addEmployeeAccounts(
                        this.props.employeeId,
                        newAccountHolderName,
                        newAccountNumber,
                        newIfscCode,
                        newBankName
                      )
                      .then((response) => {
                        if (response.success) {
                          this.handleCloseAccountsModal();
                          employee.current_bank_accounts_split &&
                            employee.current_bank_accounts_split.length > 1 &&
                            this.setState({ showSalarySplitModal: true });
                        }
                      });
              }}
            >
              {!selectedBankAccount ? "Add" : "Update"}
            </div>
          </div>
        </div>
      </Modal>
    );
  }

  renderSalarySplitButton() {
    return (
      <div
        style={{
          color: "#1d4c7f",
          cursor: "pointer",
          textDecoration: "underline",
          margin: "auto 0",
        }}
        onClick={() => {
          this.setState({ showSalarySplitModal: true });
        }}
      >
        Split Salary
      </div>
    );
  }

  handleCloseSalarySplitModal() {
    this.props.updateSalarySplitProps(null, null, true).then(() => {
      this.setState({ showSalarySplitModal: false });
    });
  }

  renderSalarySplitModal() {
    const { showSalarySplitModal } = this.state;
    const { employee } = this.props;
    const shares = employee.current_bank_accounts_split.map((acc) => {
      return parseFloat(acc.share);
    });
    const currentSplitSum =
      shares && shares.length > 0
        ? shares.reduce((prev, current) => prev + current)
        : 0;
    return (
      <Modal
        open={showSalarySplitModal}
        onClose={() => this.handleCloseSalarySplitModal()}
        styles={{ modal: { width: "80vw" } }}
      >
        <div style={{ padding: "30px 15px", height: "100%" }}>
          <div className="heading-1">Salary Split:</div>
          <div style={{ width: "100%", textAlign: "center" }}>
            <div>
              <span className="heading-2">Current Salary: </span>
              {convertToCurrencyFormat(employee.current_salary)}
            </div>
            <div>
              <span className="heading-2">Split Salary: </span>
              {convertToCurrencyFormat(currentSplitSum)}
              {"   "}
              {employee.current_salary - currentSplitSum !== 0 && (
                <span style={{ color: "red", fontSize: 14 }}>
                  {convertToCurrencyFormat(
                    employee.current_salary - currentSplitSum
                  )}
                </span>
              )}
            </div>
          </div>
          <div style={{ margin: 15 }}>
            <table className="table-bordered">
              <thead>
                <tr>
                  <th>S. No.</th>
                  <th>Account Number</th>
                  <th>Account Holder Name</th>
                  <th style={{ textAlign: "right" }}>Salary Split</th>
                </tr>
              </thead>
              <tbody>
                {employee.current_bank_accounts_split.map((acc, idx) => {
                  return (
                    <tr key={`bank acc split - ${idx}`}>
                      <td>{idx + 1}</td>
                      <td>{acc.account_number}</td>
                      <td>{acc.account_holder_name}</td>
                      <td>
                        <input
                          type="Number"
                          defaultValue={acc.share}
                          onChange={(event) => {
                            this.props.updateSalarySplitProps(
                              acc.id,
                              event.target.value,
                              false
                            );
                          }}
                          style={{
                            textAlign: "right",
                            display: "flex",
                            float: "right",
                          }}
                        ></input>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
          <div style={{ float: "right", marginTop: 15, display: "flex" }}>
            <div
              className="Button secondary-Button"
              style={{ width: 100 }}
              onClick={() => this.handleCloseSalarySplitModal()}
            >
              Cancel
            </div>
            <div
              className="Button primary-Button"
              style={{
                width: 100,
                marginLeft: 15,
                opacity:
                  parseFloat(employee.current_salary) !==
                    parseFloat(currentSplitSum) && 0.5,
                cursor:
                  parseFloat(employee.current_salary) !==
                    parseFloat(currentSplitSum) && "default",
              }}
              onClick={() => {
                parseFloat(employee.current_salary) ===
                  parseFloat(currentSplitSum) &&
                  this.props
                    .updateEmployeeSalarySplit(
                      employee.id,
                      employee.current_bank_accounts_split
                    )
                    .then((response) => {
                      if (response.success) {
                        this.handleCloseSalarySplitModal();
                      }
                    });
              }}
            >
              Update
            </div>
          </div>
        </div>
      </Modal>
    );
  }

  render() {
    const { employeeId } = this.props;
    const {
      showAccountsModal,
      showEditModal,
      showNotesModal,
      showUpdateSalaryModal,
      showTerminationModal,
      showSalarySplitModal,
    } = this.state;

    if (this.props.isLoading) {
      return <Loader />;
    }

    return (
      <div style={{ marginLeft: "20%", padding: 15, height: "100vh" }}>
        <div
          style={{
            display: "flex",
            justifyContent: "right",
            margin: "15px 30px",
          }}
        >
          {this.renderEditButton()}
          {this.renderUpdateSalaryButton()}
          {this.renderTerminateButton()}
        </div>
        {this.renderEmployeeDetails()}
        {showEditModal && (
          <AddEmployee
            showModal={showEditModal}
            handleCloseModal={() =>
              this.setState({ showEditModal: false, employee: "" })
            }
            updateEmployeeDetails={this.updateEmployeeDetails}
            employee={this.props.employee}
            campuses={this.props.campuses}
          />
        )}
        {showNotesModal && this.renderNotesModal()}
        {showAccountsModal && this.renderManageAccountsModal()}
        {showUpdateSalaryModal && this.renderUpdateSalaryModal()}
        {showTerminationModal && this.renderTerminationModal()}
        {showSalarySplitModal && this.renderSalarySplitModal()}
      </div>
    );
  }
}

EmployeeProfile.propTypes = {
  isLoading: PropTypes.bool,
  employee: PropTypes.object,
};

EmployeeProfile.defaultProps = {
  employee: {},
};

const mapStateToProps = ({ hrBoard, institute }) => ({
  employee: hrBoard.employee,
  isLoading: hrBoard.isLoading,
  campuses: institute.campuses,
});

export default withRouter(
  connect(mapStateToProps, {
    getEmployeeData,
    terminateEmployee,
    addEmployeeNotes,
    updateEmployeeNotes,
    updateEmployeeData,
    addEmployeeAccounts,
    updateEmployeeAccounts,
    updateEmployeeSalary,
    updateSalarySplitProps,
    updateEmployeeSalarySplit,
  })(EmployeeProfile)
);
