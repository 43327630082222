import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import ReactTooltip from 'react-tooltip';
import Cookies from 'universal-cookie';
import Loader from '../loading';
import FeedbackProgressBar from '../feedbackProgressBar';
import FeedbackTable from '../feedbackTable';
import { getQuizFeedback } from '../../actions';

const cookies = new Cookies();
const roles = cookies.get('roles');

class QuizFeedback extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      showLoader: true,
    };
  }

  componentDidMount() {
    const { id, meetId, classroom } = this.props;
    this.props.getQuizFeedback(id, meetId || '', classroom.id || '').then(() => {
      this.setState({ showLoader: false });
    });
  }

  render() {
    const { showLoader } = this.state;
    const { feedbacks, feedbackWidgetData, isAnonymousFeedback } = this.props;
    if (showLoader) {
      return <Loader />;
    }
    return (
      <div style={{ marginTop: 30, marginBottom: 30, fontFamily: 'Roboto' }}>
        <ReactTooltip effect="solid" />
        {feedbacks && feedbacks.length > 0
        && <FeedbackProgressBar feedbackWidgetData={feedbackWidgetData} />}
        <div style={{ marginTop: 60 }}>
          <FeedbackTable
            feedbacks={feedbacks}
            isAnonymousFeedback={roles.is_dean
            && !isAnonymousFeedback}
          />
        </div>
      </div>
    );
  }
}

QuizFeedback.propTypes = {
  feedbackWidgetData: PropTypes.object,
  isAnonymousFeedback: PropTypes.bool,
  isHigherEducation: PropTypes.bool,
  feedbacks: PropTypes.array,
  classroom: PropTypes.object,
};

QuizFeedback.defaultProps = {
  feedbackWidgetData: {},
  feedbacks: [],
  isHigherEducation: false,
  isAnonymousFeedback: true,
  classroom: {},
};

const mapStateToProps = ({ quiz }) => ({
  feedbackWidgetData: quiz.feedbackWidgetData,
  feedbacks: quiz.feedbacks,
  isHigherEducation: quiz.is_higher_education,
  isAnonymousFeedback: quiz.is_anonymous_feedback,
});

export default connect(mapStateToProps, { getQuizFeedback })(QuizFeedback);
