import React, { PureComponent } from "react";
import { connect } from "react-redux";
import Select from "react-select";
import Modal from "../modal/baseModal";
import PropTypes from "prop-types";
import CreatableSelect from "react-select/creatable";
import closeMark from "../../static/images/close-mark.svg";
import { applicantCreation, applicantUpdation } from "../../actions";

const BLOOD_GROUP = {
  "O POSITIVE": 0,
  "O NEGATIVE": 1,
  "A POSITIVE": 2,
  "A NEGATIVE": 3,
  "B POSITIVE": 4,
  "B NEGATIVE": 5,
  "AB POSITIVE": 6,
  "AB NEGATIVE": 7,
};

const GENDER = {
  FEMALE: true,
  MALE: false,
};

const APPLICANT_STATUS = {
  LEAD: 0,
  INTERESTED: 1,
  "ADVANCE PAID": 2,
  ADMITTED: 3,
  CANCELED: 4,
  "NOT INTERESTED": 5,
  JUNK: 6,
};

const customDropDownStyles = {
  control: (base, state) => ({
    ...base,
    height: 35,
    width: 200,
    backgroundColor: "white",
    border: "1px solid #CDCFD6 !important",
  }),
  indicatorsContainer: (base, state) => ({
    ...base,
    height: 35,
  }),
  valueContainer: (base, state) => ({
    ...base,
    height: 40,
  }),
  option: (base, state) => ({
    ...base,
    textAlign: "left",
  }),
};

class AddApplicant extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      applicantFirstName: this.props.applicant.first_name || "",
      applicantLastName: this.props.applicant.last_name || "",
      dob: this.props.applicant.dob || "",
      fatherName: this.props.applicant.father_name || "",
      motherName: this.props.applicant.mother_name || "",
      applicantMobile: this.props.applicant.phone || "",
      fatherMobile: this.props.applicant.father_phone || "",
      motherMobile: this.props.applicant.mother_phone || "",
      previousExamScore: this.props.applicant.previous_exam_score || "",
      selectedBloodGroup: this.props.selectedBloodGroup || {},
      bloodGroup: this.props.applicant.blood_group || "",
      selectedApplicantStatus: this.props.selectedApplicantStatus || {},
      applicantStatus: this.props.applicant.applicant_status || "",
      fatherEmail: this.props.applicant.father_email || "",
      motherEmail: this.props.applicant.mother_email || "",
      applicantEmail: this.props.applicant.email || "",
      program: this.props.applicant.program || "",
      selectedCampus: this.props.selectedCampus || {},
      applicantCampus: this.props.applicant.campus_id || "",
      selectedInstitutesTag: this.props.selectedTags || [],
      selectedInstitutesTagIds: this.props.applicant.tag_ids || [],
      selectedGender: this.props.selectedGender || {},
      gender: this.props.applicant.gender,
      logoUploaded: false,
      picture: "",
      permanentAddressLine1: this.props.permanentAddress?.line_one || "",
      permanentAddressLine2: this.props.permanentAddress?.line_two || "",
      permanentAddressCity: this.props.permanentAddress?.city || "",
      permanentAddressPinCode: this.props.permanentAddress?.pin_code || "",
      currentAddressLine1: this.props.currentAddress?.line_one || "",
      currentAddressLine2: this.props.currentAddress?.line_two || "",
      currentAddressCity: this.props.currentAddress?.city || "",
      currentAddressPinCode: this.props.currentAddress?.pin_code || "",
      showAlert: false,
      validPhone: true,
      validfatherPhone: true,
      validmotherPhone: true,
    };
  }

  toggleModal() {
    const { showEditModal } = this.state;
    this.setState({
      showEditModal: !showEditModal,
    });
  }

  getLogo() {
    document.getElementById("uploadFile").click();
  }

  handleFormSubmit(e) {
    e.preventDefault();
  }

  updateFieldValueChanges(type, value) {
    if (type === "applicantMobile") {
      value = value.replace(" ", "");
      value =
        value && value.match(/^[0-9]*$/g)
          ? value.match(/^[0-9]*$/g).join("")
          : "";
      this.setState({
        validPhone: !value || value.length === 10,
        applicantMobile: value,
      });
    }
    if (type === "fatherMobile") {
      value = value.replace(" ", "");
      value =
        value && value.match(/^[0-9]*$/g)
          ? value.match(/^[0-9]*$/g).join("")
          : "";
      this.setState({
        validfatherPhone: value.length === 10,
        fatherMobile: value,
      });
    }
    if (type === "motherMobile") {
      value = value.replace(" ", "");
      value =
        value && value.match(/^[0-9]*$/g)
          ? value.match(/^[0-9]*$/g).join("")
          : "";
      this.setState({
        validmotherPhone: !value || value.length === 10,
        motherMobile: value,
      });
    }
    this.setState({ [type]: value });
  }

  renderInstituteTags() {
    const { selectedInstitutesTag } = this.state;
    const displayValue = selectedInstitutesTag ? selectedInstitutesTag : [];

    return (
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "flex-start",
        }}
      >
        <div style={{ marginBottom: 5 }} className="heading-3">
          Tags
        </div>
        <CreatableSelect
          options={this.props.tags}
          value={displayValue}
          onChange={(event) => {
            this.setState(
              event === null
                ? { selectedInstitutesTag: [], selectedInstitutesTagIds: [] }
                : {
                    selectedInstitutesTag: event,
                    selectedInstitutesTagIds: event.map((tag) => tag.value),
                  }
            );
          }}
          styles={{
            control: (base, state) => ({
              ...base,
              height: 35,
              width: 200,
              backgroundColor: "white",
              border: "1px solid #CDCFD6 !important",
            }),
            indicatorsContainer: (base, state) => ({
              ...base,
              height: 35,
            }),
            valueContainer: (base, state) => ({
              ...base,
              height: 30,
            }),
            option: (base, state) => ({
              ...base,
              textAlign: "left",
            }),
          }}
          maxMenuHeight={150}
          isMulti
          isClearable
        />
      </div>
    );
  }

  renderInput(
    value = "",
    inputType = "text",
    handleChange = () => {},
    placeholder = ""
  ) {
    return (
      <input
        type={inputType}
        value={value}
        placeholder={placeholder}
        style={{
          border: "none",
          borderBottom: "1px solid #E4E7EE",
          width: "90%",
          outline: "none",
          margin: "5px 0px 0px 0px",
          fontSize: 16,
        }}
        onChange={handleChange}
      />
    );
  }

  async allowNewApplicantCreation() {
    const {
      applicantFirstName,
      applicantLastName,
      applicantMobile,
      fatherMobile,
      fatherName,
      fatherEmail,
      motherMobile,
      motherName,
      dob,
      applicantEmail,
      motherEmail,
      previousExamScore,
      bloodGroup,
      applicantStatus,
      program,
      applicantCampus,
      selectedInstitutesTagIds,
      gender,
      permanentAddressLine1,
      permanentAddressLine2,
      permanentAddressCity,
      permanentAddressPinCode,
      currentAddressLine1,
      currentAddressLine2,
      currentAddressCity,
      currentAddressPinCode,
      validPhone,
      validfatherPhone,
      validmotherPhone,
    } = this.state;
    const allowCreation = validPhone && validfatherPhone && validmotherPhone;
    allowCreation &&
      (await this.props.applicantCreation(
        applicantFirstName,
        applicantLastName,
        applicantMobile,
        fatherMobile,
        fatherName,
        fatherEmail,
        motherMobile,
        motherName,
        dob,
        applicantEmail,
        motherEmail,
        previousExamScore,
        bloodGroup,
        applicantStatus,
        program,
        applicantCampus,
        selectedInstitutesTagIds,
        gender,
        permanentAddressLine1,
        permanentAddressLine2,
        permanentAddressCity,
        permanentAddressPinCode,
        currentAddressLine1,
        currentAddressLine2,
        currentAddressCity,
        currentAddressPinCode
      ));
    allowCreation && window.location.reload();
  }

  async allowUpdateApplicant() {
    const {
      applicantFirstName,
      applicantLastName,
      applicantMobile,
      fatherMobile,
      fatherName,
      fatherEmail,
      motherMobile,
      motherName,
      dob,
      applicantEmail,
      motherEmail,
      previousExamScore,
      bloodGroup,
      applicantStatus,
      program,
      applicantCampus,
      selectedInstitutesTagIds,
      gender,
      permanentAddressLine1,
      permanentAddressLine2,
      permanentAddressCity,
      permanentAddressPinCode,
      currentAddressLine1,
      currentAddressLine2,
      currentAddressCity,
      currentAddressPinCode,
      validPhone,
      validfatherPhone,
      validmotherPhone,
    } = this.state;
    const { applicant } = this.props;
    const allowCreation = validPhone && validfatherPhone && validmotherPhone;
    (await allowCreation) &&
      this.props.applicantUpdation(
        applicant.id,
        applicantFirstName,
        applicantLastName,
        applicantMobile,
        fatherMobile,
        fatherName,
        fatherEmail,
        motherMobile,
        motherName,
        dob,
        applicantEmail,
        motherEmail,
        previousExamScore,
        bloodGroup,
        applicantStatus,
        program,
        applicantCampus,
        selectedInstitutesTagIds,
        gender,
        permanentAddressLine1,
        permanentAddressLine2,
        permanentAddressCity,
        permanentAddressPinCode,
        currentAddressLine1,
        currentAddressLine2,
        currentAddressCity,
        currentAddressPinCode
      );
    allowCreation && window.location.reload();
  }

  renderSubInfo(
    label,
    type,
    value,
    input = false,
    marginBottom = 20,
    required = true,
    inputType = "text",
    autofocus
  ) {
    return (
      <div style={{ marginBottom }}>
        <div className="heading-3">{label}</div>
        {!input && (
          <div className="content-text" style={{ marginTop: 5 }}>
            {value || "-"}
          </div>
        )}
        {input && (
          <input
            type={inputType}
            value={this.state[type]}
            pattern={
              label === "Address"
                ? "[a-zA-Z0-9,- ]"
                : inputType === "text"
                ? "[a-zA-Z0-9 ]+"
                : "[a-z0-9._%+-]+@[a-z0-9.-]+.[a-z]{2,}$"
            }
            style={{
              border: "none",
              borderBottom: "1px solid #E4E7EE",
              width: "90%",
              outline: "none",
            }}
            autofocus={autofocus}
            onChange={(event) =>
              this.updateFieldValueChanges(type, event.target.value)
            }
            required={required}
          />
        )}
      </div>
    );
  }

  renderDropDown(
    filterItems,
    selectedItem,
    selectedFunction,
    item,
    defaultValue = ""
  ) {
    const defaultSelectedValue = defaultValue || { label: item, value: 0 };
    return (
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "flex-start",
        }}
      >
        <div style={{ marginBottom: 5 }} className="heading-3">
          {item}
        </div>
        <Select
          styles={customDropDownStyles}
          value={selectedItem || defaultSelectedValue}
          onChange={selectedFunction}
          options={filterItems}
          placeholder="All"
          maxMenuHeight={150}
          required
        />
      </div>
    );
  }

  renderEdit() {
    const {
      applicantFirstName,
      applicantLastName,
      applicantMobile,
      fatherMobile,
      fatherName,
      fatherEmail,
      motherMobile,
      motherName,
      dob,
      applicantEmail,
      motherEmail,
      previousExamScore,
      selectedBloodGroup,
      selectedApplicantStatus,
      program,
      selectedCampus,
      applicantCampus,
      notes,
      selectedGender,
      gender,
      permanentAddressLine1,
      permanentAddressLine2,
      permanentAddressCity,
      permanentAddressPinCode,
      currentAddressLine1,
      currentAddressLine2,
      currentAddressCity,
      currentAddressPinCode,
      validPhone,
      validfatherPhone,
      validmotherPhone,
      showAlert,
    } = this.state;
    const { campuses } = this.props;
    return (
      <div style={{ padding: 50 }}>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            marginBottom: 30,
          }}
        >
          <div className="heading-1">Applicant Details</div>
        </div>
        <div
          style={{
            display: "flex",
            padding: 10,
            justifyContent: "space-between",
          }}
        >
          <div>
            {this.renderSubInfo(
              " First Name *",
              "applicantFirstName",
              applicantFirstName,
              true,
              20,
              true,
              "text",
              "autofocus"
            )}
          </div>
          <div>
            {this.renderSubInfo(
              " Last Name",
              "applicantLastName",
              applicantLastName,
              true
            )}
          </div>
          <div>
            {this.renderSubInfo(
              "Applicant's Phone",
              "applicantMobile",
              applicantMobile,
              true,
              20,
              false
            )}
            {!validPhone && showAlert && (
              <div style={{ color: "red", marginBottom: 5, fontSize: 14 }}>
                The phone number must be length of 10*
              </div>
            )}
          </div>
          <div>
            {this.renderSubInfo(
              "Applicant's Email",
              "applicantEmail",
              applicantEmail,
              true,
              20,
              false,
              "email"
            )}
          </div>
        </div>
        <div
          style={{
            display: "flex",
            padding: 10,
            justifyContent: "space-between",
          }}
        >
          <div>
            {this.renderSubInfo(
              "Previous Exam Score",
              "previousExamScore",
              previousExamScore,
              true,
              20,
              false,
              "text"
            )}
          </div>
          <div>{this.renderSubInfo("Program", "program", program, true)}</div>
          <div>{this.renderInstituteTags()}</div>
          <div style={{ textAlign: "center" }}>
            <div style={{ alignItems: "center" }}>
              {this.renderDropDown(
                campuses,
                selectedCampus,
                (event) =>
                  this.setState({
                    selectedCampus: event,
                    applicantCampus: event.value,
                  }),
                "Campuses *",
                applicantCampus
              )}
            </div>
          </div>
        </div>
        <div
          style={{
            display: "flex",
            padding: 10,
            justifyContent: "space-between",
          }}
        >
          <div>
            {this.renderSubInfo(
              "Date Of Birth *",
              "dob",
              dob,
              true,
              20,
              true,
              "date"
            )}
          </div>
          <div>
            {this.renderDropDown(
              Object.keys(BLOOD_GROUP).map((group) => {
                return {
                  value: BLOOD_GROUP[group],
                  label: group,
                };
              }),
              selectedBloodGroup,
              (event) =>
                this.setState({
                  selectedBloodGroup: event,
                  bloodGroup: event.value,
                }),
              "Blood Group",
              ""
            )}
          </div>
          {this.renderDropDown(
            Object.keys(GENDER).map((group) => {
              return {
                value: GENDER[group],

                label: group,
              };
            }),
            selectedGender,
            (event) =>
              this.setState({
                selectedGender: event,

                gender: event.value,
              }),

            "Gender",
            ""
          )}
          {/* <div style={{ height: "0px", overflow: "hidden" }}>
            <input
              id="uploadFile"
              type="file"
              name="pic"
              accept="image/*"
              onChange={(event) => {
                this.setState({
                  logoUploaded: true,
                  picture: event.target.files[0],
                });
              }}
            />
          </div> */}
          {/* <div style={{ cursor: "pointer", height: "100%" }}>
                  <div
                    style={{
                      borderRadius: "4px",
                      border: "solid 1px #cdcdce",
                      height: "100%",
                      display: "flex",
                      justifyContent: "center",
                      padding: 5,
                      position: "relative",
                    }}
                  >
                    {this.state.logoUploaded ? (
                      <img
                        alt=""
                        src={URL.createObjectURL(this.state.picture)}
                        style={{ maxWidth: "60%", width: "fit-content" }}
                        height="fit-content"
                      />
                    ) : (
                      <img
                        src={""}
                        width="50px"
                        height="auto"
                        style={{ margin: 50 }}
                      />
                    )}
                    <div
                      role="presentation"
                      style={{
                        height: 35,
                        backgroundColor: "#E4E7EE",
                        width: "100%",
                        position: "absolute",
                        bottom: 0,
                        left: 0,
                        color: "#40A7FF",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                      onClick={this.getLogo}
                    >
                      Upload
                    </div>
                    <div
                      role="presentation"
                      style={{
                        position: "absolute",
                        top: 5,
                        right: 10,
                        cursor: "pointer",
                      }}
                      onClick={(event) => {
                        event.stopPropagation();
                        event.preventDefault();
                        document.getElementById("uploadFile").value = null;
                        this.setState({
                          picture: null,
                          logoUploaded: false,
                        });
                      }}
                    >
                      <img src={closeMark} alt="x-mark" />
                    </div>
                  </div>
                </div> */}
        </div>
        <div
          style={{
            display: "flex",
            padding: 10,
            justifyContent: "space-between",
          }}
        >
          <div>
            {this.renderSubInfo(
              "Father's Name *",
              "fatherName",
              fatherName,
              true,
              "text",
              false
            )}
          </div>
          <div>
            {this.renderSubInfo(
              "Father's Phone *",
              "fatherMobile",
              fatherMobile,
              true,
              20,
              false
            )}
            {!validfatherPhone && showAlert && (
              <div style={{ color: "red", marginBottom: 5, fontSize: 14 }}>
                The phone number must be length of 10*
              </div>
            )}
          </div>
          <div>
            {this.renderSubInfo(
              "Father's Email",
              "fatherEmail",
              fatherEmail,
              true,
              20,
              false,
              "email"
            )}
          </div>
        </div>
        <div
          style={{
            display: "flex",
            padding: 10,
            justifyContent: "space-between",
          }}
        >
          <div>
            {this.renderSubInfo(
              "Mother's Name",
              "motherName",
              motherName,
              true,
              20,
              false
            )}
          </div>
          <div>
            {this.renderSubInfo(
              "Mother's Phone",
              "motherMobile",
              motherMobile,
              true,
              20,
              false
            )}
            {!validmotherPhone && showAlert && (
              <div style={{ color: "red", marginBottom: 5, fontSize: 14 }}>
                The phone number must be length of 10*
              </div>
            )}
          </div>
          <div>
            {" "}
            {this.renderSubInfo(
              "Mother's Email",
              "motherEmail",
              motherEmail,
              true,
              20,
              false,
              "email"
            )}
          </div>
        </div>
        <div className="heading-3">Permanent Address: </div>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <div>
            {this.renderSubInfo(
              "Line 1",
              "permanentAddressLine1",
              permanentAddressLine1,
              true,
              5,
              false
            )}
          </div>
          <div>
            {this.renderSubInfo(
              "Line 2",
              "permanentAddressLine2",
              permanentAddressLine2,
              true,
              5,
              false
            )}
          </div>
          <div>
            {this.renderSubInfo(
              "City",
              "permanentAddressCity",
              permanentAddressCity,
              true,
              5,
              false
            )}
          </div>
          <div>
            {this.renderSubInfo(
              "Pincode",
              "permanentAddressPinCode",
              permanentAddressPinCode,
              true,
              10,
              false
            )}
          </div>
        </div>
        <div className="heading-3">Current Address: </div>
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <div>
            {this.renderSubInfo(
              "Line 1",
              "currentAddressLine1",
              currentAddressLine1,
              true,
              5,
              false
            )}
          </div>
          <div>
            {this.renderSubInfo(
              "Line 2",
              "currentAddressLine2",
              currentAddressLine2,
              true,
              5,
              false
            )}
          </div>
          <div>
            {this.renderSubInfo(
              "City",
              "currentAddressCity",
              currentAddressCity,
              true,
              5,
              false
            )}
          </div>
          <div>
            {this.renderSubInfo(
              "Pincode",
              "currentAddressPinCode",
              currentAddressPinCode,
              true,
              10,
              false
            )}
          </div>
        </div>
        <div style={{ padding: 10, justifyContent: "space-between" }}>
          {" "}
          <div style={{ alignItems: "center" }}>
            {this.renderDropDown(
              Object.keys(APPLICANT_STATUS).map((group) => {
                return {
                  value: APPLICANT_STATUS[group],
                  label: group,
                };
              }),
              selectedApplicantStatus,
              (event) =>
                this.setState({
                  selectedApplicantStatus: event,
                  applicantStatus: event.value,
                }),
              "Applicant Status",
              ""
            )}
          </div>
        </div>
        <div style={{ paddingRight: 10 }}></div>
        <button
          style={{
            width: 180,
            height: 35,
            marginBottom: 15,
            backgroundColor: "#3994ae",
            color: "#FFFFFF",
            borderRadius: 4,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            lineHeight: 0,
            fontSize: 14,
            border: "1px solid #767171",
            float: "right",
          }}
          onClick={() => {
            if (
              !applicantFirstName ||
              !fatherName ||
              !fatherMobile ||
              gender === null ||
              !applicantCampus
            ) {
              alert(
                "Please Enter Mandatory Fields (First Name, Father Name, Father Phone, Gender, Campus"
              );
            } else {
              this.setState({ showAlert: true });
              this.props.edit
                ? this.allowUpdateApplicant()
                : this.allowNewApplicantCreation();
            }
          }}
        >
          {this.props.edit ? "Update" : "Create"}
        </button>
      </div>
    );
  }

  render() {
    return (
      <div>
        <Modal
          showModal={this.props.showModal}
          handleCloseModal={this.props.handleCloseModal}
          width={"70%"}
          height={"90%"}
        >
          <div>{this.renderEdit()}</div>
        </Modal>
      </div>
    );
  }
}

AddApplicant.propTypes = {
  isLoading: PropTypes.bool,
};

AddApplicant.defaultProps = {
  isLoading: false,
};

const mapStateToProps = ({ admissionBoard }) => ({
  campuses: admissionBoard.campuses,
  tags: admissionBoard.tags,
});

export default connect(mapStateToProps, {
  applicantCreation,
  applicantUpdation,
})(AddApplicant);
