import Api from '../helpers/Api';
import { showToast } from '.';
import { mathjax } from '../helpers/util';

export const UPDATE_SEARCH_STRING = 'getQuestions/UPDATE_SEARCH_STRING';
export const RESET_QUESTION_LIST = 'getQuestions/RESET_QUESTION_LIST';
export const UPDATE_SEARCH_RESULTS = 'getQuestions/UPDATE_SEARCH_RESULTS';
export const updateSearchString = (response) => {
  return {
    type: UPDATE_SEARCH_STRING,
    payload: response,
  };
};

export const resetQuestionsListReducer = () => async (dispatch) => {
  dispatch({
    type: RESET_QUESTION_LIST,
  });
};

export const searchQuestions = () => async (dispatch, getState) => {
  const { searchText } = getState().getQuestions;
  if (!searchText) {
    return;
  }
  const response = await Api({
    method: 'get',
    url: `/questions/get_questions_from_ids_to_print?&search_question_ids=${searchText}`,
  });
  if (response.success) {
    const payload = {
      questions: response.questions,
      totalQuestionsCount: response.questions.length,
    };
    dispatch({
      type: UPDATE_SEARCH_RESULTS,
      payload,
    });
    mathjax();
  } else {
    dispatch(showToast('Something went wrong'));
  }
};
