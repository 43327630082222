import React, { Component } from 'react';
import { connect } from 'react-redux';
import Select from 'react-select';
import Modal from './baseModal';
import closeMark from '../../static/images/close-mark.svg';
import Constants from '../../shared/constants';

class TeacherDetailsEditModal extends Component {
  constructor(props) {
    super(props);
    const { teacher, subjects, role } = this.props;
    this.state = {
      teacherId: teacher.id,
      teacherName: teacher.name,
      teacherTitle: teacher.title,
      teacherBadgeId: teacher.badge_id,
      teacherEmail: teacher.email,
      teacherDepartment: teacher.department_names && teacher.department_names.length ? teacher.department_names.filter((name) => !!name)[0] : '-',
      badgeId: teacher.student_id,
      selectedClassrooms: [],
      selectedSubjects: [],
      dropdownSubjects: subjects,
      isActive: teacher.active || teacher === 'new',
      showEditModal: false,
      newTeacher: teacher === 'new',
      selectedRole: { label: role, value: 1 },
      showAlert: false,
      subjectCodes: teacher.subject_codes ? teacher.subject_codes.filter((s) => !!s) : [],
      subjectClassrooms: teacher.subject_classrooms ? teacher.subject_classrooms : [],
      selectedCampus: teacher.campuses && teacher.campuses.length ? teacher.campuses[0] : null,
      campusId: teacher.campuses && teacher.campuses.length ? teacher.campuses[0].value : null,
    };
    this.toggleModal = this.toggleModal.bind(this);
    this.renderEdit = this.renderEdit.bind(this);
  }

  toggleModal() {
    const { showEditModal } = this.state;
    this.setState({
      showEditModal: !showEditModal,
    });
  }

  handleFormSubmit(e) {
    e.preventDefault();
  }

  updateFieldValueChanges(type, value) {
    this.setState({ [type]: value });
  }

  handleMultiSelect(key, value) {
    this.setState({ [key]: value });
  }

  addSubjectClassrooms() {
    const {
      selectedClassrooms, selectedSubjects, subjectClassrooms, dropdownSubjects,
    } = this.state;
    let subjectClassroomsData = [...subjectClassrooms];
    const dropdownSubjectsData = [...dropdownSubjects];
    selectedSubjects.map((subject) => {
      selectedClassrooms.map((classroom) => {
        subjectClassroomsData = [...subjectClassroomsData, { subjectID: subject.value, classroomID: classroom.value, label: `${subject.label} - ${classroom.label}` }];
      });
    });
    this.setState({
      dropdownSubjects: dropdownSubjectsData,
      subjectClassrooms: subjectClassroomsData,
    }, () => this.setState({ selectedClassrooms: [], selectedSubjects: [] }));
  }

  handleMappingRemoval(item, index) {
    const { subjectClassrooms } = this.state;
    const filteredData = [...subjectClassrooms];
    filteredData.splice(index, 1);
    this.setState({ subjectClassrooms: filteredData });
  }

  manageTeacherCreationOrUpdate(event) {
    event.preventDefault();
    const {
      teacherId, teacherName, teacherTitle, teacherBadgeId, teacherEmail,
      isActive, selectedRole, subjectClassrooms,
    } = this.state;
    this.props.addOrUpdateTeacherDetails({
      teacherId,
      teacherBadgeId,
      teacherName,
      teacherTitle,
      teacherEmail,
      isActive,
      selectedRole,
      subjectClassrooms,
    });
  }

  renderSubInfo(label, fieldlName, input = false, required = true, type = 'text') {
    return (
      <div style={{ marginBottom: 10 }}>
        <div className="heading-3">{label}</div>
        {!input && <div className="content-text" style={{ marginTop: 5 }}>{this.state[fieldlName] || '-' }</div>}
        {input
        && (
        <input
          type={type}
          value={this.state[fieldlName]}
          style={{
            border: 'none', borderBottom: '1px solid #E4E7EE', width: '90%', outline: 'none', padding: 0,
          }}
          onChange={(event) => this.updateFieldValueChanges(fieldlName, event.target.value)}
          required={required}
        />
        )}
      </div>
    );
  }

  renderDropDown(filterItems, selectedItem, selectedFunction, item, defaultValue = {}) {
    const defaultSelectedValue = defaultValue || { label: item, value: 0 };
    const customStyles = {
      control: (base, state) => ({
        ...base,
        height: 35,
        width: 200,
        backgroundColor: 'white',
        border: '1px solid #CDCFD6 !important',
      }),
      indicatorsContainer: (base, state) => ({
        ...base,
        height: 35,
      }),
      valueContainer: (base, state) => ({
        ...base,
        height: 40,
      }),
      option: (base, state) => ({
        ...base,
        textAlign: 'left',
      }),
    };
    return (
      <div style={{
        display: 'flex', flexDirection: 'column', alignItems: 'flex-start', marginBottom: 5,
      }}
      >
        <div className="heading-3">{item}</div>
        <Select
          styles={customStyles}
          value={selectedItem && selectedItem.value ? selectedItem : defaultSelectedValue}
          onChange={selectedFunction}
          options={filterItems}
          placeholder="All"
          maxMenuHeight={150}
          required
        />
      </div>
    );
  }

  renderMultiSelect(filterItems, selectedItems, selectedFunction, item) {
    const customStyles = {
      control: (base, state) => {
        return ({
          ...base,
          marginBottom: 5,
          height: 50,
          width: 200,
          cursor: state.isDisabled ? 'not-allowed' : 'pointer',
          textAlign: 'center',
        });
      },
      valueContainer: (base, state) => {
        return ({
          ...base,
          height: 45,
          paddingLeft: 20,
          overflow: 'scroll',
        });
      },
      option: (base, state) => {
        if (state.data.__isNew__) {
          return ({
            ...base,
            color: Constants().color.primary,
            fontWeight: 'bold',
            textAlign: 'center',
            backgroundColor: '#FFFFF',
            cursor: 'pointer',
            fontSize: 14,
          });
        }
        return ({
          ...base,
          border: '1px solid #F8F8F8',
          color: '#343B40',
          backgroundColor: state.isFocused ? '#DEEBFF' : '#FFFFFF',
        });
      },
    };
    return (
      <div style={{
        display: 'flex', flexDirection: 'column', alignItems: 'flex-start', marginBottom: 5,
      }}
      >
        <div className="heading-3">{item}</div>
        <Select
          isMulti
          value={selectedItems}
          options={filterItems}
          className="basic-multi-select"
          classNamePrefix="select"
          styles={customStyles}
          maxMenuHeight={150}
          placeholder="None"
          onChange={selectedFunction}
        />
      </div>
    );
  }

  renderMappedItems(item, index) {
    return (
      <div key={index} style={{ display: 'flex' }}>
        <div role="presentation" className="clickable" style={{ marginRight: 15 }} onClick={() => this.handleMappingRemoval(item, index)}>
          X
        </div>
        <div>
          {item.label}
        </div>
      </div>
    );
  }

  renderClassroomTeacherAssignmemt() {
    const { classrooms, isHigherEducationInstitute } = this.props;
    const {
      selectedClassrooms, selectedSubjects, dropdownSubjects, subjectClassrooms, campusId,
    } = this.state;
    let subjectClassroomIDs = [];
    let commonClassroomIDs = isHigherEducationInstitute && selectedSubjects
    && selectedSubjects.length ? selectedSubjects[0].classroom_id_list : [];
    let filteredClassrooms = [];

    if (isHigherEducationInstitute) {
      if (selectedSubjects && selectedSubjects.length > 1) {
        selectedSubjects.map((subject) => {
          subjectClassroomIDs = subject.classroom_id_list;
          commonClassroomIDs = commonClassroomIDs.filter((classroom) => subjectClassroomIDs.includes(classroom));
        });
      }
      commonClassroomIDs.map((classroomID) => {
        filteredClassrooms = [...filteredClassrooms,
          classrooms.filter((classroom) => classroomID === classroom.value)[0]];
      });
    } else if (campusId) {
      filteredClassrooms = classrooms.filter((classroom) => classroom.campus_id === campusId);
    } else {
      filteredClassrooms = classrooms;
    }

    if ((filteredClassrooms.length === 0) && selectedClassrooms.length) {
      this.setState({ selectedClassrooms: [] });
    }

    return (
      <div style={{ marginTop: 15 }}>
        <div style={{ display: 'flex', flexDirection: 'column', width: 'fit-content' }}>
          <div style={{ display: 'flex' }}>
            <div style={{ marginRight: 15 }}>
              {this.renderMultiSelect(dropdownSubjects, selectedSubjects, (selectedSubject) => this.handleMultiSelect('selectedSubjects', selectedSubject), 'Subjects')}
            </div>
            {this.renderMultiSelect(filteredClassrooms, selectedClassrooms, (selectedClassroom) => this.handleMultiSelect('selectedClassrooms', selectedClassroom), 'Classrooms')}
            <div
              role="presentation"
              style={{
                marginLeft: 15, alignSelf: 'flex-end', width: 60, height: 36, marginBottom: 15, backgroundColor: '#40A7FF', color: '#FFFFFF', borderRadius: 4, display: 'flex', justifyContent: 'center', alignItems: 'center', cursor: 'pointer', opacity: selectedClassrooms && selectedClassrooms.length ? 1 : 0.5,
              }}
              onClick={() => selectedClassrooms
                && selectedClassrooms.length && this.addSubjectClassrooms()}
            >
              ADD
            </div>
          </div>
        </div>
        <div style={{ marginTop: 20 }}>
          {(subjectClassrooms.length > 0) && <div className="heading-3" style={{ marginBottom: 5 }}>Subject - Classroom</div>}
          {
            subjectClassrooms.map((item, index) => {
              return this.renderMappedItems(item, index);
            })
        }
        </div>
      </div>
    );
  }

  renderEdit() {
    const {
      teacherName, teacherEmail, teacherBadgeId, isActive,
      newTeacher, teacherDepartment, selectedRole, selectedCampus,
    } = this.state;
    const { campuses, isHigherEducationInstitute } = this.props;
    const statusStyleActive = { border: '1px solid #40A7FF', color: '#40A7FF' };
    const statusStyleInactive = { border: '1px solid #CDCFD6', color: '' };
    const allowTeacherCreation = teacherName && teacherEmail && teacherBadgeId;
    const roles = [{ label: 'Teacher', value: 1 }, { label: 'HOD', value: 2 }, { label: 'Dean', value: 3 }, { label: 'Admin', value: 4 }];

    return (
      <div style={{ padding: 25 }}>
        <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: 30 }}>
          <div className="heading-1">Teacher Details</div>
          <div role="presentation" onClick={this.props.handleCloseModal} style={{ cursor: 'pointer' }}>
            <img src={closeMark} alt="x-mark" />
          </div>
        </div>
        <form style={{ display: 'flex' }} onSubmit={newTeacher ? (allowTeacherCreation) ? (event) => this.manageTeacherCreationOrUpdate(event) : (event) => this.setState({ showAlert: true }, event.preventDefault()) : (event) => this.manageTeacherCreationOrUpdate(event)}>
          <div style={{
            width: '40%', display: 'flex', justifyContent: 'center', flexDirection: 'column',
          }}
          >
            {this.renderSubInfo('Staff Id', 'teacherBadgeId', true)}
            {this.renderSubInfo('Name', 'teacherName', true)}
            {this.renderSubInfo('Title', 'teacherTitle', true, false)}
            {!newTeacher && teacherDepartment && this.renderSubInfo('Department', 'teacherDepartment', false)}
            {this.renderSubInfo('Email', 'teacherEmail', true, true, 'email')}
            <div style={{ width: 370 }}>
              <div className="heading-1">Status</div>
              <div style={{ display: 'flex', fontWeight: 700 }}>
                <div role="presentation" className="verify" style={{ ...isActive ? statusStyleActive : statusStyleInactive, marginRight: 15 }} onClick={() => this.setState({ isActive: true })}>Active</div>
                <div role="presentation" className="verify" style={!isActive ? statusStyleActive : statusStyleInactive} onClick={() => this.setState({ isActive: false })}>In Active</div>
              </div>
            </div>
          </div>
          <div style={{ width: '60%' }}>
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              <div>
                {this.renderDropDown(roles, selectedRole, (event) => this.setState({ selectedRole: event }), 'Role')}
                {!isHigherEducationInstitute
                    && (
                    <div style={{ marginTop: 15 }}>
                        {this.renderDropDown(campuses, selectedCampus, (event) => this.setState({ selectedCampus: event, campusId: event ? event.value : '' }), 'Campus')}
                    </div>
                    )}
                {this.renderClassroomTeacherAssignmemt()}
                <div style={{
                  display: 'flex', width: 370, fontWeight: 700, justifyContent: 'space-between', marginTop: 30,
                }}
                >
                  <div role="presentation" className="custom-secondary-button" style={{ height: 35, width: 180, border: '1px solid #CDCFD6' }} onClick={() => this.props.handleCloseModal()}>Cancel</div>
                  <button
                    type="submit"
                    style={{
                      width: 180, height: 35, marginBottom: 15, backgroundColor: '#40A7FF', color: '#FFFFFF', borderRadius: 4, display: 'flex', justifyContent: 'center', alignItems: 'center', lineHeight: 0, fontSize: 14,
                    }}
                  >
                    { newTeacher ? 'Create' : 'Update' }
                  </button>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
    );
  }

  render() {
    return (
      <div>
        <Modal showModal={this.props.showModal} handleCloseModal={this.props.handleCloseModal} height="520px" width="900px">
          <div>
            {this.renderEdit()}
          </div>
        </Modal>
      </div>
    );
  }
}

const mapStateToProps = ({ institute }) => ({
  subjects: institute.subjects,
  classrooms: institute.classrooms,
  courses: institute.courses,
  departments: institute.departments,
  isHigherEducationInstitute: institute.isHigherEducationInstitute,
  teacherDetails: institute.teacherDetails,
});

export default connect(mapStateToProps)(TeacherDetailsEditModal);
