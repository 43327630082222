import { updateAssignmentQuestionsIds } from '.';
import Api from '../helpers/Api';

export const GET_PROPERTIES_INSIGHT = 'insight/GET_PROPERTIES_INSIGHT';
export const GET_QUESTION_DISTRIBUTION = 'insight/GET_QUESTION_DISTRIBUTION';
export const GET_SIMILAR_QUESTIONS = 'insight/GET_SIMILAR_QUESTIONS';
export const GET_REPEATED_QUESTIONS = 'insight/GET_REPEATED_QUESTIONS';

export function getPropertiesInsightTest(id) {
  return (dispatch) => Api({
    method: 'get',
    url: `/insight/get_properties_question_assessment?id=${id}`,
  }).then((response) => {
    if (response.success) {
      dispatch({
        type: GET_PROPERTIES_INSIGHT,
        payload: response.properties_insight,
      });
    }
    return response;
  });
}

export function getRepeatdQuestionInsightTest(id) {
  return (dispatch) => Api({
    method: 'get',
    url: `/insight/get_repeated_question_assessment?id=${id}`,
  }).then((response) => {
    if (response.success) {
      dispatch(updateAssignmentQuestionsIds(response.test_question_ids));
      dispatch({ type: GET_REPEATED_QUESTIONS, payload: response.repeated_questions_ids });
    }
    return response;
  });
}

export function getQuestionsDistribution(id) {
  return (dispatch) => Api({
    method: 'get',
    url: `/insight/get_test_distribution?id=${id}`,
  }).then((response) => {
    if (response.success) {
      dispatch({
        type: GET_QUESTION_DISTRIBUTION,
        payload: response.question_distribution,
      });
    }
    return response;
  });
}

export function getSimilarQuestion(id) {
  return (dispatch) => Api({
    method: 'get',
    url: `/insight/get_simlar_questions_assessment?id=${id}`,
  }).then((response) => {
    if (response.success) {
      dispatch(updateAssignmentQuestionsIds(response.test_question_ids));
      dispatch({ type: GET_SIMILAR_QUESTIONS, payload: response.similar_questions });
    }
    return response;
  });
}
