import { GET_CLASSROOM_TEACHERS } from '../actions';

const initialState = {
  campuses: {},
  classrooms: {},
  subjects: {},
  classroomTeachers: [],
  currentClassroomID: 0,
  allTeachers: [],
};

export default function (state = initialState, action) {
  switch (action.type) {
    case GET_CLASSROOM_TEACHERS: {
      return {
        ...state,
        ...action.payload,
      };
    }
    default:
      return state;
  }
}
