import React from 'react';
import PropTypes from 'prop-types';
import Select from 'react-select';
import { connect } from 'react-redux';

class CheckpointDropdown extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      value: props.defaultValue,
    };
  }
  
  render() {
    const { selectedChapter, checkpointChapterMap, onChange } = this.props;
    if (selectedChapter) {
      return (
        <Select
          isMulti
          isSearchable
          value={this.state.value}
          options={checkpointChapterMap[selectedChapter]}
          onChange={(data = []) => {
            onChange(data);
            this.setState({ value: data });
          }}
          className="checkpointDropDownList"
          placeholder="Checkpoint / Topic"
        />
      );
    }
    if (this.state.value) {
      return (
        <div style={{ paddingTop: 30 }}>
          {this.state.value.map((checkpoint) => <div style={{ marginRight: 5 }}>{checkpoint.label}({checkpoint.value});</div>)}
        </div>
      );
    }
    return null;
  }
}

const mapStateToProps = ({ quickpoints }) => ({
  selectedChapter: quickpoints.selectedChapter,
  checkpointChapterMap: quickpoints.checkpointChapterMap,
});

CheckpointDropdown.propTypes = {
  onChange: PropTypes.func.isRequired,
  selectedChapter: PropTypes.number.isRequired,
  checkpointChapterMap: PropTypes.object.isRequired,
  defaultValue: PropTypes.array,
};

CheckpointDropdown.defaultProps = {
  defaultValue: [],
};

export default connect(mapStateToProps)(CheckpointDropdown);
