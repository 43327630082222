import {
  GET_RETAG_TOPICS,
  GET_RETAG_QUESTIONS,
  UPDATE_RETAG_INDEX,
  HIGHLIGHT_SELECTED_TOPIC,
  UPDATE_RETAG_COMPLEXITY,
} from '../actions';

const initialState = {
  topics: [],
  questions: [],
  parentCheckpoints: [],
  index: 0,
  isHighlight: false,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case GET_RETAG_TOPICS: {
      return {
        topics: action.payload,
      };
    }
    case GET_RETAG_QUESTIONS: {
      return {
        questions: action.payload.questions,
        parentCheckpoints: action.payload.parent_checkpoints,
        index: 0,
      };
    }
    case UPDATE_RETAG_INDEX: {
      return {
        ...state,
        index: state.index + action.payload,
      };
    }
    case HIGHLIGHT_SELECTED_TOPIC:
      return {
        ...state,
        ...action.payload,
      };
    case UPDATE_RETAG_COMPLEXITY: {
      const {questions} = state;
      questions[action.payload.index].complexity = action.payload.complexity;
      return {
        ...state,
        questions,
      };
    }
    default:
      return state;
  }
}

