import React, { Component } from 'react';
import { connect } from 'react-redux';
import Select from 'react-select';
import _ from 'lodash';
import ReactTooltip from 'react-tooltip';
import Constants from '../../shared/constants';
import AccessDenied from '../accessDenied';
import Layout from '../layout';
import SideNavigationBar from '../assignmentView/sideNavigationBar';
import ConfirmationModal from '../modal/confirmationModal';
import ApproveTestModal from '../modal/approveTestModal';
import FeedBackModal from '../modal/feedbackModal';
import ThankyouModal from '../modal/thankyouModal';
import QuestionList from '../assignmentView/questionList';
import MobileHeader from '../mobileHeader';
import ReportError from '../modal/reportError';
import Loading from '../loading';
import whiteClose from '../../static/images/whiteClose.svg';
import {
  getAssignmentQuestions, updateAssignmentQuestions,
  updateLoader, updateTestReviewForAssignment, updateNumericalQuestions,
  updateShowTest, showToast,
} from '../../actions';
import {
  INSIGHTS, PAGETYPE, FILTERTYPES, DIFFICULTY_COLOR, QUESTION_TYPES, COMPLEXITY,
} from '../../constants';
import { sendEvent } from '../../helpers/Analytics';
import { numberWithCommas, isMobileDevice, mathjax } from '../../helpers/util';
import dropDownArrow from '../../static/images/dropDownArrow.svg';
import '../../styles/questionPaperInsights.css';
import search from '../../static/images/search.svg';
import arrowRight from '../../static/images/common/arrow-right.svg';

const IS_MOBILE = isMobileDevice();
const SEARCH_TYPES = { PROGRESSIVE: 'progressive' };
const selectedFilterStyle = {
  width: 'fit-content', backgroundColor: '#56D060', borderRadius: 2, display: 'flex', alignItems: 'center', height: 30, color: '#FFFFFF', padding: 5, marginLeft: 10, border: '1px solid #56D060',
};

const FILTER_TYPES = {
  difficulty: [
    { value: '2', label: 'Easy' },
    { value: '3', label: 'Medium' },
    { value: '4', label: 'Hard' },
  ],
  previous_paper: [
    { value: 'neet', label: 'NEET' },
    { value: 'jee', label: 'JEE' },
    { value: 'kcet', label: 'KCET' },
    { value: 'jipmer', label: 'JIPMER' },
    { value: 'aiims', label: 'AIIMS' },
    { value: 'mht-cet', label: 'MHT CET' },
    { value: 'mht-cet', label: 'MHT CET' },
    { value: 'ap-eamcet', label: 'AP EAMCET' },
  ],
  question_type: [
    { value: 'assertion', label: 'Assertion', disabled: true },
    { value: 'matching', label: 'Match the Following', disabled: true },
    { value: 'diagram', label: 'Diagram based', disabled: true },
    { value: 'ordering', label: 'Order Options', disabled: true },
    { value: 'blanks', label: 'Fill in the Blanks', disabled: true },
    { value: 'correct', label: 'Correct Statement', disabled: true },
    { value: 'other', label: 'Others', disabled: true },
    { value: 'numerical', label: 'Numerical' },
    { value: 'multiple-choice', label: 'Multiple Choice' },
  ],
};

class NewAssignment extends Component {
  constructor(props) {
    super(props);
    this.iScroll = React.createRef();
    this.state = {
      page: 1,
      questionId: '',
      userAppliedFilters: {},
      selectedChapter: {},
      selectedCheckpoint: {},
      showFilters: false,
      showReportError: false,
      showChapterSelection: false,
      showQuestionDistribution: false,
      showOptions: false,
      showApproveModal: false,
      showFeedbackModal: false,
      showThankyouModal: false,
      showConfirmation: false,
      loadingState: false,
      pageStyle: {},
      showReviewPage: false,
      questionSearchString: '',
      questionsAdded: 0,
      questionsRemoved: 0,
      overAllQuestionsAdded: 0,
      overAllQuestionsRemoved: 0,
      pageStartTime: 0,
      sessionStartTime: 0,
      filterType: '',
      filterId: '',
      filterName: '',
      showPageLoading: true,
      ignoredInsight: [],
      selectedPage: PAGETYPE.PICKQUESTION,
      previousPage: null,
      showFilter: '',
      screenHeight: global.innerHeight,
      screenWidth: global.innerWidth,
    };
    this.questionSearch = _.debounce(this.questionSearch.bind(this), 500);
    this.toggleReportModal = this.toggleReportModal.bind(this);
    this.allowTestView = this.allowTestView.bind(this);
    this.toggleSelectedPage = this.toggleSelectedPage.bind(this);
    this.showFilters = this.showFilters.bind(this);
    this.closeFilters = this.closeFilters.bind(this);
    this.updateQuestions = this.updateQuestions.bind(this);
    this.scrollListener = this.scrollListener.bind(this);
    this.toggleApproveModal = this.toggleApproveModal.bind(this);
    this.renderInsights = this.renderInsights.bind(this);
    this.renderProgressBar = this.renderProgressBar.bind(this);
    this.toggleFeedbackModal = this.toggleFeedbackModal.bind(this);
    this.toggleThankyouModal = this.toggleThankyouModal.bind(this);
    this.toggleNumericalQuestionState = this.toggleNumericalQuestionState.bind(this);
  }

  componentDidMount() {
    const query = new URLSearchParams(window.location.search);
    const { id } = this.props.match.params;
    const type = query.get('type');
    const subjectId = query.get('subject_id');
    const track = query.get('track');
    const page = parseInt(query.get('page_type'), 10);
    const subject = subjectId && subjectId !== 'undefined' ? subjectId : '';
    const payload = (typeof type !== 'undefined' && (type === INSIGHTS.REVIEW || page === PAGETYPE.REVIEW)) ? {
      id, show_selected_questions_only: true, page: 1, subjectId: subject, track,
    } : { id, subjectId: subject, track };
    this.props.getAssignmentQuestions(payload, 'getQuestions').then(() => {
      const {
        appliedFilters, progressiveSearchSelectedValues, isTrial, isReviewed, cannotPickQuestion,
      } = this.props;
      const {
        filterType, filterName,
        filterId,
      } = this.getSelectedFilters(progressiveSearchSelectedValues);
      const pageType = (isTrial || cannotPickQuestion || isReviewed) ? PAGETYPE.REVIEW : page;
      const selectedPage = pageType || PAGETYPE.PICKQUESTION;
      let showReviewPage = pageType === PAGETYPE.REVIEW;
      if (type !== 'undefined' && type === INSIGHTS.REVIEW) {
        showReviewPage = true;
      }
      sendEvent('Assignment', 'PickQuestionPage', 'Open', { id });
      const pageStyle = {
        height: global.innerHeight * 0.85, overflow: 'auto', overflowX: 'hidden', zindex: '5', width: '100%',
      };
      this.setState({
        pageStyle,
        selectedPage,
        sessionStartTime: new Date(),
        pageStartTime: new Date(),
        screenHeight: global.innerHeight,
        screenWidth: global.innerWidth,
        userAppliedFilters: appliedFilters,
        selectedChapter: progressiveSearchSelectedValues.chapter
        && progressiveSearchSelectedValues.chapter,
        selectedCheckpoint: progressiveSearchSelectedValues.checkpoint
        && progressiveSearchSelectedValues.checkpoint,
        showReviewPage,
        filterType,
        filterId,
        filterName,
      });
      this.setState(() => ({ showPageLoading: false }));
      mathjax();
    });
  }

  getSelectedFilters(filterData) {
    let filterType = '';
    let filterName = '';
    let filterId = 0;
    if (filterData) {
      if (filterData.sub_checkpoint) {
        filterType = FILTERTYPES.SELECTEDSUBTOPIC;
      } else if (filterData.checkpoint) {
        filterType = FILTERTYPES.SELECTEDTOPIC;
      } else if (filterData.chapter) {
        filterType = FILTERTYPES.SELECTEDCHAPTER;
      }
      if (filterType) {
        filterName = filterData[filterType].label;
        filterId = filterData[filterType].value;
        filterType = `${filterType}s`;
      }
      return { filterType, filterName, filterId };
    }
    return {};
  }

  getAppliedFilters(togglePage = false, applyFilters = true) {
    const query = new URLSearchParams(window.location.search);
    const { id } = this.props.match.params;
    const subjectId = query.get('subject_id');
    const track = query.get('track');
    const {
      filterId, filterType, questionSearchString, showReviewPage,
    } = this.state;
    const {
      freshness, previous_paper, difficulty, question_type,
    } = this.props.appliedFilters;
    const { isReviewed } = this.props;
    const payload = {
      id, subjectId, track, isReviewed,
    };
    payload.page = 1;
    if (showReviewPage && !togglePage) {
      payload.show_selected_questions_only = showReviewPage;
    }
    if (filterId !== 0 && !togglePage && !showReviewPage) {
      payload.filter_type = filterType;
      payload.search_object_id = filterId;
    }
    if (applyFilters) {
      payload.difficulty = JSON.stringify(difficulty);
      payload.previous_paper = JSON.stringify(previous_paper);
      payload.question_type = JSON.stringify(question_type);

      if (freshness) {
        payload.freshness = freshness;
      }
      if (questionSearchString !== '') {
        payload.search_question_text = questionSearchString;
      }
    }
    return payload;
  }

  getTimeSpent(startTime) {
    return ((new Date() - startTime) / (1000 * 60)).toFixed(2); // Time spent in minutes
  }

  scrollListener() {
    const scrollField = this.iScroll.current;
    const { renderMoreQuestions } = this.props;
    if ((scrollField.scrollTop + scrollField.clientHeight >= scrollField.scrollHeight - 100)
    && renderMoreQuestions) {
      this.loadMoreItems();
    }
  }

  loadMoreItems() {
    if (this.state.loadingState) {
      return;
    }
    const payload = this.getAppliedFilters(false, true);
    const page = this.state.page + 1;
    const { isAutoTest, selectedQuestionCount } = this.props;
    const {
      pageStartTime, sessionStartTime, overAllQuestionsAdded,
      overAllQuestionsRemoved, questionsAdded, questionsRemoved,
    } = this.state;
    payload.page = page;
    payload.last_question_index = this.props.questions.slice(-1)[0].id;
    this.setState({ loadingState: true, page });
    sendEvent('Assignment', 'NextPage', 'log', {
      ...payload,
      isAutoTest,
      selectedQuestionCount,
      pageTimeSpent: this.getTimeSpent(pageStartTime),
      sessionTimeSpent: this.getTimeSpent(sessionStartTime),
      questionsAdded,
      questionsRemoved,
      overAllQuestionsAdded,
      overAllQuestionsRemoved,
    });
    this.props.getAssignmentQuestions(payload, 'updateQuestions').then((data) => {
      if (data.success) {
        this.setState({
          loadingState: false, pageStartTime: new Date(), questionsAdded: 0, questionsRemoved: 0,
        });
        mathjax();
      } else {
        this.setState({ loadingState: false });
        alert(data.message);
        mathjax();
      }
    });
  }

  printAssignment() {
    const query = new URLSearchParams(window.location.search);
    const { id } = this.props.match.params;
    const subjectId = query.get('subject_id');
    const track = query.get('track');
    const {
      questionsAdded, questionsRemoved, overAllQuestionsAdded,
      overAllQuestionsRemoved, pageStartTime, sessionStartTime,
    } = this.state;
    sendEvent('Assignment', 'PrintAssignment', 'Click', {
      id,
      questionsAdded,
      questionsRemoved,
      overAllQuestionsAdded,
      overAllQuestionsRemoved,
      pageTimeSpent: this.getTimeSpent(pageStartTime),
      sessionTimeSpent: this.getTimeSpent(sessionStartTime),
    });
    if (this.props.isAssignment) {
      window.open(`/print_assignment/${id}`);
      return true;
    }
    window.open(`${Constants().api.url}/tests/new?tab=review&test_id=${id}&proofread=true&subject_id=${subjectId}&track=${track}`);
    return true;
  }

  updateQuestions(question, type, section = null) {
    const query = new URLSearchParams(window.location.search);
    const { id } = this.props.match.params;
    const subjectId = query.get('subject_id');
    const track = query.get('track');
    const { isAutoTest, selectedQuestionCount } = this.props;
    const {
      page, questionSearchString, filterType, filterId, filterName,
    } = this.state;
    let {
      questionsAdded, questionsRemoved, overAllQuestionsAdded, overAllQuestionsRemoved,
    } = this.state;
    this.props.updateLoader(question.id, true);
    const eventProperties = {
      id,
      subjectId,
      track,
      type,
      questionId: question.id,
      subjectId: question.subject_id,
      page,
      questionSearchString,
      filterType,
      filterId,
      filterName,
      selectedQuestionCount,
      isAutoTest,
    };
    this.props.updateAssignmentQuestions(id, question.id, track,
      question.question_type === QUESTION_TYPES.NUMERICAL
        ? QUESTION_TYPES.FOUR_OPTIONS : question.question_type, section).then((result) => {
      this.props.updateLoader(question.id, false);
      if (result.success === false) {
        sendEvent('Assignment', type === 'addQuestion' ? 'AddQuestionFailed' : 'RemoveQuestionFailed', 'log', eventProperties);
      } else {
        if (type === 'addQuestion') {
          questionsAdded += 1;
          overAllQuestionsAdded += 1;
        } else {
          questionsRemoved += 1;
          overAllQuestionsRemoved += 1;
        }
        sendEvent('Assignment', type === 'addQuestion' ? 'AddQuestion' : 'RemoveQuestion', 'log', {
          ...eventProperties,
          questionsAdded,
          questionsRemoved,
          overAllQuestionsAdded,
          overAllQuestionsRemoved,
        });
        this.setState({
          questionsAdded, questionsRemoved, overAllQuestionsAdded, overAllQuestionsRemoved,
        });
      }
    });
  }

  toggleNumericalQuestionState(questionId) {
    const { isAutoTest } = this.props;
    const query = new URLSearchParams(window.location.search);
    const { id } = this.props.match.params;
    const subjectId = query.get('subject_id');
    const track = query.get('track');
    this.props.updateNumericalQuestions(id, questionId).then((result) => {
      sendEvent('Assignment', result.is_numerical_question ? 'ToggleNumericalQuestion' : 'ToggleMCQQuestion', 'log', {
        id,
        questionId,
        subjectId,
        track,
        isAutoTest,
        isNumericalQuestion: result.is_numerical_question,
      });
    });
  }

  togglePage(status) {
    const query = new URLSearchParams(window.location.search);
    const subjectId = query.get('subject_id');
    const track = query.get('track');
    const { isAutoTest } = this.props;
    const {
      questionsAdded, questionsRemoved, overAllQuestionsAdded,
      overAllQuestionsRemoved, pageStartTime, sessionStartTime,
    } = this.state;
    const payload = this.getAppliedFilters(true);
    if (status) {
      payload.show_selected_questions_only = true;
    }
    this.setState({ showReviewPage: status, page: 1, showPageLoading: true });
    this.props.getAssignmentQuestions(payload, 'getQuestions').then(() => {
      sendEvent('Assignment', status ? 'ReviewQuestionPage' : 'PickQuestionPage', 'Open', {
        ...payload,
        subjectId,
        track,
        isAutoTest,
        pageTimeSpent: this.getTimeSpent(pageStartTime),
        sessionTimeSpent: this.getTimeSpent(sessionStartTime),
        questionsAdded,
        questionsRemoved,
        overAllQuestionsAdded,
        overAllQuestionsRemoved,
      });
      this.setState({
        questionsAdded: 0,
        questionsRemoved: 0,
        overAllQuestionsAdded: 0,
        overAllQuestionsRemoved: 0,
        pageStartTime: new Date(),
        sessionStartTime: new Date(),
      });
      this.setState(() => ({ showPageLoading: false }));
      mathjax();
    });
  }

  toggleSelectedPage(pageType) {
    const { selectedPage } = this.state;
    if (pageType === PAGETYPE.OVERVIEW) {
      const query = new URLSearchParams(window.location.search);
      const { id } = this.props.match.params;
      const subjectId = query.get('subject_id');
      const track = query.get('track');
      sendEvent('Assignment', 'navigateToOverview', 'log', { id, subjectId, track });
      this.props.history.push(`/test_creation_overview/${id}`);
    }
    if (pageType === PAGETYPE.PRINT) {
      this.printAssignment();
      return;
    }
    this.setState({ previousPage: selectedPage });
    this.setState({ selectedPage: pageType, questionSearchString: '' });
    if (pageType === PAGETYPE.PICKQUESTION || pageType === PAGETYPE.REVIEW) {
      this.togglePage(pageType === PAGETYPE.REVIEW);
    }
  }

  questionSearch(searchString) {
    const page = 1;
    const payload = this.getAppliedFilters();
    const { isAutoTest } = this.props;
    const query = new URLSearchParams(window.location.search);
    const subjectId = query.get('subject_id');
    const track = query.get('track');
    payload.search_question_text = searchString;
    this.setState({ page });
    sendEvent('Search', 'QuestionSearched', 'log', {
      ...payload, subjectId, track, isAutoTest,
    });
    this.props.getAssignmentQuestions(payload, 'getQuestions').then(() => {
      mathjax();
      this.setState({
        questionsAdded: 0,
        questionsRemoved: 0,
        overAllQuestionsAdded: 0,
        overAllQuestionsRemoved: 0,
        pageTimeSpent: new Date(),
        sessionStartTime: new Date(),
      });
    });
  }

  updateSearchfields(searchObjectId, name, filterType, searchType = '') {
    const page = 1;
    const payload = this.getAppliedFilters();
    const query = new URLSearchParams(window.location.search);
    const subjectId = query.get('subject_id');
    const track = query.get('track');
    const { isAutoTest } = this.props;
    const {
      questionsAdded, questionsRemoved, overAllQuestionsAdded,
      overAllQuestionsRemoved, pageStartTime, sessionStartTime,
    } = this.state;
    payload.filter_type = filterType;
    payload.search_object_id = searchObjectId;
    this.setState({
      filterType,
      filterId: searchObjectId,
      filterName: name,
      page,
      showPageLoading: true,
    });
    sendEvent('Search', searchType === SEARCH_TYPES.PROGRESSIVE ? 'ProgressiveFilterSelected' : 'SubjectTrackChanged', 'log', {
      ...payload,
      isAutoTest,
      subjectId,
      track,
      questionsAdded,
      questionsRemoved,
      overAllQuestionsAdded,
      overAllQuestionsRemoved,
      pageTimeSpent: this.getTimeSpent(pageStartTime),
      sessionTimeSpent: this.getTimeSpent(sessionStartTime),
    });
    this.props.getAssignmentQuestions(payload, 'getQuestions').then(() => {
      this.setState({
        questionsAdded: 0,
        questionsRemoved: 0,
        overAllQuestionsAdded: 0,
        overAllQuestionsRemoved: 0,
        pageStartTime: new Date(),
        sessionStartTime: new Date(),
        showChapterSelection: false,
      });
      this.setState(() => ({ showPageLoading: false }));
      mathjax();
    });
  }

  updateProgressiveSearchField(selectedItem, type) {
    const fieldId = selectedItem.value;
    const name = selectedItem.label;
    if (fieldId === 'navigateToChapterSelection') {
      const query = new URLSearchParams(window.location.search);
      const { id } = this.props.match.params;
      const track = query.get('track');
      const { isAutoTest } = this.props;
      const subject = query.get('subject_id');
      const subjectId = subject || this.props.progressiveSearchSelectedValues.subject.value;
      sendEvent('Assignment', 'navigateToChapterSelection', 'log', {
        id, subjectId, track, isAutoTest,
      });
      this.props.history.push(`/test_chapter_selection/${id}?subject_id=${subjectId}&track=${track}`);
      this.setState({ showPageLoading: true });
      return;
    }
    this.updateSearchfields(fieldId, name, type, 'progressive');
  }

  progressiveSearch(filterItems, selectedItem, selectedFunction, item) {
    const customStyles = {
      control: (base, state) => ({
        ...base,
        height: 50,
        width: '100%',
        border: `1px solid var(--${Constants().color.inactive}) !important`,
      }),
      option: (base, { data, isFocused }) => ({
        ...base,
        border: '1px solid #F8F8F8',
        color: data.color ? data.color : '#343B40',
        backgroundColor: isFocused ? '#DEEBFF' : '#FFFFFF',
      }),
    };
    const filterItemsData = _.flatten([...filterItems, [{ value: 'navigateToChapterSelection', label: 'Add or Remove Chapters', color: Constants().color.primary }]]);
    return (
      <Select
        isDisabledboolean={(!filterItemsData || filterItemsData.length === 0)}
        styles={customStyles}
        value={selectedItem}
        onChange={selectedFunction}
        options={filterItemsData}
        placeholder={item}
        isSearchable={false}
      />
    );
  }

  updateFilter(item, type, applyFilter = false) {
    const page = 1;
    const { isAutoTest } = this.props;
    const query = new URLSearchParams(window.location.search);
    const subjectId = query.get('subject_id');
    const track = query.get('track');
    const payload = this.getAppliedFilters();
    const {
      questionsAdded, questionsRemoved, overAllQuestionsAdded,
      overAllQuestionsRemoved, pageStartTime, sessionStartTime,
    } = this.state;
    const { userAppliedFilters } = this.state;
    if (type === '' && item === 'clear_all') {
      Object.keys(FILTER_TYPES).forEach((filterType) => {
        payload[filterType] = [];
        userAppliedFilters[filterType] = [];
      });
    } else {
      if (IS_MOBILE) {
        if (applyFilter) {
          Object.keys(FILTER_TYPES).forEach((filterType) => {
            payload[filterType] = userAppliedFilters[filterType] ? JSON.stringify(userAppliedFilters[filterType]) : '';
          });
        } else {
          const payloadValues = [];
          userAppliedFilters[type].forEach((fieldVal) => payloadValues.push(fieldVal.value));
          const index = payloadValues.indexOf(item.value);
          if (index > -1) {
            userAppliedFilters[type].splice(index, 1);
          } else {
            userAppliedFilters[type].push(item);
          }
          this.setState({ userAppliedFilters });
          return;
        }
      }
      if (item === 'clear') {
        payload[type] = [];
      } else {
        payload[type] = payload[type] ? JSON.parse(payload[type]) : [];
        const payloadValues = [];
        payload[type].forEach((fieldVal) => payloadValues.push(fieldVal.value));
        const index = payloadValues.indexOf(item.value);
        if (index > -1) {
          payload[type].splice(index, 1);
        } else {
          payload[type].push(item);
        }
      }
      payload[type] = JSON.stringify(payload[type]);
    }
    this.setState({ page, showPageLoading: true });
    sendEvent('Search', 'FilterApplied', 'log', {
      ...payload,
      subjectId,
      track,
      isAutoTest,
      questionsAdded,
      questionsRemoved,
      overAllQuestionsAdded,
      overAllQuestionsRemoved,
      pageTimeSpent: this.getTimeSpent(pageStartTime),
      sessionTimeSpent: this.getTimeSpent(sessionStartTime),
    });
    this.props.getAssignmentQuestions(payload, 'getQuestions').then(() => {
      this.setState({
        questionsAdded: 0,
        questionsRemoved: 0,
        overAllQuestionsAdded: 0,
        overAllQuestionsRemoved: 0,
        pageStartTime: new Date(),
        sessionStartTime: new Date(),
        showFilters: false,
        userAppliedFilters,
      });
      this.setState(() => ({ showPageLoading: false }));
      mathjax();
    });
  }

  showFilters(event, type) {
    event.preventDefault();
    this.setState({ showFilter: type }, () => {
      document.addEventListener('click', this.closeFilters);
    });
  }

  closeFilters(event) {
    if (event && event.target.className !== 'dropDownList') {
      this.setState({ showFilter: '' }, () => {
        document.removeEventListener('click', this.closeFilters);
      });
    }
  }

  customFilters(name, type, values) {
    const { showFilter } = this.state;
    const highlightFilter = values.length > 0;
    return (
      <div style={{ width: 150, marginRight: 10 }}>
        <div
          role="presentation"
          className="ml-3 cursor"
          style={{
            border: `1px solid ${Constants().color.inactive}`, textAlign: 'center', backgroundColor: highlightFilter && Constants().color.primary, color: highlightFilter && 'white', borderRadius: 5, width: '100%', height: 40, display: 'flex', justifyContent: 'space-between',
          }}
          onClick={(event) => this.showFilters(event, type)}
        >
          <div style={{
            width: '100%', padding: '0px 5px', display: 'flex', justifyContent: 'space-between', alignItems: 'center',
          }}
          >
            {name}
            <img alt="" src={dropDownArrow} width="10" height="10" />
          </div>
        </div>
        {(showFilter === type)
          && (
          <div
            className="mt-1 ml-3 dropDownList"
            style={{
              position: 'absolute', zIndex: 5, backgroundColor: '#F8F8F8', border: `1px solid ${Constants().color.inactive}`, borderRadius: 5, minWidth: 200,
            }}
          >
            {FILTER_TYPES[type].map((filter) => (!filter.disabled)
              && (this.renderFilterData(filter, type, values)))}
            <b role="presentation" className="d-flex mb-3 p-4 cursor" style={{ color: values ? Constants().color.primary : Constants().color.inactive }} onClick={() => values && this.updateFilter('clear', type)}> Clear </b>
          </div>
          )}
      </div>
    );
  }

  toggleTestReviewStatus() {
    const query = new URLSearchParams(window.location.search);
    const { id } = this.props.match.params;
    const {
      questionsAdded, questionsRemoved, overAllQuestionsAdded,
      overAllQuestionsRemoved, pageStartTime, sessionStartTime,
    } = this.state;
    const { isAutoTest, isReviewed } = this.props;
    const payload = {
      id,
      review: !isReviewed,
    };
    const subjectId = query.get('subject_id');
    const track = query.get('track');
    sendEvent('Assignment', 'UpdateReview', 'log', {
      ...payload,
      isAutoTest,
      subjectId,
      track,
      questionsAdded,
      questionsRemoved,
      overAllQuestionsAdded,
      overAllQuestionsRemoved,
      pageTimeSpent: this.getTimeSpent(pageStartTime),
      sessionTimeSpent: this.getTimeSpent(sessionStartTime),
    });
    this.props.updateTestReviewForAssignment(payload).then((result) => {
      if (!result.success) {
        alert(result.message);
      }
    });
    return true;
  }

  insightPoint(count, insightName, pageType) {
    const { ignoredInsight } = this.state;
    const query = new URLSearchParams(window.location.search);
    const { id } = this.props.match.params;
    return (
      <div key={pageType} className="row" style={{ width: '100%', paddingLeft: 20, marginTop: 10 }}>
        <div style={{ width: '80%' }}>
          <div style={{
            backgroundColor: '#565D62', display: 'inline-block', borderRadius: 10, width: 10, height: 10, marginRight: 20,
          }}
          />
          <span style={{ fontWeight: 'bold', fontSize: 14 }}>
            {count}
            {insightName}
          </span>
        </div>
        <div className="btn-group align-item-center" style={{ width: '20%', display: 'flex' }}>
          <button
            type="button"
            className="btn cursor"
            style={{
              borderColor: Constants().color.primary, color: Constants().color.primary, fontSize: 12, marginLeft: 15, height: 30, width: 100, fontWeight: 'bold',
            }}
            onClick={() => this.setState({ ignoredInsight: [...ignoredInsight, pageType] })}
          >
            Ignore
          </button>
          <button
            type="button"
            className="btn cursor"
            style={{
              backgroundColor: Constants().color.primary, color: 'white', fontSize: 12, marginLeft: 15, height: 30, width: 100,
            }}
            onClick={() => this.props.history.push(`/insight/${id}&type=${pageType}`)}
          >
            Fix it
          </button>
        </div>
      </div>
    );
  }

  toggleApproveModal(shouldApprove = false) {
    const { showApproveModal } = this.state;
    this.setState({ showApproveModal: !showApproveModal });
    if (shouldApprove) {
      this.toggleTestReviewStatus();
    }
  }

  toggleFeedbackModal(showFeedbackModal, showThankyouModal) {
    this.setState({ showFeedbackModal, showThankyouModal });
  }

  toggleThankyouModal(showThankyouModal) {
    this.setState({ showThankyouModal });
  }

  checkForTestViewStatus() {
    if (this.props.showTest) {
      this.props.showToast('Once the assignment is allowed for view, it can not be modified. ');
      return;
    }
    this.setState({ showConfirmation: true });
  }

  allowTestView() {
    this.setState({ showConfirmation: false });
    const query = new URLSearchParams(window.location.search);
    const { id } = this.props.match.params;
    sendEvent('Assignment', 'allowTestView', 'View', { id, value: 'Enabling...' });
    this.props.updateShowTest(id).then((result) => {
      if (!result.success) {
        this.props.showToast(result.message || 'Unable to allow test for view. Try again.');
      }
    });
  }

  toggleReportModal(questionId = '') {
    if (this.state.showReportError) {
      this.setState({ showReportError: false, questionId: '' });
      return;
    }
    this.setState({ showReportError: true, questionId });
  }

  renderSideBar() {
    const { selectedPage, showPageLoading, screenHeight } = this.state;
    const {
      progressiveSearchSelectedValues, chapters, checkpoints,
      selectedQuestionCount, totalQuestionsToBeSelected, isAssignment,
      isReviewed, sectionWiseQuestions, selectedTestGroup,
    } = this.props;
    if (showPageLoading) {
      return (
        <div />
      );
    }
    if (selectedPage === PAGETYPE.PICKQUESTION) {
      const headerText = selectedTestGroup.is_new_neet_pattern
        ? `Total questions picked - ( Section A: ${sectionWiseQuestions.A.length} / 35 questions, Section B: ${sectionWiseQuestions.B.length} / 15 questions )`
        : `Total questions picked - ( ${selectedQuestionCount} / ${totalQuestionsToBeSelected} questions )`;
      return (
        <div style={{ width: IS_MOBILE ? window.innerWidth - 40 : 300 }}>
          <div className="header_text">
            {headerText}
          </div>
          <div className="secondary_text mt-3"> Chapters </div>
          {this.progressiveSearch(chapters, progressiveSearchSelectedValues ? progressiveSearchSelectedValues.chapter : '', (selectedListItem) => this.updateProgressiveSearchField(selectedListItem, FILTERTYPES.CHAPTERS), 'Chapters')}
          <div className="secondary_text mt-3"> Topics and subtopics </div>
          <div style={{ overflowY: 'scroll', overflowX: 'hidden', height: screenHeight * 0.55 }}>
            {checkpoints.map((checkpoint) => checkpoint.chapter_id
              === (progressiveSearchSelectedValues.chapter && progressiveSearchSelectedValues.chapter.value)
              && (this.renderTopics(checkpoint)))}
          </div>
        </div>
      );
    }
    if (selectedPage === PAGETYPE.REVIEW) {
      const {
        questionDistribution, name, testDate,
        totalSelectedQuestionCount, selectedClassroomList,
        studentsCount, showTest, isOnline, dispatch,
      } = this.props;
      const {
        showApproveModal, showFeedbackModal,
        showThankyouModal, showConfirmation,
      } = this.state;
      const query = new URLSearchParams(window.location.search);
      const { id } = this.props.match.params;
      const subjectId = query.get('subject_id');
      const track = query.get('track');
      const chapterData = {};
      let checkpoint = '';
      Object.keys(questionDistribution).forEach((checkpointId) => {
        checkpoint = questionDistribution[checkpointId];
        chapterData[checkpoint.chapter_id] = chapterData[checkpoint.chapter_id]
          ? chapterData[checkpoint.chapter_id] : {
            id: checkpoint.chapter_id, name: checkpoint.chapter_name, count: 0, checkpoints: [],
          };
        chapterData[checkpoint.chapter_id].checkpoints.push({
          id: checkpointId,
          name: checkpoint.name,
          count: checkpoint.count,
        });
        chapterData[checkpoint.chapter_id].count += checkpoint.count;
      });
      let scrollViewHeight = screenHeight * 0.8;
      if (isAssignment && isReviewed) {
        scrollViewHeight -= 20;
      }
      return (
        <div style={{ width: 300 }}>
          {isAssignment && isReviewed
            && (
            <ConfirmationModal
              visible={showConfirmation}
              title="Are you sure?"
              description="This change is irreversible. Once the assignment is allowed for view, it can not be modified!"
              onConfirm={this.allowTestView}
              onClose={() => this.setState({ showConfirmation: false })}
              classrooms={selectedClassroomList}
              studentsCount={studentsCount}
            />
            )}

          <ApproveTestModal
            name={name}
            testDate={testDate}
            questionCount={totalSelectedQuestionCount}
            showApproveModal={showApproveModal}
            toggleApproveModal={this.toggleApproveModal}
            isOnline={isOnline}
          />

          <FeedBackModal
            referenceData={{ id, subjectId, track }}
            showModal={showFeedbackModal}
            toggleFeedbackModal={this.toggleFeedbackModal}
            dispatch={dispatch}
          />

          <ThankyouModal
            showModal={showThankyouModal}
            toggleThankyouModal={this.toggleThankyouModal}
          />

          <div className="pt-4 pl-2 pr-2" style={{ overflowY: 'scroll', overflowX: 'hidden', height: scrollViewHeight }}>
            <div className="pb-5" style={{ fontSize: 24, fontWeight: 'bold' }}> Question Distribution </div>
            {this.renderQuestionDistributionData()}
          </div>
          <div style={{
            width: 300, position: 'absolute', bottom: 90, backgroundColor: '#FBFBFB',
          }}
          >
            {isAssignment && isReviewed && (
              <div style={{ display: 'flex', marginBottom: 20, alignItems: 'center' }}>
                <input id="show-test" type="checkbox" checked={showTest} onChange={() => this.checkForTestViewStatus()} style={{ fontSize: 'x-large', marginTop: 0 }} />
                <label
                  style={{
                    fontSize: 16, fontWeight: 'bold', margin: 0, marginLeft: 10,
                  }}
                >
                  Allow students to view
                </label>
              </div>
            )}
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              <div role="presentation" className="secondary_button" style={{ width: isAssignment && !showTest ? '48%' : '100%' }} onClick={() => this.toggleFeedbackModal(true, false)}>FeedBack</div>
              {isAssignment && !showTest
                && (
                <div role="presentation" id="toggle-approval" className="primary_button" style={{ width: '48%' }} onClick={() => (isReviewed ? this.toggleTestReviewStatus() : this.toggleApproveModal())}>
                  {isReviewed ? 'Unapprove' : 'Approve' }
                </div>
                )}
            </div>
          </div>
        </div>
      );
    }
    return <div />;
  }

  renderProgressBar(complexity) {
    const { totalQuestionsToBeSelected, difficultyDistribution } = this.props;
    let progressBarWidth = difficultyDistribution[complexity]
      ? ((difficultyDistribution[complexity] / totalQuestionsToBeSelected) * 100) : 0;
    progressBarWidth = (progressBarWidth > 100) ? 100 : progressBarWidth;
    return (
      <div className="progress" style={{ boxShadow: 'none' }}>
        <div className="col-sm-3 complexity-tag">{COMPLEXITY[complexity]}</div>
        <div style={{ width: '100%', marginRight: 20 }}>
          <div className="progress-bar" style={{ width: `${progressBarWidth}%`, backgroundColor: DIFFICULTY_COLOR[complexity] }} />
        </div>
        <div className="complexity-count">{difficultyDistribution[complexity]}</div>
      </div>
    );
  }

  renderInsights() {
    const {
      difficultyDistribution, repeatedQuestionsCount,
      previousYearQuestions, selectedQuestionCount, isNumericalSupport,
      numericalQuestionCount, currentTrack,
    } = this.props;
    const freshQuestions = selectedQuestionCount - repeatedQuestionsCount;
    const otherTrack = (parseInt(currentTrack, 10) === 1) ? 2 : 1;
    const freshnessPercentage = parseInt((((freshQuestions) / selectedQuestionCount) * 100) || 0, 10);
    return (
      <div
        className="mr-5"
        style={{
          marginRight: 12, marginLeft: 0, display: 'flex', justifyContent: 'space-between',
        }}
      >
        <div style={{ width: (isNumericalSupport) ? '30%' : '40%', backgroundColor: '#FFFFFF', marginRight: 10 }} className="insights-container">
          <div style={{ width: '100%', backgroundColor: '#FFFFFF' }}>
            {Object.keys(difficultyDistribution).map((complexity) => this.renderProgressBar(complexity))}
          </div>
        </div>
        { isNumericalSupport
          && (
          <div
            className="insights-container"
            style={{
              width: '24%', backgroundColor: '#FFFFFF', justifyContent: 'center', marginRight: 10,
            }}
          >
            <div className="insight-card">
              <div style={{ display: 'flex' }}>
                <div className="previous-year-questions">
                  {numericalQuestionCount[currentTrack] || 0 }
                </div>
                {numericalQuestionCount[otherTrack]
                  && (
                  <div style={{ display: 'flex', alignItems: 'flex-end', paddingBottom: 3 }}>
                    <div>
                            &ensp;+
                      {numericalQuestionCount[otherTrack]}
                      (track
                      {otherTrack}
                      )
                    </div>
                  </div>
                  )}
              </div>
              <div>Numerical Questions</div>
            </div>
          </div>
          )}
        <div
          className="insights-container"
          style={{
            width: (isNumericalSupport) ? '24%' : '28%', backgroundColor: '#FFFFFF', justifyContent: 'center', marginRight: 10,
          }}
        >
          <div className="insight-card">
            <div className="previous-year-questions">
              {previousYearQuestions}
            </div>
            <div>Previous Year Questions</div>
          </div>
        </div>

        <div className="insights-container" style={{ width: (isNumericalSupport) ? '24%' : '28%', backgroundColor: '#FFFFFF', justifyContent: 'center' }}>
          <div className="insight-card">
            <div className="freshness-label">
              {freshnessPercentage}
              %
            </div>
            <div>
              (
              {freshQuestions}
              /
              {selectedQuestionCount}
              new questions )
            </div>
          </div>
        </div>

      </div>
    );
  }

  renderMainView() {
    const { selectedPage, pageStyle, showPageLoading } = this.state;
    if (showPageLoading) {
      return (
        <div>
          <Loading extraHeight={selectedPage === PAGETYPE.PICKQUESTION ? 300 : 0} />
        </div>
      );
    }
    if (selectedPage === PAGETYPE.PICKQUESTION || selectedPage === PAGETYPE.REVIEW) {
      return (
        <div ref={this.iScroll} onScroll={() => this.scrollListener()} style={pageStyle} className="hide-in-print">
          {this.renderQuestions()}
        </div>
      );
    }
    return (
      <div style={pageStyle} className="hide-in-print" />
    );
  }

  renderQuestionDistributionData() {
    const { questionDistribution } = this.props;
    const chapters = {};
    let checkpoint = '';
    Object.keys(questionDistribution).forEach((checkpointId) => {
      checkpoint = questionDistribution[checkpointId];
      chapters[checkpoint.chapter_id] = chapters[checkpoint.chapter_id]
        ? chapters[checkpoint.chapter_id] : {
          id: checkpoint.chapter_id, name: checkpoint.chapter_name, count: 0, checkpoints: [],
        };
      chapters[checkpoint.chapter_id].checkpoints.push({
        id: checkpointId,
        name: checkpoint.name,
        count: checkpoint.count,
      });
      chapters[checkpoint.chapter_id].count += checkpoint.count;
    });
    return (
      <div>
        {Object.values(chapters).map((chapter) => (
          <div key={`Chapter_${chapter.id}`}>
            <p className="d-flex ml-3" style={{ alignItems: 'center', fontSize: 16, color: 'black' }}>
              <b className="mr-4">{chapter.count}</b>
              <b>{chapter.name}</b>
            </p>
            <ul>
              {chapter.checkpoints.map((checkpointData) => (
                <li key={`Checkpoint_${checkpointData.id}`}>
                  <p className="d-flex" style={{ alignItems: 'center', fontSize: 14, color: '#808080' }}>
                    <b className="ml-4 mr-4">{checkpointData.count}</b>
                    {checkpointData.name}
                  </p>
                </li>
              ))}
            </ul>
          </div>
        ))}
        <style jsx>
          {`
          ul {
            padding: 0px;
            margin: 0px;
            list-style-type: none;
            position: relative;
          }
          li {
            padding-bottom: 5px;
            margin: 0;
            list-style-type: none;
            border-left: 2px solid ${Constants().color.inactive};
            margin-left: 1em;
          }
          li p {
            padding-left: 1em;
            position: relative;
          }
          li p::before {
            content:'';
            position: absolute;
            top: 0;
            left: -2px;
            bottom: 50%;
            width: 1.5em;
            border: 2px solid ${Constants().color.inactive};
            border-top: 0px;
            border-right: 0px;
          }
          ul > li:last-child {
            border-left: 2px solid transparent;
          }
        `}
        </style>
      </div>
    );
  }

  renderQuestions() {
    const {
      showReviewPage, questionSearchString, ignoredInsight, loadingState,
    } = this.state;
    const {
      selectedQuestionCount, totalQuestionsToBeSelected, progressiveSearchSelectedValues,
    } = this.props;
    const { id } = this.props.match.params;
    const totalQuestionCount = this.props.totalQuestionCount ? this.props.totalQuestionCount : 0;
    const { similarQuestionsCount, repeatedQuestions } = this.props.testInsight;
    const { filterName } = this.getSelectedFilters(progressiveSearchSelectedValues);
    const insightPoints = [
      { value: similarQuestionsCount, label: 'Set of similar questions in this paper', key: 'similar' },
      { value: repeatedQuestions, label: 'Questions are repeated from the previous assignment.', key: 'repeated' },
    ];
    const headerText = this.props.selectedTestGroup.is_new_neet_pattern
      ? `Review - ( Section A: ${this.props.sectionWiseQuestions.A.length} / 35 questions, Section B: ${this.props.sectionWiseQuestions.B.length} / 15 questions )`
      : `Review - ( ${selectedQuestionCount} / ${totalQuestionsToBeSelected} questions )`;
    return (
      <>
        {(false && showReviewPage)
          && typeof similarQuestionsCount !== 'undefined'
          && typeof repeatedQuestions !== 'undefined'
          && (similarQuestionsCount > 0 || repeatedQuestions > 0)
          && (
          <div>
            <div className="d-flex justify-content-between" style={{ width: '100%', paddingLeft: 20 }}>
              <span style={{ fontWeight: 'bold', fontSize: 18 }}>Improvements</span>
              <div>
                <button
                  type="button"
                  className="btn cursor"
                  style={{
                    borderColor: Constants().color.primary, color: Constants().color.primary, fontSize: 14, marginLeft: 15, height: 30, width: 100, fontWeight: 'bold',
                  }}
                  onClick={() => this.props.history.push(`/insight/${id}?type=${INSIGHTS.PROPERTIES}`)}
                >
                  More Info
                </button>
              </div>
            </div>
            <div className="shadow-sm p-3 mb-4 bg-white rounded align-item-center justify-content-center" style={{ backgroundColor: '#FFFFFF', paddingTop: 40, marginBottom: 20 }}>
              {insightPoints.map((data) => {
                return (ignoredInsight.indexOf(data.key) === -1
                && data.value > 0 && this.insightPoint(data.value,
                  data.label, data.key));
              })}
            </div>
          </div>
          )}
        {this.renderInsights()}
        {!(showReviewPage)
          && (
          <div className="mt-4 mb-2" style={{ fontSize: 24, fontWeight: 'bold' }}>
            {(questionSearchString === '')
              && (
              <div>
                {filterName}
                &nbsp;(&nbsp;
                {numberWithCommas(totalQuestionCount)}
                &nbsp;questions )
              </div>
              )}
            {!(questionSearchString === '')
              && (
              <div>
                Results for
                <b>
                  “
                  {questionSearchString}
                  ” in
                  {filterName}
                </b>
                &nbsp;(&nbsp;
                {numberWithCommas(totalQuestionCount)}
                  &nbsp;questions )
              </div>
              )}
          </div>
          )}
        <div className="input-group" style={{ width: '100%', margin: '10px 0px' }}>
          <div
            className="mr-5"
            style={{
              display: 'flex', justifyContent: 'space-between', border: '1px solid #9EA0A5', width: '100%', borderRadius: 4, height: 40,
            }}
          >
            <div style={{
              paddingLeft: 15, display: 'flex', alignItems: 'center', width: 40,
            }}
            >
              <img alt="" src={search} width="16" height="16" />
            </div>
            <div style={{ width: '100%', display: 'flex', justifyContent: 'space-between' }}>
              <div style={{ width: '100%', display: 'flex', alignItems: 'center' }}>
                <input
                  className="form-control"
                  id="AssignmentName"
                  onChange={(e) => {
                    this.setState({ questionSearchString: e.target.value });
                    this.questionSearch(e.target.value);
                  }}
                  type="text"
                  placeholder="Search for questions"
                  value={questionSearchString}
                  style={{
                    fontSize: 14, border: 'none', marginBottom: 0, height: 35, backgroundColor: '#FBFBFB',
                  }}
                />
              </div>
            </div>
          </div>
        </div>
        {(showReviewPage)
          && (
          <div className="row pl-4 pt-5 mb-2">
            <div style={{ fontSize: 24, fontWeight: 'bold', width: '100%' }}>
              {(questionSearchString === '') ? headerText : `Results for "${questionSearchString}" in Review `}
            </div>
          </div>
          )}
        <QuestionList
          match={this.props.match}
          loadingState={loadingState}
          showReviewPage={showReviewPage}
          searchString={questionSearchString}
          updateQuestions={this.updateQuestions}
          router={this.props.router}
          toggleNumericalQuestionState={this.toggleNumericalQuestionState}
        />
      </>
    );
  }

  renderFilters() {
    const { name, isNumericalSupport } = this.props;
    const {
      previous_paper, difficulty, question_type,
    } = this.props.appliedFilters;
    return (
      <div className="d-flex mt-2 pb-3 pr-5" style={{ backgroundColor: '#FBFBFB', borderBottom: `1px solid ${Constants().color.inactive}`, paddingTop: 15 }}>
        <div className="pl-5 pr-5" style={{ borderRight: `1px solid ${Constants().color.inactive}` }}>
          <div className="header_text mt-4" style={{ width: 300 }}>
            {name}
          </div>
        </div>
        <div className="d-flex flex-column pl-4 pr-3" style={{ width: '100%' }}>
          <div className="d-flex flex-row" style={{ justifyContent: 'space-between' }}>
            <div className="row pr-5" style={{ width: '100%', alignItems: 'center' }}>
              {this.customFilters('Difficulty', FILTERTYPES.DIFFICULTY, difficulty)}
              {isNumericalSupport
                && this.customFilters('Question Type', FILTERTYPES.QUESTION_TYPE, question_type)}
              {this.customFilters('Previous Paper', FILTERTYPES.PREVIOUS_PAPER, previous_paper)}
              {difficulty.length > 0
                && difficulty.map((selectedDifficulty) => this.renderSelectedFilters(
                  selectedDifficulty, FILTERTYPES.DIFFICULTY,
                ))}
              {question_type.length > 0
                && question_type.map((selectedQuestionType) => this.renderSelectedFilters(
                  selectedQuestionType, FILTERTYPES.QUESTION_TYPE,
                ))}
              {previous_paper.length > 0
                && previous_paper.map((selectedPreviousPaper) => this.renderSelectedFilters(
                  selectedPreviousPaper, FILTERTYPES.PREVIOUS_PAPER,
                ))}
            </div>
          </div>
        </div>
      </div>
    );
  }

  renderSubTopics(subCheckpoint, isLastTopic) {
    const { progressiveSearchSelectedValues } = this.props;
    const selectedSubCheckpoint = progressiveSearchSelectedValues
      ? progressiveSearchSelectedValues.sub_checkpoint : {};
    const selected = (subCheckpoint.value === (selectedSubCheckpoint
      ? selectedSubCheckpoint.value : null));
    return (
      <div
        role="presentation"
        className="cursor pl-4 pr-4"
        key={`SubTopic_${subCheckpoint.value}`}
        style={{
          borderBottom: !isLastTopic && `0.5px solid ${Constants().color.inactive}`, padding: 10, backgroundColor: selected ? Constants().color.primary : 'white', color: selected ? 'white' : '#343B40', textOverflow: 'ellipsis', overflow: 'hidden', whiteSpace: 'nowrap', fontWeight: '500', borderRadius: isLastTopic && 5, fontSize: 14,
        }}
        onClick={() => this.updateProgressiveSearchField(subCheckpoint, FILTERTYPES.SUBTOPICS)}
      >
        {subCheckpoint.label}
      </div>
    );
  }

  renderTopics(checkpoint) {
    const { subCheckpoints, progressiveSearchSelectedValues, questionDistribution } = this.props;
    const selectedCheckpoint = progressiveSearchSelectedValues ? progressiveSearchSelectedValues.checkpoint : '';
    const selectedSubCheckpoint = progressiveSearchSelectedValues ? progressiveSearchSelectedValues.sub_checkpoint : '';
    const count = (questionDistribution && questionDistribution[checkpoint.value]) ? questionDistribution[checkpoint.value].count : 0;
    const selected = checkpoint.value === (selectedCheckpoint ? selectedCheckpoint.value : null);
    let backgroundColor = 'white';
    let color = '#343B40';
    if (selected) {
      if (selectedSubCheckpoint) {
        backgroundColor = 'white';
        color = Constants().color.primary;
      } else {
        backgroundColor = Constants().color.primary;
        color = 'white';
      }
    }
    return (
      <div className="mb-4 mt-3" key={`CheckpointId_${checkpoint.value}`}>
        <div
          role="presentation"
          className="cursor d-flex pl-4"
          style={{
            elevation: 10, border: `1px solid ${Constants().color.inactive}`, borderRadius: 5, backgroundColor, color, fontWeight: 'bold', boxShadow: selected && '0px 3px 15px #e2e2e2', height: 50, alignItems: 'center', fontSize: 16,
          }}
          onClick={() => this.updateProgressiveSearchField(checkpoint, FILTERTYPES.TOPICS)}
        >
          <div style={{
            border: '1px solid', borderColor: color, borderRadius: 5, textAlign: 'center', height: 25, width: 25, fontWeight: 'bold',
          }}
          >
            {count}
          </div>
          <div className="col-sm-10" style={{ textOverflow: 'ellipsis', overflow: 'hidden', whiteSpace: 'nowrap' }}>
            {checkpoint.label}
          </div>
        </div>
        {selected
          && (
          <div className="ml-3 mr-3" style={{ boxShadow: '0px 2px 5px #dfdfdf', border: `1px solid ${Constants().color.inactive}`, borderRadius: 5 }}>
            {subCheckpoints.map((subCheckpoint, index) => (this.renderSubTopics(subCheckpoint,
              (index + 1 === subCheckpoints.length))))}
          </div>
          )}
      </div>
    );
  }

  renderFilterData(filter, type, selectedValues) {
    const payloadValues = [];
    selectedValues.forEach((fieldVal) => payloadValues.push(fieldVal.value));
    const selected = payloadValues.indexOf(filter.value) > -1;
    return (
      <div className={IS_MOBILE ? 'pl-5' : 'p-4'}>
        <div role="presentation" className="d-flex flex-row  cursor" style={{ alignItems: 'center' }} onClick={() => this.updateFilter(filter, type)}>
          <input className="cursor" type="checkbox" checked={selected} style={{ fontSize: 'x-large', marginTop: 0 }} />
          <label
            className="cursor"
            style={{
              fontSize: 16, fontWeight: '400', margin: 0, marginLeft: 10,
            }}
          >
            {filter.label}
          </label>
        </div>
      </div>
    );
  }

  renderSelectedFilters(selectedFieldValue, value) {
    return (
      <div style={selectedFilterStyle}>
        <div style={{ padding: '2px 16px 2px 2px', cursor: 'default' }}>{selectedFieldValue.label}</div>
        <div role="presentation" style={{ cursor: 'pointer' }} onClick={() => this.updateFilter(selectedFieldValue, value)}>
          <img alt="" src={whiteClose} width="10" height="10" />
        </div>
      </div>
    );
  }

  renderChapterTopicSelection() {
    const { selectedChapter, selectedCheckpoint } = this.state;
    const selectedChapterId = selectedChapter && selectedChapter.value;
    const selectedCheckpointId = selectedCheckpoint && selectedCheckpoint.value;
    const {
      chapters, checkpoints, progressiveSearchSelectedValues, isTestDataEditable,
    } = this.props;
    return (
      <>
        <MobileHeader name="Chapters and Topics" showCloseButton onImageClick={() => this.setState({ showChapterSelection: false, selectedChapter: progressiveSearchSelectedValues.chapter, selectedCheckpoint: progressiveSearchSelectedValues.checkpoint })} />
        <div style={{ padding: 20, marginTop: 60, marginBottom: 60 }}>
          {chapters.map((chapter) => (
            <div style={{
              marginBottom: 20, display: 'flex', flexDirection: 'column', alignItems: 'center',
            }}
            >
              <div
                role="presentation"
                onClick={() => this.setState({ selectedChapter: chapter, selectedCheckpoint: '' })}
                style={{
                  width: '100%', height: 40, border: `1px solid ${selectedChapterId === chapter.value ? Constants().color.primary : '#ddd'}`, borderRadius: 4, display: 'flex', padding: '0px 20px', justifyContent: 'space-between', alignItems: 'center', backgroundColor: selectedChapterId === chapter.value && '#EBE8FF', color: selectedChapterId === chapter.value && Constants().color.primary,
                }}
              >
                {chapter.label}
                <img src={dropDownArrow} alt="x-mark" />
              </div>
              {selectedChapter && selectedChapterId === chapter.value
                && (
                <div style={{
                  width: window.innerWidth - 80, border: '1px solid #ddd', borderRadius: 4, display: 'flex', flexDirection: 'column', backgroundColor: '#F2F2F2',
                }}
                >
                  {checkpoints.map((checkpoint) => checkpoint.chapter_id === chapter.value && (
                  <div
                    role="presentation"
                    onClick={() => this.setState({ selectedCheckpoint: checkpoint })}
                    style={{
                      padding: 20, borderBottom: '1px solid #ddd', color: selectedCheckpointId === checkpoint.value && Constants().color.primary, backgroundColor: selectedCheckpointId === checkpoint.value && '#EBE8FF',
                    }}
                  >
                    {checkpoint.label}
                  </div>
                  ))}
                </div>
                )}
            </div>
          ))}
          {isTestDataEditable && (
          <div
            role="presentation"
            style={{ marginTop: 20, color: Constants().color.primary }}
            onClick={() => this.updateProgressiveSearchField({ value: 'navigateToChapterSelection' }, '')}
          >
            Add or Remove Chapter
          </div>
          )}
        </div>
        <div
          role="presentation"
          onClick={() => (selectedCheckpoint ? this.updateProgressiveSearchField(selectedCheckpoint,
            FILTERTYPES.TOPICS) : this.updateProgressiveSearchField(selectedChapter,
            FILTERTYPES.CHAPTERS))}
          style={{
            display: 'flex', position: 'fixed', bottom: 0, backgroundColor: Constants().color.primary, color: 'white', height: 50, alignItems: 'center', justifyContent: 'center', width: window.innerWidth,
          }}
        >
          Apply
        </div>
      </>
    );
  }

  renderMobileFilterElements(name, type, values) {
    return (
      <div style={{ borderBottom: '1px solid #ddd', padding: '20px 20px 0px 20px' }}>
        <div style={{ color: 'black', fontWeight: 'bold' }}>
          {name}
        </div>
        {FILTER_TYPES[type].map((filter) => (!filter.disabled)
          && this.renderFilterData(filter, type, values))}
      </div>
    );
  }

  renderMobileFilters() {
    const { previous_paper, difficulty } = this.state.userAppliedFilters;
    return (
      <>
        <MobileHeader name="Picked Questions" showCloseButton onImageClick={() => this.setState({ showFilters: false })}>
          <div role="presentation" style={{ padding: 10, color: Constants().color.primary }} onClick={() => this.updateFilter('clear_all', '')}> Clear All </div>
        </MobileHeader>
        <div style={{ marginTop: 60, marginBottom: 60 }}>
          {this.renderMobileFilterElements('Difficulty', FILTERTYPES.DIFFICULTY, difficulty)}
          {this.renderMobileFilterElements('Previous Paper', FILTERTYPES.PREVIOUS_PAPER, previous_paper)}
        </div>
        <div
          role="presentation"
          onClick={() => this.updateFilter('', '', true)}
          style={{
            display: 'flex', position: 'fixed', bottom: 0, backgroundColor: Constants().color.primary, color: 'white', height: 50, alignItems: 'center', justifyContent: 'center', width: window.innerWidth,
          }}
        >
          Apply
        </div>
      </>
    );
  }

  renderMobile() {
    const {
      totalQuestionCount, totalQuestionsToBeSelected, selectedQuestionCount,
      isReviewed, isTestDataEditable,
    } = this.props;
    const {
      pageStyle, showOptions, showQuestionDistribution,
      selectedPage, showReportError, questionId, showChapterSelection,
      showPageLoading, showFilters, selectedChapter, selectedCheckpoint,
    } = this.state;
    if (showPageLoading) {
      return <Loading />;
    }
    const query = new URLSearchParams(window.location.search);
    const { id } = this.props.match.params;
    const subjectId = query.get('subject_id');
    const track = query.get('track');
    const pageHeaderText = selectedPage === PAGETYPE.REVIEW ? 'Total questions picked' : selectedCheckpoint ? selectedCheckpoint.label : selectedChapter ? selectedChapter.label : 'Question Pick';
    const pageSubHeaderText = selectedPage === PAGETYPE.REVIEW ? ` - ${selectedQuestionCount} / ${totalQuestionsToBeSelected}` : `(${totalQuestionCount})`;
    return (
      <>
        {showQuestionDistribution
          && (
          <>
            <MobileHeader name="Question Distribution" showCloseButton onImageClick={() => this.setState({ showQuestionDistribution: false })} />
            <div style={{
              overflowY: 'scroll', overflowX: 'hidden', height: window.innerHeight - 60, padding: 20, marginTop: 60,
            }}
            >
              {this.renderQuestionDistributionData()}
            </div>
          </>
          )}
        {showReportError
          && (
          <>
            <MobileHeader name="Report Error" showCloseButton onImageClick={() => this.toggleReportModal()} />
            <div style={{
              overflowY: 'scroll', overflowX: 'hidden', height: window.innerHeight - 60, padding: 20, marginTop: 60,
            }}
            >
              <ReportError isMobile questionId={questionId} router={this.props.router} closeReportModal={this.toggleReportModal} />
            </div>
          </>
          )}
        {showFilters && this.renderMobileFilters()}
        {showChapterSelection && this.renderChapterTopicSelection()}
        <div style={{ display: (showQuestionDistribution || showReportError || showFilters || showChapterSelection) && 'none' }}>
          <MobileHeader
            color="white"
            backgroundColor={Constants().color.primary}
            name={pageHeaderText}
            headerContext={pageSubHeaderText}
            showBackButton
            onImageClick={() => {
              if (isTestDataEditable) {
                this.props.history.push(`/distribution_selection/${id}?subject_id=${subjectId}&track=${track}`);
              } else {
                this.props.history.push(`/test_creation_overview/${id}`);
              }
              this.setState({ showPageLoading: true });
            }}
          />
          <div style={{ marginTop: 60 }} />
          {!isReviewed
          && (
          <div style={{
            display: 'flex', width: '100%', backgroundColor: Constants().color.primary, height: 40,
          }}
          >
            <div
              role="presentation"
              style={{
                display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'space-between', width: '50%', marginTop: 10, color: 'white',
              }}
              onClick={() => this.toggleSelectedPage(PAGETYPE.PICKQUESTION)}
            >
              Pick Question
              {selectedPage === PAGETYPE.PICKQUESTION && (
              <div style={{
                marginTop: -4, height: 4, width: 110, backgroundColor: '#fff', alignSelf: 'center', borderTopLeftRadius: 5, borderTopRightRadius: 5,
              }}
              />
              )}
            </div>
            <div style={{ borderRight: '1px solid #ddd', margin: '10px 0px 10px 0px' }} />
            <div
              role="presentation"
              style={{
                display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'space-between', width: '50%', marginTop: 10, color: 'white',
              }}
              onClick={() => this.toggleSelectedPage(PAGETYPE.REVIEW)}
            >
              Review
              {selectedPage === PAGETYPE.REVIEW && (
              <div style={{
                marginTop: -4, height: 4, width: 60, backgroundColor: '#fff', alignSelf: 'center', borderTopLeftRadius: 5, borderTopRightRadius: 5,
              }}
              />
              )}
            </div>
          </div>
          )}
          <div style={{ display: 'flex', boxShadow: '0px 3px 6px #343B4014' }}>
            <div style={{
              margin: '10px 0px 10px 0px', display: 'flex', height: 30, width: '50%', alignItems: 'center', justifyContent: 'center', borderRight: '1px solid #ddd', fontSize: 12,
            }}
            >
              Show Options
              <>
                <input
                  checked={showOptions}
                  onChange={() => this.setState({ showOptions: !showOptions })}
                  className="react-switch-checkbox"
                  id="react-switch-new"
                  type="checkbox"
                />
                <label
                  style={{ backgroundColor: showOptions && Constants().color.primary, marginLeft: 10, marginBottom: 0 }}
                  className="react-switch-label"
                  htmlFor="react-switch-new"
                >
                  <span className="react-switch-button" />
                </label>
              </>
            </div>
            <div
              role="presentation"
              onClick={() => this.setState({ showQuestionDistribution: true })}
              style={{
                display: 'flex', height: 50, width: '50%', alignItems: 'center', justifyContent: 'center', fontSize: 12, lineHeight: 0,
              }}
            >
              Question Distribution
              <img alt="" src={arrowRight} width={10} height={10} style={{ marginLeft: 5 }} />
            </div>
          </div>
          <div
            ref={this.iScroll}
            onScroll={() => this.scrollListener()}
            style={{
              ...pageStyle, marginTop: 20, height: window.innerHeight - 170, display: 'flex', width: window.innerWidth, justifyContent: 'center',
            }}
            className="hide-in-print"
          >
            <QuestionList match={this.props.match} loadingState={this.state.loadingState} showReviewPage={this.state.showReviewPage} updateQuestions={this.updateQuestions} router={this.props.router} toggleNumericalQuestionState={this.toggleNumericalQuestionState} isMobileView showOnlyQuestionText={!showOptions} selectedPage={selectedPage} showReportModal={this.toggleReportModal} />
          </div>
          {selectedPage === PAGETYPE.PICKQUESTION
          && (
          <div style={{
            display: 'flex', width: '100%', position: 'fixed', bottom: 0, height: 50, fontSize: 16, fontWeight: 'bold', color: 'white', zIndex: 1,
          }}
          >
            <div
              role="presentation"
              onClick={() => this.setState({ showFilters: true })}
              style={{
                display: 'flex', alignItems: 'center', justifyContent: 'center', width: '40%', height: '100%', backgroundColor: '#454545',
              }}
            >
              Filter
            </div>
            <div
              role="presentation"
              onClick={() => this.setState({ showChapterSelection: true })}
              style={{
                display: 'flex', alignItems: 'center', justifyContent: 'center', width: '60%', height: '100%', backgroundColor: Constants().color.primary,
              }}
            >
              Chapters & Topics
            </div>
          </div>
          )}
          {selectedPage === PAGETYPE.REVIEW
          && (
          <div
            role="presentation"
            onClick={() => this.props.history.push(`/test_creation_overview/${id}`)}
            style={{
              display: 'flex', alignItems: 'center', justifyContent: 'center', width: '100%', position: 'fixed', bottom: 0, height: 50, fontSize: 16, fontWeight: 'bold', color: 'white', backgroundColor: Constants().color.primary,
            }}
          >
            Change Subject
          </div>
          )}
        </div>
      </>
    );
  }

  render() {
    const {
      selectedPage, screenHeight, screenWidth, previousPage, showPageLoading,
    } = this.state;
    const {
      isAssignment, history, permissionedDenied, cannotPickQuestion,
    } = this.props;
    const query = new URLSearchParams(window.location.search);
    const { id } = this.props.match.params;
    const subjectId = query.get('subject_id');
    const track = query.get('track');
    if (permissionedDenied) {
      return <AccessDenied />;
    }
    if (IS_MOBILE === '') {
      return <div />;
    }
    if (IS_MOBILE) {
      return this.renderMobile();
    }
    return (
      <Layout globalHistory={history} title={isAssignment ? 'Assignment Page' : 'Test Page'}>
        <ReactTooltip />
        <div
          style={{
            width: 70, height: '100%', backgroundColor: Constants().color.primary, position: 'fixed', zIndex: '1', boxShadow: '0 3px 6px 0 rgba(0,0,0,0.16)',
          }}
          className="hide-in-print"
        >
          <SideNavigationBar globalHistory={history} subjectId={subjectId} track={track} id={id} selectedPage={selectedPage} previousPage={previousPage} toggleSelectedPage={this.toggleSelectedPage} cannotPickQuestion={cannotPickQuestion === 'true'} />
        </div>
        <div>

          <div
            style={{
              width: screenWidth - 70, marginLeft: 60, position: 'fixed', height: screenHeight,
            }}
            className="hide-in-print pl-4"
          >
            {selectedPage === PAGETYPE.PICKQUESTION && !showPageLoading && this.renderFilters()}
            <div className="d-flex" style={{ height: '100%', width: '100%' }}>
              <div
                className="p-5"
                style={{
                  height: screenHeight - 70, backgroundColor: '#FBFBFB', borderRight: `1px solid ${Constants().color.inactive}`, display: showPageLoading && 'none',
                }}
              >
                {this.renderSideBar()}
              </div>
              <div className="d-flex flex-column p-4" style={{ backgroundColor: '#F8F8F8', width: '100%' }}>
                {this.renderMainView()}
              </div>
            </div>
          </div>
        </div>
      </Layout>
    );
  }
}

const mapStateToProps = ({ assignment, questionPaperInsights }) => ({
  questions: assignment.questions,
  isTestDataEditable: assignment.isTestDataEditable,
  currentTrack: assignment.testInfo.current_track,
  name: assignment.testInfo.name,
  testDate: assignment.testInfo.test_date,
  showTest: assignment.showTest,
  isAutoTest: assignment.testInfo.is_auto_test,
  isOnline: assignment.testInfo.is_online,
  isNumericalSupport: assignment.testInfo.is_numerical_support,
  cannotPickQuestion: assignment.testInfo.cannot_pick_questions,
  assignmentQuestionIds: assignment.assignmentQuestionIds,
  numericalQuestionIds: assignment.numericalQuestionIds,
  numericalQuestionCount: assignment.numericalQuestionCount,
  totalQuestionCount: assignment.totalQuestionCount,
  questionDistribution: assignment.questionDistribution,
  chapters_list: assignment.chapters_list,
  checkpoints_list: assignment.checkpoints_list,
  chapters: assignment.chapters,
  units: assignment.units,
  checkpoints: assignment.checkpoints,
  subCheckpoints: assignment.subCheckpoints,
  progressiveSearchSelectedValues: assignment.progressiveSearchSelectedValues,
  renderMoreQuestions: assignment.renderMoreQuestions,
  selectedQuestionCount: assignment.selectedQuestionCount,
  questionIdsLoading: assignment.questionIdsLoading,
  students: assignment.students,
  isAssignment: assignment.testInfo.is_assignment,
  selectedTestGroup: assignment.selectedTestGroup,
  sectionWiseQuestions: assignment.sectionWiseQuestions,
  selectedClassroomList: assignment.selectedClassroomList,
  reporterMode: assignment.reporterMode,
  appliedFilters: assignment.appliedFilters,
  isReviewed: assignment.testInfo.is_reviewed,
  testInsight: assignment.testInsight,
  totalSelectedQuestionCount: assignment.totalSelectedQuestionCount,
  totalQuestionsToBeSelected: assignment.totalQuestionsToBeSelected,
  difficultyDistribution: questionPaperInsights.difficultyDistribution,
  difficultyLevel: questionPaperInsights.difficultyLevel,
  repeatedQuestionsCount: questionPaperInsights.repeatedQuestionsCount,
  previousYearQuestions: questionPaperInsights.previousYearQuestions,
  isTrial: assignment.isTrial,
  permissionedDenied: assignment.permissionedDenied,
  studentsCount: assignment.studentsCount,
});

export default connect(mapStateToProps, {
  getAssignmentQuestions,
  updateAssignmentQuestions,
  updateLoader,
  updateTestReviewForAssignment,
  showToast,
  updateNumericalQuestions,
  updateShowTest,
})(NewAssignment);
