import React from 'react';
import { connect } from 'react-redux';
import Constants from '../../shared/constants';
import Modal from '../modal/baseModal';
import { sendUserFeedback } from '../../actions';
import { getToken } from '../../helpers/Api';

class FeedBack extends React.Component {
  constructor(props) {
    super(props);
    const isTokenPresent = getToken();
    this.state = {
      showFeedbackModal: false,
      value: '',
      buttonState: false,
      name: '',
      email: '',
      contact: '',
      isTokenPresent,
    };
    this.toggleFeedbackModal = this.toggleFeedbackModal.bind(this);
  }

  toggleFeedbackModal() {
    const { showFeedbackModal } = this.state;
    this.setState({
      showFeedbackModal: !showFeedbackModal,
    });
  }

  submitFeedback() {
    const {
      value, isTokenPresent, contact, name, email, showFeedbackModal,
    } = this.state;
    if (!value || (!isTokenPresent && !contact)) {
      if (!value) {
        alert('Please specify your concern');
      } else {
        alert('Kindly specify your contact information');
      }
    } else {
      const feedback = isTokenPresent ? `${value} - ${contact}` : `${value} - Name: ${name} - Phone: ${contact} - Email: ${email}`;
      this.props.sendUserFeedback(feedback, 'Suggestion Received');
      this.setState({
        showFeedbackModal: !showFeedbackModal,
        value: '',
        name: '',
        email: '',
        contact: '',
      });
    }
  }

  handleChange(type, value) {
    this.setState({ [type]: value });
  }

  updateButtonState(status) {
    this.setState({ buttonState: status });
  }

  render() {
    const { colorStyle, fillButton } = this.props;
    const {
      buttonState, showFeedbackModal, name, email, contact,
    } = this.state;
    const borderColor = colorStyle;
    const { isTokenPresent } = this.state;
    let color = 'white';
    let backgroundColor = colorStyle;
    if (fillButton && !buttonState) {
      color = colorStyle;
      backgroundColor = 'white';
    }
    return (
      <>
        <Modal showModal={showFeedbackModal} top="40%" left="35%" handleCloseModal={() => this.handleChange('showFeedbackModal', false)} header="Talk to Us">
          <div>
            {(!isTokenPresent)
              && (
              <input
                onChange={(event) => this.handleChange('name', event.target.value)}
                type="text"
                className="form-control mt-2"
                placeholder="Enter Name"
                value={name}
                style={{
                  backgroundColor: '#ffffff', fontSize: '14px', width: '94%', marginLeft: '3%',
                }}
                autoFocus
              />
              )}
            {(!isTokenPresent)
              && (
              <input
                onChange={(event) => this.handleChange('email', event.target.value)}
                type="text"
                className="form-control mt-2"
                placeholder="Enter your email"
                value={email}
                style={{
                  backgroundColor: '#ffffff', fontSize: '14px', width: '94%', marginLeft: '3%',
                }}
              />
              )}
            {(!isTokenPresent)
              && (
              <input
                onChange={(event) => this.handleChange('contact', event.target.value)}
                type="text"
                className="form-control mt-2"
                placeholder="Enter your phone"
                value={contact}
                style={{
                  backgroundColor: '#ffffff', fontSize: '14px', width: '94%', marginLeft: '3%',
                }}
              />
              )}
            <div className="col-sm-12 mt-2"><textarea rows="10" style={{ height: '200px', width: '100%' }} cols="44" value={this.state.value} onChange={(event) => this.handleChange('value', event.target.value)} placeholder="Type your message here..." autoFocus={!!isTokenPresent} /></div>
            <div className="row justify-content-center">
              <div
                role="presentation"
                className="btn btn-lg"
                style={{
                  backgroundColor: colorStyle, color: 'white', fontSize: 16, width: '90%', height: 50, padding: 12,
                }}
                onClick={() => this.submitFeedback()}
              >
                <b>Submit</b>
              </div>

            </div>
            <div className="col-sm-12 mt-4 mb-4">
              <p style={{ marginBottom: '0', fontSize: 14, textAlign: 'center' }}>
                Alternatively, drop us an email at
                <strong>{Constants().email}</strong>
              </p>
              <p style={{ marginBottom: '0', fontSize: 14, textAlign: 'center' }}>
                or give us a call at  &nbsp;
                <strong>{Constants().contact}</strong>
              </p>
            </div>
          </div>
        </Modal>
        <button
          className="Button"
          style={{
            fontWeight: 'bold', fontSize: 14, backgroundColor: color, color: backgroundColor, borderColor, width: 160, borderRadius: 0, height: 40, marginBottom: 0,
          }}
          type="button"
          onClick={() => this.toggleFeedbackModal()}
          onFocus={() => this.updateButtonState(true)}
          onMouseOver={() => this.updateButtonState(true)}
          onMouseLeave={() => this.updateButtonState(false)}
        >
          Talk to Us
        </button>
      </>
    );
  }
}

export default connect(null, { sendUserFeedback })(FeedBack);
