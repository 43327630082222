import { GET_CHAPTER_LIST, GET_QUESTION_FROM_CHAPTER, UPDATE_QUESTION_IMAGE_SIZE } from '../actions';

const initialState = {
  chapterList: [],
  question: {},
};

export default function (state = initialState, action) {
  switch (action.type) {
    case GET_CHAPTER_LIST: {
      return { ...state, chapterList: action.payload };
    }
    case GET_QUESTION_FROM_CHAPTER: {
      return { ...state, question: action.payload };
    }
    case UPDATE_QUESTION_IMAGE_SIZE: {
      return { ...state, question: action.payload };
    }
    default:
      return state;
  }
}
