import Api from '../helpers/Api';

import { UpdateStatus } from '../constants';

export const TEST_OVERVIEW = 'testOverview/TEST_OVERVIEW';
export const UPDATE_QUESTION_LIST = 'testOverview/UPDATE_QUESTION_LIST';
export const UPDATE_FILTERS_TEST_OVERVIEW = 'testOverview/UPDATE_FILTERS_TEST_OVERVIEW';
export const RESET_FILTERS_TEST_OVERVIEW = 'testOverview/RESET_FILTERS_TEST_OVERVIEW';
export const UPDATE_DATA_MANAGEMENT = 'testOverview/UPDATE_DATA_MANAGEMENT';
export const UPDATE_DATA_MANAGEMENT_MODAL = 'testOverview/UPDATE_DATA_MANAGEMENT_MODAL';
export const STUDENT_RESULT_UPLOAD_STATUS_DELETE = 'testOverview/STUDENT_RESULT_UPLOAD_STATUS_DELETE';
export const UPDATE_TEST_DETAIL = 'testOverview/UPDATE_TEST_DETAIL';

export function getTestOverviewData(filters) {
  return (dispatch) => Api({
    method: 'post',
    url: '/dashboard/get_test_dashboard',
    data: filters,
  }).then((response) => {
    if (response.success) {
      const payload = {
        name: response.name,
        attendance: response.attendance,
        rankList: response.rank_list,
        graphData: response.graph_data,
        weakness: response.weakness,
        strengths: response.strengths,
        majorErrorQuestions: response.major_error_question,
        performanceTrend: response.performance_trend,
        scoreDistribution: response.score_distribution,
        campuses: response.campuses,
        classrooms: response.classrooms,
        subjects: response.subjects,
        tracks: response.tracks,
        subjectAvg: response.subject_avg,
        maxSubjectScore: response.max_subject_score,
        medianData: response.median_data,
      };
      dispatch({
        type: TEST_OVERVIEW,
        payload,
      });
    }
    return response;
  });
}

export function getTestData(payload) {
  return (dispatch) => Api({
    method: 'get',
    url: `dashboard/${payload.id}/get_test_data`,
  }).then((response) => {
    if (response.success) {
      dispatch({
        type: TEST_OVERVIEW,
        payload: {
          name: response.name,
          isOnline: response.is_online,
          testResponses: response.test_responses,
          status: response.status,
        },
      });
    }
    return response;
  });
}

export function getAbsenteeData(filters) {
  return (dispatch) => Api({
    method: 'post',
    url: '/dashboard/get_attendance_data',
    data: filters,
  }).then((response) => {
    if (response.success) {
      const payload = {
        name: response.name,
        attendance: response.attendance,
        absenteeList: response.absentee_list,
        absenteeRate: response.absentee_rate,
        campuses: response.campuses,
        classrooms: response.classrooms,
        subjects: response.subjects,
        tracks: response.tracks,
      };
      dispatch({
        type: TEST_OVERVIEW,
        payload,
      });
    }
    return response;
  });
}

export function getRankListData(filters) {
  return (dispatch) => Api({
    method: 'post',
    url: '/dashboard/get_rank_list_data',
    data: filters,
  }).then((response) => {
    if (response.success) {
      const payload = {
        name: response.name,
        rankList: response.rank_list,
        campuses: response.campuses,
        classrooms: response.classrooms,
        subjects: response.subjects,
        tracks: response.tracks,
        sampleTestId: response.sample_test_id,
        hasAccessToParentPortal: response.has_access_to_parent_portal,
      };
      dispatch({
        type: TEST_OVERVIEW,
        payload,
      });
    }
    return response;
  });
}

export function getTestComparison(filters) {
  return (dispatch) => Api({
    method: 'post',
    url: '/dashboard/get_tests_comparison',
    data: filters,
  }).then((response) => {
    if (response.success) {
      const payload = {
        testStudentDistribution: response.test_student_distribution,
        campuses: response.campuses,
        classrooms: response.classrooms,
        subjects: response.subjects,
        tracks: response.tracks,
        name: response.name,
        graphRangeValue: response.graph_range_value,
        subjectsMedian: response.subjects_median,
        previousTests: response.previous_tests,
        previousTestName: response.previous_test_name,
        currentTestPerformance: response.current_test_performance,
        previousTestPerformance: response.previous_test_performance,
        testMinScore: response.min_test_score,
      };
      dispatch({
        type: TEST_OVERVIEW,
        payload,
      });
    } else if (!response.success) {
      dispatch({
        type: TEST_OVERVIEW,
        payload: {
          pageError: true,
          campuses: response.campuses,
          classrooms: response.classrooms,
          subjects: response.subjects,
          tracks: response.tracks,
          name: response.name,
        },
      });
    }
  });
}

export function getTrackComparison(filters) {
  return (dispatch) => Api({
    method: 'post',
    url: '/dashboard/get_track_comparison',
    data: filters,
  }).then((response) => {
    if (response) {
      const payload = {
        subjectTrack1Comparison: response.subject_track1_comparison,
        subjectTrack2Comparison: response.subject_track2_comparison,
        track1PerformanceComparison: response.track1_performance_comparison,
        track2PerformanceComparison: response.track2_performance_comparison,
        trackSetter: response.track_setter,
        campuses: response.campuses,
        classrooms: response.classrooms,
        subjects: response.subjects,
        tracks: response.tracks,
        name: response.name,
      };
      dispatch({
        type: TEST_OVERVIEW,
        payload,
      });
    }
    return response;
  });
}

export function getScoreDistributionData(filters) {
  return (dispatch) => Api({
    method: 'post',
    url: '/dashboard/get_score_distribution',
    data: filters,
  }).then((response) => {
    if (response.success) {
      const payload = {
        name: response.name,
        scoreDistribution: response.score_distribution,
        campuses: response.campuses,
        classrooms: response.classrooms,
        subjects: response.subjects,
        tracks: response.tracks,
      };
      dispatch({
        type: TEST_OVERVIEW,
        payload,
      });
    }
    return response;
  });
}

export function getQuestionAnalysisData(filters) {
  return (dispatch) => Api({
    method: 'post',
    url: '/dashboard/get_question_analysis_data',
    data: filters,
  }).then((response) => {
    if (response.success) {
      const payload = {
        name: response.name,
        weakness: response.weakness,
        strengths: response.strengths,
        majorErrorCheckpoints: response.major_error_checkpoint,
        majorErrorQuestions: response.major_error_question,
        renderMoreQuestions: response.render_more_questions,
        campuses: response.campuses,
        classrooms: response.classrooms,
        subjects: response.subjects,
        tracks: response.tracks,
        userType: response.user_type,
        isSample: response.is_sample,
      };
      dispatch({
        type: TEST_OVERVIEW,
        payload,
      });
    }
    return response;
  });
}

export function loadMoreQuestionAnalysisQuestions(filters) {
  return (dispatch) => Api({
    method: 'post',
    url: '/dashboard/fetch_question_for_question_analysis',
    data: filters,
  }).then((response) => {
    if (response.success) {
      const payload = {
        majorErrorQuestions: response.major_error_question,
        renderMoreQuestions: response.render_more_questions,
      };
      dispatch({
        type: UPDATE_QUESTION_LIST,
        payload,
      });
    }
    return response;
  });
}

export function updateFilterData(type, value) {
  return (dispatch) => new Promise((resolve) => {
    const payload = { type, value };
    dispatch({ type: UPDATE_FILTERS_TEST_OVERVIEW, payload });
    resolve();
  });
}

export function resetFilters() {
  return (dispatch) => new Promise((resolve) => {
    dispatch({ type: RESET_FILTERS_TEST_OVERVIEW });
    resolve();
  });
}

export function updateDataManagementModal(bool) {
  return (dispatch) => new Promise((resolve) => {
    dispatch({ type: UPDATE_DATA_MANAGEMENT_MODAL, payload: bool });
    resolve();
  });
}

export function uploadStudentResultsDataManagement(file, testId) {
  return (dispatch) => Api({
    method: 'post',
    url: '/tests/reupload_student_result_file',
    attachment: file,
    type: 'spreadsheet',
    data: { test_id: testId },
  }).then((response) => {
    if (response.success) {
      dispatch({
        type: UPDATE_DATA_MANAGEMENT,
        payload: {
          id: testId,
          status: UpdateStatus.PROCESS,
        },
      });
      dispatch({
        type: UPDATE_DATA_MANAGEMENT_MODAL,
        payload: false,
      });
    }
  });
}

export function deleteStudentResultsDataManagemnet(testId, label, index) {
  return (dispatch) => {
    dispatch({
      type: UPDATE_DATA_MANAGEMENT,
      payload: {
        id: testId,
        status: UpdateStatus.PROCESS,
      },
    });
    Api({
      method: 'post',
      url: '/tests/delete_offline_test_file',
      data: { id: testId, file_name: label, index },
    }).then((response) => {
      if (response.success) {
        dispatch({
          type: STUDENT_RESULT_UPLOAD_STATUS_DELETE,
          status: response.status,
        });
      }
    });
  };
}

export function updateTestStatus(status) {
  return (dispatch) => new Promise((resolve) => {
    dispatch({ type: UPDATE_TEST_DETAIL, status });
    resolve();
  });
}
