import CryptoJS from 'crypto-js';

import Constants from '../shared/constants';

const chars = '0123456789ABCDEFGHIJKLMNOPQRSTUVWXTZabcdefghiklmnopqrstuvwxyz*&-%/!?*+=()';
const generateKey = (keyLength) => {
  let randomstring = '';

  for (let i = 0; i < keyLength; i += 1) {
    const rnum = Math.floor(Math.random() * chars.length);
    randomstring += chars[rnum];
  }
  return randomstring;
};

export const encrypt = (data) => {
  const JSEncryptModule = require('jsencrypt');
  const { publicKey } = Constants();
  const key = generateKey(50);
  const dataAsString = JSON.stringify(data);
  const aesEncrypted = CryptoJS.AES.encrypt(dataAsString, key);
  const aesKey = `${aesEncrypted.key}:::${aesEncrypted.iv}`;
  const encryptedMessage = aesEncrypted.toString();
  const rsaEncrypt = new JSEncryptModule.JSEncrypt();

  rsaEncrypt.setPublicKey(publicKey);
  const encryptedKey = rsaEncrypt.encrypt(aesKey);
  const payload = `${encryptedKey}:::${encryptedMessage}`;
  return payload;
};
