import { combineReducers } from "redux";

import Attendance from "./attendance";
import Auth from "./auth";
import Global from "./global";
import Test from "./test";
import SnackBar from "./snackBar";
import StudentReport from "./studentReport";
import TestOverview from "./testOverview";
import Assignment from "./assignment";
import ClassroomTeacher from "./classroomTeacher";
import FilesBoard from "./filesBoard";
import ImageQuestionView from "./imageQuestionView";
import Insight from "./insight";
import Institute from "./institute";
import LiveClassroom from "./liveClassroom";
import Notification from "./notification";
import Progress from "./progress";
import QuestionPaperInsights from "./questionPaperInsights";
import GetQuestions from "./getQuestions";
import QuestionSuggestionsList from "./questionSuggestionsList";
import Quickpoints from "./quickpoints";
import Quiz from "./quiz";
import Retag from "./retag";
import SortQuestions from "./sortQuestions";
import StudentDashboard from "./studentDashboard";
import TagComplexity from "./tagComplexity";
import TestConfiguration from "./testConfiguration";
import VirtualMeeting from "./virtualMeeting";
import BdeTools from "./bdeTools";
import AdmissionBoard from "./admissionBoard";
import HrBoard from "./hrBoard";

export default combineReducers({
  attendance: Attendance,
  auth: Auth,
  global: Global,
  home: Test,
  snackBar: SnackBar,
  studentReport: StudentReport,
  testOverview: TestOverview,
  assignment: Assignment,
  classroomTeacher: ClassroomTeacher,
  filesBoard: FilesBoard,
  imageQuestionView: ImageQuestionView,
  insight: Insight,
  institute: Institute,
  liveClassroom: LiveClassroom,
  notification: Notification,
  getQuestions: GetQuestions,
  progress: Progress,
  questionPaperInsights: QuestionPaperInsights,
  questionSuggestionsList: QuestionSuggestionsList,
  quickpoints: Quickpoints,
  quiz: Quiz,
  retag: Retag,
  sortQuestions: SortQuestions,
  studentDashboard: StudentDashboard,
  tagComplexity: TagComplexity,
  testConfiguration: TestConfiguration,
  virtualMeeting: VirtualMeeting,
  bdeTools: BdeTools,
  admissionBoard: AdmissionBoard,
  hrBoard: HrBoard,
});
