import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { getQuizQuestions, showToast } from '../../actions';
import { NewQuestion } from '../common';
import '../../styles/instituteOnBoarding.css';
import '../../static/tools.css';

class QuizQuestion extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      textChanges: {
        question_text: '',
        option_1: '',
        option_2: '',
        option_3: '',
        option_4: '',
        answer: '',
      },
      editableQuestionId: 0,
    };
  }

  componentDidMount() {
    const { id } = this.props;
    this.props.getQuizQuestions({ id });
  }

  handleChange(type, value) {
    const { textChanges } = this.state;
    textChanges[type] = value;
    this.setState({ textChanges });
  }

  renderTextArea(textType) {
    const type = textType.toLowerCase().split(' ').join('_');
    const { textChanges } = this.state;
    return (
      <textarea
        type="text"
        placeholder={type}
        value={textChanges[type]}
        id={type}
        style={{
          fontWeight: '400', display: 'flex', fontSize: '14px', width: (type === 'answer') ? '20%' : '100%', minHeight: (type === 'answer') && 'fit-content', padding: (type !== 'answer') && 10, overflow: 'auto', resize: 'none',
        }}
        onChange={(event) => this.handleChange(type, event.target)}
      />
    );
  }

  render() {
    const {
      quizQuestionList, isAddQuestion, updatedCount, shownQuizIds, removedIds, id, style,
    } = this.props;
    const { editableQuestionId } = this.state;
    return (
      <div
        className="Card-View"
        style={{
          marginTop: 30, fontSize: 16, padding: 80, ...style,
        }}
      >
        { isAddQuestion && (
          <div
            role="presentation"
            onClick={() => this.setState({ editableQuestionId: 0 })}
          >
            <NewQuestion
              id={id}
              updatedCount={updatedCount}
              edit={() => this.setState({ editableQuestionId: 0 })}
              renderPublishTestModal={this.renderPublishTestModal}
              removeNewQuestion={this.props.removeNewQuestion}
              isEditable={editableQuestionId === 0}
              resetEdit={() => this.setState({ editableQuestionId: 0 })}
              isNewQuestion
            />
          </div>
        )}
        {(quizQuestionList.map((quizQuestion, index) => (
          !removedIds.includes(quizQuestion.id) && (
          <NewQuestion
            id={id}
            question={quizQuestion}
            updatedCount={updatedCount}
            resetEdit={() => this.setState({ editableQuestionId: 0 })}
            key={`${quizQuestion.id} - ${updatedCount}`}
            index={index + 1}
            isEditable={editableQuestionId === quizQuestion.id}
            shownQuizIds={shownQuizIds}
            edit={() => {
              this.setState({ editableQuestionId: quizQuestion.id });
            }}
            updateShowStatus={this.props.updateShowStatus}
            renderPublishTestModal={this.renderPublishTestModal}
          />
          )
        )))}
      </div>
    );
  }
}

QuizQuestion.propTypes = {
  quizQuestionList: PropTypes.array,
  isAddQuestion: PropTypes.bool,
  removedIds: PropTypes.array,
  shownQuizIds: PropTypes.array,
  updatedCount: PropTypes.number,
  id: PropTypes.number,
  style: PropTypes.object,
};

QuizQuestion.defaultProps = {
  quizQuestionList: [],
  shownQuizIds: [],
  isAddQuestion: false,
  removedIds: [],
  updatedCount: 0,
  style: {},
  id: 0,
};

const mapStateToProps = ({ quiz }) => ({
  quizQuestionList: quiz.quizQuestionList,
  shownQuizIds: quiz.shownQuizIds,
  removedIds: quiz.removedIds,
});

export default connect(mapStateToProps, {
  getQuizQuestions,
  showToast,
})(QuizQuestion);
