import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { IntegratedQuizQuestionView } from '.';
import '../../static/tools.css';
import collapse from '../../static/images/collapse.svg';
import expand from '../../static/images/expand.svg';

import { updateAttendeesCount, updateLiveResults, showToast } from '../../actions';

export const LIVE_QUIZ_RESULT_UPDATE_CHANNEL = 'LiveQuizResultUpdateChannel';

class ShowQuizQuestion extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      show: false,
    };
    this.handleChange = this.handleChange.bind(this);
    this.updateShowQuestion = this.updateShowQuestion.bind(this);
    this.updateLiveResults = this.updateLiveResults.bind(this);
  }

  handleChange(type, value) {
    const { textChanges } = this.state;
    textChanges[type] = value;
    this.setState({ textChanges: { ...textChanges } });
  }

  isDataPresent(textChanges) {
    const { dispatch } = this.props;
    let optionArray = Array.from(new Set([textChanges.option_1,
      textChanges.option_2, textChanges.option_3, textChanges.option_4]));
    optionArray = optionArray.filter((e) => e);
    if (!textChanges.question_text) {
      dispatch(showToast('Question Text Should Be Present'));
      return false;
    }
    if (optionArray.length !== 4) {
      dispatch(showToast('All four options should be present and it should be unique'));
      return false;
    }
    if (!textChanges.answer) {
      dispatch(showToast('Atleast one option should be marked as correct'));
      return false;
    }
    return true;
  }

  updateShowQuestion() {
    const { textChanges } = this.state;
    const { id } = this.props.match.params;
    this.props.updateShowStatus([textChanges.id], textChanges.show, id);
  }

  updateLiveResults() {
    const {
      currentMeetingQuizQuestions, currentQuizQuestionIndex, dispatch,
    } = this.props;
    if (window.ZoomMtg) {
      window.ZoomMtg.getAttendeeslist({
        success(res) {
          dispatch(updateAttendeesCount(res && res.result
            && res.result.attendeesList && res.result.attendeesList.length));
        },
      });
    }
    dispatch(updateLiveResults(currentMeetingQuizQuestions[currentQuizQuestionIndex]
      && currentMeetingQuizQuestions[currentQuizQuestionIndex].id,
    currentMeetingQuizQuestions[currentQuizQuestionIndex]
    && currentMeetingQuizQuestions[currentQuizQuestionIndex].quiz_id));
  }

  showQuestion() {
    const {
      currentMeetingQuizQuestions, currentQuizQuestionIndex,
      updatedCount, questionsShown, isCompleted, questionsLocked,
    } = this.props;
    if (currentMeetingQuizQuestions[currentQuizQuestionIndex]) {
      return (
        <IntegratedQuizQuestionView
          question={{ ...currentMeetingQuizQuestions[currentQuizQuestionIndex] }}
          index={currentQuizQuestionIndex + 1}
          isCompleted={isCompleted}
          next={Object.keys(currentMeetingQuizQuestions).length !== (currentQuizQuestionIndex + 1)}
          prev={currentQuizQuestionIndex !== 0}
          isQuestionShown={[...questionsShown].includes(currentMeetingQuizQuestions[currentQuizQuestionIndex].id)}
          isQuestionLocked={[...questionsLocked].includes(currentMeetingQuizQuestions[currentQuizQuestionIndex].id)}
          key={updatedCount}
        />
      );
    }
    return (
      <div />
    );
  }

  showLiveResults() {
    const {
      attendeesCount, currentQuizQuestionAttemptedStudentsCount,
    } = this.props;

    return (
      <div style={{ width: '100%', fontSize: 24, paddingTop: 20 }}>
        <div style={{
          display: 'flex', justifyContent: 'space-between', fontSize: 20, paddingTop: 20,
        }}
        >
          <div style={{ display: 'flex', width: '25%' }}>
            {currentQuizQuestionAttemptedStudentsCount}
          </div>
          <div style={{ display: 'flex', width: '75%' }}>
            RESPONDED
          </div>
        </div>
        <div style={{
          display: 'flex', justifyContent: 'space-between', fontSize: 20, paddingBottom: 40, paddingTop: 20,
        }}
        >
          <div style={{ display: 'flex', width: '25%' }}>
            {(attendeesCount - currentQuizQuestionAttemptedStudentsCount - 1) > 0
              ? (attendeesCount - currentQuizQuestionAttemptedStudentsCount - 1) : 0 }
          </div>
          <div style={{ display: 'flex', width: '75%' }}>
            DID NOT RESPOND
          </div>
        </div>
        {this.renderOptionData()}
      </div>
    );
  }

  renderOptions(option) {
    const { optionData, currentMeetingQuizQuestions, currentQuizQuestionIndex } = this.props;
    const isAnswer = currentMeetingQuizQuestions[currentQuizQuestionIndex]
    && currentMeetingQuizQuestions[currentQuizQuestionIndex].answer === option;
    const percentage = optionData && parseInt(optionData[`option_${option}_percentage`], 10);
    if (!isAnswer) {
      return (
        <div style={{
          display: 'flex', color: isAnswer ? '#77B33D' : '#DD636E', fontSize: 20, paddingBottom: 20,
        }}
        >
          <div style={{ display: 'flex', width: '25%' }}>
            {`${percentage || 0} %`}
          </div>
          <div>
            {`OPTION ${option}`}
          </div>
        </div>
      );
    }
    return <div />;
  }

  renderOptionData() {
    return (
      <div style={{ marginTop: 20 }}>
        {Array.from(Array(4).keys()).map((option) => { return this.renderOptions(option + 1); })}
      </div>
    );
  }

  render() {
    const {
      showQuizQuestion, currentMeetingQuizQuestions, integrated,
      currentQuizQuestionIndex, questionsLocked, optionData,
    } = this.props;
    const { show } = this.state;
    const answer = currentMeetingQuizQuestions
    && currentMeetingQuizQuestions[currentQuizQuestionIndex]
    && currentMeetingQuizQuestions[currentQuizQuestionIndex].answer;
    const correctAnswerPercentage = optionData && parseInt(optionData[`option_${answer}_percentage`], 10);
    const image = show ? collapse : expand;
    if (((showQuizQuestion && integrated)) && Object.keys(currentMeetingQuizQuestions).length > 0) {
      return (
        <>
          <div style={{
            top: 90, left: 20, position: integrated && 'fixed', display: 'flex', justifyContent: 'flex-end', width: 375, zIndex: 1001, background: 'none',
          }}
          >
            <div style={{
              backgroundColor: 'white',
              borderRadius: 4,
              boxShadow: '0px 3px 15px rgba(65, 61, 74, 0.1)',
              width: 375,
              height: show ? 575 : 'fit-content',
              color: '#343737',
              userSelect: 'none',
              overflow: 'scroll',
              padding: '20px 25px',
              fontFamily: 'Roboto',
              border: '1px solid #343737',
            }}
            >
              <div
                role="presentation"
                style={{
                  cursor: 'pointer', display: 'flex', justifyContent: 'space-between', fontSize: 24, fontWeight: 'bold', paddingBottom: show && 20,
                }}
                onClick={() => { this.setState({ show: !show }); }}
              >
                <div style={{ marginRight: 10 }}>
                  {`Question ${currentQuizQuestionIndex + 1}`}
                </div>
                <div>
                  <img alt="" src={image} height="20px" width="20px" />
                </div>
              </div>
              {show && this.showQuestion()}
            </div>
          </div>
          {show && [...questionsLocked].includes(currentMeetingQuizQuestions[currentQuizQuestionIndex] && currentMeetingQuizQuestions[currentQuizQuestionIndex].id)
          && (
          <div style={{
            top: 90, left: 395, position: 'fixed', display: 'flex', justifyContent: 'flex-end', width: 375, zIndex: 1001, background: 'none', fontFamily: 'Roboto',
          }}
          >
            <div style={{
              backgroundColor: 'white',
              borderRadius: 4,
              boxShadow: '0px 3px 15px rgba(65, 61, 74, 0.1)',
              width: 375,
              height: 'fit-content',
              color: '#343737',
              userSelect: 'none',
              overflow: 'scroll',
              padding: '20px 25px',
              border: '1px solid #343737',
            }}
            >
              <div
                role="presentation"
                style={{
                  display: 'flex', justifyContent: 'space-between', fontSize: 22, color: '#77B33D',
                }}
              >
                <div style={{
                  display: 'flex', width: '25%', fontSize: 24, fontWeight: 'bold',
                }}
                >
                  {`${correctAnswerPercentage || 0} %`}
                </div>
                <div style={{ display: 'flex', width: '75%' }}>
                  ANSWERED CORRECTLY
                </div>
              </div>
              {show && this.showLiveResults()}
            </div>
          </div>
          )}
        </>
      );
    }
    return <div />;
  }
}

ShowQuizQuestion.propTypes = {
  showQuizQuestion: PropTypes.bool,
  currentMeetingQuizQuestions: PropTypes.array,
  integrated: PropTypes.bool,
  currentQuizQuestionIndex: PropTypes.number,
  questionsShown: PropTypes.array,
  questionsLocked: PropTypes.array,
  isCompleted: PropTypes.bool,
  updatedCount: PropTypes.number,
  attendeesCount: PropTypes.number,
  optionData: PropTypes.object,
  currentQuizQuestionAttemptedStudentsCount: PropTypes.number,
  router: PropTypes.object,
};

ShowQuizQuestion.defaultProps = {
  showQuizQuestion: false,
  integrated: false,
  currentQuizQuestionIndex: 0,
  isCompleted: false,
  currentMeetingQuizQuestions: [],
  questionsShown: [],
  questionsLocked: [],
  updatedCount: 0,
  attendeesCount: 0,
  optionData: {},
  router: {},
  currentQuizQuestionAttemptedStudentsCount: 0,
};

const mapStateToProps = ({ virtualMeeting }) => ({
  showQuizQuestion: virtualMeeting.showQuizQuestion,
  showLiveResults: virtualMeeting.showLiveResults,
  currentQuizQuestionIndex: virtualMeeting.currentQuizQuestionIndex,
  currentMeetingQuizQuestions: virtualMeeting.currentMeetingQuizQuestions,
  questionsShown: virtualMeeting.questionsShown,
  questionsLocked: virtualMeeting.questionsLocked,
  isCompleted: virtualMeeting.isCompleted,
  updatedCount: virtualMeeting.updatedCount,
  attendeesCount: virtualMeeting.attendeesCount,
  optionData: virtualMeeting.optionData,
  currentQuizQuestionAttemptedStudentsCount:
  virtualMeeting.currentQuizQuestionAttemptedStudentsCount,
});

export default connect(mapStateToProps)(ShowQuizQuestion);
