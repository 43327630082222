import React, { Component } from 'react';
import { connect } from 'react-redux';
import Select from 'react-select';
import { getClassroomTeacher, getSpecificClassroomTeacher, updateClassroomTeacher } from '../../actions';
import {
  CAMPUSES, CAMPUS_SELECTED, CLASSES, CLASSROOM_SELECTED, TEACHERS, TEACHER_SELECTED,
} from '../../constants';
import Layout from '../layout';

const TRACK = Object.freeze({
  TRACK_1: 1,
  TRACK_2: 2,
});

class ClassroomTeachers extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showLoader: false,
      campusSelected: '',
      classroomSelected: {},
      teacherSelection: {},
    };
    this.updateResults = this.updateResults.bind(this);
  }

  componentDidMount() {
    const { classrooms, currentClassroomID, classroomTeachers } = this.props;
    this.props.getClassroomTeacher().then(() => {
      const teacherSelection = new Map();
      if (classroomTeachers.length > 0) {
        classroomTeachers.forEach((teacher) => {
          teacherSelection.set({
            subject: teacher.subject_id,
            track: teacher.track_id,
          }, { label: teacher.name, value: teacher.teacher_id });
        });
      }
      const classroom = Object.values(classrooms).find((obj) => obj.id === currentClassroomID) || {};
      const classroomSelected = { label: classroom.name, value: classroom.id };
      this.setState({
        showLoader: false,
        campusSelected: '',
        classroomSelected,
        teacherSelection,
      });
    });
  }

  updateClassroomTeacher() {
    const { teacherSelection, classroomSelected } = this.state;
    const data = [];
    for (const [key, value] of teacherSelection) {
      data.push({
        subject: key.subject,
        track: key.track,
        teacherID: value.value,
      });
    }
    this.props.updateClassroomTeacher(data, classroomSelected.value);
  }

  updateResults(itemSelected, type, subjectID, trackID) {
    if (type === CAMPUS_SELECTED) {
      this.setState({ campusSelected: itemSelected });
    } else if (type === TEACHER_SELECTED) {
      const { teacherSelection } = this.state;
      const key = Array.from(teacherSelection.keys()).find((o) => o.subject === subjectID
      && o.track === trackID);
      teacherSelection.delete(key);
      teacherSelection.set({ subject: subjectID, track: trackID }, itemSelected);
      this.setState({ teacherSelection });
    } else {
      this.setState({ classroomSelected: itemSelected });
      this.props.getSpecificClassroomTeacher(itemSelected.value).then((obj) => {
        const classroomTeachers = obj.classroom_teachers;
        const teacherSelection = new Map();
        if (classroomTeachers.length > 0) {
          classroomTeachers.forEach((teacher) => {
            teacherSelection.set({
              subject: teacher.subject_id,
              track: teacher.track_id,
            }, { label: teacher.name, value: teacher.teacher_id });
          });
        }
        this.setState({ teacherSelection });
      });
    }
  }

  renderDropDowns(dataSet, selectedItem, selectedFunction, item, showFilter = true) {
    const selectBoxStyle = {
      control: (styles, state) => ({ ...styles, height: 40 }),
    };

    if (!showFilter) {
      return (
        <div style={{ margin: 10, minWidth: 100 }} />
      );
    }
    let filterItems = [];
    if (item !== TEACHERS) {
      for (let i = 0; i < dataSet.length; i++) {
        filterItems.push({ label: dataSet[i].name, value: dataSet[i].id });
      }
    } else {
      filterItems = dataSet;
    }

    return (
      <div style={{ width: 150, margin: 'auto' }}>
        <Select
          value={selectedItem}
          onChange={selectedFunction}
          options={filterItems}
          placeholder={item}
          styles={selectBoxStyle}
          isSearchable={false}
        />
      </div>
    );
  }

  renderDropdownMenu() {
    const { classrooms, campuses } = this.props;
    const { campusSelected, classroomSelected } = this.state;
    let classroomData = [];
    if (campusSelected) {
      classroomData = Object.values(classrooms).filter((classroom) => classroom.campus_id
      === campusSelected.value);
    }
    return (
      <div className="d-flex flex-row justify-content-between" style={{ paddingTop: '2%', marginLeft: '40%', width: '30%' }}>
        {this.renderDropDowns(Object.values(campuses), campusSelected,
          (selectedListItem) => this.updateResults(selectedListItem, CAMPUS_SELECTED), CAMPUSES)}
        {this.renderDropDowns(Object.values(classroomData), classroomSelected,
          (selectedListItem) => this.updateResults(selectedListItem, CLASSROOM_SELECTED), CLASSES, campusSelected)}
      </div>
    );
  }

  render() {
    const { subjects, allTeachers, history } = this.props;
    const { teacherSelection, classroomSelected } = this.state;

    return (
      <Layout globalHistory={history} feedback footerShow={false}>
        <div className="row" style={{ marginTop: '6%' }}>
          {this.renderDropdownMenu()}
          <div className="col-md-10 offset-1 table-responsive">
            <h5>{classroomSelected.label}</h5>
            <table className="table text-center" style={{ border: '1px solid #dee2e6' }}>
              <thead>
                <tr>
                  <th className="text-center">Subject</th>
                  <th className="text-center">Track</th>
                  <th className="text-center">Teacher</th>
                </tr>
              </thead>
              <tbody>
                {subjects && subjects.map((subject, idx) => {
                  const val_1 = Array.from(teacherSelection.keys()).find((o) => o.subject === subject.id
                  && o.track === TRACK.TRACK_1);
                  const val_2 = Array.from(teacherSelection.keys()).find((o) => o.subject === subject.id
                  && o.track === TRACK.TRACK_2);
                  return (
                    <React.Fragment key={`${val_1}_${idx}`}>
                      <tr>
                        <td>{subject.name}</td>
                        <td>Track 1</td>
                        <td style={{ textAlign: 'center' }}>{this.renderDropDowns(allTeachers, teacherSelection.get(val_1), (selectedListItem) => this.updateResults(selectedListItem, TEACHER_SELECTED, subject.id, TRACK.TRACK_1), TEACHERS)}</td>
                      </tr>
                      <tr>
                        <td>{subject.name}</td>
                        <td>Track 2</td>
                        <td style={{ textAlign: 'center' }}>{this.renderDropDowns(allTeachers, teacherSelection.get(val_2), (selectedListItem) => this.updateResults(selectedListItem, TEACHER_SELECTED, subject.id, TRACK.TRACK_2), TEACHERS)}</td>
                      </tr>
                    </React.Fragment>
                  );
                })}
              </tbody>
            </table>
            <div className="pull-right mt-5">
              <button type="button" className="btn-xl btn-primary" onClick={() => this.updateClassroomTeacher()}><span style={{ fontSize: 18 }}> Update</span></button>
            </div>
          </div>
        </div>
      </Layout>
    );
  }
}

const mapStateToProps = ({ classroomTeacher }) => ({
  campuses: classroomTeacher.campuses,
  classrooms: classroomTeacher.classrooms,
  subjects: classroomTeacher.subjects,
  classroomTeachers: classroomTeacher.classroomTeachers,
  currentClassroomID: classroomTeacher.currentClassroomID,
  allTeachers: classroomTeacher.allTeachers,
});

export default connect(mapStateToProps, {
  getClassroomTeacher,
  getSpecificClassroomTeacher,
  updateClassroomTeacher,
})(ClassroomTeachers);
