import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Layout from '../layout';
import Menubar from '../menubar';
import constants from '../../shared/constants';
import Modal from '../modal/baseModal';
import CreatableSelect from 'react-select/creatable';
import Select from 'react-select';
import Pagination from "react-js-pagination";
import { 
  getInstitutesTags, 
  editInstitutesTag, 
  createNewInstitutesTag, 
  createNewInstitutesTagGroup,
  showToast,
  getMembershipDetailsFb,
  bulkUpdateStudentsTags,
  updateInstitutesTagGroup,
  deleteTag,
} from '../../actions';

const buttonStyle = {
  height: 30, width: 65, display: 'flex', alignItems: 'center', justifyContent: 'center', cursor: 'pointer',
};
 const customDropDownStyles = {
      control: (base, state) => ({
        ...base,
        height: 35,
        width: 200,
        backgroundColor: 'white',
        border: '1px solid #CDCFD6 !important',
      }),
      indicatorsContainer: (base, state) => ({
        ...base,
        height: 35,
      }),
      valueContainer: (base, state) => ({
        ...base,
        height: 40,
      }),
      option: (base, state) => ({
        ...base,
        textAlign: 'left',
      }),
    };

class InstitutesTags extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      hasAccess: true,
      showEditModal: false,
      selectedTag: null,
      showNewTagModal: false,
      newTagName: "",
      selectedNewTagGroup: null,
      showBulkUpdateModal: false,
      bulkUpdateTag: null,
      bulkUpdateCampus: null,
      bulkUpdateClassroom: null,
      perPage: 8,
      showLoader: true,
      bulkUpdateSelectedStudents: [],
      bulkUpdateStudentsPage: 1,
      bulkUpdateSelectedStudentsPage: 1, 
      bulkUpdateSearchText: "",
      showTagGroupEditModal: false,
      selectedTagGroup: null,
      selectedTagGroupNewName: "",
      showConfirmationModal: false,
    };
  }

  componentDidMount() {
    this.props.getInstitutesTags().then((data) => {
      this.setState({ hasAccess: data.success });
    });
    this.props.getMembershipDetailsFb().then(() => this.setState({ showLoader: false }));
  }

  noPermission() {
    return (
      <div style={{
        paddingTop: 300,
        paddingLeft: 300,
        width: '100%',
        backgroundColor: '#F5F8FA',
        fontSize: '20px',
      }}
      >
        <span style={{ borderStyle: 'solid', padding: '10px' }}>You dont have permission to access this page </span>
      </div>
    );
  }

  renderInput(value = '', inputType = 'text', handleChange = () => {}, placeholder = '', styles = {}) {
    return (
      <input
        type={inputType}
        value={value}
        placeholder={placeholder}
        style={{
          border: 'none',
          borderBottom: '1px solid #E4E7EE',
          width: '90%',
          outline: 'none',
          margin: '5px 0px 0px 0px',
          fontSize: 16,
          ...styles,
        }}
        onChange={handleChange}
      />
    );
  }

  renderDropDown(filterItems, selectedItem, selectedFunction, item, defaultValue = '') {
    const defaultSelectedValue = defaultValue || { label: item, value: 0 };
    return (
      <div style={{
        display: 'flex', flexDirection: 'column', alignItems: 'flex-start', marginBottom: 30,
      }}
      >
        <CreatableSelect 
          options={filterItems} 
          value={selectedItem || defaultSelectedValue}
          onChange={selectedFunction}
          onCreateOption={this.props.createNewInstitutesTagGroup}
          styles={customDropDownStyles}
          maxMenuHeight={200}
          isClearable
        />
      </div>
    );
  }

  renderSingleSelect(filterItems, selectedItem, selectedFunction, item, defaultValue = '') {
    const defaultSelectedValue = defaultValue || { label: item, value: 0 };
    return (
      <div style={{
        display: 'flex', flexDirection: 'column', alignItems: 'flex-start', margin: 5, marginBottom: 30,
      }}
      >
        <div className='heading-2'>{item}</div>
        <Select 
          options={filterItems} 
          value={selectedItem || defaultSelectedValue}
          onChange={selectedFunction}
          styles={customDropDownStyles}
          maxMenuHeight={200}
          isClearable
        />
      </div>
    );
  }

  renderNewTagModal() {
    const { showNewTagModal, newTagName, selectedNewTagGroup } = this.state;
    const { institutesTagGroups } = this.props;

    return (
      <Modal
        showModal={showNewTagModal}
        handleCloseModal={() => this.setState({ showNewTagModal: false, newTagName: "", selectedNewTagGroup: null })}
        height="350px"
        width="500px"
        header={"Create New Tag"}
        >
        <div style={{padding: 15}}>
          <div style={{ paddingTop: 25 }}>
            Tag name
            {this.renderInput(newTagName, 'text', (event) => {
              this.setState({ newTagName: event.target.value });
            }, 'Tag Name')}
          </div>
          <div style={{ paddingTop: 25 }}>
            Tag Group
            {this.renderDropDown(institutesTagGroups, selectedNewTagGroup, (event) => this.setState({ selectedNewTagGroup: event }), 'Tag Group')}
          </div>
          <div style={{ paddingTop: 25 }}>
            <div
              className="Button primary-Button"
              role="presentation"
              style={{ width: '200px', marginLeft: "auto" }}
              onClick={() => {
                if (!newTagName) {
                  this.props.showToast('Please Add Tag Name');
                } else {
                this.props.createNewInstitutesTag(newTagName, selectedNewTagGroup).then((response) => {
                  if (response.success) {
                    this.props.showToast(response.message);
                    this.setState({ showNewTagModal: false, newTagName: "", selectedNewTagGroup: null });
                    window.location.reload();
                  }
              });
            }}}>
            Create
            </div>
          </div>
        </div>
      </Modal>
    );
  }

  renderConfirmationModal () {
    const { selectedTag, showConfirmationModal } = this.state;
    return (
      <Modal
        showModal={showConfirmationModal}
        handleCloseModal={() => this.setState({ selectedTag: null, showConfirmationModal: false })}
        width="600px"
        header={`Delete - ${selectedTag.name}`}
      >
        <div style={{padding: 15}}>
          <div style={{ display: 'flex', alignItems: 'center', flexDirection: "column" }}>
            <div style={{marginTop:30}}>Are you sure you want to delete {selectedTag.name}?</div>
            {selectedTag.usage_count > 1 && <div>This tag has been assigned to students.</div>}
            <div style={{
                display: 'flex', justifyContent: 'space-between', width: '100%', marginTop: 30,
              }}
            >
              <div
                className="primary-Button"
                role="presentation"
                style={buttonStyle}
                onClick={() => this.setState({ showConfirmationModal: false, selectedTag: null })}
              >
                Cancel
              </div>
              <div
                className="primary-Button"
                role="presentation"
                style={buttonStyle}
                onClick={() => {
                  this.props.deleteTag(selectedTag.id).then((response) => {
                    if (response.success) {
                      this.setState({ showConfirmationModal: false, selectedTag: null });
                    }
                  });
                }}
              >
                Delete
              </div>
            </div>
          </div>
        </div>
      </Modal>
    );
  }

  renderEditTagGroupModal () {
    const { showTagGroupEditModal, selectedTagGroup, selectedTagGroupNewName } = this.state;
    return (
      <Modal
        showModal={showTagGroupEditModal}
        handleCloseModal={() => this.setState({ showTagGroupEditModal: false, selectedTagGroup: null, selectedTagGroupNewName: "" })}
        header={`Edit ${selectedTagGroup.label}`}
      >
        <div style={{padding: 15}}>
          <div className='heading-1'>Tag Group Name</div>
          {this.renderInput(selectedTagGroupNewName, 'text', (event) => { this.setState({ selectedTagGroupNewName: event.target.value }); }, 'Tag Group Name', {width: "100%"})}
          <div 
            className='Button primary-Button' 
            style={{ marginLeft: "auto", marginTop: 15, width: 120, opacity: (selectedTagGroupNewName === selectedTagGroup.label || selectedTagGroupNewName === "") && 0.5 }}
            onClick={() => { this.props.updateInstitutesTagGroup(selectedTagGroupNewName, selectedTagGroup.value).then((response) => {if(response.success) {this.setState({ showTagGroupEditModal: false, selectedTagGroup: null, selectedTagGroupNewName: ""})}})}}
          >
            Update
          </div>  
        </div>
      </Modal>
    );
  }

  renderEditTag() {
    const { name, id, tag_group_name, tag_group_id } = this.state.selectedTag;
    const { institutesTagGroups } = this.props;
    return (
      <div style={{ padding: 30 }}>
        <div style={{ display: 'flex', alignItems: 'center', flexDirection: "column" }}>
          <div className='primary-Button' style={{padding: 5, borderRadius: 4, backgroundColor: "red", marginLeft: "auto", cursor: "pointer"}} onClick={() => {this.setState({ showConfirmationModal: true, showEditModal: false })}}>Delete Tag</div>
          <div style={{display:"flex", flexDirection:"row"}}>
            <div style={{ marginRight: 20 }}>Tag Name</div>
            <input
              type="text"
              placeholder="Tag Name"
              value={name}
              style={{
                fontWeight: '400',
                display: 'inline-block',
                fontSize: '14px',
                marginBottom: 10,
                overflow: 'auto',
                width: 200,
                resize: 'none',
                border: 0,
                borderBottom: '1px solid #454545',
                background: '#FFFFFF',
              }}
              onChange={(event) => {
                this.setState({
                  selectedTag: { ...this.state.selectedTag, name: event.target.value },
                });
              }}
            />
          </div>
          <div style={{display:"flex", flexDirection:"row"}}>
            <div style={{ marginRight: 20 }}>Tag Group</div>
            {this.renderDropDown(institutesTagGroups, {label: tag_group_name, value: tag_group_id}, (event) => this.setState({selectedTag: { ...this.state.selectedTag, tag_group_name: event === null ? "" : event.label, tag_group_id: event === null ? 0 : event.value }}), 'Tag Group')}
          </div>
        </div>
        <div style={{
          display: 'flex', justifyContent: 'space-between', width: '100%', marginTop: 10,
        }}
        >
          <div
            className="primary-Button"
            role="presentation"
            style={buttonStyle}
            onClick={() => this.setState({ showEditModal: false, selectedTag: null })}
          >
            Cancel
          </div>
          <div
            className="primary-Button"
            role="presentation"
            style={buttonStyle}
            onClick={() => {
              this.props.editInstitutesTag(id, this.state.selectedTag.name, this.state.selectedTag.tag_group_id).then((response) => {
                if (response.success) {
                  this.setState({ showEditModal: false, selectedTag: null });
                }
              });
            }}
          >
            Save
          </div>
        </div>
      </div>
    );
  }

  renderEditModal() {
    const { selectedTag, showEditModal } = this.state;
    return (
      <Modal
        showModal={showEditModal}
        handleCloseModal={() => this.setState({ selectedTag: null, showEditModal: false })}
        height="250px"
        width="600px"
        header={`Edit - ${selectedTag.name}`}
      >
        {this.renderEditTag()}
      </Modal>
    );
  }

  renderTag(tag) {
    return (
      <div
        key={tag.id}
        style={{
          width: 200,
          padding: 20,
          margin: 10,
          backgroundColor: 'white',
          boxShadow: '1px 2px 6px #8B9DAF33',
          cursor: 'pointer',
        }}
        onClick={() => this.setState({ showEditModal: true, selectedTag: tag })}
      >
        {tag.name}
      </div>
    );
  }

  handleSelectAllBulkUpdate (students) {
    let { bulkUpdateSelectedStudents } = this.state;
    let unassignedStudents = students.filter(
      (x) => !bulkUpdateSelectedStudents.includes(x)
    );
    bulkUpdateSelectedStudents = bulkUpdateSelectedStudents.length === unassignedStudents.length
      ? []
      : [...unassignedStudents];
    this.setState({ bulkUpdateSelectedStudents });
  }

  handleSelectForBulkUpdate (student) {
    let { bulkUpdateSelectedStudents } = this.state;
    if (bulkUpdateSelectedStudents.indexOf(student) > -1)
      bulkUpdateSelectedStudents = bulkUpdateSelectedStudents.filter(
        (selectedStudent) => selectedStudent.id !== student.id
      );
    else bulkUpdateSelectedStudents = [...bulkUpdateSelectedStudents, student];
    this.setState({ bulkUpdateSelectedStudents });
  }

  handleCloseBulkUpdateModal () {
    this.setState({ 
      showBulkUpdateModal: false, 
      bulkUpdateTag: null, 
      bulkUpdateCampus: null, 
      bulkUpdateClassroom: null, 
      bulkUpdateSelectedStudents: [], 
      bulkUpdateStudentsPage: 1, 
      bulkUpdateSelectedStudentsPage: 1, 
      bulkUpdateSearchText: "" })
  }

  renderBulkUpdateFilters () {
    const { bulkUpdateTag, bulkUpdateCampus, bulkUpdateClassroom, bulkUpdateSearchText } = this.state;
    const { institutesTags, campuses, classrooms } = this.props;
    return (
      <div style={{display: "flex", flexDirection: "row"}}>
        <div>
          <div className='heading-2'>Student Name / Badge ID</div>
          {this.renderInput(bulkUpdateSearchText, 'text', (event) => { this.setState({ bulkUpdateSearchText: event.target.value }); }, 'Student Name / Badge ID', {width: 270, marginRight: 5, marginTop: 0})}
        </div>
        {this.renderSingleSelect(campuses, bulkUpdateCampus, (event) => this.setState({ bulkUpdateCampus: event }), 'Campus')}
        {this.renderSingleSelect(bulkUpdateCampus ? classrooms.filter((classroom) => classroom.campus_id === bulkUpdateCampus.value) : classrooms, bulkUpdateClassroom, (event) => this.setState({ bulkUpdateClassroom: event }), 'Classroom')}
        {this.renderSingleSelect(institutesTags, bulkUpdateTag, (event) => this.setState({ bulkUpdateTag: event }), 'Select Tag to Assign')}
      </div>
    )
  }

  renderBulkUpdateStudentDetails () {
    const { studentDetailsFb } = this.props;
    const { perPage, bulkUpdateCampus, bulkUpdateClassroom, bulkUpdateStudentsPage, bulkUpdateSelectedStudents, bulkUpdateSearchText } = this.state;
    let filteredStudents = studentDetailsFb;

    if (bulkUpdateSearchText) {
      filteredStudents = filteredStudents.filter((student) => 
      (student.first_name && student.first_name.toUpperCase().includes(bulkUpdateSearchText.toUpperCase())) || 
      (student.badge_id && student.badge_id.toUpperCase().includes(bulkUpdateSearchText.toUpperCase())))
    }

    if (bulkUpdateCampus) {
      filteredStudents = filteredStudents.filter((student) => student.campus_id === bulkUpdateCampus.value);
    }

    if (bulkUpdateClassroom) {
      filteredStudents = filteredStudents.filter((student) => student.classroom_id === bulkUpdateClassroom.value);
    }

    return (
      <>
        <table
          className="table table-bordered"
          style={{
            boxShadow: "1px 2px 6px #8B9DAF33",
            marginBottom: 10,
          }}
        >
          <thead>
            <tr
              className='heading-1'
              style={{
                fontSize: 16,
                fontWeight: "bold",
              }}
            >
              <td>Student ID</td>
              <td>Name</td>
              <td>Classroom</td>
              <td>Campus</td>
              <td style={{textAlign: "center"}}>
                Assign{" "}
                <input type="checkbox" onChange={() => { this.handleSelectAllBulkUpdate(filteredStudents) }} />
              </td>
            </tr>
          </thead>
          <tbody>
            {filteredStudents
              .slice((bulkUpdateStudentsPage - 1) * perPage, bulkUpdateStudentsPage * perPage)
              .map((items, index) => {
                const isSelected = bulkUpdateSelectedStudents.includes(items);
                return (
                  <tr key={index}>
                    <td>{items.badge_id}</td>
                    <td>
                      {items.first_name ? items.first_name : "-"}
                    </td>
                    <td>{items.classroom_name}</td>
                    <td>{items.campus_name}</td>
                    <td style={{ textAlign: "center" }}>
                      <input
                        type="checkbox"
                        checked={isSelected}
                        onChange={() => {this.handleSelectForBulkUpdate(items)}
                        }
                      />
                    </td>
                  </tr>
                );
            })}
          </tbody>
        </table>
        <div style={{ display: "flex", justifyContent: "center" }}>
          <Pagination
            activePage={bulkUpdateStudentsPage}
            itemsCountPerPage={perPage}
            totalItemsCount={filteredStudents.length}
            pageRangeDisplayed={7}
            onChange={(pageNumber) => this.setState({ bulkUpdateStudentsPage: pageNumber })}
          />
        </div>
      </>
    );
  }

  renderBulkUpdateSelectedStudents () {
    const { perPage, bulkUpdateSelectedStudentsPage, bulkUpdateSelectedStudents } = this.state;
    if (bulkUpdateSelectedStudents.length === 0) {
      return (
        <>
         <div className='heading-1'>Selected Students</div>
         <div className='heading-2' style={{ textAlign: "center" }}>No Students Selected</div>
        </>
      );
    }
    return (
      <>
        <div className='heading-1'>Selected Students</div>
        <table
          className="table table-bordered"
          style={{
            boxShadow: "1px 2px 6px #8B9DAF33",
            marginBottom: 10,
          }}
        >
          <thead>
            <tr
              className='heading-1'
              style={{
                fontSize: 16,
                fontWeight: "bold",
              }}
            >
              <td>Student ID</td>
              <td>Name</td>
              <td>Classroom</td>
              <td>Campus</td>
              <td style={{textAlign: "center"}}>
                Assign{" "}
                <input type="checkbox" checked={true} onChange={() => { this.handleSelectAllBulkUpdate(bulkUpdateSelectedStudents) }} />
              </td>
            </tr>
          </thead>
          <tbody>
            {bulkUpdateSelectedStudents
              .slice((bulkUpdateSelectedStudentsPage - 1) * perPage, bulkUpdateSelectedStudentsPage * perPage)
              .map((items, index) => {
                const isSelected = bulkUpdateSelectedStudents.includes(items);
                return (
                  <tr key={index}>
                    <td>{items.badge_id}</td>
                    <td>
                      {items.first_name ? items.first_name : "-"}
                    </td>
                    <td>{items.classroom_name}</td>
                    <td>{items.campus_name}</td>
                    <td style={{ textAlign: "center" }}>
                    <input
                      type="checkbox"
                      checked={isSelected}
                      onChange={() => {this.handleSelectForBulkUpdate(items)}
                      }
                    />
                    </td>
                  </tr>
                );
            })}
          </tbody>
        </table>
        <div style={{ display: "flex", justifyContent: "center" }}>
          <Pagination
            activePage={bulkUpdateSelectedStudentsPage}
            itemsCountPerPage={perPage}
            totalItemsCount={bulkUpdateSelectedStudents.length}
            pageRangeDisplayed={7}
            onChange={(pageNumber) => this.setState({ bulkUpdateSelectedStudentsPage: pageNumber })}
          />
        </div>
      </>
    );
  }

  renderBulkUpdateModal() {
    const { showBulkUpdateModal, showLoader, bulkUpdateSelectedStudents, bulkUpdateTag } = this.state;
    if (showLoader) {
      return (
        <div style={{margin: "auto"}}>Loading...</div>
      );
    }
    return (
      <Modal
        showModal={showBulkUpdateModal}
        handleCloseModal={() => { this.handleCloseBulkUpdateModal() }}
        height="90%"
        width="80%"
        header={`Bulk Update`}
      >
        <div style={{padding: 15, overflowY: "scroll"}}>
          {this.renderBulkUpdateFilters()}
          {this.renderBulkUpdateStudentDetails()}
          {this.renderBulkUpdateSelectedStudents()}
          <div 
            className='Button primary-Button' 
            style={{ marginLeft: "auto", marginTop: 10, width: 200, opacity: (bulkUpdateSelectedStudents.length === 0 || bulkUpdateTag === null) && 0.5 }}
            onClick={() => { this.props.bulkUpdateStudentsTags(bulkUpdateSelectedStudents.map((student) => { return student.id; }), bulkUpdateTag).then((response) => { if (response.sucess) { this.handleCloseBulkUpdateModal(); } }) }}
          >
            Assign
          </div>
        </div>
      </Modal>
    );
  }

  renderScreen() {
    const { institutesTags, institutesTagGroups } = this.props;
    const { showLoader } = this.state;
    return (
      <>
        <div style={{ display: "flex", flexDirection: "row" }}>
          <div
            role="presentation"
            style={{
              height: 35, width: 140, display: 'flex', justifyContent: 'center', alignItems: 'center', border: '1px solid #CDCFD6', borderRadius: 4, cursor: 'pointer', marginRight: 10,
            }}
            onClick={() => this.setState({ showNewTagModal: true })}
          >
            New Tag
          </div>
          <div
            role="presentation"
            style={{
              height: 35, width: 140, display: 'flex', justifyContent: 'center', alignItems: 'center', border: '1px solid #CDCFD6', borderRadius: 4, cursor: 'pointer', opacity: showLoader && 0.5
            }}
            onClick={() => showLoader ? {} : this.setState({ showBulkUpdateModal: true })}
          >
            Bulk Update
          </div>
        </div>
        {institutesTagGroups.map(tagGroup => {
          return (
            <div>
              <div className="heading-1" style={{ fontSize: 20, margin: '25px 0px' }}>
                {tagGroup.label}{" "}<i className='fa fa-edit' style={{ cursor: "pointer", fontSize: 18 }} onClick={() => {this.setState({showTagGroupEditModal: true, selectedTagGroup: tagGroup, selectedTagGroupNewName: tagGroup.label})}}></i>
              </div>
              <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                {institutesTags.map((tag) => tag.tag_group_id === tagGroup.value && this.renderTag(tag))}
              </div>
            </div>
          );
        })}
        <div>
            <div className="heading-1" style={{ fontSize: 20, margin: '25px 0px' }}>
              Standalone Tags
            </div>
            <div style={{ display: 'flex', flexWrap: 'wrap' }}>
              {institutesTags.map((tag) => tag.tag_group_id === null && this.renderTag(tag))}
            </div>
        </div>
      </>
    );
  }

  render() {
    const { history } = this.props;
    const { hasAccess, showEditModal, showNewTagModal, showBulkUpdateModal, showTagGroupEditModal, showConfirmationModal } = this.state;
    const { instituteOnBoardingMenu } = constants();
    const query = new URLSearchParams(window.location.search);
    const instituteId = query.get('institute_id');
    instituteOnBoardingMenu.forEach((page) => {
      page.route += `${instituteId !== null ? `?institute_id=${instituteId}` : ''}`;
    });

    let innerHeight = null;
    if (typeof window !== 'undefined') {
      innerHeight = window.innerHeight;
    }
    return (
      <Layout globalHistory={history} hideHeader>
        <div style={{
          width: '100%', display: 'flex', fontFamily: 'Nunito Sans', backgroundColor: '#F5F8FA', minHeight: innerHeight,
        }}
        >
          <div style={{ width: '20%', padding: 0, zIndex: 1 }}>
            <Menubar
              history={history}
              menuList={instituteOnBoardingMenu}
              selectedOption={3}
              redirectToRoot
            />
          </div>
          <div style={{ width: '80%', padding: 30 }}>
           {hasAccess
              ? (
                <div>
                  {this.renderScreen()}
                </div>
              ) : this.noPermission()}
            {showEditModal && this.renderEditModal()}
            {showNewTagModal && this.renderNewTagModal()}
            {showBulkUpdateModal && this.renderBulkUpdateModal()}
            {showTagGroupEditModal && this.renderEditTagGroupModal()}
            {showConfirmationModal && this.renderConfirmationModal()}
          </div>
        </div>
      </Layout>
    );
  }
}

InstitutesTags.propTypes = {
  institutesTags: PropTypes.array,
  institutesTagGroups: PropTypes.array,
};

InstitutesTags.defaultProps = {
  institutesTags: [],
  institutesTagGroups: [],
};

const mapStateToProps = ({ institute }) => ({
  campuses: institute.campuses,
  classrooms: institute.classrooms,
  studentDetailsFb: institute.studentDetailsFb,
  institutesTags: institute.institutesTags,
  institutesTagGroups: institute.institutesTagGroups,
});

export default connect(mapStateToProps, { 
  getInstitutesTags, 
  editInstitutesTag,
  createNewInstitutesTag,
  createNewInstitutesTagGroup,
  showToast,
  getMembershipDetailsFb,
  bulkUpdateStudentsTags,
  updateInstitutesTagGroup,
  deleteTag,
})(InstitutesTags);
