import React, { Component } from 'react';
import ReactTooltip from 'react-tooltip';
import { connect } from 'react-redux';
import _ from 'lodash';
import { mathjax } from '../../helpers/util';
import {
  getSortQuestionData, getQuestionToSort, placeQuestionItem,
  pickedQuestionBack, reportSortIssue, updateComplexityInSort, updateSimilarity,
  ungroupSimilarity, showToast,
} from '../../actions';
import Layout from '../layout';
import QuestionView from '../questionView';
import Modal from '../modal/baseModal';
import backButton from '../../static/images/backButton.svg';
import move from '../../static/images/move.svg';
import mergeIcon from '../../static/images/merge_icon.svg';
import problem from '../../static/images/problem.svg';
import more from '../../static/images/more.svg';

const REPORT_ISSUES = [
  { value: 8, text: 'Wrong Topic' },
  { value: 6, text: 'Wrong Answer' },
  { value: 7, text: 'Content Issue' },
  { value: 4, text: 'Others' },
];

class SortQuestion extends Component {
  constructor(props) {
    super(props);
    this.state = {
      index: null,
      isOverQuestion: false,
      showModal: false,
      showMoreModal: false,
      question: null,
      questionIndex: null,
      mergeQuestions: [],
      isGroup: false,
      showOnlyQuestionText: true,
      isSorting: true,
      showOnlyOffline: true,
      errorMessage: '',
      selectedErrorType: null,
    };
    this.start = null;
    this.questionTimer = {};
    this.groupingTime = 0;
    this.submitIssue = this.submitIssue.bind(this);
    this.updateErrorMessage = this.updateErrorMessage.bind(this);
  }

  componentDidMount() {
    const { pickedQuestion } = this.props;
    this.props.getSortQuestionData(this.props.match.params.id).then(() => {
      mathjax();
    });
    this.props.getQuestionToSort(this.props.match.params.id, this.state.showOnlyOffline).then(() => {
      mathjax();
    });

    this.timer = setInterval(() => {
      if (pickedQuestion) {
        const questionId = pickedQuestion.id;
        if (this.questionTimer[questionId] && this.state.isSorting) {
          this.questionTimer[questionId] += 100;
        } else {
          this.questionTimer[questionId] = 100;
        }
        if (!this.state.isSorting) {
          this.groupingTime += 100;
        }
      }
    }, 100);
  }

  componentWillUnmount() {
    clearInterval(this.timer);
  }

  updateErrorMessage(e) {
    this.setState({ errorMessage: e.target.value });
  }

  placeQuestion(index) {
    const { pickedQuestion, unSortedQuestionCount } = this.props;
    this.props.placeQuestionItem(index, pickedQuestion.id, this.props.match.params.id, this.questionTimer[pickedQuestion.id]).then(() => {
      this.questionTimer[pickedQuestion.id] = 0;
      mathjax();
      this.props.getSortQuestionData(this.props.match.params.id).then(() => {
        mathjax();
        if (unSortedQuestionCount <= 1) {
          this.props.getQuestionToSort(this.props.match.params.id, this.state.showOnlyOffline).then(() => {
            mathjax();
          });
        }
      });
    });
  }

  getScroller() {
    const perPage = parseInt((this.props.questions.length / 10), 10) + 1;
    return (_.times(10 + 1, (i) => {
      if (((i * perPage) + 1) > this.props.questions.length) {
        return (<div />);
      }
      return (
        <div
          role="presentation"
          style={{
            marginTop: 10, marginBottom: 10, border: '1px solid rgb(71, 134, 255)', textAlign: 'center', color: 'rgb(71, 134, 255)', paddingLeft: 5, paddingRight: 5,
          }}
          onClick={() => {
            const element = document.getElementById(`question_${(i * perPage)}`);
            element.scrollIntoView();
          }}
        >
          {(i * perPage) + 1}
        </div>
      );
    }));
  }

  submitIssue() {
    if (this.state.selectedErrorType) {
      if (this.state.selectedErrorType.value == 4 && !this.state.errorMessage) {
        alert('Enter youre issue');
      } else {
        this.setState({ showModal: false });
        this.props.reportSortIssue(this.state.question.id, this.state.selectedErrorType.value, this.state.errorMessage).then((response) => {
          if (response.success) {
            this.setState({ errorMessage: '', selectedErrorType: null });
          }
        });
      }
    } else {
      alert('Choose Your Issue');
    }
  }

  render() {
    const { questions, topic, similarQuestionIds } = this.props;
    if (!questions) {
      return <div />;
    }
    const { pickedQuestion } = this.props;
    const {
      index, isOverQuestion, isGroup, showOnlyQuestionText,
    } = this.state;
    const buttonStyle = {
      top: 80, border: '1px solid rgb(71, 134, 255)', borderRadius: 5, padding: 5, color: 'rgb(71, 134, 255)', cursor: 'pointer', backgroundColor: '#fff', marginRight: 20,
    };
    const parent = {};
    Object.keys(similarQuestionIds).forEach((parentId) => {
      similarQuestionIds[parentId].forEach((qId) => {
        parent[qId] = parseInt(parentId, 10);
      });
    });
    return (
      <Layout globalHistory={this.props.history} hideHeader>
        <div style={{
          display: 'flex', justifyContent: 'space-between', marginTop: 20, marginBottom: 20,
        }}
        >
          <div style={{ marginLeft: 20 }}>
            <img
              role="presentation"
              alt=""
              src={backButton}
              style={{ height: 40, width: 40 }}
              onClick={() => this.props.history.push('/sort')}
            />
          </div>
          <div style={{ display: 'flex' }}>
            <div
              role="presentation"
              style={buttonStyle}
              onClick={() => { this.setState({ showOnlyQuestionText: !this.state.showOnlyQuestionText }); }}
            >
              Toggle Full Question
            </div>
            { false && (
            <div
              role="presentation"
              style={buttonStyle}
              onClick={() => { this.setState({ isSorting: !this.state.isSorting }); }}
            >
              Activate
              {this.state.isSorting ? 'Grouping' : 'Sorting'}
            </div>
            )}
            <div
              role="presentation"
              style={buttonStyle}
              onClick={() => {
                this.setState({ showOnlyOffline: !this.state.showOnlyOffline }, () => {
                  this.props.getQuestionToSort(this.props.match.params.id, this.state.showOnlyOffline).then(() => {
                    this.props.showToast(`Showing ${this.state.showOnlyOffline ? 'Only Offline' : 'All'} Questions`);
                    mathjax();
                  });
                });
              }}
            >
              Show
              {!this.state.showOnlyOffline ? 'Only Tab questions' : 'All Questions'}
            </div>
          </div>
        </div>
        <Modal showModal={this.state.showModal} handleCloseModal={() => { this.setState({ showModal: false, errorMessage: '', selectedErrorType: null }); }} header="Report Error" top="25%">
          <div style={{ maxHeight: 500 }}>
            <div style={{ paddingLeft: 15 }}>
              <QuestionView question={this.state.question} renderMathJaxOnMount showAnswer printPage={false} showOnlyQuestionText={showOnlyQuestionText} />
            </div>
            <div style={{ width: '100%', textAlign: 'center' }}>
              {REPORT_ISSUES.map((errorType) => (
                <button
                  type="button"
                  className={`${this.state.selectedErrorType == errorType ? 'primary_button' : 'secondary_button'}`}
                  style={{
                    padding: 0, fontSize: 14, marginLeft: 15, height: 40, width: 200, fontWeight: 'bold',
                  }}
                  onClick={() => {
                    this.setState({ selectedErrorType: errorType });
                  }}
                >
                  {errorType.text}
                </button>
              ))}
              <div style={{
                display: 'flex', flexDirection: 'column', alignItems: 'center', marginLeft: 10,
              }}
              >
                <textarea
                  cols="74"
                  onChange={this.updateErrorMessage}
                  type="text"
                  placeholder="please enter your error message"
                  value={this.state.errorMessage}
                />
                <div
                  role="presentation"
                  className="primary_button"
                  style={{
                    marginLeft: 15, height: 40, width: 200, fontWeight: 'bold', fontSize: 17,
                  }}
                  onClick={() => this.submitIssue()}
                >
                  Submit
                </div>
              </div>
              <div style={{ opacity: 0.5, textAlign: 'left', paddingLeft: 15 }}>
                Question id:
                {this.state.question && this.state.question.id && this.state.question.id}
              </div>
            </div>
          </div>
        </Modal>
        <Modal showModal={this.state.showMoreModal} handleCloseModal={() => { this.setState({ showMoreModal: false }); }} header="More" top="25%">
          <div style={{ maxHeight: 500 }}>
            <QuestionView question={this.state.question} renderMathJaxOnMount showAnswer printPage={false} showOnlyQuestionText={showOnlyQuestionText} sortPage />
            <div style={{ marginLeft: '20%', marginRight: '20%' }}>
              { false && this.state.question && ['JUNK', '1', '2', '3', '4', '5'].map((item, itemIndex) => {
                return (
                  <div
                    role="presentation"
                    key={item}
                    onClick={() => {
                      this.props.updateComplexityInSort(this.state.question.id, this.state.questionIndex, itemIndex).then((response) => {
                        if (response.success) {
                          this.setState({ showMoreModal: false });
                        }
                      });
                    }}
                    style={{
                      alignItem: 'center',
                      justifyContent: 'center',
                      color: `${this.state.question.complexity_2 !== itemIndex ? '#fff' : 'rgb(71, 134, 255)'}`,
                      backgroundColor: `${this.state.question.complexity_2 !== itemIndex ? 'rgb(71, 134, 255)' : '#fff'}`,
                      fontWeight: 'bolder',
                      fontSize: 'larger',
                      height: '50px',
                      border: '1px solid',
                      textAlign: 'center',
                      display: 'table-cell',
                      verticalAlign: 'middle',
                      width: 80,
                      cursor: 'pointer',
                    }}
                  >
                    {item}
                  </div>
                );
              })}
            </div>
          </div>
        </Modal>
        <div id="question-item" style={{ height: '80vh' }}>
          <div style={{ display: 'flex' }}>
            <div style={{
              overflow: 'scroll', height: this.state.isSorting ? '74vh' : '100%', backgroundColor: '#eee', paddingBottom: 100, marginLeft: 20, width: '100%', border: '1px solid #fff', borderRadius: 5,
            }}
            >
              <h5
                style={{
                  paddingLeft: 10, paddingBottom: 10, marginBottom: 0, backgroundColor: '#fff',
                }}
              >
                <b>{topic}</b>
              </h5>
              {this.state.isSorting && (
              <div
                role="presentation"
                style={{
                  color: '#20CB90', textAlign: 'center', padding: (index === 0 && !isOverQuestion) ? 0 : 12, cursor: 'pointer',
                }}
                onMouseEnter={() => { this.setState({ index: 0 }); }}
                onClick={() => { this.placeQuestion(0); this.setState({ index: null, isOverQuestion: false }); }}
              >
                {index === 0 && !isOverQuestion && <span>-&emsp;-&emsp;-&emsp;-&emsp;-&emsp;-&emsp;move here&emsp;-&emsp;-&emsp;-&emsp;-&emsp;-&emsp;-&emsp;-</span>}
              </div>
              )}
              {
            questions.map((question, questionIndex) => {
              return (
                <div
                  id={`question_${questionIndex}`}
                  key={question.id}
                >
                  <div style={{ borderTop: (questions[questionIndex - 1] && (parent[questions[questionIndex - 1].id] !== parent[question.id]) && similarQuestionIds[parent[question.id]]) ? '2px solid #445EE9' : '' }} />
                  <div
                    style={{
                      background: parent[question.id] ? '#0026ff11' : '#fff', border: `1px solid ${(index === questionIndex && isOverQuestion) ? '#445EE9' : '#CDCDCE'}`, margin: 10, padding: 10, borderRadius: 3,
                    }}
                    onMouseEnter={() => { this.setState({ index: questionIndex, isOverQuestion: true }); }}
                    onMouseLeave={() => { this.setState({ index: null, isOverQuestion: false }); }}
                  >
                    <div className="row">
                      <div style={{ width: '90%', paddingLeft: 20, position: 'relative' }}>
                        {parent[question.id] && !this.state.isSorting
                        && (
                        <img
                          role="presentation"
                          alt=""
                          data-tip="Ungroup Questions"
                          src={mergeIcon}
                          style={{
                            width: 35, height: 35, position: 'absolute', left: -5, top: 0, bottom: 0, margin: 'auto', borderRadius: 5, border: '1px solid #445EE9',
                          }}
                          onClick={() => {
                            const parentItem = parent[question.id];
                            const parentLength = similarQuestionIds[parentItem].length;
                            const parentIndex = questions.findIndex((item, i) => {
                              return item.id === parentItem;
                            });
                            questions.indexOf(parentItem);
                            this.props.ungroupSimilarity(parent[question.id], question.id, parent[question.id] === question.id ? questions[questionIndex + 1].id : null, this.props.match.params.id).then((response) => {
                              if (response.success && parentLength > 2) {
                                this.props.pickedQuestionBack(question.id, questionIndex, this.props.match.params.id).then((resp) => {
                                  if (resp.success) {
                                    this.placeQuestion(parentLength + parentIndex - 1);
                                  }
                                  this.setState({ index: null });
                                });
                              }
                            });
                          }}
                        />
                        )}
                        <div style={{ width: '90%' }}>
                          <QuestionView question={question} showAnswer index={questionIndex} printPage={false} showOnlyQuestionText={showOnlyQuestionText} />
                        </div>
                      </div>
                      <div style={{ width: '8%', padding: 5 }}>
                        <ReactTooltip />
                        {(!parent[question.id] || similarQuestionIds[question.id]) && this.state.isSorting && (
                          <img
                            role="presentation"
                            alt=""
                            data-tip="Rearrange Order"
                            src={move}
                            style={{
                              width: 40, float: 'right', height: 30, margin: 5, border: '1px solid #445EE9', padding: 5, borderRadius: 5, background: '#fff',
                            }}
                            onClick={() => {
                              this.props.pickedQuestionBack(question.id, questionIndex, this.props.match.params.id).then(() => {
                                mathjax();
                                this.props.getSortQuestionData(this.props.match.params.id).then(() => {
                                  mathjax();
                                });
                                this.props.getQuestionToSort(this.props.match.params.id, this.state.showOnlyOffline).then(() => {
                                  mathjax();
                                });
                              });
                            }}
                          />
                        )}
                        <img
                          role="presentation"
                          alt=""
                          data-tip="Report Error"
                          src={problem}
                          style={{
                            width: 35, float: 'right', height: 30, margin: 5, border: '1px solid #445EE9', padding: 5, borderRadius: 5, background: '#fff',
                          }}
                          onClick={() => { this.setState({ showModal: true, question }); }}
                        />
                        { false && (
                          <img
                            role="presentation"
                            alt=""
                            data-tip="More"
                            src={more}
                            style={{
                              width: 40, height: 30, margin: 5, border: '1px solid #445EE9', padding: 5, borderRadius: 5, background: '#fff',
                            }}
                            onClick={() => { this.setState({ showMoreModal: true, question, questionIndex }); }}
                          />
                        )}
                      </div>
                    </div>
                  </div>
                  <div style={{ borderBottom: (questions[questionIndex + 1] && (parent[questions[questionIndex + 1].id] !== parent[question.id]) && similarQuestionIds[parent[question.id]]) || (parent[question.id] && !questions[questionIndex + 1]) ? '2px solid #445EE9' : '' }} />
                  <div className="row">
                    {(parent[question.id] && questions[questionIndex + 1] && parent[questions[questionIndex + 1].id] === parent[question.id]) || !this.state.isSorting
                    && (
                    <div
                      style={{
                        color: '#20CB90', textAlign: 'center', cursor: 'pointer', width: '100%',
                      }}
                    />
                    )}
                    { ((!questions[questionIndex + 1]) || (questions[questionIndex + 1] && (!parent[question.id] || (parent[questions[questionIndex + 1].id] !== parent[question.id])))) && this.state.isSorting
                    && (
                    <div
                      role="presentation"
                      style={{
                        color: '#20CB90', textAlign: 'center', cursor: 'pointer', width: '100%',
                      }}
                      onMouseEnter={() => { this.setState({ index: questionIndex + 1, isOverQuestion: false, isGroup: false }); }}
                      onClick={() => { this.placeQuestion(questionIndex + 1); this.setState({ index: null }); }}
                    >
                      <span className={`${index === (questionIndex + 1) && !isGroup ? 'question-placement-position' : 'group'}`}>-&emsp;-&emsp;-&emsp;-&emsp;-&emsp;-&emsp;move here&emsp;-&emsp;-&emsp;-&emsp;-&emsp;-&emsp;-&emsp;-</span>
                    </div>
                    )}
                    { questions[questionIndex + 1] && (!parent[question.id] || (parent[questions[questionIndex + 1].id] !== parent[question.id])) && false
                    && (
                    <div
                      role="presentation"
                      style={{
                        color: '#20CB90', textAlign: 'center', cursor: 'pointer', width: '50%',
                      }}
                      onMouseEnter={() => { this.setState({ index: questionIndex + 1, isOverQuestion: false, isGroup: false }); }}
                      onClick={() => { this.placeQuestion(questionIndex + 1); this.setState({ index: null }); }}
                    >
                      <span className={`${index === (questionIndex + 1) && !isGroup ? 'question-placement-position' : 'group'}`}>-&emsp;-&emsp;-&emsp;-&emsp;-&emsp;-&emsp;move here&emsp;-&emsp;-&emsp;-&emsp;-&emsp;-&emsp;-&emsp;-</span>
                    </div>
                    )}
                    { questions[questionIndex + 1] && (!parent[question.id] || (parent[questions[questionIndex + 1].id] !== parent[question.id])) && !this.state.isSorting
                     && (
                     <div
                       role="presentation"
                       alt=""
                       onMouseEnter={() => { this.setState({ index: questionIndex + 1, isOverQuestion: false, isGroup: true }); }}
                       style={{
                         color: '#cb7220', textAlign: 'center', cursor: 'pointer', width: '100%',
                       }}
                       onClick={() => {
                         this.props.updateSimilarity(this.props.match.params.id, parent[question.id] ? parent[question.id] : question.id, questions[questionIndex + 1].id, this.groupingTime).then((response) => {
                           if (response.success) {
                             this.groupingTime = 0;
                           }
                           this.forceUpdate();
                         });
                       }}
                     >
                       <span className={`${index === (questionIndex + 1) && !isGroup ? 'placement-position' : 'group'}`}>-&emsp;-&emsp;-&emsp;-&emsp;-&emsp;-&emsp;move here&emsp;-&emsp;-&emsp;-&emsp;-&emsp;-&emsp;-&emsp;-</span>
                     </div>
                     )}
                  </div>
                </div>
              );
            })
          }
            </div>
            <div style={{ padding: 5, marginTop: '5%', width: 'fit-content' }}>
              {this.getScroller()}
            </div>
          </div>
          { !!pickedQuestion && this.state.isSorting
            && (
            <div
              style={{
                position: 'fixed', bottom: 0, width: '100%', height: showOnlyQuestionText ? 150 : 200, overflow: 'scroll', padding: 10, backgroundColor: '#fff', boxShadow: '#ddd 0px 0px 5px 2px', borderTop: '1px solid #aaa',
              }}
              key={pickedQuestion.id}
              onMouseEnter={() => { this.setState({ index: null }); }}
            >
              <QuestionView question={pickedQuestion} showAnswer printPage={false} showOnlyQuestionText={showOnlyQuestionText} />
              <img
                role="presentation"
                alt=""
                data-tip="Report Error"
                src={problem}
                style={{
                  width: 35, float: 'right', height: 30, margin: 5, border: '1px solid #445EE9', padding: 5, borderRadius: 5, background: '#fff',
                }}
                onClick={() => { this.setState({ showModal: true, question: pickedQuestion }); }}
              />
              <div
                className="row"
                style={{
                  alignItem: 'center', width: '80%', paddingLeft: '3%', color: '#445EE9', textAlign: 'right', fontSize: 12, fontWeight: 'bold', paddingTop: 10,
                }}
              >
                { false && ['JUNK', '1', '2', '3', '4', '5'].map((item, itemIndex) => {
                  return (
                    <div
                      role="presentation"
                      key={item}
                      onClick={() => {
                        this.props.updateComplexityInSort(pickedQuestion.id, itemIndex).then((response) => {
                          if (response.success) {
                            this.forceUpdate();
                            if (itemIndex === 0) {
                              this.props.getQuestionToSort(this.props.match.params.id, this.state.showOnlyOffline).then(() => {
                                mathjax();
                              });
                            }
                          }
                        });
                      }}
                      style={{
                        alignItem: 'center',
                        justifyContent: 'center',
                        color: `${pickedQuestion.complexity_2 !== itemIndex ? 'rgb(71, 134, 255)' : '#fff'}`,
                        backgroundColor: `${pickedQuestion.complexity_2 !== itemIndex ? '#fff' : 'rgb(71, 134, 255)'}`,
                        fontWeight: 'bolder',
                        fontSize: 'larger',
                        height: 30,
                        border: '1px solid',
                        textAlign: 'center',
                        verticalAlign: 'middle',
                        width: 80,
                        cursor: 'pointer',
                        marginRight: itemIndex === 0 ? 100 : 0,
                      }}
                    >
                      {item}
                    </div>
                  );
                })}
              </div>
            </div>
            )}
        </div>
        <style jsx>
          {`
       @media only screen and (max-width: 769px) {
          div .group {
            opacity: 1;
          }
        }
        .group {
          opacity: 0.2;
        }
        .question-placement-position {
          opacity: 1;
        }
        `}
        </style>
      </Layout>
    );
  }
}

const mapStateToProps = ({ sortQuestions }) => ({
  questions: sortQuestions.questions,
  pickedQuestion: sortQuestions.pickedQuestion,
  unSortedQuestionCount: sortQuestions.unSortedQuestionCount,
  topic: sortQuestions.topic,
  similarQuestionIds: sortQuestions.similarQuestionIds,
});

export default connect(mapStateToProps, {
  getSortQuestionData, getQuestionToSort, placeQuestionItem, pickedQuestionBack, reportSortIssue, updateComplexityInSort, updateSimilarity, ungroupSimilarity, showToast,
})(SortQuestion);
