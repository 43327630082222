import {
  GET_INSTITUTE_FILES, UPDATE_FILE_DETAILS, SHOW_HIDE_FILE,
  FILE_UPLOAD_PERCENTAGE, GET_META_DATA, SET_OFFLINE_TEST_SUBJECTS,
} from '../actions';

const initialState = {
  instituteFiles: [],
  subjectFiles: {},
  maxFileSize: 1,
  classrooms: [],
  campuses: [],
  semesters: [],
  departments: [],
  subjects: [],
  isHigherEducation: false,
  fileUploadedPercentage: 0,
  offlineTestListSubjectList: [],
  subjectOfflineTestList: [],
  offlineTestSubjectName: '',
  offlineStudentResponses: [],
  offlineTest: {},
};

export default function (state = initialState, action) {
  switch (action.type) {
    case GET_INSTITUTE_FILES: {
      return { ...state, ...action.payload };
    }
    case UPDATE_FILE_DETAILS: {
      let instituteFiles = [...state.instituteFiles];
      instituteFiles = state.instituteFiles.map((file) => {
        if (file.id === action.payload.id) {
          file.title = action.payload.title;
          file.description = action.payload.description;
        }
        return file;
      });
      return { ...state, instituteFiles };
    }
    case SHOW_HIDE_FILE: {
      const instituteFiles = [...state.instituteFiles];
      instituteFiles.map((files, index) => {
        if (files.id === action.id) {
          instituteFiles[index].show = !instituteFiles[index].show;
        }
      });
      return { ...state, instituteFiles };
    }
    case FILE_UPLOAD_PERCENTAGE: {
      return { ...state, fileUploadedPercentage: action.payload };
    }
    case GET_META_DATA: {
      return { ...state, ...action.payload };
    }
    case SET_OFFLINE_TEST_SUBJECTS: {
      return { ...state, ...action.payload };
    }
    default:
      return state;
  }
}
