import React from "react";
import PropTypes from "prop-types";
import Loader from "../loading";
import { connect } from "react-redux";
import Pagination from "react-js-pagination";
import Constants from "../../shared/constants";
import { createBrowserHistory } from "history";
import { withRouter } from "react-router-dom";
import {
  getEmployeesDetails,
  createNewEmployee,
  insertEmployeeDetails,
} from "../../actions";
import "./hrBoard.css";
import AddEmployee from "./addEmployee";
import Modal from "../modal/baseModal";
import { UUID } from "../../helpers/util";

const history = createBrowserHistory({
  forceRefresh: true,
});

class EmployeesList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      page: 1,
      perPage: 10,
      searchText: "",
      showModal: false,
      employee: null,
      showImportModal: false,
      errorsInFile: [],
    };
    this.newEmployeeDetails = this.newEmployeeDetails.bind(this);
    this.uploadEmployeeList = this.uploadEmployeeList.bind(this);
  }

  componentDidMount() {
    this.props.getEmployeesDetails();
  }

  newEmployeeDetails(
    employeeBadgeId,
    firstName,
    lastName,
    jobTitle,
    subject,
    organization,
    campuses,
    personalPhone,
    department,
    gender,
    pan,
    fatherName,
    currentAddressLineOne,
    currentAddressLineTwo,
    currentAddressCity,
    currentAddressPinCode,
    permanentAddressLineOne,
    permanentAddressLineTwo,
    permanentAddressCity,
    permanentAddressPinCode,
    bloodGroup,
    dateOfJoining,
    workEmail,
    emergencyContactName,
    emergencyContactNumber,
    emergencyContactRelationship,
    currentSalary,
    status,
    personalEmail,
    workPhone,
    // picture,
    dateOfBirth
  ) {
    this.props
      .createNewEmployee(
        employeeBadgeId,
        firstName,
        lastName,
        jobTitle,
        subject,
        organization,
        campuses,
        personalPhone,
        department,
        gender,
        pan,
        fatherName,
        currentAddressLineOne,
        currentAddressLineTwo,
        currentAddressCity,
        currentAddressPinCode,
        permanentAddressLineOne,
        permanentAddressLineTwo,
        permanentAddressCity,
        permanentAddressPinCode,
        bloodGroup,
        dateOfJoining,
        workEmail,
        emergencyContactName,
        emergencyContactNumber,
        emergencyContactRelationship,
        currentSalary,
        status,
        personalEmail,
        workPhone,
        // picture,
        dateOfBirth
      )
      .then((response) => {
        this.setState({ showModal: false, employee: "" });
      });
  }

  renderFilters() {
    const { searchText } = this.state;
    return (
      <div style={{ marginLeft: 15, display: "flex", flexDirection: "row" }}>
        <input
          type="text"
          defaultValue={searchText}
          placeholder="Search using employee name"
          style={{ width: 300 }}
          onChange={(event) =>
            this.setState({ searchText: event.target.value })
          }
          autoFocus
        />
      </div>
    );
  }

  renderEmployeesList() {
    const { page, perPage, searchText } = this.state;
    const { employeesList, totalEmployeesCount } = this.props;

    const filteredEmployeesList = !!searchText
      ? employeesList.filter((employee) => {
          return (
            (!!employee.employee_badge_id &&
              employee.employee_badge_id
                .toLowerCase()
                .includes(searchText.toLowerCase())) ||
            (!!employee.first_name &&
              employee.first_name
                .toLowerCase()
                .includes(searchText.toLowerCase())) ||
            (!!employee.last_name &&
              employee.last_name
                .toLowerCase()
                .includes(searchText.toLowerCase()))
          );
        })
      : employeesList;

    if (totalEmployeesCount === 0) {
      return (
        <div style={{ width: "100%" }}>
          <span
            className="heading-1"
            style={{
              margin: "30px auto",
              display: "flex",
              justifyContent: "center",
              fontSize: 20,
            }}
          >
            No Employees exist, please add an employee or change search criteria
          </span>
        </div>
      );
    }

    return (
      <div style={{ margin: 15, marginTop: 45 }}>
        <span className="heading-1">
          No. of employees: {filteredEmployeesList.length}
        </span>
        <table className="table-bordered">
          <thead>
            <tr>
              <th style={{ textAlign: "right" }}>Employee Id</th>
              <th>Name</th>
              <th>Job Title</th>
              <th>Subject</th>
              <th>Organization</th>
              <th>Campus</th>
              <th style={{ textAlign: "right" }}>Personal Phone</th>
            </tr>
          </thead>
          <tbody>
            {filteredEmployeesList &&
              filteredEmployeesList
                .slice((page - 1) * perPage, page * perPage)
                .map((employee) => {
                  return (
                    <tr
                      key={employee.id}
                      style={{ cursor: "pointer" }}
                      onClick={() => {
                        this.props.history.push(
                          `/hr_board/employee_profile?id=${employee.id}`
                        );
                      }}
                    >
                      <td style={{ textAlign: "right", maxWidth: 150 }}>
                        {employee.employee_badge_id}
                      </td>
                      <td>{employee.first_name + " " + employee.last_name}</td>
                      <td>{employee.job_title}</td>
                      <td>{employee.subject}</td>
                      <td>{employee.organization}</td>
                      <td>
                        {employee.campuses
                          .map((campus) => {
                            return campus.campus_name;
                          })
                          .join(", ") || "-"}
                      </td>
                      <td style={{ textAlign: "right" }}>
                        {employee.personal_phone}
                      </td>
                    </tr>
                  );
                })}
          </tbody>
        </table>
        <div style={{ display: "flex", justifyContent: "center" }}>
          <Pagination
            activePage={page}
            itemsCountPerPage={perPage}
            totalItemsCount={filteredEmployeesList.length}
            pageRangeDisplayed={5}
            onChange={(pageNumber) => this.setState({ page: pageNumber })}
          />
        </div>
      </div>
    );
  }

  renderAddEmployeeButton() {
    return (
      <div
        className="Button primary-Button"
        style={{ width: 200, margin: "auto 15px" }}
        onClick={() => {
          this.setState({ employee: "new", showModal: true });
        }}
      >
        Add Employee
      </div>
    );
  }

  getEmployeeListFile() {
    document.getElementById("uploadEmployeeListFile").click();
  }

  uploadEmployeeList(event) {
    const fileList = event.target.files;
    const files = Object.values(fileList);
    const sendFiles = files.map((file) => {
      return new File([file], `${UUID()}.xlsx`, { type: file.type });
    });
    this.props.insertEmployeeDetails(sendFiles).then((response) => {
      if (response.success) {
        this.setState({ showImportModal: false, errorsInFile: [] });
      } else {
        response.errors && this.setState({ errorsInFile: response.errors });
      }
    });
  }

  renderImportEmployeesButton() {
    return (
      <div
        className="Button primary-Button"
        style={{ width: 200, margin: "auto 15px" }}
        onClick={() => {
          this.setState({ showImportModal: true });
        }}
      >
        Import Employees
      </div>
    );
  }

  renderImportEmployeesModal() {
    const { showImportModal, errorsInFile } = this.state;
    return (
      <Modal
        showModal={showImportModal}
        handleCloseModal={() =>
          this.setState({ showImportModal: false, errorsInFile: [] })
        }
        width={"20%"}
        header={"Insert Employees"}
      >
        <div style={{ width: "100%", padding: 20 }}>
          <a
            download
            target="_blank"
            href="/assets/hr_board_format.xlsx"
            style={{ width: "100%" }}
          >
            <div
              className="Button secondary-Button"
              style={{ width: "100%", marginBottom: 10 }}
              onClick={() => {}}
            >
              Download .xslx
            </div>
          </a>
          <div
            className="Button primary-Button"
            style={{ width: "100%" }}
            onClick={() => this.getEmployeeListFile()}
          >
            Insert data
          </div>
          <div style={{ height: "0px", width: "0px", overflow: "hidden" }}>
            <input
              id="uploadEmployeeListFile"
              type="file"
              name="result"
              accept=".xlsx"
              multiple="false"
              onChange={this.uploadEmployeeList}
            />
          </div>
          <div style={{ marginTop: 20, overflowY: "scroll", maxHeight: 200 }}>
            {errorsInFile.length !== 0 &&
              errorsInFile.map((error, index) => {
                return (
                  <div style={{ color: "red" }}>
                    <span>{index + 1}. </span>
                    <span>{error}</span>
                  </div>
                );
              })}
          </div>
        </div>
      </Modal>
    );
  }

  render() {
    const { showModal, showImportModal } = this.state;

    if (this.props.isLoading) {
      return <Loader />;
    }

    return (
      <div style={{ marginLeft: "20%", padding: 15, height: "100vh" }}>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            marginTop: 50,
          }}
        >
          {this.renderFilters()}
          <div style={{ display: "flex" }}>
            {this.renderImportEmployeesButton()}
            {this.renderAddEmployeeButton()}
          </div>
        </div>
        {this.renderEmployeesList()}
        {showModal && (
          <AddEmployee
            showModal={this.state.showModal}
            handleCloseModal={() =>
              this.setState({ showModal: false, employee: "" })
            }
            newEmployeeDetails={this.newEmployeeDetails}
            employee={this.state.employee}
            campuses={this.props.campuses}
          />
        )}
        {showImportModal && this.renderImportEmployeesModal()}
      </div>
    );
  }
}

EmployeesList.propTypes = {};

EmployeesList.defaultProps = {};

const mapStateToProps = ({ hrBoard, institute }) => ({
  employeesList: hrBoard.employeesList,
  totalEmployeesCount: hrBoard.totalEmployeesCount,
  isLoading: hrBoard.isLoading,
  campuses: institute.campuses,
});

export default withRouter(
  connect(mapStateToProps, {
    getEmployeesDetails,
    createNewEmployee,
    insertEmployeeDetails,
  })(EmployeesList)
);
