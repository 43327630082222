import React, { Component } from 'react';
import { connect } from 'react-redux';
import Select from 'react-select';
import Pagination from 'react-js-pagination';
import { ExcelExport, ExcelExportColumn } from '@progress/kendo-react-excel-export';
import Layout from '../layout';
import Constants from '../../shared/constants';
import Loader from '../loading';
import Menubar from '../menubar';
import AccessDenied from '../accessDenied';
import search from '../../static/images/search.svg';
import EditSubjectDetailsModal from '../modal/editSubjectDetailsModal';
import '../../styles/instituteOnBoarding.css';
import { createNewSubject, updateSubjectDetails, getSubjectDetails } from '../../actions';

import { FILE_TYPE } from '../../constants';

class SubjectDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showModal: false,
      showLoader: true,
      campusId: '',
      courseId: '',
      classroomId: '',
      searchString: '',
      subject: null,
      activePage: 1,
      itemsCountPerPage: 10,
    };
    this.renderOverviewList = this.renderOverviewList.bind(this);
    this.subjectSearch = this.subjectSearch.bind(this);
    this.updateSearch = this.updateSearch.bind(this);
    this.updateCurrentSubjectDetail = this.updateCurrentSubjectDetail.bind(this);
    this.newSubjectDetails = this.newSubjectDetails.bind(this);
    this.subjectDetailsSave = React.createRef();
  }

  componentDidMount() {
    const query = new URLSearchParams(window.location.search);
    this.props.getSubjectDetails({ instituteId: query.get('institute_id') }).then(() => this.setState({ showLoader: false }));
  }

  fetchSubjectData() {
    const {
      course, campus, classroom, searchString, department, activePage,
    } = this.state;
    const query = new URLSearchParams(window.location.search);
    const payload = {
      searchString,
      courseId: course ? course.value : '',
      campusId: campus ? campus.value : '',
      classroomId: classroom ? classroom.value : '',
      departmentId: department ? department.value : '',
      instituteId: query.get('institute_id'),
      activePage,
    };
    this.props.getSubjectDetails(payload).then(() => {
      if (typeof window !== 'undefined') {
        window.scrollTo(0, 0);
      }
    });
  }

  subjectSearch(e) {
    const searchString = e.target.value;
    this.setState({ searchString }, () => this.fetchSubjectData());
  }

  updateCurrentSubjectDetail(subjectId, subjectName, subjectCode, selectedCourses) {
    const { activePage } = this.state;
    const query = new URLSearchParams(window.location.search);
    this.props.updateSubjectDetails(subjectId, subjectName, subjectCode, selectedCourses,
      activePage, query.get('institute_id')).then(() => this.fetchSubjectData());
  }

  newSubjectDetails(subjectName, subjectCode, courses) {
    const query = new URLSearchParams(window.location.search);
    this.props.createNewSubject(subjectName, subjectCode,
      courses, query.get('institute_id')).then(() => this.fetchSubjectData());
  }

  updateSearch(selectedItem, payloadType, type) {
    const query = new URLSearchParams(window.location.search);
    const {
      course, campus, classroom, searchString, department,
    } = this.state;
    const payload = {
      searchString,
      courseId: course ? course.value : '',
      campusId: campus ? campus.value : '',
      classroomId: classroom ? classroom.value : '',
      departmentId: department ? department.value : '',
      instituteId: query.get('institute_id'),
    };
    payload[payloadType] = selectedItem ? selectedItem.value : '';
    this.setState({ [type]: selectedItem, [payloadType]: selectedItem ? selectedItem.value : '', activePage: 1 });
    this.props.getSubjectDetails(payload).then(() => this.setState({ [type]: selectedItem }));
  }

  domainSearch(filterItems, selectedItem, selectedFunction, item) {
    if (!filterItems) {
      return true;
    }
    const customStyles = {
      control: (base, state) => ({
        ...base,
        height: 35,
        width: 200,
        backgroundColor: 'white',
        border: '1px solid #CDCFD6 !important',
      }),
      indicatorsContainer: (base, state) => ({
        ...base,
        height: 35,
      }),
      valueContainer: (base, state) => ({
        ...base,
        height: 40,
      }),
      option: (base, state) => ({
        ...base,
        textAlign: 'left',
      }),
    };
    const items = [...filterItems, { id: null, value: '', label: 'All' }];
    return (
      <div style={{ textAlign: 'center', marginLeft: '25px' }}>
        <div style={{ alignItems: 'center', marginLeft: '10px' }}>
          <Select
            styles={customStyles}
            value={selectedItem && selectedItem.value ? selectedItem : { label: item, value: '' }}
            defaultValue={{ label: item, value: 0 }}
            onChange={selectedFunction}
            options={items}
            placeholder="All"
            isClearable={selectedItem && selectedItem.value}
          />
        </div>
      </div>
    );
  }

  handlePageChange(pageNumber) {
    this.setState({ activePage: pageNumber }, () => this.fetchSubjectData());
  }

  exportFile() {
    const {
      departmentId, courseId, campusId, classroomId, activePage,
    } = this.state;
    const query = new URLSearchParams(window.location.search);
    const payload = {
      departmentId,
      courseId,
      campusId,
      classroomId,
      isDownload: true,
      instituteId: query.get('institute_id'),
      activePage,
    };
    this.props.getSubjectDetails(payload).then(() => {
      this.subjectDetailsSave.current.save();
    });
  }

  createExport() {
    const { isHigherEducationInstitute } = this.props;
    const fields = ['S.No', 'Name', 'Code', isHigherEducationInstitute ? 'Department - Semester' : 'Courses'];
    const fieldIds = ['index', 'name', 'subject_code', isHigherEducationInstitute ? 'departments_semester' : 'subject_courses'];
    return {
      fields,
      fieldsIds: fieldIds,
    };
  }

  renderCourses(department) {
    return (
      (department.courses).map((course) => {
        if (course && course.name) {
          return (
            <div style={{ display: 'flex', marginTop: 5 }}>
              {course.is_elective ? `${course.name} (E)` : course.name}
            </div>
          );
        }
      })
    );
  }

  renderSubjectList(subject, ct) {
    const { isHigherEducationInstitute } = this.props;
    const cellStyle = { textAlign: 'left', verticalAlign: 'middle' };
    return (
      <tr style={{ fontSize: '14px', height: 50 }}>
        <td role="presentation" className="clickable" style={{ ...cellStyle, paddingLeft: 50, width: '40%' }} onClick={() => this.setState({ showModal: true, subject, subjectIndex: ct })}>{subject.name}</td>
        <td style={{ ...cellStyle, width: '20%' }}>{subject.subject_code}</td>
        {isHigherEducationInstitute && (
        <td style={{ ...cellStyle, width: '15%' }}>
          {Object.values(subject.departments).map((department) => {
            return <div>{department.dept_code}</div>;
          })}
        </td>
        )}
        {isHigherEducationInstitute && (
        <td style={{ textAlign: 'center', width: '15%' }}>
          {Object.values(subject.departments).map((department) => {
            return this.renderCourses(department);
          })}
        </td>
        )}
        {!isHigherEducationInstitute && (
        <td style={{ textAlign: 'center', width: '15%' }}>
          {Object.values(subject.courses).map((course) => (
            <div style={{ display: 'flex', marginTop: 5 }}>
              {course.is_elective ? `${course.name} (E)` : course.name}
            </div>
          ))}
        </td>
        )}
      </tr>
    );
  }

  renderSearchFilters() {
    const {
      classrooms, departments, isHigherEducationInstitute, courses, deptCourses,
    } = this.props;
    const { campus, course, department } = this.state;
    let filteredClassrooms = isHigherEducationInstitute ? [] : classrooms;
    let filteredCourses = isHigherEducationInstitute ? [] : courses;
    if (department && department.value) {
      filteredCourses = courses.filter((courses) => courses.department_id == department.value);
    }
    if (campus && campus.value) {
      filteredClassrooms = classrooms.filter((classRoom) => classRoom.campus_id == campus.value);
    }
    if (course && course.value) {
      filteredClassrooms = classrooms.filter((classRoom) => classRoom.course_id == course.value);
    }
    if (isHigherEducationInstitute) {
      return (
        <>
          {this.domainSearch(departments, this.state.department, (selectedOption) => {
            this.updateSearch(selectedOption, 'departmentId', 'department'); this.setState({
              course: '', courseId: '', classroomId: '', classroom: '',
            });
          }, 'Departments')}
          {this.domainSearch(filteredCourses, this.state.course, (selectedOption) => { this.updateSearch(selectedOption, 'courseId', 'course'); this.setState({ classroom: '', classroomId: '' }); }, 'Semester')}
          {this.domainSearch(filteredClassrooms, this.state.classroom, (selectedOption) => this.updateSearch(selectedOption, 'classroomId', 'classroom'), 'Classroom')}
        </>
      );
    }
    return (
      <>
        {this.domainSearch(deptCourses, this.state.course, (selectedOption) => { this.updateSearch(selectedOption, 'courseId', 'course'); this.setState({ classroom: '', classroomId: '' }); }, 'Course')}
      </>
    );
  }

  renderButton(type) {
    return (
      <div
        role="presentation"
        style={{
          height: 35, width: 140, marginLeft: (type !== 'Insert') ? 30 : 0, display: 'flex', justifyContent: 'center', alignItems: 'center', border: '1px solid #40A7FF', color: '#40A7FF', borderRadius: 4, cursor: 'pointer',
        }}
        onClick={() => this.exportFile(FILE_TYPE.EXCEL)}
      >
        {type}
      </div>
    );
  }

  renderOverviewList() {
    const { subjectDetailsLength, isHigherEducationInstitute } = this.props;
    const {
      selectedSubjects, departmentId, courseId, classroomId, searchString, subject,
    } = this.state;
    const isSubjectsSelected = selectedSubjects && selectedSubjects.length;
    let selectedCourses = [];

    if (this.state.subject) {
      if (!isHigherEducationInstitute && this.state.subject.courses) {
        subject.courses.map((course) => selectedCourses = [...selectedCourses, { isElective: course.is_elective, label: course.is_elective ? `${course.name} (E)` : `${course.name}`, value: course.id }]);
      } else if (this.state.subject.departments) {
        Object.entries(this.state.subject.departments).map(([dept_id, { dept_code, courses }]) => selectedCourses = [...selectedCourses, ...courses.map((course) => {
          return {
            deptId: dept_id, isElective: course.is_elective, label: course.is_elective ? `${dept_code} - ${course.name} (E)` : `${dept_code} - ${course.name}`, value: course.id,
          };
        })]);
      }
    }

    return (
      <div>
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <div style={{
            display: 'flex', backgroundColor: '#FFFFFF', height: 60, width: '100%', alignItems: 'center', justifyContent: 'space-between', padding: '0px 50px', zIndex: 2, position: 'fixed', width: '80%',
          }}
          >
            <div style={{
              display: 'flex', justifyContent: 'space-between', width: 350, border: '1px solid #CDCFD6', borderRadius: 4, height: 40,
            }}
            >
              <div style={{ paddingLeft: 15, display: 'flex', alignItems: 'center' }}>
                <img src={search} width="16" height="16" />
              </div>
              <div style={{ width: 330, display: 'flex', justifyContent: 'space-between' }}>
                <div style={{ width: '100%', display: 'flex', alignItems: 'center' }}>
                  <input
                    className="input-field"
                    id="Search"
                    onChange={this.subjectSearch}
                    type="text"
                    placeholder="Search..."
                    value={this.state.searchString}
                    style={{
                      width: '99%', border: 'none', marginBottom: 0, height: 35,
                    }}
                  />
                </div>
              </div>
            </div>
            <div style={{ display: 'flex' }}>
              {this.renderSearchFilters()}
            </div>
          </div>
        </div>
        <div style={{ padding: '0px 50px', backgroundColor: '#F5F8FA' }}>
          {this.state.subject && this.state.showModal
            && (
            <EditSubjectDetailsModal
              showModal={this.state.subject && this.state.showModal}
              handleCloseModal={() => this.setState({ showModal: false, subject: '' })}
              updateCurrentSubjectDetail={this.updateCurrentSubjectDetail}
              newSubjectDetails={this.newSubjectDetails}
              subject={this.state.subject}
              courses={this.props.deptCourses}
              selectedCourses={selectedCourses}
              departments={this.props.departments}
              isHigherEducationInstitute={isHigherEducationInstitute}
            />
            )}
          <div className="heading-2" style={{ marginTop: 70 }}>
            Total Subjects:
            {subjectDetailsLength}
          </div>
          <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: 10 }}>
            <div
              role="presentation"
              style={{
                height: 35, width: 140, display: 'flex', justifyContent: 'center', alignItems: 'center', border: '1px solid #CDCFD6', borderRadius: 4, cursor: 'pointer',
              }}
              onClick={() => this.setState({ subject: 'new', showModal: true })}
            >
              Add Subject
            </div>
            {this.renderButton('Export')}
          </div>
          <div className="mt-5">
            <table className="table" style={{ boxShadow: '1px 2px 6px #8B9DAF33', borderRadius: 4, backgroundColor: '#FFFFFF' }}>
              <thead>
                <tr
                  className="table-header"
                  style={{
                    fontSize: '18px', fontWeight: 700, height: 60, color: '#11426C',
                  }}
                >
                  {this.renderSubjectListHeader()}
                </tr>
              </thead>
              <tbody>
                {Object.values(this.props.subjectData).map((subject, index) => {
                  return this.renderSubjectList(subject, index);
                })}
              </tbody>
            </table>
            {Object.values(this.props.subjectData).length < 1 && (
            <div style={{
              display: 'flex', justifyContent: 'center', alignItems: 'center', color: '#CDCFD6', height: 60, backgroundColor: '#FFFFFF',
            }}
            >
              No results found
            </div>
            )}
            <style jsx>
              {
                `table, th, td {
                    text-align: center;
                }
                td {
                    vertical-align: middle;
                }
                `
            }
            </style>
          </div>
        </div>
        {Object.values(this.props.subjectData).length > 0
            && (
            <div style={{ display: 'flex', justifyContent: 'center', width: '100%' }}>
              <Pagination
                activePage={this.state.activePage}
                itemsCountPerPage={this.state.itemsCountPerPage}
                totalItemsCount={this.props.subjectDetailsLength}
                pageRangeDisplayed={5}
                onChange={(event) => this.handlePageChange(event)}
              />
            </div>
            )}
      </div>
    );
  }

  renderSubjectListHeader() {
    const { isHigherEducationInstitute } = this.props;
    const headerStyle = { borderTop: 'none', textAlign: 'left' };
    return (
      <>
        <th className="table-header" style={{ ...headerStyle, paddingLeft: 50, width: '50%' }}>Name</th>
        <th className="table-header" style={{ ...headerStyle, width: '20%' }}>Code</th>
        {isHigherEducationInstitute && <th className="table-header" style={{ ...headerStyle, width: '15%' }}>Department</th>}
        {isHigherEducationInstitute && <th className="table-header" style={{ ...headerStyle, textAlign: 'center', width: '15%' }}>Semester</th>}
        {!isHigherEducationInstitute && <th className="table-header" style={{ ...headerStyle, textAlign: 'center', width: '15%' }}>Course</th>}
      </>
    );
  }

  render() {
    const { instituteOnBoardingMenu } = Constants();
    const query = new URLSearchParams(window.location.search);
    const instituteId = query.get('institute_id');
    const metaData = this.createExport();
    const { fields } = metaData;
    const fieldIds = metaData.fieldsIds;
    instituteOnBoardingMenu.forEach((page) => {
      page.route = `${page.route}${instituteId !== null ? `?institute_id=${instituteId}` : ''}`;
    });
    if (this.state.showLoader) {
      return <Loader />;
    }
    if (this.props.permissionedDenied) {
      return (
        <AccessDenied />
      );
    }
    return (
      <Layout globalHistory={this.props.history} hideHeader>
        <div className="custom-checkbox" style={{ width: '100%', display: 'flex', minHeight: '100vh' }}>
          <div style={{ width: '20%', padding: 0, zIndex: 1 }}>
            <Menubar history={this.props.history} menuList={instituteOnBoardingMenu} selectedOption={5} redirectToRoot />
          </div>
          <ExcelExport
            data={Object.values(this.props.subjectDetailsSave)}
            fileName="SubjectDetails.xlsx"
            ref={this.subjectDetailsSave}
          >
            {fields.map((field, index) => (<ExcelExportColumn field={fieldIds[index]} title={field} />))}
          </ExcelExport>
          <div style={{ width: '80%', backgroundColor: '#F5F8FA' }}>
            {this.renderOverviewList()}
          </div>
        </div>
      </Layout>
    );
  }
}
const mapStateToProps = ({ institute }) => ({
  campuses: institute.campuses,
  classrooms: institute.classrooms,
  courses: institute.courses,
  deptCourses: institute.deptCourses,
  subjectData: institute.subjectData,
  subjectDetailsSave: institute.subjectDetailsSave,
  permissionedDenied: institute.permissionedDenied,
  subjectDetailsLength: institute.subjectDetailsLength,
  departments: institute.departments,
  isHigherEducationInstitute: institute.isHigherEducationInstitute,
});

export default connect(mapStateToProps, { createNewSubject, updateSubjectDetails, getSubjectDetails })(SubjectDetails);
