import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { getOrUpdateQuiz, showToast } from '../../actions';
import { returnCurrentDate } from '../../helpers/util';
import '../../styles/instituteOnBoarding.css';
import '../../static/tools.css';

class QuizUpdate extends PureComponent {
  constructor(props) {
    super(props);
    const { quizData, setDate } = props;
    this.state = {
      quizName: quizData.name,
      quizDate: setDate, // change created_at to quiz_date
    };
  }

  onTodoChange(value, type) {
    const { quizDate } = this.state;
    if (type === 'quizDate') {
      if (new Date().setHours(0, 0, 0, 0) > new Date(value)) {
        this.setState({ [type]: quizDate });
        this.props.showToast('Quiz date should be in Future');
        return;
      }
    }
    this.setState({ [type]: value });
  }

  updateQuizData(quizName, quizDate) {
    const { quizData } = this.props;
    const quizId = quizData.id;
    if (quizName && quizName.length > 0 && quizDate) {
      this.props.getOrUpdateQuiz(quizId, quizName, quizDate).then(() => {
        this.props.showToast('Quiz Updated Successfully');
      });
      return;
    }
    this.props.showToast('Quiz Name and Date should not be empty');
  }

  render() {
    const { quizName, quizDate } = this.state;
    return (
      <div className="Card-View" style={{ marginTop: 30 }}>
        <div style={{ display: 'flex' }}>
          <div style={{ margin: '1%', alignSelf: 'center' }}>
            Quiz Name
          </div>

          <input
            className="input-field"
            type="text"
            onChange={(e) => this.onTodoChange(e.target.value, 'quizName')}
            value={quizName}
            style={{
              width: '40%', marginBottom: 0, height: 40,
            }}
          />

          <div style={{ margin: '1%', marginLeft: 40, alignSelf: 'center' }}>
            Quiz Date
          </div>
          <input
            className="input-field"
            min={returnCurrentDate()}
            type="date"
            onChange={(e) => this.onTodoChange(e.target.value, 'quizDate')}
            value={quizDate}
            style={{
              width: '20%', marginBottom: 0, height: 40,
            }}
          />

          <div
            className="edit-button"
            role="presentation"
            style={{
              marginLeft: 40, marginBottom: 0, width: '10%', height: 40, backgroundColor: '#3994AE',
            }}
            onClick={() => this.updateQuizData(quizName, quizDate)}
          >
            Update
          </div>
        </div>

      </div>
    );
  }
}

QuizUpdate.propTypes = {
  quizData: PropTypes.object,
  quizDate: PropTypes.string,
  setDate: PropTypes.string,
};

QuizUpdate.defaultProps = {
  quizData: {},
  quizDate: '',
  setDate: '',
};

const mapStateToProps = ({ quiz }) => ({
  quizData: quiz.quizData,
  setDate: quiz.setDate,
  quizDate: quiz.quizDate,
});

export default connect(mapStateToProps, {
  getOrUpdateQuiz,
  showToast,
})(QuizUpdate);
