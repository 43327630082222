import Api from '../helpers/Api';

import { showToast } from '.';

export const GET_QUESTIONS = 'tagComplexity/GET_QUESTIONS';
export const UPDATE_INDEX = 'tagComplexity/UPDATE_INDEX';
export const UPDATE_COMPLEXITY = 'tagComplexity/UPDATE_COMPLEXITY';
export const GET_TOPICS = 'tagComplexity/GET_TOPICS';

export const getQuestionData = (chapterId) => async (dispatch) => {
  const response = await Api({
    method: 'get',
    url: `/question_tagging/${chapterId}/get_questions_to_tag_complexity`,
  });
  if (response.success) {
    dispatch({
      type: GET_QUESTIONS,
      questions: response.questions,
      index: response.index,
    });
  }
  return response;
};

export const updateIndex = (offset) => async (dispatch) => {
  dispatch({
    type: UPDATE_INDEX,
    payload: offset,
  });
};

export const updateComplexity = (index, complexity,
  previousComplexity, timeSpent, scope) => async (dispatch, getState) => {
  const { tagComplexity: { questions } } = getState();
  dispatch({
    type: UPDATE_COMPLEXITY,
    payload: { index, complexity },
  });
  scope.forceUpdate();
  const response = await Api({
    method: 'post',
    url: `/question_tagging/${questions[index].id}/update_question_complexity`,
    data: { complexity, timeSpent },
  });
  if (response.success) {
    dispatch(updateIndex(1));
  } else {
    dispatch(showToast('Failed to update! Please try again!'));
    dispatch({
      type: UPDATE_COMPLEXITY,
      payload: { index, previousComplexity },
    });
  }
  return Promise.resolve();
};

export const getTopics = () => async (dispatch, getState) => {
  const response = await Api({
    method: 'get',
    url: '/question_tagging/get_topics',
  });
  dispatch({
    type: GET_TOPICS,
    payload: response.success ? response.topics : [],
  });
  return Promise.resolve();
};

export const reportIssue = (questionId) => async (dispatch, getState) => {
  const response = await Api({
    method: 'post',
    url: '/report_issue/create',
    data: { problem_type: 0, question_id: questionId, status: 0 },
  });
  if (response && response.success) {
    dispatch(showToast('Thank you! we will look into it'));
  } else {
    dispatch(showToast('Failed to report error. Try later!'));
  }
};
