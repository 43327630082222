import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
  getMeetingQuizData, getUserInfo, updateLiveResults, updateShowStatus,
} from '../../actions';
import Layout from '../layout';
import Loader from '../loading';
import Menubar from '../menubar';
import QuizQuestions from '../quizQuestions';
import { QuizQuestionView } from '../common';
import LocaleDate from '../localeDate';
import Constants from '../../shared/constants';

class ConductQuiz extends Component {
  constructor(props) {
    super(props);
    const { id, type } = props.match.params;
    this.state = {
      type,
      id,
      showLoader: true,
      isAddQuestion: false,
    };
    this.createNewQuestion = this.createNewQuestion.bind(this);
    this.removeNewQuestion = this.removeNewQuestion.bind(this);
  }

  componentDidMount() {
    const { id } = this.state;
    this.props.getUserInfo();
    this.props.getMeetingQuizData(id).then(() => {
      this.setState({ showLoader: false });
    });
  }

  removeNewQuestion() {
    this.setState({ isAddQuestion: false });
  }

  createNewQuestion() {
    return (
      <div
        className="edit-button"
        role="presentation"
        style={{
          height: 35, marginBottom: 0, width: 150, backgroundColor: '#3994AE', marginLeft: 'auto', marginRight: 80, whiteSpace: 'nowrap', marginTop: 30,
        }}
        onClick={() => { this.setState({ isAddQuestion: true }); }}
      >
        New Question
      </div>
    );
  }

  showQuestion() {
    const { currentMeetingQuizQuestions } = this.props;
    if (Object.keys(currentMeetingQuizQuestions).length > 0) {
      return (
        <div style={{
          width: '100%', padding: 150,
        }}
        >
          {currentMeetingQuizQuestions.map((question, index) => this.renderQuestionView(question, index))}
        </div>
      );
    }
    return <div style={{ paddingTop: 150, textAlignLast: 'center', fontSize: 20 }}>No Questions To Display</div>;
  }

  showLiveResults(question) {
    const { attendeesCount, optionList } = this.props;
    const outerStyle = { display: 'flex' };
    const innerStyle = { marginRight: 10, width: 50 };
    const optionData = (optionList && optionList[question.id] && Object.keys(optionList[question.id].data || {}).length
    && JSON.parse(optionList[question.id].data)) || {};
    const correctAnswerPercentage = parseInt(optionData[`option_${question.answer}_percentage`], 10);
    const attemptedCount = optionList[question.id].count;
    return (
      <div style={{
        width: '100%', fontSize: 20, border: '1px solid #343737', padding: 20, height: '100%', borderLeft: 0, whiteSpace: 'nowrap', background: '#FFFFFF',
      }}
      >
        <div style={outerStyle}>
          <div style={innerStyle}>{`${correctAnswerPercentage || 0} %`}</div>
          Answered Correctly
        </div>
        <div style={outerStyle}>
          <div style={innerStyle}>{attemptedCount}</div>
          Responded
        </div>
        <div style={outerStyle}>
          <div style={innerStyle}>
            {(attendeesCount - attemptedCount - 1) > 0
              ? (attendeesCount - attemptedCount - 1) : 0 }

          </div>
          Did Not Respond
        </div>
        <div style={{ marginTop: 10 }}>
          {Array.from(Array(4).keys()).map((option) => {
            const isAnswer = Number(question.answer) === Number(option + 1);
            const percentage = optionData && parseInt(optionData[`option_${option + 1}_percentage`], 10);
            return (
              <div style={{ ...outerStyle, color: isAnswer ? 'green' : 'red' }}>
                <div style={innerStyle}>{`${percentage || 0} %`}</div>
                {`Option ${option + 1}`}
              </div>
            );
          })}
        </div>
      </div>
    );
  }

  renderAttemptedCount(question) {
    const { optionList } = this.props;
    const optionData = (optionList && optionList[question.id] && Object.keys(optionList[question.id].data || {}).length
        && JSON.parse(optionList[question.id].data)) || {};
    const count = optionList[question.id] && optionList[question.id].count;
    const countData = optionData && parseInt(optionData[`option_${question.answer}_count`], 10);
    const percentage = optionData && parseInt(optionData[`option_${question.answer}_percentage`], 10);
    if (count > 0) {
      return (
        <div style={{
          border: '1px solid #343737', padding: '10px 20px', borderTop: 0, fontSize: 16,
        }}
        >
          <b style={{ fontFamily: 'Nuntio' }}>
            {percentage.toFixed(0)}
                % &nbsp;(
            {countData || 0}
                &nbsp;
                of&nbsp;&nbsp;
            {count}
                )&nbsp;

          </b>
            students answered this question correctly
        </div>

      );
    }
    return (
      <div
        style={{
          border: '1px solid #343737', padding: '10px 20px', borderTop: 0, fontSize: 16,
        }}
      >
        No one attempted the question
      </div>
    );
  }

  renderQuestionView(question, index) {
    const { questionsLocked } = this.props;
    const isQuestionLocked = [...questionsLocked].includes(question.id);
    return (
      <div style={{ paddingBottom: 30 }}>
        <div style={{ fontSize: 16 }}>
            Question&nbsp;
          {index + 1}
        </div>
        <div style={{ display: 'flex', width: '100%' }}>
          <div style={{
            border: '1px solid #343737', background: '#FFFFFF', padding: 20, width: '100%',
          }}
          >
            <QuizQuestionView
              quizQuestion={{ ...question }}
              textChanges={{ ...question }}
            />
          </div>
          {/* {isQuestionLocked && (
          <div style={{ marginBottom: 40 }}>{this.showLiveResults(question)}</div>
          )}  will be used later */}
        </div>
        {isQuestionLocked && this.renderAttemptedCount(question)}
        {!isQuestionLocked && this.renderStatus(question)}
      </div>
    );
  }

  renderStatus(question) {
    const { questionsShown } = this.props;
    if (!([...questionsShown].includes(question.id))) {
      return (
        <div
          role="presentation"
          className="Button"
          style={{
            width: 100, background: '#AED580', borderTop: 0, borderRadius: 0,
          }}
          onClick={() => this.props.updateShowStatus([question.id],
            question.show, question.quiz_id)}
        >
        START
        </div>
      );
    }
    return (
      <div
        role="presentation"
        className="Button"
        style={{
          width: 100, background: '#ED6E8C', borderTop: 0, borderRadius: 0,
        }}
        onClick={() => this.props.updateLiveResults(question.id,
          question.quiz_id)}
      >
        FINISH
      </div>
    );
  }

  render() {
    const {
      id, type, showLoader, isAddQuestion,
    } = this.state;
    const { history } = this.props;
    if (showLoader) {
      return <Loader />;
    }
    const MENU_DATA = {
      SHOW_QUESTIONS: { label: 'Show Questions', route: `/conduct_quiz/show_questions/${id}` },
      QUIZ_QUESTIONS: { label: 'Quiz Questions', route: `/conduct_quiz/quiz_questions/${id}` },
    };
    const { meeting, userData, updatedCount } = this.props;
    const dataType = type && type.toUpperCase();
    const types = Object.keys(MENU_DATA);
    const index = types.indexOf(dataType);
    const isTitlePresent = meeting.module || meeting.lecture || meeting.title;
    return (
      <Layout hideHeader title="Quiz Board">
        <div style={{
          height: typeof window !== 'undefined' && window.innerHeight, userSelect: 'none', fontFamily: 'Roboto', backgroundColor: '#F5F8FA',
        }}
        >
          <Menubar
            history={history}
            menuList={Object.values(MENU_DATA)}
            selectedOption={index}
            userData={userData}
            redirectTo="/"
            renderHeader
            feedback
            help
            showProfileIcon
            openFeedbackModalView={this.openFeedbackModalView}
            openHelpModalView={this.openHelpModalView}
          />
          <div style={{ width: '80%', marginLeft: '20%' }}>
            <div style={{
              fontWeight: 600, fontSize: 22, padding: '15px 60px 10px', minHeight: 70, background: '#FFFFFF', color: Constants().color.primary, boxShadow: '0px 3px 6px #343B4014', width: '80%', position: 'fixed', display: 'flex', zIndex: 1000, justifyContent: 'space-between', top: 0,
            }}
            >
              <div style={{ display: 'flex', flexDirection: 'column' }}>
                <div>{meeting.subject_name || ''}</div>
                {isTitlePresent
                  ? (
                    <div style={{ marginTop: 2, fontSize: 14 }}>
                      {meeting.module ? `Module ${meeting.module} ${(meeting.lecture || meeting.title) ? ' | ' : ' '}` : ''}
                      {meeting.lecture ? `Lecture ${meeting.lecture} ${meeting.title ? ' | ' : ' '}` : ''}
                      {meeting.title ? meeting.title : ''}
                    </div>
                  )
                  : <></>}
                <div style={{ marginTop: 2, fontSize: 12 }}>
                  {meeting.classroom_names && meeting.classroom_names.join(', ')}
                </div>
              </div>
              <div style={{ marginLeft: 'auto' }}>
                <LocaleDate date={new Date(meeting.start_time)} />
              </div>
            </div>
            {dataType === 'SHOW_QUESTIONS' && this.showQuestion()}
            {dataType === 'QUIZ_QUESTIONS'
              && (
              <div style={{ paddingTop: 130, backgroundColor: '#F5F8FA' }}>
                {this.createNewQuestion()}
                <QuizQuestions
                  id={meeting.live_quiz_id}
                  style={{ paddingTop: 20 }}
                  updateShowStatus={this.updateShowStatus}
                  key={`${updatedCount}-${isAddQuestion}`}
                  isAddQuestion={isAddQuestion}
                  updatedCount={updatedCount}
                  removeNewQuestion={this.removeNewQuestion}
                />
              </div>
              )}
          </div>
        </div>
      </Layout>
    );
  }
}

ConductQuiz.propTypes = {
  router: PropTypes.object,
  meeting: PropTypes.object,
  userData: PropTypes.object,
  currentMeetingQuizQuestions: PropTypes.array,
  updatedCount: PropTypes.number,
  questionsLocked: PropTypes.array,
  questionsShown: PropTypes.array,
  optionList: PropTypes.object,
  attendeesCount: PropTypes.number,
};

ConductQuiz.defaultProps = {
  router: {},
  meeting: {},
  currentMeetingQuizQuestions: [],
  userData: {},
  updatedCount: 0,
  questionsShown: [],
  questionsLocked: [],
  optionList: {},
  attendeesCount: 0,
};

const mapStateToProps = ({ virtualMeeting }) => ({
  meeting: virtualMeeting.meeting,
  updatedCount: virtualMeeting.updatedCount,
  currentMeetingQuizQuestions: virtualMeeting.currentMeetingQuizQuestions,
  userData: virtualMeeting.user,
  questionsShown: virtualMeeting.questionsShown,
  questionsLocked: virtualMeeting.questionsLocked,
  optionList: virtualMeeting.optionList,
  attendeesCount: virtualMeeting.attendeesCount,
});

export default connect(mapStateToProps, {
  getMeetingQuizData, getUserInfo, updateLiveResults, updateShowStatus,
})(ConductQuiz);
