import React, { Component } from 'react';
import { connect } from 'react-redux';
import Layout from '../layout';
import { getCampusesClassrooms, toggleClassroomActive, editClassroom } from '../../actions';
import Constants from '../../shared/constants';
import AccessDenied from '../accessDenied';
import Modal from '../modal/baseModal';
import Menubar from '../menubar';
import '../../styles/instituteOnBoarding.css';
import CreateCampusModal from '../modal/createCampusModal';

const buttonStyle = {
  height: 30, width: 65, display: 'flex', alignItems: 'center', justifyContent: 'center', cursor: 'pointer',
};
class Classroom extends Component {
  constructor(props) {
    super(props);
    this.state = {
      editClassroomId: -1,
      classroomName: '',
      removeClassroomId: -1,
      showClassroomModel: false,
    };
    this.renderContent = this.renderContent.bind(this);
    this.renderCard = this.renderCard.bind(this);
    this.renderEditClassroom = this.renderEditClassroom.bind(this);
    this.closeClassroomEdit = this.closeClassroomEdit.bind(this);
    this.renderRemoveClassroom = this.renderRemoveClassroom.bind(this);
  }

  componentDidMount() {
    const query = new URLSearchParams(window.location.search);
    const instituteId = query.get('institute_id');
    this.props.getCampusesClassrooms(instituteId);
  }

  closeClassroomEdit() {
    this.setState({ editClassroomId: -1, classroomName: '' });
  }

  renderCampusTitle(campus, active) {
    const { campusesClassrooms } = this.props;
    const filteredClassrooms = campusesClassrooms[campus].filter((classroom) => classroom.active === active);
    const showCampus = filteredClassrooms.length > 0;
    return (
      <div key={campus}>
        {showCampus && <div className="heading-1">{campus}</div>}
        <div style={{ display: 'flex', flexWrap: 'wrap' }}>
          {filteredClassrooms.map((classRoom) => (
            this.renderCard(classRoom.classroom_name, classRoom.count, classRoom.classroom_id, active)
          ))}
        </div>
      </div>
    );
  }

  renderRemoveClassroom(id, className, active) {
    return (
      <div style={{ padding: 30 }}>
        <div style={{ display: 'inline' }}>
          Are you sure, You want to remove mark classroom
          {' '}
          {className}
          {' '}
          as
          {' '}
          {active ? 'inactive, marking it Inactive will mark all the students associated with the class as inactive' : 'Active'}
          ?
        </div>
        <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: 30 }}>
          <div
            className="primary-Button"
            role="presentation"
            style={buttonStyle}
            onClick={() => this.setState({ removeClassroomId: -1 })}
          >
            No
          </div>
          <div
            className="primary-Button"
            role="presentation"
            style={buttonStyle}
            onClick={() => this.props.toggleClassroomActive(id, !active).then(() => this.setState({ removeClassroomId: -1 }))}
          >
            Yes
          </div>
        </div>
      </div>
    );
  }

  renderEditClassroom(id, className, active) {
    const { classroomName } = this.state;
    return (
      <div style={{ padding: 30 }}>
        <div
          className="primary-Button"
          role="presentation"
          onClick={() => {
            this.setState({ removeClassroomId: id });
            this.closeClassroomEdit();
          }}
          style={{
            display: 'flex',
            justifyContent: 'center',
            marginLeft: 'auto',
            background: active ? 'red' : '#1d4c7f',
            height: 30,
            width: 150,
            cursor: 'pointer',
            alignItems: 'center',
          }}
        >
          {active ? 'Mark as Inactive' : 'Mark as Active'}
        </div>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <div style={{ marginRight: 20 }}>Classroom Name</div>
          <input
            type="text"
            placeholder="Classroom Name"
            id={id}
            value={classroomName}
            style={{
              fontWeight: '400',
              display: 'inline-block',
              fontSize: '14px',
              marginBottom: 10,
              overflow: 'auto',
              width: 200,
              resize: 'none',
              border: 0,
              borderBottom: '1px solid #454545',
              background: '#FFFFFF',
            }}
            onChange={(event) => {
              this.setState({ classroomName: event.target.value });
            }}
          />
        </div>
        <div style={{
          display: 'flex', justifyContent: 'space-between', width: '100%', marginTop: 30,
        }}
        >
          <div
            className="primary-Button"
            role="presentation"
            style={buttonStyle}
            onClick={() => this.closeClassroomEdit()}
          >
            Cancel
          </div>
          <div
            className="primary-Button"
            role="presentation"
            style={buttonStyle}
            onClick={() => {
              if (className !== classroomName) {
                this.props.editClassroom(id, classroomName).then(() => this.closeClassroomEdit());
              } else {
                this.closeClassroomEdit();
              }
            }}
          >
            Save
          </div>
        </div>
      </div>
    );
  }

  renderCard(className, count, id, active) {
    const { editClassroomId, removeClassroomId } = this.state;
    return (
      <div
        key={id}
        style={{
          width: '20%', height: 100, borderRadius: 4, backgroundColor: active ? '#6B94A9' : '#FFCCCB', color: '#FFFFFF', padding: 20, paddingTop: 2, paddingRight: 2, margin: 20,
        }}
      >
        <Modal showModal={id === removeClassroomId} handleCloseModal={() => this.setState({ removeClassroomId: -1 })} height="250px" width="500px" header={`Remove - ${className}`}>
          {this.renderRemoveClassroom(id, className, active)}
        </Modal>
        <Modal showModal={id === editClassroomId} handleCloseModal={() => this.closeClassroomEdit()} height="250px" width="600px" header={`Edit - ${className}`}>
          {this.renderEditClassroom(id, className, active)}
        </Modal>
        <div
          role="presentation"
          style={{
            marginLeft: 'auto', padding: '0px 10px', width: 'fit-content', color: '#6B94A9', background: '#FFFFFF', userSelect: 'none', cursor: 'pointer',
          }}
          onClick={() => { this.setState({ editClassroomId: id, classroomName: className }); }}
        >
          Edit
        </div>
        <div style={{
          fontSize: 22, display: 'flex', justifyContent: 'space-between', width: '100%', color: active ? 'white' : 'black',
        }}
        >
          <div style={{
            overflow: 'hidden', textOverflow: 'ellipsis', width: '100%', whiteSpace: 'nowrap',
          }}
          >
            {className}
          </div>
        </div>
        <div style={{ fontFamily: 'Nunito' }}>
          Students:
          {count}
        </div>
      </div>
    );
  }

  renderCourseContents(course, classroom) {
    return (
      <div key={course} style={{ marginBottom: 10, marginTop: 5 }}>
        <div className="heading-1">{course}</div>
        <div style={{ display: 'flex', flexWrap: 'wrap' }}>
          {Object.entries(classroom).map(([id, data]) => {
            return this.renderCard(data.name, data.count, id, data.active);
          })}
        </div>
      </div>
    );
  }

  renderDepartmentContents(dpt, courseDetails) {
    return (
      <div key={dpt} style={{ marginBottom: 20 }}>
        <div className="heading-1">{dpt}</div>
        {Object.entries(courseDetails).map(([course, classroom]) => {
          return this.renderCourseContents(course, classroom);
        })}
      </div>
    );
  }

  renderEmptyMessage(text) {
    return (
      <div style={{
        display: 'flex', justifyContent: 'center', alignItems: 'center', color: '#7E8398', height: 60, backgroundColor: '#FFFFFF',
      }}
      >
        { `No ${text} found`}
      </div>
    );
  }

  renderInstituteContents(active) {
    const {
      isHigherEducationInstitute, campusesClassrooms,
      departmentCourseClassrooms,
    } = this.props;
    if (isHigherEducationInstitute) {
      return (
        <>
          {Object.entries(departmentCourseClassrooms).map(([dpt, courseDetails]) => {
            return this.renderDepartmentContents(dpt, courseDetails);
          })}
          {Object.keys(departmentCourseClassrooms).length === 0 && this.renderEmptyMessage('Department')}
        </>
      );
    }
    return (
      <>
        {Object.keys(campusesClassrooms).map((campus) => (
          this.renderCampusTitle(campus, active)
        ))}
        {Object.keys(campusesClassrooms).length === 0 && this.renderEmptyMessage('Campus')}
      </>
    );
  }

  renderContent() {
    return (
      <>
        <div style={{ paddingTop: '30px', paddingBottom: '20px' }}>
          <div
            role="presentation"
            style={{
              height: 35, width: 140, display: 'flex', justifyContent: 'center', alignItems: 'center', border: '1px solid #CDCFD6', borderRadius: 4, cursor: 'pointer',
            }}
            onClick={() => this.setState({ showClassroomModel: true })}
          >
            New Classroom
          </div>
        </div>
        <div className="heading-1" style={{ color: '#11426C', marginBottom: 15, fontSize: 23 }}>Active Classrooms</div>
        <div>
          {this.renderInstituteContents(true)}
        </div>
        <div className="heading-1" style={{ color: '#11426C', marginBottom: 15, fontSize: 23 }}>Inactive Classrooms</div>
        <div>
          {this.renderInstituteContents(false)}
        </div>
      </>
    );
  }

  render() {
    const { showClassroomModel } = this.state;
    return (
      <div style={{ padding: 20 }}>
        {this.renderContent()}
        {showClassroomModel
          && (
          <CreateCampusModal
            visible
            submitButton="Create"
            header="Create New Classroom"
            onClose={() => this.setState({ showClassroomModel: false })}
          />
          ) }
      </div>
    );
  }
}
const mapStateToProps = ({ institute }) => ({
  campusesClassrooms: institute.campusesClassrooms,
  departmentCourseClassrooms: institute.departmentCourseClassrooms,
  isHigherEducationInstitute: institute.isHigherEducationInstitute,
  permissionedDenied: institute.permissionedDenied,
});

export default connect(mapStateToProps, {
  getCampusesClassrooms,
  toggleClassroomActive,
  editClassroom,
})(Classroom);
