import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import Lottie from 'react-lottie';
import test from '../../static/assets/lottie/createtest.json';

const LOADER_TYPES = {
  test,
};
class Loader extends PureComponent {
  constructor(props) {
    super(props);
    const { type, height, width } = props;
    this.state = {
      screenHeight: 0,
      height: 300,
      width: 290,
      type: type || 'test',
    };
  }

  componentDidMount() {
    this.setState({ screenHeight: global.innerHeight });
  }

  render() {
    const { speed, extraHeight, mainHeight } = this.props;
    const {
      screenHeight, type, height, width,
    } = this.state;
    const defaultOptions = {
      loop: true,
      autoplay: true,
      animationData: LOADER_TYPES[type],
      rendererSettings: {
        preserveAspectRatio: 'xMidYMid slice',
      },
    };
    return (
      <div style={{
        height: mainHeight || screenHeight - (extraHeight || 0), display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center',
      }}
      >
        <Lottie
          options={defaultOptions}
          height={height}
          width={width}
          speed={speed}
          isClickToPauseDisabled
        />
      </div>
    );
  }
}

Loader.propTypes = {
  height: PropTypes.number,
  width: PropTypes.number,
  speed: PropTypes.number,
  extraHeight: PropTypes.number,
};

Loader.defaultProps = {
  height: 500,
  width: 700,
  speed: 2,
  extraHeight: 0,
};

export default Loader;
