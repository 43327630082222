import { UPDATE_SEARCH_STRING, RESET_QUESTION_LIST, UPDATE_SEARCH_RESULTS } from '../actions';

const initialState = {
  searchText: '',
  questions: [],
  totalQuestionsCount: 0,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case UPDATE_SEARCH_STRING:
      return { ...state, ...action.payload };
    case RESET_QUESTION_LIST:
      return initialState;
    case UPDATE_SEARCH_RESULTS:
      return { ...state, ...action.payload };
    default:
      return state;
  }
}
