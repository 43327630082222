import React, { Component } from 'react';
import { connect } from 'react-redux';
import Cookies from 'universal-cookie';
import Loader from '../loading';
import Modal from '../modal/baseModal';
import Constants from '../../shared/constants';
import { ApplyFilter, DateRange } from '../common';
import { getTeacherAttendance } from '../../actions';

const cookies = new Cookies();
const roles = cookies.get('roles');

const endingDate = new Date();
endingDate.setDate(new Date().getDate() - 7);
const yesterday = new Date();
yesterday.setDate((yesterday).getDate() - 1);
const SELECTED_RANGE = {
  startDate: endingDate,
  endDate: new Date(),
  key: 'selection',
};

class TeacherAttendance extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedRange: SELECTED_RANGE,
      showDateRange: false,
    };
    this.selectDateRange = this.selectDateRange.bind(this);
    this.handleSelect = this.handleSelect.bind(this);
  }

  componentDidMount() {
    const { selectedRange } = this.state;
    this.props.getTeacherAttendance(selectedRange);
  }

  getReport() {
    const {
      departments, selectedDepartment,
    } = this.props.virtualMeeting;
    const { selectedRange } = this.state;
    const department = ((selectedDepartment && selectedDepartment.id) || !departments.length);
    this.props.getTeacherAttendance(
      selectedRange,
      Number.isInteger(department) ? department : '',
    );
  }

  truncate(name) {
    return name.length > 25 ? `${name.substring(0, 25)}...` : name;
  }

  selectDateRange() {
    const { selectedRange, showDateRange } = this.state;
    return (
      <Modal
        showModal={showDateRange}
        overrideCloseIcon
        handleCloseModal={() => this.setState({ showDateRange: false })}
        style={{
          padding: 30, overflowY: 'initial', overflowX: 'initial',
        }}
      >
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
          <DateRange
            showSelectionPreview={false}
            selectedRange={{ ...selectedRange }}
            color="#1c3e6b"
            maxDate={new Date()}
            showPreview={false}
            handleSelect={this.handleSelect}
          />
          <div
            className="Button"
            style={{ width: 150 }}
            role="presentation"
            onClick={() => {
              this.setState({ showDateRange: false });
              this.getReport();
            }}
          >
            SET
          </div>
        </div>
      </Modal>
    );
  }

  handleSelect(date) {
    this.setState({ selectedRange: date.selection });
  }

  filterView() {
    const {
      departments, isHigherEducation, selectedDepartment,
    } = this.props.virtualMeeting;

    return (
      <div style={{
        display: 'flex', flex: 1, alignItems: 'center', justifyContent: (isHigherEducation && roles.is_dean) ? 'space-between' : 'flex-end',
      }}
      >
        {isHigherEducation && roles.is_dean && (
          <div>
            <ApplyFilter
              inline
              hideClear
              hideTeacher
              hideSemester
              hideClassroom
              hideSubject
              departments={departments}
              isHigherEducation
              selectedDepartment={{ ...selectedDepartment }}
              applyDataFilters={() => this.getReport()}
            />
          </div>
        ) }
        <div
          role="presentation"
          className="Button Primary-Button"
          style={{ width: 150, marginLeft: 20 }}
          onClick={() => this.setState({ showDateRange: true })}
        >
          Date Range
        </div>
      </div>
    );
  }

  renderTable() {
    const {
      teacherAttendance,
    } = this.props.virtualMeeting;
    return (
      <div style={{ marginTop: 20 }}>
        { teacherAttendance.length > 0 ? (
          <table
            className="table table-hover table-bordered table-fixed"
            style={{
              boxShadow: '1px 2px 6px #8B9DAF33', borderRadius: 4, backgroundColor: '#FFFFFF', width: '100%', border: '1px solid #CDCFD6',
            }}
          >
            <thead style={{ background: '#F6F8FA', color: '#23394C' }}>
              <tr style={{
                fontSize: '18px', fontWeight: '700', height: 60, color: '#11426C',
              }}
              >
                <th rowSpan="1" style={{ verticalAlign: 'middle' }}> S.No </th>
                <th rowSpan="1" style={{ verticalAlign: 'middle', textAlign: 'left', width: '25%' }}> Teacher Name </th>
                <th rowSpan="1" style={{ verticalAlign: 'middle' }}> Scheduled Classes </th>
                <th rowSpan="1" style={{ verticalAlign: 'middle' }}> Classes Conducted </th>
                <th rowSpan="1" style={{ verticalAlign: 'middle' }}> Classes Conducted on Time </th>
              </tr>
            </thead>
            <tbody>
              { teacherAttendance.map((attendance, index) => this.renderTeacherAttendanceList(attendance, index))}
            </tbody>
          </table>
        ) : (
          <div style={{
            display: 'flex', justifyContent: 'center', alignItems: 'center', color: '#CDCFD6', height: 60, backgroundColor: '#FFFFFF',
          }}
          >
            No reports available yet!
          </div>
        )}
      </div>
    );
  }

  renderTeacherAttendanceList(data, index) {
    const style = { padding: '14px 5px', textAlign: 'center' };
    return (
      <tr style={{ fontSize: '14px', height: 50 }}>
        <td style={style}>{ index + 1 }</td>
        <td style={{ padding: '14px 5px', textAlign: 'left' }}>
          {data.name ? this.truncate(data.name) : ''}
        </td>
        <td style={style}>{data.classes_scheduled}</td>
        <td style={style}>{data.classes_conducted}</td>
        <td style={style}>{data.classes_conducted_on_time}</td>

        <style jsx>
          {`
            tr.border_bottom td {
              border-bottom:1px solid black;
            }            
          `}
        </style>
      </tr>
    );
  }

  renderContent() {
    const {
      loading
    } = this.props.virtualMeeting;

    if (loading) {
      return (
        <Loader />
      );
    }
    return (
      <>
        <div style={{ display: 'flex' }}>
          {this.filterView()}
        </div>
        {this.selectDateRange()}
        {this.renderTable()}
      </>
    );
  }

  render() {
    let innerHeight = null;
    if (typeof window !== 'undefined') {
      innerHeight = window.innerHeight;
    }
    return (
      <div style={{ background: '#F5F8FA', height: typeof window !== 'undefined' && window.innerHeight }}>

        <div style={{
          fontWeight: 600, fontSize: 24, padding: '15px 60px', background: '#FFFFFF', color: Constants().color.primary, boxShadow: '0px 3px 6px #343B4014',
        }}
        >
          Teacher Attendance
        </div>

        <div style={{
          padding: '25px 65px', backgroundColor: '#F5F8FA', width: '100%',
        }}
        >
          {this.renderContent()}
        </div>
      </div>
    );
  }
}

const mapStateToProps = ({ virtualMeeting }) => ({
  virtualMeeting,
});

export default connect(mapStateToProps, {
  getTeacherAttendance,
})(TeacherAttendance);
