import {
  GET_QUESTIONS, UPDATE_INDEX, UPDATE_COMPLEXITY, GET_TOPICS,
} from '../actions';

const initialState = {
  questions: [],
  index: 0,
  topics: [],
};

export default function (state = initialState, action) {
  switch (action.type) {
    case GET_QUESTIONS: {
      return {
        ...state, questions: action.questions, index: action.index,
      };
    }
    case UPDATE_INDEX: {
      return {
        ...state, index: state.index + action.payload,
      };
    }
    case UPDATE_COMPLEXITY: {
      const { questions } = state;
      questions[action.payload.index].complexity = action.payload.complexity;
      return {
        ...state,
        questions,
      };
    }
    case GET_TOPICS: {
      return {
        topics: action.payload,
      };
    }
    default:
      return state;
  }
}
