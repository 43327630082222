import React from 'react';
import { connect } from 'react-redux';
import Modal from './baseModal';
import { newLeadData } from '../../actions';
import Constants from '../../shared/constants';
import { validateContact, validateEmail } from '../../helpers/util';
import { sendEvent } from '../../helpers/Analytics';

class GetInvite extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      name: '',
      email: '',
      contact: '',
      showGetInviteModal: false,
      dataSaved: false,
      showInvalidNumberWarning: false,
      showInvalidEmailWarning: false,
      showInvalidNameWarning: false,
    };
    this.getInviteChange = this.getInviteChange.bind(this);
    this.getInviteName = this.getInviteName.bind(this);
    this.getInviteEmail = this.getInviteEmail.bind(this);
    this.saveUserData = this.saveUserData.bind(this);
    this.toggleGetInviteModal = this.toggleGetInviteModal.bind(this);
  }

  getInviteChange(e) {
    this.setState({ contact: e.target.value, showInvalidNumberWarning: false });
  }

  getInviteName(e) {
    this.setState({ name: e.target.value, showInvalidNameWarning: false });
  }

  getInviteEmail(e) {
    this.setState({ email: e.target.value, showInvalidEmailWarning: false });
  }

  toggleGetInviteModal() {
    const { showGetInviteModal } = this.state;
    this.setState({
      showGetInviteModal: !showGetInviteModal,
      contact: '',
      dataSaved: false,
    });
  }

  saveUserData() {
    const { name, email, contact } = this.state;
    const { dashboard, ideaboard, mobile } = Constants().products;
    const { product } = this.props;
    if (name.length > 0) {
      if (validateContact(contact)) {
        if (product === dashboard || product === ideaboard) {
          if (typeof email !== 'undefined') {
            if (validateEmail(email)) {
              this.props.newLeadData(contact, product, name, email).then((result) => {
                if (result.success) {
                  this.setState({ showGetInviteModal: true });
                  sendEvent(product === ideaboard ? 'InstituteLandingPage' : 'TestDashboardLandingPage', 'getInvite', 'Click');
                  this.setState({
                    dataSaved: true, contact: '', name: '', email: '',
                  });
                }
              });
            } else {
              this.setState({ showInvalidEmailWarning: true });
            }
          } else {
            this.props.newLeadData(contact, product).then((result) => {
              if (result.success) {
                this.setState({ showGetInviteModal: true });
                sendEvent('InstituteLandingPage', 'getInvite', 'log');
                this.setState({ dataSaved: true, contact: '' });
              }
            });
          }
        } else if (product === mobile) {
          this.props.newLeadData(contact, product, name).then((result) => {
            if (result.success) {
              this.setState({ showGetInviteModal: true });
              sendEvent('MobileLandingPage', 'getInvite', 'log');
              this.setState({ dataSaved: true, contact: '', name: '' });
            }
          });
        }
      } else {
        this.setState({ showInvalidNumberWarning: true });
      }
    } else {
      this.setState({ showInvalidNameWarning: true });
    }
  }

  showGetInvite() {
    const {
      modal, device, text, header, bgcolor, width, color,
    } = this.props;
    if (modal) {
      return (
        <div className="row" style={{ marginTop: '5%' }}>
          <div className="container">
            <p style={{ fontSize: device === 'Desktop' ? '24px' : '14px', textAlign: 'center' }}>{text}</p>
            <div className="row justify-content-center" style={{ paddingBottom: '8%' }}>
              <input
                type="text"
                className="form-control"
                onChange={this.getInviteChange}
                placeholder="Enter phone number"
                style={{
                  width: device === 'Desktop' ? '40%' : '68%', fontSize: '14px', fontStyle: 'italic', color: 'black',
                }}
              />
              <input
                type="submit"
                value={header}
                onClick={this.saveUserData}
                style={{
                  border: 'none', backgroundColor: bgcolor, color: 'white', marginLeft: '2%', fontSize: '14px', fontFamily: 'Montserrat',
                }}
              />
            </div>
          </div>
        </div>
      );
    }
    return (
      <div
        className="btn btn-lg"
        role="presentation"
        style={{
          width, backgroundColor: bgcolor, boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)', borderRadius: '5px', color: color || 'white', fontSize: '18px', fontFamily: 'Montserrat', fontWeight: 'bold',
        }}
        onClick={() => this.toggleGetInviteModal()}
      >
        {header}
      </div>
    );
  }

  showModalView() {
    const { dashboard, ideaboard, mobile } = Constants().products;
    const { product } = this.props;
    const {
      dataSaved, name, showInvalidNameWarning, email, contact, showInvalidEmailWarning, showInvalidNumberWarning,
    } = this.state;
    if (dataSaved) {
      return (
        <div>
          <div className="row justify-content-center" style={{ backgroundColor: '#20CB90' }}>
            <span style={{
              fontSize: '2vw', fontFamily: 'Raleway', color: 'white', marginTop: '5px', marginBottom: '5px',
            }}
            >
              Request Submitted
            </span>
            <span style={{
              fontSize: '2vw', left: '115px', top: '5px', position: 'relative', color: 'white',
            }}
            >
              <i role="presentation" className="fa fa-close" onClick={this.toggleGetInviteModal} />
            </span>
          </div>
          <div className="m-5" style={{ color: '#535353' }}><p>Thank you for your interest. You’ll receive a confirmation message shortly. We’ll keep you informed about any updates.</p></div>
        </div>
      );
    }
    return (
      <div>
        <div className="row">
          <div className="col justify-content-center m-2 mx-auto">
            {
              (product === dashboard || product === mobile || product === ideaboard)
              && (
              <input
                id="getInviteName"
                onChange={this.getInviteName}
                type="text"
                className="form-control mx-auto"
                placeholder="Enter Name"
                value={name}
                style={{ backgroundColor: '#ffffff', margin: '10px', fontSize: '14px' }}
                autoFocus
              />
              )
            }
            {showInvalidNameWarning && <p style={{ color: 'red', marginLeft: '1%' }}>Enter a valid name</p>}
            {
              (product === dashboard || product === ideaboard)
              && (
              <input
                id="getInviteEmail"
                onChange={this.getInviteEmail}
                type="text"
                className="form-control mx-auto"
                placeholder="Enter email"
                value={email}
                style={{ backgroundColor: '#ffffff', margin: '10px', fontSize: '14px' }}
              />
              )
            }
            {showInvalidEmailWarning && <p style={{ color: 'red', marginLeft: '1%' }}>Enter a valid Email</p>}
            <input
              id="getInviteNumber"
              onChange={this.getInviteChange}
              type="text"
              className="form-control mb-20 mx-auto"
              placeholder="Enter phone number"
              value={contact}
              style={{
                backgroundColor: '#ffffff', margin: '10px', fontSize: '14px', marginBottom: '40px',
              }}
            />
            {showInvalidNumberWarning && <p style={{ color: 'red', marginLeft: '1%' }}>Enter a valid Number</p>}
            <div
              role="presentation"
              className="btn mb-5"
              style={{
                backgroundColor: '#20CB90', color: 'white', marginLeft: '5%', width: '90%', height: 40, padding: 6, fontSize: 16,
              }}
              onClick={this.saveUserData}
            >
              <strong>I’m Interested!</strong>

            </div>
          </div>
        </div>
      </div>
    );
  }

  render() {
    const { header } = this.props;
    return (
      <div>
        <Modal
          showModal={this.state.showGetInviteModal}
          handleCloseModal={() => this.toggleGetInviteModal()}
          header={header}
        >
          {this.showModalView()}
        </Modal>
        {this.showGetInvite()}
      </div>
    );
  }
}

export default connect(null, { newLeadData })(GetInvite);
