import React from 'react';
import { QUESTION_VIEW_CONFIG } from '../../constants';
import OptionView from '../optionView';
import { mathjax } from '../../helpers/util';

class GetQuestionView extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      maxWidthOption: 0,
      options: {},
    };
  }

  componentDidMount() {
    mathjax();
  }

  optionsLayout(question) {
    const { mobileView, fontSize, economyMode, highlightAnswer } = this.props;
    const { options} = this.state;
    const options_array = [1,2,3,4];
    const imageWidth = (question.image_widths && !mobileView) ? question.image_widths : {};
    let sumOfAll = 0;
    let sumOfOneTwo = 0;
    let sumOfThreeFour = 0;
    let optionsMargin = 15;
    let optionLayout = 4;
    let maxOptionsTotalwidth = null;
    if (typeof window !== "undefined") {
      maxOptionsTotalwidth = window.innerWidth - (question.supporting_picture.url ? 520 : 360);
    }
    if (economyMode ) {
      options_array.forEach(i => {
        const currentWidth = Math.floor(
          options[i] && options[i].getBoundingClientRect().width
        );
        sumOfAll += currentWidth;
        if (i == 1 || i == 2) {
          sumOfOneTwo += currentWidth;
        } else {
          sumOfThreeFour += currentWidth;
        }
      });
      if (sumOfAll <= maxOptionsTotalwidth) {
        optionLayout = 1;
      } else if (sumOfOneTwo <= maxOptionsTotalwidth && sumOfThreeFour <= maxOptionsTotalwidth) {
        optionLayout = 2;
      } else {
        optionLayout = 4;
      }
      if (optionLayout === 1) {
        return (
          <React.Fragment>
            <tr>
              <td>
                <div className="d-flex justify-content-start">
                  {options_array.map(i => (
                    <div className="mr-4" key={`option_${i}_${question.id}`} style={{ whiteSpace: 'nowrap' }} >
                      <OptionView
                        index={question['id']}
                        removeLeftBracket
                        text={question[`option_${i}`]}
                        image={question[`image_option_${i}`].url}
                        marginRight={optionsMargin}
                        image_width={QUESTION_VIEW_CONFIG.OPTIONS_IMAGE_WIDTH}
                        optionIndex={i}
                        highlightAnswer={highlightAnswer && question.answers.includes(i)}
                        maxWidth={
                          QUESTION_VIEW_CONFIG.IMAGE_MAX_WIDTH_OPTION_LAYOUT_1
                        }
                      />
                    </div>
                  ))}
                </div>
              </td>
            </tr>
          </React.Fragment>
        );
      } else if (optionLayout === 2) {
        return (
          <React.Fragment>
            <tr style={{ pageBreakInside: 'auto' }}>
              <td>
                <table>
                  <tbody>
                    <tr>
                      {options_array.map(i => {
                        if (i <= 2) {
                          return (
                            <td style={{ paddingRight: 20, whiteSpace: 'nowrap' }} key={`option_${i}_${question.id}`} >
                              <OptionView
                                index={question['id']}
                                removeLeftBracket
                                text={question[`option_${i}`]}
                                image={question[`image_option_${i}`].url}
                                marginRight={optionsMargin}
                                image_width={QUESTION_VIEW_CONFIG.OPTIONS_IMAGE_WIDTH}
                                optionIndex={i}
                                highlightAnswer={highlightAnswer && question.answers.includes(i)}
                                maxWidth={
                                  QUESTION_VIEW_CONFIG.IMAGE_MAX_WIDTH_OPTION_LAYOUT_2
                                }
                              />
                            </td>
                          );
                        }
                      })}
                    </tr>
                    <tr>
                      {options_array.map(i => {
                        if (i > 2) {
                          return (
                            <td style={{ paddingRight: 20, whiteSpace: 'nowrap' }} key={`option_${i}_${question.id}`} >
                              <OptionView
                                index={question['id']}
                                removeLeftBracket
                                text={question[`option_${i}`]}
                                image={question[`image_option_${i}`].url}
                                marginRight={optionsMargin}
                                image_width={QUESTION_VIEW_CONFIG.OPTIONS_IMAGE_WIDTH}
                                optionIndex={i}
                                highlightAnswer={highlightAnswer && question.answers.includes(i)}
                                maxWidth={
                                  QUESTION_VIEW_CONFIG.IMAGE_MAX_WIDTH_OPTION_LAYOUT_2
                                }
                              />
                            </td>
                          );
                        }
                      })}
                    </tr>
                  </tbody>
                </table>
              </td>
            </tr>
          </React.Fragment>
        );
      } else if (optionLayout === 4) {
        return options_array.map(i => (
          <tr key={`option_${i}_${question.id}`}>
            <td>
              <OptionView
                index={question['id']}
                removeLeftBracket
                text={question[`option_${i}`]}
                image={question[`image_option_${i}`].url}
                marginRight={optionsMargin}
                image_width={QUESTION_VIEW_CONFIG.OPTIONS_IMAGE_WIDTH}
                optionIndex={i}
                highlightAnswer={highlightAnswer && question.answers.includes(i)}
                maxWidth={QUESTION_VIEW_CONFIG.IMAGE_MAX_WIDTH_OPTION_LAYOUT_4}
              />
            </td>
          </tr>
        ));
      }
    } else {
      return options_array.map(i => (
        <tr key={`option_${i}_${question.id}`}>
          <td>
            <OptionView
              getRef={optionElement => {
                const options = this.state.options;
                options[i] = optionElement;
                this.setState({ options });
              }}
              index={question['id']}
              removeLeftBracket
              text={question[`option_${i}`]}
              image={question[`image_option_${i}`].url}
              marginRight={optionsMargin}
              image_width={QUESTION_VIEW_CONFIG.OPTIONS_IMAGE_WIDTH}
              optionIndex={i}
              highlightAnswer={highlightAnswer && question.answers.includes(i)}
              maxWidth={QUESTION_VIEW_CONFIG.IMAGE_MAX_WIDTH_OPTION_LAYOUT_4}
            />
          </td>
        </tr>
      ));
    }
  }

  showNumericalNotes() {
    const { showOnlyQuestionText, question } = this.props;
    if(showOnlyQuestionText && question.numerical_notes && question.numerical_notes.length > 0) {
      return (
          <div style={{ color: "#9EA0A5" }}>
            (Note : The answer should be {question.numerical_notes})
          </div>
      );
    }
  }


  solutionView(showSolution) {
    const { mobileView, question, fontSize, fontFamily } = this.props;
    const solutionStyle = { height: showSolution ? 'auto' : 0, backgroundColor: '#000000080', fontSize: fontSize ? fontSize : 16, fontFamily: fontFamily ? fontFamily : "sans-serif" };
    return (
      <>
        <div className={(showSolution) ? "row" : "row d-none"} style={solutionStyle}>
          <div className="ml-5" style={{ pageBreakInside: 'auto', whiteSpace: 'pre-line' }}>{question.solution_text}</div>
          {(question.solution_supporting_picture.url) &&
            <div className="d-flex justify-content-center mt-3">
              <div><img src={question.solution_supporting_picture} style={{ display: 'block', width: (question.image_widths.solution_supporting_picture_width && !mobileView) ? question.image_widths.solution_supporting_picture_width : "140", height: 'auto', maxHeight: 300, objectFit: 'contain', maxWidth: 250 }} /></div>
            </div>
          }
        </div>
      </>
    );
  }

  showHintAndSolutionDefault() {
    return (
      <div>
        {this.props.showSolution && <div style={{ marginBottom: 10, fontWeight: 'bold', fontSize: 16, textDecoration: 'underline' }}>Solution:</div>}
        {this.solutionView(this.props.showSolution)}
      </div>
    );
  }

  renderQuestionViewComponents(question) {
    return (
      <>
        <div style={{ display: 'flex' }}>
          <div>
            <div style={{ whiteSpace: 'pre-line', borderTop: 0 }}> 
              {question.question_text} 
              {this.showNumericalNotes()}
            </div>
            <div style={{ display: this.props.showOnlyQuestionText && 'none' }}>
              {this.optionsLayout(question)}
            </div>
          </div>
          <div style={{ textAlign: "center", marginLeft: 15 }}>
          <img src={question.supporting_picture.url} width="140" height="auto"/>
          </div>
        </div>
        {this.showHintAndSolutionDefault()}
      </>
    )
  }

  renderQuestionView() {
    const { question } = this.props;
    if (question.supporting_picture.url) {
      return this.renderQuestionViewComponents(question);
    }
    return (
      <React.Fragment>
        <tr>
          <div style={{ verticalAlign: "top", whiteSpace: 'pre-line' }}> 
            {question.question_text} 
            {this.showNumericalNotes()}
          </div>
        </tr>
        <div style={{ display: this.props.showOnlyQuestionText && 'none' }}>
          {this.optionsLayout(question)}
        </div>
        {this.showHintAndSolutionDefault()}
      </React.Fragment>
    );
  }

  render() {    
    const { fontSize, printPage, question, marginValue, fontFamily } = this.props;
    return (
      <React.Fragment key={`QuestionView${question.id}`}>
        <div style={{ fontSize: fontSize ? fontSize : 16, fontFamily: fontFamily ? fontFamily : "sans-serif" }} className={`font-${fontFamily ? fontFamily.split(" ").join("-").toLowerCase() : "sans-serif"} two-column`}>
          {(!printPage) &&
            <React.Fragment>
              <table className={marginValue ? marginValue : "mt-2 mb-2"}>
                <tbody>
                  {this.renderQuestionView()}
                </tbody>
              </table>
            </React.Fragment>
          }
        </div>
      </React.Fragment>
    );
  }
}

export default GetQuestionView;
