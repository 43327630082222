import React from 'react';
import PropTypes from 'prop-types';

const CustomAxisLabel = (props) => {
  const yLabelOffset = {
    y: props.innerHeight / 2 + props.title.length * 3,
    x: 15,
  };

  const xLabelOffset = {
    x: props.innerWidth / 2,
    y: 1.2 * props.innerHeight,
  };
  const transform = props.xAxis
    ? `translate(${xLabelOffset.x}, ${xLabelOffset.y})`
    : `translate(${yLabelOffset.x}, ${yLabelOffset.y}) rotate(-90)`;
  return (
    <g transform={transform}>
      <text {...props.style}>{props.title}</text>
    </g>
  );
};

CustomAxisLabel.displayName = 'CustomAxisLabel';
CustomAxisLabel.requiresSVG = true;

export default CustomAxisLabel;
