import React from 'react';
import PropTypes from 'prop-types';

import OptionView from '../optionView';
import { QUESTION_VIEW_CONFIG, QUESTION_PAPER_VIEW_TYPE } from '../../constants';

class TwoColumnQuestionView extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      maxWidthOption: 0,
      options: {},
    };
  }

  optionsLayout(question) {
    const { mobileView, fontSize, economyMode } = this.props;
    const { options} = this.state;
    const options_array = [1,2,3,4];
    const imageWidth = (question.image_widths && !mobileView) ? question.image_widths : {};
    const effectiveOptionWidth = (fontSize - QUESTION_VIEW_CONFIG.BASE_FONT_SIZE) * QUESTION_VIEW_CONFIG.LENGHT_FACTOR;
    let sumOfAll = 0;
    let sumOfOneTwo = 0;
    let sumOfThreeFour = 0;
    let optionLayout = 4;

    if (economyMode ) {
      options_array.forEach(i => {
        const currentWidth = Math.floor(
          options[i].getBoundingClientRect().width
        );
        sumOfAll += currentWidth;
        if (i == 1 || i == 2) {
          sumOfOneTwo += currentWidth;
        } else {
          sumOfThreeFour += currentWidth;
        }
      });
      if (
        sumOfAll <=
        (QUESTION_VIEW_CONFIG.MAX_WIDTH_OPTION_LAYOUT_1 -effectiveOptionWidth) * 4) {
        optionLayout = 1;
      } else if (
        sumOfOneTwo <=
          (QUESTION_VIEW_CONFIG.MAX_WIDTH_OPTION_LAYOUT_2 -
            effectiveOptionWidth) *
            2 &&
        sumOfThreeFour <=
          (QUESTION_VIEW_CONFIG.MAX_WIDTH_OPTION_LAYOUT_2 -
            effectiveOptionWidth) *
            2
      ) {
        optionLayout = 2;
      } else {
        optionLayout = 4;
      }
      if (optionLayout === 1) {
        return (
          <React.Fragment>
            <tr>
              <td>
                <div className="d-flex justify-content-start">
                  {options_array.map(i => (
                    <div
                      className="mr-4"
                      key={`option_${i}_${question.id}`}
                      style={{ whiteSpace: 'nowrap' }}
                    >
                      <OptionView
                        index={question['id']}
                        text={question[`option_${i}`]}
                        image={question[`image_option_${i}`]}
                        image_width={
                          imageWidth[`image_option_width_${i}`]
                            ? imageWidth[`image_option_width_${i}`]
                            : 140
                        }
                        optionIndex={i}
                        maxWidth={
                          QUESTION_VIEW_CONFIG.IMAGE_MAX_WIDTH_OPTION_LAYOUT_1
                        }
                      />
                    </div>
                  ))}
                </div>
              </td>
            </tr>
          </React.Fragment>
        );
      } else if (optionLayout === 2) {
        return (
          <React.Fragment>
            <tr style={{ pageBreakInside: 'auto' }}>
              <td>
                <table>
                  <tbody>
                    <tr>
                      {options_array.map(i => {
                        if (i <= 2) {
                          return (
                            <td
                              style={{ paddingRight: 20, whiteSpace: 'nowrap' }}
                              key={`option_${i}_${question.id}`}
                            >
                              <OptionView
                                index={question['id']}
                                text={question[`option_${i}`]}
                                image={question[`image_option_${i}`]}
                                image_width={
                                  imageWidth[`image_option_width_${i}`]
                                    ? imageWidth[`image_option_width_${i}`]
                                    : 140
                                }
                                optionIndex={i}
                                maxWidth={
                                  QUESTION_VIEW_CONFIG.IMAGE_MAX_WIDTH_OPTION_LAYOUT_2
                                }
                              />
                            </td>
                          );
                        }
                      })}
                    </tr>
                    <tr>
                      {options_array.map(i => {
                        if (i > 2) {
                          return (
                            <td
                              style={{ paddingRight: 20, whiteSpace: 'nowrap' }}
                              key={`option_${i}_${question.id}`}
                            >
                              <OptionView
                                index={question['id']}
                                text={question[`option_${i}`]}
                                image={question[`image_option_${i}`]}
                                image_width={
                                  imageWidth[`image_option_width_${i}`]
                                    ? imageWidth[`image_option_width_${i}`]
                                    : 140
                                }
                                optionIndex={i}
                                maxWidth={
                                  QUESTION_VIEW_CONFIG.IMAGE_MAX_WIDTH_OPTION_LAYOUT_2
                                }
                              />
                            </td>
                          );
                        }
                      })}
                    </tr>
                  </tbody>
                </table>
              </td>
            </tr>
          </React.Fragment>
        );
      } else if (optionLayout === 4) {
        return options_array.map(i => (
          <tr key={`option_${i}_${question.id}`}>
            <td>
              <OptionView
                index={question['id']}
                text={question[`option_${i}`]}
                image={question[`image_option_${i}`]}
                image_width={
                  imageWidth[`image_option_width_${i}`]
                    ? imageWidth[`image_option_width_${i}`]
                    : 140
                }
                optionIndex={i}
                maxWidth={QUESTION_VIEW_CONFIG.IMAGE_MAX_WIDTH_OPTION_LAYOUT_4}
              />
            </td>
          </tr>
        ));
      }
    } else {
      return options_array.map(i => (
        <tr key={`option_${i}_${question.id}`}>
          <td>
            <OptionView
              getRef={optionElement => {
                const options = this.state.options;
                options[i] = optionElement;
                this.setState({ options });
              }}
              index={question['id']}
              text={question[`option_${i}`]}
              image={question[`image_option_${i}`]}
              image_width={
                imageWidth[`image_option_width_${i}`]
                  ? imageWidth[`image_option_width_${i}`]
                  : 140
              }
              optionIndex={i}
              maxWidth={QUESTION_VIEW_CONFIG.IMAGE_MAX_WIDTH_OPTION_LAYOUT_4}
            />
          </td>
        </tr>
      ));
    }
  }

  showNumericalNotes() {
    const { showOnlyQuestionText, question } = this.props;
    if(showOnlyQuestionText && question.numerical_notes && question.numerical_notes.length > 0) {
      return (
          <div style={{ color: "#9EA0A5" }}>
            (Note : The answer should be {question.numerical_notes})
          </div>
      );
    }
  }

  renderIndex(index, rowSpan=2) {
    return (
        <td rowSpan={rowSpan} style={{ verticalAlign: "top", paddingRight:10 , borderTop: 0 }} > {index + 1}) </td>
    )
  }

  renderQuestionViewComponents(question, index) {
    const { mobileView } = this.props;
    const maxImageWidth = Math.min(question.image_widths.supporting_picture_width, QUESTION_VIEW_CONFIG.MAX_IMAGE_WIDTH);
    return (
      <React.Fragment>
        <tr>
          {this.renderIndex(index, 6)}
          <td style={{ whiteSpace: 'pre-line', borderTop: 0, textAlign: `${this.props.textJustify === QUESTION_PAPER_VIEW_TYPE.ALIGN_LEFT ? 'left' : 'justify'}` }}> 
            {question.question_text} 
            {this.showNumericalNotes()}
          </td>
        </tr>
        <tr>
          <td style={{ textAlign: "center" }}>
            <img src={question.supporting_picture} width={(question.image_widths.supporting_picture_width && !mobileView) ? maxImageWidth : "140"} height="auto"/>
          </td>
        </tr>
        <div style={{ display: this.props.showOnlyQuestionText && 'none' }}>
          {this.optionsLayout(question)}
        </div>

      </React.Fragment>
    )
  }

  renderQuestionView() {
    const { question, index } = this.props;
    if (question.supporting_picture) {
      return this.renderQuestionViewComponents(question, index);
    }
    return (
      <React.Fragment>
        <tr>
          {this.renderIndex(index, 5)}
          <td style={{ verticalAlign: "top", whiteSpace: 'pre-line', textAlign: `${this.props.textJustify === QUESTION_PAPER_VIEW_TYPE.ALIGN_LEFT ? 'left' : 'justify'}` }}> 
            {question.question_text} 
            {this.showNumericalNotes()}
            </td>
        </tr>
        <div style={{ display: this.props.showOnlyQuestionText && 'none' }}>
          {this.optionsLayout(question)}
        </div>
      </React.Fragment>
    );
  }

  render() {
    const { fontSize, printPage, question, marginValue, fontFamily, subjectNameDetails, sectionInfoData } = this.props;
    return (
      <React.Fragment key={`QuestionView${question.id}`}>
        <div style={{ fontSize: fontSize ? fontSize : 16, fontFamily: fontFamily ? fontFamily : "sans-serif" }} className={`font-${fontFamily ? fontFamily.split(" ").join("-").toLowerCase() : "sans-serif"} two-column`}>
          {(!printPage) &&
            <React.Fragment>
              {subjectNameDetails && subjectNameDetails.subjectName &&
                <div style={{ fontSize: fontSize ? fontSize + 10 : 26, fontWeight: 'bold', textDecoration: 'underline', pageBreakBefore: subjectNameDetails.addPageBreak && 'always' }}>
                  {subjectNameDetails.subjectName}
                </div>
              }
              {sectionInfoData.showSectionInfo &&
                <div style={{ border: '1px solid black', borderRadius: 10, padding: 5, margin: 5, textAlign: 'center' }}>
                  <div style={{ fontSize: 20, fontWeight: 'bold' }}>
                    {sectionInfoData.sectionHeaderTitle}
                  </div>
                  <div>{sectionInfoData.sectionInstruction}</div>
                </div>
              }
              <table className={marginValue ? marginValue : "mt-2 mb-2"}>
                <tbody>
                  {this.renderQuestionView()}
                </tbody>
              </table>
            </React.Fragment>
          }
        </div>
      </React.Fragment>
    );
  }
}

TwoColumnQuestionView.propTypes = {
  sectionInfoData: PropTypes.object,
};

TwoColumnQuestionView.defaultProps = {
  sectionInfoData: {},
};

export default TwoColumnQuestionView;
