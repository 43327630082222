import Api from '../helpers/Api';
import { showToast } from '.';

export const GET_ASSIGNMENT_LIST = 'test/GET_ASSIGNMENT_LIST';
export const CREATE_NEW_ASSIGNMENT = 'test/CREATE_NEW_ASSIGNMENT';
export const UPDATE_TEST_SUMMARY = 'test/UPDATE_TEST_SUMMARY';
export const UPDATE_TEST_OTP = 'test/UPDATE_TEST_OTP';
export const CREATE_NEW_CLASSROOM = 'test/CREATE_NEW_CLASSROOM';
export const UPDATE_TEST_PUBLISH_STATE = 'test/UPDATE_TEST_PUBLISH_STATE';
export const FILTER_CLASSROOM_LIST = 'test/FILTER_CLASSROOM_LIST';
export const TOGGLE_TEST_ARCHIVE_STATE = 'test/TOGGLE_TEST_ARCHIVE_STATE';

export function getAssignmentList(isTest) {
  return (dispatch) => Api({
    method: 'get',
    url: `/membership_tests/get_user_assignment_list?is_test=${isTest}`,
  }).then((response) => {
    if (response.data.success) {
      const payload = {
        assignmentsList: response.assignment_list,
        testsList: response.test_list,
        testTaken: response.test_taken,
        studentLimit: response.student_limit,
        classroomList: response.classroom_list,
        filteredClassroomList: response.classroom_list,
        testGroupList: response.test_groups,
        previousYearPaperList: response.previous_year_paper_list,
        testTypeList: response.test_types,
        hasMentorBoardAccess: response.has_mentor_board_access,
        hasAccess: response.has_permission,
        subjects: response.subjects,
        showSentSms: response.show_send_sms,
        smsBalance: response.sms_balance || 0,
        subjectMapping: response.subject_list,
        assignmentQuestionLimit: response.assignment_question_limit,
        uploadPermission: response.has_upload_result_permission,
        isTrial: response.is_trial,
        alertMessage: response.alert_message,
        remainingQuestionCount: response.remaining_question_count,
        otpValidation: response.online_otp_validation,
        isTestLockedByOtp: response.can_lock_test,
        testOtpType: response.test_otp_type,
      };
      dispatch({
        type: GET_ASSIGNMENT_LIST,
        payload,
      });
    }
    return response;
  });
}

export function generateOtp(id) {
  return (dispatch) => Api({
    method: 'post',
    url: '/membership_tests/generate_dynamic_otp',
    data: { id },
  }).then((response) => {
    if (response.success) {
      dispatch({
        type: UPDATE_TEST_OTP,
        payload: { [response.id]: response.otp },
      });
    } else {
      alert('Sorry you dont have permission to access this data');
    }
    return response;
  });
}

export function updateTestAttribute(id, status) {
  return (dispatch) => new Promise((resolve) => {
    dispatch({ type: UPDATE_TEST_SUMMARY, id, status });
    resolve();
  });
}

export function createNewAssignment(payload) {
  return (dispatch) => Api({
    method: 'post',
    url: '/membership_tests/create_new_assignment',
    data: payload,
  }).then((response) => {
    dispatch({
      type: CREATE_NEW_ASSIGNMENT,
      created: response.success,
      message: response.message,
    });
    return response;
  });
}

export function updateTestPublishState(testId) {
  return (dispatch) => {
    dispatch({ type: UPDATE_TEST_PUBLISH_STATE, payload: { testId, currentState: true } });
    return Api({
      method: 'post',
      url: `/tests/${testId}/update_test_publish_state`,
      data: {},
    }).then((response) => {
      if (!response.success) {
        dispatch({
          type: UPDATE_TEST_PUBLISH_STATE,
          payload: {
            testId,
            testTaken: response.test_taken,
            testTakenCount: response.test_taken_count,
            currentState: false,
          },
        });
      }
      return response;
    });
  };
}

export const classroomSearchOrCreation = (name) => async (dispatch) => {
  dispatch({ type: FILTER_CLASSROOM_LIST, name });
};

export function createNewClassroom(name) {
  return (dispatch) => Api({
    method: 'post',
    url: '/classrooms/create_new_classroom',
    data: { name },
  }).then((response) => {
    if (response.success) {
      const newClassroom = { id: null, label: name, value: response.created_classroom_id };
      const payload = {
        newClassroom,
        createdClassroomId: response.created_classroom_id,
      };
      dispatch({
        type: CREATE_NEW_CLASSROOM,
        payload,
      });
      response.newClassroom = newClassroom;
    }
    return response;
  });
}

export function archiveTest(testId, isTest) {
  return (dispatch) => Api({
    method: 'post',
    url: '/tests/archive',
    disableRedirect: true,
    data: { test_id: testId },
  }).then((response) => {
    if (response.success) {
      const payload = {
        testId,
        isTest,
      };
      dispatch({
        type: TOGGLE_TEST_ARCHIVE_STATE,
        payload,
      });
    } else if (response.status === 203) {
      dispatch(showToast('You dont have permission'));
    } else {
      dispatch(showToast('Something went wrong'));
    }
    return response;
  });
}

export function copyQuestionsFromTest(sourceTestId, destinationTestId) {
  return (dispatch) => Api({
    method: 'post',
    url: '/tests/copy_questions_from_test',
    data: {
      source_test_id: sourceTestId,
      destination_test_id: destinationTestId,
    },
  }).then((response) => {
    if (response.success) {
      dispatch(showToast(response.success_message));
      return response;
    }
    dispatch(showToast(response.error_message));
    return response;
  });
}
