import { GET_STUDENT_DETAIL, ERROR_STUDENT_DETAIL, VALIDATE_STUDENT_DETAIL } from '../actions';

const initialState = {
  basicStudentData: Object.create(null),
  recentTests: [],
  error: '',
  testData: [],
  testStats: Object.create(null),
  subjects: [],
  performance: {
    attendance: '',
    bestScore: '',
    bestRank: '',
    strongSubject: '',
    weakSubject: '',
    subjectWiseScore: [],
  },
  isValidate: false,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case GET_STUDENT_DETAIL:
      return {
        ...state,
        ...action.payload,
      };
    case VALIDATE_STUDENT_DETAIL:
      return {
        ...state,
        ...action.payload,
      };
    case ERROR_STUDENT_DETAIL:
      return {
        ...state,
        error: 'Wrong UserID provided!!!!',
      };
    default:
      return state;
  }
}
