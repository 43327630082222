import React, { Component } from 'react';
import { connect } from 'react-redux';
import Modal from './baseModal';
import closeMark from '../../static/images/close-mark.svg';
import greenTick from '../../static/images/green-tick.png';
import redCross from '../../static/images/red-cross.png';

class OnBoardingInstructionModal extends Component {
  renderPreferences(index, text, status) {
    return (
      <div style={{ marginTop: 5 }}>
        {index}
        .
        {text}
        :
        <img src={status ? greenTick : redCross} alt="preference" style={{ height: 25 }} />
      </div>
    );
  }

  renderContent() {
    const { preferences } = this.props;
    return (
      <div style={{ padding: 20 }}>
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <div className="nunito-sans-header" style={{ fontWeight: 'bold', fontSize: 18 }}>Instructions</div>
          <div role="presentation" onClick={() => this.props.handleCloseModal()} style={{ cursor: 'pointer' }}>
            <img src={closeMark} alt="x-mark" />
          </div>
        </div>
        <div style={{ margin: '20px 0px' }}>
          {this.renderPreferences(1, 'Mobile account creation', preferences.support_mobile_user_creation)}
          {this.renderPreferences(2, 'Send Credentials to parents', preferences.send_otp_to_parents)}
          {this.renderPreferences(3, 'Create Random Phone Numbers', preferences.random_phone_numbers)}
          <div style={{ marginTop: 5 }}>(contact sales to change any preference)</div>
        </div>
        <div role="presentation" className={preferences.support_mobile_user_creation ? 'primary_button' : 'secondary_button'} style={{ marginTop: 5, cursor: preferences.support_mobile_user_creation ? 'pointer' : 'not-allowed' }} onClick={() => preferences.support_mobile_user_creation && this.props.insertDetails()}>Insert</div>
      </div>
    );
  }

  render() {
    return (
      <div>
        <Modal showModal={this.props.showModal} handleCloseModal={() => this.props.handleCloseModal()} height="280px" width="400px">
          <div>
            {this.renderContent()}
          </div>
        </Modal>
      </div>
    );
  }
}
export default connect()(OnBoardingInstructionModal);
