import Api from '../helpers/Api';

export const GET_INSTITUTE_LIVE_CLASSROOM_LIST = '@liveClassroom/GET_INSTITUTE_LIVE_CLASSROOM_LIST';

export function getLiveClassroomList() {
  return (dispatch) => Api({
    method: 'get',
    url: '/live_classrooms/get_institute_video_list',
  }).then((response) => {
    if (response.success) {
      const payload = {
        liveClassroomList: response.classroom_video_list,
        testTypes: response.test_types,
        subjects: response.subjects,
        videoStatuses: response.video_statuses,
      };
      dispatch({
        type: GET_INSTITUTE_LIVE_CLASSROOM_LIST,
        payload,
      });
    }
    return response;
  });
}

export function createOrUpdateLiveClassroom(payload) {
  return () => Api({
    method: 'post',
    url: '/live_classrooms/create_or_update_live_classroom',
    data: payload,
  }).then((response) => {
    return response;
  });
}
