import {
  TEST_OVERVIEW, UPDATE_QUESTION_LIST, UPDATE_FILTERS_TEST_OVERVIEW,
  RESET_FILTERS_TEST_OVERVIEW, UPDATE_DATA_MANAGEMENT_MODAL, UPDATE_DATA_MANAGEMENT,
  STUDENT_RESULT_UPLOAD_STATUS_DELETE, UPDATE_TEST_DETAIL,
} from '../actions';

const initialState = {
  name: '',
  attendance: [],
  rankList: [],
  graphData: {},
  weakness: [],
  strengths: [],
  majorErrorQuestions: [],
  performanceTrend: [],
  absenteeList: [],
  absenteeRate: [],
  scoreDistribution: [],
  campuses: {},
  testType: {},
  classrooms: {},
  subjects: {},
  tracks: {},
  campusSelected: '',
  testTypeSelected: { label: 'NEET' },
  classroomSelected: '',
  subjectSelected: '',
  trackSelected: '',
  renderMoreQuestions: false,
  userType: '',
  subjectTrack1Comparison: [],
  subjectTrack2Comparison: [],
  track1PerformanceComparison: [],
  track2PerformanceComparison: [],
  currentTestPerformance: [],
  previousTestPerformance: [],
  testMinScore: 0,
  trackSetter: {},
  subjectAvg: {},
  maxSubjectScore: 0,
  testStudentDistribution: {},
  graphRangeValue: [],
  subjectsMedian: {},
  previousTests: [],
  previousTestName: '',
  PageError: false,
  isOnline: false,
  testResponses: [],
  errors: [],
  status: -1,
  showModal: false,
  medianData: {},
};

export default function (state = initialState, action) {
  switch (action.type) {
    case TEST_OVERVIEW: {
      return { ...state, ...action.payload };
    }
    case UPDATE_QUESTION_LIST: {
      return {
        ...state,
        majorErrorQuestions: [...state.majorErrorQuestions, ...action.payload.majorErrorQuestions],
        renderMoreQuestions: action.payload.renderMoreQuestions,
      };
    }
    case UPDATE_FILTERS_TEST_OVERVIEW: {
      if (action.payload.type === 'campusSelected' || action.payload.type === 'subjectSelected') {
        const type = (action.payload.type === 'campusSelected') ? 'classroomSelected' : 'trackSelected';
        return {
          ...state,
          [action.payload.type]: action.payload.value,
          [type]: '',
        };
      }
      return {
        ...state,
        [action.payload.type]: action.payload.value,
      };
    }
    case RESET_FILTERS_TEST_OVERVIEW: {
      return initialState;
    }
    case UPDATE_DATA_MANAGEMENT_MODAL: {
      return { ...state, showModal: action.payload };
    }
    case UPDATE_DATA_MANAGEMENT: {
      return { ...state, status: action.payload.status };
    }
    case UPDATE_TEST_DETAIL: {
      return { ...state, status: action.status };
    }
    case STUDENT_RESULT_UPLOAD_STATUS_DELETE: {
      let { errors, status } = action;
      if (!errors) {
        errors = [];
      }
      if (!status) {
        status = '';
      }
      return {
        ...state,
        errors,
        status,
      };
    }
    default:
      return state;
  }
}
