import React from "react";
import Constants from "../../../shared/constants";
import BdeInstitutesList from "./institutesList";
import BdeInstituteInfo from "./instituteInfo";
import BdeInstituteDeals from "./instituteDeals";
import BdeInstitutePayments from "./institutePayments";
import PropTypes from "prop-types";
import Loader from "../../loading";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { getInstitute, getInstitutesList } from "../../../actions";

const TABS = {
  INFO: BdeInstituteInfo,
  DEALS: BdeInstituteDeals,
  PAYMENTS: BdeInstitutePayments,
};

class BdeInstitutes extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedTab: "INFO",
    };
  }

  componentDidMount() {
    this.props.instituteId
      ? this.props.getInstitute(this.props.instituteId)
      : this.props.getInstitutesList();
  }

  renderTabs() {
    const { selectedTab } = this.state;
    return (
      <div style={{ margin: "10px 0px", display: "flex" }}>
        {Object.keys(TABS).map((tab) => (
          <div
            key={tab}
            style={{
              padding: "5px 10px",
              border: `1px solid ${Constants().color.primary}`,
              color: selectedTab === tab ? "white" : Constants().color.primary,
              backgroundColor:
                selectedTab === tab ? Constants().color.primary : "white",
              cursor: "pointer",
              minWidth: 150,
              textAlign: "center",
            }}
            onClick={() => this.setState({ selectedTab: tab })}
          >
            {tab}
          </div>
        ))}
      </div>
    );
  }

  renderInstituteContactData() {
    const { institute } = this.props;
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          flexDirection: "row",
          marginBottom: 15,
        }}
      >
        <div style={{ color: Constants().color.primary }}>
          <div style={{ fontSize: 28 }}>{institute.name}</div>
          <div style={{ fontSize: 20 }}>{institute.campuses?.join(", ")}</div>
        </div>
        <div style={{ color: "gray", fontSize: 16, textAlign: "right" }}>
          <div>{institute.contact_name || "-"}</div>
          <div>{institute.phone || "-"}</div>
          <div>{institute.admin_emails?.join(", ") || "-"}</div>
        </div>
      </div>
    );
  }

  render() {
    const { instituteId } = this.props;
    const { selectedTab } = this.state;

    const Tab = TABS[selectedTab];

    if (this.props.isLoading) {
      return <Loader />;
    }

    return (
      <div style={{ marginLeft: "20%" }}>
        {!instituteId ? (
          <BdeInstitutesList />
        ) : (
          <div style={{ padding: 20 }}>
            {this.renderTabs()}
            {this.renderInstituteContactData()}
            <Tab instituteId={instituteId} />
          </div>
        )}
      </div>
    );
  }
}

BdeInstitutes.propTypes = {
  isLoading: PropTypes.bool,
  institute: PropTypes.object,
};

BdeInstitutes.defaultProps = {
  institute: {},
};

const mapStateToProps = ({ bdeTools }) => ({
  isLoading: bdeTools.isLoading,
  institute: bdeTools.institute,
});

export default withRouter(
  connect(mapStateToProps, {
    getInstitute,
    getInstitutesList,
  })(BdeInstitutes)
);
