import React from 'react';
import { connect } from 'react-redux';
import Select from 'react-select';
import Layout from '../layout';
import Constants from '../../shared/constants';
import SnackBar from '../snackBar';
import {
  getChapterListFromSubject, getQuestionFromChapter,
  updateQuestionImageSize, getQuestionFromTest, showToast,
} from '../../actions';
import { mathjax } from '../../helpers/util';
import { IMAGE_SIZE, STATE_TYPE, MOBILE_SCREEN_SIZE } from '../../constants';

class ImageResize extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showPageLoading: false,
      subject: { value: 1, label: 'Maths' },
      test: '',
      showTest: false,
      chapter: '',
      showOffline: false,
      showRoadmap: false,
      supportingPicture: '',
      hintSupportingPicture: '',
      solutionSupportingPicture: '',
      option1: '',
      option2: '',
      option3: '',
      option4: '',
      nextPage: 1,
      maxQuestion: 0,
      showUpdate: '',
    };
    this.searchTestId = this.searchTestId.bind(this);
  }

  componentDidMount() {
    this.props.getChapterListFromSubject(this.state.subject.value).then((response) => {
      this.changeQuestionState(this.props);
    });
  }

  changeQuestionState(response) {
    const imageSize = response.question.image_widths;
    this.setState({
      supportingPicture: imageSize ? parseInt(imageSize.supporting_picture_size, 10) : '',
      hintSupportingPicture: imageSize ? parseInt(imageSize.hint_supporting_picture_size, 10) : '',
      solutionSupportingPicture: imageSize ? parseInt(imageSize.solution_supporting_picture_size, 10) : '',
      option1: imageSize ? parseInt(imageSize.image_option_1_size, 10) : '',
      option2: imageSize ? parseInt(imageSize.image_option_2_size, 10) : '',
      option3: imageSize ? parseInt(imageSize.image_option_3_size, 10) : '',
      option4: imageSize ? parseInt(imageSize.image_option_4_size, 10) : '',
    });
    mathjax();
  }

  changeStateValue(type, value) {
    const {
      chapter, showOffline, nextPage, test, showTest, showRoadmap,
    } = this.state;
    if (type !== 'subject' && type !== 'chapter' && !chapter && type !== 'showTest' && type !== 'test' && !test) {
      this.props.showToast('Select chapter');
      return;
    }

    this.setState({ [type]: value }, () => {
      if (type === 'subject') {
        this.props.getChapterListFromSubject(this.state.subject.value);
        this.setState({ chapter: '' });
      } else if (type === 'chapter' || type === 'showOffline' || type === 'showRoadmap') {
        this.props.getQuestionFromChapter(this.state.chapter.value, this.state.showOffline, this.state.showRoadmap, nextPage).then((response) => {
          this.changeQuestionState(response);
          this.setState({
            nextPage: response.page ? parseInt(response.page) : 1,
            maxQuestion: response.question_count ? parseInt(response.question_count) : 0,
          });
        });
      } else if (type === 'nextPage') {
        if (showTest) {
          this.props.getQuestionFromTest(test, value).then((response) => {
            this.changeQuestionState(response);
            this.setState({
              showUpdate: '',
            });
          });
        } else {
          this.props.getQuestionFromChapter(chapter.value, showOffline, showRoadmap, value).then((response) => {
            this.changeQuestionState(response);
            this.setState({
              showUpdate: '',
            });
          });
        }
      } else if (type === 'showTest') {
        this.setState({
          nextPage: 1,
        });
      } else if (type === 'test') {
        this.props.getQuestionFromTest(this.state.test, nextPage).then((response) => {
          this.changeQuestionState(response);
          this.setState({
            nextPage: response.page ? parseInt(response.page) : 1,
            maxQuestion: response.question_count ? parseInt(response.question_count) : 0,
          });
        });
      } else {
        this.props.updateQuestionImageSize(this.props.question.id, STATE_TYPE[type], value).then((response) => {
          this.setState({
            showUpdate: type,
            [type]: value,
          });
        });
      }
      mathjax();
    });
  }

  renderImageUrl(image) {
    return image.url;
  }

  calculateImageSize(imageSize) {
    const size = imageSize ? parseInt(imageSize) : 0;
    if (size === 1) {
      return MOBILE_SCREEN_SIZE.WIDTH / 3;
    } if (size === 2) {
      return MOBILE_SCREEN_SIZE.WIDTH / 2;
    } if (size === 3) {
      return MOBILE_SCREEN_SIZE.WIDTH;
    }
    return MOBILE_SCREEN_SIZE.WIDTH / 2;
  }

  renderDropDown(label, type, options, selectedValue, width) {
    const customStyles = {
      control: (base, state) => ({
        ...base,
        height: 40,
        cursor: 'pointer',
        backgroundColor: '#ffffff',
        border: `1px solid var(--${Constants().color.inactive}) !important`,
        width,
      }),
      option: (base, state) => ({
        ...base,
        border: '1px solid #F8F8F8',
        color: '#343B40',
        backgroundColor: state.isFocused ? '#DEEBFF' : '#FFFFFF',
      }),
    };
    return (
      <div className="mb-4">
        {label && (
        <div className="mr-2" style={{ fontSize: 16 }}>
          {label}
          :
        </div>
        )}
        <Select
          styles={customStyles}
          value={selectedValue}
          onChange={(event) => this.changeStateValue(type, event)}
          options={options}
          isSearchable={!!this.state.showTest}
        />
      </div>
    );
  }

  renderCheckbox(label, value, type) {
    return (
      <div className="d-flex flex-row mb-4">
        <input className="cursor" type="checkbox" checked={value} onChange={() => this.changeStateValue(type, !value)} style={{ fontSize: 'x-large', marginTop: 9 }} />
        <label style={{ fontSize: 16, fontWeight: '400', margin: 5 }}>{label}</label>
      </div>
    );
  }

  searchTestId(e) {
    this.setState({ test: e.target.value });
  }

  renderSetting() {
    const subjectList = [
      { value: 1, label: 'Math' },
      { value: 2, label: 'Physics' },
      { value: 3, label: 'Chemistry' },
      { value: 4, label: 'Zoology' },
      { value: 5, label: 'Botony' },
    ];
    if (this.state.showTest) {
      return (
        <div className="d-flex flex-row">
          <input
            id="Search"
            onChange={this.searchTestId}
            type="text"
            className="form-control"
            placeholder="Search test by Id"
            value={this.state.test}
            style={{ fontSize: 16, height: 50, width: '200px' }}
          />
          <button type="button" className="btn-sm btn-info ml-5 mt-2" onClick={() => this.changeStateValue('test', this.state.test)}>
            <span style={{ fontSize: 18 }}>Search Test</span>
          </button>
        </div>
      );
    }
    return (
      <div className="row" style={{ alignItems: 'flex-end', justifyContent: 'space-between' }}>
        <div className="col-md-4" style={{ paddingRight: 0 }}>
          {this.renderDropDown('Subject', 'subject', subjectList, this.state.subject, '100%')}
        </div>
        <div className="col-md-4" style={{ paddingLeft: 10 }}>{this.renderDropDown('Chapter', 'chapter', this.props.chapterList, this.state.chapter, '100%')}</div>
        {this.renderCheckbox('Show Offline', this.state.showOffline, 'showOffline')}
        {this.renderCheckbox('Show Rodmap', this.state.showRoadmap, 'showRoadmap')}
      </div>
    );
  }

  renderImageSize(type, value, name) {
    return (
      <div style={{ position: 'relative', left: 500, top: -100 }}>
        <div className="mb-4">
          <div
            className="mr-5"
            style={{
              color: 'black', border: 0, fontSize: 14, marginRight: 10, whiteSpace: 'nowrap',
            }}
          >
            {name}
            :
          </div>
          <div className="btn-group" style={{ alignItems: 'center', zIndex: 0 }}>
            <div className="mr-3" />
            <button style={{ marginTop: 10, backgroundColor: value === IMAGE_SIZE.SMALL && Constants().color.primary }} onClick={() => this.changeStateValue(type, IMAGE_SIZE.SMALL)}>S</button>
            <div className="mr-3" />
            <button style={{ marginTop: 10, backgroundColor: value === IMAGE_SIZE.MEDIUM && Constants().color.primary }} onClick={() => this.changeStateValue(type, IMAGE_SIZE.MEDIUM)}>M</button>
            <div className="mr-3" />
            <button style={{ marginTop: 10, backgroundColor: value === IMAGE_SIZE.LARGE && Constants().color.primary }} onClick={() => this.changeStateValue(type, IMAGE_SIZE.LARGE)}>L</button>
          </div>
          <div
            className={this.state.showUpdate === type ? 'row' : 'row d-none'}
            style={{
              color: 'red', border: 0, fontSize: 14, marginRight: 10, whiteSpace: 'nowrap',
            }}
          >
            Updated
            {name}
            size
          </div>
        </div>
      </div>
    );
  }

  renderOptions(text, image, image_width, optionIndex, highlightAnswer) {
    if (image && image.url) {
      return (
        <div>
          <div className="row image-question-view" style={{ margin: 2, marginBottom: -70 }}>
            <span style={{ fontWeight: this.props.showAnswer && highlightAnswer ? 'bold' : 'normal' }}>
              {optionIndex}
              &#41;
            </span>
            <div><img src={this.renderImageUrl(image)} width={image_width} height="auto" /></div>
          </div>
          {this.renderImageSize(`option${optionIndex}`, this.state[`option${optionIndex}`], `Option ${optionIndex}`, false)}
        </div>
      );
    }
    return (
      <div className="image-question-view">
        <span style={{ fontWeight: this.props.showAnswer && highlightAnswer ? 'bold' : 'normal' }}>
          {optionIndex}
          &#41;
          {text}
        </span>
      </div>
    );
  }

  optionsLayout(question) {
    const options_array = [1, 2, 3, 4];
    return (
      <div style={{ marginTop: 5 }}>
        { options_array.map((i) => <div key={`option_${i}_${question.id}`}>{this.renderOptions(question[`option_${i}`], question[`image_option_${i}`], this.calculateImageSize(this.state[`option${i}`]), i, question.answers.includes(i))}</div>)}
      </div>
    );
  }

  commonCardView(title, imageName, imageSize, name, text, picture) {
    return (
      <div
        className="row"
        style={{
          width: '100%', backgroundColor: '#000000080', fontSize: 16, fontFamily: 'sans-serif',
        }}
      >
        { (text || (picture && picture.url))
          && (
          <div>
            <div className="ml-4 mt-2" style={{ pageBreakInside: 'auto' }}>{title}</div>
            { text
              && <div className="m-4 image-question-view" style={{ pageBreakInside: 'auto' }}>{text}</div>}
            {(picture && picture.url)
              && (
              <div className="mt-3" style={{ marginBottom: -70, width: 400, marginLeft: 5 }}>
                <div style={{ display: 'block' }}>
                  <img
                    src={this.renderImageUrl(picture)}
                    style={{
                      margin: 'auto', display: 'block', width: this.calculateImageSize(imageSize), height: 'auto',
                    }}
                  />
                </div>
                {this.renderImageSize(imageName, imageSize, name)}
              </div>
              )}
          </div>
          )}
      </div>
    );
  }

  render() {
    const { question } = this.props;
    const {
      maxQuestion, nextPage, supportingPicture, hintSupportingPicture, solutionSupportingPicture, showTest,
    } = this.state;
    return (
      <Layout globalHistory={this.props.history}>
        <div className="container pt-5 mt-5" style={{ marginBottom: '5%', position: 'relative' }}>
          <SnackBar />
          <div className="mb-4">
            <div className="btn-group" style={{ alignItems: 'center', zIndex: 2, display: 'flex' }}>
              <button style={{ marginTop: 10, backgroundColor: !showTest && Constants().color.primary, fontSize: 16 }} onClick={() => this.changeStateValue('showTest', false)}>Subject</button>
              <div className="mr-3" />
              <button style={{ marginTop: 10, backgroundColor: showTest && Constants().color.primary, fontSize: 16 }} onClick={() => this.changeStateValue('showTest', true)}>Test</button>
            </div>
          </div>
          {this.renderSetting()}
          <div>
            <div className="row">
              {
                Object.keys(this.props.question).length > 0 && (this.state.chapter || this.state.test)
                && (
                <div
                  className="mt-5 offset-md-1"
                  style={{
                    fontSize: 16, fontFamily: 'sans-serif', width: 400, border: '1px solid #CECECE', padding: 10,
                  }}
                >
                  <div style={{ margin: 5, fontSize: 16 }}>
                    Question Id:
                    {this.props.question.id}
                  </div>
                  <table className="mt-2 mb-2">
                    <tbody>
                      <tr style={{ marginTop: 30 }}>
                        <td style={{ marginLeft: 0, marginRight: 0 }}>{this.commonCardView('', 'supportingPicture', supportingPicture, 'Supporting Picture', question.question_text, question.supporting_picture)}</td>
                      </tr>
                      <tr style={{ marginTop: 5 }}>
                        <td style={{ marginLeft: 0, marginRight: 0 }}>{this.optionsLayout(question)}</td>
                      </tr>
                      <tr style={{ marginTop: 30 }}>
                        <td style={{ marginLeft: 0, marginRight: 0 }}>{this.commonCardView('Hint', 'hintSupportingPicture', hintSupportingPicture, 'Hint Support Picture', question.hint, question.hint_supporting_picture)}</td>
                      </tr>
                      <tr style={{ marginTop: 30 }}>
                        <td style={{ marginLeft: 0, marginRight: 0 }}>{this.commonCardView('Solution', 'solutionSupportingPicture', solutionSupportingPicture, 'Solution Supporting Picture', question.solution_text, question.solution_supporting_picture)}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                )
              }
            </div>
            <div className="row" style={{ justifyContent: 'space-between' }}>
              { nextPage !== 1
                && <button className="previous" style={{ marginTop: 20, backgroundColor: '#E8E8E8', fontSize: 16 }} onClick={() => this.changeStateValue('nextPage', this.state.nextPage - 1)}>&laquo; Back</button>}
              { maxQuestion > 1 && nextPage < maxQuestion
                && <button className="next" style={{ marginTop: 20, backgroundColor: '#E8E8E8', fontSize: 16 }} onClick={() => this.changeStateValue('nextPage', this.state.nextPage + 1)}>Next &raquo;</button>}
            </div>
          </div>
        </div>
      </Layout>
    );
  }
}

const mapStateToProps = ({ imageQuestionView }) => ({
  chapterList: imageQuestionView.chapterList,
  question: imageQuestionView.question ? imageQuestionView.question : {},
});

export default connect(mapStateToProps, {
  getChapterListFromSubject,
  getQuestionFromChapter,
  updateQuestionImageSize,
  getQuestionFromTest,
  showToast,
})(ImageResize);
