import React from 'react';
import { connect } from 'react-redux';
import MobileHeader from '../mobileHeader';
import Modal from './baseModal';
import Constants from '../../shared/constants';
import closeMark from '../../static/images/close-mark.svg';
import filter from '../../static/images/filter.svg';

const SHOW_ARCHIVED = 'Show Archived';
const HIDE_ARCHIVED = 'Hide Archived';
class TestListFilterModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showTestListFilterModal: false,
      status: props.appliedStatus ? props.appliedStatus : 'In Progress',
      course: props.appliedTestType ? props.appliedTestType : null,
      testGroups: props.appliedTestGroups ? props.appliedTestGroups : [],
      showArchived: props.showArchived,
    };
    this.handleCloseModal = this.handleCloseModal.bind(this);
    this.renderStatusCourse = this.renderStatusCourse.bind(this);
    this.renderTestGroups = this.renderTestGroups.bind(this);
  }

  handleCloseModal(resetFilters = false) {
    if (this.props.isMobile) {
      this.props.closeFilter(resetFilters);
      return;
    }
    this.setState({
      showTestListFilterModal: false,
      testGroups: [],
    });
  }

  applyFilters() {
    const {
      course, testGroups, showArchived,
    } = this.state;
    this.props.applyTestFilters(course, testGroups, showArchived);
    this.handleCloseModal();
  }

  showModalView() {
    return (
      <div style={{ padding: 20 }}>
        <div style={{ display: 'flex', justifyContent: 'space-between', height: 'fit-content' }}>
          <div className="nunito-sans-header" style={{ fontWeight: 'bold', fontSize: 18 }}>Filters</div>
          <div
            role="presentation"
            onClick={this.handleCloseModal}
            style={{ cursor: 'pointer' }}
          >
            <img src={closeMark} alt="x-mark" />
          </div>
        </div>
        <div style={{ marginTop: 20, marginBottom: 20 }}>
          {this.renderFilterElements()}
        </div>
        <div
          role="presentation"
          onClick={() => this.applyFilters()}
          style={{
            width: 320, height: 40, backgroundColor: Constants().color.primary, borderRadius: 4, border: `1px solid ${Constants().color.primary}`, boxShadow: '0px 3px 6px #1d4c7f80', display: 'flex', justifyContent: 'center', alignItems: 'center', color: '#FFFFFF', cursor: 'pointer',
          }}
        >
          Apply
        </div>
      </div>
    );
  }

  renderFilterElements() {
    const { status, course } = this.state;
    const { isTestPage, statuses, isMobile } = this.props;
    const courseLength = Object.keys(this.props.statuses[status]).length;
    return (
      <>
        {(courseLength > 0) && (
          <div style={{ margin: '20px 0px' }}>
            <div className="nunito-sans-header" style={{ fontWeight: 'bold', fontSize: 16 }}>Course</div>
            <div style={{
              display: 'flex', flexFlow: 'wrap', marginTop: 10, height: 60, overflow: 'scroll',
            }}
            >
              {Object.keys(statuses[status]).map((courseId) => {
                if (statuses[status][courseId].testGroupIds.length) {
                  return this.renderStatusCourse(parseInt(courseId, 10), 'course', courseLength);
                }
              })}
            </div>
          </div>
        )}
        {isTestPage && (
          <>
            <div className="nunito-sans-header" style={{ fontWeight: 'bold', fontSize: 16 }}>Test Groups</div>
            <div style={{ height: !isMobile && 176, overflow: !isMobile && 'scroll', marginTop: 10 }}>
              {this.props.testGroups.map((testGroupItem, testGroup) => {
                if (!course || (course
                  && this.props.statuses[status][course].testGroupIds.includes(testGroup))) {
                  return this.renderTestGroups(testGroupItem.testGroupName, testGroup);
                }
              })}
            </div>
          </>
        )}
        <div className="nunito-sans-header" style={{ fontWeight: 'bold', fontSize: 16 }}>Archived</div>
        <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: 10 }}>
          {this.renderArchivedFilterItem(SHOW_ARCHIVED)}
          {this.renderArchivedFilterItem(HIDE_ARCHIVED)}
        </div>
      </>
    );
  }

  renderArchivedFilterItem(text) {
    const { showArchived } = this.state;
    const { isMobile } = this.props;
    const isSelected = (showArchived && text === SHOW_ARCHIVED)
    || (!showArchived && text === HIDE_ARCHIVED);
    const width = isMobile ? ((window.innerWidth - 50) / 2) : 150;
    return (
      <div
        role="presentation"
        style={{
          width, height: 40, display: 'flex', justifyContent: 'center', alignItems: 'center', borderRadius: 4, color: (isSelected) ? Constants().color.primary : 'black', border: (isSelected) ? `1px solid ${Constants().color.primary}` : '1px solid #CBCBCB', cursor: 'pointer',
        }}
        onClick={() => this.setState({ showArchived: isSelected ? showArchived : !showArchived })}
      >
        {text}
      </div>
    );
  }

  renderTestGroups(testGroupName, testGroup) {
    const { testGroups } = this.state;
    const isSelected = testGroups.includes(testGroup);
    return (
      <div
        role="presentation"
        key={testGroup}
        style={{ display: 'flex', alignItems: 'center', padding: 10 }}
        onClick={() => this.setState({
          testGroups: isSelected ? testGroups.filter((group) => group !== testGroup)
            : [...testGroups, testGroup],
        })}
      >
        <input
          type="checkbox"
          checked={isSelected}
          style={{
            width: 20, height: 20, marginRight: 10, marginBottom: 0, cursor: 'pointer',
          }}
        />
        <div>{testGroupName}</div>
      </div>
    );
  }

  renderStatusCourse(value, type, length) {
    const { isMobile } = this.props;
    const { course, status } = this.state;
    const isStatus = type === 'status';

    const isSelected = isStatus ? status === value : course === value;
    let width = isStatus ? (isMobile ? ((window.innerWidth - 50) / 2) : 150)
      : length > 2 ? 100 : 150;
    if (type === 'course') {
      width = 'fit-content';
    }
    return (
      <div
        role="presentation"
        style={{
          width, padding: 10, height: 40, display: 'flex', justifyContent: 'center', alignItems: 'center', borderRadius: 4, color: (isSelected) ? Constants().color.primary : 'black', border: (isSelected) ? `1px solid ${Constants().color.primary}` : '1px solid #CBCBCB', cursor: 'pointer', marginRight: !isStatus ? 5 : '', margin: 5,
        }}
        onClick={() => this.setState({
          status: (isStatus) ? value : status,
          course: (!isStatus) ? (isSelected) ? null : value : null,
          testGroups: [],
          selectedAllTestGroups: false,
        })}
      >
        {isStatus ? value : this.props.statuses[status][value].testTypeName }
      </div>
    );
  }

  render() {
    const { isMobile } = this.props;
    const { showTestListFilterModal } = this.state;
    if (isMobile) {
      return (
        <>
          <MobileHeader name="Filters" showCloseButton onImageClick={this.props.closeFilter}>
            <div role="presentation" style={{ color: Constants().color.primary, fontWeight: 'bold' }} onClick={() => this.handleCloseModal(true)}> Clear All </div>
          </MobileHeader>
          <div style={{ margin: '60px 0px', padding: 20 }}>
            {this.renderFilterElements()}
          </div>
          <div
            role="presentation"
            onClick={() => this.applyFilters()}
            style={{
              position: 'fixed', bottom: 0, display: 'flex', width: '100%', height: 50, backgroundColor: Constants().color.primary, justifyContent: 'center', alignItems: 'center', color: '#FFFFFF',
            }}
          >
            Apply
          </div>
        </>
      );
    }
    return (
      <>
        <Modal key={showTestListFilterModal} showModal={showTestListFilterModal} handleCloseModal={this.handleCloseModal} width="380px" minwidth="none">
          {this.showModalView()}
        </Modal>
        <div
          role="presentation"
          style={{
            display: 'flex', justifyContent: 'space-evenly', alignItems: 'center', border: '1px solid #9EA0A5', width: 110, height: 38, borderRadius: 4,
          }}
          onClick={() => this.setState({ showTestListFilterModal: true })}
        >
          <img alt="" src={filter} width="16" height="16" />
          Filters
        </div>
      </>
    );
  }
}
export default connect()(TestListFilterModal);
