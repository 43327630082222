import React from 'react';
import ReactTooltip from 'react-tooltip';
import moment from 'moment';
import PropTypes from 'prop-types';
import { RadialChart } from 'react-vis';
import { connect } from 'react-redux';
import { createBrowserHistory } from 'history';
import upload from '../../static/images/upload.svg';
import Constants from '../../shared/constants';
import { UUID, isMobileDevice } from '../../helpers/util';
import Layout from '../layout';
import Loading from '../loading';
import ProgressBar from '../progressBar';
import ApproveTestModal from '../modal/approveTestModal';
import Modal from '../modal/baseModal';
import ConfirmationModal from '../modal/confirmationModal';
import MobileHeader from '../mobileHeader';
import Botany from '../../static/images/common/subject/Botany.svg';
import Physics from '../../static/images/common/subject/Physics.svg';
import Zoology from '../../static/images/common/subject/Zoology.svg';
import Chemistry from '../../static/images/common/subject/Chemistry.svg';
import Maths from '../../static/images/common/subject/Maths.svg';

import { PAGETYPE, REPORT_ISSUE_TOAST_MESSAGES } from '../../constants';
import {
  getTestOverview, updateTestSetCount, updateTestReview, showToast,
  updateShowTest, uploadQuestions, copyQuestionsFromTest,
  generateOtp,
} from '../../actions';
import { sendEvent } from '../../helpers/Analytics';
import TestAssignmentModal from '../modal/testAssignmentModal';
import EditSelected from '../../static/images/assignmentPage/editSelected.svg';

const images = {
  Botany,
  Physics,
  Zoology,
  Chemistry,
  Maths,
};

const history = createBrowserHistory({
  forceRefresh: true,
});

const SUCCESS = {
  fontWeight: 'bold', fontSize: 16, color: '#81DE79', textAlign: 'center',
};

const IS_MOBILE = isMobileDevice();
const TEST_QUESTION_UPDATE_CHANNEL = 'TestQuestionUpdateChannel';
const UPLOAD_STATUS = {
  Error: 0,
  Progressing: 1,
  Complete: 2,
  UnUpload: 3,
  Verified: 4,
  NotVerified: 5,
};
const COMPLETE_PROGRESS = 100;

class TestCreationOverview extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showPageLoading: true,
      showApproveModal: false,
      showConfirmation: false,
      showTestModal: false,
      screenWidth: 0,
      cable: Object.create(null),
      subscription: Object.create(null),
      progress: 0,
      errors: [],
      copyTestId: null,
    };
    this.toggleApproveModal = this.toggleApproveModal.bind(this);
    this.checkForTestViewStatus = this.checkForTestViewStatus.bind(this);
    this.allowTestView = this.allowTestView.bind(this);
    this.uploadTestQuestion = this.uploadTestQuestion.bind(this);
    this.renderIcon = this.renderIcon.bind(this);
    this.renderTestButton = this.renderTestButton.bind(this);
  }

  componentDidMount() {
    const { match } = this.props;
    const { id } = match.params;
    this.props.getTestOverview(id).then(() => {
      const { testDetails } = this.props;
      this.setState({
        multipleTestSets: testDetails.has_multiple_test_sets,
        showTest: testDetails.show_test,
        status: testDetails.question_upload_status,
      });
      this.setState(() => ({ showPageLoading: false }));
    });
    if (typeof window !== 'undefined') {
      this.setState({ screenWidth: window.innerWidth });
      const actionCable = require('actioncable');
      const cable = actionCable.createConsumer(Constants().api.ws_url);
      this.setState({ cable });
      const subscription = cable.subscriptions.create({ channel: TEST_QUESTION_UPDATE_CHANNEL, id }, {
        received: (data) => {
          this.setState({ status: data.status, progress: data.progress });
          if (data.progress === COMPLETE_PROGRESS && data.status === UPLOAD_STATUS.Complete) {
            this.props.showToast(REPORT_ISSUE_TOAST_MESSAGES.UPDATED);
            window.location.reload();
          }
        },
      });
      this.setState({ subscription });
    }
  }

  componentWillUnmount() {
    if (typeof window !== 'undefined') {
      const { cable, subscription } = this.state;
      if (cable) {
        cable.subscriptions.remove(subscription);
      }
    }
  }

  onClickCopyQuestionsFromTestId() {
    this.props.copyQuestionsFromTest(this.state.copyTestId, this.props.match.params.id).then((response) => {
      if (response.success) {
        setTimeout(() => window.location.reload(), 2000);
      }
    });
  }

  getStudentResultFile() {
    document.getElementById('uploadTestQuestionFile').click();
  }

  toggleApproveModal(shouldApprove = false) {
    const { testDetails } = this.props;
    if (!testDetails.test_approval_status && !testDetails.approved) {
      alert('Test cannot be Approved. Not all conditions are satisfied.');
      return;
    }
    const { showApproveModal } = this.state;
    this.setState({ showApproveModal: !showApproveModal });
    if (shouldApprove) {
      this.toggleTestReviewStatus();
    }
  }

  uploadTestQuestion(event) {
    const { id } = this.props.match.params;
    const fileList = event.target.files;
    const files = Object.values(fileList);
    if (files.length > 0) {
      this.props.showToast(REPORT_ISSUE_TOAST_MESSAGES.NOTVERIFIED);
      this.setState({ fileList: files });
      const sendFiles = files.map((file) => {
        const extension = file.name && file.name.split('.');
        return new File([file], `${UUID()}.${extension[extension.length - 1]}`, { type: file.type });
      });
      this.setState({ status: UPLOAD_STATUS.NotVerified }, () => this.props.uploadQuestions(sendFiles, id).then((data) => {
        if (data.success) {
          this.setState({ errors: data.errors, status: data.status });
          if (data.errors.length === 0) {
            this.props.showToast(REPORT_ISSUE_TOAST_MESSAGES.VERIFIED);
          } else {
            this.props.showToast(REPORT_ISSUE_TOAST_MESSAGES.ERRORS);
          }
        } else {
          this.setState({ showTestModal: false, status: UPLOAD_STATUS.UnUpload });
          this.props.showToast(REPORT_ISSUE_TOAST_MESSAGES.UPLOAD_ERROR);
        }
      }));
    }
  }

  addQuestionsToTest(text) {
    const { testDetails } = this.props;
    const { showTest, errors } = this.state;
    return (
      <div
        role="presentation"
        className="secondary_button"
        data-tip={testDetails.approved ? 'Cannot add Questions Once the test is approved' : ''}
        style={{ margin: '10px 0px', cursor: testDetails.approved ? 'not-allowed' : 'pointer' }}
        onClick={() => (testDetails.approved ? showTest ? this.checkForTestViewStatus : '' : this.setState({ showTestModal: true, errors: text === 'Upload Again' ? [] : errors }))}
      >
        {text}
        {testDetails.approved && <ReactTooltip />}
      </div>
    );
  }

  showProgress() {
    const { status, progress } = this.state;
    const isProgressing = UPLOAD_STATUS.Progressing === status;
    const isVerified = status === UPLOAD_STATUS.Verified;
    const isNotVerified = status === UPLOAD_STATUS.NotVerified;
    const text = isProgressing ? `${Object.keys(UPLOAD_STATUS)[status]} - ${progress}%` : 'No Processing Files';
    return (
      <div className="d-flex justify-content-center" style={{ margin: '10%', textAlignLast: 'center' }}>
        {isProgressing && <div style={SUCCESS}>{text}</div>}
        {isVerified && <div style={SUCCESS}>{REPORT_ISSUE_TOAST_MESSAGES.VERIFIED}</div>}
        {isNotVerified && <div style={SUCCESS}>{REPORT_ISSUE_TOAST_MESSAGES.NOTVERIFIED}</div>}
      </div>
    );
  }

  showUpoadedErrors() {
    const { errors } = this.state;
    return (
      <div style={{ display: 'flex', alignItems: 'center', flexDirection: 'column' }}>
        <div style={{ overflowY: 'scroll', maxHeight: 400, padding: '0px 10px' }}>
          {errors.map((error) => (
            <div style={{ color: 'red', margin: '20px 0px' }}>{error}</div>
          ))}
        </div>
        <div style={{ width: '50%' }}>{this.addQuestionsToTest('Upload Again')}</div>
      </div>
    );
  }

  allowTestView(testDate, startTime, showTestSyllabus, showStartTime, isClicked = false) {
    if (isClicked && (!startTime || !testDate)) {
      if (showStartTime) {
        alert('Test Date and Start Time connot be empty');
        return;
      } if (!testDate) {
        alert('Test Date connot be empty');
        return;
      }
    }
    const time = moment(startTime).format('H:mm:s');
    const date = testDate.toDateString();
    const testDateTime = new Date(`${date} ${time}`);
    this.setState({ showConfirmation: false, showPageLoading: true });
    const { id } = this.props.match.params;
    sendEvent('Assignment', 'allowTestView', 'View', { id, value: 'Enabling...' });
    this.props.updateShowTest(id, testDateTime,
      showTestSyllabus, showStartTime).then((result) => {
      let { showTest } = this.state;
      if (result.success) {
        showTest = !showTest;
      } else {
        this.props.showToast(result.message || 'Unable to allow test for view. Try again.');
      }
      this.setState({ showTest });
      this.setState(() => ({ showPageLoading: false }));
    });
  }

  checkForTestViewStatus() {
    const { showTest } = this.state;
    if (showTest) {
      this.props.showToast('Once the test is allowed for view, it can not be modified. ');
      return;
    }
    this.setState({ showConfirmation: true });
  }

  updateTestSets() {
    const multipleTestSets = !this.state.multipleTestSets;
    const { id } = this.props.match.params;
    const setCount = multipleTestSets ? 4 : 1;
    sendEvent('Assignment', 'UpdateMultipleSets', 'Click', { id, setCount });
    const payload = {
      id,
      set_count: setCount,
    };
    this.props.updateTestSetCount(payload);
    this.setState({ multipleTestSets });
  }

  toggleTestReviewStatus() {
    const { testDetails, match } = this.props;
    const { id } = match.params;
    const payload = {
      id,
      review: !testDetails.approved,
    };
    sendEvent('Assignment', 'UpdateReview', 'click', { payload });
    this.props.updateTestReview(payload).then((result) => {
      if (!result.success) {
        alert(result.message);
      }
    });
    return true;
  }

  renderTestModal() {
    return (
      <div
        role="presentation"
        className="d-flex flex-column justify-content-center"
        style={{
          paddingRight: global.innerWidth * 0.1, paddingLeft: global.innerWidth * 0.1, cursor: 'pointer', margin: '14px 0px',
        }}
        onClick={this.getStudentResultFile}
      >
        <div role="presentation" style={{ textAlign: 'center' }}>
          <img src={upload} alt="upload" width="50px" />
          <p> Supported .xlsx, .docx </p>
          <p style={{ fontSize: '12px', color: '#cdcdce' }}>Drag or browse the Excel file to upload.</p>
        </div>
        <div style={{ height: '0px', width: '0px', overflow: 'hidden' }}>
          <input id="uploadTestQuestionFile" type="file" name="result" accept=".xlsx,.docx" multiple="true" onChange={this.uploadTestQuestion} />
        </div>
      </div>
    );
  }

  renderMobile() {
    const { testDetails, hasAccess } = this.props;
    const { multipleTestSets, screenWidth, showTest } = this.state;
    const backNavigation = testDetails.isAssignment ? '/list/assignment' : '/list/test';
    return (
      <div>
        <MobileHeader
          color="white"
          backgroundColor={Constants().color.primary}
          name={testDetails.name}
          showBackButton
          onImageClick={() => {
            this.props.history.push(backNavigation);
            this.setState({ showPageLoading: true });
          }}
        />
        <div style={{
          display: 'flex', flexDirection: 'column', padding: 20, marginTop: 50, marginBottom: 50, backgroundColor: Constants().color.backgroundColor,
        }}
        >
          <div style={{
            backgroundColor: 'white', borderRadius: 5, padding: 20, boxShadow: '0px 3px 15px #343B4014',
          }}
          >
            <div style={{
              display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: 10,
            }}
            >
              <div>Questions</div>
              <div>
                {testDetails.picked_questions}
                /
                {testDetails.total_questions}
              </div>
            </div>
            <ProgressBar
              height={5}
              width={screenWidth - 80}
              fillPercentage={`${((testDetails.picked_questions / testDetails.total_questions) * 100)}%`}
            />
          </div>
          {hasAccess
            && (
            <div
              role="presentation"
              style={{
                display: 'flex', alignItems: 'center', marginTop: 10, padding: 10, width: 'fit-content',
              }}
              onClick={() => this.updateTestSets()}
            >
              <input type="checkbox" checked={multipleTestSets} style={{ width: 20, height: 20, margin: 0 }} />
              <div style={{ fontSize: 16, fontWeight: 'bold', marginLeft: 10 }}> Generate Multiple sets </div>
            </div>
            )}
          {hasAccess && testDetails.approved
            && (
            <div
              role="presentation"
              style={{
                display: 'flex', alignItems: 'center', padding: 10, width: 'fit-content',
              }}
              onClick={() => this.checkForTestViewStatus()}
            >
              <input type="checkbox" checked={showTest} style={{ width: 20, height: 20, margin: 0 }} />
              <div style={{ fontSize: 16, fontWeight: 'bold', marginLeft: 10 }}> Allow students to view </div>
            </div>
            )}
          <div style={{ display: 'flex', flexFlow: 'row wrap', marginTop: 10 }}>
            {testDetails.subjects && Object.keys(testDetails.subjects).map((id) => (
              this.renderSubject(id)
            ))}
          </div>
        </div>
        {!showTest && hasAccess
          && (
          <div style={{
            display: 'flex', position: 'fixed', bottom: 0, color: 'white', height: 50, width: '100%',
          }}
          >
            <div
              role="presentation"
              onClick={() => (testDetails.approved ? this.toggleTestReviewStatus() : this.toggleApproveModal())}
              style={{
                display: 'flex', justifyContent: 'center', alignItems: 'center', backgroundColor: testDetails.approved ? '#454545' : Constants().color.primary, width: testDetails.approved ? '50%' : '100%', height: '100%',
              }}
            >
              {testDetails.approved ? 'Unapprove' : 'Approve'}
            </div>
            {testDetails.approved
              && (
              <div
                role="presentation"
                onClick={() => this.renderPage()}
                style={{
                  display: 'flex', justifyContent: 'center', alignItems: 'center', backgroundColor: Constants().color.primary, width: '50%', height: '100%',
                }}
              >
                Print
              </div>
              )}
          </div>
          )}
      </div>
    );
  }

  renderWeb() {
    const { testDetails } = this.props;
    return (
      <Layout globalHistory={this.props.history}>
        <div style={{ display: 'flex', height: window.innerHeight - 70 }}>
          <div style={{ backgroundColor: '#FBFBFB', borderRight: '1px solid #ddd', padding: 20, width: '25%' }}>
            {this.renderSideBar()}
          </div>
          <div style={{ backgroundColor: '#EBEBEB', paddingTop: 20, width: '100%' }}>
            {testDetails.subjects && Object.keys(testDetails.subjects).map((id) => this.renderSubject(id))}
          </div>
        </div>
      </Layout>
    );
  }

  renderSubjectView(id, trackId, showTrack) {
    const { testDetails } = this.props;
    const { screenWidth } = this.state;
    let cardWidth = screenWidth > 1000 ? 1000 : screenWidth;
    cardWidth = (cardWidth / 2) - 40;
    const progressData = testDetails.subjects[id].tracks[trackId];
    let backgroundColor = '#6B76FF';
    let progressWidth = ((progressData.picked_questions * 100) / progressData.total_questions);
    if (progressWidth === 100) {
      backgroundColor = '#7FC436';
    } else if (progressWidth > 100) {
      progressWidth = 100;
      backgroundColor = '#F16F6F';
    }
    return (
      <div
        role="presentation"
        key={`SubjectTrack_${id}_${trackId}`}
        onClick={() => this.renderNextPage(id, trackId, progressData.chapters_picked)}
        style={{
          display: 'flex', flexDirection: 'column', alignItems: 'center', backgroundColor: 'white', borderRadius: 5, boxShadow: '0px 3px 15px #343B4014', margin: 10, padding: 20,
        }}
      >
        <img alt="" className="center" src={images[testDetails.subjects[id].name]} height={60} />
        <div className="header_text" style={{ marginTop: 10 }}>
          {testDetails.subjects[id].name}
        </div>
        {showTrack && (
        <div style={{ color: '#525252', marginTop: 5 }}>
          Track -
          {trackId}
        </div>
        )}
        <div style={{ fontWeight: 'bold', marginTop: 5, marginBottom: 10 }}>
          {progressData.picked_questions}
          /
          {progressData.total_questions}
        </div>
        <ProgressBar
          height={5}
          width={cardWidth - 40}
          fillPercentage={`${progressWidth}%`}
        />
      </div>
    );
  }

  renderIcon(type, text) {
    const {
      selectedClassroomList, classroomList,
      dispatch, hasAccess, selectedTestGroup,
      subjectQuestionLimit, isTestDataEditable, history, testDetails,
      isTrial, testDuration, previousPage, subjectId, track,
    } = this.props;
    const { selectedPage } = this.state;
    return (
      <TestAssignmentModal
        globalHistory={history}
        key={selectedPage}
        hasMultipleTestSets={testDetails.has_multiple_test_sets}
        isTestPage={!testDetails.isAssignment}
        name={testDetails.name}
        subjectId={subjectId}
        track={track}
        id={testDetails.id}
        refreshOnClose
        isPreviousYearPaperCopy={testDetails.is_previous_year_paper_copy}
        onCloseEdit={() => window.location.reload()}
        testDuration={testDuration}
        testDate={testDetails.test_date}
        selectedClassroomList={selectedClassroomList}
        subjectList={[]}
        isTestDataEditable={isTestDataEditable}
        classroomList={classroomList}
        selectedTestGroup={selectedTestGroup}
        isReviewed={testDetails.review}
        testGroupList={[]}
        dispatch={dispatch}
        hasAccess={hasAccess}
        questionLimit={subjectQuestionLimit}
        showModal={selectedPage === type}
        previousPage={previousPage}
        isOnline={testDetails.is_online}
        isTrial={isTrial}
        showTest={testDetails.show_test}
      />
    );
  }

  renderTestButton(test) {
    const { testOtp } = this.props;
    const showOTP = test.is_online && !test.is_published;
    return (
      <div style={{ display: 'flex', justifyContent: 'center', fontSize: 16 }}>
        {showOTP
          && (
          <div
            role="presentation"
            className="tertiary_button mr-3"
            style={{
              width: 80, fontSize: 16, height: 35, alignSelf: 'center',
            }}
            onClick={(event) => {
              this.props.generateOtp(test.id);
              event.stopPropagation();
            }}
          >
            {testOtp[test.id] ? testOtp[test.id] : 'Get OTP'}
          </div>
          )}
      </div>
    );
  }

  renderSideBar() {
    const {
      testDetails, hasAccess, isTestLockedByOtp, testOtpType, hasQuestionUploadAccess,
    } = this.props;
    const {
      multipleTestSets, showTestModal, errors, status, showTest,
    } = this.state;
    const data = [
      { count: (testDetails.picked_questions), color: Constants().color.primary },
      { count: (testDetails.total_questions - testDetails.picked_questions), color: '#EBEBEB' },
    ];
    const canUpload = !([UPLOAD_STATUS.Progressing, UPLOAD_STATUS.Verified, UPLOAD_STATUS.NotVerified].includes(status)) && errors.length === 0;
    return (
      <div style={{
        display: 'flex', justifyContent: 'space-between', height: '100%', flexDirection: 'column',
      }}
      >
        <div>
          <div style={{ width: '100%', textAlign: 'center' }}>
            <RadialChart
              innerRadius={80}
              radius={100}
              getAngle={(angle) => angle.count}
              data={data}
              height={250}
              width={300}
              colorType="literal"
            />
            <div style={{
              position: 'absolute', top: 215, left: 170, transform: 'translate(-50%, -50%)', fontWeight: 'bold', textAlign: 'center',
            }}
            >
              <div style={{ fontSize: 28, fontWeight: 'bold' }}>
                {testDetails.picked_questions}
                /
                {testDetails.total_questions}
              </div>
              <div style={{ fontSize: 16 }}>Questions</div>
            </div>
          </div>
          <div style={{
            textAlign: 'center',
            borderBottom: '1px solid #ddd',
            paddingBottom: 10,
          }}
          >
            <div className="header_text">
              <span role="presentation">
                <div style={{ paddingRight: 20, overflowWrap: 'anywhere' }}>
                  {testDetails.name}
                </div>
              </span>
            </div>
            <div className="secondary_text" style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
              {testDetails.date}
              <img alt="Edit" role="presentation" className="center" src={EditSelected} width="5%" style={{ marginLeft: 15 }} onClick={() => { this.setState({ selectedPage: PAGETYPE.EDIT }); }} />
              {this.renderIcon(PAGETYPE.EDIT, '')}
            </div>
            <div style={{ margin: '10px 0px' }}>
              {this.renderTestButton(testDetails)}
            </div>
          </div>
          {(hasAccess && !testDetails.isAssignment) && (
            <div
              style={{
                flex: 1,
                display: 'flex',
                margin: '15px 0px',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <div style={{ width: 150 }}>Copy Questions from Test ID:</div>
              <div>
                <input
                  style={{
                    width: 70,
                    height: 35,
                    marginBottom: 0,
                    marginRight: 13,
                  }}
                  value={this.state.copyTestId}
                  onChange={
                    (event) => this.setState({
                      copyTestId: parseInt(event.target.value, 10) || null,
                    })
                  }
                />
              </div>
              <div
                role="presentation"
                className="primary_button"
                style={{ padding: 7 }}
                onClick={() => this.onClickCopyQuestionsFromTestId()}
              >
                Copy
              </div>
            </div>
          )}
          {hasQuestionUploadAccess
          && (
          <>
            {this.addQuestionsToTest('Upload Questions')}
            <Modal showModal={showTestModal} handleCloseModal={() => this.setState({ showTestModal: false })} header="Processing Result" top="25%" headerHeight="50px">
              {canUpload ? this.renderTestModal() : errors.length === 0 ? this.showProgress() : this.showUpoadedErrors()}
            </Modal>
          </>
          )}
          {hasAccess
          && (
          <div style={{ display: 'flex', alignItems: 'center', marginTop: 10 }}>
            <input id="multiple-sets-test-creation" type="checkbox" checked={multipleTestSets} onChange={() => this.updateTestSets()} style={{ fontSize: 'x-large', marginRight: 10, marginTop: 0 }} />
            <label style={{ fontSize: 16, fontWeight: 'bold', marginBottom: 0 }}> Generate Multiple sets </label>
          </div>
          )}
          {hasAccess && testDetails.approved && !testDetails.is_published
          && (
          <div style={{
            display: 'flex', alignItems: 'center', marginTop: 20, paddingBottom: 10, borderBottom: '1px solid #ddd',
          }}
          >
            <input type="checkbox" checked={this.state.showTest} onChange={this.checkForTestViewStatus} style={{ fontSize: 'x-large', marginRight: 10, marginTop: 0 }} />
            <label style={{ fontSize: 16, fontWeight: 'bold', marginBottom: 0 }}> Allow students to view </label>
          </div>
          )}
          {(!testDetails.isAssignment) && testDetails.is_online && (isTestLockedByOtp)
          && (
          <div style={{ paddingLeft: 10, fontSize: 12 }}>
            <span>
              Preference: The test will be locked if a student moves out of the screen
            </span>
          </div>
          )}
          {(!testDetails.isAssignment) && testDetails.is_online && testOtpType
          && (
          <div style={{ paddingLeft: 10, marginTop: 10 }}>
            Test OTP Type:
            {testOtpType}
          </div>
          )}
        </div>
        <div>
          {!testDetails.test_approval_status && !testDetails.approved && <ReactTooltip />}
          {testDetails.approved && (
          <div
            role="presentation"
            className="secondary_button"
            onClick={() => this.renderPage()}
          >
            {testDetails.approved ? 'Print' : 'Insights'}
          </div>
          )}
          {!showTest && hasAccess
            && (
            <div
              role="presentation"
              data-tip="Test cannot be Approved"
              className="primary_button mt-4"
              onClick={() => (testDetails.approved ? this.toggleTestReviewStatus() : this.toggleApproveModal())}
            >
              {testDetails.approved ? 'Unapprove' : 'Approve'}
            </div>
            )}
        </div>
      </div>
    );
  }

  renderPage() {
    const { testDetails, match } = this.props;
    const { id } = match.params;
    if (testDetails.approved) {
      sendEvent('Assignment', 'OpenPrintPage', 'click', { id });
      this.props.history.push(`/print_assignment/${id}`);
    }
  }

  renderSubject(id) {
    const { testDetails } = this.props;
    const showTrack = Object.keys(testDetails.subjects[id].tracks).length > 1;
    return Object.keys(testDetails.subjects[id].tracks).map((trackId) => (
      IS_MOBILE ? this.renderSubjectView(id, trackId, showTrack) : this.renderView(id, trackId, showTrack)
    ));
  }

  renderView(id, trackId, showTrack) {
    const { testDetails } = this.props;
    const progressData = testDetails.subjects[id].tracks[trackId];
    let backgroundColor = Constants().color.primary;
    let progressWidth = ((progressData.picked_questions * 100) / progressData.total_questions);
    if (progressWidth === 100) {
      backgroundColor = '#7FC436';
    } else if (progressWidth > 100) {
      progressWidth = 100;
      backgroundColor = '#F16F6F';
    }
    return (
      <div key={`SubjectTrack_${id}_${trackId}`} className="m-5 p-5 col-md-3" style={{ backgroundColor: 'white', borderRadius: 5 }}>
        <div className="header_text">
          {testDetails.subjects[id].name}
        </div>
        <div style={{ color: showTrack ? '#525252' : 'white' }}>
          Track -
          {trackId}
        </div>
        <div className="d-flex mt-4" style={{ fontWeight: 'bold', justifyContent: 'space-between' }}>
          <div>Questions</div>
          <div>
            {progressData.picked_questions}
            /
            {progressData.total_questions}
          </div>
        </div>
        <div className="progress_bar mt-1">
          <div className="progress_bar_fill" style={{ width: `${progressWidth}%`, backgroundColor }} />
        </div>
        {(!testDetails.is_previous_year_paper_copy && (progressData.has_access || testDetails.isAssignment))
          && (
          <div
            role="presentation"
            className="primary_button mt-4"
            style={{ textAlign: 'center' }}
            onClick={() => this.renderNextPage(id, trackId, progressData.chapters_picked)}
          >
            Continue
          </div>
          )}
      </div>
    );
  }

  renderNextPage(subjectId, track, renderChapterSelection) {
    const { testDetails, isTrial, match } = this.props;
    const { id } = match.params;
    const progressData = testDetails.subjects[subjectId].tracks[track];
    const pickedQuestions = progressData ? progressData.picked_questions : 0;
    if (renderChapterSelection || pickedQuestions > 0) {
      const pageType = (isTrial || testDetails.cannot_pick_questions === 'true') ? PAGETYPE.REVIEW : PAGETYPE.PICKQUESTION;
      sendEvent('Assignment', 'OpenPickQuestions', 'log', { id, subjectId, track });
      this.props.history.push(`/new_assignment/${id}?subject_id=${subjectId}&track=${track}&page_type=${pageType}`);
    } else {
      sendEvent('Assignment', 'OpenTestChapterSelection', 'log', { id, subjectId, track });
      this.props.history.push(`/test_chapter_selection/${id}?subject_id=${subjectId}&track=${track}`);
    }
    this.setState({ showPageLoading: true });
  }

  render() {
    const { testDetails } = this.props;
    const { showApproveModal, showPageLoading, showConfirmation } = this.state;
    if (showPageLoading) {
      return <Loading />;
    }
    if (IS_MOBILE === '') {
      return <div />;
    }
    return (
      <>
        {showApproveModal
          && (
          <ApproveTestModal
            isMobile={IS_MOBILE}
            name={testDetails.name}
            testDate={testDetails.date}
            questionCount={testDetails.picked_questions}
            showApproveModal={showApproveModal}
            toggleApproveModal={this.toggleApproveModal}
            isOnline={testDetails.is_online}
          />
          )}
        {showConfirmation
          && (
          <ConfirmationModal
            isTest
            isMobile={IS_MOBILE}
            visible={showConfirmation}
            title="Are you sure?"
            description="This change is irreversible. Once the test is allowed for view, it can not be modified!"
            onConfirm={this.allowTestView}
            onClose={() => this.setState({ showConfirmation: false })}
            classrooms={testDetails.classrooms}
            studentsCount={testDetails.total_students}
            testDate={testDetails.test_date}
            showTestSyllabus={testDetails.show_syllabus}
            isOnline={testDetails.is_online}
            isMockTest={testDetails.is_mock_test}
          />
          )}
        {IS_MOBILE ? this.renderMobile() : this.renderWeb()}
      </>
    );
  }
}

TestCreationOverview.propTypes = {
  testDetails: PropTypes.object,
  testOtp: PropTypes.object,
  isTrial: PropTypes.bool,
  match: PropTypes.object,
  hasAccess: PropTypes.bool,
};

TestCreationOverview.defaultProps = {
  testDetails: {},
  testOtp: {},
  isTrial: true,
  match: { params: { id: '' } },
  hasAccess: false,
};

const mapStateToProps = ({ home, testConfiguration }) => ({
  testDetails: testConfiguration.testDetails,
  hasAccess: testConfiguration.hasAccess,
  isTrial: testConfiguration.isTrial,
  isTestLockedByOtp: testConfiguration.isTestLockedByOtp,
  testOtpType: testConfiguration.testOtpType,
  hasQuestionUploadAccess: testConfiguration.hasQuestionUploadAccess,
  name: testConfiguration.testDetails.name,
  testDate: testConfiguration.testDetails.test_date,
  testDuration: testConfiguration.testDetails.duration,
  isTestDataEditable: testConfiguration.isTestDataEditable,
  classroomList: testConfiguration.classroomList,
  selectedTestGroup: testConfiguration.selectedTestGroup,
  selectedClassroomList: testConfiguration.testDetails.classrooms,
  isAssignment: testConfiguration.testDetails.is_assignment,
  isReviewed: testConfiguration.testDetails.is_reviewed,
  isOnline: testConfiguration.testDetails.is_online,
  testOtp: home.testOtp,
});

export default connect(mapStateToProps, {
  getTestOverview,
  updateTestSetCount,
  updateTestReview,
  showToast,
  updateShowTest,
  uploadQuestions,
  copyQuestionsFromTest,
  generateOtp,
})(TestCreationOverview);
