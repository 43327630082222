import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { toggleMeetingStatus } from '../../actions';
import { MENU_DATA } from '../classBoard';

class EndMeeting extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      showLoader: true,
    };
  }

  componentDidMount() {
    const { router, showCurrentClass } = this.props;
    const { query } = router;
    const { id } = query;
    if (id) {
      this.props.toggleMeetingStatus(id, false).then(() => {
        this.setState({ showLoader: false });
        this.props.history.push(MENU_DATA.VIRTUAL_MEETING.route);
      });
    } else {
      this.setState({ showLoader: false });
      if (showCurrentClass) {
        this.props.history.push(MENU_DATA.CURRENT_CLASSES.route);
      } else {
        this.props.history.push(MENU_DATA.VIRTUAL_MEETING.route);
      }
    }
  }

  render() {
    const { showLoader } = this.state;
    if (showLoader) {
      return (
        <> </>
      );
    }
    return (
      <>
      </>
    );
  }
}

EndMeeting.propTypes = {
  router: PropTypes.object,
};

EndMeeting.defaultProps = {
  router: { query: {} },
};

const mapStateToProps = ({ }) => ({
});
export default connect(mapStateToProps)(EndMeeting);
