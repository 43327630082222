import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Layout from '../layout';
import Loader from '../loading';
import QuizResults from '../quizResults';
import QuizRankList from '../quizRankList';
import QuizFeedback from '../quizFeedback';
import LocaleDate from '../localeDate';
import QuizQuestions from '../quizQuestions';
import Menubar from '../menubar';
import Constants from '../../shared/constants';
import { getUserInfo, getMeetingData, updateShowStatus } from '../../actions';

class classReport extends PureComponent {
  constructor(props) {
    super(props);
    const { id, type } = props.match.params;
    this.state = {
      type,
      id,
      showLoader: true,
      isAddQuestion: false,
    };
    this.removeNewQuestion = this.removeNewQuestion.bind(this);
    this.updateShowStatus = this.updateShowStatus.bind(this);
    this.createNewQuestion = this.createNewQuestion.bind(this);
  }

  componentDidMount() {
    const { id } = this.state;
    this.props.getMeetingData(id);
    this.props.getUserInfo().then(() => {
      this.setState({ showLoader: false });
    });
  }

  updateShowStatus(questionIds, showQuestion, quizId) {
    this.props.updateShowStatus(questionIds, showQuestion, quizId);
  }

  createNewQuestion() {
    return (
      <div
        className="edit-button"
        role="presentation"
        style={{
          height: 35, marginBottom: 0, width: 150, backgroundColor: '#3994AE', marginLeft: 'auto', marginRight: 80, whiteSpace: 'nowrap', marginTop: 30,
        }}
        onClick={() => { this.setState({ isAddQuestion: true }); }}
      >
        New Question
      </div>
    );
  }

  removeNewQuestion() {
    this.setState({ isAddQuestion: false });
  }

  render() {
    const {
      type, showLoader, id, isAddQuestion,
    } = this.state;
    const { userData, meeting, updatedCount,history } = this.props;
    const NEW_MENU_DATA = meeting.live_quiz_id
      ? {
        FEEDBACK: { label: 'Feedback', route: `/classboard/feedback/${id}` },
        QUIZ_REPORT: { label: 'Quiz Report', route: `/classboard/quiz_report/${id}` },
        RANK_LIST: { label: 'Quiz Toppers', route: `/classboard/rank_list/${id}` },
        QUIZ_QUESTIONS: { label: 'Quiz Questions', route: `/classboard/quiz_questions/${id}` },
      }
      : {
        FEEDBACK: { label: 'Feedback', route: `/classboard/feedback/${id}` },
      };
    const dataType = type && type.toUpperCase();
    const types = Object.keys(NEW_MENU_DATA);
    const index = types.indexOf(dataType);
    const isTitlePresent = meeting.module || meeting.lecture || meeting.title;
    if (showLoader) {
      return <Loader />;
    }
    return (
      <Layout globalHistory={history} hideHeader title={NEW_MENU_DATA[dataType] ? NEW_MENU_DATA[dataType].label : ''}>
        <Menubar
          history={history}
          menuList={Object.values(NEW_MENU_DATA)}
          selectedOption={index}
          userData={userData}
          redirectTo="/"
          renderHeader
          feedback
          help
          showProfileIcon
          openFeedbackModalView={this.openFeedbackModalView}
          openHelpModalView={this.openHelpModalView}
        />
        <div style={{
          width: '80%', marginLeft: '20%', height: typeof window !== 'undefined' && window.innerHeight, userSelect: 'none', fontFamily: 'Roboto',
        }}
        >
          <div style={{
            fontWeight: 600, fontSize: 22, padding: '15px 60px 10px', minHeight: 70, background: '#FFFFFF', color: Constants().color.primary, boxShadow: '0px 3px 6px #343B4014', width: '80%', position: 'fixed', display: 'flex', zIndex: 1000, justifyContent: 'space-between',
          }}
          >
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              <div>{meeting.subject_name || ''}</div>
              {isTitlePresent
                ? (
                  <div style={{ marginTop: 2, fontSize: 14 }}>
                    {meeting.module ? `Module ${meeting.module} ${(meeting.lecture || meeting.title) ? ' | ' : ' '}` : ''}
                    {meeting.lecture ? `Lecture ${meeting.lecture} ${meeting.title ? ' | ' : ' '}` : ''}
                    {meeting.title ? meeting.title : ''}
                  </div>
                )
                : <></>}
              <div style={{ marginTop: 2, fontSize: 12 }}>
                {meeting.classroom_names && meeting.classroom_names.join(', ')}
              </div>
            </div>
            <div style={{ marginLeft: 'auto' }}>
              <LocaleDate date={new Date(meeting.start_time)} />
            </div>
          </div>
          <div style={{ padding: 90 }}>
            {dataType === 'QUIZ_QUESTIONS'
              && (
              <div>
                {this.createNewQuestion()}
                <QuizQuestions
                  id={meeting.live_quiz_id}
                  style={{ paddingTop: 20 }}
                  updateShowStatus={this.updateShowStatus}
                  key={`${updatedCount}-${isAddQuestion}`}
                  isAddQuestion={isAddQuestion}
                  updatedCount={updatedCount}
                  removeNewQuestion={this.removeNewQuestion}
                />
              </div>
              )}
            {dataType === 'FEEDBACK' && <QuizFeedback id={meeting.live_quiz_id} meetId={id} />}
            {dataType === 'QUIZ_REPORT' && <QuizResults id={meeting.live_quiz_id} key={updatedCount} />}
            {dataType === 'RANK_LIST' && <QuizRankList id={meeting.live_quiz_id} key={updatedCount} />}

          </div>
        </div>
      </Layout>
    );
  }
}

const mapStateToProps = ({ quiz, virtualMeeting }) => ({
  updatedCount: quiz.updatedCount,
  meeting: virtualMeeting.meeting,
  userData: virtualMeeting.user,
});

export default connect(mapStateToProps, { getUserInfo, updateShowStatus, getMeetingData })(classReport);
