import React, { PureComponent } from 'react';
import Modal from './baseModal';

class ApproveTestModal extends PureComponent {

  render() {
    const {
      name, testDate, questionCount, isMobile, isOnline, showApproveModal,
    } = this.props;
    return (
      <Modal showModal={showApproveModal} handleCloseModal={() => this.props.toggleApproveModal()} width={isMobile ? window.innerWidth - 60 : 'auto'}>
        <div style={{ margin: 30 }}>
          <div> Once the test is approved, you cannot make any changes.</div>
          <div style={{ marginTop: 10 }}>
            <div>
              <b> Name: </b>
              {name}
            </div>
            <div>
              <b> Date: </b>
              {testDate}
            </div>
            <div>
              <b> Questions Selected: </b>
              {questionCount}
            </div>
            <div>
              <b> Type: </b>
              {isOnline ? 'Online' : 'Offline'}
            </div>
          </div>
          <div className="row" style={{ justifyContent: 'flex-end', marginTop: 20 }}>
            <div
              role="presentation"
              className="secondary_button mr-3"
              style={{ padding: 10, width: isMobile ? 90 : 150 }}
              onClick={() => this.props.toggleApproveModal()}
            >
              Cancel
            </div>
            <div
              role="presentation"
              id="confirm-approve"
              className="primary_button"
              style={{ padding: 10, width: isMobile ? 90 : 150 }}
              onClick={() => this.props.toggleApproveModal(true)}
            >
              Approve
            </div>
          </div>
        </div>
      </Modal>
    );
  }
}

export default ApproveTestModal;
