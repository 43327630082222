import React from "react";
import "../bdeTools.css";
import { convertToCurrencyFormat } from "../../../helpers/util";
import Modal from "../../modal/baseModal";
import PropTypes from "prop-types";
import Loader from "../../loading";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import {
  editInstituteDetails,
  toggleSubscriptionStatus,
  showToast,
} from "../../../actions";
import moment from "moment";
class BdeInstituteInfo extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showEditModal: false,
      editInstituteData: {},
    };
  }

  handleInputChange(value, type) {
    this.setState({
      editInstituteData: { ...this.state.editInstituteData, [type]: value },
    });
  }

  renderInstituteInfo() {
    const { institute } = this.props;
    const lastPaymentDate =
      institute.payments?.length > 0
        ? moment(institute.payments[0].created_at).format("DD/MM/YYYY")
        : null;
    return (
      <div style={{ marginBottom: 15 }}>
        <div className="extended-row-background">
          <div style={{ margin: 10, width: 200 }}>
            <div className="extended-row-content">
              {institute.created_at
                ? moment(institute.created_at).format("DD/MM/YYYY")
                : "-"}
            </div>
            <div className="extended-row-title">Date Created</div>
          </div>
          <div style={{ margin: 10, width: 200 }}>
            <div className="extended-row-content">
              {institute.student_count
                ? convertToCurrencyFormat(institute.student_count, "")
                : "-"}
            </div>
            <div className="extended-row-title">Student Limit</div>
          </div>
          <div style={{ margin: 10, width: 200 }}>
            <div className="extended-row-content">
              {institute.questions_count
                ? convertToCurrencyFormat(institute.questions_count, "")
                : "-"}
            </div>
            <div className="extended-row-title">Questions Alloted</div>
          </div>
          <div style={{ margin: 10, width: 200 }}>
            <div className="extended-row-content">{lastPaymentDate || "-"}</div>
            <div className="extended-row-title">Last Payment Date</div>
          </div>
        </div>
        <div className="extended-row-background">
          <div style={{ margin: 10, width: 200 }}>
            <div className="extended-row-content">{this.props.instituteId}</div>
            <div className="extended-row-title">Institute ID</div>
          </div>
          <div style={{ margin: 10, width: 200 }}>
            <div className="extended-row-content">
              {convertToCurrencyFormat(institute.active_students_count, "") ||
                "-"}
            </div>
            <div className="extended-row-title">Current Active Students</div>
          </div>
          <div style={{ margin: 10, width: 200 }}>
            <div className="extended-row-content">
              {convertToCurrencyFormat(
                institute.remaining_question_count,
                ""
              ) || "-"}
            </div>
            <div className="extended-row-title">Remaining Questions</div>
          </div>
          <div style={{ margin: 10, width: 200 }}>
            <div className="extended-row-content">
              {convertToCurrencyFormat(institute.tests_count, "") || "-"}
            </div>
            <div className="extended-row-title">Tests Created</div>
          </div>
          {/* <div style={{ margin: 10, width: 200 }}>
            <div className="extended-row-content">
              {convertToCurrencyFormat(institute.videos_watched, "")} min
            </div>
            <div className="extended-row-title">Video Lectures Watched</div>
          </div> */}
        </div>
      </div>
    );
  }

  renderSubscriptionStatusToggle(onText, offText, checked, dealID) {
    return (
      <div style={{ display: "flex", justifyContent: "space-around" }}>
        <label className="toggle-switch" style={{ margin: "auto" }}>
          <input
            type="checkbox"
            defaultChecked={checked}
            onChange={() => {
              this.props.toggleSubscriptionStatus(
                dealID,
                checked ? "NEW" : "WON"
              );
            }}
          />
          <div className="slider-text round">
            <div className="switch-on">{onText}</div>
            <div className="switch-off">{offText}</div>
          </div>
        </label>
      </div>
    );
  }

  renderSendEmailButton(dealID) {
    return (
      <div
        role="presentation"
        className="Button primary-Button"
        onClick={() => {
          //POST to server with dealID
        }}
        style={{ width: 100, margin: "auto" }}
      >
        Send Email
      </div>
    );
  }

  renderSubscriptionDetails() {
    const { institute } = this.props;
    if (institute.subscriptions?.length === 0) {
      return (
        <div
          style={{
            margin: "20px 0 20px 0",
            fontSize: 23,
            fontWeight: 600,
            color: "#1d4c7f",
            display: "flex",
            justifyContent: "center",
          }}
        >
          No Subscriptions
        </div>
      );
    }
    return (
      <table className="table-bordered" style={{ margin: "30px 0" }}>
        <thead>
          <tr>
            <th>Product</th>
            <th style={{ textAlign: "right" }}>Subscription Start Date</th>
            <th style={{ textAlign: "right" }}>Subscription End Date</th>
            <th style={{ textAlign: "right" }}>Deal ID</th>
            <th style={{ textAlign: "right" }}>Trial</th>
            <th style={{ border: "none", textAlign: "center" }}>Status</th>
            {/* <th style={{ border: "none" }}></th> */}
          </tr>
        </thead>
        <tbody>
          {institute.subscriptions?.map((subscription) => {
            return (
              <tr key={subscription.id}>
                <td>{subscription.name}</td>
                <td style={{ textAlign: "right" }}>
                  {moment(subscription.subscription_start_date).format(
                    "DD/MM/YYYY"
                  )}
                </td>
                <td style={{ textAlign: "right" }}>
                  {moment(subscription.subscription_end_date).format(
                    "DD/MM/YYYY"
                  )}
                </td>
                <td style={{ textAlign: "right" }}>{subscription.id}</td>
                <td style={{ textAlign: "right" }}>
                  {subscription.is_trial ? "Yes" : "No"}
                </td>
                <td style={{ border: "none" }}>
                  {this.renderSubscriptionStatusToggle(
                    "Active",
                    "Paused",
                    subscription.status === "WON",
                    subscription.id
                  )}
                </td>
                {/* <td style={{ border: "none", padding: 15 }}>
                  {this.renderSendEmailButton(institute.deal_id)}
                </td> */}
              </tr>
            );
          })}
        </tbody>
      </table>
    );
  }

  renderBalanceInformation() {
    const { institute } = this.props;
    return (
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-around",
        }}
      >
        <style>
          {`
            .card-contents {
                font-size: 20px;
                text-align: center;
                color: #152a53
            }

            .card-titles {
                font-size: 14px;
                text-align: center;
                color: gray;
            }
          `}
        </style>
        <div>
          <div className="card-contents">
            {convertToCurrencyFormat(institute.sms_balance, "")}
          </div>
          <div className="card-titles">SMS Balance</div>
        </div>
        <div>
          <div className="card-contents">
            {convertToCurrencyFormat(institute.whatsapp_balance, "")}
          </div>
          <div className="card-titles">WhatsApp Balance</div>
        </div>
        <div>
          <div className="card-contents">
            {convertToCurrencyFormat(institute.email_balance, "")}
          </div>
          <div className="card-titles">E-Mail Balance</div>
        </div>
      </div>
    );
  }

  renderEditModal() {
    const { institute } = this.props;
    const { editInstituteData } = this.state;
    return (
      <Modal
        showModal={this.state.showEditModal}
        handleCloseModal={() =>
          this.setState({ showEditModal: false, editInstituteData: {} })
        }
        header={`Edit ${institute.name}`}
      >
        <div style={{ padding: 15 }}>
          <div
            style={{ marginBottom: 5, display: "flex", flexDirection: "row" }}
          >
            <span style={{ margin: "auto 15px", width: 200 }}>
              Student Limit:
            </span>
            <input
              className="input-field"
              id="Search"
              onChange={(event) => {
                this.handleInputChange(event.target.value, "student_count");
              }}
              type="text"
              placeholder={"Student Limit"}
              defaultValue={institute.student_count}
              style={{
                border: "1px solid #BFBFBF",
                marginBottom: 0,
              }}
              autoFocus
            />
          </div>
          <div
            style={{ marginBottom: 5, display: "flex", flexDirection: "row" }}
          >
            <span style={{ margin: "auto 15px", width: 200 }}>
              Contact Name:
            </span>
            <input
              className="input-field"
              id="Search"
              onChange={(event) => {
                this.handleInputChange(event.target.value, "contact_name");
              }}
              type="text"
              placeholder={"Contact Name"}
              defaultValue={institute.contact_name}
              style={{
                border: "1px solid #BFBFBF",
                marginBottom: 0,
              }}
            />
          </div>
          <div
            style={{ marginBottom: 5, display: "flex", flexDirection: "row" }}
          >
            <span style={{ margin: "auto 15px", width: 200 }}>
              Contact Phone:
            </span>
            <input
              className="input-field"
              id="Search"
              onChange={(event) => {
                this.handleInputChange(event.target.value, "phone");
              }}
              type="text"
              placeholder={"Contact Phone"}
              defaultValue={institute.phone}
              style={{
                border: "1px solid #BFBFBF",
                marginBottom: 0,
              }}
            />
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              marginTop: 10,
              justifyContent: "right",
            }}
          >
            <div
              className="Button secondary-Button"
              style={{ width: 100, margin: "auto 10px" }}
              onClick={() => {
                this.setState({ showEditModal: false, editInstituteData: {} });
              }}
            >
              Cancel
            </div>
            <div
              className="Button primary-Button"
              style={{ width: 100, margin: "auto 10px" }}
              onClick={() => {
                if (
                  !editInstituteData.student_count ||
                  !editInstituteData.phone ||
                  !editInstituteData.contact_name
                ) {
                  this.props.showToast(
                    "Please provide mandatory values: (Student Limit, Contact Name, Contact Phone)"
                  );
                } else {
                  this.props.editInstituteDetails(
                    editInstituteData,
                    institute.id
                  );
                  this.setState({
                    showEditModal: false,
                    editInstituteData: {},
                  });
                }
              }}
            >
              Update
            </div>
          </div>
        </div>
      </Modal>
    );
  }

  render() {
    const { institute } = this.props;
    if (this.props.isLoading) {
      return <Loader />;
    }
    return (
      <div>
        <div
          className="Button primary-Button"
          style={{ width: 100, margin: "0 0 15px auto" }}
          onClick={() => {
            this.setState({
              showEditModal: true,
              editInstituteData: {
                student_count: institute.student_count,
                phone: institute.phone,
                contact_name: institute.contact_name,
              },
            });
          }}
        >
          Edit
        </div>
        {this.renderInstituteInfo()}
        {this.renderSubscriptionDetails()}
        {/* {this.renderBalanceInformation()} */}
        {this.state.showEditModal && this.renderEditModal()}
      </div>
    );
  }
}

BdeInstituteInfo.propTypes = {
  isLoading: PropTypes.bool,
  institute: PropTypes.object,
};

BdeInstituteInfo.defaultProps = {
  institute: {},
};

const mapStateToProps = ({ bdeTools }) => ({
  isLoading: bdeTools.isLoading,
  institute: bdeTools.institute,
});

export default withRouter(
  connect(mapStateToProps, {
    editInstituteDetails,
    toggleSubscriptionStatus,
    showToast,
  })(BdeInstituteInfo)
);
