import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Select from 'react-select';
import Modal from './baseModal';
import {
  createCampus, createClassroom, getCampusList, showToast,
} from '../../actions';

const customDropdownStyles = {
  control: (base) => ({
    ...base,
    height: 35,
    width: 350,
    backgroundColor: 'white',
    border: '1px solid #CDCFD6',
  }),
  indicatorsContainer: (base) => ({
    ...base,
    height: 35,
  }),
  valueContainer: (base) => ({
    ...base,
    height: 35,
    overflow: 'auto',
  }),
  option: (base, state) => ({
    ...base,
    textAlign: 'left',
    color: state.isFocused ? '#FFF' : '#000',
    backgroundColor: state.isFocused && '#3994AE',
  }),
};
class CreateCampusModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      campusName: '',
      classroomName: '',
      showLoader: false,
      campusId: '',
    };
  }

  componentDidMount() {
    this.props.getCampusList();
  }

  renderInput(value = '', inputType = 'text', handleChange = () => {}, placeholder = '') {
    return (
      <input
        type={inputType}
        value={value}
        placeholder={placeholder}
        style={{
          border: 'none',
          borderBottom: '1px solid #E4E7EE',
          width: '90%',
          outline: 'none',
          margin: '5px 0px 0px 0px',
          fontSize: 16,
        }}
        onChange={handleChange}
      />
    );
  }

  renderSingleSelect(options, selectedOption, placeholder) {
    return (
      <Select
        value={selectedOption}
        onChange={(option) => {
          this.setState({
            campusId: option.value,
          });
        }}
        options={options}
        placeholder={placeholder}
        styles={customDropdownStyles}
        maxMenuHeight={130}
        isSearchable
      />
    );
  }

  renderCampusContent() {
    const {
      campusName, showLoader,
    } = this.state;
    const { submitButton, showToast, createCampus } = this.props;
    return (
      <>
        <div style={{ paddingTop: 25 }}>
          Campus name
          {this.renderInput(campusName, 'text', (event) => {
            this.setState({ campusName: event.target.value });
          }, 'Campus Name')}
        </div>
        <div style={{ paddingTop: 25, paddingLeft: 270 }}>
          {!showLoader
            ? (
              <div
                className="Button primary-Button"
                role="presentation"
                style={{ width: '200px' }}
                onClick={() => {
                  this.setState({ showLoader: true });
                  if (!campusName) {
                    showToast('Please Add Campus Name');
                  } else {
                    createCampus(campusName).then((response) => {
                      this.setState({ showLoader: false });
                      showToast(response.message);
                      if (response.success) {
                        window.location.reload();
                      }
                    });
                  }
                }}
              >
                {submitButton}
              </div>
            )
            : (
              <div
                className="Button primary-Button"
                role="presentation"
                style={{ width: '200px' }}
                onClick={() => {
                }}
              >
                {submitButton}
              </div>
            )}
        </div>
      </>
    );
  }

  renderClassroomContent() {
    const {
      classroomName, showLoader, campusId,
    } = this.state;
    const {
      submitButton, showToast, createClassroom, campusList,
    } = this.props;
    const selectedCampus = '';
    return (
      <>
        <div style={{ paddingTop: 25 }}>
          Classroom name
          {this.renderInput(classroomName, 'text', (event) => {
            this.setState({ classroomName: event.target.value });
          }, 'Classroom Name')}
        </div>
        <div style={{ paddingTop: 25, paddingBottom: 25 }}>
          Campus
          {this.renderSingleSelect(campusList.map((campus) => {
            return { value: campus.id, label: campus.name };
          }), selectedCampus.value, 'select campus')}
        </div>
        <div style={{ paddingTop: 25, paddingLeft: 270 }}>
          {!showLoader
            ? (
              <div
                className="Button primary-Button"
                role="presentation"
                style={{ width: '200px' }}
                onClick={() => {
                  this.setState({ showLoader: true });
                  if (!classroomName) {
                    showToast('Please Add Campus Name');
                  } else {
                    createClassroom(classroomName, campusId).then((response) => {
                      this.setState({ showLoader: false });
                      showToast(response.message);
                      if (response.success) {
                        window.location.reload();
                      }
                    });
                  }
                }}
              >
                {submitButton}
              </div>
            )
            : (
              <div
                className="Button primary-Button"
                role="presentation"
                style={{ width: '200px' }}
                onClick={() => {
                }}
              >
                {submitButton}
              </div>
            )}
        </div>
      </>
    );
  }

  render() {
    const {
      visible, onClose, header,
    } = this.props;
    return (
      <Modal
        showModal={visible}
        handleCloseModal={onClose}
        width="600px"
        header={header}
        style={{ padding: 40 }}
      >
        {header === 'Create New Campus' ? this.renderCampusContent() : this.renderClassroomContent()}
      </Modal>
    );
  }
}

CreateCampusModal.propTypes = {
  visible: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
  header: PropTypes.string,
  submitButton: PropTypes.string,
  campusList: PropTypes.array,
};

CreateCampusModal.defaultProps = {
  visible: false,
  header: 'Create New Campus',
  submitButton: 'Create',
  campusList: [],
};

const mapStateToProps = ({ institute }) => ({
  campusList: institute.campusList,
});

export default connect(mapStateToProps,
  {
    createCampus, createClassroom, getCampusList, showToast,
  })(CreateCampusModal);
