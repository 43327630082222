import { GET_QUESTION_SUGGESTION } from '../actions';

const initialState = {
  questionSuggestions: [],
};

export default function (state = initialState, action) {
  switch (action.type) {
    case GET_QUESTION_SUGGESTION: {
      return { ...state, ...action.payload };
    }
    default:
      return state;
  }
}
