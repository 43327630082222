import React from 'react';
import { connect } from 'react-redux';
import { getNumberFromString } from '../../helpers/util';
import { sendFeedback, showToast } from '../../actions';

const style = {
  resize: 'none', width: 350, minHeight: 45, marginBottom: 30, outline: 'none', border: '1px solid #454545', borderRadius: 0,
};
const border = {
  border: '1px solid #454545', width: 105, height: 38, borderRadius: 0,
};
class Feedback extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      phone: '',
      comments: '',
    };
    this.clear = this.clear.bind(this);
    this.submit = this.submit.bind(this);
  }

  clear() {
    this.setState({ phone: '', comments: '' });
    this.props.handleCloseModal();
  }

  submit() {
    const { phone, comments } = this.state;
    const { isClassboardProduct } = this.props;
    if (phone && phone.length < 10) {
      this.props.showToast('Phone number is invalid');
      return;
    }
    if (comments && comments.length > 0) {
      this.props.sendFeedback(phone, comments, isClassboardProduct).then(() => {
        this.props.showToast('Thank You For Your Feedback');
        this.props.handleCloseModal();
        this.clear();
      });
    } else {
      this.props.showToast('Comment should be present');
    }
  }

  render() {
    const { phone, comments } = this.state;
    return (
      <div style={{
        display: 'flex', background: '#F5F8FA', alignItems: 'center', placeContent: 'center', fontFamily: 'Roboto', width: 'fit-content',
      }}
      >
        <style>
          {`
          textarea::placeholder {
            color: #000000 !important;
          }
          `}
        </style>
        <div style={{
          display: 'flex', flexDirection: 'column', placeItems: 'center', background: '#FFFFFF',
        }}
        >
          <div style={{
            fontSize: 28, fontWeight: 600, marginBottom: 20, whiteSpace: 'nowrap',
          }}
          >
            Please share your feedback
          </div>
          <textarea
            type="tel"
            pattern="[0-9]+"
            maxLength="12"
            placeholder="Phone"
            autoFocus
            style={{ ...style, height: 45 }}
            value={phone}
            onChange={(event) => this.setState({ phone: getNumberFromString(event.target.value) })}
          />
          <textarea
            type="text"
            placeholder="Comments *"
            style={{ ...style, height: 155 }}
            value={comments}
            onChange={(event) => this.setState({ comments: event.target.value })}
          />
          <div style={{
            display: 'flex', justifyContent: 'space-between', width: 350, marginTop: 25,
          }}
          >
            <div
              role="presentation"
              className="Button"
              style={{ ...border }}
              onClick={() => this.clear()}
            >
            Cancel
            </div>
            <div
              role="presentation"
              className="Button"
              style={{ ...border }}
              onClick={() => this.submit()}
            >
            Submit
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default connect(null, { sendFeedback, showToast })(Feedback);
