import axios from 'axios';

import Api from '../helpers/Api';
import { showToast } from '.';

export const GET_INSTITUTE_FILES = 'instituteFiles/GET_INSTITUTE_FILES';
export const UPDATE_FILE_DETAILS = 'instituteFiles/UPDATE_FILE_DETAILS';
export const SHOW_HIDE_FILE = 'instituteFiles/SHOW_HIDE_FILE';
export const FILE_UPLOAD_PERCENTAGE = 'instituteFiles/FILE_UPLOAD_PERCENTAGE';
export const SET_OFFLINE_TEST_SUBJECTS = 'instituteFiles/SET_OFFLINE_TEST_SUBJECTS';
export const GET_META_DATA = 'instituteFiles/GET_META_DATA';

export function getInstituteFiles(virtualMeetingId) {
  return (dispatch) => Api({
    method: 'get',
    url: `/institutes/get_institute_attachments?from_institute=true&virtual_meeting_id=${virtualMeetingId || ''}`,
  }).then((response) => {
    if (response.success) {
      const subjectFiles = {};
      let subjectId = 0;
      response.file_details.forEach((file) => {
        subjectId = file.subject_id || 0;
        subjectFiles[subjectId] = { id: subjectId, name: file.subject_name || 'Common', totalFiles: subjectFiles[subjectId] ? (subjectFiles[subjectId].totalFiles + 1) : 1 };
      });
      const payload = {
        subjectFiles,
        instituteFiles: response.file_details,
        maxFileSize: response.max_file_size,
        classrooms: response.classrooms,
        campuses: response.campuses,
        semesters: response.semesters,
        subjects: response.subjects,
        departments: response.departments,
        isHigherEducation: response.is_higher_education,
        modulesAndLectures: response.lecture_and_modules,
      };
      dispatch({
        type: GET_INSTITUTE_FILES,
        payload,
      });
    }
    return response;
  });
}

export function getOfflineTestStudentResponse(testId) {
  return (dispatch) => Api({
    method: 'get',
    url: `/institutes/get_offline_test_students_response?offline_test_id=${testId}`,
  }).then((response) => {
    if (response.success) {
      const payload = {
        offlineStudentResponses: response.offline_student_responses,
        offlineTest: response.offline_test,
      };
      dispatch({
        type: SET_OFFLINE_TEST_SUBJECTS,
        payload,
      });
    } else {
      dispatch(showToast(response.message || 'Something went wrong, please contact us!'));
    }
    return response;
  });
}

export const uploadFileTo3 = (signedUrlResponse, file) => async (dispatch) => {
  const formData = new FormData();
  Object.keys(signedUrlResponse.presigned_aws_data.url_fields).forEach((key) => {
    formData.set(key, signedUrlResponse.presigned_aws_data.url_fields[key]);
  });
  formData.append('file', file);
  formData.set('Content-Type', file.type);
  const response = await axios.post(signedUrlResponse.presigned_aws_data.url, formData, {
    headers: { 'Content-Type': file.type },
    onUploadProgress: (ProgressEvent) => {
      const percentageCompleted = Math.round((ProgressEvent.loaded * 100) / ProgressEvent.total);
      dispatch(fileUploadPercentage(percentageCompleted));
    },
  });
  return response;
};

export function createOfflineTest(testName, testDateTime, testDurationInMinutes, testMarks, testInstruction, questionPaperS3Url, classroomIds = '', campusId = '', semesterId = '', departmentId = '', subjectId = '') {
  return (dispatch) => Api({
    method: 'post',
    url: '/institutes/create_offline_test',
    data: {
      name: testName,
      date_time: testDateTime,
      duration: testDurationInMinutes,
      mark: testMarks,
      instruction: testInstruction,
      classroom_ids: classroomIds || '',
      campus: campusId || '',
      semester: semesterId || '',
      department: departmentId || '',
      subject: subjectId || '',
      question_paper_url: questionPaperS3Url,
    },
  }).then((response) => {
    if (response.success) {
      dispatch(showToast('Test created successfully'));
    } else {
      dispatch(showToast('Something went wrong, please contact us'));
    }
    return response;
  });
}

export function getSubjectsOfflineTest(subjectId) {
  return (dispatch) => Api({
    method: 'get',
    url: `/institutes/get_subjects_offline_test?subject_id=${subjectId}`,
  }).then((response) => {
    if (response.success) {
      const payload = {
        subjectOfflineTestList: response.subject_offline_test_list,
        offlineTestSubjectName: response.subject_name,
      };
      dispatch({
        type: SET_OFFLINE_TEST_SUBJECTS,
        payload,
      });
    } else {
      dispatch(showToast(response.message || 'Something went wrong, please contact us!'));
    }
    return response;
  });
}

export function getOfflineTestListSubjects() {
  return (dispatch) => Api({
    method: 'get',
    url: '/institutes/get_offline_test_subjects',
  }).then((response) => {
    if (response.success) {
      const payload = {
        offlineTestListSubjectList: response.offline_tests,
      };
      dispatch({
        type: SET_OFFLINE_TEST_SUBJECTS,
        payload,
      });
    } else {
      dispatch(showToast('Something went wrong, please contact us!'));
    }
    return response;
  });
}

export const getSignedUrl = (fileExtension) => async () => {
  const response = await Api({
    method: 'get',
    url: `/institutes/get_signed_url?file_extension=${fileExtension}`,
  });
  return response;
};

export function getMetaData() {
  return (dispatch) => Api({
    method: 'get',
    url: '/institutes/get_institute_meta_data',
  }).then((response) => {
    if (response.success) {
      const payload = {
        maxFileSize: response.max_file_size,
        classrooms: response.classrooms,
        campuses: response.campuses,
        semesters: response.semesters,
        subjects: response.subjects,
        departments: response.departments,
        isHigherEducation: response.is_higher_education,
        modulesAndLectures: response.lecture_and_modules,
      };
      dispatch({
        type: GET_META_DATA,
        payload,
      });
    }
    return response;
  });
}

export function uploadAttachmentDetails(title, description, fileS3Url, classroomIds = '', campusId = '', semesterId = '', departmentId = '', subjectId = '', virtualMeetingId = '', moduleNo = '', lectureNo = '', fromVirtualMeeting = true) {
  return (dispatch) => Api({
    method: 'post',
    url: '/institutes/upload_institute_attachments',
    type: 'institute_file',
    data: {
      title,
      description,
      classrooms: classroomIds || '',
      campus: campusId || '',
      semester: semesterId || '',
      department: departmentId || '',
      subject: subjectId || '',
      virtual_meeting_id: virtualMeetingId || '',
      module: moduleNo || '',
      lecture: lectureNo || '',
      file_url: fileS3Url,
    },
  }).then((response) => {
    if (response.success) {
      const virtualMeeting = fromVirtualMeeting ? virtualMeetingId : '';
      dispatch(getInstituteFiles(virtualMeeting));
      dispatch(showToast('File shared successfully'));
    } else {
      dispatch(showToast('Something went wrong, please contact us'));
    }
    return response;
  });
}

export function fileUploadPercentage(percentageCompleted) {
  return (dispatch) => new Promise((resolve) => {
    dispatch({
      type: FILE_UPLOAD_PERCENTAGE,
      payload: percentageCompleted,
    });
    resolve();
  });
}

export function updateStudentMark(id, mark, testId) {
  return (dispatch) => Api({
    method: 'post',
    url: '/institutes/update_offline_test_mark',
    data: { offline_test_student_response_id: id, mark, offline_test_id: testId },
  }).then((response) => {
    if (response.success) {
      const payload = {
        offlineStudentResponses: response.offline_student_responses,
      };
      dispatch({
        type: SET_OFFLINE_TEST_SUBJECTS,
        payload,
      });
      dispatch(showToast('Updated successfully'));
    } else {
      dispatch(showToast(response.message || 'Something went wrong, please contact us!'));
    }
  });
}

export const getSignedUrlAndUploadToS3 = (title, description, file, classroomIds = '', campusId = '', semesterId = '', departmentId = '', subjectId = '', virtualMeetingId = '', moduleNo = '', lectureNo = '', fromVirtualMeeting = true) => async (dispatch, getState) => {
  const fileExtension = file.name.split('.').slice(-1).pop();
  const response = await Api({
    method: 'get',
    url: `/institutes/get_signed_url?file_extension=${fileExtension}`,
  });

  if (response.success) {
    const formData = new FormData();
    Object.keys(response.presigned_aws_data.url_fields).forEach((key) => {
      formData.set(key, response.presigned_aws_data.url_fields[key]);
    });
    formData.append('file', file);
    formData.set('Content-Type', file.type);
    await axios.post(response.presigned_aws_data.url, formData, {
      headers: { 'Content-Type': file.type },
      onUploadProgress: (ProgressEvent) => {
        const percentageCompleted = Math.round((ProgressEvent.loaded * 100) / ProgressEvent.total);
        dispatch(fileUploadPercentage(percentageCompleted));
      },
    })
      .then((res) => {
        const fileS3Url = `${res.config.url}/${response.key}`;
        dispatch(uploadAttachmentDetails(title, description, fileS3Url,
          classroomIds, campusId, semesterId, departmentId, subjectId,
          virtualMeetingId, moduleNo, lectureNo, fromVirtualMeeting));
      });
  } else {
    dispatch(showToast('Something went wrong!'));
  }
  return response;
};

export function updateAttachmentDetails(id, title, description, classroomIds) {
  return (dispatch) => Api({
    method: 'post',
    url: '/institutes/update_institute_attachment_details',
    data: {
      id, title, description, classrooms: classroomIds,
    },
  }).then((response) => {
    if (response.success) {
      dispatch(showToast('Succesfully updated.'));
      const payload = {
        id,
        title,
        description,
      };
      dispatch({
        type: UPDATE_FILE_DETAILS,
        payload,
      });
      dispatch(getInstituteFiles());
    }
    return response;
  });
}

export function hideFile(id) {
  return (dispatch) => Api({
    method: 'post',
    url: '/institutes/show_hide_institute_file',
    data: { id },
  }).then((response) => {
    if (response.success) {
      dispatch({
        type: SHOW_HIDE_FILE,
        id,
      });
      dispatch(showToast('Updated successfully'));
    }
    return response;
  });
}
