import Api from '../helpers/Api';

export const GET_STUDENT_DETAIL = 'studentDashboard/GET_STUDENT_DETAIL';
export const VALIDATE_STUDENT_DETAIL = 'studentDashboard/VALIDATE_STUDENT_DETAIL';
export const ERROR_STUDENT_DETAIL = 'studentDashboard/ERROR_STUDENT_DETAIL';

export const getStudentDetail = (studentID) => async (dispatch) => {
  const response = await Api({
    method: 'get',
    url: `/students/${studentID}/get_student_details`,
  });
  if (response.success && response.is_valid) {
    dispatch({
      type: GET_STUDENT_DETAIL,
      payload: {
        basicStudentData: response.basic_data,
        testData: response.test_data,
        testStats: response.test_stats,
        subjects: response.subjects,
        performance: response.performance,
        isValidate: response.is_valid,
      },
    });
  } else if (response.success && !response.is_valid) {
    dispatch({
      type: GET_STUDENT_DETAIL,
      payload: {
        isValidate: response.is_valid,
      },
    });
  } else {
    dispatch({
      type: ERROR_STUDENT_DETAIL,
    });
  }
};

export const validateStudentDetail = (studentID, dateOfBirth) => async (dispatch) => {
  const response = await Api({
    method: 'post',
    url: '/students/validate_student_details',
    data: { student_id: studentID, date_of_birth: dateOfBirth },
  });
  dispatch({
    type: VALIDATE_STUDENT_DETAIL,
    payload: {
      error: response.error,
    },
  });
  return response.success;
};
