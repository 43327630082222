import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
  XAxis,
  YAxis,
  VerticalGridLines,
  HorizontalGridLines,
  VerticalBarSeries,
  LineSeries,
  LineMarkSeries,
  FlexibleWidthXYPlot,
} from 'react-vis';
import avatar from '../../static/images/avatar.png';
import CustomAxisLabel from '../testOverview/customAxisLabel';
import '../../../node_modules/react-vis/dist/style.css';
import RadialChart from '../../../node_modules/react-vis/dist/radial-chart/index.js';

import Layout from '../layout';
import { getStudentDetail, validateStudentDetail } from '../../actions';
import { STUDENT_DASHBOARD_TYPE, STUDENT_DASHBOARD_TYPE_COLOR } from '../../constants';
import { getLogoutUrl } from '../../helpers/util';

const TEST_COUNT = 5;
class StudentDashboard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showMore: false,
      badgeID: '',
      dateOfBirth: '',
      errors: '',
      subjectSelected: 'overall',
    };
    this.toggle = this.toggle.bind(this);
    this.updateSubjectSelection = this.updateSubjectSelection.bind(this);
  }

  componentDidMount() {
    const query = new URLSearchParams(window.location.search);
    const studentID = query.get('studentID');
    this.props.getStudentDetail(studentID);
  }

  

  toggle() {
    const { showMore } = this.state;
    this.setState({ showMore: !showMore });
  }

  updateSubjectSelection(subjectSelected) {
    this.setState({ subjectSelected });
  }

  validateStudentDetail() {
    const { badgeID, dateOfBirth } = this.state;
    if (dateOfBirth) {
      this.props.validateStudentDetail(badgeID, dateOfBirth).then((response) => {
        if (response) {
          this.props.history.push(`/student_dashboard?student_id=${this.state.badgeID}`);
        }
      });
    } else {
      this.setState({ errors: 'Please Fill Date of Birth' });
    }
  }

  renderTrackWiseScore() {
    const { subjectWiseScore } = this.props.performance;
    const track1Subject = subjectWiseScore.map((subjectData) => { return { x: subjectData.subject, y: subjectData.highest_score }; });
    const track2Subject = subjectWiseScore.map((subjectData) => { return { x: subjectData.subject, y: subjectData.median_score }; });
    return (
      <FlexibleWidthXYPlot height={360} yType="linear" margin={{ left: 80, bottom: 60 }} yDomain={[0, 220]} xType="ordinal">
        <HorizontalGridLines style={{ stroke: '#e6e6e9' }} />
        <XAxis title="" style={{ ticks: { fontSize: 14 } }} />
        <YAxis title="" />
        <CustomAxisLabel title="Marks" />
        <CustomAxisLabel title="Subjects" xAxis />
        <VerticalBarSeries data={track1Subject} color="#9B51E0" barWidth={0.3} style={{ stroke: '#fff', strokeWidth: 2 }} />
        <VerticalBarSeries data={track2Subject} color="#8DB4FF" barWidth={0.3} style={{ stroke: '#fff', strokeWidth: 2 }} />
      </FlexibleWidthXYPlot>
    );
  }

  renderStudentBasicData() {
    const { basicStudentData } = this.props;
    const query = new URLSearchParams(window.location.search);
    const studentID = query.get('studentID');
    return (
      <>
        <style jsx>
          {`
          p {
            margin: 0px;
            margin-bottom: 5px;
          }
          h3 {
            text-align: center;
          }
          .header {
            font-size: 19px;
            font-weight: bold;
          }
          .border-ruler {
            border-top: 1px solid #535353;
            border-bottom: 1px solid #535353;
            margin-top: 30px;
            padding-top: 30px;
            padding-bottom: 20px;
          }
        `}
        </style>
        <p style={{ fontSize: 32, fontWeight: 'bold', textAlign: 'center' }}>{basicStudentData.name}</p>
        <div className="d-flex flex-row border-ruler">
          <div className="col-md-4">
            <div className="row">
              <div className="col-md-4">
                <img className="cursor" src={avatar} width="auto" height="auto" />
              </div>
              <div className="col-md-8 mt-3">
                <p style={{ fontSize: 18 }}>
                  <span className="header">Badge ID: &nbsp;</span>
                  {studentID}
                </p>
                <p style={{ fontSize: 18 }}>
                  <span className="header">CLassroom: &nbsp;</span>
                  {basicStudentData.classroom_name}
                </p>
                <p style={{ whiteSpace: 'nowrap', fontSize: 18 }}>
                  <span className="header">Campus: &nbsp;</span>
                  {basicStudentData.campus_name}
                </p>
              </div>
            </div>
          </div>
          <div className="col-md-3 offset-3 mt-3">
            <p>
              <span className="header">Date of Birth: &nbsp;</span>
              <span>{basicStudentData.dob}</span>
            </p>
            <p style={{ display: '-webkit-box' }}>
              <span className="header">Address: &nbsp;</span>
              <span style={{ display: 'table' }}>{basicStudentData.address}</span>
            </p>
          </div>
        </div>
      </>
    );
  }

  renderRecentTestList() {
    const { testData } = this.props;
    const { showMore } = this.state;
    const listLength = showMore ? testData.length : TEST_COUNT;
    const showFirstFive = testData.slice(0, listLength);
    const showMoreText = showMore ? 'See Less' : 'See More';

    return (
      <div className="mt-5" style={{ borderTop: '1px solid #535353' }}>
        <p style={{
          marginBottom: 10, fontWeight: 'bold', fontSize: 20, marginTop: 30,
        }}
        >
          Recent Tests
        </p>
        <table className="table table-borderless">
          <thead>
            <tr>
              <th>Date</th>
              <th>Name</th>
              <th>Score</th>
              <th>Rank</th>
            </tr>
          </thead>
          <tbody>
            {showFirstFive.map((testData) => {
              const testValues = Object.values(testData)[0];
              return (
                <tr key={`${testValues.rank}_${testValues.score}`}>
                  <td>{testValues.test_date}</td>
                  <td>{testValues.name}</td>
                  <td>{testValues.score}</td>
                  <td>{testValues.rank}</td>
                </tr>
              );
            })}
          </tbody>
        </table>
        <button type="button" style={{ background: '#9B51E0' }} className="btn-xl" onClick={() => this.toggle()}>
          <span style={{ fontSize: 16, color: 'white' }}>{showMoreText}</span>
        </button>
      </div>
    );
  }

  renderStudentScore() {
    const { testData } = this.props;
    const lastFivePerformance = testData.slice(0, 5).map((testData) => {
      const values = Object.values(testData);
      return { x: values[0].name, y: parseInt(values[0].score, 10) };
    });

    return (
      <>
        <div>
          <FlexibleWidthXYPlot height={360} xType="ordinal" margin={{ left: 80, bottom: 60 }} yDomain={[0, 720]}>
            <HorizontalGridLines style={{ stroke: '#e6e6e9' }} />
            <VerticalGridLines style={{ stroke: '#e6e6e9' }} />
            <XAxis style={{ ticks: { fontSize: 10 } }} />
            <YAxis title="Average Score" />
            <LineSeries
              data={lastFivePerformance}
              color="#535353"
            />
            <LineMarkSeries
              data={lastFivePerformance}
              style={{
                strokeLinejoin: 'round',
                strokeWidth: 3,
                fill: 'none',
              }}
              color="#535353"
              fill="#535353"
              size={3}
            />
            <CustomAxisLabel title="Scores" />
            <CustomAxisLabel title="Tests" xAxis />
          </FlexibleWidthXYPlot>
        </div>
      </>
    );
  }

  renderRadialChart(type, value) {
    let averageValue = 0;
    const data = [];
    const colorRange = [];
    let valueType = type.charAt(0).toUpperCase() + type.slice(1);
    if (type === STUDENT_DASHBOARD_TYPE.SCORE) {
      const totalTestScore = 720;
      averageValue = value / TEST_COUNT;
      data.push({ angle: totalTestScore - averageValue });
      data.push({ angle: averageValue });
    } else {
      averageValue = ((value / TEST_COUNT) * 100).toFixed(2);
      data.push({ angle: 100 - parseFloat(averageValue) });
      data.push({ angle: parseFloat(averageValue) });
      averageValue += ' %';
    }

    if (type === STUDENT_DASHBOARD_TYPE.ANSWER) {
      valueType = 'Answer Rate';
      colorRange.push(STUDENT_DASHBOARD_TYPE_COLOR[STUDENT_DASHBOARD_TYPE.ANSWER]);
    } else if (type === STUDENT_DASHBOARD_TYPE.INCORRECT) {
      valueType = 'Negative Marks';
      colorRange.push(STUDENT_DASHBOARD_TYPE_COLOR[STUDENT_DASHBOARD_TYPE.INCORRECT]);
    } else {
      colorRange.push(STUDENT_DASHBOARD_TYPE_COLOR[STUDENT_DASHBOARD_TYPE.SCORE]);
    }
    colorRange.push(STUDENT_DASHBOARD_TYPE_COLOR[STUDENT_DASHBOARD_TYPE.MISC]);

    return (
      <div className="flex-col mr-5" style={{ textAlign: 'center' }} key={value}>
        <style jsx>
          {`
          .image-container {
            position: relative;
            text-align: center;
          }
          .text-centered {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
          }
          p {
            margin: 0px 0px 0px 0px;
            font-size: 20px;
          }
        `}
        </style>
        <div className="image-container">
          <RadialChart
            className="donut-chart-example"
            innerRadius={60}
            radius={70}
            colorRange={colorRange}
            getAngle={(d) => d.angle}
            data={data}
            width={200}
            height={200}
            padAngle={0.04}
          />
          <p className="text-centered">{averageValue}</p>
        </div>
        <p>{valueType}</p>
      </div>
    );
  }

  renderAverageStats() {
    const { testStats, subjects } = this.props;
    const { subjectSelected } = this.state;
    const currentStatSelected = testStats[subjectSelected.toLowerCase()];
    let subjectList = ['overall'];
    subjectList = subjectList.concat(subjects);

    return (
      <>
        <style jsx>
          {`
          p {
            font-size: 18px;
            margin: 0px 5px 0px 5px;
            padding: 5px;
          }
          .golden-border {
            border: 1px solid #F2C94C;
            border-radius: 5px;
          }
          .div-spacing {
            margin: 0px 30px 0px 30px
          }
        `}
        </style>
        <p style={{ marginBottom: 15, fontWeight: 'bold', fontSize: 20 }}>Average Stats</p>
        <div className="d-flex flex-row" style={{ margin: 0, padding: 0 }}>
          {subjectList.map((subject) => {
            const background = subject === subjectSelected ? 'rgba(242, 201, 76, 0.1)' : 'white';
            return (
              <div className="golden-border div-spacing cursor" key={subject} style={{ background }} onClick={() => this.updateSubjectSelection(subject)}>
                <p>{subject.toUpperCase()}</p>
              </div>
            );
          })}
        </div>
        <div
          className="d-flex flex-row"
          style={{
            justifyContent: 'space-between', marginTop: 20, margin: 0, padding: 0,
          }}
        >
          {Object.keys(currentStatSelected).map((key) => this.renderRadialChart(key, currentStatSelected[key]))}
        </div>
      </>
    );
  }

  render() {
    const { error, isValidate } = this.props;
    const {
      attendance, bestScore, bestRank, strongSubject, weakSubject, subjectWiseScore,
    } = this.props.performance;
    if (isValidate && Object.keys(this.props.basicStudentData).length > 0) {
      return (
        <Layout globalHistory={this.props.history} feedback={false} footerShow={false}>
          <style jsx>
            {`
          .container {
            margin-top: 4%;
          }
          p {
            font-size: 24px;
          }
          .div-border {
            border-radius: 4px;
            border: 1px solid #BFBFBF;
            padding: 25px;
            margin-right: 20px;
            margin-bottom: 30px;
          }
          .performance {
            width: 100px;
            height: 100px;
            padding-bottom: 100px;
            border: 2px solid #9B51E0;
            border-radius: 50px;
            line-height: 100px;
            text-align: center;
            font-size: 30px;
            font-weight: bold;
          }
          
          .performance-p {
            font-size: 14px;
            margin-top: 10px;
            text-align: center;
          }
          `}
          </style>
          <div className="container">
            {this.renderStudentBasicData()}
            <div className="d-flex flex-row" style={{ padding: '40px 30px 20px 30px', borderBottom: '1px solid #535353', justifyContent: 'space-between' }}>
              {attendance && (
              <div>
                <div className="performance">
                  {attendance}
                  %
                </div>
                <p className="performance-p">Attendance</p>
              </div>
              )}
              {bestRank && (
              <div>
                <div className="performance">{bestRank}</div>
                <p className="performance-p">Best Rank</p>
              </div>
              )}
              {bestScore && (
              <div>
                <div className="performance">{bestScore}</div>
                <p className="performance-p">Best Score</p>
              </div>
              )}
              {strongSubject && (
              <div>
                <div className="performance">{strongSubject.substring(0, 3).toUpperCase()}</div>
                <p className="performance-p">Strong Subject</p>
              </div>
              )}
              {weakSubject && (
              <div>
                <div className="performance">{weakSubject.substring(0, 3).toUpperCase()}</div>
                <p className="performance-p">Weak Subject</p>
              </div>
              )}
            </div>
            <p style={{
              margin: 0, padding: 0, marginTop: 34, marginBottom: 10, fontWeight: 'bold', fontSize: 20,
            }}
            >
              Recent Performance (Last
              {TEST_COUNT}
              Tests)
            </p>
            <div className="div-border">
              {this.renderStudentScore()}
              <div className="d-flex flex-row" style={{ alignItems: 'center' }}>
                {/* <div style={{height: 20, width: 20, borderRadius: 4, backgroundColor:"#9B51E0", marginLeft: 20, marginRight: 10 }}></div>
              <p style={{ fontSize: 12, margin: 0, padding: 0 }}>Highest</p> */}
                <div style={{
                  height: 20, width: 20, borderRadius: 4, backgroundColor: '#535353', marginLeft: 20, marginRight: 10,
                }}
                />
                <p style={{ fontSize: 12, margin: 0, padding: 0 }}>Median</p>
              </div>
            </div>
            {subjectWiseScore.length > 0
            && (
            <div>
              <p style={{ marginBottom: 10, fontWeight: 'bold', fontSize: 20 }}>Subject-wise score</p>
              <div className="div-border">
                {this.renderTrackWiseScore()}
                <div className="d-flex flex-row" style={{ alignItems: 'center' }}>
                  <div style={{
                    height: 20, width: 20, borderRadius: 4, backgroundColor: '#9B51E0', marginLeft: 20, marginRight: 10,
                  }}
                  />
                  <p style={{ fontSize: 12, margin: 0, padding: 0 }}>Highest</p>
                  <div style={{
                    height: 20, width: 20, borderRadius: 4, backgroundColor: '#8DB4FF', marginLeft: 20, marginRight: 10,
                  }}
                  />
                  <p style={{ fontSize: 12, margin: 0, padding: 0 }}>Median</p>
                </div>
              </div>
            </div>
            )}
            {this.renderAverageStats()}
            {this.renderRecentTestList()}
          </div>
        </Layout>
      );
    }
    return (
      <Layout globalHistory={this.props.history} feedback={false} footerShow={false}>
        <style jsx>
          {`
            .container {
              margin-top: 4%;
            }
            p {
              font-size: 24px;
              color: red;
            }
            `}
        </style>
        <div className="container">
          <p>
            {error}
            {this.state.errors}
          </p>
          <div className="d-flex flex-row">
            <input
              id="Search"
              onChange={(event) => this.setState({ badgeID: event.target.value })}
              type="text"
              className="form-control"
              placeholder="Badge Id"
              value={this.state.searchUserID}
              style={{
                fontSize: 16, height: 50, width: '200px', marginRight: 50,
              }}
            />
            <div style={{ lineHeight: '50px', marginRight: 20 }}>Date of Birth:</div>
            <input
              id="DateOfBirth"
              onChange={(event) => this.setState({ dateOfBirth: event.target.value })}
              type="date"
              placeholder="Date of Birth"
              style={{
                width: '200px', height: 50, paddingLeft: 10, marginRight: 50,
              }}
            />
            <button type="button" className="btn-sm btn-info ml-5 mt-2" onClick={() => this.validateStudentDetail()}>
              <span style={{ fontSize: 18 }}>Validate</span>
            </button>
          </div>
        </div>
      </Layout>
    );
  }
}

const mapStateToProps = ({ studentDashboard }) => ({
  basicStudentData: studentDashboard.basicStudentData,
  recentTests: studentDashboard.recentTests,
  error: studentDashboard.error,
  testData: studentDashboard.testData,
  testStats: studentDashboard.testStats,
  subjects: studentDashboard.subjects,
  performance: studentDashboard.performance,
  isValidate: studentDashboard.isValidate,
});

export default connect(mapStateToProps, { getStudentDetail, validateStudentDetail })(StudentDashboard);
