import React, { Component } from 'react';
import ReactTooltip from 'react-tooltip';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Select from 'react-select';
import _ from 'lodash';
import { isMobileDevice, mathjax } from '../../helpers/util';
import { getPrintQuestions, updateInstitutePaperPreference } from '../../actions';
import { QUESTION_PAPER_VIEW_TYPE, QUESTION_VIEW_CONFIG } from '../../constants';
import { googleAnalyticsPage, sendEvent } from '../../helpers/Analytics';
import Loader from '../loading';
import MobileHeader from '../mobileHeader';
import QuestionView from '../questionView';
import TwoColumnQuestionView from '../twoColumnQuestionView';
import Constants from '../../shared/constants';
import StaticLogo from '../../static/images/logo.png';
import Singleblack from '../../static/images/printAssignment/single_column_black.svg';
import Singlewhite from '../../static/images/printAssignment/single_column_white.svg';
import Doublewhite from '../../static/images/printAssignment/two_column_white.svg';
import Doubleblack from '../../static/images/printAssignment/two_column_black.svg';
import leftAlignBlack from '../../static/images/printAssignment/left_align_black.svg';
import leftAlignWhite from '../../static/images/printAssignment/left_align_white.svg';
import justifyWhite from '../../static/images/printAssignment/justify_white.svg';
import justifyBlack from '../../static/images/printAssignment/justify_black.svg';

const IS_MOBILE = isMobileDevice();

let previousSubjectName = '';
const TRIAL_PRINT_QUESTION_COUNT = 20;
class PrintAssignment extends Component {
  constructor(props) {
    super(props);
    this.state = {
      fontSize: {},
      fontFamily: {},
      questionSpacing: {},
      headerFormat: {},
      columnFormat: {},
      textJustify: '',
      showAnswerKey: false,
      showSolution: false,
      showTopic: false,
      showWatermark: false,
      showInstruction: false,
      marketingMessageType: '',
      selectedTestSet: '',
      showPageLoading: true,
      answerKeyNumber: 1,
      showAnswers: true,
      watermark: '',
      roughWorkPadding: 10,
      economyMode: false,
      addPageBreakAfterEachSubject: false,
    };
    this.renderPageContent = this.renderPageContent.bind(this);
    this.printpage = this.printpage.bind(this);
    this.changeStateValue = _.debounce(this.changeStateValue.bind(this), 500);
  }

  componentDidMount() {
    const query = new URLSearchParams(window.location.search);
    const { id } = this.props.match.params;
    const subjectId = query.get('subject_id') || '';
    const track = query.get('track') || '';
    this.props.getPrintQuestions(id, subjectId, track).then(() => {
      const {
        paperPreference, isAssignment, isReviewed, selectedTestSet,
      } = this.props;
      const isProofread = !isAssignment && !isReviewed;
      this.setState({
        fontSize: paperPreference.fontSize ? typeof paperPreference.fontSize === 'string' ? JSON.parse(paperPreference.fontSize) : paperPreference.fontSize : { value: 16, label: '12' },
        fontFamily: paperPreference.fontFamily ? typeof paperPreference.fontFamily === 'string' ? JSON.parse(paperPreference.fontFamily) : paperPreference.fontFamily : { value: 'sans-serif', label: 'Sans Serif' },
        questionSpacing: paperPreference.questionSpacing ? typeof paperPreference.questionSpacing === 'string' ? JSON.parse(paperPreference.questionSpacing) : paperPreference.questionSpacing : { value: 'mt-4 mb-4', label: 'Normal' },
        headerFormat: paperPreference.headerFormat ? typeof paperPreference.headerFormat === 'string' ? JSON.parse(paperPreference.headerFormat) : paperPreference.headerFormat : { value: 1, label: 'Logo' },
        columnFormat: paperPreference.columnFormat && !isProofread ? parseInt(paperPreference.columnFormat) : isProofread ? QUESTION_PAPER_VIEW_TYPE.ONE_COLUMN : QUESTION_PAPER_VIEW_TYPE.TWO_COLUMN,
        textJustify: paperPreference.textJustify ? parseInt(paperPreference.textJustify, 10) : QUESTION_PAPER_VIEW_TYPE.ALIGN_JUSTIFY,
        showSolution: paperPreference.showSolution && paperPreference.showSolution === 'true',
        showTopic: paperPreference.showTopic && paperPreference.showTopic === 'true',
        showWatermark: !(paperPreference.showWatermark && paperPreference.showWatermark === 'false'),
        showInstruction: paperPreference.showInstruction && paperPreference.showInstruction === 'true',
        marketingMessageType: paperPreference.marketing_message_type,
        watermark: paperPreference.watermark_setting,
        roughWorkPadding: paperPreference.roughWorkPadding
          ? parseInt(paperPreference.roughWorkPadding, 10) : 10,
        showPageLoading: false,
        addPageBreakAfterEachSubject: paperPreference.addPageBreakAfterEachSubject && paperPreference.addPageBreakAfterEachSubject === 'true',
        selectedTestSet: selectedTestSet || '',
      });
      mathjax();
    });
    this.setState({ displayHeight: global.innerHeight });
    // googleAnalyticsPage(router.pathname, router.asPath, 'Print Assignments');
    sendEvent('Assignment', 'PrintAssignmentPageOpened', 'open', { id });
    mathjax();
  }

  componentDidUpdate() {
    mathjax();
  }

  printpage() {
    const id = this.props.match.params;
    sendEvent('Assignment', 'Print', 'Click', { id });
    document.title = this.props.name;
    window.print();
  }

  showWatermark() {
    const { height, opacity, text } = this.state.watermark;
    if (this.props.instituteWatermark && !text) {
      return (
        <div className="printable_test_paper_background">
          <img alt="" src={this.props.instituteWatermark} align="center" height={this.state.displayHeight * height} />
          <style jsx>
            {`
              .printable_test_paper_background {
                display: none;
              }

              @media print {
                .printable_test_paper_background {
                  position: fixed;
                  display: block;
                  top: 50%;
                  left: 50%;
                  transform: translate(-50%, -50%);
                  padding: 15%;
                  z-index: 2;
                  opacity: ${opacity};
                  filter: alpha(opacity=50);
                }
               }
            `}
          </style>
        </div>
      );
    }
    return (
      <div id="background">
        <p className="printable_test_paper_background" id="bg-text">{text || 'IDEABOARD'}</p>
        <style jsx>
          {`
            .printable_test_paper_background {
              display: none;
            }
            @media print {
              .printable_test_paper_background {
                position: fixed;
                display: block;
              }
              #bg-text {
                color:${Constants().color.inactive};
                display: flex;
                justify-content: center;
                align-items: 'center';
                width: 100%;
                font-size: 150px;
                font-family: arial;
                font-weight: bold;
                transform: rotate(310deg);
                position: fixed;
                -webkit-transform: rotate(310deg);
                margin-top: 55%;
                text-align: center;
                opacity: ${opacity};
                z-index: 1;
              }
              #background {
                position:absolute;
                z-index:2;
                display:block;
                min-height:50%;
                min-width:50%;
                text-align:center;
                vertical-align:center;
              }
            }
          `}
        </style>
      </div>
    );
  }

  changeStateValue(type, value) {
    this.setState({ [type]: value }, () => {
      if (['columnFormat', 'showAnswerKey', 'economyMode'].indexOf(type) > -1) {
        mathjax();
      }
    });
    if (type === 'selectedTestSet') {
      this.setState({ showPageLoading: true });
      this.props.getPrintQuestions(this.props.match.params.id, '', '', value.value).then(() => {
        this.setState({ showPageLoading: false });
        mathjax();
      });
    } else {
      const payload = {
        type,
        value: JSON.stringify(value),
      };
      this.props.updateInstitutePaperPreference(payload).then(() => {
        if (this.state.columnFormat === QUESTION_PAPER_VIEW_TYPE.TWO_COLUMN && ['fontSize', 'fontFamily', 'questionSpacing', 'textJustify'].indexOf(type) > -1) {
          window.location.reload();
        }
      });
    }
  }

  renderTopic() {
    const distribution = this.props.questionDistribution;
    const children = [];
    const checkpoints = [];
    Object.keys(distribution).map((subject) => {
      children.push(<b key={`TopicSubject${subject}`} style={{ fontSize: 18 }}>
        {subject}
        :
      </b>);
      { Object.values(distribution[subject]).map((chapter) => {
        children.push(<div key={`TopicChapterData_${chapter.name}`}>
          <b>
            {chapter.name}
          </b>
          -
          {chapter.checkpoints}
                      </div>);
      }); }
      children.push(<br key={`br_${subject}`} />);
    });
    return (
      <div style={{ border: `1px solid ${Constants().color.inactive}`, padding: 10 }}>
        <div style={{ fontSize: 18, fontWeight: 'bold' }}> Topics Covered:</div>
        <div style={{ columnCount: 2, paddingLeft: 10, columnGap: 20 }}>
          {children}
        </div>
      </div>
    );
  }

  showAnswerKeys() {
    const table = [];
    const answers = this.props.questions.map((a) => a.answers);
    const fontSize = this.state.fontSize.value;
    const fontFamily = this.state.fontFamily.value;
    const columnNumberFactor = (QUESTION_VIEW_CONFIG.BASE_NUMBER_OF_ANSWER_KEY_LINE - (fontSize - QUESTION_VIEW_CONFIG.BASE_FONT_SIZE));
    for (let i = 0; i < answers.length; i += columnNumberFactor) {
      const children = [];
      for (let j = 0; j < columnNumberFactor && answers[i + j]; j++) {
        children.push(<td
          key={`AnswerKeyIndex_${i + j + 1}`}
          style={{
            border: '1px solid #000000', display: 'content', fontSize, fontFamily, padding: 5,
          }}
        >
          {`${i + j + 1}) ${answers[i + j].join(', ')}`}
                      </td>);
      }
      table.push(<tr key={`AnswerKeyRowIndex${i}`}>{children}</tr>);
    }
    return table;
  }

  renderTestMetaData() {
    const { testMetaData, testSets } = this.props;
    return (
      <div style={{ position: 'absolute', right: 10 }}>
        <div className="row">
          <div className="col" style={{ whiteSpace: 'nowrap' }}>
            <div>Student ID</div>
            <div>Total Marks</div>
            <div>Duration</div>
          </div>
          <div className="col">
            <div>:</div>
            <div>
              :&nbsp;&nbsp;
              {testMetaData.total_marks}
            </div>
            <div>
              :&nbsp;&nbsp;
              {testMetaData.duration}
            </div>
          </div>
        </div>
        {(testSets.length > 1)
          && (
          <div style={{
            fontSize: 34, fontWeight: 'bold', border: '2px solid', marginTop: 10, borderRadius: 0, width: 80, height: 80, textAlign: 'center', justifyContent: 'center', paddingTop: 10,
          }}
          >
            {this.state.selectedTestSet.label}
          </div>
          )}
      </div>
    );
  }

  renderHeaderFormat(headerFormat) {
    const {
      name, date, instituteLogo, instituteName, testSets, instituteTestHeaderImage, testMetaData, isAssignment,
    } = this.props;
    const { showInstruction, showAnswerKey } = this.state;
    return (
      <div>
        <div className="row ml-2 mb-5" style={{ paddingTop: headerFormat === 3 ? 0 : '5%', width: '100%' }}>
          <div style={{
            textAlign: 'center', width: '100%', justifyContent: 'center', alignItems: 'center', paddingRight: '20%', paddingLeft: '20%',
          }}
          >
            {headerFormat !== 3
              && (
              <div style={{ position: 'absolute', left: 10, width: '20%' }}>
                <img
                  alt=""
                  src={instituteLogo || StaticLogo}
                  style={{
                    display: 'block', width: 'auto', height: 'auto', objectFit: 'contain', maxWidth: '100%', maxHeight: 120,
                  }}
                />
              </div>
              )}
            {headerFormat === 2 && <div style={{ fontWeight: 'bold', fontSize: 34 }}>{instituteName}</div> }
            <div style={{ fontWeight: 'bold', fontSize: 24, textAlign: 'center' }}>{name}</div>
            <div style={{ fontSize: 20 }}>{date}</div>
          </div>
          {testMetaData && this.renderTestMetaData()}
          {((testSets.length > 1) && isAssignment)
            && (
            <div style={{
              position: 'absolute', right: 10, fontSize: 34, fontWeight: 'bold', border: '2px solid', marginTop: 10, borderRadius: 0, width: 80, height: 80, textAlign: 'center', justifyContent: 'center', paddingTop: 10,
            }}
            >
              {this.state.selectedTestSet.label}
            </div>
            )}
        </div>
        <div style={{ marginTop: (testSets.length > 1) ? 100 : 10 }} />
        {(testMetaData && showInstruction && !showAnswerKey)
          && (
          <div style={{ border: `1px solid ${Constants().color.inactive}`, padding: 10, whiteSpace: 'pre-line' }}>
            <div dangerouslySetInnerHTML={{ __html: testMetaData.instructions }} />
          </div>
          )}
      </div>
    );
  }

  renderHeader() {
    const headerFormat = this.state.headerFormat.value;
    if (headerFormat === 1 || headerFormat === 2) {
      return this.renderHeaderFormat(headerFormat);
    }
    const { instituteTestHeaderImage } = this.props;
    return (
      <div>
        <style jsx>
          {
              `@media print {
                .header_image {
                  padding-top: 0px !important;
                }
              }
              .header_image {
                padding-top: 10%;
                display: flex;
              }`
            }
        </style>
        <div className="align-items-center justify-content-center header_image">
          <img alt="" src={instituteTestHeaderImage || StaticLogo} height="120px" />
        </div>
        {this.renderHeaderFormat(headerFormat)}
      </div>
    );
  }

  showSubjectName(subjectId) {
    if (this.props.subjectGroupMapping[subjectId] !== previousSubjectName) {
      const addPageBreak = previousSubjectName !== '' && this.state.addPageBreakAfterEachSubject;
      previousSubjectName = this.props.subjectGroupMapping[subjectId];
      return { subjectName: previousSubjectName, addPageBreak };
    }
    return false;
  }

  getSectionInfo(index) {
    const { selectedTestGroup } = this.props;
    if (selectedTestGroup.is_new_neet_pattern) {
      // New Subject
      if (index % 50 === 0) {
        return {
          showSectionInfo: true,
          sectionHeaderTitle: 'Section A',
          sectionInstruction: 'Attempt all 35 questions',
        };
      }
      if (index % 50 === 35) {
        return {
          showSectionInfo: true,
          sectionHeaderTitle: 'Section B',
          sectionInstruction: 'This section will have 15 questions. Candidate can choose to attempt any 10 questions out of these 15 questions. In case the candidate attempts more than 10 questions, first 10 attempted questions will be considered for marking.',
        };
      }
    }
    return { showSectionInfo: false };
  }

  renderPageContent(isProofread) {
    const {
      name, date, questions, testsClassrooms, testSets,
    } = this.props;
    const {
      showAnswerKey, showTopic, answerKeyNumber, columnFormat, showAnswers, economyMode, textJustify, marketingMessageType,
    } = this.state;
    const fontSize = this.state.fontSize.value;
    const fontFamily = this.state.fontFamily.value;
    const oneColumnSolutionStyle = { marginBottom: 30 };
    const twoColumnSolutionStyle = {
      columnCount: columnFormat, columnGap: 20, columnRule: '1px solid black', marginBottom: 30,
    };
    const answerKeyCopies = [];
    const marketingMessage = {
      APP_PROMOTION: 'Download IdeaBoard from the Play Store and start learning the smart way!',
      IDEABOARD: 'Powered by IdeaBoard',
      NONE: '',
    };
    const classrooms = testsClassrooms.map((classroom) => classroom.name).join(',  ');
    for (let i = 0; i < (showAnswerKey ? answerKeyNumber : 0); i++) {
      answerKeyCopies.push(
        <React.Fragment key={i}>
          <div style={{ marginTop: 50, textAlign: 'center' }}>
            <div style={{ fontSize: fontSize + 3, fontStyle: 'bold', fontFamily }}>{name}</div>
            <div style={{ fontSize, fontFamily }}>{date}</div>
          </div>
          <div style={{
            display: 'flex', justifyContent: 'space-between', marginBottom: 10, alignItems: 'center',
          }}
          >
            {testsClassrooms.length > 0
              && (
              <div style={{ display: 'flex', alignItems: 'baseline' }}>
                <div style={{ marginRight: 15, fontSize, fontWeight: 'bold' }}>
                  Classrooms:
                </div>
                <div style={{ display: 'flex', alignItems: 'center', flexWrap: 'wrap' }}>
                  {classrooms}
                </div>
              </div>
              )}
            {(testSets.length > 1)
              && (
              <div style={{
                fontSize: 34, fontWeight: 'bold', border: '2px solid', marginTop: 30, borderRadius: 0, width: 80, height: 80, textAlign: 'center', justifyContent: 'center', paddingTop: 10, position: 'absolute', right: 10,
              }}
              >
                {this.state.selectedTestSet.label}
              </div>
              )}
          </div>
          <table className="print-friendly" style={{ marginBottom: 30, width: '100%', marginTop: 80 }}>
            <tbody>
              {showAnswers && this.showAnswerKeys()}
            </tbody>
          </table>
          {(this.state.showSolution)
            && (
            <div key={i} style={columnFormat === QUESTION_PAPER_VIEW_TYPE.TWO_COLUMN ? twoColumnSolutionStyle : oneColumnSolutionStyle}>
              <div style={{ fontSize, fontFamily, fontWeight: 'bold' }}> Solution :- </div>
              <table className={` ${columnFormat === QUESTION_PAPER_VIEW_TYPE.TWO_COLUMN && 'two_column_solution'} print-friendly`}>
                {questions.map((question, index) => (
                  <React.Fragment key={`QuestionIndexValue_${question.id}`}>
                    <QuestionView question={question} index={index} fontSize={fontSize} fontFamily={fontFamily} showAnswer={isProofread} columnFormat={columnFormat} showSolution printPage pageType="PRINT_PAGE" />
                  </React.Fragment>
                ))}
              </table>
            </div>
            )}
        </React.Fragment>,
      );
    }
    return (
      <>
        <div className="container" id="preventcopy" style={{ backgroundColor: 'white' }}>
          {!showAnswerKey && this.renderHeader()}
          <hr style={{ margin: showAnswerKey ? 0 : 20 }} />
          {answerKeyCopies}
          {(!showAnswerKey)
            && (
            <>
              {(showTopic && !isProofread) && this.renderTopic()}
              <div id="content">
                {questions.map((question, index) => {
                  const sectionInfoData = this.getSectionInfo(index);
                  return (
                    <React.Fragment key={`QuestionIndexValue_${question.id}_${columnFormat}`}>
                      {
                        columnFormat === QUESTION_PAPER_VIEW_TYPE.TWO_COLUMN && !isProofread
                          ? <TwoColumnQuestionView showOnlyQuestionText={(this.props.numericalQuestionIds.indexOf(question.id) > -1)} economyMode={economyMode} question={question} index={index} marginValue={this.state.questionSpacing.value} fontSize={this.state.fontSize.value} fontFamily={this.state.fontFamily.value} sectionInfoData={sectionInfoData} subjectNameDetails={this.showSubjectName(question.subject_id)} showAnswer={isProofread} textJustify={this.state.textJustify} />
                          : <QuestionView showOnlyQuestionText={(this.props.numericalQuestionIds.indexOf(question.id) > -1)} question={question} index={index} marginValue={this.state.questionSpacing.value} fontSize={this.state.fontSize.value} fontFamily={this.state.fontFamily.value} sectionInfoData={sectionInfoData} subjectNameDetails={this.showSubjectName(question.subject_id)} showAnswer={isProofread} printPage />
                      }
                    </React.Fragment>
                  )
                })}
              </div>
            </>
            )}
        </div>
        {false
          && (
          <div
            className="page-break"
            style={{
              position: 'fixed', zIndex: 5, width: '100%', bottom: '-2mm',
            }}
          >
            <p
              className="space-for-rough"
              style={(this.state.roughWorkPadding === 0) ? { display: 'none' } : {
                backgroundColor: 'white', color: Constants().color.inactive, textAlign: 'center', fontSize: 10, fontWeight: 'bold',
              }}
            >
              SPACE FOR ROUGH WORK
            </p>
            <p
              className="copyright"
              style={{
                textAlign: 'center', color: Constants().color.inactive, fontSize: 10, fontWeight: 'bold',
              }}
            >
              {marketingMessage[marketingMessageType]}
            </p>
          </div>
          )}
        <style jsx>
          {`
            table.print-friendly tr td, table.print-friendly tr th {
               page-break-inside: avoid;
             }
            .page-break { display: none; }
            @page { margin-bottom:${this.state.roughWorkPadding}mm;}
            @media print {
              .page-break {
                display: table;
                page-break-before: always;
                display: block;
                position: absolute;
              }
              .page-break .copyright {
                position: absolute;
                bottom: -20px;
                right: 0%;
              }
              .page-break .space-for-rough {
                position: absolute;
                bottom: -20px;
                left: 45%;
              }
              #content {
                column-count: ${columnFormat};
                column-rule: ${columnFormat === QUESTION_PAPER_VIEW_TYPE.TWO_COLUMN ? '1px solid black' : '0'};
                column-gap: ${columnFormat === QUESTION_PAPER_VIEW_TYPE.TWO_COLUMN ? '20px' : '0'};
              }
            }
          `}
        </style>
      </>
    );
  }

  renderDropDown(label, type, options, selectedValue, width) {
    const customStyles = {
      control: (base, state) => ({
        ...base,
        height: 40,
        cursor: 'pointer',
        backgroundColor: '#ffffff',
        border: `1px solid var(--${Constants().color.inactive}) !important`,
        width,
      }),
      option: (base, state) => ({
        ...base,
        border: '1px solid #F8F8F8',
        color: '#343B40',
        backgroundColor: state.isFocused ? '#DEEBFF' : '#FFFFFF',
      }),
    };
    return (
      <div className="mb-4">
        {label && (
        <div className="mr-2" style={{ fontSize: 16 }}>
          {label}
          :
        </div>
        )}
        <Select
          styles={customStyles}
          value={selectedValue}
          onChange={(event) => this.changeStateValue(type, event)}
          options={options}
          isSearchable={false}
        />
      </div>
    );
  }

  renderCheckbox(label, value, type) {
    return (
      <div className="d-flex flex-row mb-4">
        <input className="cursor" type="checkbox" checked={value} onChange={() => this.changeStateValue(type, !value)} style={{ fontSize: 'x-large', marginTop: 9 }} />
        <label style={{ fontSize: 16, fontWeight: '400', margin: 5 }}>{label}</label>
      </div>
    );
  }

  renderFont() {
    const fontSize = [
      { value: 13, label: '10' },
      { value: 15, label: '11' },
      { value: 16, label: '12' },
      { value: 17, label: '13' },
      { value: 19, label: '14' },
      { value: 21, label: '15' },
      { value: 22, label: '16' },
    ];
    const fontFamily = [
      { value: 'sans-serif', label: 'Sans Serif' },
      { value: 'Times New Roman', label: 'Times New Roman' },
      { value: 'arial', label: 'Arial' },
      { value: 'monospace', label: 'Monospace' },
      { value: 'baskerville', label: 'Baskerville' },
    ];
    return (
      <div className="row" style={{ alignItems: 'flex-end', justifyContent: 'space-between' }}>
        <div className="col-md-7" style={{ paddingRight: 0 }}>
          {this.renderDropDown('Font', 'fontFamily', fontFamily, this.state.fontFamily, '100%')}
        </div>
        <div className="col-md" style={{ paddingLeft: 10 }}>{this.renderDropDown('', 'fontSize', fontSize, this.state.fontSize, '100%')}</div>
      </div>
    );
  }

  renderSets() {
    const { hasAccess, testSets, isAssignment } = this.props;
    const { selectedTestSet } = this.state;
    return (
      <div>
        <label style={{ fontSize: 16, fontWeight: '400', margin: 5 }}>Sets :</label>
        <div className="d-flex" style={{ justifyContent: 'space-around' }}>
          {testSets.map((setType) => (
            <div
              role="presentation"
              onClick={() => this.changeStateValue('selectedTestSet', setType)}
              className={`pl-5 pr-5 ${selectedTestSet.value === setType.value ? 'primary_button' : 'secondary_button'}`}
            >
              {setType.label}
            </div>
          ))}
        </div>
      </div>
    );
  }

  updateRoughWorkPadding(event) {
    this.setState({ roughWorkPadding: event.target.value });
    this.changeStateValue('roughWorkPadding', parseInt(event.target.value));
  }

  renderPrintSettings() {
    const query = new URLSearchParams(window.location.search);
    const { id } = this.props.match.params;
    const { hasAccess, testSets, isAssignment } = this.props;
    const {
      showAnswerKey, showSolution, showTopic, showWatermark,
      columnFormat, showInstruction, showAnswers, textJustify, economyMode,
      addPageBreakAfterEachSubject,
    } = this.state;
    const questionSpacing = [
      { value: 'mt-1 mb-1', label: 'Less' },
      { value: 'mt-4 mb-4', label: 'Normal' },
      { value: 'mt-5 mb-5', label: 'More' },
    ];
    const headerFormat = [
      { value: 1, label: 'Logo' },
      { value: 2, label: 'Logo and Text' },
      { value: 3, label: 'Header Image' },
    ];
    const isOneColumnView = columnFormat === QUESTION_PAPER_VIEW_TYPE.ONE_COLUMN;
    const isTwoColumnView = columnFormat === QUESTION_PAPER_VIEW_TYPE.TWO_COLUMN;

    if (showAnswerKey) {
      return (
        <div>
          {this.renderCheckbox('Show Answer Key', showAnswers, 'showAnswers')}
          {this.renderCheckbox('Show Solution', showSolution, 'showSolution')}
          {hasAccess
            && (
            <div>
              {this.renderCheckbox('Show Watermark', showWatermark, 'showWatermark')}
              <div className="mr-2" style={{ fontSize: 16 }}>Repetitions: </div>
              <input type="number" id="answerKey" name="answerKeyNumber" max="10" min="1" value={this.state.answerKeyNumber} onChange={(event) => this.changeStateValue('answerKeyNumber', event.target.value)} style={{ width: '100%' }} />
              {this.renderFont()}
              {(testSets.length > 1) && this.renderSets()}
              Watermark Opacity:
              <input type="number" min={0} max={100} value={Math.round(this.state.watermark.opacity * 100)} onChange={(event) => this.setState({ watermark: { ...this.state.watermark, opacity: event.target.value / 100 } })} style={{ width: '100%' }} />
            </div>
            )}
        </div>
      );
    } if (!hasAccess) {
      return <div />;
    }
    return (
      <div className={` ${economyMode ? 'hide-settings' : ''}`}>
        {this.renderCheckbox('Show Topics', showTopic, 'showTopic')}
        {this.renderCheckbox('Show Watermark', showWatermark, 'showWatermark')}
        {!isAssignment && this.renderCheckbox('Show Instructions', showInstruction, 'showInstruction')}
        {!isAssignment && this.renderCheckbox('New Page for every Subject', addPageBreakAfterEachSubject, 'addPageBreakAfterEachSubject')}
        <div className="mb-4">
          <div
            className="mr-2"
            style={{
              backgroundColor: 'white', color: 'black', border: 0, fontSize: 14, marginRight: 10,
            }}
          >
            Layout:
          </div>
          <div style={{ alignItems: 'center', zIndex: 2, display: 'flex' }}>
            <button type="button" style={{ marginTop: 10, backgroundColor: isOneColumnView && Constants().color.primary }} onClick={() => this.changeStateValue('columnFormat', QUESTION_PAPER_VIEW_TYPE.ONE_COLUMN)}><img src={isOneColumnView ? Singlewhite : Singleblack} width="24px" alt="one_column" /></button>
            <div className="mr-3" />
            <button type="button" style={{ marginTop: 10, backgroundColor: isTwoColumnView && Constants().color.primary }} onClick={() => this.changeStateValue('columnFormat', QUESTION_PAPER_VIEW_TYPE.TWO_COLUMN)}><img src={isTwoColumnView ? Doublewhite : Doubleblack} width="24px" alt="two_column" /></button>
          </div>
        </div>
        {
          isTwoColumnView
          && (
          <div className="mb-4">
            <div
              className="mr-2"
              style={{
                backgroundColor: 'white', color: 'black', border: 0, fontSize: 14, marginRight: 10,
              }}
            >
              Text Align:
            </div>
            <div style={{ alignItems: 'center', zIndex: 2, display: 'flex' }}>
              <button type="button" style={{ marginTop: 10, backgroundColor: textJustify === QUESTION_PAPER_VIEW_TYPE.ALIGN_LEFT && Constants().color.primary }} onClick={() => this.changeStateValue('textJustify', QUESTION_PAPER_VIEW_TYPE.ALIGN_LEFT)}><img src={textJustify === QUESTION_PAPER_VIEW_TYPE.ALIGN_LEFT ? leftAlignWhite : leftAlignBlack} width="24px" alt="left_justify" /></button>
              <div className="mr-3" />
              <button type="button" style={{ marginTop: 10, backgroundColor: textJustify === QUESTION_PAPER_VIEW_TYPE.ALIGN_JUSTIFY && Constants().color.primary }} onClick={() => this.changeStateValue('textJustify', QUESTION_PAPER_VIEW_TYPE.ALIGN_JUSTIFY)}><img src={textJustify === QUESTION_PAPER_VIEW_TYPE.ALIGN_JUSTIFY ? justifyWhite : justifyBlack} width="24px" alt="full_justify" /></button>
            </div>
          </div>
          )
        }
        {this.renderFont()}
        {this.renderDropDown('Spacing', 'questionSpacing', questionSpacing, this.state.questionSpacing, '100%')}
        {this.renderDropDown('Header', 'headerFormat', headerFormat, this.state.headerFormat, '100%')}
        {(testSets.length > 1) && this.renderSets()}
        Watermark Opacity:
        <input type="number" min={0} max={100} value={Math.round(this.state.watermark.opacity * 100)} onChange={(event) => this.setState({ watermark: { ...this.state.watermark, opacity: event.target.value / 100 } })} style={{ width: '100%' }} />
        Space for Roughwork:
        <input type="number" min={0} max={35} value={this.state.roughWorkPadding} onChange={(event) => this.updateRoughWorkPadding(event)} style={{ width: '100%', marginBottom: 30 }} />
      </div>
    );
  }

  renderPrintSideBar() {
    const { id } = this.props.match.params;
    const { showAnswerKey, columnFormat, economyMode } = this.state;
    const isTwoColumnView = columnFormat === QUESTION_PAPER_VIEW_TYPE.TWO_COLUMN;
    return (
      <div>
        <div
          className="pt-5 pl-5 pr-5 pb-2"
          style={{
            height: showAnswerKey ? '82%' : '90%', display: 'inline-block', overflowY: 'scroll', width: '100%', position: 'absolute',
          }}
        >
          <div className="row mb-5" style={{ justifyContent: 'center' }}>
            <div
              role="presentation"
              className={(!showAnswerKey) ? 'primary_button' : 'secondary_button'}
              onClick={() => this.changeStateValue('showAnswerKey', false)}
              style={{ width: '45%', borderBottomRightRadius: 'inherit', borderTopRightRadius: 'inherit' }}
            >
              Question Paper
            </div>
            <div
              role="presentation"
              className={(showAnswerKey) ? 'primary_button' : 'secondary_button'}
              onClick={() => {
                this.setState({
                  economyMode: false,
                }, () => {
                  this.changeStateValue('showAnswerKey', true);
                });
              }}
              style={{ width: '45%', borderBottomLeftRadius: 'inherit', borderTopLeftRadius: 'inherit' }}
            >
              Answer Key
            </div>
          </div>
          {this.renderPrintSettings()}
        </div>
        <div className="pt-2 pb-2 pl-5 pr-5" style={{ position: 'absolute', bottom: 10, width: '100%' }}>
          {showAnswerKey && (
          <a href={`${Constants().api.url}/tests/${id}/download_answer_key?show_test_type=${this.props.testSets.length > 0}&test_set_id=${this.props.selectedTestSet.value}`} download>
            <div className="secondary_button" style={{ width: '100%' }}> Download Answer Key </div>
          </a>
          )}
          {isTwoColumnView && economyMode && <ReactTooltip />}
          {isTwoColumnView && !showAnswerKey && (
          <div role="presentation" data-tip="Disable Economy Mode to edit" className={` ${economyMode ? 'primary_button' : 'economy-button'}`} style={{ width: '100%' }} onClick={() => this.changeStateValue('economyMode', !economyMode)}>
            { economyMode ? 'Disable Economy Mode' : 'Economy Mode'}
          </div>
          )}
          <div role="presentation" className="primary_button mt-3" style={{ width: '100%' }} onClick={this.printpage}> Print </div>
        </div>
      </div>
    );
  }

  renderTrialFooter() {
    return (
      <div
        className="trial-footer nunito-body"
        style={{
          padding: '30px',
          fontSize: 24,
          textAlign: 'center',
          bottom: 0,
          zIndex: 1,
        }}
      >
        The trial version let's you print the first&nbsp;
        <b>{TRIAL_PRINT_QUESTION_COUNT}</b>
        &nbsp;
        questions only. To print the full question paper,
        <div style={{ display: 'inline-block' }}>
          please contact
          {Constants().contact}
          .
        </div>
        <style jsx>
          {`
            .trial-footer {
              position: fixed !important;
              width: 80%;
              background-color: ${Constants().primaryHeaderTextColor};
              color: #ffd555;
            }

            @media print {
              .trial-footer {
                position: relative !important;
                width: 100%;
                background-color: #fff;
                color: #000;
                font-size: 30px !important;
              }
            }
          `}
        </style>
      </div>
    );
  }

  render() {
    previousSubjectName = '';
    const { showWatermark, showPageLoading, displayHeight } = this.state;
    const { isTrial } = this.props;
    const isProofread = !this.props.isAssignment && !this.props.isReviewed;
    if (showPageLoading) {
      return <Loader />;
    }
    if (IS_MOBILE === '') {
      return <div />;
    }
    if (IS_MOBILE) {
      const { id } = this.props.match.params;
      return (
        <>
          <MobileHeader
            color="white"
            backgroundColor={Constants().color.primary}
            name="Print Page"
            showBackButton
            onImageClick={() => {
              this.props.history.push(`/test_creation_overview/${id}`);
              this.setState({ showPageLoading: true });
            }}
          >
            <div
              role="presentation"
              onClick={this.printpage}
              style={{
                display: 'flex', width: 100, height: 35, borderRadius: 4, backgroundColor: 'white', color: Constants().color.primary, justifyContent: 'center', alignItems: 'center',
              }}
            >
              Print
            </div>
          </MobileHeader>
          {(!isProofread && showWatermark) && this.showWatermark()}
          <div className="printView">
            {this.renderPageContent(isProofread)}
          </div>
          <style jsx>
            {`
              .printView {
                overflow: auto;
                overflowX: hidden;
                margin-top: 70px;
              }
              @media print {
                .printView {
                  width: 100%;
                }
              }
            `}
          </style>
        </>
      );
    }
    return (
      <div style={{ background: '#FFFFFF', height: typeof window !== 'undefined' && window.innerHeight }}>
        {(!isProofread && showWatermark) && this.showWatermark()}
        <head>
          <title>Print Assignments</title>
        </head>
        <div style={{ height: '100%' }}>
          <div
            className="hide-in-print"
            style={{
              height: '100%', width: '25%', position: 'fixed', borderRight: `1px solid ${Constants().color.inactive}`,
            }}
          >
            {this.renderPrintSideBar()}
          </div>
          <div className="printView" style={{ marginLeft: '25%' }}>
            {this.renderPageContent(isProofread)}
            {isTrial && this.renderTrialFooter()}
          </div>
        </div>
        <style jsx>
          {`
            .printView {
              width: 80%;
              margin-left: 25% !important;
              overflow: auto;
              overflowX: hidden;
            }
            @media print {
              .printView {
                width: 100%;
                margin-left: 0px !important;
              }
            }
          `}
        </style>
      </div>
    );
  }
}

PrintAssignment.propTypes = {
  paperPreference: PropTypes.object,
};
PrintAssignment.defaultProps = {
  paperPreference: {},
};

const mapStateToProps = (state) => ({
  questions: state.assignment.isTrial ? state.assignment.questions.slice(0, TRIAL_PRINT_QUESTION_COUNT) : state.assignment.questions,
  numericalQuestionIds: state.assignment.numericalQuestionIds,
  name: state.assignment.testInfo.name,
  date: state.assignment.testInfo.date,
  selectedTestGroup: state.assignment.selectedTestGroup,
  testSets: state.assignment.testSets,
  selectedTestSet: state.assignment.selectedTestSet,
  hasAccess: state.assignment.hasAccess,
  instituteWatermark: state.assignment.instituteWatermark,
  instituteTestHeaderImage: state.assignment.instituteTestHeaderImage,
  instituteLogo: state.assignment.instituteLogo,
  instituteName: state.assignment.instituteName,
  questionDistribution: state.assignment.questionDistribution,
  subjectGroupMapping: state.assignment.subjectGroupMapping,
  paperPreference: state.assignment.paperPreference,
  isReviewed: state.assignment.testInfo.is_reviewed,
  isAssignment: state.assignment.testInfo.is_assignment,
  testMetaData: state.assignment.testMetaData,
  isTrial: state.assignment.isTrial,
  testsClassrooms: state.assignment.testsClassrooms,
});

export default connect(mapStateToProps, { getPrintQuestions, updateInstitutePaperPreference })(PrintAssignment);
