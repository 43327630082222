import React, { Component } from 'react';
import { connect } from 'react-redux';
import Select from 'react-select';
import Pagination from 'react-js-pagination';
import { ExcelExport, ExcelExportColumn } from '@progress/kendo-react-excel-export';
import Layout from '../layout';
import Constants from '../../shared/constants';
import Loader from '../loading';
import Menubar from '../menubar';
import AccessDenied from '../accessDenied';
import '../../styles/instituteOnBoarding.css';
import search from '../../static/images/search.svg';
import {
  getTeacherDetails, manageTeacherDetails, uploadTeacherDetails, uploadVerifiedTreachers,
} from '../../actions';
import TeacherDetailsEditModal from '../modal/teacherDetailsEditModal';
import FileUploaderModal from '../modal/fileUploaderProgress';

import { FILE_TYPE } from '../../constants';

class TeacherDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      importType: 'insert',
      showModal: false,
      teacherDetailsFile: null,
      showLoader: true,
      courseCode: '',
      campusId: '',
      courseId: '',
      classroomId: '',
      searchString: '',
      verified: true,
      displayVerification: false,
      activePage: 1,
      itemsCountPerPage: 10,
      uploadSuccess: false,
      fileUploadProgressStatus: true,
      showOverViewList: false,
      selectedTeachers: [],
      verificationData: {},
    };
    this.renderOverviewList = this.renderOverviewList.bind(this);
    this.userSearch = this.userSearch.bind(this);
    this.updateSearch = this.updateSearch.bind(this);
    this.uploadTeacherDetails = this.uploadTeacherDetails.bind(this);
    this.addOrUpdateTeacherDetails = this.addOrUpdateTeacherDetails.bind(this)
    this.teacherDetailsSave = React.createRef();
  }

  componentDidMount() {
    const query = new URLSearchParams(window.location.search);
    this.props.getTeacherDetails({ itemsCountPerPage: this.state.itemsCountPerPage }, query.get('institute_id')).then(() => this.setState({ showLoader: false }));
  }

  getTeacherDetailsFile() {
    document.getElementById('uploadTeacherDetailsFile').click();
  }

  getRole(permissions) {
    const roles = ['Teacher', 'HOD', 'Dean', 'Admin'];
    return roles.filter((role) => {
      return permissions.indexOf(role) >= 0;
    })[0];
  }

  uploadTeacherDetails(event) {
    const query = new URLSearchParams(window.location.search);
    const instituteId = query.get('institute_id');
    this.setState({
      teacherDetailsFile: event.target.files[0],
      showModal: true,
      fileUploadProgressStatus: true,
    }, () => {
      const file = this.state.teacherDetailsFile ? this.state.teacherDetailsFile
        : event.target.files[0];
      this.props.uploadTeacherDetails(file, this.state.importType, instituteId).then((data) => {
        if (data.success) {
          this.setState({ fileUploaded: true,
            displayVerification: this.props.displayVerification, verified: false });
        }
      });
    });
  }

  fetchTeachersData() {
    const {
      courseId, classroomId, searchString, departmentId, activePage, campusId,
    } = this.state;
    const payload = {
      searchString, departmentId, courseId, classroomId, activePage, campusId,
    };
    const query = new URLSearchParams(window.location.search);
    this.props.getTeacherDetails(payload, query.get('institute_id'));
  }

  userSearch(e) {
    const searchString = e.target.value;
    this.setState({ searchString, activePage: 1 }, () => this.fetchTeachersData());
  }

  updateSearch(selectedItem, payloadType, type) {
    this.setState({ [type]: selectedItem, [payloadType]: selectedItem ? selectedItem.value : '', activePage: 1 }, () => this.fetchTeachersData());
  }

  addOrUpdateTeacherDetails(details) {
    const query = new URLSearchParams(window.location.search);
    details.instituteId = query.get('institute_id');
    this.props.manageTeacherDetails(details).then((response) => {
      if (response && response.success) {
        this.setState({ showModal: false, teacher: '' }, () => this.fetchTeachersData());
      }
    });
  }

  domainSearch(filterItems, selectedItem, selectedFunction, item) {
    if (!filterItems) {
      return true;
    }
    const customStyles = {
      control: (base, state) => ({
        ...base,
        height: 35,
        width: 200,
        backgroundColor: 'white',
        border: '1px solid #CDCFD6 !important',
      }),
      indicatorsContainer: (base, state) => ({
        ...base,
        height: 35,
      }),
      valueContainer: (base, state) => ({
        ...base,
        height: 40,
      }),
      option: (base, state) => ({
        ...base,
        textAlign: 'left',
      }),
    };
    const items = [...filterItems, { id: null, value: '', label: 'All' }];
    return (
      <div style={{ textAlign: 'center', marginLeft: '25px' }}>
        <div style={{ alignItems: 'center', marginLeft: '10px' }}>
          <Select
            styles={customStyles}
            value={selectedItem && selectedItem.value ? selectedItem : { label: item, value: '' }}
            defaultValue={{ label: item, value: 0 }}
            onChange={selectedFunction}
            options={items}
            placeholder="All"
            isClearable={selectedItem && selectedItem.value}
          />
        </div>
      </div>
    );
  }


  handlePageChange(pageNumber) {
    this.setState({ activePage: pageNumber }, () => {
      const {
        courseCode, campusId, classroomId, searchString, activePage, itemsCountPerPage,
      } = this.state;
      const payload = {
        searchString, courseCode, campusId, classroomId, activePage, itemsCountPerPage,
      };
      const query = new URLSearchParams(window.location.search);
      this.props.getTeacherDetails(payload, query.get('institute_id')).then(() => this.setState({ showLoader: false }));
      if (typeof window !== 'undefined') {
        window.scrollTo(0, 0);
      }
    });
  }

  exportFile() {
    const {
      courseId, campusId, classroomId, departmentId,
    } = this.state;
    const payload = {
      departmentId, courseId, campusId, classroomId, isDownload: true,
    };
    const query = new URLSearchParams(window.location.search);
    this.props.getTeacherDetails(payload, query.get('institute_id')).then(() => {
      this.teacherDetailsSave.current.save();
    });
  }

  createExport() {
    const { isHigherEducationInstitute } = this.props;
    const fields = isHigherEducationInstitute ? ['S.No', 'ID', 'Name', 'Email', 'Department', 'Subjects'] : ['S.No', 'ID', 'Name', 'Email', 'Campus', 'Subjects'];
    const fieldIds = isHigherEducationInstitute ? ['index', 'badge_id', 'name', 'email', 'department_names[0]', 'subjects_code_name'] : ['index', 'badge_id', 'name', 'email', 'campuses[0].label', 'subjects_code_name'];
    return {
      fields,
      fieldsIds: fieldIds,
    };
  }

  renderButton(type) {
    return (
      <div
        role="presentation"
        style={{
          height: 35, width: 100, marginLeft: (type !== 'Import') ? 30 : 0, display: 'flex', justifyContent: 'center', alignItems: 'center', border: '1px solid #40A7FF', color: '#40A7FF', borderRadius: 4, cursor: 'pointer',
        }}
        onClick={() => ((type === 'Import') ? this.getTeacherDetailsFile() : this.exportFile(FILE_TYPE.EXCEL))}
      >
        {type}
      </div>
    );
  }

  renderImportedDetails(role, count) {
    return (
      <div>
        <div className="campus-count" style={{ fontSize: 16 }}>
          No of
          {role}
          s:
          {count}
        </div>
      </div>
    );
  }

  renderVerification() {
    const { verificationData } = this.state;
    return (
      <div style={{ padding: '45px 80px 75px 80px' }}>
        <div className="heading-1" style={{ color: '#11426C' }}>Confirm Teachers created</div>
        <div style={{ padding: 40 }}>
          {Object.entries(verificationData).map(([role, count]) => (
            this.renderImportedDetails(role, count)
          ))}
        </div>
      </div>
    );
  }

  renderOverviewList() {
    const {
      teacherDetailsLength, isHigherEducationInstitute,
    } = this.props;
    const {
      showModal, teacher,
    } = this.state;
    const role = teacher && teacher.permissions && teacher.permissions.length ? this.getRole(teacher.permissions) : 'Teacher';
    let ct = 0;
    return (
      <div>
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          {this.renderSearchBar()}
          {this.state.showModal && teacher
            && (
            <TeacherDetailsEditModal
              showModal={showModal}
              handleCloseModal={() => this.setState({ showModal: false, teacher: '' })}
              addOrUpdateTeacherDetails={this.addOrUpdateTeacherDetails}
              teacher={teacher}
              role={role}
              classrooms={this.props.classrooms}
              courses={this.props.courses}
              campuses={this.props.campuses}
              departments={this.props.departments}
              isHigherEducationInstitute={isHigherEducationInstitute}
            />
            )}
        </div>
        <div style={{ padding: '40px 50px', backgroundColor: '#F5F8FA' }}>
          <div className="heading-2" style={{ marginTop: 60 }}>
            Total Teachers :&nbsp;
            {teacherDetailsLength}
          </div>
          <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: 20 }}>
            <div
              role="presentation"
              style={{
                height: 35, width: 140, display: 'flex', justifyContent: 'center', alignItems: 'center', border: '1px solid #CDCFD6', borderRadius: 4, cursor: 'pointer',
              }}
              onClick={() => this.setState({ teacher: 'new', showModal: true })}
            >
              Add Teacher
            </div>
            <div style={{ height: '0px', width: '0px', overflow: 'hidden' }}>
              <input id="uploadTeacherDetailsFile" type="file" name="file" accept=".xlsx" onChange={this.uploadTeacherDetails} />
            </div>
            <div style={{ display: 'flex' }}>
              {this.renderButton('Import')}
              {this.renderButton('Export')}
            </div>
          </div>
          <div className="mt-5">
            <table className="table table-hover" style={{ boxShadow: '1px 2px 6px #8B9DAF33', borderRadius: 4, border: '1px solid #c5c6c7' }}>
              <thead style={{ background: '#F6F8FA', color: '#23394C' }}>
                <tr style={{
                  fontSize: '18px', fontWeight: 700, height: 60, color: '#11426C',
                }}
                >
                  {this.renderTeacherListHeader()}
                </tr>
              </thead>
              <tbody>
                {Object.values(this.props.teacherDetails).map((teacher) => {
                  return this.renderTeacherList(teacher);
                })}
              </tbody>
            </table>
            {Object.values(this.props.teacherDetails) < 1 && (
            <div style={{
              display: 'flex', justifyContent: 'center', alignItems: 'center', color: '#CDCFD6', height: 60, backgroundColor: '#FFFFFF',
            }}
            >
              No results found
            </div>
            )}
            {this.props.teacherDetails.length < 1 && (
            <div style={{
              display: 'flex', justifyContent: 'center', alignItems: 'center', color: '#CDCFD6', height: 60, backgroundColor: '#FFFFFF',
            }}
            >
              No results found
            </div>
            )}
            <style jsx>
              {
                `table, th, td {
                    text-align: center;
                }
                td {
                    vertical-align: middle;
                }
                `
                }
            </style>
          </div>
        </div>
        {Object.values(this.props.teacherDetails).length > 0
            && (
            <div style={{
              display: 'flex', marginTop: 10, justifyContent: 'center', width: '100%',
            }}
            >
              <Pagination
                activePage={this.state.activePage}
                itemsCountPerPage={this.state.itemsCountPerPage}
                totalItemsCount={this.props.teacherDetailsLength}
                pageRangeDisplayed={5}
                onChange={(event) => this.handlePageChange(event)}
              />
            </div>
            )}
      </div>
    );
  }


  renderSubjectDetails(subject, index) {
    const { isHigherEducationInstitute } = this.props;
    if (subject.subject_code && subject.name) {
      return (
        <li key={subject.subject_code}>
          {`${isHigherEducationInstitute ? subject.subject_code : index + 1}: ${subject.name}`}
        </li>
      );
    }
    return ('-');
  }

  renderTeacherList(teacher) {
    const { campuses, isHigherEducationInstitute } = this.props;
    const cellStyle = { textAlign: 'left', verticalAlign: 'top' };
    const departmentNames = teacher.department_names
    && teacher.department_names.length ? teacher.department_names.filter((name) => !!name) : null;
    const teacherCampuses = teacher.campuses;
    return (
      <tr rowSpan={teacher.row_count} style={{ fontSize: '14px', height: 50, marginTop: 150 }}>
        <td role="presentation" className="clickable" style={cellStyle} onClick={() => this.setState({ showModal: true, teacher })}>{teacher.badge_id}</td>
        <td style={{ textAlign: 'left', paddingLeft: 50, verticalAlign: 'top' }}>{teacher.name || '-'}</td>
        <td style={{ textAlign: 'left' }}>{teacher.email || '-'}</td>
        {isHigherEducationInstitute && <td style={{ textAlign: 'left' }}>{departmentNames && departmentNames.length ? departmentNames[0] : '-' }</td>}
        {!isHigherEducationInstitute && <td style={{ textAlign: 'left' }}>{teacherCampuses && teacherCampuses.length ? teacherCampuses[0].label : '-' }</td>}
        <td style={{ textAlign: 'left' }}>
          {teacher.subjects
            ? (
              <ul>
                {teacher.subjects.map((subject, index) => this.renderSubjectDetails(subject, index))}
              </ul>
            )
            : '-'}
        </td>
      </tr>
    );
  }

  renderSearchFilters() {
    const {
      campuses, classrooms, departments, isHigherEducationInstitute, courses,
    } = this.props;
    const {
      campus, department,
    } = this.state;
    let filteredClassrooms = classrooms;
    let filteredCourses = isHigherEducationInstitute ? [] : courses;
    if (department && department.value) {
      filteredCourses = courses.filter((courses) => courses.department_id == department.value);
    }
    if (campus && campus.value) {
      filteredClassrooms = classrooms.filter((classRoom) => classRoom.campus_id == campus.value);
    }
    if (isHigherEducationInstitute) {
      return (
        <>
          {this.domainSearch(departments, this.state.department, (selectedOption) => { this.updateSearch(selectedOption, 'departmentId', 'department'); this.setState({ courseId: '', course: '' }); }, 'Departments')}
          {this.domainSearch(filteredCourses, this.state.course, (selectedOption) => { this.updateSearch(selectedOption, 'courseId', 'course'); this.setState({ classroomId: '' }); }, 'Course')}
        </>
      );
    }
    return (
      <>
        {campuses.length > 1 && this.domainSearch(campuses, this.state.campus, (selectedOption) => { this.updateSearch(selectedOption, 'campusId', 'campus'); this.setState({ classroom: '', classroomId: '' }); }, 'Campus')}
        {this.domainSearch(filteredClassrooms, this.state.classroom, (selectedOption) => this.updateSearch(selectedOption, 'classroomId', 'classroom'), 'Classroom')}
      </>
    );
  }

  renderSearchBar() {
    return (
      <div style={{
        display: 'flex', backgroundColor: '#FFFFFF', height: 60, width: '100%', alignItems: 'center', justifyContent: 'space-between', padding: '40px 50px', zIndex: 2, position: 'fixed', width: '80%',
      }}
      >
        <div style={{
          display: 'flex', justifyContent: 'space-between', width: 350, border: '1px solid #CDCFD6', borderRadius: 4, height: 40,
        }}
        >
          <div style={{ paddingLeft: 15, display: 'flex', alignItems: 'center' }}>
            <img alt="" src={search} width="16" height="16" />
          </div>
          <div style={{ width: 330, display: 'flex', justifyContent: 'space-between' }}>
            <div style={{ width: '100%', display: 'flex', alignItems: 'center' }}>
              <input
                className="input-field"
                id="Search"
                onChange={this.userSearch}
                type="text"
                placeholder="Search..."
                value={this.state.searchString}
                style={{
                  width: '99%', border: 'none', marginBottom: 0, height: 35,
                }}
              />
            </div>
          </div>
        </div>
        <div style={{ display: 'flex' }}>
          {this.renderSearchFilters()}
        </div>
      </div>
    );
  }

  renderTeacherListHeader() {
    const { isHigherEducationInstitute } = this.props;
    const headerStyle = {
      textAlign: 'left', border: '1px solid #c5c6c7', borderRight: '1px solid #dedfe0', paddingLeft: 20,
    };
    return (
      <>
        <th style={{ ...headerStyle, width: '10%' }}>ID</th>
        <th style={{ ...headerStyle, width: '20%', paddingLeft: 50 }}>Name</th>
        <th style={{ ...headerStyle, width: '15%' }}>Email</th>
        {isHigherEducationInstitute && <th style={{ ...headerStyle, width: '20%' }}>Department</th>}
        {!isHigherEducationInstitute && <th style={{ ...headerStyle, width: '20%' }}>Campus</th>}
        <th style={{ ...headerStyle, width: '25%' }}>Subjects</th>
      </>
    );
  }

  render() {
    const { instituteOnBoardingMenu } = Constants();
    const query = new URLSearchParams(window.location.search);
    const instituteId = query.get('institute_id');
    const {
      verified, displayVerification, showOverViewList, showLoader,
      uploadSuccess, showModal, teacherDetailsFile, fileUploadProgressStatus,
    } = this.state;
    const metaData = this.createExport();
    const { fields } = metaData;
    const fieldIds = metaData.fieldsIds;

    instituteOnBoardingMenu.forEach((page) => {
      page.route = `${page.route}${instituteId !== null ? `?institute_id=${instituteId}` : ''}`;
    });
    if (showLoader) {
      return <Loader />;
    }
    if (this.props.permissionedDenied) {
      return (
        <AccessDenied />
      );
    }
    return (
      <Layout globalHistory={this.props.history} hideHeader>
        <div className="custom-checkbox" style={{ width: '100%', display: 'flex', minHeight: '100vh' }}>
          <div style={{ width: '20%', padding: 0, zIndex: 1 }}>
            <Menubar history={this.props.history} menuList={instituteOnBoardingMenu} selectedOption={4} redirectToRoot />
            <FileUploaderModal
              history={this.props.history}
              type="Teacher"
              instituteId={instituteId}
              showModal={(teacherDetailsFile || uploadSuccess) && showModal}
              success={fileUploadProgressStatus}
              route={instituteOnBoardingMenu[3].route}
              handleSuccess={(status) => this.setState({ fileUploadProgressStatus: status })}
              handleCloseModal={(success, data = {}) => {
                this.setState({
                  showModal: false, displayVerification: success, uploadSuccess: success, teacherDetailsFile: success ? this.state.teacherDetailsFile : null, verificationData: data,
                },
                !success ? () => {}
                  : () => {
                    this.setState({ verificationData: data });
                  });
                document.getElementById('uploadTeacherDetailsFile').value = null;
              }}
              showExistingStudents={() => this.setState({ verified: true })}
            />
          </div>
          <ExcelExport
            data={Object.values(this.props.teacherDetailsSave)}
            fileName="TeacherDetails.xlsx"
            ref={this.teacherDetailsSave}
          >
            {fields.map((field, index) => (<ExcelExportColumn field={fieldIds[index]} title={field} />))}
          </ExcelExport>
          <div style={{ width: '80%', backgroundColor: '#F5F8FA' }}>
            {((verified || !displayVerification) || showOverViewList) && this.renderOverviewList()}
            {displayVerification && !verified && this.renderVerification()}
            {displayVerification && !verified
              && (
              <div style={{
                height: 60, bottom: 0, position: 'fixed', width: '80%', zIndex: 2, display: 'flex', justifyContent: 'center', backgroundColor: '#FFFFFF',
              }}
              >
                <div style={{
                  display: 'flex', alignItems: 'center', width: '100%', justifyContent: 'space-around',
                }}
                >
                  <div style={{
                    height: '0px', width: '0px', overflow: 'hidden', display: 'none',
                  }}
                  >
                    <input id="uploadTeacherDetailsFile" type="file" name="file" accept=".xlsx" onChange={this.uploadTeacherDetails} />
                  </div>
                  <div role="presentation" className="verify" style={{ width: 400, border: '1px solid #40A7FF' }} onClick={() => this.getTeacherDetailsFile()}>Re upload</div>
                  <div
                    role="presentation"
                    className="verify"
                    style={{ width: 400, backgroundColor: '#40A7FF', color: '#FFFFFF' }}
                    onClick={() => {
                      this.props.uploadVerifiedTreachers(this.state.importType,
                        instituteId); this.setState({
                        verified: true, displayVerification: false, showModal: true,
                        showOverViewList: true,
                      });
                    }}
                  >
                    Verify
                  </div>
                </div>
              </div>
              )}
          </div>
        </div>
      </Layout>
    );
  }
}

const mapStateToProps = ({ institute }) => ({
  campuses: institute.campuses,
  classrooms: institute.classrooms,
  courses: institute.courses,
  campusesClassrooms: institute.campusesClassrooms,
  studentDetails: institute.studentDetails,
  preferences: institute.preferences,
  permissionedDenied: institute.permissionedDenied,
  departments: institute.departments,
  isHigherEducationInstitute: institute.isHigherEducationInstitute,
  teacherDetails: institute.teacherDetails,
  teacherDetailsSave: institute.teacherDetailsSave,
  teacherDetailsLength: institute.teacherDetailsLength,
});

export default connect(mapStateToProps, {
  getTeacherDetails, manageTeacherDetails, uploadTeacherDetails, uploadVerifiedTreachers,
})(TeacherDetails);
