import React, { Component } from 'react';
import { connect } from 'react-redux';
import Cookies from 'universal-cookie';
import Layout from '../layout';
import Constants from '../../shared/constants';
import { getQuestionSuggestions, uploadQuestionSuggestion } from '../../actions';
import '../../styles/questionSuggestionImport.css';
import imageIcon from '../../static/images/imageIcon.svg';
import Menubar from '../menubar';

const cookies = new Cookies();

class QuestionImageImport extends Component {
  constructor(props) {
    super(props);
    this.state = {
      imageUploaded: false,
      imageFile: null,
      imageFiles: [],
    };
  }

  componentDidMount() {
    const user = cookies.get('user');
    this.props.getQuestionSuggestions(user.id);
  }

  renderListHeader() {
    return (
      <>
        <th
          scope="col"
          className="table-header"
          style={{
            width: '10%', paddingLeft: 20, borderRight: '1px solid #DFE3EA',
          }}
        >
          S.No
        </th>
        <th scope="col" className="table-header" style={{ width: '70%', textAlign: 'center', borderRight: '1px solid #DFE3EA' }}>Image</th>
        <th
          scope="col"
          className="table-header"
          style={{
            width: '20%', paddingLeft: 20, borderRight: '1px solid #DFE3EA',
          }}
        >
          Status
        </th>
      </>
    );
  }

  render() {
    const { questionSuggestionMenu } = Constants();
    const { imageFile, imageUploaded, imageFiles } = this.state;
    const imagesList = this.props.questionSuggestions;
    return (
      <Layout globalHistory={this.props.history} hideHeader>
        <div style={{ width: '100%', display: 'flex' }}>
          <div style={{ width: '20%', padding: 0, zIndex: 1 }}>
            <Menubar history={this.props.history} menuList={questionSuggestionMenu} selectedOption={0} redirectToRoot />
          </div>
          <div style={{ width: '80%' }}>
            <div style={{ width: '80%', margin: 'auto' }}>
              <div className="heading-1" style={{ marginTop: 40 }}>Import Question Suggestions</div>
              <div style={{ height: '30px', overflow: 'hidden' }}>
                <input
                  id="uploadFile"
                  type="file"
                  name="pic"
                  accept="image/*"
                  onChange={(event) => this.setState({
                    imageUploaded: true,
                    imageFile: event.target.files[0],
                  })}
                />
              </div>
              <div style={{
                borderRadius: '4px', border: 'solid 1px #cdcdce', height: '100%', width: '50%', display: 'flex', justifyContent: 'center', padding: 5, position: 'relative',
              }}
              >
                {imageUploaded && imageFile ? <img src={URL.createObjectURL(imageFile)} alt="question-suggestion" style={{ maxWidth: '60%', width: 'fit-content' }} height="fit-content" /> : <img src={imageIcon} alt="question-suggestion" width="50px" height="auto" style={{ margin: 50 }} />}
              </div>
              <button
                type="submit"
                style={{
                  height: 30, width: '50%', backgroundColor: '#40A7FF', color: '#FFFFFF', borderRadius: 4, display: 'flex', justifyContent: 'center', alignItems: 'center', lineHeight: 0, fontSize: 14,
                }}
                onClick={() => {
                  imageFile && this.setState({
                    imageFiles: [...imageFiles, (new File([this.state.imageFile], `image.${this.state.imageFile.type && this.state.imageFile.type.split('/')[1]}`,
                      { type: this.state.imageFile.type }))],
                  }, () => this.props.uploadQuestionSuggestion('', 0, this.state.imageFiles).then(() => { this.setState({ imageUploaded: false, imageFile: '' }); }));
                }}
              >
                Add
              </button>
              <div>
                Note: Images must be of size less than 4mb
              </div>
            </div>
            <div style={{ width: '85%', margin: 'auto' }}>
              <div className="heading-1" style={{ margin: '60px 30px 30px 30px' }}>Your Question Suggestions</div>
              <table
                className="table table-hover"
                style={{
                  margin: '30px 30px 70px 30px', boxShadow: '1px 2px 6px #8B9DAF33', borderRadius: 4, backgroundColor: '#FFFFFF', border: '1px solid #CDCFD6',
                }}
              >
                <thead style={{ background: '#F6F8FA', color: '#23394C' }}>
                  <tr
                    className="table-header"
                    style={{
                      fontSize: '18px', fontWeight: 700, height: 60, color: '#11426C',
                    }}
                  >
                    {this.renderListHeader()}
                  </tr>
                </thead>
                <tbody>
                  {imagesList.map((imageDetail, index) => {
                    if (imageDetail.image) {
                      return (
                        <tr style={{ fontSize: '14px', height: 50 }}>
                          <th
                            scope="col"
                            className="table-header"
                            style={{
                              width: '10%', borderTop: 'none', textAlign: 'left', verticalAlign: 'middle', paddingLeft: 50,
                            }}
                          >
                            {index + 1}
                          </th>
                          <th scope="col" className="table-header" style={{ width: '70%', borderTop: 'none', textAlign: 'center' }}>
                            <img src={imageDetail.image.url} alt="written_question_image" width="400px" height="auto" style={{ margin: 50, verticalAlign: 'middle' }} />
                          </th>
                          <th
                            scope="col"
                            className="table-header"
                            style={{
                              width: '20%', borderTop: 'none', verticalAlign: 'middle', textAlign: 'center',
                            }}
                          >
                            {imageDetail.status}
                          </th>
                        </tr>
                      );
                    }
                  })}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </Layout>
    );
  }
}

const mapStateToProps = ({ questionSuggestionsList }) => ({
  questionSuggestions: questionSuggestionsList.questionSuggestions,
});

export default connect(mapStateToProps, {
  getQuestionSuggestions,
  uploadQuestionSuggestion,
})(QuestionImageImport);
