import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Layout from '../layout';
import VirtualMeeting from '../virtualMeeting';
import FilesBoard from '../filesBoard';
import EndMeeting from '../endMeeting';
import ClassBoardDashboard from '../classBoardDashboard';
import DelayedClasses from '../delayedClasses';
import VideoRecordings from '../videoRecording';
import OfflineTest from '../offlineTest/index';
import VirtualMeetingFeedback from '../virtualMeetingFeedbackReport';
import Attendance from '../attendance';
import Feedback from '../ClassboardFeedback';
import Help from '../help';
import Menubar from '../menubar';
import TimeTable from '../timetable';
import { getUserInfo } from '../../actions';
import { isMobileDevice } from '../../helpers/util';

const IS_MOBILE = isMobileDevice();

export const MENU_DATA = {
  DASHBOARD: { label: 'Dashboard', route: '/classboard/dashboard', type: 'DASHBOARD' },
  VIRTUAL_MEETING: { label: 'Live Classes', route: '/classboard/virtual_meeting', type: 'VIRTUAL_MEETING' },
  TIME_TABLE: { label: 'Time Table', route: '/classboard/time_table', type: 'TIME_TABLE' },
  FILES_BOARD: { label: 'Files', route: '/classboard/files_board', type: 'FILES_BOARD' },
  OFFLINE_TEST: { label: 'Test', route: '/classboard/offline_test', type: 'OFFLINE_TEST' },
  VIDEO_RECORDINGS: { label: 'Video Library', route: '/classboard/video_recordings?id=0&is_subject=true', type: 'VIDEO_RECORDINGS' },
  DELAYED_CLASSES: { label: 'Delayed Classes', route: '/classboard/delayed_classes', type: 'DELAYED_CLASSES' },
  CURRENT_CLASSES: { label: 'Current Classes', route: '/classboard/current_classes', type: 'CURRENT_CLASSES' },
  ATTENDANCE: { label: 'Attendance', route: '/classboard/attendance', type: 'ATTENDANCE' },
  STUDENT_FEEDBACK: { label: 'Student Feedback', route: '/classboard/student_feedback', type: 'STUDENT_FEEDBACK' },
};


class ClassBoard extends PureComponent {
  constructor(props) {
    super(props);
    const { type } = props.match.params;
    this.state = {
      type,
      showLoader: true,
    };
    this.changePropState = this.changePropState.bind(this);
  }

  componentDidMount() {
    this.props.getUserInfo().then(() => {
      this.setState(() => ({ showLoader: false }));
    });
  }

  changePropState(type) {
    this.setState({ type });
  }

  render() {
    const { showLoader } = this.state;
    const { type } = this.props.match.params;
    const { hasDelayedClassAccess, userData, history } = this.props;
    const NEW_MENU_DATA = hasDelayedClassAccess ? {
      ...MENU_DATA,
    }
      : {
        VIRTUAL_MEETING: { label: 'Live Classes', route: '/classboard/virtual_meeting' },
        TIME_TABLE: { label: 'Time Table', route: '/classboard/time_table' },
        FILES_BOARD: { label: 'Files', route: '/classboard/files_board' },
        VIDEO_RECORDINGS: { label: 'Video Library', route: '/classboard/video_recordings?id=0&is_subject=true' },
        OFFLINE_TEST: { label: 'Test', route: '/classboard/offline_test' },
        ATTENDANCE: { label: 'Attendance', route: '/classboard/attendance' },
        STUDENT_FEEDBACK: { label: 'Student Feedback', route: '/classboard/student_feedback' },
      };
    const dataType = type && type.toUpperCase();
    const types = Object.keys(NEW_MENU_DATA);
    const index = types.indexOf(dataType);
    if (showLoader) {
      return <div />;
    }
    return (
      <Layout globalHistory={history} hideHeader title={dataType !== 'DASHBOARD' && MENU_DATA[dataType] ? MENU_DATA[dataType].label : 'Class Board'}>
        <Menubar
          history={history}
          menuList={Object.values(NEW_MENU_DATA)}
          selectedOption={index}
          userData={userData}
          redirectTo="/"
          intimateOnChange
          onChange={this.changePropState}
          renderHeader
          feedback
          help
          showProfileIcon
          openFeedbackModalView={this.openFeedbackModalView}
          openHelpModalView={this.openHelpModalView}
        />
        <style>
          {`
            a:hover, a:visited, a:active {
              color: #454545 !important;
            }  
            input[type=checkbox]:after {
              background-color: #3994AE;
            }
        `}
        </style>
        <div style={{ width: IS_MOBILE ? '100%' : '80%', marginLeft: IS_MOBILE ?  '' : '20%', userSelect: 'none' }}>
          {hasDelayedClassAccess && dataType === 'DASHBOARD' && <ClassBoardDashboard />}
          {dataType === 'VIRTUAL_MEETING' && <VirtualMeeting />}
          {dataType === 'FILES_BOARD' && <FilesBoard onClass />}
          {dataType === 'VIDEO_RECORDINGS' && <VideoRecordings history={history} />}
          {dataType === 'OFFLINE_TEST' && <OfflineTest onClass history={history} />}
          {dataType === 'ATTENDANCE' && <Attendance type={type} />}
          {dataType === 'TIME_TABLE' && <TimeTable />}
          {dataType === 'STUDENT_FEEDBACK' && <VirtualMeetingFeedback />}
          {hasDelayedClassAccess && dataType === 'DELAYED_CLASSES' && <DelayedClasses history={history} />}
          {hasDelayedClassAccess && dataType === 'CURRENT_CLASSES' && <VirtualMeeting showCurrentClass />}
          {dataType === 'FEEDBACK' && <Feedback />}
          {dataType === 'HELP' && <Help />}
          {dataType === 'END_MEETING' && <EndMeeting showCurrentClass />}
        </div>
      </Layout>
    );
  }
}

ClassBoard.propTypes = {
  router: PropTypes.object,
  hasDelayedClassAccess: PropTypes.bool,
  userData: PropTypes.object,
  isHigherEducation: PropTypes.bool,
};

ClassBoard.defaultProps = {
  router: { query: {} },
  hasDelayedClassAccess: false,
  userData: {},
  isHigherEducation: false,
};

const mapStateToProps = ({ virtualMeeting }) => ({
  hasDelayedClassAccess: virtualMeeting.hasDelayedClassAccess,
  userData: virtualMeeting.user,
  isHigherEducation: virtualMeeting.isHigherEducation,
});

export default connect(mapStateToProps, { getUserInfo })(ClassBoard);
