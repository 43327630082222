import React from "react";
import moment from "moment";
import { exportComponentAsJPEG } from "react-component-export-image";
import Ceal from "../../static/images/Ceal.jpg";
import Signature from "../../static/images/Signature.jpg";
import { isMobileDevice } from "../../helpers/util";

const IS_MOBILE = isMobileDevice();

class NOCFormat2 extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
    this.componentRef = React.createRef();
  }
  renderFormat() {
    const { fullName, proofNumber, poi, address, phoneNumber } =
      this.props.data;
    return (
      <div
        style={{
          width: !IS_MOBILE && 793,
          height: !IS_MOBILE && 1123,
          padding: 20,
        }}
        ref={this.componentRef}
      >
        <div
          style={{
            fontSize: 40,
            fontWeight: "bolder",
            color: "brown",
            textAlign: "center",
            borderBottom: "5px solid brown",
          }}
        >
          BLR ENVISION ACADEMY LLP
        </div>
        <div style={{ textAlign: "right", marginRight: 20 }}>
          Date : {moment().format("DD/MM/YYYY")}
        </div>
        <div style={{ marginTop: 10 }}>
          Reliance Jio Infocomm Limited India.
        </div>
        <div style={{ marginTop: 10 }}>
          <strong>
            Subject: E-NOC for Transfer of Ownership of Corporate Connection to
            Personal
          </strong>
        </div>
        <div style={{ marginTop: 10 }}>To whomsoever it may concern,</div>
        <div style={{ marginTop: 10 }}>
          This is to certify that the following mobile number is under our Jio
          Corporate (Enterprise) connection and is being used by our Staff and
          Students.
        </div>
        <div style={{ marginTop: 10 }}>
          You are requested to discontinue the mentioned number from our Jio
          Corporate (Enterprise) connection and convert the same into self-paid
          connection in favor of the applicant.
        </div>
        <div style={{ marginTop: 10 }}>
          We have No Objection' to transfer the given mobile number into the
          individual name with user documentation.
        </div>
        <div style={{ marginTop: 10 }}>
          The user will submit documents to you for migration of the current
          mobile number.
        </div>
        <table className="table-bordered" style={{ marginTop: 20 }}>
          <tbody>
            <tr>
              <td>
                <p>NAME</p>
              </td>
              <td>{fullName}</td>
            </tr>
            <tr>
              <td>
                <p>{poi.replace("_", "  ")}</p>
              </td>
              <td>{proofNumber}</td>
            </tr>
            <tr>
              <td>
                <p>POI ADDRESS</p>
              </td>
              <td>{address}</td>
            </tr>
            <tr>
              <td>
                <p>PHONE NUMBER</p>
              </td>
              <td>{phoneNumber}</td>
            </tr>
          </tbody>
        </table>
        <p>
          <br />
          <br />
        </p>
        <div style={{ display: "flex", alignItems: "center" }}>
          <div>
            <img src={Signature} width={"230px"} height={"110px"}></img>
          </div>
          <div style={{ marginLeft: 20 }}>
            <img src={Ceal} width={"100px"} height={"100px"}></img>
          </div>
        </div>
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <div>Authorized Signatory : Idamakanti Lakshmireddy</div>
          <div>Date:{moment().format("DD/MM/YYYY")} </div>
          <div>Bengaluru</div>
        </div>
        <div style={{ marginTop: 10 }}>
          <strong>BLR Envision Academy LLP</strong>
        </div>
        <div>4,9th B Main,Muni Reddy Layout Banaswadi&nbsp;</div>
        <div>Bangalore 560043</div>
      </div>
    );
  }
  render() {
    return (
      <div>
        {this.renderFormat()}{" "}
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <button
            style={{ marginTop: 20 }}
            onClick={() => {
              this.componentRef.current.style.width = "793px";
              this.componentRef.current.style.height = "1123px";
              exportComponentAsJPEG(this.componentRef);
            }}
          >
            Download
          </button>
        </div>
      </div>
    );
  }
}
export default NOCFormat2;
