import React from 'react';
import { connect } from 'react-redux';

import Campus from './campus';
import Layout from '../layout';
import Menubar from '../menubar';
import Classroom from './classroom';
import AccessDenied from '../accessDenied';
import constants from '../../shared/constants';

const TABS = {
  CAMPUSES: Campus,
  CLASSROOMS: Classroom,
};

class CampusAndClassroom extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      selectedTab: 'CAMPUSES',
    };
  }

  renderTabs() {
    const { selectedTab } = this.state;
    return (
      <div style={{ margin: '10px 0px', display: 'flex' }}>
        {Object.keys(TABS).map((tab) => (
          <div
            key={tab}
            style={{
              padding: 10,
              border: '1px solid #007fd2',
              color: selectedTab === tab ? 'white' : '#007fd2',
              backgroundColor: selectedTab === tab ? '#007fd2' : 'white',
              cursor: 'pointer',
            }}
            onClick={() => this.setState({ selectedTab: tab })}
          >
            {tab}
          </div>
        ))}
      </div>
    );
  }

  render() {
    const { selectedTab } = this.state;
    const { permissionedDenied, history } = this.props;
    const { instituteOnBoardingMenu } = constants();
    const query = new URLSearchParams(window.location.search);
    const instituteId = query.get('institute_id');
    instituteOnBoardingMenu.forEach((page) => {
      page.route += `${instituteId !== null ? `?institute_id=${instituteId}` : ''}`;
    });
    if (permissionedDenied) {
      return (
        <AccessDenied />
      );
    }
    let innerHeight = null;
    if (typeof window !== 'undefined') {
      innerHeight = window.innerHeight;
    }
    const Tab = TABS[selectedTab];
    return (
      <Layout globalHistory={history} hideHeader>
        <div style={{
          width: '100%', display: 'flex', fontFamily: 'Nunito Sans', backgroundColor: '#F5F8FA', minHeight: innerHeight,
        }}
        >
          <div style={{ width: '20%', padding: 0, zIndex: 1 }}>
            <Menubar
              history={history}
              menuList={instituteOnBoardingMenu}
              selectedOption={2}
              redirectToRoot
            />
          </div>
          <div style={{ width: '80%', padding: 30 }}>
            {this.renderTabs()}
            <Tab />
          </div>
        </div>
      </Layout>
    );
  }
}

const mapStateToProps = ({ institute }) => ({
  campusesClassrooms: institute.campusesClassrooms,
  departmentCourseClassrooms: institute.departmentCourseClassrooms,
  isHigherEducationInstitute: institute.isHigherEducationInstitute,
  permissionedDenied: institute.permissionedDenied,
});

export default connect(mapStateToProps, {
})(CampusAndClassroom);
