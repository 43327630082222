export const DIFFICULTY_VALUE_MAPPING = [null, 'EASY', 'EASY', 'MEDIUM', 'HARD', 'HARD'];

export const ERROR_MAPPING = ['QUESTIONS', 'TOPICS'];

export const PROBLEM_TYPES = ['question_text', 'option_1', 'option_2', 'option_3', 'option_4', 'solution_text'];

export const MATHJAX_SCRIPT = 'https://cdnjs.cloudflare.com/ajax/libs/mathjax/2.7.2/MathJax.js?config=AM_HTMLorMML';

export const MODAL_TEXT = {
  SUBMIT: 'Are you sure you want to Submit?',
  GO_BACK: 'Are you sure you need to go back?\n Your changes will be lost',
};

export const DIFFICULTY = {
  EASY: 'E',
  MEDIUM: 'M',
  HARD: 'H',
};
export const DIFFICULTY_COLOR = {
  EASY: '#49BEB7',
  MEDIUM: '#FFB038',
  HARD: '#FF5F5F',
};
export const INSIGHTS = {
  PROPERTIES: 'properties',
  REPEATED: 'repeated',
  DISTRIBUTION: 'distribution',
  SIMILAR: 'similar',
  REVIEW: 'review',
};

export const PAGETYPE = {
  OVERVIEW: 0,
  EDIT: 1,
  PICKQUESTION: 2,
  REVIEW: 3,
  INSIGHTS: 4,
  PRINT: 5,
};

export const REPORT_ISSUE_TYPE = {
  NO_ISSUE: 0,
  QUESTION_TEXT: 5,
  SOLUTION_TEXT: 6,
  IMAGE: 7,
  ANSWER: 8,
  MULTIPLE: 9,
};

export const QUESTION_TYPES = {
  FOUR_OPTIONS: 0,
  NUMERICAL: 1,
  MULTIPLE_OPTION: 2,
  COMPREHENSION: 3,
};

export const REPORT_ISSUE_TOAST_MESSAGES = {
  NOT_SELECTED: 'Atleast one change should be done before updating and Question data should not be empty',
  UPDATED: 'Question Updated Successfully',
  PROGRESS: 'Questions are getting Uploaded',
  SELECT_SUBJECT: 'Subject Should Be Selected',
  OPTION_SELECTED: 'All Four Options Should Be Filled',
  QUESTION_TEXT: 'Question Text Should Not Be Empty',
  ANSWERS: 'Atleast One Option Should Be Selected',
  NOT_UPDATED: 'Failed To Update A Question',
  SELECTED: 'Issues will be resolved soon !',
  UPLOAD_ERROR: 'Failed To Upload Questions',
  ERRORS: 'Solve all errors mentioned before uploading',
  VERIFIED: 'Questions Are Verified, It Will Be Uploaded Soon',
  NOTVERIFIED: 'Uploaded Data is Being Verified',
};

export const FEEDBACK_FEATURES_TEXT_POSITIVE = [
  'Quality of questions is good',
  'Auto-pick gave me a quality paper',
  'I have lots of questions to choose from',
  'The interface is simple',
];
export const FEEDBACK_FEATURES_TEXT_NEGATIVE = [
  'Questions have too many errors',
  'Auto-pick did not give a quality paper',
  'I need more questions in some topics',
  'The interface is complex',
];

export const FILTERTYPES = {
  SELECTEDCHAPTER: 'chapter',
  SELECTEDTOPIC: 'checkpoint',
  SELECTEDSUBTOPIC: 'sub_checkpoint',
  CHAPTERS: 'chapters',
  TOPICS: 'checkpoints',
  SUBTOPICS: 'sub_checkpoints',
  DIFFICULTY: 'difficulty',
  QUESTION_TYPE: 'question_type',
  PREVIOUS_PAPER: 'previous_paper',
};

export const QUESTION_PAPER_VIEW_TYPE = {
  ONE_COLUMN: 1,
  TWO_COLUMN: 2,
  ALIGN_LEFT: 3,
  ALIGN_JUSTIFY: 4,
};

export const QUESTION_VIEW_CONFIG = {
  MAX_IMAGE_WIDTH: 300,
  LENGTH_OF_CHARACTER_ONE_LINE: 60,
  LENGTH_OF_CHARACTER_TWO_LINE: 100,
  BASE_FONT_SIZE: 10,
  LENGHT_FACTOR: 7,
  BASE_NUMBER_OF_ANSWER_KEY_LINE: 21,
  IMAGE_MAX_WIDTH_OPTION_LAYOUT_1: 80,
  IMAGE_MAX_WIDTH_OPTION_LAYOUT_2: 160,
  IMAGE_MAX_WIDTH_OPTION_LAYOUT_4: 300,
  MAX_WIDTH_OPTION_LAYOUT_1: 100,
  MAX_WIDTH_OPTION_LAYOUT_2: 200,
  MAX_WIDTH_OPTION_LAYOUT_4: 451,
};

export const OUTER_WIDTH_QUESTON_OPTIONS = 730;
