import React from "react";
import { connect } from "react-redux";
import { createBrowserHistory } from "history";
import PropTypes from "prop-types";
import Constants from "../../shared/constants";
import Pagination from "react-js-pagination";
import AddApplicant from "./addApplicant";
import { getApplicantsList, uploadApplicants } from "../../actions";
import Select from "react-select";
import Loader from "../loading";
import Modal from "../modal/baseModal";
import { UUID } from "../../helpers/util";
const TABS = {
  All: "All",
  Lead: "Lead",
  Interested: "Interested",
  "Advance Paid": "Advance_paid",
  Admitted: "Admitted",
  Canceled: "Canceled",
  "Not Interested": "Not_intereseted",
  Junk: "Junk",
};

const history = createBrowserHistory({
  forceRefresh: true,
});

const customDropDownStyles = {
  control: (base, state) => ({
    ...base,
    height: 35,
    width: 200,
    backgroundColor: "white",
    border: "1px solid #CDCFD6 !important",
  }),
  indicatorsContainer: (base, state) => ({
    ...base,
    height: 35,
  }),
  valueContainer: (base, state) => ({
    ...base,
    height: 40,
  }),
  option: (base, state) => ({
    ...base,
    textAlign: "left",
  }),
};

class ApplicantsList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedTab: "All",
      searchText: "",
      showModal: false,
      selectedCampus: null,
      selectedTag: null,
      page: 1,
      perPage: 10,
      showImportModal: false,
      message: [],
      loader: false,
    };
  }

  componentDidMount() {
    this.props.getApplicantsList();
  }

  renderTabs() {
    const { selectedTab } = this.state;
    const { applicants } = this.props;
    const count = applicants.reduce((acc, applicant) => {
      acc[applicant.status.replace("_", " ")] =
        acc[applicant.status.replace("_", " ")] + 1 || 1;
      return acc;
    },{});
    return (
      <div style={{ margin: "10px 0px", display: "flex" }}>
        {Object.keys(TABS).map((tab) => (
          <div
            key={tab}
            style={{
              padding: "5px 10px",
              border: `1px solid ${Constants().color.primary}`,
              color: selectedTab === tab ? "white" : Constants().color.primary,
              backgroundColor:
                selectedTab === tab ? Constants().color.primary : "white",
              cursor: "pointer",
              minWidth: 130,
              textAlign: "center",
            }}
            onClick={() => this.setState({ selectedTab: tab, page: 1 })}
          >
            {tab}
            <div>{tab==='All'? applicants.length : count[tab.toUpperCase()] || 0 }</div>
          </div>
        ))}
      </div>
    );
  }

  renderDropDown(
    filterItems,
    selectedItem,
    selectedFunction,
    item,
    defaultValue = ""
  ) {
    const defaultSelectedValue = defaultValue || { label: item, value: 0 };
    return (
      <div>
        <Select
          styles={customDropDownStyles}
          value={selectedItem || defaultSelectedValue}
          onChange={selectedFunction}
          options={filterItems}
          placeholder={item}
          maxMenuHeight={150}
          required
        />
      </div>
    );
  }

  renderFilters() {
    const { searchText, selectedCampus, selectedTag } = this.state;
    const { campuses, tags } = this.props;
    return (
      <div
        style={{
          marginTop: 30,
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <input
          type="text"
          value={searchText}
          placeholder="Search Applicant ID/Name"
          onChange={(event) =>
            this.setState({ searchText: event.target.value })
          }
          style={{ width: 220 }}
        ></input>
        {this.renderDropDown(
          campuses,
          selectedCampus,
          (event) =>
            this.setState({
              selectedCampus: event,
            }),
          "Campuses",
          ""
        )}
        {this.renderDropDown(
          tags,
          selectedTag,
          (event) =>
            this.setState({
              selectedTag: event,
            }),
          "Tags",
          ""
        )}
      </div>
    );
  }

  renderApplicantCreation() {
    return (
      <div
        style={{ width: 150, marginTop: 30 }}
        className="Button"
        onClick={() => this.setState({ showModal: true })}
      >
        New Applicant
      </div>
    );
  }

  renderApplicantForm() {
    return (
      <AddApplicant
        showModal={this.state.showModal}
        handleCloseModal={() => this.setState({ showModal: false })}
        applicant={{}}
        edit={false}
      />
    );
  }

  getApplicantListFile() {
    document.getElementById("uploadEmployeeListFile").click();
  }

  async uploadApplicantList(event) {
    const fileList = event.target.files;
    const files = Object.values(fileList);
    const sendFiles = files.map((file) => {
      return new File([file], `${UUID()}.xlsx`, { type: file.type });
    });
    this.setState({ loader: true });
    const response = await this.props.uploadApplicants(sendFiles);
    if (!response.success) {
      this.setState({ message: response.message || [] });
    } else {
      this.setState({ message: [] });
    }
    this.setState({ loader: false });
  }

  renderImportEmployeesButton() {
    return (
      <div
        className="Button primary-Button"
        style={{ width: 200, margin: "auto 15px" }}
        onClick={() => {
          this.setState({ showImportModal: true });
        }}
      >
        Import Applicants
      </div>
    );
  }

  renderImportApplicantsModal() {
    const { showImportModal, message, loader } = this.state;
    return (
      <Modal
        showModal={showImportModal}
        handleCloseModal={() => this.setState({ showImportModal: false })}
        width={"20%"}
        header={"Insert Applicants"}
      >
        {loader ? (
          <Loader height={10} />
        ) : (
          <div style={{ width: "100%", padding: 20 }}>
            <a
              download
              target="_blank"
              href="/assets/admission_board_format.xlsx"
              style={{ width: "100%" }}
            >
              <div
                className="Button secondary-Button"
                style={{ width: "100%", marginBottom: 10 }}
                onClick={() => {}}
              >
                Download .xslx
              </div>
            </a>
            <div
              className="Button primary-Button"
              style={{ width: "100%" }}
              onClick={() => this.getApplicantListFile()}
            >
              Insert data
            </div>
            <div style={{ height: "0px", width: "0px", overflow: "hidden" }}>
              <input
                id="uploadEmployeeListFile"
                type="file"
                name="result"
                accept=".xlsx"
                multiple="false"
                onChange={(event) => this.uploadApplicantList(event)}
              />
            </div>
            {message.length !== 0 && (
              <div style={{ marginTop: 20 }}>
                {message.map((error, index) => {
                  return (
                    <div style={{ color: "red" }}>
                      <span>{index + 1}</span>
                      <span style={{ paddingLeft: 10 }}>{error}</span>
                    </div>
                  );
                })}
              </div>
            )}
          </div>
        )}
      </Modal>
    );
  }

  renderApplicants() {
    const { applicants } = this.props;
    const {
      selectedCampus,
      selectedTag,
      selectedTab,
      page,
      perPage,
      searchText,
    } = this.state;
    let filteredApplicants = !!searchText
      ? applicants.filter((applicant) => {
          return (
            (!!applicant.application_id &&
              applicant.application_id.indexOf(searchText) !== -1) ||
            (!!applicant.name &&
              applicant.name.toLowerCase().includes(searchText.toLowerCase()))
          );
        })
      : applicants;

    if (selectedCampus) {
      filteredApplicants = filteredApplicants.filter(
        (applicant) => applicant.campus_id === selectedCampus.value
      );
    }
    if (selectedTag) {
      filteredApplicants = filteredApplicants.filter((applicant) =>
        applicant.tag_ids.includes(selectedTag.value)
      );
    }
    if (selectedTab !== "All") {
      filteredApplicants = filteredApplicants.filter((applicant) => {
        return applicant.status.replace("_", " ") === selectedTab.toUpperCase();
      });
    }
    if (filteredApplicants.length === 0) {
      return <div style={{ textAlign: "center" }}>No applicants</div>;
    }
    return (
      <>
        <table className="table-bordered" style={{ marginTop: 10 }}>
          <thead>
            <th>S.No</th>
            <th>Name</th>
            <th>Personal Phone</th>
            <th>Program</th>
            <th>Tag</th>
            <th>Campus</th>
            <th>Father's Name</th>
            <th>Father's phone</th>
          </thead>
          <tbody>
            {filteredApplicants
              .slice((page - 1) * perPage, page * perPage)
              .map((applicant, index) => {
                return (
                  <tr key={index}>
                    <td>{(page - 1) * 10 + index + 1}</td>
                    <td
                      style={{ cursor: "pointer" }}
                      onClick={() => {
                        history.push(
                          `/admission_board/applicant_profile?id=${applicant.id}`
                        );
                        window.location.reload();
                      }}
                    >
                      {applicant.name}
                    </td>
                    <td>{applicant.phone}</td>
                    <td>{applicant.program}</td>
                    <td>{applicant.tag_names?.join(",")}</td>
                    <td>{applicant.campus_name}</td>
                    <td>{applicant.father_name}</td>
                    <td>{applicant.father_phone}</td>
                  </tr>
                );
              })}
          </tbody>
        </table>
        <div style={{ display: "flex", justifyContent: "center" }}>
          <Pagination
            activePage={page}
            itemsCountPerPage={perPage}
            totalItemsCount={filteredApplicants.length}
            pageRangeDisplayed={10}
            onChange={(pageNumber) => this.setState({ page: pageNumber })}
          />
        </div>
      </>
    );
  }
  renderButtons() {
    return (
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        {this.renderApplicantCreation()}
        {this.renderImportEmployeesButton()}
      </div>
    );
  }

  render() {
    if (this.props.isLoading) {
      return <Loader />;
    }
    return (
      <div style={{ marginLeft: "20%", padding: 30 }}>
        {this.renderTabs()}
        {this.renderButtons()}
        {this.renderFilters()}
        {this.renderApplicants()}
        {this.state.showModal && this.renderApplicantForm()}
        {this.state.showImportModal && this.renderImportApplicantsModal()}
      </div>
    );
  }
}

ApplicantsList.propTypes = {
  applicants: PropTypes.array,
};

ApplicantsList.defaultProps = {
  applicants: [],
};

const mapStateToProps = ({ admissionBoard }) => ({
  applicants: admissionBoard.applicants,
  tags: admissionBoard.tags,
  campuses: admissionBoard.campuses,
  isLoading: admissionBoard.isLoading,
});

export default connect(mapStateToProps, {
  getApplicantsList,
  uploadApplicants,
})(ApplicantsList);
