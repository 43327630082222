export const genericError = 'Some issue has occured. Please check with developer';

export const SUBJECT_LIST = [{ value: 4, label: 'Zoology' }, { value: 5, label: 'Botany' }, { value: 7, label: 'General Knowledge' }, { value: 8, label: 'Reasoning' }, { value: 9, label: 'English' }, { value: 1, label: 'Maths' }, { value: 2, label: 'Physics' }, { value: 3, label: 'Chemistry' }, { value: 15, label: 'Science' }];

export const IMAGE_UPDATE_QUICKPOINT = {
  NO_CHANGE: 0,
  UPDATED: 1,
  CHANGED: 2,
  DELETED: 3,
};
