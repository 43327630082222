import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import ReactTooltip from 'react-tooltip';
import Layout from '../layout';
import Modal from '../modal/baseModal';
import Constants from '../../shared/constants';
import Loading from '../loading';
import { isMobileDevice, getLogoutUrl } from '../../helpers/util';
import MobileHeader from '../mobileHeader';
import { PAGETYPE } from '../../constants';
import xMark from '../../static/images/close-mark.svg';
import { getTestDistribution, generateAutoTest } from '../../actions';
import { sendEvent } from '../../helpers/Analytics';
import backButton from '../../static/images/backButton.svg';

const DIFFICULTY = [
  { value: '2', label: 'Easy' },
  { value: '3', label: 'Medium' },
  { value: '4', label: 'Hard' },
];
const maxCustomDistributionCount = 100;
const customDifficulty = '7';
const IS_MOBILE = isMobileDevice();

class DistributionSelection extends React.Component {
  constructor(props) {
    super(props);
    this.inputDifficultyRef = {
      Easy: React.createRef(),
      Medium: React.createRef(),
      Hard: React.createRef(),
    };
    this.state = {
      showPageLoading: true,
      isBackNavigation: false,
      showDifficultyModal: false,
      selectedDifficulty: DIFFICULTY[1].value,
      checkpointRange: [],
      startTime: new Date(),
      customDifficultyDistribution: {
        Easy: '',
        Medium: '',
        Hard: '',
      },
      isCustomDistribution: false,
      showErrorMessage: false,
      totalDistributionCount: 0,
      rangeDifference: [],
    };
    this.updateCustomDifficultyDistribution = this.updateCustomDifficultyDistribution.bind(this);
    this.showButton = this.showButton.bind(this);
  }

  componentDidMount() {
    const query = new URLSearchParams(window.location.search);
    const { id } = this.props.match.params;
    const subjectId = query.get('subject_id');
    const track = query.get('track');
    this.props.getTestDistribution(id, subjectId && subjectId !== 'undefined' ? subjectId : '', track).then(() => {
      const { selectedDistributionRanges, questionLimit } = this.props;
      const rangeDifference = this.getRangeDifference(selectedDistributionRanges,
        questionLimit);
      this.setState({ checkpointRange: selectedDistributionRanges, rangeDifference });
      this.setState(() => ({ showPageLoading: false }));
    });
  }

  getRangeDifference(checkpointRange, questionLimit) {
    const rangeCount = Object.values(checkpointRange).reduce((a, b) => parseInt(a, 10) + parseInt(b, 10), 0);
    return questionLimit - rangeCount;
  }

  getTimeSpent(startTime = this.state.startTime) {
    return ((new Date() - startTime) / (1000 * 60)).toFixed(2); // Time spent in minutes
  }

  showButton(key) {
    this.inputDifficultyRef[key].current.focus();
  }

  updateCustomDifficultyDistribution(customDifficultyType, customDifficultyValue) {
    const { customDifficultyDistribution } = this.state;
    if (customDifficultyValue <= maxCustomDistributionCount) {
      let currentDistributionCount = 0;
      const currentDistribution = {
        ...this.state.customDifficultyDistribution,
        [customDifficultyType]: parseInt(customDifficultyValue),
      };
      currentDistributionCount = Object.values(currentDistribution).reduce((t = 0, value) => t + parseInt(value || 0), 0);
      customDifficultyDistribution[customDifficultyType] = (customDifficultyValue);
      if ((maxCustomDistributionCount - currentDistributionCount) >= 0) {
        this.setState({
          customDifficultyDistribution,
          totalDistributionCount: currentDistributionCount,
          showErrorMessage: false,
        });
      } else {
        this.setState({
          customDifficultyDistribution,
          totalDistributionCount: currentDistributionCount,
          showErrorMessage: true,
        });
      }
    }
  }

  toggleDifficultyModal() {
    const { customDifficultyDistribution } = this.state;
    const query = new URLSearchParams(window.location.search);
    const { id } = this.props.match.params;
    const subjectId = query.get('subject_id');
    const track = query.get('track');
    const showDifficultyModal = !this.state.showDifficultyModal;
    Object.keys(customDifficultyDistribution).map((key) => (
      customDifficultyDistribution[key] = ''
    ));
    sendEvent('AutoTestGeneration', 'toggleDifficultyModal', 'Log', {
      id, subjectId, track, showDifficultyModal, pageTimeSpent: this.getTimeSpent(),
    });
    this.setState({
      showDifficultyModal, selectedDifficulty: DIFFICULTY[1].value, isCustomDistribution: false, customDifficultyDistribution, totalDistributionCount: 0, showErrorMessage: false,
    });
  }

  autoGenerateTest() {
    this.setState({ showPageLoading: true });
    const query = new URLSearchParams(window.location.search);
    const { id } = this.props.match.params;
    const subjectId = query.get('subject_id');
    const track = query.get('track');
    const { checkpointRange, selectedDifficulty, customDifficultyDistribution } = this.state;
    const payload = {
      id,
      track,
      customDifficultyDistribution: JSON.stringify(customDifficultyDistribution),
      subjectId: subjectId && subjectId !== 'undefined' ? subjectId : '',
      difficulty: selectedDifficulty,
      distribution: JSON.stringify(checkpointRange),
    };
    sendEvent('AutoTestGeneration', 'requestAutoTestGeneration', 'Log', { ...payload, pageTimeSpent: this.getTimeSpent() });
    const autoTestGenerationRequestTime = new Date();
    this.props.generateAutoTest(payload).then((response) => {
      sendEvent('AutoTestGeneration', 'responseAutoTestGeneration', 'Log', { ...payload, status: response.success, responseTime: this.getTimeSpent(autoTestGenerationRequestTime) });
      if (response.success) {
        this.props.history.push(`/new_assignment/${id}?subject_id=${subjectId}&track=${track}&page_type=${PAGETYPE.REVIEW}`);
      } else {
        alert(response.data.message);
      }
      this.setState(() => ({ showPageLoading: false }));
    });
  }

  updateRange(id, value) {
    const query = new URLSearchParams(window.location.search);
    const subjectId = query.get('subject_id');
    const track = query.get('track');
    const { checkpointRange } = this.state;
    if (!value || value === 0) {
      delete checkpointRange[id];
    } else {
      checkpointRange[id] = value;
    }
    const rangeDifference = this.getRangeDifference(checkpointRange, this.props.questionLimit);
    sendEvent('AutoTestGeneration', 'updateRanges', 'Log', {
      id, subjectId, track, rangeDifference, checkpointRange, pageTimeSpent: this.getTimeSpent(),
    });
    this.setState({ rangeDifference, checkpointRange });
  }

  clearData() {
    const query = new URLSearchParams(window.location.search);
    const { id } = this.props.match.params;
    const subjectId = query.get('subject_id');
    const track = query.get('track');
    sendEvent('AutoTestGeneration', 'clearData', 'Log', {
      id, subjectId, track, pageTimeSpent: this.getTimeSpent(),
    });
    this.setState({ checkpointRange: {}, rangeDifference: this.props.questionLimit });
  }

  renderDistribution(field) {
    const { questionLimit } = this.props;
    const { checkpointRange } = this.state;
    const value = checkpointRange[field.id] ? checkpointRange[field.id] : 0;
    const distributionColor = '#7E8398';
    const numericInputStyle = IS_MOBILE ? {
      height: 40, width: 40, display: 'flex', justifyContent: 'center', alignItems: 'center', fontSize: 24, backgroundColor: distributionColor, color: 'white',
    } : {
      height: 40, width: 40, display: 'flex', justifyContent: 'center', alignItems: 'center', fontSize: 24, backgroundColor: distributionColor, color: 'white', cursor: 'pointer', userSelect: 'none',
    };
    return (
      <div style={{
        display: 'flex', justifyContent: 'space-between', border: `1px solid ${Constants().color.inactive}`, borderRadius: 2, width: '100%', alignItems: 'center', padding: 20,
      }}
      >
        <div>
          {field.name}
        </div>
        <div style={{ marginLeft: 20, display: 'flex', justifyContent: 'center' }}>
          <div role="presentation" style={{ ...numericInputStyle, borderTopLeftRadius: 4, borderBottomLeftRadius: 4 }} onClick={() => ((parseInt(value) - 1) >= 0) && this.updateRange(field.id, (parseInt(value) - 1))}> - </div>
          {IS_MOBILE ? (
            <div style={{
              width: 40, height: 40, display: 'flex', justifyContent: 'center', alignItems: 'center', borderTop: `1px solid ${distributionColor}`, borderBottom: `1px solid ${distributionColor}`,
            }}
            >
              {value}
            </div>
          ) : (
            <input
              type="text"
              min="0"
              max={questionLimit}
              onChange={(event) => this.updateRange(field.id, event.target.value)}
              style={{
                width: 50, height: 40, marginBottom: 0, border: 'none', borderRadius: 0, borderTop: `1px solid ${distributionColor}`, borderBottom: `1px solid ${distributionColor}`,
              }}
              value={value}
            />
          )}
          <div role="presentation" style={{ ...numericInputStyle, borderTopRightRadius: 4, borderBottomRightRadius: 4 }} onClick={() => ((parseInt(value) + 1) <= questionLimit) && this.updateRange(field.id, (parseInt(value) + 1))}> + </div>
        </div>
      </div>
    );
  }

  renderHeader(id, index) {
    const { distribution } = this.props;
    return (
      <div key={`HeaderId_${id}`} className="m-5" style={{ fontSize: 14 }}>
        <div className="row header_text">
          {index + 1}
          .
          {distribution[id].name}
        </div>
        {distribution[id].fields.map((field) => (
          <div key={`field_${field.id}`}>
            {this.renderDistribution(field)}
          </div>
        ))}
      </div>
    );
  }

  renderBody() {
    const { distribution, isAssignment, questionLimit } = this.props;
    const {
      showPageLoading, rangeDifference, selectedDifficulty,
      isCustomDistribution, customDifficultyDistribution, totalDistributionCount,
      showErrorMessage,
    } = this.state;
    if (showPageLoading) {
      return <Loading />;
    }
    const backgroundColor = rangeDifference < 0 ? Constants().color.error : Constants().color.primary;
    return (
      <div>
        <div id="chapter-distribution-header" className="pt-5 mt-5" style={{ marginBottom: 80, padding: IS_MOBILE ? 0 : 50 }}>
          <div style={{ display: 'flex', justifyContent: 'space-between', flexDirection: IS_MOBILE ? 'column' : 'row' }}>
            <div style={{ padding: '0px 20px', fontWeight: 'bold', color: '#112F50' }}>
              <p style={{ fontSize: 28, marginBottom: 0 }}> How many questions for each topic? </p>
              <p style={{ fontSize: 16 }}>
                Make sure they add upto
                &nbsp;
                {questionLimit}
                &nbsp;questions.
              </p>
            </div>
            <div style={{ display: 'flex', justifyContent: 'flex-end', marginRight: IS_MOBILE ? 50 : 20 }}>
              <div role="presentation" className={`secondary_button ${IS_MOBILE ? '' : 'm-5'}`} style={{ width: 120, height: 35 }} onClick={() => this.clearData()}>
                Reset all
              </div>
            </div>
          </div>
          <Modal showModal={this.state.showDifficultyModal} handleCloseModal={() => this.toggleDifficultyModal()} width="460px" isMobile={IS_MOBILE} style={{ padding: 20 }}>
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              <div
                className="nunito-sans-header"
                style={{
                  fontWeight: 'bold', fontSize: 18, display: 'flex', cursor: 'pointer',
                }}
              >
                <div role="presentation" style={{ display: (isCustomDistribution) ? 'flex' : 'none', marginRight: 10 }} onClick={() => this.setState({ isCustomDistribution: false, selectedDifficulty: DIFFICULTY[1].value })}>
                  <img alt="" src={backButton} />
                </div>
                { isCustomDistribution ? 'Custom Difficulty' : 'Set Difficulty'}
              </div>
              <div role="presentation" onClick={() => this.toggleDifficultyModal()} style={{ cursor: 'pointer' }}>
                <img src={xMark} alt="x-mark" />
              </div>
            </div>
            {isCustomDistribution
              && (
              <div style={{ marginTop: '5%', marginBottom: '5%' }}>
                <div className="d-flex" style={{ justifyContent: 'space-around' }}>
                  {DIFFICULTY.map((difficulty) => {
                    return (
                      <div
                        key={difficulty.label}
                        style={{
                          display: 'flex', flexDirection: 'column', margin: '0px 10px', width: 120,
                        }}
                      >
                        <div>
                          {difficulty.label}
                        </div>
                        <div style={{
                          display: 'flex', justifyContent: 'space-between', border: '1px solid #D5D5D5', borderRadius: 4, height: 40,
                        }}
                        >
                          <div role="presentation" style={{ width: '100%', display: 'flex' }} onClick={() => this.showButton(difficulty.label)}>
                            <input
                              ref={this.inputDifficultyRef[difficulty.label]}
                              type="number"
                              min="0"
                              max="100"
                              style={{
                                width: '60%', border: 'none', paddingLeft: 14, paddingRight: 0, marginBottom: 0,
                              }}
                              id={difficulty.label}
                              placeholder={33}
                              value={customDifficultyDistribution[difficulty.label]}
                              onChange={(event) => this.updateCustomDifficultyDistribution(difficulty.label, event.target.value)}
                            />
                            <div style={{ alignSelf: 'center' }}>%</div>
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </div>
                <div style={{ height: 20, color: 'red' }}>
                  { (showErrorMessage) ? 'Please ensure Easy + Medium + Hard = 100%' : ''}
                </div>
                <div
                  role="presentation"
                  data-effect="solid"
                  data-tip={(totalDistributionCount < maxCustomDistributionCount) ? 'Please ensure Easy + Medium + Hard = 100%' : ''}
                  className="primary_button"
                  style={{
                    width: '100%', marginBottom: 18, marginTop: 5, opacity: (totalDistributionCount != maxCustomDistributionCount) ? '.5' : '1', cursor: (totalDistributionCount != maxCustomDistributionCount) ? 'not-allowed' : 'pointer',
                  }}
                  onClick={() => (totalDistributionCount == maxCustomDistributionCount) && this.autoGenerateTest()}
                >
                  Done
                </div>
                <ReactTooltip />
              </div>
              )}
            {!isCustomDistribution
               && (
               <div style={{ marginTop: '5%', marginBottom: '5%' }}>
                 <div className="d-flex mb-4" style={{ justifyContent: 'space-between' }}>
                   {DIFFICULTY.map((difficulty) => (
                     <div
                       role="presentation"
                       key={difficulty.label}
                       className={(difficulty.value === selectedDifficulty) ? 'primary_button' : ''}
                       style={{
                         width: 120, border: `1px solid ${Constants().color.inactive}`, borderRadius: 5, color: difficulty === selectedDifficulty && 'white', textAlign: 'center', padding: 5, cursor: 'pointer',
                       }}
                       onClick={() => this.setState({ selectedDifficulty: difficulty.value })}
                     >
                       {difficulty.label}
                     </div>
                   ))}
                 </div>
                 <div role="presentation" className="secondary_button" style={{ width: '100%', marginBottom: 20 }} onClick={() => this.setState({ isCustomDistribution: true, selectedDifficulty: customDifficulty })}>
                   Custom Difficulty
                 </div>
                 <div role="presentation" id="confirm-difficulty-distribution" data-effect="solid" data-tip={!selectedDifficulty ? `Select the ${isAssignment ? 'assignment' : 'test'} difficulty` : ''} className="primary_button" style={{ width: '100%', opacity: (selectedDifficulty) ? '1' : '.5', cursor: (selectedDifficulty) ? 'pointer' : 'not-allowed' }} onClick={() => selectedDifficulty && this.autoGenerateTest()}> Continue </div>
                 {!IS_MOBILE && <ReactTooltip />}
               </div>
               )}
          </Modal>
          {Object.keys(distribution).map((id, index) => (
            this.renderHeader(id, index)
          ))}
        </div>
        <div
          className="d-flex p-4"
          style={{
            backgroundColor, bottom: 0, position: 'fixed', width: '100%', justifyContent: 'center',
          }}
        >
          {rangeDifference !== 0
            && (
            <div className="d-flex">
              <div className="mr-4 pt-2 pb-2" style={{ color: 'white', fontSize: 16, fontWeight: 'bold' }}> Questions remaining </div>
              <div
                className="pl-5 pr-5 pt-2 pb-2"
                style={{
                  backgroundColor: 'white', color: backgroundColor, borderRadius: 5, fontWeight: 'bold', fontSize: 16,
                }}
              >
                {rangeDifference}
              </div>
            </div>
            )}
          {rangeDifference === 0
            && <div role="presentation" id="confirm-chapter-distribution" className="secondary_button pl-5 pr-5" style={{ width: 200, fontWeight: 'bold', fontSize: 16 }} onClick={() => this.toggleDifficultyModal()}> Continue </div>}
        </div>
      </div>
    );
  }

  render() {
    const { history } = this.props;
    if (IS_MOBILE === '') {
      return <div />;
    }
    if (IS_MOBILE) {
      const query = new URLSearchParams(window.location.search);
      const { id } = this.props.match.params;
      const subjectId = query.get('subject_id');
      const track = query.get('track');
      return (
        <div>
          <MobileHeader
            color="white"
            backgroundColor={Constants().color.primary}
            name="Picked Questions"
            showBackButton
            onImageClick={() => {
              history.push(`/test_chapter_selection/${id}?subject_id=${subjectId}&track=${track}`);
              this.setState({ showPageLoading: true, isBackNavigation: true });
            }}
          />
          {this.renderBody()}
        </div>
      );
    }
    return (
      <Layout globalHistory={history}>
        {this.renderBody()}
      </Layout>
    );
  }
}

const mapStateToProps = ({ testConfiguration }) => ({
  distribution: testConfiguration.distribution,
  questionLimit: testConfiguration.questionLimit,
  selectedDistributionRanges: testConfiguration.selectedDistributionRanges,
  isAssignment: testConfiguration.isAssignment,
});

export default connect(mapStateToProps, {
  getTestDistribution,
  generateAutoTest,
})(DistributionSelection);
