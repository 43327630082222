import Api from "../helpers/Api";
import { INSTITUTE_DETAILS, showToast } from ".";

export const UPDATE_HR_BOARD_DETAILS = "hrBoard/UPDATE_HR_BOARD_DETAILS";
export const UPDATE_HR_BOARD_EMPLOYEE = "hrBoard/UPDATE_HR_BOARD_EMPLOYEE";
export const UPDATE_HR_BOARD_EMPLOYEE_SALARY_SPLIT =
  "hrBoard/UPDATE_HR_BOARD_EMPLOYEE_SALARY_SPLIT";

export const showLoader = (show) => async (dispatch) => {
  await dispatch({
    type: UPDATE_HR_BOARD_DETAILS,
    payload: {
      isLoading: show,
    },
  });
};

export const getEmployeesDetails = () => async (dispatch) => {
  await dispatch(showLoader(true));
  const response = await Api({
    method: "get",
    url: "/hr_board/get_employees_list",
  });
  if (response.success) {
    await dispatch({
      type: UPDATE_HR_BOARD_DETAILS,
      payload: {
        employeesList: response.employees_list,
        totalEmployeesCount: response.employees_count,
      },
    });
    await dispatch({
      type: INSTITUTE_DETAILS,
      payload: {
        campuses: response.campuses,
      },
    });
  }
  await dispatch(showLoader(false));
};

export const getEmployeeData = (id) => async (dispatch) => {
  await dispatch(showLoader(true));
  const response = await Api({
    method: "get",
    url: `/hr_board/get_employee_data?id=${id}`,
  });
  if (response.success) {
    await dispatch({
      type: UPDATE_HR_BOARD_EMPLOYEE,
      payload: {
        employeeDetails: response.employee,
      },
    });
    await dispatch({
      type: INSTITUTE_DETAILS,
      payload: {
        campuses: response.campuses,
      },
    });
  }
  await dispatch(showLoader(false));
};

export const terminateEmployee = (id, date) => async (dispatch) => {
  await dispatch(showLoader(true));
  const response = await Api({
    method: "post",
    url: `/hr_board/terminate_employee`,
    data: { id: id, date: date },
  });
  if (response.success) {
    await dispatch({
      type: UPDATE_HR_BOARD_EMPLOYEE,
      payload: {
        employeeDetails: response.employee,
      },
    });
  }
  await dispatch(showLoader(false));
  return response;
};

export const createNewEmployee =
  (
    employeeBadgeId,
    firstName,
    lastName,
    jobTitle,
    subject,
    organization,
    campuses,
    personalPhone,
    department,
    gender,
    pan,
    fatherName,
    currentAddressLineOne,
    currentAddressLineTwo,
    currentAddressCity,
    currentAddressPinCode,
    permanentAddressLineOne,
    permanentAddressLineTwo,
    permanentAddressCity,
    permanentAddressPinCode,
    bloodGroup,
    dateOfJoining,
    workEmail,
    emergencyContactName,
    emergencyContactNumber,
    emergencyContactRelationship,
    currentSalary,
    status,
    personalEmail,
    workPhone,
    // picture,
    dateOfBirth
  ) =>
  async (dispatch) => {
    await dispatch(showLoader(true));
    const response = await Api({
      method: "post",
      url: `/hr_board/create_new_employee`,
      data: {
        employee_badge_id: employeeBadgeId,
        first_name: firstName,
        last_name: lastName,
        job_title: jobTitle,
        subject: subject,
        organization: organization,
        campuses: JSON.stringify(campuses),
        personal_phone: personalPhone,
        department: department,
        gender: gender && gender.value,
        pan: pan,
        father_name: fatherName,
        current_address_line_one: currentAddressLineOne,
        current_address_line_two: currentAddressLineTwo,
        current_address_city: currentAddressCity,
        current_address_pin_code: currentAddressPinCode,
        permanent_address_line_one: permanentAddressLineOne,
        permanent_address_line_two: permanentAddressLineTwo,
        permanent_address_city: permanentAddressCity,
        permanent_address_pin_code: permanentAddressPinCode,
        blood_group: bloodGroup && bloodGroup.value,
        date_of_joining: dateOfJoining,
        work_email: workEmail,
        emergency_contact_name: emergencyContactName,
        emergency_contact_number: emergencyContactNumber,
        emergency_contact_relationship: emergencyContactRelationship,
        current_salary: currentSalary,
        status: status && status.value,
        personal_email: personalEmail,
        work_phone: workPhone,
        date_of_birth: dateOfBirth,
      },
      // attachment: picture,
      // type: "user_image",
    });
    if (response.success) {
      await dispatch({
        type: UPDATE_HR_BOARD_DETAILS,
        payload: {
          employeesList: response.employees_list,
          totalEmployeesCount: response.employees_count,
        },
      });
    }
    await dispatch(showLoader(false));
    !!response.message && dispatch(showToast(response.message));
    return response;
  };

export const updateEmployeeData =
  (
    employeeBadgeId,
    firstName,
    lastName,
    jobTitle,
    subject,
    organization,
    campuses,
    personalPhone,
    department,
    gender,
    pan,
    fatherName,
    currentAddressLineOne,
    currentAddressLineTwo,
    currentAddressCity,
    currentAddressPinCode,
    permanentAddressLineOne,
    permanentAddressLineTwo,
    permanentAddressCity,
    permanentAddressPinCode,
    bloodGroup,
    dateOfJoining,
    workEmail,
    emergencyContactName,
    emergencyContactNumber,
    emergencyContactRelationship,
    currentSalary,
    status,
    personalEmail,
    workPhone,
    employeeId,
    // picture,
    dateOfBirth
  ) =>
  async (dispatch) => {
    await dispatch(showLoader(true));
    const response = await Api({
      method: "post",
      url: `/hr_board/update_employee`,
      data: {
        employee_badge_id: employeeBadgeId,
        first_name: firstName,
        last_name: lastName,
        job_title: jobTitle,
        subject: subject,
        organization: organization,
        campuses: JSON.stringify(campuses),
        personal_phone: personalPhone,
        department: department,
        gender: gender && gender.value,
        pan: pan,
        father_name: fatherName,
        current_address_line_one: currentAddressLineOne,
        current_address_line_two: currentAddressLineTwo,
        current_address_city: currentAddressCity,
        current_address_pin_code: currentAddressPinCode,
        permanent_address_line_one: permanentAddressLineOne,
        permanent_address_line_two: permanentAddressLineTwo,
        permanent_address_city: permanentAddressCity,
        permanent_address_pin_code: permanentAddressPinCode,
        blood_group: bloodGroup && bloodGroup.value,
        date_of_joining: dateOfJoining,
        work_email: workEmail,
        emergency_contact_name: emergencyContactName,
        emergency_contact_number: emergencyContactNumber,
        emergency_contact_relationship: emergencyContactRelationship,
        current_salary: currentSalary,
        status: status && status.value,
        personal_email: personalEmail,
        work_phone: workPhone,
        id: employeeId,
        date_of_birth: dateOfBirth,
      },
      // attachment: picture,
      // type: "user_image",
    });
    if (response.success) {
      await dispatch({
        type: UPDATE_HR_BOARD_EMPLOYEE,
        payload: {
          employeeDetails: response.employee,
        },
      });
    }
    await dispatch(showLoader(false));
    !!response.message && dispatch(showToast(response.message));
    return response;
  };

export const insertEmployeeDetails = (files) => async (dispatch) => {
  const response = await Api({
    method: "post",
    url: `/hr_board/upload_employee_details`,
    attachment: files,
    type: "spreadsheet",
  });
  if (response.success) {
    await dispatch({
      type: UPDATE_HR_BOARD_DETAILS,
      payload: {
        employeesList: response.employees_list,
        totalEmployeesCount: response.employees_count,
      },
    });
  }
  !!response.message && dispatch(showToast(response.message));
  return response;
};

export const addEmployeeNotes = (employeeId, notes) => async (dispatch) => {
  await dispatch(showLoader(true));
  const response = await Api({
    method: "post",
    url: `/hr_board/add_employee_notes`,
    data: { id: employeeId, notes: notes },
  });
  if (response.success) {
    await dispatch({
      type: UPDATE_HR_BOARD_EMPLOYEE,
      payload: {
        employeeDetails: response.employee,
      },
    });
  }
  await dispatch(showLoader(false));
  !!response.message && dispatch(showToast(response.message));
  return response;
};

export const updateEmployeeNotes = (noteId, notes) => async (dispatch) => {
  await dispatch(showLoader(true));
  const response = await Api({
    method: "post",
    url: `/hr_board/update_employee_notes`,
    data: { id: noteId, notes: notes },
  });
  if (response.success) {
    await dispatch({
      type: UPDATE_HR_BOARD_EMPLOYEE,
      payload: {
        employeeDetails: response.employee,
      },
    });
  }
  await dispatch(showLoader(false));
  !!response.message && dispatch(showToast(response.message));
  return response;
};

export const addEmployeeAccounts =
  (employeeId, accountHolderName, accountNumber, ifscCode, bankName) =>
  async (dispatch) => {
    await dispatch(showLoader(true));
    const response = await Api({
      method: "post",
      url: `/hr_board/add_employee_bank_accounts`,
      data: {
        id: employeeId,
        account_holder_name: accountHolderName,
        account_number: accountNumber,
        ifsc_code: ifscCode,
        bank_name: bankName,
      },
    });
    if (response.success) {
      await dispatch({
        type: UPDATE_HR_BOARD_EMPLOYEE,
        payload: {
          employeeDetails: response.employee,
        },
      });
    }
    await dispatch(showLoader(false));
    !!response.message && dispatch(showToast(response.message));
    return response;
  };

export const updateEmployeeAccounts =
  (accountId, accountHolderName, accountNumber, ifscCode, bankName) =>
  async (dispatch) => {
    await dispatch(showLoader(true));
    const response = await Api({
      method: "post",
      url: `/hr_board/update_employee_bank_accounts`,
      data: {
        id: accountId,
        account_holder_name: accountHolderName,
        account_number: accountNumber,
        ifsc_code: ifscCode,
        bank_name: bankName,
      },
    });
    if (response.success) {
      await dispatch({
        type: UPDATE_HR_BOARD_EMPLOYEE,
        payload: {
          employeeDetails: response.employee,
        },
      });
    }
    await dispatch(showLoader(false));
    !!response.message && dispatch(showToast(response.message));
    return response;
  };

export const updateEmployeeSalary =
  (employeeId, newSalary, oldSalary, effectiveFrom) => async (dispatch) => {
    await dispatch(showLoader(true));
    const response = await Api({
      method: "post",
      url: `/hr_board/update_employee_salary`,
      data: {
        id: employeeId,
        new_salary: newSalary,
        effect_date: effectiveFrom,
        old_salary: oldSalary,
      },
    });
    if (response.success) {
      await dispatch({
        type: UPDATE_HR_BOARD_EMPLOYEE,
        payload: {
          employeeDetails: response.employee,
        },
      });
    }
    await dispatch(showLoader(false));
    !!response.message && dispatch(showToast(response.message));
    return response;
  };

export const updateSalarySplitProps =
  (accountId, newShare, reset = false) =>
  async (dispatch, getState) => {
    let { employee } = getState().hrBoard;
    let newSalarySplit =
      reset === true
        ? employee.bank_accounts
        : employee.current_bank_accounts_split.map((account) => {
            if (account.id === accountId) {
              account.share =
                newShare === "" ? parseFloat(0) : parseFloat(newShare);
            }
            return account;
          });
    await dispatch({
      type: UPDATE_HR_BOARD_EMPLOYEE_SALARY_SPLIT,
      payload: {
        newBankAccountsSplit: newSalarySplit,
      },
    });
  };

export const updateEmployeeSalarySplit =
  (employeeId, salarySplit) => async (dispatch) => {
    await dispatch(showLoader(true));
    const response = await Api({
      method: "post",
      url: `/hr_board/update_employee_salary_split`,
      data: {
        id: employeeId,
        salary_split: JSON.stringify(salarySplit),
      },
    });
    if (response.success) {
      await dispatch({
        type: UPDATE_HR_BOARD_EMPLOYEE,
        payload: {
          employeeDetails: response.employee,
        },
      });
    }
    await dispatch(showLoader(false));
    !!response.message && dispatch(showToast(response.message));
    return response;
  };
