import React from "react";
import Layout from "../layout";
import { isMobileDevice } from "../../helpers/util";
import MenuBar from "../menubar";
import EmployeesList from "./employeeList";
import EmployeeProfile from "./employeeProfile";

const IS_MOBILE = isMobileDevice();

const MENU_DATA = {
  EMPLOYEES_LIST: {
    label: "Employees List",
    route: "/hr_board/employees_list",
  },
};

class HrBoard extends React.Component {
  constructor(props) {
    const { type } = props.match.params;
    super(props);
    this.state = { type };
  }

  componentDidMount() {}

  render() {
    const { history } = this.props;
    const { type } = this.props.match.params;
    const params = new URLSearchParams(window.location.search);
    const employeeId = Number(params.get("id"));
    const dataType = type && type.toUpperCase();
    const types = Object.keys(MENU_DATA);
    const index = types.indexOf(dataType);

    return (
      <Layout
        hideHeader
        globalHistory={history}
        isMobile={IS_MOBILE}
        title="HR Board"
      >
        <MenuBar
          history={history}
          isQuestionBoard
          menuList={Object.values(MENU_DATA)}
          selectedOption={index}
          redirectToRoot
          headerText={{ first: "HR", last: "BOARD" }}
        />
        {type === "employees_list" && <EmployeesList />}
        {type === "employee_profile" && (
          <EmployeeProfile employeeId={employeeId} />
        )}
      </Layout>
    );
  }
}

export default HrBoard;
