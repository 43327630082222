import { GET_INSTITUTE_LIVE_CLASSROOM_LIST } from '../actions';

const initialState = {
  liveClassroomList: [],
  videoStatuses: [],
  testTypes: [],
  subjects: [],
};

export default function (state = initialState, action) {
  switch (action.type) {
    case GET_INSTITUTE_LIVE_CLASSROOM_LIST: {
      return { ...state, ...action.payload };
    }
    default:
      return state;
  }
}
