import React from "react";
import PropTypes from "prop-types";
import Loader from "../../loading";
import { connect } from "react-redux";
import Pagination from "react-js-pagination";
import Constants from "../../../shared/constants";
import { createBrowserHistory } from "history";
import { withRouter } from "react-router-dom";
import "../bdeTools.css";

import { getInstitutesList } from "../../../actions";

const history = createBrowserHistory({
  forceRefresh: true,
});
class BdeInstitutesList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      page: 1,
      perPage: 15,
      searchText: "",
    };
  }

  renderFilters() {
    const { searchText } = this.props;
    return (
      <>
        <div style={{ marginLeft: 30 }}>
          <input
            type="text"
            value={searchText}
            placeholder="Search Institute Name/phone/email"
            onChange={(event) =>
              this.setState({ searchText: event.target.value })
            }
            style={{ width: 300 }}
          ></input>
        </div>
      </>
    );
  }

  renderInstitutesList() {
    const { institutesList } = this.props;
    const { page, perPage, searchText } = this.state;
    const filteredInstitutes = !!searchText
      ? institutesList.filter((institute) => {
          return (
            (!!institute.phone && institute.phone.indexOf(searchText) !== -1) ||
            (!!institute.name &&
              institute.name
                .toLowerCase()
                .includes(searchText.toLowerCase())) ||
            (!!institute.admin_emails &&
              institute.admin_emails
                .join(" ")
                .toLowerCase()
                .includes(searchText.toLowerCase()))
          );
        })
      : institutesList;
    return (
      <div style={{ margin: "0 30px 30px 30px" }}>
        <div style={{ fontWeight: "bold", paddingTop: "10px" }}>
          Total Number of Institutes: {filteredInstitutes.length}
        </div>
        <table className="table table-bordered">
          <thead>
            <tr>
              <th>S.no</th>
              <th>Institute Name</th>
              <th>Contact Number</th>
              <th>Admin Emails</th>
            </tr>
          </thead>
          <tbody>
            {filteredInstitutes
              .slice((page - 1) * perPage, page * perPage)
              .map((institute, index) => {
                return (
                  <tr
                    key={institute.id - 1}
                    onClick={() => {
                      history.push(`/bde_tools/institutes/${institute.id}`);
                      window.location.reload(false);
                    }}
                    style={{ cursor: "pointer" }}
                  >
                    <td>{(page - 1) * perPage + (index + 1)}</td>
                    <td>{institute.name}</td>
                    <td>{institute.phone || "-"}</td>
                    <td>{institute.admin_emails?.join(", ") || "-"}</td>
                  </tr>
                );
              })}
          </tbody>
        </table>
        <div style={{ display: "flex", justifyContent: "center" }}>
          <style>
            {`
          .pagination>li.active>a{
            background-color: ${Constants().color.primary} !important;
            color: #FFFFFF !important;
          }
          .pagination > li > a, .pagination > li > span{
            color: ${Constants().color.primary} !important;
          }
          .pagination > li > a:hover{
            background-color: #3d73ad !important;
            color: #FFFFFF !important;
          }
          `}
          </style>
          <Pagination
            activePage={page}
            itemsCountPerPage={perPage}
            totalItemsCount={filteredInstitutes.length}
            pageRangeDisplayed={5}
            onChange={(pageNumber) => this.setState({ page: pageNumber })}
          />
        </div>
      </div>
    );
  }

  render() {
    const { instituteId } = this.props;

    if (this.props.isLoading) {
      return <Loader />;
    }

    return (
      <div style={{ padding: 15, height: "100vh" }}>
        {this.renderFilters()}
        {this.renderInstitutesList()}
      </div>
    );
  }
}

BdeInstitutesList.propTypes = {
  isLoading: PropTypes.bool,
  institutesList: PropTypes.array,
};

BdeInstitutesList.defaultProps = {
  institutesList: [],
};

const mapStateToProps = ({ bdeTools }) => ({
  isLoading: bdeTools.isLoading,
  institutesList: bdeTools.institutesList,
});

export default withRouter(
  connect(mapStateToProps, { getInstitutesList })(BdeInstitutesList)
);
