import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import PropTypes from 'prop-types';
import ProgressBar from '../progressBar';
import CompactLoader from '../loading/compactLoader';
import {
  getSignedUrlAndUploadToS3, showToast,
  fileUploadPercentage, applyInstitueFilters,
} from '../../actions';
import Constants from '../../shared/constants';
import closeMark from '../../static/images/close-mark.svg';
import { BYTES_TO_MEGA_BYTE, FILE_IMAGES } from '../../constants';
import '../../styles/instituteOnBoarding.css';
import { ApplyFilter, DropDown } from '../common';
import excel from '../../static/images/fileTypes/excel.svg';
import file from '../../static/images/fileTypes/file.svg';
import folder from '../../static/images/fileTypes/folder.svg';
import image from '../../static/images/fileTypes/image.svg';
import pdf from '../../static/images/fileTypes/powerpoint.svg';
import video from '../../static/images/fileTypes/video.svg';
import word from '../../static/images/fileTypes/word.svg';
import uploadImage from '../../static/images/uploadFile.svg';

const images = {
  excel,
  file,
  folder,
  image,
  pdf,
  video,
  word,
};

class UploadFile extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      fileTypes: 'image/*,video/*,.pdf,.ppt,.doc,.docx,.pptx,application/vnd.ms-powerpoint,text/plain,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel',
      fileTitle: '',
      fileDescription: '',
      fileUploaded: false,
      userFile: null,
      editableFile: null,
      searchString: '',
      isUploadPage: true,
      moduleNo: '',
      lectureNo: '',
      fileUploading: false,
      selectedModuleLecture: {},
      selectedTestType: {},
      fileExtension: 'file',
      fileShareSelectedSubject: { id: null, name: null },
    };
    this.updateFieldValueChanges = this.updateFieldValueChanges.bind(this);
    this.renderFileData = this.renderFileData.bind(this);
    this.uploadFiles = this.uploadFiles.bind(this);
    this.renderFilters = this.renderFilters.bind(this);
    this.publishFiles = this.publishFiles.bind(this);
  }

  getClassroomsList() {
    const {
      selectedCampus, selectedSemester,
      selectedSubject, selectedDepartment, subjects,
    } = this.props;
    const { classrooms, isHigherEducation } = this.props;
    let filteredData = [];
    const campus = selectedCampus && selectedCampus.id;
    const department = selectedDepartment && selectedDepartment.id;
    const semester = selectedSemester && selectedSemester.id;
    let subjectSelected = selectedSubject && selectedSubject.id;
    if (subjectSelected) {
      [subjectSelected] = subjects.filter((subject) => subject.id === selectedSubject.id);
    }
    filteredData = classrooms.filter((classroom) => {
      if (isHigherEducation) {
        return ((!semester && !department && !subjectSelected)
          || ((semester && !subjectSelected)
          && (classroom.course_id === semester))
          || ((semester && subjectSelected)
          && (classroom.course_id === semester
            && subjectSelected.course_id_list.includes(classroom.course_id)))
          || ((!semester && department && !subjectSelected)
          && (classroom.department_id === department))
          || ((!semester && department && subjectSelected)
          && (classroom.department_id === department
            && subjectSelected.department_id_list.includes(classroom.department_id)))
          || (!semester && !department && subjectSelected
            && (subjectSelected.course_id_list.includes(classroom.course_id)
            || subjectSelected.department_id_list.includes(classroom.department_id)))
        );
      }
      return (!campus || classroom.campus_id === campus);
    });
    return filteredData;
  }

  getLogo() {
    document.getElementById('uploadFile').click();
  }

  publishFiles() {
    const {
      fileTitle, fileDescription, userFile, moduleNo, lectureNo, selectedModuleLecture,
    } = this.state;
    const {
      selectedCampus, selectedDepartment, selectedSemester,
      selectedClassroom, selectedSubject,
    } = this.props;
    let subjectId = '';
    subjectId = selectedSubject ? selectedSubject.id : '';
    const classroomIds = [];
    const semesterId = selectedSemester ? selectedSemester.id : '';
    const departmentId = selectedDepartment ? selectedDepartment.id : '';
    const campusId = selectedCampus ? selectedCampus.id : '';
    if (selectedClassroom) {
      selectedClassroom.forEach((e) => classroomIds.push(e.value));
    }
    this.setState({ fileUploading: true });
    this.props.getSignedUrlAndUploadToS3(fileTitle, fileDescription, userFile, classroomIds.join(','), campusId, semesterId, departmentId, subjectId, selectedModuleLecture.id || '', moduleNo, lectureNo, false).then(() => {
      this.setState({
        fileUploading: false, fileTitle: '', fileDescription: '', userFile: null, moduleNo: '', lectureNo: '', selectedModuleLecture: {}, selectedTestType: {},
      });
      this.props.applyInstitueFilters({
        selectedDepartment: {},
        selectedSubject: null,
        selectedClassroom: null,
        selectedSemester: {},
        selectedTeacher: {},
      });
      this.props.fileUploadPercentage(0);
    });
  }

  uploadFiles(event) {
    const { maxFileSize } = this.props;
    const file = event.target.files[0];
    if (file.size > (maxFileSize * BYTES_TO_MEGA_BYTE)) {
      this.props.showToast(`File should be less than ${maxFileSize} MB.`);
    } else {
      const fileExtension = file.name.split('.')[(file.name.split('.').length) - 1];
      const fileimage = FILE_IMAGES[fileExtension];
      this.setState({
        fileUploaded: true, userFile: file, fileTitle: file.name.split('.')[0], fileExtension: fileimage || FILE_IMAGES.default,
      });
    }
  }

  updateFieldValueChanges(type, value) {
    if (type === 'selectedClassroom' && !value) {
      this.setState({ [type]: null });
      return;
    }
    this.setState({ [type]: value });
  }

  handleFormSubmit(e) {
    e.preventDefault();
  }

  renderFilters() {
    const {
      campuses, isHigherEducation, departments,
      selectedCampus, semesters, subjects,
      selectedDepartment, selectedSemester, selectedSubject,
    } = this.props;
    return (
      <div style={{ marginTop: -25 }}>
        <ApplyFilter
          campuses={campuses}
          departments={departments}
          semesters={semesters}
          subjects={subjects}
          isHigherEducation={isHigherEducation}
          applyDataFilters={this.applyDataFilters}
          selectedCampus={{ ...selectedCampus }}
          selectedDepartment={{ ...selectedDepartment }}
          selectedSemester={{ ...selectedSemester }}
          selectedSubject={{ ...selectedSubject }}
          hideClassroom
          hideTeacher
          width="100%"
          hideApply
          hideClear
          ignoreRequired
          dropDownColor="1px solid #343737"
        />
      </div>
    );
  }

  renderFileFilters() {
    const { selectedClassroom, isHigherEducation } = this.props;
    const classroomsList = this.getClassroomsList();
    return (
      <div>
        { this.renderFilters() }
        { isHigherEducation && this.renderModuleAndLecture() }
        { this.props.domainSearch(classroomsList, selectedClassroom,
          (selectedOption) => this.props.applyInstitueFilters({ selectedClassroom: selectedOption }), true) }
      </div>
    );
  }

  renderModuleAndLecture() {
    const { selectedSubject, modulesAndLectures } = this.props;
    const { selectedModuleLecture } = this.state;
    const modulesAndLecturesList = [];
    const subjectsModuleAndLectures = [];
    if (selectedSubject && selectedSubject.id !== null) {
      modulesAndLectures.map((moduleLecture) => {
        if (Number(moduleLecture.subject_ids[0]) === Number(selectedSubject.id)) {
          subjectsModuleAndLectures.push(moduleLecture);
          return true;
        }
      });
      if (subjectsModuleAndLectures.length) {
        let dropDownValue = '';
        subjectsModuleAndLectures.forEach((moduleAndLecture) => {
          if (moduleAndLecture.module || moduleAndLecture.lecture) {
            dropDownValue = moment(moduleAndLecture.date).format('DD MMM');
            if (moduleAndLecture.module) {
              dropDownValue += ` Module ${moduleAndLecture.module}`;
            }
            if (moduleAndLecture.module && moduleAndLecture.lecture) {
              dropDownValue += ' | ';
            }
            if (moduleAndLecture.lecture) {
              dropDownValue += ` Lecture ${moduleAndLecture.lecture}`;
            }
            modulesAndLecturesList.push({ id: moduleAndLecture.id, name: dropDownValue });
          }
        });
      }
    }
    return (
      <div style={{ marginBottom: 40 }}>
        <DropDown
          defaultText="Select Module and Lecture"
          key={selectedSubject ? `${selectedSubject.id}` : 'key'}
          selectedItem={selectedModuleLecture}
          optionsList={[...modulesAndLecturesList]}
          width="100%"
          height={40}
          style={{ borderColor: '#343737' }}
          color="#DDDDDD"
          placeholder="Module and Lecture"
          onValueChange={(id, name) => {
            this.setState({ selectedModuleLecture: { id, name } });
          }}
        />
      </div>
    );
  }

  renderFileUploadStatus() {
    const {
      fileTitle, userFile, fileUploading,
    } = this.state;
    const {
      fileUploadedPercentage, selectedDepartment, selectedSemester, isHigherEducation,
    } = this.props;
    const allowSubmit = userFile && fileTitle
    && (!isHigherEducation || (Object.keys(selectedDepartment).length > 0
    && Object.keys(selectedSemester).length > 0));
    if (fileUploading) {
      return (
        <>
          <div style={{ textAlign: 'center' }}>
            Uploading...
          </div>
          <ProgressBar
            height={4}
            width="100%"
            fillColor={Constants().color.primary}
            style={{ marginTop: 2 }}
            fillPercentage={`${fileUploadedPercentage}%`}
          />
        </>
      );
    }
    return (
      <div
        role="presentation"
        className={!fileUploading ? 'Button' : ''}
        style={{
          height: 30, width: '100%', borderRadius: 4, display: 'flex', justifyContent: 'center', alignItems: 'center', lineHeight: 0, fontSize: 14, border: fileUploading ? '0px' : '1px solid #343737', marginBottom: 0, alignContent: 'flex-end',
        }}
        onClick={(() => {
          if (!fileUploading) {
            if (allowSubmit) {
              this.publishFiles();
            } else {
              this.props.showToast('The required fields are not filled');
            }
          } else {
            this.props.showToast('File is uploading please wait');
          }
        })}
      >
        Share
      </div>
    );
  }

  renderFileData() {
    const { userFile, fileTypes, fileExtension } = this.state;
    const isImage = userFile && userFile.type.includes('image');
    const isPdf = userFile && userFile.type.includes('pdf');
    const isVideo = userFile && userFile.type.includes('video');
    const fileFormat = userFile && userFile.name.split('.').slice(-1).pop();
    const isPpt = userFile && ((fileFormat === 'ppt') || (fileFormat === 'pptx'));
    const isExcel = userFile && ((fileFormat === 'xls') || (fileFormat === 'xlsx'));
    const isDoc = userFile && ((fileFormat === 'doc') || (fileFormat === 'docx'));
    if (userFile) {
      return (
        <div style={{
          borderRadius: 4, height: 200, width: '100%', textAlign: 'center', display: 'flex', flexDirection: 'column', position: 'relative', cursor: 'pointer', marginBottom: 15,
        }}
        >
          <div style={{ height: '0px', overflow: 'hidden' }}>
            <input id="uploadFile" type="file" name="file" accept={this.state.fileTypes} onChange={(event) => this.uploadFiles(event)} />
          </div>
          <div style={{ cursor: 'pointer', height: '99%' }}>
            <div style={{
              borderRadius: '4px', border: 'solid 1px #cdcdce', height: '100%', display: 'flex', justifyContent: 'center', padding: 5, position: 'relative',
            }}
            >
              {isImage && <img alt="" src={URL.createObjectURL(userFile)} style={{ maxWidth: '60%', width: 'fit-content' }} height="fit-content" />}
              {isPdf && <embed style={{ maxWidth: '60%', width: 'fit-content' }} height="fit-content" name="plugin" src={URL.createObjectURL(userFile)} scrolling="no" />}
              {isVideo && (
                <video width="100%" controls>
                  <source src={URL.createObjectURL(userFile)} type="video/mp4" />
                  <source src={URL.createObjectURL(userFile)} type="video/ogg" />
                  Your browser does not support HTML video.
                </video>
              )}
              {(isPpt || isDoc || isExcel) && (
              <div style={{
                alignSelf: 'center', overflow: 'hidden', textOverflow: 'ellipsis', textAlign: 'center',
              }}
              >
                {userFile.name}
              </div>
              )}
              <div
                role="presentation"
                style={{
                  height: 35, backgroundColor: '#E4E7EE', width: '100%', position: 'absolute', bottom: 0, left: 0, color: '#40A7FF', display: 'flex', justifyContent: 'center', alignItems: 'center',
                }}
                onClick={this.getLogo}
              >
                <img src={images[fileExtension]} alt="file" style={{ height: 20, width: 20, marginRight: 10 }} />
                Change
              </div>
              <div
                role="presentation"
                style={{
                  position: 'absolute', top: 5, right: 10, cursor: 'pointer',
                }}
                onClick={(event) => {
                  event.stopPropagation(); event.preventDefault(); document.getElementById('uploadFile').value = null; this.setState({
                    userFile: null, fileUploaded: false, fileTitle: '', fileDescription: '',
                  });
                }}
              >
                <img src={closeMark} alt="x-mark" />
              </div>
            </div>
          </div>
        </div>
      );
    }
    return (
      <>
        <div style={{
          borderRadius: 4, height: 200, width: '100%', textAlign: 'center', border: '1px solid #343737', backgroundColor: '#FFFFFF', display: 'flex', flexDirection: 'column', position: 'relative', cursor: 'pointer', marginBottom: 15,
        }}
        >
          <div style={{
            margin: 20, height: '100%', textAlign: 'center', backgroundColor: '#FFFFFF', display: 'flex', flexDirection: 'column', alignItems: ' center', justifyContent: 'center', border: 'none',
          }}
          >
            <input
              style={{
                position: 'absolute', opacity: '0', textAlign: 'center', zIndex: '1', height: '100%', width: '100%', cursor: 'pointer',
              }}
              type="file"
              accept={fileTypes}
              onChange={(event) => this.uploadFiles(event)}
            />
            <div style={{
              height: '35px', position: 'absolute', textAlign: 'center', width: '35px', background: `url(${uploadImage}) no-repeat`, opacity: 1,
            }}
            />
            <div style={{
              fontSize: '12', color: Constants().color.primary, padding: '8px', marginTop: 60,
            }}
            >
              Drag or browse the file to upload.
            </div>
          </div>
        </div>
      </>
    );
  }

  renderInput(type, placeholderText, style = {}) {
    return (
      <input
        type="text"
        placeholder={placeholderText}
        value={this.state[type]}
        style={{
          width: '100%', outline: 'none', margin: '5px 0px 0px 0px', fontSize: 16, border: '1px solid #343737', ...style,
        }}
        onChange={(event) => this.updateFieldValueChanges(type, event.target.value)}
        required
      />
    );
  }

  render() {
    const {
      fileTitle, fileDescription, userFile,
    } = this.state;
    const { selectedDepartment, selectedSemester, showLoader } = this.props;
    const allowSubmit = userFile && fileTitle
    && fileDescription && Object.keys(selectedDepartment).length > 0
    && Object.keys(selectedSemester).length > 0;
    if (showLoader) {
      return <CompactLoader height={typeof window !== 'undefined' && window.innerHeight - 150} />;
    }
    return (
      <div style={{
        width: '100%', backgroundColor: '#FFFFFF', padding: '5% 5% 1% 5%', border: '1px solid #343737',
      }}
      >
        <form onSubmit={(event) => { this.setState({ fileTitle: allowSubmit ? '' : fileTitle, fileDescription: allowSubmit ? '' : fileDescription, userFile: allowSubmit ? null : userFile }); this.handleFormSubmit(event); }} style={{ display: 'flex' }}>
          <div style={{ width: '50%', display: 'flex', flexDirection: 'column' }}>
            <div style={{
              display: 'flex', flexDirection: 'column', width: '90%', height: '100%',
            }}
            >
              {this.renderFileData()}
              {this.renderInput('fileTitle', 'Name', { marginBottom: 15, height: 80 })}
              <textarea
                value={fileDescription}
                placeholder="Description"
                className="fill-remaining"
                style={{
                  border: '1px solid #343737', width: '100%', outline: 'none', marginTop: 5, marginBottom: 0,
                }}
                onChange={(event) => this.updateFieldValueChanges('fileDescription', event.target.value)}
                required
              />
            </div>
          </div>
          <div style={{ width: '50%', display: 'flex', justifyContent: 'center' }}>
            <div style={{
              width: '90%', display: 'flex', flexDirection: 'column', justifyContent: 'space-between',
            }}
            >
              <div>
                <div role="presentation" style={{ fontSize: 22 }}> Share With: </div>
                {this.renderFileFilters()}
              </div>
              <div style={{
                width: '100%', justifyContent: 'center', alignItems: 'center', marginTop: 50,
              }}
              >
                {this.renderFileUploadStatus()}
              </div>
            </div>
          </div>
        </form>
      </div>
    );
  }
}

UploadFile.propTypes = {
  maxFileSize: PropTypes.number,
  semesters: PropTypes.array,
  subjects: PropTypes.array,
  selectedClassroom: PropTypes.array,
  departments: PropTypes.array,
  isHigherEducation: PropTypes.bool,
  classrooms: PropTypes.array,
  campuses: PropTypes.array,
  selectedCampus: PropTypes.object,
  selectedDepartment: PropTypes.object,
  selectedSemester: PropTypes.object,
  selectedSubject: PropTypes.object,
  showToast: PropTypes.func.isRequired,
  fileUploadPercentage: PropTypes.func.isRequired,
  fileUploadedPercentage: PropTypes.string.isRequired,
  modulesAndLectures: PropTypes.array.isRequired,
  showLoader: PropTypes.bool,
};

UploadFile.defaultProps = {
  maxFileSize: 1,
  semesters: [],
  subjects: [],
  departments: [],
  selectedClassroom: [],
  isHigherEducation: false,
  classrooms: [],
  campuses: [],
  selectedCampus: {},
  selectedDepartment: {},
  showLoader: true,
  selectedSemester: {},
  selectedSubject: {},
};

const mapStateToProps = ({ filesBoard, virtualMeeting }) => ({
  maxFileSize: filesBoard.maxFileSize,
  semesters: filesBoard.semesters,
  departments: filesBoard.departments,
  subjects: filesBoard.subjects,
  isHigherEducation: filesBoard.isHigherEducation,
  classrooms: filesBoard.classrooms,
  campuses: filesBoard.campuses,
  selectedClassroom: virtualMeeting.selectedClassroom,
  selectedDepartment: virtualMeeting.selectedDepartment,
  selectedSemester: virtualMeeting.selectedSemester,
  selectedSubject: virtualMeeting.selectedSubject,
  selectedCampus: virtualMeeting.selectedCampus,
  fileUploadedPercentage: filesBoard.fileUploadedPercentage,
  modulesAndLectures: filesBoard.modulesAndLectures,
});

export default connect(mapStateToProps, {
  getSignedUrlAndUploadToS3,
  applyInstitueFilters,
  fileUploadPercentage,
  showToast,
})(UploadFile);
