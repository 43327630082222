import { UPDATE_APPLICANTS } from "../actions";

const INITIAL_STATE = {
  applicants: [],
  applicant: {},
  campuses: [],
  tags: [],
  isLoading: false,
  notes: [],
  permanentAddress: {},
  currentAdddress: {},
  newLeadsOverTime: [],
  newAdmissionsOverTime: [],
};

export default function (state = INITIAL_STATE, action) {
  switch (action.type) {
    case UPDATE_APPLICANTS: {
      return { ...state, ...action.payload };
    }
    default:
      return state;
  }
}
