import React from 'react';
import { connect } from 'react-redux';
import Modal from './baseModal';
import { newReferalData } from '../../actions';
import closeMark from '../../static/images/close-mark.svg';
import handShake from '../../static/images/handShake.svg';
import './referalModal.css';

class ReferalModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showReferalModal: false,
      contactNumber: '',
      instituteName: '',
      personName: '',
      referralSubmited: false,
      validNumber: false,
    };
    this.saveUserData = this.saveUserData.bind(this);
    this.handleCloseModal = this.handleCloseModal.bind(this);
    this.updateInstituteDetails = this.updateInstituteDetails.bind(this);
    this.handleFormSubmit = this.handleFormSubmit.bind(this);
  }

  handleFormSubmit(e) {
    e.preventDefault();
  }

  handleCloseModal() {
    const { showReferalModal } = this.state;
    this.setState({
      showReferalModal: !showReferalModal,
      contactNumber: '',
      instituteName: '',
      personName: '',
      referralSubmited: false,
      validNumber: false,
    });
  }

  updateInstituteDetails(type, value) {
    const { contactNumber } = this.state;
    let number = contactNumber;
    if (type === 'contactNumber') {
      value = value.replace(' ', '');
      value = value && value.match(/^[0-9]*$/g) ? value.match(/^[0-9]*$/g).join('') : '';
      number = value;
    }
    this.setState({ [type]: value, validNumber: number.length === 10 });
  }

  saveUserData(allowSubmission) {
    const { instituteName, contactNumber, personName } = this.state;
    if (allowSubmission && instituteName && personName) {
      this.props.newReferalData(instituteName,
        personName, contactNumber).then((result) => {
        if (result.success) {
          this.setState({ referralSubmited: true });
        }
      });
    }
  }

  showModalView() {
    const {
      validNumber, instituteName, personName, contactNumber, referralSubmited,
    } = this.state;
    const allowSubmission = validNumber && instituteName && personName;
    const cursor = allowSubmission ? '' : 'not-allowed';

    if (referralSubmited) {
      return (
        <div style={{
          backgroundColor: '#FFFFF',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
        >
          <div style={{
            display: 'flex', flexDirection: 'column', alignItems: 'center', margin: 30,
          }}
          >
            <img alt="" src={handShake} />
            <div style={{ marginTop: 20 }}> Thank you for your referral!</div>
            <div
              role="presentation"
              className="submit-referal"
              style={{
                width: 115, height: 35, marginTop: 30, cursor: 'pointer',
              }}
              onClick={this.handleCloseModal}
            >
              Done
            </div>
          </div>
        </div>
      );
    }

    return (
      <div style={{ backgroundColor: '#FFFFF', width: '100%' }}>
        <div style={{ marginLeft: 18 }}>
          <div style={{
            display: 'flex', justifyContent: 'space-between', marginBottom: 25, marginTop: 25, width: 250,
          }}
          >
            <div className="nunito-sans-header" style={{ fontWeight: 'bold', fontSize: 18 }}>Institute Details</div>
            <div
              role="presentation"
              onClick={this.handleCloseModal}
              style={{ cursor: 'pointer' }}
            >
              <img src={closeMark} alt="x-mark" />
            </div>
          </div>
          <form onSubmit={this.handleFormSubmit}>
            <div className="nunito-body">
              <div className="modal-sub-header">Institute Name</div>
              <input
                className="input-referal"
                id="instituteName"
                onChange={(event) => this.updateInstituteDetails('instituteName', event.target.value)}
                type="text"
                placeholder="Eg: MGR College"
                value={instituteName}
                autoFocus
                required
              />
              <div className="modal-sub-header">Contact Person</div>
              <input
                className="input-referal"
                id="personName"
                onChange={(event) => this.updateInstituteDetails('personName', event.target.value)}
                type="text"
                placeholder="Ravi"
                value={personName}
                required
              />
              <div className="modal-sub-header">Contact Number</div>
              <input
                className="input-referal"
                id="getInviteNumber"
                onChange={(event) => this.updateInstituteDetails('contactNumber', event.target.value)}
                type="text"
                maxLength="10"
                value={contactNumber}
                placeholder="9876543210"
                required
              />
              <button
                type="submit"
                style={{
                  cursor, color: '#FFFFFF', opacity: (allowSubmission) ? 1 : 0.6, marginTop: 20, marginBottom: 30,
                }}
                className="submit-referal"
                onClick={() => this.saveUserData(allowSubmission)}
              >
                Refer Now
              </button>
            </div>
          </form>
        </div>
      </div>
    );
  }

  render() {
    const { showReferalModal } = this.state;
    return (
      <div>
        <Modal showModal={showReferalModal} handleCloseModal={this.handleCloseModal} width="310px" minwidth="none">
          {this.showModalView()}
        </Modal>
        <div
          role="presentation"
          className="refer-button"
          style={{
            display: 'flex', justifyContent: 'center', alignItems: 'center', color: '#0EA4DA', cursor: 'pointer',
          }}
          onClick={() => {
            this.setState({ showReferalModal: true });
          }}
        >
          Refer Now
        </div>
      </div>
    );
  }
}

export default connect()(ReferalModal);
