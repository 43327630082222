import React, { Component } from 'react';
import { connect } from 'react-redux';
import Layout from '../layout';
import Constants from '../../shared/constants';
import { getClassroomStudent, submitClassAttendance, getOfflineClassroomData } from '../../actions';
import Modal from '../modal/baseModal';
import backButton from '../../static/images/back_button.svg';
import Loader from '../loading';
import db from '../../helpers/db';
import {
  PRESENT_BORDER_COLOR,
  PRESENT_BACKGROUND_COLOR,
  ABSENT_BACKGROUND_COLOR,
  ABSENT_BORDER_COLOR,
  ALL_PRESENT_COLOR,
  ALL_ABSENT_COLOR,
} from '../../constants';

class StudentAttendance extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showLoader: true,
      allAbsent: false,
      showModal: false,
    };
    this.updateStudentAttendance = this.updateStudentAttendance.bind(this);
    this.updateAllAttendance = this.updateAllAttendance.bind(this);
    this.submitAttendance = this.submitAttendance.bind(this);
    this.handleModal = this.handleModal.bind(this);
  }

  componentDidMount() {
    const query = new URLSearchParams(window.location.search);
    const { id } = this.props;
    const period_id = query.get('period_id') || '';
    const name = query.get('name') || '';
    const payload = {
      id,
      period_id,
    };
    this.props.getClassroomStudent(payload).then(() => {
      const { currentAbsentStudent } = this.props;
      this.setState({
        absentStudent: currentAbsentStudent,
      });
      const { studentList } = this.props;
      if (navigator.onLine) {
        db.classrooms.put({ id, name, students: studentList });
      } else {
        this.props.getOfflineClassroomData(id);
      }
      this.setState(() => ({ showLoader: false }));
    });
  }

  updateStudentAttendance(item) {
    const { absentStudent } = this.state;
    if (absentStudent.includes(item.id)) {
      const index = absentStudent.indexOf(item.id);
      absentStudent.splice(index, 1);
    } else {
      absentStudent.push(item.id);
    }
    this.setState({ absentStudent });
  }

  updateAllAttendance() {
    const { allAbsent } = this.state;
    this.setState({ allAbsent: !allAbsent });
    const { studentList } = this.props;
    let studentIDS;
    {
      studentIDS = studentList.map((student) => { return student.id; });
    }
    if (!allAbsent) {
      this.setState({ absentStudent: studentIDS });
    } else {
      this.setState({ absentStudent: [] });
    }
  }

  submitAttendance() {
    const { absentStudent } = this.state;
    const query = new URLSearchParams(window.location.search);
    const { id } = this.props.match.params;
    const period_id = query.get('period_id');
    if (navigator.onLine) {
      this.props.submitClassAttendance(JSON.stringify(absentStudent), id, period_id).then(() => {
        this.setState({ showModal: false });
        this.goBack();
      });
    } else {
      const time = new Date();
      db.offlineAttendance.add({
        id, time: time.toISOString(), student_ids: absentStudent, period_id,
      });
      this.setState({ showModal: false });
      this.goBack();
    }
  }

  handleModal(showModal) {
    this.setState({ showModal });
  }

  goBack() {
    this.props.history.push('/attendance/get_all_classes/');
  }

  renderQuitModal() {
    const query = new URLSearchParams(window.location.search);
    const name = query.get('name');
    const { studentList } = this.props;
    const { absentStudent } = this.state;
    const date = new Date();
    return (
      <div className="d-flex flex-column">
        <p className="font-16" style={{ marginBottom: '0px' }}>
          {name}
          <span className="font-12 ml-4" style={{ color: '#9EA0A5' }}>
            {date.getHours()}
            :
            {date.getMinutes()}
          </span>
        </p>
        <div className="d-flex flex-row">
          <div
            className="font-14 d-flex"
            style={{
              justifyContent: 'space-between', backgroundColor: 'white', marginBottom: '4%', marginTop: '4%',
            }}
          >
            <p className="mb-0" style={{ whiteSpace: 'nowrap' }}>
              Total :
              {studentList ? studentList.length : 0}
            </p>
            <p className="ml-4 mb-0" style={{ color: '#56C900', whiteSpace: 'nowrap' }}>
              Present :
              {studentList ? studentList.length - absentStudent.length : 0}
            </p>
            <p className="ml-4 mb-0" style={{ color: '#FF7676', whiteSpace: 'nowrap' }}>
              Absentee :
              {absentStudent ? absentStudent.length : 0}
            </p>
          </div>
        </div>
        <div className="d-flex flex-row justify-content-end mt-3 font-14">
          <div
            role="presentation"
            className="cursor"
            onClick={() => { this.handleModal(false); }}
            style={{
              border: `1px solid ${Constants().color.primary}`, width: '40%', textAlign: 'center', borderRadius: '5px',
            }}
          >
            <span style={{ color: Constants().color.primary }}>Cancel</span>
          </div>
          <div
            role="presentation"
            className="cursor ml-3"
            onClick={() => { this.submitAttendance(); }}
            style={{
              border: `1px solid ${Constants().color.primary}`, backgroundColor: Constants().color.primary, width: '40%', textAlign: 'center', borderRadius: '5px',
            }}
          >
            <span style={{ color: 'white' }}>Finish</span>
          </div>
        </div>
      </div>
    );
  }

  renderStudentData(item, idx) {
    const { absentStudent } = this.state;
    let color = PRESENT_BACKGROUND_COLOR;
    let borderColor = PRESENT_BORDER_COLOR;
    if (absentStudent.includes(item.id)) {
      color = ABSENT_BACKGROUND_COLOR;
      borderColor = ABSENT_BORDER_COLOR;
    }
    return (
      <div
        role="presentation"
        className="card-view mr-5 py-1 cursor"
        key={`${item}_${idx}`}
        onClick={() => { this.updateStudentAttendance(item); }}
        style={{ background: color, width: '100%', border: `1px solid ${borderColor}` }}
      >
        <p style={{ marginBottom: '0px', fontWeight: 'bold' }}>{item.badge_id}</p>
        <p style={{ marginBottom: '0px', fontWeight: 'bold' }}>{item.first_name}</p>
      </div>
    );
  }

  render() {
    if (this.state.showLoader) {
      return (
        <Loader />
      );
    }
    const query = new URLSearchParams(window.location.search);
    const name = query.get('name');
    const { studentList } = this.props;
    const { allAbsent, absentStudent } = this.state;
    let allText;
    let color;
    if (allAbsent) {
      allText = 'Mark all present';
      color = ALL_PRESENT_COLOR;
    } else {
      allText = 'Mark all absent';
      color = ALL_ABSENT_COLOR;
    }
    return (
      <Layout globalHistory={this.props.history} feedback footerShow={false}>
        <div className="row">
          <div style={{
            display: 'flex', justifyContent: 'space-between', backgroundColor: Constants().color.header, width: '100%', height: '50px',
          }}
          >
            <div
              role="presentation"
              className="cursor"
              style={{ marginLeft: '5%', display: 'flex', alignItems: 'center' }}
              onClick={() => { this.goBack(); }}
            >
              <img alt="" src={backButton} width="30px" />
              <p
                className="font-18"
                style={{
                  color: 'white', marginBottom: '0px', fontWeight: 'bold', marginLeft: '20px',
                }}
              >
                {name}
              </p>
            </div>
          </div>
        </div>
        <div className="row">
          <div
            className="font-14"
            style={{
              display: 'flex', justifyContent: 'space-between', backgroundColor: 'white', width: '100%', height: '45px', padding: '5%', marginBottom: '3%',
            }}
          >
            <p>
              Total :
              {studentList ? studentList.length : 0}
            </p>
            <p style={{ color: '#56C900' }}>
              Present :
              {studentList && absentStudent ? studentList.length - absentStudent.length : 0 }
            </p>
            <p style={{ color: '#FF7676' }}>
              Absentee :
              {absentStudent ? absentStudent.length : 0}
            </p>
          </div>
        </div>
        <div className="row mt-1" style={{ marginRight: '20px', marginLeft: '20px' }}>
          <div className="d-flex flex-column mt-2" style={{ height: '100%', width: '100%' }}>
            <div
              role="presentation"
              className="d-flex"
              style={{ marginLeft: '20px' }}
              onClick={() => { this.updateAllAttendance(); }}
            >
              <button type="button" className="btn mt-2 mb-2" style={{ borderRadius: '40px', backgroundColor: color }}><span style={{ color: 'white', fontSize: '15px' }}>{allText}</span></button>
            </div>
            <div
              role="presentation"
              onClick={() => { this.handleModal(true); }}
            >
              <button
                type="button"
                className="btn-primary font-18"
                style={{
                  position: 'fixed', bottom: '0', height: '45px', width: '92%', backgroundColor: Constants().color.header,
                }}
              >
                <span style={{ color: 'white' }}>Finish</span>
              </button>
            </div>
            <Modal showModal={this.state.showModal} handleCloseModal={() => this.handleModal(false)} top="25%">
              {this.renderQuitModal()}
            </Modal>
            <div className="row d-flex flex-column" style={{ marginBottom: '20%', marginLeft: '20px', marginRight: '20px' }}>
              { studentList && studentList.map((item, idx) => (this.renderStudentData(item, idx)))}
            </div>
          </div>
        </div>
      </Layout>
    );
  }
}

const mapStateToProps = ({ attendance }) => ({
  studentList: attendance.studentList,
  currentAbsentStudent: attendance.currentAbsentStudent,
});

export default connect(mapStateToProps, {
  getClassroomStudent,
  submitClassAttendance,
  getOfflineClassroomData,
})(StudentAttendance);
