import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import Modal from '../modal/baseModal';
import { getCampuses, editCampus, toggleCampusActive } from '../../actions';
import CreateCampusModal from '../modal/createCampusModal'
const buttonStyle = {
  height: 30, width: 65, display: 'flex', alignItems: 'center', justifyContent: 'center', cursor: 'pointer',
};

class Campus extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      selectedCampus: null,
      showEditModal: false,
      showConfirmationModal: false,
      showCampusModel: false,
    };
  }

  componentDidMount() {
    this.props.getCampuses();
  }

  renderConfirmationModal() {
    const { showConfirmationModal, selectedCampus } = this.state;
    return (
      <Modal
        showModal={showConfirmationModal}
        handleCloseModal={() => this.setState({ showConfirmationModal: false })}
        height="250px"
        width="500px"
        header={`Remove - ${selectedCampus.name}`}
      >
        <div style={{ padding: 30 }}>
          <div style={{ display: 'inline' }}>
            Are you sure, You want to mark campus -
            {' '}
            {selectedCampus.name}
            {' '}
            as
            {' '}
            {selectedCampus.active ? 'inactive, all classrooms and students associated with this campus will be marked inactive?' : 'Active?'}
          </div>
          <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: 30 }}>
            <div
              className="primary-Button"
              role="presentation"
              style={buttonStyle}
              onClick={() => this.setState({ showConfirmationModal: false })}
            >
              No
            </div>
            <div
              className="primary-Button"
              role="presentation"
              style={buttonStyle}
              onClick={() => this.props.toggleCampusActive(selectedCampus.id, !selectedCampus.active)}
            >
              Yes
            </div>
          </div>
        </div>
      </Modal>
    );
  }

  renderEditCampus() {
    const { name, id, active } = this.state.selectedCampus;
    return (
      <div style={{ padding: 30 }}>
        <div
          className="primary-Button"
          role="presentation"
          onClick={() => {
            this.setState({ showConfirmationModal: true, showEditModal: false });
          }}
          style={{
            display: 'flex',
            justifyContent: 'center',
            marginLeft: 'auto',
            background: active ? 'red' : '#1d4c7f',
            height: 30,
            cursor: 'pointer',
            width: 150,
            alignItems: 'center',
          }}
        >
          {active ? 'Mark as Inactive' : 'Mark as Active'}
        </div>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <div style={{ marginRight: 20 }}>Campus Name</div>
          <input
            type="text"
            placeholder="Classroom Name"
            value={name}
            style={{
              fontWeight: '400',
              display: 'inline-block',
              fontSize: '14px',
              marginBottom: 10,
              overflow: 'auto',
              width: 200,
              resize: 'none',
              border: 0,
              borderBottom: '1px solid #454545',
              background: '#FFFFFF',
            }}
            onChange={(event) => {
              this.setState({
                selectedCampus: { ...this.state.selectedCampus, name: event.target.value },
              });
            }}
          />
        </div>
        <div style={{
          display: 'flex', justifyContent: 'space-between', width: '100%', marginTop: 30,
        }}
        >
          <div
            className="primary-Button"
            role="presentation"
            style={buttonStyle}
            onClick={() => this.setState({ showEditModal: false, selectedCampus: null })}
          >
            Cancel
          </div>
          <div
            className="primary-Button"
            role="presentation"
            style={buttonStyle}
            onClick={() => {
              this.props.editCampus(id, name).then((response) => {
                if (response.success) {
                  this.setState({ showEditModal: false, selectedCampus: null });
                }
              });
            }}
          >
            Save
          </div>
        </div>
      </div>
    );
  }

  renderEditModal() {
    const { selectedCampus, showEditModal } = this.state;
    return (
      <Modal
        showModal={showEditModal}
        handleCloseModal={() => this.setState({ selectedCampus: null, showEditModal: false })}
        height="250px"
        width="600px"
        header={`Edit - ${selectedCampus.name}`}
      >
        {this.renderEditCampus()}
      </Modal>
    );
  }

  renderCampus(campus) {
    return (
      <div
        key={campus.id}
        style={{
          width: 200,
          padding: 20,
          margin: 10,
          backgroundColor: 'white',
          boxShadow: '1px 2px 6px #8B9DAF33',
          cursor: 'pointer',
        }}
        onClick={() => this.setState({ showEditModal: true, selectedCampus: campus })}
      >
        {campus.name}
      </div>
    );
  }

  renderScreen() {
    const { campuses } = this.props;
    return (
      <>
        <div style={{ paddingTop: '50px' }}>
          <div
            role="presentation"
            style={{
              height: 35, width: 140, display: 'flex', justifyContent: 'center', alignItems: 'center', border: '1px solid #CDCFD6', borderRadius: 4, cursor: 'pointer',
            }}
            onClick={() => this.setState({ showCampusModel: true })}
          >
            New Campus
          </div>
        </div>
        <div className="heading-1" style={{ fontSize: 20, margin: '25px 0px' }}>Active Campuses</div>
        <div style={{ display: 'flex', flexWrap: 'wrap' }}>
          {campuses.filter((campus) => campus.active === true).map((campus) => this.renderCampus(campus))}
        </div>
        <div className="heading-1" style={{ fontSize: 20, margin: '25px 0px' }}>Inactive Campuses</div>
        <div style={{ display: 'flex', flexWrap: 'wrap' }}>
          {campuses.filter((campus) => campus.active === false).map((campus) => this.renderCampus(campus))}
        </div>
      </>
    );
  }

  render() {
    const { showEditModal, showConfirmationModal, showCampusModel } = this.state;
    return (
      <>
        {this.renderScreen()}
        {showCampusModel
          && (
          <CreateCampusModal
            visible
            header="Create New Campus"
            submitButton="Create"
            onClose={() => this.setState({ showCampusModel: false })}
          />
          )}
        {showEditModal && this.renderEditModal()}
        {showConfirmationModal && this.renderConfirmationModal()}
      </>
    );
  }
}

Campus.defaultProps = {
  campuses: PropTypes.array,
  getCampuses: PropTypes.func.isRequired,
  editCampus: PropTypes.func.isRequired,
  toggleCampusActive: PropTypes.func.isRequired,
};

Campus.propTypes = {
  campuses: [],
};

const mapStateToProps = ({ institute }) => ({
  campuses: institute.campuses,
});

export default connect(mapStateToProps, {
  editCampus,
  getCampuses,
  toggleCampusActive,
})(Campus);
