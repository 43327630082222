import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { ExcelExport, ExcelExportColumn } from '@progress/kendo-react-excel-export';
import {
  updateCompleteQuizState, getOrUpdateQuiz, updateShowQuestionStatus, getReport,
} from '../../actions';
import Layout from '../layout';
import Loader from '../loading';
import QuizUpdate from '../quizUpdate';
import QuizQuestions from '../quizQuestions';
import QuizResults from '../quizResults';
import { DropDown } from '../common';
import QuizRankList from '../quizRankList';
import Menubar from '../menubar';
import QuizFeedback from '../quizFeedback';
import Attendance from '../attendance';
import '../../styles/instituteOnBoarding.css';
import '../../static/tools.css';
import { getLiveQuizDomain } from '../../helpers/util';
import copy from '../../static/images/common/copy.svg';

const TOOLS_TYPES = {
  quiz_update: 'Details',
  feedback: 'Feedback',
  attendance: 'Attendance',
  quiz_results: 'Quiz Results',
  rank_list: 'Quiz Toppers',
  quiz_questions: 'Quiz Questions',
};

const endingDate = new Date();
endingDate.setDate(new Date().getDate() - 7);

const SELECTED_RANGE = {
  startDate: endingDate,
  endDate: new Date(),
  key: 'selection',
};

class Tools extends PureComponent {
  constructor(props) {
    super(props);
    const { id } = props.match.params;
    this.state = {
      isCompleted: false,
      showLoader: false,
      isAddQuestion: false,
      selectedClassroom: { id: null },
      quizTestUrl: `${getLiveQuizDomain()}/${id}`,
    };
    this.removeNewQuestion = this.removeNewQuestion.bind(this);
    this.createNewQuestion = this.createNewQuestion.bind(this);
    this.showURL = this.showURL.bind(this);
    this.updateShowStatus = this.updateShowStatus.bind(this);
    this.renderClassrooms = this.renderClassrooms.bind(this);
    this.attendanceDetailsToSave = React.createRef();
  }

  componentDidMount() {
    const { match } = this.props;
    const { type } = this.props.match.params;
    const selectedType = type ? TOOLS_TYPES[type] : TOOLS_TYPES.quiz_update;
    const quizId = match.params.id;
    this.props.getOrUpdateQuiz(quizId).then((response) => {
      if (response.quiz) {
        const isCompleted = response.quiz.status === 'COMPLETE';
        this.setState({ isCompleted });
      }
    });
    if (selectedType === TOOLS_TYPES.attendance) {
      this.props.getReport(SELECTED_RANGE, false, 1, quizId);
    }
  }

  removeNewQuestion() {
    this.setState({ isAddQuestion: false });
  }

  copyCodeToClipboard() {
    this.refs.quizLink.select();
    document.execCommand('copy');
  }

  showURL() {
    const { quizTestUrl } = this.state;
    return (
      <div
        style={{
          display: 'flex', justifyContent: 'flex-end', marginBottom: 10, alignItems: 'center', marginTop: 5,
        }}
      >
        <input
          ref="quizLink"
          style={{
            border: '2px solid #CDCFD6', borderRadius: 4, fontWeight: 400, padding: '4px 6px', width: 320,
          }}
          value={quizTestUrl}
          readOnly
        />
        <img
          className="center"
          role="presentation"
          alt=""
          style={{
            marginLeft: 4, width: 30, padding: 3, cursor: 'pointer', paddingBottom: 20,
          }}
          src={copy}
          onClick={() => this.copyCodeToClipboard()}
        />
      </div>
    );
  }

  updateCompleteQuizState(status) {
    const { quizData } = this.props;
    const quizId = quizData.id;
    this.props.updateCompleteQuizState(quizId, status).then(() => {
      this.setState({ isCompleted: true });
    });
  }

  createNewQuestion() {
    return (
      <div
        className="edit-button"
        role="presentation"
        style={{
          height: 35, marginBottom: 0, width: 150, backgroundColor: '#3994AE',
        }}
        onClick={() => { this.setState({ isAddQuestion: true }); }}
      >
        New Question
      </div>
    );
  }

  updateShowStatus(questionIds, showQuestion, quizId) {
    this.props.updateShowQuestionStatus(questionIds, showQuestion, quizId);
  }

  printRankList() {
    const { rankListData } = this.props;
    return (
      <div style={{ display: 'flex' }}>
        {this.renderClassrooms()}
        {rankListData.length > 0 && (
        <div
          onClick={() => window.print()}
          role="presentation"
          className="Button Primary-Button"
          style={{
            padding: 10, fontWeight: 'bold', marginLeft: 20,
          }}
        >
          Export PDF
        </div>
        )}
      </div>

    );
  }

  printData(showUrl = true) {
    const { type } = this.props.match.params;
    const selectedType = type ? TOOLS_TYPES[type] : TOOLS_TYPES.quiz_update;
    const isQuizUpdate = selectedType === TOOLS_TYPES.quiz_update;
    const isQuizQuestion = selectedType === TOOLS_TYPES.quiz_questions;
    const isQuizResults = selectedType === TOOLS_TYPES.quiz_results;
    const isRankList = selectedType === TOOLS_TYPES.rank_list;
    const isQuizFeedback = selectedType === TOOLS_TYPES.feedback;
    const isAttendance = selectedType === TOOLS_TYPES.attendance;
    return this.renderContent(selectedType, showUrl, {
      isQuizUpdate, isQuizQuestion, isQuizResults, isRankList, isQuizFeedback, isAttendance,
    });
  }

  renderClassrooms() {
    const { selectedClassroom } = this.state;
    const { classrooms } = this.props;
    if (classrooms && Object.values({ ...classrooms }).length > 0) {
      return (
        <DropDown
          key={selectedClassroom.id}
          selectedItem={{ ...selectedClassroom }}
          optionsList={Object.values({ ...classrooms })}
          width={180}
          height={40}
          defaultText="Select Classroom"
          color="#DDDDDD"
          onValueChange={(id, name) => {
            this.setState({ selectedClassroom: { id, name } });
          }}
        />
      );
    }
    return <div />;
  }

  renderQuizHeader(showUrl) {
    const { isCompleted, selectedClassroom } = this.state;
    const { quizData, quizDate } = this.props;
    return (
      <div style={{ borderBottom: '2px solid #CDCFD6', marginBottom: 30, paddingBottom: 5 }}>
        <div style={{
          display: 'flex', justifyContent: 'space-between',
        }}
        >
          <div>
            <div style={{
              alignSelf: 'center', fontSize: 22, fontWeight: 'bold', marginBottom: 10,
            }}
            >
              {quizData.name}
            </div>
            <div style={{ alignSelf: 'center', fontWeight: 'bold', marginBottom: 10 }}>
              {quizDate}
            </div>
            {selectedClassroom.name && (
              <div className="printable" style={{ alignSelf: 'center', fontWeight: 'bold', marginBottom: 10 }}>
                Classroom:&nbsp;&nbsp;
                {selectedClassroom.name}
              </div>
            )}
          </div>
          <div
            className="edit-button hide-in-print"
            role="presentation"
            style={{
              marginLeft: 20, width: 100, height: 40, backgroundColor: (isCompleted) ? '#3994AE' : '#ffffff', color: (isCompleted) ? '#ffffff' : '#3994AE', border: (isCompleted) ? '' : '1px solid #3994AE', opacity: isCompleted && 0.5, cursor: isCompleted && 'not-allowed',
            }}
            onClick={() => !isCompleted && this.updateCompleteQuizState('COMPLETE')}
          >
            Complete
          </div>
        </div>
        <div className="hide-in-print">
          {showUrl && this.showURL()}
        </div>
      </div>
    );
  }

  renderDownloadButton() {
    return (
      <div
        role="presentation"
        className="Button Primary-Button"
        onClick={() => this.attendanceDetailsToSave.current.save()}
        style={{
          fontWeight: 'bold', width: 100,
        }}
      >
        Export
      </div>
    );
  }

  renderContent(selectedType, showUrl, data) {
    const { match } = this.props;
    const { id } = match.params;
    const { isAddQuestion, selectedClassroom } = this.state;
    const { updatedCount } = this.props;
    return (
      <div style={{ padding: 60, height: typeof window !== 'undefined' && window.innerHeight }}>
        {this.renderQuizHeader(showUrl)}
        <div className="hide-in-print" style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <div style={{ display: 'flex' }}>
            {Object.keys(TOOLS_TYPES).map((type, index) => {
              const selected = selectedType === TOOLS_TYPES[type];
              return (
                <div
                  role="presentation"
                  className={selected ? 'Button Primary-Button' : 'Button Secondary-Button'}
                  style={{
                    borderRadius: 0, borderLeft: (index !== 0) && 0, display: 'flex', padding: '0px 15px',
                  }}
                  onClick={() => {
                    this.props.history.push(`/quiz/${type}/${id}`);
                    this.setState({ selectedType: type ? TOOLS_TYPES[type] : TOOLS_TYPES.quiz_update });
                  }}
                >
                  {TOOLS_TYPES[type]}
                </div>
              );
            })}
          </div>
          {data.isQuizQuestion && this.createNewQuestion()}
          {(data.isQuizResults || data.isQuizFeedback) && this.renderClassrooms()}
          {data.isRankList && this.printRankList()}
          {data.isAttendance && this.renderDownloadButton()}
        </div>
        {data.isQuizUpdate && <QuizUpdate />}
        {data.isQuizQuestion && (
        <QuizQuestions
          id={id}
          updateShowStatus={this.updateShowStatus}
          key={`${updatedCount}-${isAddQuestion}`}
          isAddQuestion={isAddQuestion}
          updatedCount={updatedCount}
          removeNewQuestion={this.removeNewQuestion}
        />
        )}
        {data.isQuizResults && <QuizResults id={id} key={`${updatedCount}-${selectedClassroom.id}`} updatedCount={updatedCount} classroom={{ ...selectedClassroom }} />}
        {data.isRankList && <QuizRankList id={id} key={`${updatedCount}-${selectedClassroom.id}`} classroom={{ ...selectedClassroom }} />}
        {data.isQuizFeedback && <QuizFeedback id={id} classroom={{ ...selectedClassroom }} key={`${updatedCount}-${selectedClassroom.id}`} />}
        {data.isAttendance && <Attendance quizId={id} />}
      </div>
    );
  }

  render() {
    const { showLoader } = this.state;
    const {
      match, attendanceDetailsToSave, tableConfig, history,
    } = this.props;
    if (showLoader) {
      return <Loader />;
    }
    const quizMenu = [{ label: 'Highlights', route: '/quizboard' }];
    const quizId = match.params.id;
    quizMenu.push({ label: 'Quizzes', route: `/quiz/quiz_update/${quizId}` });
    return (
      <Layout globalHistory={history} hideHeader>
        <style jsx>
          {`
            .printable {
              display: none
            }
              table.print-friendly tr td, table.print-friendly tr th {
                page-break-inside: avoid;
              }
              @page {
                  size: A4;
                  margin: 0;
                  margin-top: 20px;
              }
              @media print {

                  .printable {
                    display: block;
                  }
                }
                  a:hover, a:visited, a:active {
                    color: #454545 !important;
                  }  
                  input[type=checkbox]:after {
                    background-color: #3994AE;
                  }
            `}
        </style>
        <div style={{ width: '100%', display: 'flex' }}>
          <div className="hide-in-print" style={{ width: '20%', padding: 0, zIndex: 1 }}>
            <Menubar history={history} menuList={quizMenu} selectedOption={1} redirectToRoot />
          </div>
          <div className="hide-in-print" style={{ width: '80%' }}>
            {this.printData()}
          </div>
          <div className="printable" style={{ width: '100%' }}>
            {this.printData(false)}
          </div>
        </div>
        <ExcelExport
          data={attendanceDetailsToSave}
          fileName="attendanceDetails.xlsx"
          ref={this.attendanceDetailsToSave}
        >
          {tableConfig.map((field) => (<ExcelExportColumn field={field} title={field} />))}
        </ExcelExport>
      </Layout>
    );
  }
}

Tools.propTypes = {
  quizData: PropTypes.object,
  quizDate: PropTypes.string,
  classrooms: PropTypes.object,
  updatedCount: PropTypes.number,
  rankListData: PropTypes.array,
  attendanceDetailsToSave: PropTypes.array,
  tableConfig: PropTypes.array,
};

Tools.defaultProps = {
  quizData: {},
  quizDate: '',
  classrooms: {},
  updatedCount: 0,
  rankListData: [],
  attendanceDetailsToSave: [],
  tableConfig: [],
};

const mapStateToProps = ({ quiz, virtualMeeting }) => ({
  quizData: quiz.quizData,
  quizDate: quiz.quizDate,
  classrooms: quiz.classrooms,
  updatedCount: quiz.updatedCount,
  rankListData: quiz.rankListData,
  attendanceDetailsToSave: virtualMeeting.attendanceDetailsToSave,
  tableConfig: virtualMeeting.table_header,
});

export default connect(mapStateToProps, {
  getOrUpdateQuiz, updateCompleteQuizState, updateShowQuestionStatus, getReport,
})(Tools);
