import Api from "../helpers/Api";
import { showToast } from "./snackBar";

export const UPDATE_APPLICANTS = "bdeTools/UPDATE_APPLICANTS";

export const showLoader = (show) => async (dispatch) => {
  await dispatch({
    type: UPDATE_APPLICANTS,
    payload: {
      isLoading: show,
    },
  });
};

export const applicantCreation =
  (
    applicantFirstName,
    applicantLastName,
    applicantMobile,
    fatherMobile,
    fatherName,
    fatherEmail,
    motherMobile,
    motherName,
    dob,
    applicantEmail,
    motherEmail,
    previousExamScore,
    bloodGroup,
    applicantStatus,
    program,
    applicantCampus,
    selectedInstitutesTag,
    gender,
    permanentAddressLine1,
    permanentAddressLine2,
    permanentAddressCity,
    permanentAddressPinCode,
    currentAddressLine1,
    currentAddressLine2,
    currentAddressCity,
    currentAddressPinCode
  ) =>
  async (dispatch) => {
    await dispatch(showLoader(true));
    const response = await Api({
      method: "post",
      url: "/admission_board/add_new_applicant",
      data: {
        applicant_first_name: applicantFirstName,
        applicant_last_name: applicantLastName,
        applicant_mobile: applicantMobile,
        father_email: fatherEmail,
        father_mobile: fatherMobile,
        father_name: fatherName,
        mother_mobile: motherMobile,
        mother_name: motherName,
        dob: dob,
        applicant_email: applicantEmail,
        mother_email: motherEmail,
        previous_exam_score: previousExamScore,
        blood_group: bloodGroup,
        applicant_status: applicantStatus,
        program: program,
        applicant_campus: applicantCampus,
        selected_tags: JSON.stringify(selectedInstitutesTag),
        gender: gender,
        permanent_address_line1: permanentAddressLine1,
        permanent_address_line2: permanentAddressLine2,
        permanent_address_city: permanentAddressCity,
        permanent_address_pincode: permanentAddressPinCode,
        current_address_line1: currentAddressLine1,
        current_address_line2: currentAddressLine2,
        current_address_city: currentAddressCity,
        current_address_pincode: currentAddressPinCode,
      },
    });
    await dispatch(showLoader(false));
    return;
  };

export const applicantUpdation =
  (
    applicantId,
    applicantFirstName,
    applicantLastName,
    applicantMobile,
    fatherMobile,
    fatherName,
    fatherEmail,
    motherMobile,
    motherName,
    dob,
    applicantEmail,
    motherEmail,
    previousExamScore,
    bloodGroup,
    applicantStatus,
    program,
    applicantCampus,
    selectedInstitutesTag,
    gender,
    permanentAddressLine1,
    permanentAddressLine2,
    permanentAddressCity,
    permanentAddressPinCode,
    currentAddressLine1,
    currentAddressLine2,
    currentAddressCity,
    currentAddressPinCode
  ) =>
  async (dispatch) => {
    await dispatch(showLoader(true));
    const response = await Api({
      method: "post",
      url: "/admission_board/update_applicant",
      data: {
        applicant_id: applicantId,
        applicant_first_name: applicantFirstName,
        applicant_last_name: applicantLastName,
        applicant_mobile: applicantMobile,
        father_email: fatherEmail,
        father_mobile: fatherMobile,
        father_name: fatherName,
        mother_mobile: motherMobile,
        mother_name: motherName,
        dob: dob,
        applicant_email: applicantEmail,
        mother_email: motherEmail,
        previous_exam_score: previousExamScore,
        blood_group: bloodGroup,
        applicant_status: applicantStatus,
        program: program,
        applicant_campus: applicantCampus,
        selected_tags: JSON.stringify(selectedInstitutesTag),
        gender: gender,
        permanent_address_line1: permanentAddressLine1,
        permanent_address_line2: permanentAddressLine2,
        permanent_address_city: permanentAddressCity,
        permanent_address_pincode: permanentAddressPinCode,
        current_address_line1: currentAddressLine1,
        current_address_line2: currentAddressLine2,
        current_address_city: currentAddressCity,
        current_address_pincode: currentAddressPinCode,
      },
    });
    await dispatch(showLoader(false));

    return;
  };

export const getApplicantsList = () => async (dispatch) => {
  await dispatch(showLoader(true));
  const response = await Api({
    method: "get",
    url: "/admission_board/get_applicants_list",
  });
  if (response.success) {
    dispatch({
      type: UPDATE_APPLICANTS,
      payload: {
        applicants: response.applicants,
        campuses: response.campuses,
        tags: response.tags,
      },
    });
  }
  await dispatch(showLoader(false));
};

export const getApplicantDetails = (applicantId) => async (dispatch) => {
  await dispatch(showLoader(true));
  const response = await Api({
    method: "get",
    url: `/admission_board/get_applicant_detail?applicant_id=${applicantId}`,
  });
  if (response.success) {
    dispatch({
      type: UPDATE_APPLICANTS,
      payload: {
        applicant: response.applicant[0],
        campuses: response.campuses,
        tags: response.tags,
        notes: response.notes,
        permanentAddress: response.permanent_address[0],
        currentAddress: response.current_address[0],
      },
    });
  }
  await dispatch(showLoader(false));
};

export const createApplicantNotes =
  (applicantId, notes) => async (dispatch) => {
    await dispatch(showLoader(true));
    const response = await Api({
      method: "post",
      url: "/admission_board/create_applicant_notes",
      data: {
        applicant_id: applicantId,
        notes: notes,
      },
    });
    await dispatch(showLoader(false));
  };

export const uploadApplicants = (file) => async (dispatch) => {
  const response = await Api({
    method: "post",
    url: "/admission_board/upload_applicants",
    attachment: file,
    type: "spreadsheet",
  });
  if (response.success) {
    dispatch(showToast("Uploaded Successfully!"));
  }
  return response;
};

export const getDashboardDetails = () => async (dispatch) => {
  await dispatch(showLoader(true));
  const response = await Api({
    method: "get",
    url: "/admission_board/get_dashboard_details",
  });
  if (response.success) {
    dispatch({
      type: UPDATE_APPLICANTS,
      payload: {
        newLeadsOverTime: response.new_leads_over_time,
        newAdmissionsOverTime: response.new_admissions_over_time,
      },
    });
  }
  await dispatch(showLoader(false));
};
