import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Select from 'react-select';
import Constants from '../../shared/constants';
import Modal from './baseModal';
import closeMark from '../../static/images/close-mark.svg';
import CreatableSelect from 'react-select/creatable';
import { createNewInstitutesTag, showToast, createNewInstitutesTagGroup } from "../../actions";

const BLOOD_GROUP = {
  'O POSITIVE': 0,
  'O NEGATIVE': 1,
  'A POSITIVE': 2,
  'A NEGATIVE': 3,
  'B POSITIVE': 4,
  'B NEGATIVE': 5,
  'AB POSITIVE': 6,
  'AB NEGATIVE': 7,
};

const customDropDownStyles = {
      control: (base, state) => ({
        ...base,
        height: 35,
        width: 200,
        backgroundColor: 'white',
        border: '1px solid #CDCFD6 !important',
      }),
      indicatorsContainer: (base, state) => ({
        ...base,
        height: 35,
      }),
      valueContainer: (base, state) => ({
        ...base,
        height: 40,
      }),
      option: (base, state) => ({
        ...base,
        textAlign: 'left',
      }),
    };
class EditDetailsModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      studentName: props.student.first_name,
      badgeId: props.student.student_id,
      studentId: props.student.id,
      studentCourse: props.student.course_name || '',
      selectedClassroom: '',
      selectedCampus: '',
      studentCampus: props.student.campus_name,
      classroomId: props.student.classroom_id || null,
      departmentId: props.student.department_id || null,
      courseId: props.student.course_id || null,
      classroomName: props.student.classroom_name,
      studentMobile: props.student.phone,
      studentEmail: props.student.email,
      studentPhone: props.student.phone,
      fatherName: props.student.father_name,
      fatherPhone: props.student.father_phone && props.student.father_phone.replaceAll('-', ''),
      fatherEmail: props.student.father_email,
      motherEmail: props.student.mother_email,
      motherName: props.student.mother_name,
      motherPhone: props.student.mother_phone,
      address: props.student.address,
      bloodGroup: props.student.blood_group,
      dob: props.student.dob,
      doj: props.student.doj,
      isActive: props.student.active || props.student === 'new',
      isHostelite: props.student.is_hostelite || (props.student === 'new' && false),
      showEditModal: false,
      newStudent: props.student === 'new',
      validPhone: props.student.phone && props.student.phone.length === 10,
      validfatherPhone: props.student.father_phone && props.student.father_phone.replaceAll('-', '').length === 10,
      validmotherPhone: props.student.mother_phone && props.student.mother_phone.length === 10,
      showAlert: false,
      subjectCodes: props.student.subject_codes
        ? props.student.subject_codes.filter((s) => !!s) : [],
      studentSelectedElectives: props.student.student_electives
      && props.electives ? Object.values(props.electives).filter((elective) => elective
        && elective.value && (props.student.student_electives.indexOf(elective.value) > -1)) : [],
      selectedBloodGroup: null,
      selectedInstitutesTag: null,
      selectedInstitutesTagIds: (props.student !== "new" && props.student.student_tags[0].value !== null && props.student.student_tags.map((tag) => {return tag.value})) || [],
      showNewTagModal: false,
      newTagName: "",
      selectedNewTagGroup: null,
    };
    this.toggleModal = this.toggleModal.bind(this);
    this.renderEdit = this.renderEdit.bind(this);
    this.allowNewStudentCreation = this.allowNewStudentCreation.bind(this);
    this.allowUpdateCurrentStudentDetails = this.allowUpdateCurrentStudentDetails.bind(this);
  }

  toggleModal() {
    const { showEditModal } = this.state;
    this.setState({
      showEditModal: !showEditModal,
    });
  }

  handleFormSubmit(e) {
    e.preventDefault();
  }

  updateFieldValueChanges(type, value) {
    if (type === 'studentMobile') {
      value = value.replace(' ', '');
      value = value && value.match(/^[0-9]*$/g) ? value.match(/^[0-9]*$/g).join('') : '';
      this.setState({ validPhone: value.length === 10, studentPhone: value });
    }
    if (type === 'fatherPhone') {
      value = value.replace(' ', '');
      value = value && value.match(/^[0-9]*$/g)
        ? value.match(/^[0-9]*$/g).join('')
        : '';
      this.setState({ validfatherPhone: value.length === 10, fatherPhone: value });
    }
    if (type === 'motherPhone') {
      value = value.replace(' ', '');
      value = value && value.match(/^[0-9]*$/g)
        ? value.match(/^[0-9]*$/g).join('')
        : '';
      this.setState({ validmotherPhone: value.length === 10, motherPhone: value });
    }
    this.setState({ [type]: value });
  }

  allowNewStudentCreation() {
    const {
      studentName, badgeId, studentMobile, studentEmail, classroomId, studentCampus,
      isActive, classroomName, courseId, studentSelectedElectives, dob, fatherName, fatherPhone, fatherEmail, motherName, motherPhone, motherEmail, address, bloodGroup, doj, selectedInstitutesTagIds, isHostelite,
    } = this.state;
    this.props.newStudentDetails(studentName, badgeId, studentMobile, studentEmail,
      classroomId, classroomName, studentCampus, courseId, isActive, dob, fatherName, fatherPhone, fatherEmail, motherName, motherPhone, motherEmail, address, bloodGroup, doj,
      studentSelectedElectives, selectedInstitutesTagIds, isHostelite);
    this.props.handleCloseModal();
  }

  allowUpdateCurrentStudentDetails() {
    const {
      studentName, badgeId, studentMobile, studentEmail, classroomId, courseId, studentCampus, isActive, classroomName, studentId, studentSelectedElectives, dob, fatherName, fatherPhone, fatherEmail, motherName, motherPhone, motherEmail, address, bloodGroup, doj, selectedInstitutesTagIds, isHostelite,
    } = this.state;
    this.props.updateCurrentStudentDetail(studentId, studentName, badgeId, studentMobile, studentEmail, classroomId, classroomName, studentCampus, courseId, dob, fatherName, fatherPhone, fatherEmail, motherName, motherPhone, motherEmail, address, bloodGroup, doj, isActive, studentSelectedElectives, selectedInstitutesTagIds, isHostelite);
    this.props.handleCloseModal();
  }

  handleMappingRemoval(ind) {
    const { studentSelectedElectives } = this.state;
    const filteredData = [...studentSelectedElectives];
    filteredData.splice(ind, 1);
    this.setState({ studentSelectedElectives: filteredData });
  }

  handleChange() {
    const { studentSelectedElectives, selectedElective } = this.state;
    if (selectedElective) {
      studentSelectedElectives.push(selectedElective);
      this.setState({ studentSelectedElectives, selectedElective: '', selectedSubject: '' });
    }
  }

  renderInput(value = '', inputType = 'text', handleChange = () => {}, placeholder = '') {
    return (
      <input
        type={inputType}
        value={value}
        placeholder={placeholder}
        style={{
          border: 'none',
          borderBottom: '1px solid #E4E7EE',
          width: '90%',
          outline: 'none',
          margin: '5px 0px 0px 0px',
          fontSize: 16,
        }}
        onChange={handleChange}
      />
    );
  }

  renderDropDown(filterItems, selectedItem, selectedFunction, item, defaultValue = '') {
    const defaultSelectedValue = defaultValue || { label: item, value: 0 };
    return (
      <div style={{
        display: 'flex', flexDirection: 'column', alignItems: 'flex-start', marginBottom: 30,
      }}
      >
        <div className="heading-3">{item}</div>
        <Select
          styles={customDropDownStyles}
          value={selectedItem || defaultSelectedValue}
          onChange={selectedFunction}
          options={filterItems}
          placeholder="All"
          maxMenuHeight={200}
          required
        />
      </div>
    );
  }

  renderCreatableSelect(filterItems, selectedItem, selectedFunction, item, defaultValue = '') {
    const defaultSelectedValue = defaultValue || { label: item, value: 0 };
    return (
      <div style={{
        display: 'flex', flexDirection: 'column', alignItems: 'flex-start', marginBottom: 30,
      }}
      >
        <div className="heading-3">{item}</div>
        <CreatableSelect
          styles={customDropDownStyles}
          value={selectedItem || defaultSelectedValue}
          onChange={selectedFunction}
          onCreateOption={this.props.createNewInstitutesTagGroup}
          options={filterItems}
          placeholder="All"
          maxMenuHeight={200}
          required
        />
      </div>
    );
  }

  renderNewTagModal() {
    const { showNewTagModal, newTagName, selectedNewTagGroup } = this.state;
    const { institutesTagGroups } = this.props;
    return (
    <div>
      <Modal
        showModal={showNewTagModal}
        handleCloseModal={() => this.setState({ showNewTagModal: false, newTagName: "", selectedNewTagGroup: null })}
        header={"Create New Tag"}
        >
        <div style={{padding: 15}}>
          <div style={{ paddingTop: 25 }}>
            Tag name
            {this.renderInput(newTagName, 'text', (event) => {
              this.setState({ newTagName: event.target.value });
            }, 'Track Name')}
          </div>
          <div style={{ paddingTop: 25 }}>
            {this.renderCreatableSelect(institutesTagGroups, selectedNewTagGroup, (event) => this.setState({ selectedNewTagGroup: event }), 'Tag Group')}
          </div>
          <div style={{ paddingTop: 25 }}>
            <div
              className="Button primary-Button"
              role="presentation"
              style={{ width: '200px', marginLeft: "auto" }}
              onClick={() => {
                if (!newTagName) {
                  this.props.showToast('Please Add Tag Name');
                } else {
                this.props.createNewInstitutesTag(newTagName, selectedNewTagGroup).then((response) => {
                  if (response.success) {
                    this.props.showToast(response.message);
                    this.setState({ showNewTagModal: false, newTagName: "", selectedNewTagGroup: null });
                    window.location.reload();
                  }
              });
            }}}>
            Create
            </div>
          </div>
        </div>
      </Modal>
    </div>
    );
  }

  renderIntstituteTags(){
    const { newStudent, selectedInstitutesTag } = this.state;
    const displayValue = selectedInstitutesTag ? selectedInstitutesTag : newStudent ? [] : this.props.student.student_tags.map((tag)=>{if(tag.value!==null){return tag}})
    return (
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "flex-start",
          marginTop: 30,
        }}
      >
        <div style={{ marginBottom: 5 }} className="heading-3">
          Tags
        </div>
        <CreatableSelect 
          options={this.props.institutesTags} 
          value={displayValue}
          onChange={(event)=>{this.setState(event === null ? {selectedInstitutesTag: [], selectedInstitutesTagIds: []} : {selectedInstitutesTag: event, selectedInstitutesTagIds: event.map((tag) => tag.value)})}}
          onCreateOption={(event) => {this.setState({showNewTagModal: true, newTagName: event})}}
          styles={{
            control: (base, state) => ({
              ...base,
              height: 100,
              width: 200,
              backgroundColor: 'white',
              border: '1px solid #CDCFD6 !important',
            }),
            indicatorsContainer: (base, state) => ({
              ...base,
              height: 100,
            }),
            valueContainer: (base, state) => ({
              ...base,
              height: 100,
            }),
            option: (base, state) => ({
              ...base,
              textAlign: 'left',
            }),
          }}
          maxMenuHeight={150}
          isMulti
          isClearable
        />
      </div>
    );
  } 

  renderSubjects() {
    const { subjectCodes } = this.state;
    if (subjectCodes.length) {
      return (
        <>
          <div className="heading-3">Subjects</div>
          <div>
            {subjectCodes.join(' , ')}
          </div>
        </>
      );
    }
    return (<div />);
  }

  renderSingleSelect(options, selectedOption, key, placeholder) {
    return (
      <Select
        value={selectedOption}
        onChange={(option) => this.setState({ [key]: option.value })}
        options={options}
        placeholder={placeholder}
        isSearchable
        isClearable
      />
    );
  }

  renderSubInfo(label, type, value, input = false, marginBottom = 20, required = true, inputType = 'text') {
    return (
      <div style={{ marginBottom }}>
        <div className="heading-3">{label}</div>
        { !input && <div className="content-text" style={{ marginTop: 5 }}>{value || '-' }</div>}
        { input
            && (
            <input
              type={inputType}
              value={this.state[type]}
              pattern={label === 'Address' ? '[a-zA-Z0-9,- ]' : inputType === 'text' ? '[a-zA-Z0-9 ]+' : '[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$'}
              style={{
                border: 'none', borderBottom: '1px solid #E4E7EE', width: '90%', outline: 'none',
              }}
              onChange={(event) => this.updateFieldValueChanges(type, event.target.value)}
              required={required}
            />
            )}
      </div>
    );
  }

  renderMappedItems(item, ind) {
    return (
      <div key={ind} style={{ display: 'flex' }}>
        <div role="presentation" className="clickable" style={{ marginRight: 15 }} onClick={() => this.handleMappingRemoval(ind)}>
          X
        </div>
        <div>
          {`${item ? item.label : ''}  -  ${item ? item.subject_name : ''}`}
        </div>
      </div>
    );
  }

  renderClassroomTeacherAssignmemt() {
    const { electives, student } = this.props;
    const {
      studentSelectedElectives, courseId,
    } = this.state;
    let filteredSubjects = [];
    if (electives && Object.values(electives)) {
      if (courseId) {
        filteredSubjects = Object.values(electives).filter((subject) => subject.course_id
        === courseId);
      } else if (student && student.course_id) {
        filteredSubjects = Object.values(electives).filter((subject) => subject.course_id
        === student.course_id);
      }
    }
    return (
      <div>
        <div style={{ display: 'flex', flexDirection: 'column', width: 'fit-content' }}>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <div style={{ marginRight: 15 }}>
              {this.renderDropDown(filteredSubjects, this.state.selectedElective, (event) => this.setState({ selectedElective: event }), 'Electives')}
            </div>
            <div
              role="presentation"
              style={{
                marginLeft: 15, width: 60, height: 36, marginBottom: 5, backgroundColor: '#40A7FF', color: '#FFFFFF', borderRadius: 4, display: 'flex', justifyContent: 'center', alignItems: 'center', cursor: 'pointer',
              }}
              onClick={() => this.handleChange()}
            >
              ADD
            </div>
          </div>
        </div>
        <div style={{ marginTop: 20 }}>
          {studentSelectedElectives && (studentSelectedElectives.length > 0) && <div className="heading-3" style={{ marginBottom: 5 }}>Electives</div>}
          {
            studentSelectedElectives.map((item, ind) => {
              return this.renderMappedItems(item, ind);
            })
            }
        </div>
      </div>
    );
  }

  renderClassroomSelection() {
    const {
      selectedDepartment, selectedCourse, classroomId, newStudent, courseId, departmentId, showAlert, studentCampus, selectedCampus,
    } = this.state;
    const currentStudentClassroom = selectedCourse ? {} : { value: 4, label: newStudent ? 'Select' : this.props.student.classroom_name, data: 'dummy' };
    const currentStudentCampus = selectedCourse ? {} : { value: 4, label: newStudent ? 'Select' : this.props.student.campus_name, data: 'dummy' };
    const currentStudentCourse = selectedDepartment ? {} : { value: 4, label: newStudent ? 'Select' : this.props.student.course_name, data: 'dummy' };
    const currentStudentDepartment = { value: 4, label: (newStudent && !this.props.student.department_name) ? 'Select' : this.props.student.department_name, data: 'dummy' };
    const {
      student, departments, campuses, courses, classrooms, isHigherEducationInstitute,
    } = this.props;
    const showCourses = newStudent || isHigherEducationInstitute;
    const courseIdExists = !isHigherEducationInstitute && (typeof courseId !== 'undefined') && (courseId !== null);
    const filteredClassrooms = isHigherEducationInstitute ? [] : classrooms;
    const filteredCourses = isHigherEducationInstitute ? [] : courses;
    if (isHigherEducationInstitute) {
      if ((selectedDepartment && selectedDepartment.value) || departmentId) {
        const currentDepartment = departmentId || selectedDepartment.value;
        filteredCourses = courses.filter((courses) => courses.department_id == currentDepartment);
      }

      if ((selectedCourse && selectedCourse.value) || courseId) {
        const currentCourse = courseId || selectedCourse.value;
        filteredClassrooms = classrooms.filter((classRoom) => classRoom.course_id == currentCourse);
      }
      return (
        <div>
          <div>
            <div style={{ display: 'flex' }}>
              {this.renderDropDown(departments, this.state.selectedDepartment, (event) => this.setState({ selectedDepartment: event, selectedCourse: '', departmentId: event.value }), 'Department', currentStudentDepartment)}
              {!showCourses ? this.renderSubInfo('Course', 'studentCourse', student.course_name, false)
                : (
                  <div style={{ marginLeft: 15 }}>
                    <div>
                      {this.renderDropDown(filteredCourses, this.state.selectedCourse,
                        (event) => this.setState({
                          selectedCourse: event, selectedClassroom: '', courseId: event.value, classroomId: '',
                        }), 'Semester', currentStudentCourse)}
                    </div>
                    {!courseIdExists && showAlert && showCourses && <div style={{ color: 'red' }}>Please select course</div>}
                  </div>
                )}
            </div>
            <div style={{ display: 'flex' }}>
              {this.renderDropDown(filteredClassrooms, this.state.selectedClassroom,
                (event) => this.setState({
                  selectedClassroom: event,
                  classroomId: event.value,
                  classroomName: event.label,
                  studentCampus: event.campus_name,
                }), 'Classrooms', currentStudentClassroom)}
            </div>
          </div>
          {this.renderClassroomTeacherAssignmemt()}
        </div>
      );
    }
    return (
      <>
        <div>
          <div style={{ textAlign: 'center' }}>
            <div style={{ alignItems: 'center' }}>
              {this.renderDropDown(campuses,
                this.state.selectedCampus, (event) => this.setState({
                  selectedCampus: event,
                  studentCampus: event.label,
                }), 'Campuses', currentStudentCampus)}
            </div>
            { !studentCampus && showAlert && <div style={{ color: 'red', marginBottom: 5 }}>Please select Campus</div>}
            <div style={{ alignItems: 'center' }}>
              {this.renderDropDown(filteredClassrooms.filter(
                (classroom) => classroom.campus_id === selectedCampus.value,
              ),
              this.state.selectedClassroom, (event) => this.setState({
                selectedClassroom: event,
                classroomId: event.value,
                classroomName: event.label,
                studentCampus: event.campus_name,
              }), 'Classrooms', currentStudentClassroom)}
            </div>
          </div>
          { !classroomId && showAlert && <div style={{ color: 'red', marginBottom: 5 }}>Please select Classroom</div>}
        </div>
        {false && isHigherEducationInstitute && this.renderSubjects()}
      </>
    );
  }

  renderEdit() {
    const {
      badgeId, classroomId, isActive, newStudent, courseId, validPhone, showAlert, validfatherPhone, validmotherPhone, motherPhone, fatherPhone, studentPhone, bloodGroup, selectedBloodGroup, selectedDepartment, isHostelite,
    } = this.state;
    const {
      student, isHigherEducationInstitute, classrooms, courses,
    } = this.props;
    const showCourses = newStudent || isHigherEducationInstitute;
    const currentStudentCourse = selectedDepartment ? {} : { value: 4, label: newStudent ? 'Select' : this.props.student.course_name, data: 'dummy' };
    const courseIdExists = !isHigherEducationInstitute && (typeof courseId !== 'undefined') && (courseId !== null);
    const currentBloodGroup = selectedBloodGroup ? {} : { value: 4, label: newStudent ? 'Select' : this.props.student.blood_group && (this.props.student.blood_group).replace('_', ' ').toUpperCase(), data: 'dummy' };
    const statusStyleActive = { border: '1px solid #40A7FF', color: '#40A7FF' };
    const statusStyleInactive = { border: '1px solid #CDCFD6', color: '' };
    const allowPhone = validPhone || isHigherEducationInstitute;
    const allowfatherPhone = validfatherPhone;
    const allowmotherPhone = validmotherPhone;
    const noerror = (!allowPhone && !!studentPhone) || (!allowfatherPhone && !!fatherPhone) || (!allowmotherPhone && !!motherPhone) || (fatherPhone === motherPhone && !!fatherPhone && !!motherPhone);
    const filteredCourses = isHigherEducationInstitute ? [] : courses;
    if (student) {
      return (
        <div style={{ padding: 50 }}>
          <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: 30 }}>
            <div className="heading-1">Student Details</div>
            <div role="presentation" onClick={this.props.handleCloseModal} style={{ cursor: 'pointer' }}>
              <img src={closeMark} alt="x-mark" />
            </div>
          </div>
          <form onSubmit={(!noerror && courseIdExists && classroomId) ? newStudent ? () => this.allowNewStudentCreation() : () => this.allowUpdateCurrentStudentDetails() : (event) => this.setState({ showAlert: true }, event.preventDefault())} style={{ display: 'flex' }}>
            <div style={{
              width: '300px', display: 'flex', justifyContent: 'center', paddingRight: '70px',
            }}
            >
              <div style={{ width: '100%' }}>
                {this.renderSubInfo('Name *', 'studentName', student.first_name, true)}
                {this.renderSubInfo('Date Of Birth *', 'dob', student.dob, true, 20, true, 'date')}
                {this.renderSubInfo('Student ID', 'badgeId', badgeId, newStudent, 20, false)}
                {this.renderSubInfo("Father's Name", 'fatherName', student.father_name, true, 'text', false)}
                {this.renderSubInfo("Mother's Name", 'motherName', student.mother_name, true, 20, false)}
                {!showCourses ? this.renderSubInfo('Course', 'studentCourse', student.course_name, false)
                  : (
                    <div>
                      {this.renderDropDown(filteredCourses, this.state.selectedCourse,
                        (event) => this.setState({
                          selectedCourse: event, selectedClassroom: '', courseId: event.value, classroomId: '',
                        }), isHigherEducationInstitute ? 'Semester' : 'Course', currentStudentCourse)}
                    </div>
                  )}
                { !courseIdExists && showAlert && showCourses && <div style={{ color: 'red', marginBottom: 5 }}>Please select course</div>}
              </div>
            </div>
            <div style={{
              width: '330px', display: 'flex', justifyContent: 'center', paddingLeft: '70px',
            }}
            >
              <div style={{ width: '350px' }}>
                {this.renderSubInfo("Student's Phone", 'studentMobile', student.phone, newStudent, 20, false)}
                {!allowPhone && studentPhone && newStudent && showAlert && <div style={{ color: 'red', marginBottom: 5 }}>The phone number must be length of 10*</div>}
                {this.renderSubInfo('Date Of Joining', 'doj', student.doj, true, 20, false, 'date')}
                {this.renderClassroomSelection()}
                {this.renderSubInfo(
                  "Father's Phone",
                  'fatherPhone', student.father_phone, true, 20, false,
                )}
                {!allowfatherPhone && fatherPhone && showAlert && (
                <div style={{ color: 'red', marginBottom: 5, fontSize: 14 }}>
                  The phone number must be length of 10*
                </div>
                )}
                {fatherPhone === studentPhone && showAlert && fatherPhone && studentPhone && (
                <div style={{ color: 'red', marginBottom: 5, fontSize: 14 }}>
                  The student phone number and Father/Guardian phone number should not be same
                </div>
                )}
                {this.renderSubInfo(
                  "Mother's Phone",
                  'motherPhone', student.mother_phone, true, 20, false,
                )}
                {!allowmotherPhone && motherPhone && showAlert && (
                <div style={{ color: 'red', marginBottom: 5, fontSize: 14 }}>
                  The phone number must be length of 10*
                </div>
                )}
                {fatherPhone === motherPhone && showAlert && fatherPhone && motherPhone && (
                <div style={{ color: 'red', marginBottom: 5, fontSize: 14 }}>
                  The student phone number and Father/Guardian phone number should not be same
                </div>
                )}
                <div style={{ paddingLeft: '20px' }}>
                  Active
                  <input
                    checked={isActive}
                    onChange={() => this.setState({ isActive: !isActive })}
                    className="react-switch-checkbox"
                    id="react-switch-new"
                    type="checkbox"
                  />
                  <label
                    style={{ backgroundColor: isActive && Constants().color.primary }}
                    className="react-switch-label"
                    htmlFor="react-switch-new"
                  >
                    <span className="react-switch-button" />
                  </label>
                </div>
                <div style={{ paddingLeft: '20px' }}>
                  Hostelite
                  <input
                    checked={isHostelite}
                    onChange={() => this.setState({ isHostelite: !isHostelite })}
                    className="react-switch-checkbox"
                    id="react-switch-new-1"
                    type="checkbox"
                  />
                  <label
                    style={{ backgroundColor: isHostelite && Constants().color.primary }}
                    className="react-switch-label"
                    htmlFor="react-switch-new-1"
                  >
                    <span className="react-switch-button" />
                  </label>
                </div>
              </div>
              <div style={{ width: '300px', display: 'flex', justifyContent: 'center' }}>
                <div style={{ width: '300px', paddingLeft: 20 }}>
                  {this.renderSubInfo("Student's Email", 'studentEmail', student.email, true, 20, false, 'email')}
                  {this.renderSubInfo('Address', 'address', student.address, true, 20, false, 'text')}
                  <div style={{ textAlign: 'center' }}>
                    <div style={{ alignItems: 'center' }}>
                      {this.renderDropDown(Object.keys(BLOOD_GROUP).map((group) => {
                        return {
                          value: BLOOD_GROUP[group], label: group,
                        };
                      }),
                      this.state.selectedBloodGroup, (event) => this.setState({
                        selectedBloodGroup: event,
                        bloodGroup: event.value,
                      }), 'Blood Group', currentBloodGroup)}
                    </div>
                  </div>
                  {this.renderSubInfo("Father's Email", 'fatherEmail', student.father_email, true, 20, false, 'email')}
                  {this.renderSubInfo("Mother's Email", 'motherEmail', student.mother_email, true, 20, false, 'email')}
                  <div style={{ textAlign: 'center', paddingBottom: '20px' }}>
                    <div style={{ alignItems: 'center' }}>
                      {this.renderIntstituteTags()}
                  </div>
                </div>
                  <div style={{
                    display: 'flex', width: 370, fontWeight: 700, justifyContent: 'space-between', marginTop: 240, marginLeft: -150,
                  }}
                  >
                    <div role="presentation" className="custom-secondary-button" style={{ height: 35, width: 180, border: '1px solid #CDCFD6' }} onClick={() => this.props.handleCloseModal()}>Cancel</div>
                    <button
                      type="submit"
                      style={{
                        width: 180, height: 35, marginBottom: 15, backgroundColor: '#40A7FF', color: '#FFFFFF', borderRadius: 4, display: 'flex', justifyContent: 'center', alignItems: 'center', lineHeight: 0, fontSize: 14,
                      }}
                    >
                      { newStudent ? 'Create' : 'Update' }
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
      );
    }
    return <div />;
  }

  render() {
    return (
      <div>
        <Modal showModal={this.props.showModal} handleCloseModal={this.props.handleCloseModal} height="520px" width="880px">
          <div>
            {this.renderEdit()}
          </div>
        </Modal>
        {this.state.showNewTagModal && this.renderNewTagModal()}
      </div>
    );
  }
}

const mapStateToProps = () => ({});

export default connect(mapStateToProps, { createNewInstitutesTag, showToast, createNewInstitutesTagGroup })(EditDetailsModal);
