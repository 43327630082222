import React, { Component } from 'react';
import { connect } from 'react-redux';
import Select from 'react-select';
import { getTestDetails, updateTestDetails } from '../../actions';
import Layout from '../layout';
import Constants from '../../shared/constants';
import MobileHeader from '../mobileHeader';
import { isMobileDevice } from '../../helpers/util';

const IS_MOBILE = isMobileDevice();

class TestConfig extends Component {
  constructor(props) {
    super(props);

    this.state = {
      showButtonLoader: false,
      showPaperSetterView: false,
      subjectTracks: {},
      trackQuestionCount: {},
      selectedPaperSetters: {},
      questionCount: 0,
    };
    this.updateField = this.updateField.bind(this);
    this.updateSubjectTrack = this.updateSubjectTrack.bind(this);
    this.updateTestDetails = this.updateTestDetails.bind(this);
  }

  componentDidMount() {
    const { id } = this.props.match.params;
    this.props.getTestDetails(id).then(() => {
      let trackQuestionCount = {};
      let selectedPaperSetters = {};
      let subjectTracks = {};
      const { testDetails, subjectList } = this.props;
      const questionCount = testDetails.question_count;
      if (!testDetails.summary || !testDetails.summary.track_question_count) {
        subjectList.forEach((subject) => {
          trackQuestionCount[`${subject.name}_1`] = `${questionCount[subject.name]}`;
          subjectTracks[subject.name] = 1;
        });
      } else {
        trackQuestionCount = testDetails.summary.track_question_count;
        selectedPaperSetters = testDetails.summary.selected_paper_setters;
        subjectTracks = testDetails.subject_tracks;
      }
      this.setState({
        subjectTracks,
        trackQuestionCount,
        selectedPaperSetters,
        questionCount,
      });
    });
  }

  updateField(name, type, value) {
    const field = this.state[name];
    field[type] = value;
    this.setState({ [name]: field });
  }

  updateSubjectTrack(subjectName, track) {
    const { subjectTracks, trackQuestionCount, selectedPaperSetters } = this.state;
    if (subjectTracks[subjectName] < track) {
      trackQuestionCount[`${subjectName}_${track}`] = '0';
      selectedPaperSetters[`${subjectName}_${track}`] = '';
    }
    subjectTracks[subjectName] = track;
    this.setState({ subjectTracks, trackQuestionCount, selectedPaperSetters });
  }

  updateTestDetails() {
    const { id } = this.props.match.params;
    const testQuestionCount = this.props.testDetails.total_question_count;
    const {
      subjectTracks, trackQuestionCount, selectedPaperSetters, questionCount,
    } = this.state;
    let status = 0;
    let totalQuestionCount = 0;
    this.setState({ showButtonLoader: true });
    this.props.subjectList.forEach((subject) => {
      const subjectTrackCount = subjectTracks[subject.name];
      for (let i = 0; i < Number(subjectTrackCount); i += 1) {
        totalQuestionCount += (Number(trackQuestionCount[`${subject.name}_${i + 1}`]) || 0);
        if ((i === 0 && Number(trackQuestionCount[`${subject.name}_${i + 1}`]) < 0) || (i > 0 && Number(trackQuestionCount[`${subject.name}_${i + 1}`]) < 1)) {
          status = 1;
        }
        if (Number(trackQuestionCount[`${subject.name}_${i + 1}`]) && !selectedPaperSetters[`${subject.name}_${i + 1}`]) {
          status = 2;
        }
      }
    });
    if (status === 0) {
      if (totalQuestionCount !== testQuestionCount) {
        status = 3;
      }
    }
    if (status === 0) {
      const payload = {
        id,
        selectedPaperSetters: JSON.stringify(selectedPaperSetters),
        trackQuestionCount: JSON.stringify(trackQuestionCount),
        subjectTracks: JSON.stringify(subjectTracks),
        questionCount: JSON.stringify(questionCount),
      };
      this.props.updateTestDetails(payload).then((data) => {
        if (data.success) {
          this.props.history.push(`/test_creation_overview/${id}`);
        } else {
          alert('Failed to upload try again after some timeout');
          this.setState({ showButtonLoader: false });
        }
      });
    } else {
      if (status === 1) {
        alert('Question count contains zero or negative numbers');
      } else if (status === 2) {
        alert('Some Paper Setters are not selected');
      } else if (status === 3) {
        alert(`Total question count should be ${testQuestionCount} but it is ${totalQuestionCount}`);
      }
      this.setState({ showButtonLoader: false });
    }
  }

  renderTestDetails(data) {
    const { subjectTracks, trackQuestionCount, selectedPaperSetters } = this.state;
    const { paperSetters } = this.props;
    const subjectTrackCount = subjectTracks[data.name];
    const customStyles = {
      control: (base, state) => ({
        ...base,
        height: 40,
        backgroundColor: '#ffffff',
        border: '1px solid var(--#ddd) !important',
      }),
      valueContainer: (base, state) => {
        return ({
          height: 40,
          paddingLeft: 10,
        });
      },
    };

    const trackDiv = [];
    for (let i = 0; i < subjectTrackCount; i += 1) {
      trackDiv.push(
        <div className="d-flex flex-row" style={{ alignItems: 'center', marginTop: 10 }}>
          {i + 1}
          &emsp;
          <div className="config-select" style={{ width: '40%', marginRight: 10 }}>
            <Select
              styles={customStyles}
              value={selectedPaperSetters[`${data.name}_${i + 1}`]}
              onChange={(selectedItem) => this.updateField('selectedPaperSetters', `${data.name}_${i + 1}`, selectedItem)}
              options={paperSetters}
              placeholder="Add paper setter"
            />
          </div>
          <input
            type="number"
            style={{ maxWidth: 60, marginBottom: 0 }}
            value={trackQuestionCount[`${data.name}_${i + 1}`]}
            onChange={(e) => this.updateField('trackQuestionCount', `${data.name}_${i + 1}`, `${e.target.value}`)}
          />
          &emsp;questions
          {i !== 0 && (
            <div
              role="presentation"
              className="btn btn-link ml-5"
              onClick={() => this.updateSubjectTrack(data.name, Number(subjectTrackCount) - 1)}
              style={{ fontSize: 14, color: 'red' }}
            >
              Remove Track
            </div>
          )}
        </div>,
      );
    }
    return (
      <React.Fragment key={data.id}>
        <tr>
          <td
            rowSpan={subjectTrackCount}
            style={{
              display: 'flex',
              alignItems: 'center',
              verticalAlign: 'middle',
              flexDirection: 'column',
            }}
          >
            {data.name}
            <div
              role="presentation"
              className="btn btn-link"
              onClick={() => this.updateSubjectTrack(data.name, Number(subjectTrackCount) + 1)}
              style={{ fontSize: 14, marginTop: 3 }}
            >
              Add Track
            </div>
          </td>
          <td>
            {trackDiv.map((track) => track)}
          </td>
        </tr>
      </React.Fragment>
    );
  }

  renderSubjectView(subjectData, track, showTrack) {
    const { trackQuestionCount, subjectTracks } = this.state;
    const subjectTrackCount = subjectTracks[subjectData.name];
    const customStyles = {
      control: (base, state) => ({
        ...base,
        zIndex: 0,
        height: 60,
        backgroundColor: '#ffffff',
        width: window.innerWidth - 120,
        border: '1px solid var(--#ddd) !important',
      }),
    };
    return (
      <div style={{ padding: '0px 20px 0px 20px' }}>
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <div style={{ color: '#454545', display: 'flex' }}>
            {subjectData.name}
            <div style={{ color: '#7C7C7C' }}>
              {showTrack && ` - ${track}`}
            </div>
          </div>
          <div style={{ color: '#454545', paddingRight: 10 }}> Count </div>
        </div>
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <input
            style={{
              width: window.innerWidth - 120, height: 50, paddingLeft: 10, border: '1px solid #ddd', borderRadius: 4,
            }}
            value={this.state.selectedPaperSetters[`${subjectData.name}_${track}`] ? this.state.selectedPaperSetters[`${subjectData.name}_${track}`].label : ''}
            onClick={() => this.setState({ showPaperSetterView: true, selectedKey: `${subjectData.name}_${track}` })}
          />
          {false && (
          <Select
            styles={customStyles}
            placeholder="Add paper setter"
            options={this.props.paperSetters}
            value={this.state.selectedPaperSetters[`${subjectData.name}_${track}`]}
            onChange={(selectedItem) => this.updateField('selectedPaperSetters', `${subjectData.name}_${track}`, selectedItem)}
          />
          )}
          <input type="number" style={{ width: 60, height: 50 }} onChange={(e) => this.updateField('trackQuestionCount', `${subjectData.name}_${track}`, e.target.value)} value={trackQuestionCount[`${subjectData.name}_${track}`]} />
        </div>
        {(!showTrack || track === 2) && (
        <div style={{ color: showTrack ? 'red' : 'blue', padding: 5, width: 'fit-content' }} onClick={() => this.updateSubjectTrack(subjectData.name, showTrack ? Number(subjectTrackCount) - 1 : Number(subjectTrackCount) + 1)}>
          {showTrack ? 'Remove' : 'Add'}
          Track
        </div>
        )}
      </div>
    );
  }

  renderSubjectQuestionCount(data) {
    const { subjectTracks } = this.state;
    const isMultiTrack = parseInt(subjectTracks[data.name], 10) === 2;
    return (
      <div style={{ marginBottom: 20 }}>
        {this.renderSubjectView(data, 1, isMultiTrack)}
        {isMultiTrack && this.renderSubjectView(data, 2, isMultiTrack)}
      </div>
    );
  }

  renderMobileView() {
    const { showButtonLoader } = this.state;
    const { testDetails, subjectList } = this.props;
    return (
      <div>
        <MobileHeader name="Paper Setters" color="white" backgroundColor={Constants().color.primary} showBackButton onImageClick={() => this.props.history.push('/list/test')} />
        <div style={{ padding: 20, marginTop: 50 }}>
          <div style={{ color: '#454545', fontWeight: 'bold', fontSize: 18 }}>
            {testDetails.name}
          </div>
          <div style={{ color: '#7C7C7C', fontSize: 14 }}>
            {testDetails.group_name}
            ;&nbsp;,
            {testDetails.total_question_count}
            Questions
          </div>
        </div>
        <div style={{ paddingBottom: 70 }}>
          {subjectList.map((subjectData) => (this.renderSubjectQuestionCount(subjectData)))}
        </div>
        <div
          role="presentation"
          onClick={this.updateTestDetails}
          style={{
            zIndex: 1, color: 'white', backgroundColor: Constants().color.primary, display: 'flex', justifyContent: 'center', alignItems: 'center', height: 50, position: 'fixed', bottom: 0, width: '100%',
          }}
        >
          {showButtonLoader ? <i className="fa fa-spinner fa-spin" /> : 'Select Questions'}
        </div>
      </div>
    );
  }

  renderPaperSetterElement(paperSetter) {
    const { selectedKey } = this.state;
    return (
      <div
        role="presentation"
        style={{ display: 'flex', padding: 20, borderBottom: '1px solid #ddd' }}
        onClick={() => {
          const { selectedPaperSetters } = this.state;
          selectedPaperSetters[selectedKey] = paperSetter;
          this.setState({ selectedPaperSetters, showPaperSetterView: false });
        }}
      >
        {paperSetter.label}

      </div>
    );
  }

  renderPaperSetterView() {
    const { paperSetters } = this.props;
    const { selectedKey } = this.state;
    return (
      <div>
        <MobileHeader name={`Paper Setters ${selectedKey.split('_')[0]}`} showCloseButton onImageClick={() => this.setState({ showPaperSetterView: false })} />
        <div style={{ marginTop: 60 }}>
          {paperSetters.map((paperSetter) => this.renderPaperSetterElement(paperSetter))}
        </div>
      </div>
    );
  }

  render() {
    const { testDetails, subjectList } = this.props;
    const { showPaperSetterView } = this.state;
    if (IS_MOBILE === '') {
      return <div />;
    }
    if (IS_MOBILE) {
      if (showPaperSetterView) {
        return this.renderPaperSetterView();
      }
      return this.renderMobileView();
    }
    return (
      <Layout globalHistory={this.props.history} feedback footerShow={false}>
        <div style={{ margin: '10%' }}>
          <h3>
            {testDetails.name}
          </h3>
          <div>
            {testDetails.group_name}
            ,&nbsp;
            {testDetails.total_question_count}
            Questions
          </div>
          <table className="table mt-5">
            <thead>
              <tr style={{ backgroundColor: '#f5f5f5' }}>
                <th style={{ fontSize: 18 }}> Subject </th>
                <th style={{ fontSize: 18 }} colSpan={3}> Tracks </th>
              </tr>
            </thead>
            <tbody>
              {subjectList.map((data) => (this.renderTestDetails(data)))}
            </tbody>
          </table>
          <div
            role="presentation"
            className="btn btn-lg mt-5"
            style={{
              fontSize: 16, justifyContent: 'center', backgroundColor: Constants().color.primary, color: 'white', width: 200,
            }}
            onClick={this.updateTestDetails}
          >
            Select Questions
          </div>
        </div>
      </Layout>
    );
  }
}

const mapStateToProps = ({ testConfiguration }) => ({
  testDetails: testConfiguration.testDetails,
  paperSetters: testConfiguration.paperSetters,
  subjectList: testConfiguration.subjectList,
});

export default connect(mapStateToProps, { getTestDetails, updateTestDetails })(TestConfig);
