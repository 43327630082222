import React from "react";
import Modal from "../../modal/baseModal";
import moment from "moment";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import Select from "react-select";
import { convertToCurrencyFormat } from "../../../helpers/util";
import { DEAL_STATUS, editDeal, createDeal, showToast } from "../../../actions";
import "../bdeTools.css";

const customStyles = {
  control: (base) => ({
    ...base,
    backgroundColor: "white",
    width: 300,
    height: 30,
    border: "1px solid #343737 !important",
  }),
  indicatorsContainer: (base) => ({
    ...base,
  }),
  valueContainer: (base) => ({
    ...base,
    height: "inherit",
    alignContent: "center",
  }),
};

const deal_statuses = Object.keys(DEAL_STATUS).map((key) => {
  return { label: key, value: DEAL_STATUS[key] };
});

const products = [
  { label: "QuestionBoard", value: 0 },
  { label: "TestBoard", value: 1 },
  { label: "ParentBoard", value: 2 },
  { label: "InsightBoard", value: 3 },
  { label: "MentorBoard", value: 4 },
  { label: "FeeBoard", value: 5 },
];

class BdeInstituteDeals extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showNewDealListModal: false,
      showEditDealModal: false,
      showCreateDealModal: false,
      editDeal: {
        next_payment_amount: 0,
        next_payment_due_date: "",
        subscription_start_date: "",
        subscription_end_date: "",
        status: 0,
      },
      newDealProducts: [],
      newDealDetails: [],
    };
  }

  componentDidMount() {}

  resetNewDeal() {
    this.setState({ newDealProducts: [], newDealDetails: [] });
  }

  removeProductButton(productName) {
    const { newDealProducts, newDealDetails } = this.state;
    return (
      <div
        className="Button"
        style={{
          margin: "auto 0",
          backgroundColor: "#d9534f",
          color: "white",
          borderColor: "#d9534f",
        }}
        onClick={() => {
          this.setState({
            newDealProducts: newDealProducts.filter((product) => {
              return product !== productName;
            }),
            newDealDetails: newDealDetails.filter((product) => {
              if (product.product_name !== productName) {
                return product;
              }
            }),
          });
        }}
      >
        Remove Product
      </div>
    );
  }

  createNewDealItems() {
    const { newDealProducts } = this.state;
    const { institute } = this.props;
    this.setState({
      newDealDetails: newDealProducts.map((product) => {
        if (product === "QuestionBoard") {
          return {
            institute_id: institute.id,
            product_name: product,
            subscription_start_date: null,
            subscription_end_date: null,
            discount: 0,
          };
        }
        return {
          institute_id: institute.id,
          product_name: product,
          subscription_start_date: null,
          subscription_end_date: null,
          quantity: 0,
          discount: 0,
        };
      }),
    });
  }

  updateNewDealDetails(productName, attribute, value) {
    const { newDealDetails } = this.state;
    let editedNewDealDetails = newDealDetails;
    editedNewDealDetails.map((dealItem) => {
      if (dealItem.product_name === productName) {
        dealItem[attribute] =
          attribute === "discount" || attribute === "quantity"
            ? parseInt(value) || 0
            : value;
        return dealItem;
      }
      return dealItem;
    });
    this.setState({ newDealDetails: editedNewDealDetails });
  }

  checkDealsValidity() {
    const { newDealDetails } = this.state;
    let validDeal = true;
    newDealDetails.map((deal) => {
      if (
        !deal.subscription_start_date ||
        !deal.subscription_end_date ||
        (!deal.product_name === "QuestionBoard" && deal.quantity === 0)
      ) {
        validDeal = false;
      }
    });
    return validDeal;
  }

  renderNewDealListModal() {
    const { newDealProducts } = this.state;
    const { products } = this.props;
    return (
      <Modal
        showModal={this.state.showNewDealListModal}
        handleCloseModal={() => {
          this.setState({ showNewDealListModal: false });
          this.resetNewDeal();
        }}
        header={`Select Products`}
      >
        <div style={{ padding: 15 }}>
          {products.map((product) => {
            if (product.name !== "QuestionPack") {
              return (
                <div
                  style={{
                    marginBottom: 5,
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "center",
                  }}
                  key={product.id}
                >
                  <span style={{ width: 200 }}>{product.name}</span>
                  <input
                    type="checkbox"
                    value={product.id}
                    id={product.name}
                    onClick={(event, clickedItem = product.name) => {
                      document.getElementById(clickedItem).checked
                        ? this.setState(
                            clickedItem === "QuestionBoard"
                              ? {
                                  newDealProducts: [
                                    ...newDealProducts,
                                    clickedItem,
                                    "QuestionPack",
                                  ],
                                }
                              : {
                                  newDealProducts: [
                                    ...newDealProducts,
                                    clickedItem,
                                  ],
                                }
                          )
                        : this.setState({
                            newDealProducts: newDealProducts.filter(
                              (product) => {
                                return product !== clickedItem;
                              }
                            ),
                          });
                    }}
                  />
                </div>
              );
            }
          })}
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              marginTop: 10,
              justifyContent: "right",
            }}
          >
            <div
              className="Button secondary-Button"
              style={{ width: 100, margin: "auto 10px" }}
              onClick={() => {
                this.setState({ showNewDealListModal: false });
                this.resetNewDeal();
              }}
            >
              Cancel
            </div>
            <div
              className="Button primary-Button"
              style={{ width: 100, margin: "auto 10px" }}
              onClick={() => {
                this.setState({
                  showNewDealListModal: false,
                  showCreateDealModal: true,
                });
                this.createNewDealItems();
              }}
            >
              Next
            </div>
          </div>
        </div>
      </Modal>
    );
  }

  handleInputChange(value, type) {
    this.setState({
      editDeal: { ...this.state.editDeal, [type]: value },
    });
  }
  renderCreateDealModal() {
    const { newDealProducts, newDealDetails } = this.state;
    const { institute, products } = this.props;
    return (
      <Modal
        showModal={this.state.showCreateDealModal}
        handleCloseModal={() => {
          this.setState({ showCreateDealModal: false });
          this.resetNewDeal();
        }}
        header={institute.name}
      >
        {newDealProducts.length === 0 ? (
          <div
            style={{
              margin: "10px 0 10px 0",
              color: "#1d4c7f",
              display: "flex",
              justifyContent: "center",
            }}
          >
            No Products Selected
          </div>
        ) : (
          <div style={{ padding: 10 }}>
            <div style={{ maxHeight: "80vh", overflowY: "scroll" }}>
              {newDealProducts.map((productName) => {
                return (
                  <div
                    key={productName}
                    style={{
                      border: "1px solid grey",
                      borderRadius: 6,
                      padding: 5,
                      marginBottom: 10,
                    }}
                  >
                    <div className="input-row-styling">
                      <div
                        style={{
                          margin: "auto 0",
                          color: "#1d4c7f",
                          fontWeight: 600,
                        }}
                      >
                        {productName}
                      </div>
                      {this.removeProductButton(productName)}
                    </div>
                    <div>
                      {productName !== "QuestionBoard" && (
                        <div className="input-row-styling">
                          <span className="input-row-elements">
                            {productName === "QuestionPack"
                              ? "Number of Questions"
                              : "Number Of Students"}
                          </span>
                          <input
                            type="number"
                            defaultValue={0}
                            className="input-row-elements"
                            onChange={(event) => {
                              this.updateNewDealDetails(
                                productName,
                                "quantity",
                                event.target.value
                              );
                            }}
                          />
                        </div>
                      )}
                      <div className="input-row-styling">
                        <span className="input-row-elements">Discount</span>
                        <input
                          type="number"
                          defaultValue={0}
                          className="input-row-elements"
                          onChange={(event) => {
                            this.updateNewDealDetails(
                              productName,
                              "discount",
                              event.target.value
                            );
                          }}
                        />
                      </div>
                      <div className="input-row-styling">
                        <span className="input-row-elements">
                          Subscription Start Date
                        </span>
                        <input
                          type="date"
                          style={{ margin: "auto 0", width: 200 }}
                          onChange={(event) => {
                            this.updateNewDealDetails(
                              productName,
                              "subscription_start_date",
                              event.target.value
                            );
                          }}
                        />
                      </div>
                      <div className="input-row-styling">
                        <span className="input-row-elements">
                          Subscription End Date
                        </span>
                        <input
                          type="date"
                          style={{ margin: "auto 0", width: 200 }}
                          onChange={(event) => {
                            this.updateNewDealDetails(
                              productName,
                              "subscription_end_date",
                              event.target.value
                            );
                          }}
                        />
                      </div>
                    </div>
                    <div style={{ padding: 5 }}>
                      Price Before Discount:{" "}
                      {convertToCurrencyFormat(
                        productName === "QuestionBoard"
                          ? products.find(
                              (product) => product.name === productName
                            ).default_rate
                          : newDealDetails.find(
                              (product) => product.product_name === productName
                            ).quantity *
                              products.find(
                                (product) => product.name === productName
                              ).default_rate
                      )}
                    </div>
                    <div style={{ padding: 5 }}>
                      Price After Discount:{" "}
                      {convertToCurrencyFormat(
                        ((100 -
                          newDealDetails.find(
                            (product) => product.product_name === productName
                          ).discount) /
                          100) *
                          (productName === "QuestionBoard"
                            ? products.find(
                                (product) => product.name === productName
                              ).default_rate
                            : newDealDetails.find(
                                (product) =>
                                  product.product_name === productName
                              ).quantity *
                              products.find(
                                (product) => product.name === productName
                              ).default_rate)
                      )}
                    </div>
                  </div>
                );
              })}
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                marginTop: 10,
                justifyContent: "right",
              }}
            >
              <div
                className="Button secondary-Button"
                style={{ width: 100, margin: "auto 10px" }}
                onClick={() => {
                  this.setState({ showCreateDealModal: false });
                  this.resetNewDeal();
                }}
              >
                Cancel
              </div>
              <div
                className="Button primary-Button"
                style={{ width: 100, margin: "auto 10px" }}
                onClick={() => {
                  if (!this.checkDealsValidity()) {
                    this.props.showToast(
                      "Please check values for: Number of Students/Questions, Subscription Start/End Dates"
                    );
                  } else {
                    this.props.createDeal(this.state.newDealDetails);
                    this.setState({ showCreateDealModal: false });
                    this.resetNewDeal();
                  }
                }}
              >
                Create
              </div>
            </div>
          </div>
        )}
      </Modal>
    );
  }

  renderEditDealModal() {
    const styles = { width: 200 };
    const { editDeal } = this.state;
    return (
      <Modal
        showModal={this.state.showEditDealModal}
        handleCloseModal={() => this.setState({ showEditDealModal: false })}
        header={`Edit Deal`}
        height={600}
      >
        <div style={{ padding: 15 }}>
          <div style={{ display: "flex" }}>
            <div style={styles}>Next Payment Date:</div>
            <input
              type="date"
              value={moment(editDeal.next_payment_due_date).format(
                "YYYY-MM-DD"
              )}
              style={{ width: 300 }}
              onChange={(event) => {
                this.handleInputChange(
                  event.target.value,
                  "next_payment_due_date"
                );
              }}
            ></input>
          </div>
          <div style={{ display: "flex" }}>
            <div style={styles}>Next Payment Amount:</div>
            <input
              type="text"
              value={editDeal.next_payment_amount}
              style={{ width: 300 }}
              onChange={(event) => {
                this.handleInputChange(
                  event.target.value,
                  "next_payment_amount"
                );
              }}
            ></input>
          </div>
          <div style={{ display: "flex" }}>
            <div style={styles}>Product:</div>
            <div>
              <Select
                value={editDeal.product_name}
                onChange={(selectedItem) => {
                  this.handleInputChange(selectedItem.label, "product_name");
                }}
                options={products}
                placeholder={editDeal.product_name}
                maxMenuHeight={200}
                styles={customStyles}
              />
            </div>
          </div>
          <div style={{ display: "flex", marginTop: 20 }}>
            <div style={styles}>Status:</div>
            <div>
              <Select
                value={editDeal.status}
                onChange={(selectedItem) => {
                  this.handleInputChange(selectedItem.label, "status");
                }}
                options={deal_statuses}
                placeholder={editDeal.status}
                maxMenuHeight={200}
                styles={customStyles}
              />
            </div>
          </div>
          <div style={{ display: "flex", marginTop: 20 }}>
            <div style={styles}>Students/Questions Count:</div>
            <input
              type="number"
              value={editDeal.quantity}
              style={{ width: 300 }}
              onChange={(event) => {
                this.handleInputChange(event.target.value, "quantity");
              }}
            ></input>
          </div>
          <div style={{ display: "flex" }}>
            <div style={styles}>Discount:</div>
            <input
              type="number"
              value={editDeal.discount}
              style={{ width: 300 }}
              onChange={(event) => {
                this.handleInputChange(event.target.value, "discount");
              }}
            ></input>
          </div>
          <div style={{ display: "flex" }}>
            <div style={styles}>Subscription Start Date:</div>
            <input
              type="date"
              value={moment(editDeal.subscription_start_date).format(
                "YYYY-MM-DD"
              )}
              style={{ width: 300 }}
              onChange={(event) => {
                this.handleInputChange(
                  event.target.value,
                  "subscription_start_date"
                );
              }}
            ></input>
          </div>
          <div style={{ display: "flex" }}>
            <div style={styles}>Subscription End Date:</div>
            <input
              type="date"
              value={moment(editDeal.subscription_end_date).format(
                "YYYY-MM-DD"
              )}
              style={{ width: 300 }}
              onChange={(event) => {
                this.handleInputChange(
                  event.target.value,
                  "subscription_end_date"
                );
              }}
            ></input>
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              marginTop: 30,
              justifyContent: "right",
            }}
          >
            <div
              className="Button secondary-Button"
              style={{ width: 100, margin: "auto 10px" }}
              onClick={() => {
                this.setState({ showEditDealModal: false });
              }}
            >
              Cancel
            </div>
            <div
              className="Button primary-Button"
              style={{ width: 100, margin: "auto 10px" }}
              onClick={() => {
                this.setState({ showEditDealModal: false });
                this.props.editDeal(editDeal);
              }}
            >
              Update
            </div>
          </div>
        </div>
      </Modal>
    );
  }

  renderActiveDealsTable = () => {
    const { institute } = this.props;
    if (institute.deals.active_deals.length === 0) {
      return (
        <div
          style={{
            margin: "20px 0 20px 0",
            fontSize: 23,
            fontWeight: 600,
            color: "#1d4c7f",
            display: "flex",
            justifyContent: "center",
          }}
        >
          No Active Deals
        </div>
      );
    }
    return (
      <>
        <div
          style={{
            margin: "20px 0 20px 0",
            fontSize: 23,
            fontWeight: 600,
            color: "#1d4c7f",
          }}
        >
          Active Deals
        </div>
        <table className="table-bordered">
          <thead>
            <tr>
              <th>Deal#</th>
              <th>Deal Owner</th>
              <th>Product</th>
              <th>Next Payment Amount</th>
              <th>Next Payment Date</th>
              <th>Trial</th>
              <th>Deal Status</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {institute.deals.active_deals.map((deal) => {
              return (
                <tr key={deal.deal_id}>
                  <td>{deal.deal_id}</td>
                  <td>{deal.bde_name}</td>
                  <td>{deal.product_name}</td>
                  <td>
                    {deal.next_payment_amount
                      ? convertToCurrencyFormat(deal.next_payment_amount)
                      : "-"}
                  </td>
                  <td>
                    {moment(deal.next_payment_due_date).format("DD/MM/YYYY")}
                  </td>
                  <td>{deal.is_trial ? "Yes" : "No"}</td>
                  <td>{deal.status}</td>
                  <td
                    style={{
                      textAlign: "center",
                      fontSize: 30,
                      color: "#1d4c7f",
                      cursor: "pointer",
                    }}
                    onClick={() => {
                      this.setState({
                        showEditDealModal: true,
                        editDeal: { ...deal },
                      });
                    }}
                  >
                    <i className="fa fa-edit"></i>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </>
    );
  };

  renderOtherDealsTable = () => {
    const { institute } = this.props;
    if (institute.deals.other_deals?.length === 0) {
      return (
        <div
          style={{
            margin: "20px 0 20px 0",
            fontSize: 23,
            fontWeight: 600,
            color: "#1d4c7f",
            display: "flex",
            justifyContent: "center",
          }}
        >
          No Other Deals
        </div>
      );
    }
    return (
      <>
        <div
          style={{
            margin: "20px 0 20px 0",
            fontSize: 23,
            fontWeight: 600,
            color: "#1d4c7f",
          }}
        >
          Other Deals
        </div>
        <table className="table-bordered">
          <thead>
            <tr>
              <th>Deal#</th>
              <th>Deal Created</th>
              <th>Product Name</th>
              <th>Trial</th>
              <th>Contract Value</th>
              <th>Next Payment Amount</th>
              <th>next Payment Date</th>
              <th>Invoice#</th>
            </tr>
          </thead>
          <tbody>
            {institute.deals.other_deals?.map((deal) => {
              return (
                <tr>
                  <td>{deal.deal_id}</td>
                  <td>{moment(deal.created_at).format("DD/MM/YYYY")}</td>
                  <td>{deal.product_name}</td>
                  <td>{deal.is_trial ? "Yes" : "No"}</td>
                  <td>{deal.final_price}</td>
                  <td>
                    {deal.next_payment_amount
                      ? convertToCurrencyFormat(deal.next_payment_amount)
                      : "-"}
                  </td>
                  <td>
                    {moment(deal.next_payment_due_date).format("DD/MM/YYYY") ||
                      "-"}
                  </td>
                  <td>{deal.invoice_id || "-"}</td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </>
    );
  };

  render() {
    const { showNewDealListModal, showEditDealModal, showCreateDealModal } =
      this.state;
    return (
      <div>
        {showEditDealModal && this.renderEditDealModal()}
        {showNewDealListModal && this.renderNewDealListModal()}
        {showCreateDealModal && this.renderCreateDealModal()}
        <div
          style={{ display: "flex", justifyContent: "flex-end", marginTop: 30 }}
        >
          <div
            className="Button primary-Button"
            style={{ width: 100 }}
            onClick={() => {
              this.setState({ showNewDealListModal: true });
            }}
          >
            New Deal
          </div>
        </div>
        {this.renderActiveDealsTable()}
        {this.renderOtherDealsTable()}
      </div>
    );
  }
}

BdeInstituteDeals.propTypes = {
  isLoading: PropTypes.bool,
  institute: PropTypes.object,
  products: PropTypes.array,
};

BdeInstituteDeals.defaultProps = {
  institute: {},
  products: [],
};

const mapStateToProps = ({ bdeTools }) => ({
  isLoading: bdeTools.isLoading,
  institute: bdeTools.institute,
  products: bdeTools.products,
});

export default withRouter(
  connect(mapStateToProps, { editDeal, createDeal, showToast })(
    BdeInstituteDeals
  )
);
