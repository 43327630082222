import { getUser } from './Api';
import Constants from '../shared/constants';
import { isMobileDevice } from './util';

const ua = require('universal-analytics');

const user = getUser();
const visitor = ua(Constants().google_analytics_key, user.id, { strictCidFormat: false });
const IS_MOBILE = isMobileDevice();

export const googleAnalyticsPage = (path, hostname, title) => {
  visitor.pageview(path, hostname, title).send();
};

export const initAnalytics = () => {
  window.amplitude.getInstance().init(Constants.amplitudeApiKey);
  if (user && user.id) {
    window.amplitude.getInstance().setUserId(user.id);
    window.amplitude.getInstance().setUserProperties(user);
  }
};

const googleAnalyticsEvent = (screenName, eventType, eventAction, properties) => {
  const params = {
    ec: eventType,
    ea: eventAction,
    el: JSON.stringify(properties),
    dp: screenName,
  };
  visitor.event(params).send();
};

export const sendEvent = (screenName, eventType, eventAction, properties = {}) => {
  const eventProperties = properties;
  eventProperties.isMobile = IS_MOBILE;
  delete eventProperties.token;
  googleAnalyticsEvent(screenName, eventType, eventAction, eventProperties);
};
