import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import ReactTooltip from 'react-tooltip';
import Cookies from 'universal-cookie';
import Loader from '../loading';
import { CustomizedFilter } from '../common';
import Navigator from '../dayNavigator';
import { getTimeTable } from '../../actions';

const cookies = new Cookies();
const roles = cookies.get('roles');
const isOnlyTeacher = roles && roles.is_teacher && !roles.is_dean && !roles.is_hod;
const boxHeight = 120;
const boxWidth = 150;

class TimeTable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showLoader: true,
      showCompactLoader: false,
      selectedClassroom: {},
      selectedDepartment: {},
      selectedTeacher: {},
      weeksAgo: 0,
      firstZone: 8,
      minutesData: 0,
      lastZone: 16,
    };
    this.renderDropDown = this.renderDropDown.bind(this);
    this.renderFilter = this.renderFilter.bind(this);
  }

  componentDidMount() {
    const { weeksAgo } = this.state;
    this.props.getTimeTable(weeksAgo).then(() => {
      const { zones } = this.props;
      this.setState({
        firstZone: zones[0],
        lastZone: zones[zones.length - 1] + 12,
        minutesData: (zones[0] || 0) * 60,
      });
      this.setState(() => ({ showLoader: false }));
    });
  }

  getDate(date) {
    return new Date(date).toLocaleString('en-US', {
      timeZone: 'Asia/Kolkata', hour12: true, hour: 'numeric', minute: '2-digit',
    }).toLowerCase();
  }

  renderData() {
    const {
      selectedClassroom, selectedTeacher, weeksAgo, selectedDepartment,
    } = this.state;
    this.setState({ showCompactLoader: true });
    this.props.getTimeTable(weeksAgo, selectedDepartment.value,
      selectedTeacher.value, selectedClassroom.value).then(() => {
      this.setState({ showCompactLoader: false });
    });
  }

  renderDropDown(filterItems, selectedItem, selectedFunction, item, minWidth = false) {
    const filterData = [];
    filterItems.map((data) => {
      filterData.push({ value: data.id, label: data.name });
    });
    return (
      <CustomizedFilter
        selectedItem={selectedItem}
        item={item}
        isClearable={false}
        showEmpty
        minWidth={minWidth}
        filterItems={[...filterData]}
        selectedFunction={selectedFunction}
      />
    );
  }

  renderTimeTable(dataIndex) {
    const { timeTabledata, isHigherEducation, zones } = this.props;
    const {
      firstZone, lastZone, minutesData, selectedClassroom,
    } = this.state;
    let prevEndMinute = 0;
    const data = timeTabledata[dataIndex];
    const liveClassData = {};
    let hour = 0;
    const tableData = data.live_class_data && data.live_class_data.length > 0
      ? JSON.parse(data.live_class_data) : [];
    tableData.map((liveClass) => {
      const date = new Date(liveClass.start_time);
      hour = date.getHours();
      if (hour >= firstZone && hour <= lastZone) {
        let minutes = date.getMinutes();
        let startTimeMinutes = (hour * 60) + (minutes) - minutesData;
        date.setMinutes(date.getMinutes() + liveClass.duration);
        minutes = date.getMinutes();
        let endTimeMinutes = (date.getHours() * 60) + (minutes) - minutesData;
        if (startTimeMinutes > prevEndMinute) {
          liveClassData[`${prevEndMinute}-${startTimeMinutes}`] = {
            startTimeMinutes: prevEndMinute,
            endTimeMinutes: startTimeMinutes,
            duration: startTimeMinutes - prevEndMinute,
            isEmpty: true,
          };
        }
        if (startTimeMinutes < prevEndMinute) {
          startTimeMinutes = prevEndMinute;
        }
        if (endTimeMinutes > (zones.length * 60)) {
          endTimeMinutes = zones.length * 60;
        }
        if (startTimeMinutes !== endTimeMinutes && startTimeMinutes < endTimeMinutes) {
          liveClassData[`${startTimeMinutes}-${endTimeMinutes}`] = {
            hour,
            minutes,
            startTimeMinutes,
            endTimeMinutes,
            start: this.getDate(liveClass.start_time),
            end: this.getDate(date),
            startTime: liveClass.start_time,
            endTime: date,
            title: liveClass.title,
            classroomNames: liveClass.classroom_names && liveClass.classroom_names.join(', '),
            subjectName: liveClass.subject_name,
            subjectCode: liveClass.subject_code,
            lectureName: liveClass.lecture_name,
            duration: endTimeMinutes - startTimeMinutes,
          };
        }
        prevEndMinute = prevEndMinute < endTimeMinutes ? endTimeMinutes : prevEndMinute;
      }
    });
    const lastZoneTiming = ((lastZone + 1) * 60) - minutesData;
    if (prevEndMinute < lastZoneTiming) {
      liveClassData[`${prevEndMinute}-${lastZoneTiming}`] = {
        startTimeMinutes: prevEndMinute,
        endTimeMinutes: (lastZoneTiming),
        duration: (lastZoneTiming) - prevEndMinute,
        isEmpty: true,
      };
    }
    return (
      <div style={{ width: boxWidth }}>
        <div style={{
          width: boxWidth, fontSize: 20, height: boxHeight - 40, color: '#343737', fontWeight: 'bold',
        }}
        >
          <div style={{ marginBottom: 5, fontSize: 16 }}>{data.day_name.toUpperCase()}</div>
          <div>{data.day_num}</div>
        </div>
        <div style={{ width: boxWidth }}>
          {Object.keys(liveClassData).map((type) => {
            const currentData = liveClassData[type];
            let perHeight = (currentData.duration / 60) * 100;
            perHeight = (boxHeight) * (perHeight / 100);

            if (currentData.isEmpty) {
              return (
                <div
                  style={{
                    height: perHeight, background: '#FFFFFF',
                  }}
                />
              );
            }
            return (
              <div
                style={{
                  height: perHeight,
                  display: 'flex',
                  flexDirection: 'column',
                  border: 0,
                  justifyContent: 'flex-start',
                  paddingTop: '2%',
                  borderBottom: 0,
                  fontSize: perHeight < 30 ? 10 : 14,
                  borderLeft: '4px solid #33BAE1',
                  borderRadius: '5px 0 0 5px',
                  marginRight: 2,
                  marginBottom: 3,
                  background: '#D6EEF7',
                  fontWeight: 'bold',
                  zIndex: 1,
                  cursor: 'pointer',
                  flexWrap: 'nowrap',
                  overflow: 'scroll',
                }}
                data-tip={`<div style="max-width: 250px"><div style="text-align: left; margin-bottom: 3px;">${isHigherEducation ? `${currentData.subjectCode}:` : ''} ${currentData.subjectName}</div style="text-align: left; margin-bottom: 3px;">${isHigherEducation ? `<div style="text-align: left; margin-bottom: 3px;">${currentData.lectureName}</div>` : ''}${`<div style="text-align: left">${currentData.start} to ${currentData.end}</div>`}</div>`}
              >
                <ReactTooltip html />
                {!selectedClassroom.value && (
                  <div
                    style={{ zIndex: 1 }}
                  >
                    {currentData.classroomNames}
                  </div>
                )}
                <div
                  style={{ zIndex: 1 }}
                >
                  {isHigherEducation ? currentData.subjectCode
                    : currentData.subjectName}
                </div>
              </div>
            );
          })}
        </div>
      </div>
    );
  }

  renderFilter() {
    const {
      classrooms, teachers, isHigherEducation, departments,
    } = this.props;
    const { selectedClassroom, selectedTeacher, selectedDepartment } = this.state;
    if (isOnlyTeacher) {
      return <div />;
    }
    return (
      <div style={{
        display: 'flex', whiteSpace: 'nowrap', fontSize: 16, fontWeight: 'bold', width: '100%', justifyContent: 'flex-end',
      }}
      >
        {roles.is_dean && this.renderDropDown([...departments],
          { ...selectedDepartment }, (selectedListItem) => {
            this.setState({
              selectedDepartment: selectedListItem || {},
              selectedClassroom: {},
              selectedTeacher: {},
            }, () => {
              this.renderData();
            });
          }, 'Select Department')}
        {this.renderDropDown([...classrooms], { ...selectedClassroom }, (selectedListItem) => {
          this.setState({ selectedClassroom: selectedListItem || {}, selectedTeacher: {} }, () => {
            this.renderData();
          });
        }, 'Select Classroom', 200)}
        {isHigherEducation && this.renderDropDown([...teachers],
          { ...selectedTeacher }, (selectedListItem) => {
            this.setState({
              selectedTeacher: selectedListItem || {},
              selectedClassroom: {},
            }, () => {
              this.renderData();
            });
          }, 'Select Teacher', 180)}
      </div>
    );
  }

  renderTimeTableData() {
    const {
      firstZone, selectedClassroom, selectedTeacher, showCompactLoader,
    } = this.state;
    const { timeTabledata, zones } = this.props;
    if (showCompactLoader) {
      return (
        <div style={{
          marginTop: 30, height: window !== 'undefined' && window.innerHeight - 250, display: 'flex', justifyContent: 'center', alignItems: 'center',
        }}
        >
          <Loader left="20%" width="80%" />
        </div>
      );
    }
    if (!isOnlyTeacher && !(selectedClassroom.value || selectedTeacher.value)) {
      return (
        <div
          style={{
            display: 'flex', justifyContent: 'center', alignItems: 'center', color: '#454545', height: 80, backgroundColor: '#FFFFFF', marginTop: 100, border: '1px solid', fontSize: 24, fontFamily: 'Roboto',
          }}
        >
          Select Classroom or Teacher to get the Time Table
        </div>
      );
    }
    return (
      <div
        style={{ display: 'flex', marginTop: 30, placeContent: 'center' }}
      >
        <div style={{ position: 'absolute' }}>
          <div style={{ height: boxHeight - 40 }} />
          {zones.map((zone, index) => {
            const zoneIndex = index + firstZone;
            const currentZone = zoneIndex > 12 ? zoneIndex - 12 : zoneIndex;
            return (
              <div style={{ height: boxHeight, display: 'flex' }}>
                <div style={{
                  marginRight: 40,
                  width: 50,
                  color: '#343737',
                  fontWeight: 'bold',
                }}
                >
                  {currentZone}
                  &nbsp;
                  {zoneIndex <= 12 ? 'AM' : 'PM'}
                </div>
                <div style={{ borderTop: '1px solid #E6E5E6', display: 'flex' }}>
                  {timeTabledata
                    && Object.keys(timeTabledata).map(() => {
                      return (
                        <div style={{
                          height: boxHeight, border: '1px solid #E6E5E6', borderTop: 0, width: boxWidth,
                        }}
                        />
                      );
                    })}
                </div>
              </div>
            );
          })}
        </div>
        <div style={{
          display: 'flex', justifyContent: 'center', textAlign: 'center', marginLeft: 90,
        }}
        >
          {timeTabledata
            && Object.keys(timeTabledata).map((index) => this.renderTimeTable(index))}
        </div>
      </div>
    );
  }

  render() {
    const { showLoader, weeksAgo } = this.state;
    if (showLoader) {
      return <Loader />
    }
    return (
      <div style={{ fontFamily: 'Roboto', background: '#F7F9FB' }}>
        <div style={{
          display: 'flex', justifyContent: 'space-between', alignItems: 'center', padding: '20px 60px', boxShadow: '0px 3px 6px #343B4014', height: 80, background: '#FFFFFF', width: '80%', position: 'fixed', zIndex: 10, fontSize: 24, fontWeight: 'bold',
        }}
        >
          <div>Time Table</div>
          <div style={{ display: 'flex', paddingLeft: 25 }}>
            <Navigator
              text="This Week"
              daysAgo={weeksAgo}
              reloadPage={(weeks) => this.setState({ weeksAgo: weeks }, () => this.renderData())}
            />
          </div>
        </div>
        <div style={{
          padding: 60, width: '100%', paddingTop: 90, display: 'flex', flexDirection: 'column',
        }}
        >
          {this.renderFilter()}
          {this.renderTimeTableData()}
        </div>
      </div>
    );
  }
}

TimeTable.propTypes = {
  timeTabledata: PropTypes.object,
  teachers: PropTypes.array,
  classrooms: PropTypes.array,
  zones: PropTypes.array,
  isHigherEducation: PropTypes.bool,
  departments: PropTypes.array,
};

TimeTable.defaultProps = {
  timeTabledata: {},
  teachers: [],
  classrooms: [],
  departments: [],
  zones: [],
  isHigherEducation: false,
};

const mapStateToProps = ({ virtualMeeting }) => ({
  timeTabledata: virtualMeeting.timeTabledata,
  teachers: virtualMeeting.teachers,
  departments: virtualMeeting.departments,
  classrooms: virtualMeeting.classrooms,
  zones: virtualMeeting.zones,
  isHigherEducation: virtualMeeting.isHigherEducation,
  startDate: virtualMeeting.startDate,
  endDate: virtualMeeting.endDate,
});
export default connect(mapStateToProps, { getTimeTable })(TimeTable);
