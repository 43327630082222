import React, { Component } from 'react';
import { connect } from 'react-redux';
import { createBrowserHistory } from 'history';
import Layout from '../layout';
import Constants from '../../shared/constants';
import {
  getAllClassrooms, getOfflineAllClassroom, addItemToRecentList, postOfflineAttendance,
} from '../../actions';
import db from '../../helpers/db';
import { getDateString } from '../../helpers/util';

const history = createBrowserHistory({
  forceRefresh: true,
});

class AllClass extends Component {
  constructor(props) {
    super(props);
    this.state = {
      recentClassrooms: [],
    };
  }

  componentDidMount() {
    this.props.getAllClassrooms();
    db.recentClassrooms.toArray((result) => {
      this.setState({ recentClassrooms: result });
    });
    if (navigator.onLine) {
      this.props.getAllClassrooms();
      this.props.postOfflineAttendance();
    } else {
      this.props.getOfflineAllClassroom();
    }
  }

  routeStudentClassList(item) {
    this.props.addItemToRecentList(item);
    this.props.history.push(`/attendance/${item.id}/get_student_list?name=${item.name}&campus_id=${item.campus_id}`);
  }

  renderRecentClass(item) {
    return (
      <div
        role="presentation"
        className="card-view mr-5 py-1 cursor"
        key={item.id}
        onClick={() => { this.routeStudentClassList(item); }}
      >
        <p style={{ marginBottom: '0px', fontWeight: 'bold', whiteSpace: 'nowrap' }}>{item.name}</p>
      </div>
    );
  }

  renderClassroom(item) {
    return (
      <div
        role="presentation"
        className="mr-5 cursor"
        style={{
          backgroundColor: 'white', padding: '10px', marginBottom: '10px', height: '40px', width: '100%',
        }}
        key={item.id}
        onClick={() => { this.routeStudentClassList(item); }}
      >
        <p style={{ marginBottom: '0px', fontWeight: 'bold' }}>{item.name}</p>
      </div>
    );
  }

  renderCampus(campusData) {
    const campusName = Object.keys(campusData)[0];
    const classroomDetail = Object.values(campusData)[0];
    if (classroomDetail.length > 0) {
      return (
        <div className="d-flex m-2 flex-column" key={campusName}>
          <p style={{ marginBottom: '0px', fontWeight: 'bold' }}>{campusName}</p>
          <div className="m-2 row">
            { classroomDetail.map((classroom) => (this.renderClassroom(classroom))) }
          </div>
        </div>
      );
    }
    return <div />;
  }

  render() {
    const today = getDateString(new Date());
    const { allClassrooms } = this.props;
    const { recentClassrooms } = this.state;
    if (allClassrooms.length === 0) {
      return (
        <Layout globalHistory={this.props.history} feedback footerShow={false}>
          <div className="row">
            <div style={{
              display: 'flex', justifyContent: 'space-between', backgroundColor: Constants().color.primary, width: '100%', height: '50px',
            }}
            >
              <div className="ml-2" style={{ display: 'flex', alignItems: 'center' }}>
                <p style={{
                  color: 'white', fontSize: '15px', marginBottom: '0px', fontWeight: 'bold',
                }}
                >
                  Attendance
                </p>
                <p style={{
                  color: 'white', fontSize: '15px', marginBottom: '0px', fontWeight: 'bold',
                }}
                >
                  {today}
                </p>
              </div>
              <div style={{ marginRight: '5%', display: 'flex', alignItems: 'center' }}>
                <p />
                <p style={{
                  color: 'white', fontSize: '15px', marginBottom: '0px', fontWeight: 'bold',
                }}
                >
                  {today}
                </p>
              </div>
            </div>
          </div>
          <div className="row ml-2">
            <p style={{ fontSize: '18px', marginBottom: '0px', fontWeight: 'bold' }}>You don't have any classes.</p>
          </div>
        </Layout>
      );
    }
    return (
      <Layout globalHistory={this.props.history} feedback footerShow={false}>
        <div className="row">
          <div style={{
            display: 'flex', justifyContent: 'space-between', backgroundColor: Constants().color.primary, width: '100%', height: '60px',
          }}
          >
            <div className="flex-column" style={{ display: 'flex', alignItems: 'center', marginLeft: '25px' }}>
              <p style={{
                color: 'white', fontSize: '15px', marginBottom: '0px', fontWeight: 'bold', marginTop: '5px',
              }}
              >
                Attendance
              </p>
              <p style={{
                color: 'white', fontSize: '15px', marginBottom: '0px', fontWeight: 'bold', marginTop: '5px',
              }}
              >
                {today}
              </p>
            </div>
            <div
              role="presentation"
              className="cursor"
              style={{
                marginRight: '5%', display: 'flex', alignItems: 'center', border: '1px solid white', height: '30px', width: '100px', alignSelf: 'center',
              }}
              onClick={() => this.props.history.push('/attendance_session/')}
            >
              <p style={{
                color: 'white', fontSize: '15px', marginBottom: '0px', fontWeight: 'bold', marginLeft: '30px', alignSelf: 'center', padding: '5px',
              }}
              >
                Edit
              </p>
            </div>
          </div>
        </div>
        { recentClassrooms.length > 0 && (
        <div className="row">
          <div className="d-flex flex-column" style={{ width: '100%', backgroundImage: 'linear-gradient(237deg, #677BD5 25%, #2AF598 100%)', paddingLeft: '4%' }}>
            <div className="m-2 font-15">
              <p style={{ color: 'white', marginBottom: '0px', fontWeight: 'bold' }}>Recent</p>
            </div>
            <div className="d-flex m-2" style={{ marginTop: '-10px', flexFlow: 'row wrap', paddingBottom: '10px' }}>
              { recentClassrooms.map((recentClass) => (this.renderRecentClass(recentClass))) }
            </div>
          </div>
        </div>
        ) }
        <div className="row mt-2">
          <div className="d-flex flex-column" style={{ paddingLeft: '4%', width: '100%' }}>
            <div className="m-2" style={{ backgroundColor: '#FAFAFA' }}>
              <p style={{ fontSize: '18px', marginBottom: '0px', fontWeight: 'bold' }}>Classrooms</p>
            </div>
            <div>
              { allClassrooms.map((campusData) => (this.renderCampus(campusData)))}
            </div>
          </div>
        </div>
      </Layout>
    );
  }
}

const mapStateToProps = ({ attendance }) => ({
  allClassrooms: attendance.allClassrooms,
});

export default connect(mapStateToProps, {
  getAllClassrooms,
  getOfflineAllClassroom,
  postOfflineAttendance,
  addItemToRecentList,
})(AllClass);
