import {
  GET_PROPERTIES_INSIGHT, GET_QUESTION_DISTRIBUTION, GET_SIMILAR_QUESTIONS, GET_REPEATED_QUESTIONS,
} from '../actions';

const initialState = {
  propertiesInsight: {},
  repeatedQuestionsPreviousPaper: {},
  questionDistribution: {},
  similarQuestions: {},
};

export default function (state = initialState, action) {
  switch (action.type) {
    case GET_PROPERTIES_INSIGHT: {
      return { ...state, propertiesInsight: action.payload };
    }
    case GET_REPEATED_QUESTIONS: {
      return { ...state, repeatedQuestionsPreviousPaper: action.payload };
    }
    case GET_QUESTION_DISTRIBUTION: {
      return { ...state, questionDistribution: action.payload };
    }
    case GET_SIMILAR_QUESTIONS: {
      return { ...state, similarQuestions: action.payload };
    }
    default:
      return state;
  }
}
