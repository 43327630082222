import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Layout from '../layout';
import { getQuizDetails, createQuiz, showToast } from '../../actions';
import Loader from '../loading';
import { returnCurrentDate } from '../../helpers/util';
import SnackBar from '../snackBar';
import Menubar from '../menubar';
import '../../static/tools.css';


class LiveQuizBoard extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      quizName: '',
      quizDate: '',
      showLoader: false,
    };
    this.createQuiz = this.createQuiz.bind(this);
  }

  componentDidMount() {
    this.setState({ showLoader: true });
    this.props.getQuizDetails().then(() => {
      this.setState({ showLoader: false });
    });
  }

  onTodoChange(value, type) {
    const { quizDate } = this.state;
    if (type === 'quizDate') {
      if (new Date().setHours(0, 0, 0, 0) > new Date(value)) {
        this.setState({ [type]: quizDate });
        this.props.showToast('Quiz date should be in Future');
        return;
      }
    }
    this.setState({ [type]: value });
  }

  createQuiz() {
    const { quizName, quizDate } = this.state;
    if (quizName && quizDate) {
      this.props.createQuiz(quizName, quizDate).then((response) => {
        if (response.success) {
          const quizId = response.quiz.id;
          this.props.history.push(`/quiz/quiz_questions/${quizId}`);
        }
      });
    } else {
      this.props.showToast('Both Quiz Name And Quiz Date Should be present');
    }
  }

  renderStudentListHeader() {
    const style = {
      width: '15%', borderRight: '1px solid #DFE3EA', paddingLeft: 20, whiteSpace: 'nowrap',
    };
    return (
      <>
        <th style={{
          width: '5%', borderRight: '1px solid #DFE3EA', paddingLeft: 20, whiteSpace: 'nowrap',
        }}
        >
          No
        </th>
        <th
          style={{
            width: '25%', borderRight: '1px solid #DFE3EA', paddingLeft: 20, whiteSpace: 'nowrap', textAlign: 'left',
          }}
        >
          Name
        </th>
        <th style={style}>Quiz Date</th>
        <th style={style}>Question Count</th>
        <th style={style}>Response Count</th>
        <th style={{
          width: '15%', paddingLeft: 20, whiteSpace: 'nowrap', textAlign: 'left',
        }}
        >
          Status
        </th>
      </>
    );
  }

  renderQuizList(quiz, index) {
    return (
      // create a check for quiz.name when it exeeds a max 30 char
      <tr style={{ fontSize: '14px', height: 50 }}>
        <td style={{ textAlign: 'center', verticalAlign: 'middle' }}>{index}</td>
        <td
          role="presentation"
          className="clickable"
          style={{
            textAlign: 'left', verticalAlign: 'middle', cursor: 'pointer', color: '#00DAFF',
          }}
          onClick={() => this.props.history.push(`/quiz/quiz_questions/${quiz.id}`)}
        >
          {quiz.name}

        </td>
        <td style={{ textAlign: 'center', verticalAlign: 'middle' }}>{quiz.quiz_dates}</td>
        <td style={{ textAlign: 'center', verticalAlign: 'middle' }}>{quiz.question_count}</td>
        <td style={{ textAlign: 'center', verticalAlign: 'middle' }}>{quiz.response_count}</td>
        <td style={{ textAlign: 'center', verticalAlign: 'middle' }}>{quiz.status}</td>
      </tr>
    );
  }

  renderNewQuiz() {
    const { quizName, quizDate } = this.state;
    return (
      <div className="Card-View" style={{ margin: 20 }}>
        <div style={{ display: 'flex' }}>
          <div style={{ margin: '1%', alignSelf: 'center' }}>
            Quiz Name
          </div>

          <input
            className="input-field"
            type="text"
            onChange={(e) => this.onTodoChange(e.target.value, 'quizName')}
            value={quizName}
            style={{
              width: '40%', marginBottom: 0, height: 40,
            }}
          />

          <div style={{ margin: '1%', marginLeft: 40, alignSelf: 'center' }}>
            Quiz Date
          </div>

          <input
            className="input-field"
            type="date"
            min={returnCurrentDate()}
            onChange={(e) => this.onTodoChange(e.target.value, 'quizDate')}
            value={quizDate}
            style={{
              width: '20%', marginBottom: 0, height: 40,
            }}
          />

          <div
            className="edit-button"
            role="presentation"
            style={{
              marginLeft: 40, marginBottom: 0, width: '10%', height: 40, backgroundColor: '#3994AE',
            }}
            onClick={() => this.createQuiz()}
          >
            Create
          </div>
        </div>

      </div>
    );
  }

  renderAllQuiz() {
    const { quizList } = this.props;
    return (
      <div>
        <table className="table" style={{ textAlign: 'center', marginBottom: 0, border: '1px solid #CDCFD6' }}>
          <thead style={{ background: '#F6F8FA', color: '#23394C', border: '1px solid #CDCFD6' }}>
            <tr
              className="table table-hover"
              style={{
                fontSize: '18px', fontWeight: 700, height: 60,
              }}
            >
              {this.renderStudentListHeader()}
            </tr>
            <tr style={{ fontSize: 16, fontFamily: 'Nunito', fontWeight: 400 }} />
          </thead>
          <tbody>
            {quizList.map((quiz, index) => this.renderQuizList(quiz, index + 1))}
          </tbody>
        </table>
      </div>
    );
  }

  renderContent() {
    return (
      <div style={{ padding: '45px 80px 75px 50px', backgroundColor: '#F5F8FA' }}>
        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <div className="heading-1" style={{ color: '#11426C', fontSize: 22 }}>New Quiz</div>
        </div>
        <div>
          {this.renderNewQuiz()}
        </div>
        <div style={{
          display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginTop: 30,
        }}
        >
          <div className="heading-1" style={{ color: '#11426C', fontSize: 22 }}>All Quiz</div>
        </div>
        <div>
          {this.renderAllQuiz()}
        </div>
      </div>
    );
  }

  render() {
    const quizMenu = [{ label: 'Highlights', route: '/quizboard' }];
    const { showLoader } = this.state;
    const { quizList, history } = this.props;
    if (quizList.length > 0) {
      quizMenu.push({ label: 'Quizzes', route: `/quiz/quiz_update/${quizList[0].id}` });
    }
    if (showLoader) {
      return <Loader />;
    }
    return (
      <Layout globalHistory={this.props.history} hideHeader>
        <SnackBar />
        <div style={{ width: '100%', display: 'flex' }}>
          <div style={{ width: '20%', padding: 0, zIndex: 1 }}>
            <Menubar history={history} menuList={[...quizMenu]} selectedOption={0} redirectToRoot />
          </div>
          <div style={{ width: '80%' }}>
            {this.renderContent()}
          </div>
        </div>
      </Layout>
    );
  }
}

LiveQuizBoard.propTypes = {
  quizList: PropTypes.array,
};

LiveQuizBoard.defaultProps = {
  quizList: [],
};

const mapStateToProps = ({ quiz }) => ({
  quizList: quiz.quizList, // doubt is it correct?
});

export default connect(mapStateToProps, {
  createQuiz, getQuizDetails, showToast,
})(LiveQuizBoard);
