import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Modal from './baseModal';
import closeMark from '../../static/images/close-mark.svg';
import info from '../../static/images/info.svg';
import Constants from '../../shared/constants';

class WaterMarkUploader extends Component {
  constructor(props) {
    super(props);
    this.state = {
      imageFile: null,
      imageUploaded: false,
      displayImage: false,
    };
    this.toggleModal = this.toggleModal.bind(this);
    this.uploadImage = this.uploadImage.bind(this);
    this.uploadImageFile = this.uploadImageFile.bind(this);
  }

  uploadImageFile(event) {
    const { imageUploaded, displayImage } = this.state;
    this.setState({
      imageUploaded: !imageUploaded,
      imageFile: imageUploaded ? null : event.target.files[0],
      displayImage: !displayImage,
    });
  }

  toggleModal(showModal, watermarkImageUploaded) {
    const { imageFile } = this.state;
    this.props.updateFieldValueChanges('showModal', showModal);
    this.props.updateFieldValueChanges('watermarkImageUploaded', watermarkImageUploaded);
    this.props.updateFieldValueChanges('watermarkImage', imageFile);
  }

  uploadImage() {
    const { clear } = this.props;
    const { displayImage, imageFile } = this.state;
    if (displayImage && !clear) {
      return (
        <div style={{
          display: 'flex', flexDirection: 'column', justifyContent: 'center', padding: '5%',
        }}
        >
          <div style={{
            display: 'flex', justifyContent: 'center', border: '1px solid #E4E7EE', marginTop: 10, marginBottom: 10, borderRadius: 5,
          }}
          >
            {imageFile
              && (
              <div style={{
                display: 'inline-block', height: 'auto', width: 'fit-content', textAlign: 'center',
              }}
              >
                <img
                  alt={imageFile}
                  src={URL.createObjectURL(imageFile)}
                  style={{
                    height: '150px', padding: '5px', marginLeft: '0', width: 'auto',
                  }}
                />
                <div
                  className="secondary_button"
                  role="presentation"
                  style={{ margin: '20px 30%' }}
                  onClick={() => this.setState({ imageUploaded: false, imageFile: '', displayImage: false }, this.toggleModal(true, false))}
                >
                  Clear
                </div>
              </div>
              )}
          </div>
          <div role="presentation" className="primary_button" style={{ marginTop: 15 }} onClick={() => this.toggleModal(false, true)}>Done</div>
        </div>
      );
    }
    return (

      <div style={{
        display: 'flex', flexDirection: 'column', width: '90%', marginLeft: '5%',
      }}
      >
        <div style={{
          display: 'flex', justifyContent: 'space-between', marginBottom: 25, marginTop: 25, width: '100%',
        }}
        >
          <div className="heading-1" style={{ fontWeight: 'bold', fontSize: 18 }}>Upload Watermark</div>
          <div role="presentation" onClick={this.props.handleCloseModal} style={{ cursor: 'pointer' }}>
            <img src={closeMark} alt="x-mark" />
          </div>
        </div>
        <div style={{
          borderRadius: '5px', height: '220px', textAlign: 'center', border: '1px solid #E4E7EE', backgroundColor: '#FFFFFF', display: 'flex', flexDirection: 'column', textAlign: 'center',
        }}
        >
          <div style={{
            margin: 20, borderRadius: '5px', height: '100%', textAlign: 'center', border: '1px solid #E4E7EE', backgroundColor: '#FFFFFF', display: 'flex', flexDirection: 'column', textAlign: 'center', borderStyle: 'dashed',
          }}
          >
            <input
              style={{
                position: 'relative', opacity: '0', textAlign: 'center', zIndex: '1', height: '100%',
              }}
              type="file"
              accept="image/*"
              onChange={this.uploadImageFile}
            />
            <div style={{
              height: '35px', position: 'absolute', margin: '12% 41%', textAlign: 'center', width: '35px', background: 'url(/static/images/uploadFile.svg) no-repeat', opacity: 1, textAlign: 'center',
            }}
            />
            <div style={{
              color: '#4B5D71', fontSize: '14', margin: '20% 15%', position: 'absolute', textAlign: 'center',
            }}
            >
              Drag or browse the watermark file to upload.
            </div>
          </div>
        </div>
        <div style={{ display: 'flex', justifyItems: 'left' }}>
          <div style={{
            marginTop: '2%', marginBottom: '4%', height: '14px', width: '14px', background: `url(${info})`, opacity: 1,
          }}
          />
          <div style={{ fontSize: '12', color: Constants().color.primary, padding: '8px' }}>Upload only .Jpg or .Png files</div>
        </div>
      </div>
    );
  }

  render() {
    return (
      <div style={{ width: '100%' }}>
        <Modal showModal={this.props.showModal} handleCloseModal={this.props.handleCloseModal} height="380px" width="600px">
          <div>
            {this.uploadImage()}
          </div>
        </Modal>
      </div>
    );
  }
}
WaterMarkUploader.propTypes = {
  clear: PropTypes.bool,
};

WaterMarkUploader.defaultProps = {
  clear: false,
};
export default connect()(WaterMarkUploader);
