import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import Cookies from "universal-cookie";
import { logout } from "../../actions";
import Constants from "../../shared/constants";
import FeedBack from "../feedback";
import avatar from "../../static/images/avatar.png";
import { getAccountsDomain } from "../../helpers/util";

const cookies = new Cookies();

class NavBar extends Component {
  constructor(props) {
    super(props);
    this.handleLogout = this.handleLogout.bind(this);
  }

  handleLogout() {
    this.props.logout();
  }

  logButton() {
    const {
      auth: { userTypes, showSmsBalance },
      institute,
      isBusinessDeveloper,
    } = this.props;
    const token = cookies.get("token");
    const user = cookies.get("user");
    const color = institute ? "#D56073" : Constants().color.primary;
    const dropDownElementStyle = {
      fontSize: "0.8vw",
      fontWeight: "bold",
      color: "#454545 !important",
    };
    if (token && token !== "") {
      return (
        <div
          key="navbar-login"
          className="nav-item dropdown"
          style={{ marginTop: -5 }}
        >
          <style>
            {`
            a, a:hover, a:visited, a:active {
              color: #454545 !important;
              text-decoration: none;
            }  
            .dropdown-item:hover {
              color: #16181b !important;
            }
          `}
          </style>
          <a
            className="nav-link dropdown-toggle"
            href="#"
            id="navbarDropdown"
            role="button"
            data-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="false"
            style={{ color: "#45454585" }}
          >
            <img
              alt=""
              className="cursor"
              src={avatar}
              width="auto"
              height="40px"
            />
          </a>
          <div
            style={{ cursor: "pointer" }}
            className="dropdown-menu dropdown-menu-right"
            aria-labelledby="navbarDropdown"
          >
            {user.hasNotificationPermission && (
              <div>
                <a
                  href="/notification"
                  className="dropdown-item"
                  style={dropDownElementStyle}
                >
                  Notification
                </a>
                <div className="dropdown-divider" />
              </div>
            )}
            {false && (
              <div>
                <a
                  href="/assign_teachers_to_classroom"
                  className="dropdown-item"
                  style={dropDownElementStyle}
                >
                  Teacher Details
                </a>
                <div className="dropdown-divider" />
              </div>
            )}
            {showSmsBalance && (
              <div>
                <a
                  href="/sms_subscriptions"
                  className="dropdown-item"
                  style={dropDownElementStyle}
                >
                  SMS Balance
                </a>
                <div className="dropdown-divider" />
              </div>
            )}
            {user.can_manage_institute && (
              <div>
                <a
                  href="/institute/settings"
                  className="dropdown-item"
                  style={dropDownElementStyle}
                >
                  Manage my institute
                </a>
                <div className="dropdown-divider" />
              </div>
            )}
            <div>
              <a
                href="/question_suggestion_import"
                className="dropdown-item"
                style={dropDownElementStyle}
              >
                Question Suggestion
              </a>
              <div className="dropdown-divider" />
            </div>
            <div>
              <a
                href="/test_otp"
                className="dropdown-item"
                style={dropDownElementStyle}
              >
                Show Otp
              </a>
              <div className="dropdown-divider" />
            </div>
            {userTypes.length > 1 && (
              <div>
                <div
                  role="presentation"
                  onClick={() => {
                    window.location.replace(
                      `${Constants().accountsUrl}/switch_membership`
                    );
                  }}
                  className="dropdown-item"
                  style={dropDownElementStyle}
                >
                  Switch Membership
                </div>
                <div className="dropdown-divider" />
              </div>
            )}
            <div>
              <a
                href="/change_password"
                className="dropdown-item"
                style={dropDownElementStyle}
              >
                Change Password
              </a>
              <div className="dropdown-divider" />
            </div>
            {isBusinessDeveloper && (
              <div>
                <a
                  href="/bde_tools/dashboard"
                  className="dropdown-item"
                  style={dropDownElementStyle}
                >
                  BDE Tools
                </a>
                <div className="dropdown-divider" />
              </div>
            )}
            <div role="presentation" onClick={this.handleLogout}>
              <a className="dropdown-item" style={dropDownElementStyle}>
                Logout
              </a>
            </div>
          </div>
        </div>
      );
    }
    return (
      <div key="navbar-login" className="mr-4">
        <div
          role="presentation"
          className="Button"
          onClick={() =>
            window.location.replace(`${getAccountsDomain()}/sign_in`)
          }
          style={{
            fontWeight: "bold",
            fontSize: 14,
            width: 160,
            backgroundColor: color,
            color: "white",
            height: 40,
            display: "flex",
            placeContent: "center",
            borderRadius: 0,
          }}
        >
          Login
        </div>
      </div>
    );
  }

  render() {
    const user = cookies.get("user");
    const token = cookies.get("token");
    const logoUrl = user && user.instituteLogoUrl ? user.instituteLogoUrl : "";
    const { showLogin, isMobile, institute, globalHistory } = this.props;
    const color = this.props.institute ? "#D56073" : Constants().color.primary;
    return (
      <div
        style={{
          boxShadow: "0 3px 6px 0 rgba(0, 0, 0, 0.08)",
          backgroundColor: "white",
          height: 70,
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          position: "fixed",
          width: "100%",
          zIndex: 4,
          top: 0,
        }}
      >
        <div
          style={{
            letterSpacing: 0.5,
            height: 40,
            width: 160,
            marginLeft: isMobile ? 20 : 85,
            fontWeight: 800,
            textAlign: "center",
            borderRadius: 3,
          }}
        >
          <div
            role="presentation"
            onClick={
              globalHistory
                ? () => globalHistory.push("/")
                : () => window.location.replace("/")
            }
          >
            <span
              className="nunito-sans-header"
              style={{
                color: "red",
                fontSize: 26,
                fontWeight: 800,
                cursor: "pointer",
              }}
            >
              IDEA
              <span style={{ color: "black" }}>BOARD</span>
            </span>
          </div>
        </div>
        {logoUrl && (
          <div
            style={{
              marginLeft: 15,
              backgroundColor: "#EDEDED",
              height: 40,
              borderRadius: 3,
            }}
          >
            <div style={{ margin: "7px 24px 7px 24px" }}>
              <img alt="" src={logoUrl} height="24px" />
            </div>
          </div>
        )}
        {isMobile && (
          <div
            role="presentation"
            onClick={this.handleLogout}
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              width: 100,
              height: 40,
              backgroundColor: Constants().color.primary,
              borderRadius: 4,
              color: "white",
              marginRight: 20,
            }}
          >
            Logout
          </div>
        )}
        {!isMobile && (
          <>
            <button
              className="navbar-toggler"
              type="button"
              data-toggle="collapse"
              data-target="#navbarSupportedContent"
              aria-controls="navbarSupportedContent"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <span className="navbar-toggler-icon" />
            </button>
            {showLogin && (
              <div
                style={{ display: "flex", marginRight: 85 }}
                id="navbarSupportedContent"
              >
                {this.props.institute && (
                  <div key="navbar-googlePlay" className="nav-item mr-4">
                    <a
                      href="https://play.google.com/store/apps/details?id=com.ideaboard&utm_source=Website&utm_campaign=Header&pcampaignid=MKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1"
                      target="_blank"
                    >
                      <a>
                        <button
                          type="button"
                          className="btn btn-outline-danger"
                          style={{
                            fontWeight: "bold",
                            fontSize: 14,
                            width: 160,
                          }}
                        >
                          Download App
                        </button>
                      </a>
                    </a>
                  </div>
                )}
                <div key="navbar-feedback" className="nav-item mr-4">
                  <FeedBack
                    colorStyle={color}
                    fillButton={!(token && token !== "")}
                  />
                </div>
                {this.logButton()}
              </div>
            )}
          </>
        )}
      </div>
    );
  }
}

NavBar.propTypes = {
  institute: PropTypes.object,
  isMobile: PropTypes.bool,
  showLogin: PropTypes.bool,
  auth: PropTypes.object,
  showSmsBalance: PropTypes.bool,
};
NavBar.defaultProps = {
  institute: null,
  showLogin: true,
  isMobile: false,
  auth: {},
  showSmsBalance: false,
};

const mapStateToProps = ({ auth, institute }) => ({
  auth,
  isBusinessDeveloper: institute.isBusinessDeveloper,
});

export default connect(mapStateToProps, { logout })(NavBar);
