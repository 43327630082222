import React, { Component } from 'react';
import Select from 'react-select';
import { connect } from 'react-redux';
import MathJax from 'react-mathjax2';
import {
  getQuickpointList,
  addQuickpoint,
  updateQuickpoint,
  deprecateQuickpoint,
  getQuickpointById,
  showToast,
} from '../../actions';
import Layout from '../layout';
import Dropdown from './dropdown';
import QuickpointList from './quickpointList';
import CheckpointDropdown from './checkpointDropdown';
import { IMAGE_UPDATE_QUICKPOINT } from '../../constants';

const imageSizes = [{ value: 1, label: 'Small' }, { value: 2, label: 'Medium' }, { value: 3, label: 'Large' }];

const initialState = {
  editId: null,
  title: '',
  text: '',
  supportingText: '',
  imageUpdate: IMAGE_UPDATE_QUICKPOINT.NO_CHANGE,
  supportingPicture: null,
  supportingPictureDisplay: null,
  configuration: { image_size: 2, show_text: true, show_image: false },
  checkpoints: [],
  deprecated: false,
};

class Quickpoints extends Component {
  constructor(props) {
    super(props);
    this.state = {
      ...initialState,
    };
    this.loadForm = this.loadForm.bind(this);
    this.openEditMode = this.openEditMode.bind(this);
    this.modifyCheckpoint = this.modifyCheckpoint.bind(this);
  }

  componentDidMount() {
    const query = new URLSearchParams(window.location.search);
    const test = query.get('test');
    const subject = query.get('subject');
    const chapter = query.get('chapter');
    const checkpoint = query.get('checkpoint');
    this.props.getQuickpointList({
      checkpoint, subject, chapter, test,
    });
  }

  modifyCheckpoint() {
    const {
      title, text, supportingPicture, configuration, checkpoints, editId,
    } = this.state;
    if (!title || !text) {
      this.props.showToast('Title and Text must be filled');
      return;
    }
    if (supportingPicture && !configuration.image_size) {
      this.props.showToast('Please mention the image size');
      return;
    }
    if (!checkpoints || !checkpoints.length) {
      this.props.showToast('Add checkpoints for quick point.');
      return;
    }
    if (editId) {
      this.props.updateQuickpoint(this.state).then((isSuccess) => {
        if (isSuccess) {
          this.props.showToast('Quickpoint data is updated successfully.');
          this.setState(initialState);
        }
      });
      return;
    }
    this.props.addQuickpoint(this.state).then((isSuccess) => {
      if (isSuccess) {
        this.props.showToast('New quickpoint successfully added.');
        this.setState(initialState);
      }
    });
  }

  openEditMode(quickpoint) {
    if (!quickpoint) {
      this.props.showToast('Data not found');
      return;
    }
    const {
      id, title, text, supporting_text: supportingText,
      supporting_picture: supportingPicture, configuration, checkpoint_list = [], deprecated,
    } = quickpoint;
    this.setState({
      editId: id,
      title,
      text,
      supportingText,
      supportingPictureDisplay: supportingPicture.url,
      configuration: { ...initialState.configuration, ...configuration },
      checkpoints: checkpoint_list,
      deprecated,
    });
  }

  loadForm() {
    const {
      checkpoints, editId, configuration, text, supportingText, supportingPictureDisplay, deprecated, title,
    } = this.state;
    return (
      <div style={{
        display: 'flex', flexDirection: 'column', width: '100%', height: '100%', paddingTop: 10,
      }}
      >
        <div style={{ overflow: 'scroll', height: 'calc(100vh - 150px)' }}>
          <div className="d-flex flex-row">
            <div style={{ width: '80%' }}>
              <CheckpointDropdown
                key={JSON.stringify(checkpoints)}
                defaultValue={checkpoints}
                onChange={(checkpointData) => this.setState({ checkpoints: checkpointData })}
              />
            </div>
            <div style={{ width: '17%' }}>
              <input
                id="quickpointId"
                onKeyPress={(event) => {
                  if (event.key === 'Enter') {
                    this.props.getQuickpointById(event.target.value).then(this.openEditMode.bind(this));
                  }
                }}
                type="text"
                placeholder="Quickpoint id"
                value={editId}
                style={{ height: 67, marginLeft: 10, width: '100%' }}
              />
            </div>
          </div>
          <label>
                Title:
            <br />
            <input style={{ width: '98%', height: 50 }} type="text" maxLength="100" value={title} onChange={(event) => this.setState({ title: event.target.value })} />
          </label>
          <label>
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              <div>Text:</div>
              <div style={{ display: 'flex', paddingRight: 10 }}>
                <input
                  type="checkbox"
                  checked={configuration.show_text}
                  onChange={() => this.setState({ configuration: { ...configuration, show_text: !configuration.show_text } })}
                  style={{ fontSize: 'x-large', marginTop: 10, cursor: 'pointer' }}
                />
                <label className="ml-3" style={{ fontSize: 16, fontWeight: 'bold', margin: 5 }}> Show Text </label>
              </div>
            </div>
            <textarea style={{ width: '98%' }} value={text} onChange={(event) => this.setState({ text: event.target.value })} />
            <MathJax.Context input="tex">
              <div style={{ whiteSpace: 'pre-wrap' }}>
                <MathJax.Text text={text} />
              </div>
            </MathJax.Context>
            <hr />
          </label>
          <label>
              Supporting Text:
            <br />
            <textarea style={{ width: '98%' }} value={supportingText} onChange={(event) => this.setState({ supportingText: event.target.value })} />
            <MathJax.Context input="tex">
              <div style={{ whiteSpace: 'pre-wrap' }}>
                <MathJax.Text text={supportingText} />
              </div>
            </MathJax.Context>
            <hr />
          </label>
          <div>
            { !!supportingPictureDisplay && <img src={supportingPictureDisplay} alt="supporting" />}
            { this.renderImageSection() }
          </div>
          <br />
        </div>
        <div style={{
          borderTop: '1px solid #ccc', padding: 15, display: 'flex', width: '97%',
        }}
        >
          <div style={{ width: '80%', display: 'flex' }}>
            { !!editId && !deprecated && (
              <div className="mr-5">
                <div
                  role="presentation"
                  className="btn btn-outline-danger"
                  style={{ fontSize: 12, padding: 10 }}
                  onClick={() => this.props.deprecateQuickpoint(editId)}
                >
                  <i className="fa fa-trash-o" />
                    Deprecate
                </div>
              </div>
            )}
            <div>
              <div
                role="presentation"
                className="btn btn-outline-primary"
                style={{ fontSize: 12, padding: 10 }}
                onClick={() => this.setState(initialState)}
              >
                  Clear
              </div>
            </div>
          </div>
          <div style={{ width: '20%', textAlign: 'right' }}>
            <div
              role="presentation"
              className="btn btn-primary"
              style={{ fontSize: 12, padding: 10 }}
              onClick={this.modifyCheckpoint}
            >
              {`${editId ? 'Update' : 'Create'}`}
            </div>
          </div>
        </div>
      </div>
    );
  }

  renderImageSection() {
    const { configuration, supportingPicture, supportingPictureDisplay } = this.state;
    if (supportingPicture || !!supportingPictureDisplay) {
      const customStyles = {
        control: (base, state) => ({
          ...base,
          width: 200,
          backgroundColor: '#ffffff',
          border: '1px solid var(--#ddd) !important',
        }),
        option: (base, state) => ({
          ...base,
          border: '1px solid #F8F8F8',
          color: '#343B40',
          backgroundColor: state.isFocused ? '#DEEBFF' : '#FFFFFF',
          opacity: '1',
          cursor: 'default',
        }),
      };
      return (
        <div className="mt-3 d-flex flex-row">
          <input
            type="checkbox"
            checked={configuration.show_image}
            onChange={() => this.setState({ configuration: { ...configuration, show_image: !configuration.show_image } })}
            style={{ fontSize: 'x-large', marginTop: 10, cursor: 'pointer' }}
          />
          <label className="ml-3" style={{ fontSize: 16, fontWeight: 'bold', margin: 5 }}> Show Supporting Image </label>
          <Select
            styles={customStyles}
            className="imageDropDownList"
            value={imageSizes[configuration.image_size - 1]}
            options={imageSizes}
            placeholder="Image size"
            isSearchable={false}
            onChange={({ value }) => this.setState({ configuration: { ...configuration, image_size: value } })}
          />
          <div className="ml-5">
            <div
              role="presentation"
              className="btn btn-outline-danger"
              style={{ fontSize: 12, padding: 10 }}
              onClick={() => this.setState({
                supportingPicture: null, supportingPictureDisplay: null, imageUpdate: IMAGE_UPDATE_QUICKPOINT.DELETED, configuration: { ...this.state.configuration, show_image: false },
              })}
            >
              <i className="fa fa-trash-o" />
              Delete
            </div>
          </div>
        </div>
      );
    }
    return <input id="uploadSupportingImage" type="file" name="pic" accept="image/png, image/jpeg, image/jpg, image/svg" onChange={(event) => this.setState({ supportingPicture: event.target.files[0], imageUpdate: IMAGE_UPDATE_QUICKPOINT.UPDATED })} />;
  }

  render() {
    const { updating, error } = this.props;
    return (
      <Layout globalHistory={this.props.history}>
        <div className="row" style={{ height: 'calc(100vh - 70px)' }}>
          <div className="col-md-3 pl-5 pt-3" style={{ backgroundColor: '#FCFCFC', borderRight: '1px solid #ddd' }}>
            <Dropdown />
            <QuickpointList onItemClick={this.openEditMode} />
          </div>
          <div className="col-md-9 pr-3" style={{ backgroundColor: '#F3F3F3' }}>
            { error && <div className="alert alert-danger" role="alert">{error}</div>}
            { updating && <div className="alert alert-secondary" role="alert">Loading</div>}
            { this.loadForm() }
          </div>
        </div>
      </Layout>
    );
  }
}

const mapStateToProps = ({ quickpoints }) => ({
  error: quickpoints.error,
  updating: quickpoints.updating,
});

export default connect(mapStateToProps, {
  getQuickpointList,
  addQuickpoint,
  updateQuickpoint,
  deprecateQuickpoint,
  getQuickpointById,
  showToast,
})(Quickpoints);
