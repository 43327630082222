import React from 'react';

class Testimonial extends React.Component {

  renderTestimonial(img, content, name){
    const { device } = this.props;
    return (
      <div className="row m-5" style={{ backgroundColor: "white", height: device === 'Desktop' && "40%", borderRadius: "5px", boxShadow: "0 2px 2px 0 rgba(0,0,0,0.16), 0 0 0 1px rgba(0,0,0,0.08)", textAlign: device === 'Desktop' ? '' : "center" }}>
        <div className={device === 'Desktop' ? "col-sm-4 m-3" : "col-sm-3"}>
          <img src={img} width={device === 'Desktop' ? "128px" : "64px"} height="auto" style={{ margin: "5%", borderRadius: "5px" }}/>
        </div>
        <div className={device === 'Desktop' ? "col-sm-7 mt-5" : "col-sm-3"}>
          <p style={{ fontSize: device === 'Desktop' ? "18px" : "14px"}}>{content}</p>
          <p style={{ textAlign: device === 'Desktop' ? "right" : "center", fontSize: device === 'Desktop' ? "14px" : "10px" }}>{name}</p>
        </div>
      </div>
    );
  }

  render() {
    const { device } = this.props;
    return (
      <div className="row" style={{ backgroundColor: this.props.bgcolor }}>
        {device === 'Desktop' ?
        <React.Fragment>
          <div className="col-sm-6 mt-5 mb-5">
            {this.renderTestimonial("/static/images/testimonial-1.jpg", "It saves me an immense amount of time. I can focus more on giving a good paper, and less on technical bits.", "Dr. Prakash Gouda, Bangalore")}
            {this.renderTestimonial("/static/images/testimonial-3.jpg", "The community is amazing. Everyone contributes to make the question bank richer and cleaner.", "Jina Satish, Kerala")}
          </div>
          <div className="col-sm-6 mt-5 mb-5">
            {this.renderTestimonial("/static/images/testimonial-2.jpg", "I don’t have to spend hours proofreading the paper, I can print it directly myself!", "Suresh Babu Katragadda, Hyderabad")}
            {this.renderTestimonial("/static/images/testimonial-4.jpg", "This is super convenient for people like me who commute. I can make a question paper while travelling.", "Dr. Ramachandra G K, Hosur")}
          </div>
        </React.Fragment>
        :
        <div style={{ margin: device === 'Desktop' ? '' : "5%" }}>
          {this.renderTestimonial("/static/images/testimonial-1.jpg", "It saves me an immense amount of time. I can focus more on giving a good paper, and less on technical bits.", "Dr. Prakash Gouda, Bangalore")}
          {this.renderTestimonial("/static/images/testimonial-3.jpg", "The community is amazing. Everyone contributes to make the question bank richer and cleaner.", "Jina Satish, Kerala")}
          {this.renderTestimonial("/static/images/testimonial-2.jpg", "I don’t have to spend hours proofreading the paper, I can print it directly myself!", "Suresh Babu Katragadda, Hyderabad")}
          {this.renderTestimonial("/static/images/testimonial-4.jpg", "This is super convenient for people like me who commute. I can make a question paper while travelling.", "Dr. Ramachandra G K, Hosur")}
        </div>
      }
      </div>
    );
  }
}

export default Testimonial;
