import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { getQuizQuestionData, updateQuizQuestion, showToast } from '../../actions';
import Layout from '../layout';
import Loader from '../loading';
import Menubar from '../menubar';
import Modal from '../modal/baseModal';
import { QuizQuestionView } from '../common';

class QuizQuestion extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      textChanges: {
        question_text: '',
        option_1: '',
        option_2: '',
        option_3: '',
        option_4: '',
        answer: '',
      },
      showLoader: false,
      showUpdateModal: false,
    };
    this.renderQuizQuestion = this.renderQuizQuestion.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.updateQuestion = this.updateQuestion.bind(this);
    this.renderQuizHeader = this.renderQuizHeader.bind(this);
    this.handlecloseModal = this.handlecloseModal.bind(this);
  }

  componentDidMount() {
    const { id } = this.props.match.params;
    this.setState({ showLoader: true });
    this.props.getQuizQuestionData(id).then((response) => {
      this.setState({ showLoader: false, textChanges: { ...response.quiz_question_data } });
    });
  }

  handleChange(type, value) {
    const { textChanges } = this.state;
    textChanges[type] = value;
    this.setState({ textChanges: { ...textChanges } });
  }

  handlecloseModal() {
    const { quizQuestionData } = this.props;
    this.setState({ textChanges: { ...quizQuestionData }, showUpdateModal: false });
  }

  updateQuestion() {
    const { textChanges } = this.state;
    const { id } = this.props.match.params;
    if (this.isDataPresent(textChanges)) {
      this.props.updateQuizQuestion(id, textChanges).then(() => {
        this.props.getQuizQuestionData(id);
        this.setState({ showUpdateModal: false });
        this.props.showToast('Quiz Question Updated Successfully');
      });
    }
  }

  isDataPresent(textChanges) {
    let optionArray = Array.from(new Set([textChanges.option_1,
      textChanges.option_2, textChanges.option_3, textChanges.option_4]));
    optionArray = optionArray.filter((e) => e);
    if (!textChanges.question_text && !textChanges.question_text.length > 0) {
      this.props.showToast('Question Text Should Be Present');
      return false;
    }
    if (optionArray.length !== 4) {
      this.props.showToast('All four options should be present and it should be unique');
      return false;
    }
    if (!textChanges.answer) {
      this.props.showToast('Atleast one option should be marked as correct');
      return false;
    }
    return true;
  }

  renderQuizHeader() {
    const { quizQuestionData } = this.props;
    const { quizname, quizdate } = quizQuestionData;
    return (
      <div style={{ borderBottom: '2px solid #CDCFD6', marginBottom: 50, paddingBottom: 20 }}>
        <div style={{ alignSelf: 'center', fontSize: 22, fontWeight: 'bold' }}>
          {quizname}
        </div>
        <div style={{ alignSelf: 'center', fontWeight: 'bold' }}>
          {quizdate}
        </div>
      </div>
    );
  }

  renderCardView(title, value, color) {
    const { quizQuestionData } = this.props;
    return (
      <div style={{
        border: '1px solid #343737',
        height: 140,
        color,
        width: 225,
        display: 'flex',
        flexDirection: 'column',
        placeContent: 'center',
        alignItems: 'center',
        fontFamily: 'Nutnito',
      }}
      >
        <div style={{ fontSize: 40, fontWeight: 'bold' }}>{quizQuestionData[value]}</div>
        <div style={{ fontSize: 18 }}>{title}</div>
      </div>
    );
  }

  renderQuizResponseData() {
    return (
      <div style={{ display: 'flex', marginBottom: 50, justifyContent: 'space-between' }}>
        {this.renderCardView('STUDENTS ATTENDED', 'students_attended', '#8A2BE2')}
        {this.renderCardView('CORRECT COUNT', 'correct', '#008000')}
        {this.renderCardView('INCORRECT COUNT', 'incorrect', '#FF0000')}
      </div>
    );
  }

  renderQuizQuestion(isEditable = false) {
    const { textChanges } = this.state;
    return (
      <div style={{ border: !isEditable && '1px solid #343737', padding: isEditable ? 30 : 20 }}>
        <QuizQuestionView
          updateQuestion={this.updateQuestion}
          handleChange={this.handleChange}
          textChanges={{ ...textChanges }}
          isEditable={isEditable}
        />
      </div>
    );
  }

  render() {
    const { quizQuestionData } = this.props;
    const quizMenu = [{ label: 'Highlights', route: '/quizboard' }, { label: 'Quizzes', route: `/quiz/quiz_update/${quizQuestionData.quiz_id}` }];
    const { showLoader, showUpdateModal } = this.state;
    if (showLoader) {
      return <Loader />;
    }
    return (
      <Layout globalHistory={this.props.history} hideHeader>
        <Modal showModal={showUpdateModal} handleCloseModal={this.handlecloseModal} overflow="visible" header="Create Question" top="25%" width="900px">
          {this.renderQuizQuestion(true)}
        </Modal>
        <div style={{ width: '100%', display: 'flex' }}>
          <div style={{ width: '20%', padding: 0, zIndex: 1 }}>
            <Menubar history={this.props.history} menuList={quizMenu} selectedOption={1} redirectToRoot />
          </div>
          <div style={{ width: '80%', padding: 90 }}>
            {this.renderQuizHeader()}
            {this.renderQuizResponseData()}
            {this.renderQuizQuestion()}
            {quizQuestionData.students_attended > 0 && (
              <div style={{
                border: '1px solid #343737', padding: '10px 20px', borderTop: 0, fontSize: 16,
              }}
              >
                <b style={{ fontFamily: 'Nuntio' }}>
                  {((quizQuestionData.correct * 100) / quizQuestionData.students_attended).toFixed(0)}
                  % &nbsp;(
                  {quizQuestionData.correct}
                  &nbsp;
                  of&nbsp;
                  {quizQuestionData.students_attended}
                  )&nbsp;

                </b>
                students answered this question correctly
              </div>
            )}
            <div
              role="presentation"
              className="Button Primary-Button"
              style={{
                borderTop: 0, borderRadius: 0, width: 150, marginTop: 40, marginLeft: 'auto',
              }}
              onClick={() => this.setState({ showUpdateModal: true })}
            >
              Update Question
            </div>
          </div>
        </div>
      </Layout>
    );
  }
}

QuizQuestion.propTypes = {
  quizQuestionData: PropTypes.object,
};

QuizQuestion.defaultProps = {
  quizQuestionData: {},
};

const mapStateToProps = ({ quiz }) => ({
  quizQuestionData: quiz.quizQuestionData,
});

export default connect(mapStateToProps, {
  getQuizQuestionData,
  updateQuizQuestion,
  showToast,
})(QuizQuestion);
