import { SHOW_TOAST, HIDE_TOAST } from '../actions';

const initialState = {
  show: false,
  message: '',
};

export default function (state = initialState, action) {
  switch (action.type) {
    case SHOW_TOAST:
      return { show: true, message: action.message };
    case HIDE_TOAST:
      return { show: false, message: '' };
    default:
      return state;
  }
}
