import React, { PureComponent } from 'react';

import List from './lists';
import '../home/home.css';

const TEST = 'test';
class AllAssignments extends PureComponent {
  render() {
    const { history } = this.props;
    const { type } = this.props.match.params;
    return <List type={type} isTestPage={type === TEST} key={type} history={history} />;
  }
}

export default AllAssignments;
