import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

class LocaleDate extends PureComponent {
  render() {
    const { date } = this.props;
    if (!date) {
      return <div />;
    }
    return (
      <>
        <span style={{ paddingRight: 2 }}>
          {date.toLocaleString('en-US', { timeZone: 'Asia/Kolkata', day: '2-digit' })}
            &nbsp;
        </span>
        <span>
          {date.toLocaleString('en-US', { timeZone: 'Asia/Kolkata', month: 'short' })}
        </span>
        <span>
          ,&nbsp;
        </span>
        <span>
          {date.toLocaleString('en-US', { timeZone: 'Asia/Kolkata', weekday: 'long' })}
        </span>
      </>
    );
  }
}

LocaleDate.propTypes = {
  date: PropTypes.string,
};

LocaleDate.defaultProps = {
  date: '',
};

export default connect(null, {})(LocaleDate);
