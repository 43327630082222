import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
  getQuestionData, updateIndex, updateComplexity, reportIssue,
} from '../../actions';
import Layout from '../layout';
import QuestionView from '../questionView';
import { sendEvent } from '../../helpers/Analytics';
import { mathjax } from '../../helpers/util';

const QUESTION_SWITCHING = {
  NEXT: 1,
  PREVIOUS: -1,
};

class TagComplexity extends Component {
  constructor(props) {
    super(props);
    this.start = null;
    this.state = {
      showLoader: true,
    };
    this.questionTimer = {};
    this.touchEnd = this.touchEnd.bind(this);
    this.touchStart = this.touchStart.bind(this);
    this.myRef = React.createRef();
  }

  componentDidMount() {
    const { id } = this.props.match.params;
    this.props.getQuestionData(id).then(() => {
      mathjax();
      this.timer = setInterval(() => {
        const questionId = this.props.questions[this.props.index].id;
        if (this.questionTimer[questionId]) {
          this.questionTimer[questionId] += 100;
        } else {
          this.questionTimer[questionId] = 100;
        }
      }, 100);
      this.setState({ showLoader: false });
      if (document.getElementById('question-item')) {
        document.getElementById('question-item').addEventListener('touchstart', this.touchStart);
        document.getElementById('question-item').addEventListener('touchend', this.touchEnd);
      }
    });
  }

  componentWillUnmount() {
    clearInterval(this.timer);
    if (document.getElementById('question-item')) {
      document.getElementById('question-item').removeEventListener('touchstart', this.touchStart);
      document.getElementById('question-item').removeEventListener('touchend', this.touchEnd);
    }
  }

  touchStart(event) {
    if (event.touches.length === 1) {
      this.start = event.touches.item(0).clientX; // just one finger touched
    } else {
      this.start = null; // a second finger hit the screen, abort the touch
    }
  }

  touchEnd(event) {
    const offset = 50;
    if (this.start) {
      const end = event.changedTouches.item(0).clientX;
      if (end > this.start + offset) {
        this.switchQuestion(QUESTION_SWITCHING.PREVIOUS);
      }
      if (end < this.start - offset) {
        this.switchQuestion(QUESTION_SWITCHING.NEXT);
      }
    }
  }

  switchQuestion(offset) {
    if (this.props.index + offset >= 0) {
      this.props.updateIndex(offset).then(() => {
        mathjax();
        this.myRef.current.scrollTo(0, 0);
      });
    }
  }

  render() {
    const { questions, index } = this.props;
    const question = questions[index];
    if (this.state.showLoader) {
      return <div />;
    }
    return (
      <Layout globalHistory={this.props.history}>
        <div style={{ position: 'relative' }}>
          <div
            ref={this.myRef}
            id="question-item"
            style={{
              padding: 10, backgroundColor: '#F4F5F7', height: '100vh', overflow: 'scroll', paddingTop: '15%', paddingBottom: '250px',
            }}
          >
            <QuestionView question={question} showAnswer printPage={false} index={index} mobileView />
            <div style={{ paddingTop: 100, marginLeft: '15%', marginRight: '15%' }}>
              <div
                role="presentation"
                style={{
                  border: '1px solid grey', padding: 10, textAlign: 'center', borderRadius: 5, color: 'grey',
                }}
                onClick={() => this.props.reportIssue(question.id)}
              >
                Report Issue
              </div>
            </div>
          </div>
          <div
            className="row"
            style={{
              width: '100%', height: '60px', position: 'fixed', bottom: 0, marginLeft: '0px', display: 'table',
            }}
          >
            { ['JUNK', '1', '2', '3', '4', '5'].map((item, itemIndex) => {
              return (
                <div
                  role="presentation"
                  key={item}
                  onClick={() => {
                    sendEvent('Complexity', 'TagComplexity', 'log', {
                      item, itemIndex, time_spent: this.questionTimer[question.id], chapter_id: this.props.match.params.id,
                    });
                    this.props.updateComplexity(index, itemIndex, question.complexity, this.questionTimer[question.id], this).then(() => {
                      mathjax();
                      this.myRef.current.scrollTo(0, 0);
                    });
                  }}
                  style={{
                    alignItem: 'center',
                    justifyContent: 'center',
                    color: `${question.complexity !== itemIndex ? '#fff' : 'rgb(71, 134, 255)'}`,
                    backgroundColor: `${question.complexity !== itemIndex ? 'rgb(71, 134, 255)' : '#fff'}`,
                    fontWeight: 'bolder',
                    fontSize: 'larger',
                    height: '60px',
                    border: '1px solid',
                    textAlign: 'center',
                    display: 'table-cell',
                    verticalAlign: 'middle',
                    width: item === 'JUNK' ? '' : '15%',
                  }}
                >
                  {item}
                </div>
              );
            })}
          </div>
        </div>
      </Layout>
    );
  }
}

const mapStateToProps = ({ tagComplexity }) => ({
  questions: tagComplexity.questions,
  index: tagComplexity.index,
});

export default connect(mapStateToProps, {
  getQuestionData, updateIndex, updateComplexity, reportIssue,
})(TagComplexity);
