import {
  QUIZ_LIST, QUIZ_DATA, QUIZ_QUESTION_LIST, QUIZ_QUESTION_DATA_LIST,
  QUIZ_RESULT, UPDATE_SHOW_QUESTION_LIST, APPEND_NEW_QUESTION, REMOVE_QUESTIONS,
} from '../actions';

const initialState = {
  quizList: [],
  removedIds: [],
  quizData: {},
  hasTextExtractionAccess: false,
  quizQuestionData: {},
  rankListData: [],
  classrooms: [],
  quizDate: '',
  setDate: '',
  quizQuestionList: [],
  shownQuizIds: [],
  quizResult: {},
  questionScores: [],
  totalQuestions: 0,
  updatedCount: 0,
  overallScore: 0.0,
  feedbacks: [],
  feedbackWidgetData: {},
  isHigherEducation: false,
  isAnonymousFeedback: true,
};

export default function (state = initialState, action) {
  const newState = { ...state };
  newState.updatedCount += 1;
  switch (action.type) {
    case QUIZ_LIST: {
      return {
        ...state,
        ...action.payload,
      };
    }
    case QUIZ_DATA: {
      return {
        ...state,
        ...action.payload,
      };
    }
    case REMOVE_QUESTIONS: {
      newState.removedIds = [...newState.removedIds, action.id];
      newState.removedIds = [...newState.removedIds];
      return {
        ...newState,
      };
    }
    case UPDATE_SHOW_QUESTION_LIST: {
      newState.shownQuizIds = [...(newState.shownQuizIds || [])].concat([...(action.ids || [])]);
      return {
        ...newState,
      };
    }
    case QUIZ_QUESTION_LIST: {
      return {
        ...state,
        ...action.payload,
      };
    }
    case APPEND_NEW_QUESTION: {
      if (action.textChanges) {
        newState.quizQuestionList = [...newState.quizQuestionList, { ...action.textChanges }];
      }
      return {
        ...newState,
      };
    }
    case QUIZ_QUESTION_DATA_LIST: {
      newState.quizQuestionData = { ...action.payload.quizQuestionData };
      return {
        ...newState,
      };
    }
    case QUIZ_RESULT: {
      return {
        ...state,
        ...action.payload,
      };
    }
    default:
      return state;
  }
}

