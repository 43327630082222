import { func } from "prop-types";
import Api from "../helpers/Api";
import { showToast } from "./snackBar";
import { encrypt } from "../helpers/CryptoHelper";
import {
  HIDDEN_SECRET,
  MANAGE_INSTITUTE_TOTAL_ITMES_PER_PAGE,
} from "../constants";

export const INSTITUTE_DETAILS = "institute/INSTITUTE_DETAILS";
export const USER_DETAILS = "institute/USER_DETAILS";
export const MEMBER_DETAILS = "institute/MEMBER_DETAILS";
export const TEACHER_DETAILS = "institute/TEACHER_DETAILS";
export const GET_TEST_GROUP_DATA = "institute/GET_TEST_GROUP_DATA";
export const GET_TEAM_MANAGEMENT = "institute/GET_TEAM_MANAGEMENT";
export const GET_CAMPUS_FOR_INSTITUTE ="institute/GET_CAMPUS_FOR_INSTITUTE";
export const SUBJECT_DETAILS = "institute/SUBJECT_DETAILS";
export const CAMPUSES_CLASSROOMS = "institute/CAMPUSES_CLASSROOMS";
export const CLASSROOMS_AND_STUDENTS = "institute/CLASSROOMS_AND_STUDENTS";
export const UPDATE_STUDENT_DETAILS = "institute/UPDATE_STUDENT_DETAILS";
export const UPDATE_SUBJECT_DETAILS = "institute/UPDATE_SUBJECT_DETAILS";
export const UPDATE_CURRENT_SUBJECT_DATA =
  "institute/UPDATE_CURRENT_SUBJECT_DATA";
export const PERMISSION_DENIED = "institute/PERMISSION_DENIED";
export const TEST_LOCK_OTP = "institute/TEST_LOCK_OTP";
export const SAVE_STUDENT_DETAILS = "institute/SAVE_STUDENT_DETAILS";

export function getSmsSubscriptionData() {
  return (dispatch) =>
    Api({
      method: "get",
      url: "/institutes/get_sms_subcsription_data",
    }).then((response) => {
      if (response.success) {
        const payload = {
          remainingSmsCount: response.sms_balance,
          smsDetails: response.sms_details,
          smsUsed: response.sms_used,
          costPerSms: response.cost_per_sms,
        };
        dispatch({
          type: INSTITUTE_DETAILS,
          payload,
        });
      } else {
        dispatch({
          type: PERMISSION_DENIED,
          status: true,
        });
      }
      return response;
    });
}

export function rechargeForSMS(response, amount, quantity) {
  return (dispatch) =>
    Api({
      method: "post",
      url: "/institutes/recharge_sms",
      data: {
        payment_id: response.razorpay_payment_id,
        amount,
        quantity,
      },
    }).then(() => {
      dispatch(getSmsSubscriptionData());
      dispatch(showToast("Paid Successfully."));
      dispatch({ type: INSTITUTE_DETAILS, payload: { smsCountNeeded: 0 } });
    });
}

export function updateSmsCount(value = "") {
  return (dispatch) =>
    new Promise((resolve) => {
      const payload = { smsCountNeeded: value ? parseInt(value, 10) : "" };
      dispatch({ type: INSTITUTE_DETAILS, payload });
      resolve();
    });
}

export function updateInstituteDetails(
  instituteId,
  name,
  picture,
  isLogoUpdated,
  phone,
  address,
  watermarkText,
  waterMarkImage,
  waterMarkImageUpdated,
  email = "",
  reportMailingList,
  attendanceMailingList,
  financialsMailingList,
  isLiveClassThroughZoom = false,
  academic_year_start_date
) {
  return () =>
    Api({
      method: "post",
      url: `/institutes/update_institute_data${
        instituteId !== null ? `?institute_id=${instituteId}` : ""
      }`,
      attachment: [picture, waterMarkImage],
      type: "institute_image",
      data: {
        name,
        phone,
        address,
        water_mark_text: watermarkText,
        email,
        report_mailing_list: reportMailingList,
        attendance_mailing_list: attendanceMailingList,
        financials_mailing_list: financialsMailingList,
        institute_logo_updated: isLogoUpdated,
        water_mark_image_updated: waterMarkImageUpdated,
        is_live_class_through_zoom: isLiveClassThroughZoom,
        academic_year_start_date,
      },
    });
}

export function getInstituteDetails(instituteId = "") {
  return (dispatch) =>
    Api({
      method: "get",
      url: `/institutes/institute_details${
        instituteId !== null ? `?institute_id=${instituteId}` : ""
      }`,
    }).then((response) => {
      if (response.success) {
        const payload = {
          instituteName: response.name,
          instituteLogo: response.picture,
          phone: response.phone,
          address: response.address,
          mailingList: response.report_mailing_list,
          attendanceMailingList: response.attendance_info_mailing_list,
          financialsMailingList: response.financials_mailing_list,
          instituteEmail: response.email,
          watermarkText: response.watermark_text,
          watermarkImage: response.watermark_image,
          adminFirstName: response.admin_first_name,
          adminLastName: response.admin_last_name,
          adminEmail: response.admin_email,
          adminPhone: response.admin_contact,
          academicYearStartDate: response.academic_year_start_date,
          isZoomIntegrated: response.is_zoom_integrated,
          isMeetThroughZoom: response.is_live_class_through_zoom,
        };
        dispatch({
          type: INSTITUTE_DETAILS,
          payload,
        });
      } else {
        dispatch({
          type: PERMISSION_DENIED,
          status: true,
        });
      }
      return response;
    });
}

export function sendLoginCredentials(payload = {}, type = "") {
  const { courseId, campusId, classroomId, departmentId } = payload;

  return (dispatch) =>
    Api({
      method: "post",
      url: "/institutes/send_student_login_credentials",
      data: {
        type,
        course_id: courseId || "",
        campus_id: campusId || "",
        classroom_id: classroomId || "",
        department_id: departmentId || "",
      },
    }).then((response) => {
      if (response.success) {
        dispatch(showToast(response.message || "Will be shared successfully."));
      } else {
        dispatch(showToast("Failed to send Credentials"));
      }
    });
}

export function uploadStudentDetails(file, importType, instituteId = "") {
  return () =>
    Api({
      method: "post",
      url: `/institutes/upload_student_details${
        instituteId !== null ? `?institute_id=${instituteId}` : ""
      }`,
      attachment: file,
      type: "spreadsheet",
      data: { import_type: importType },
    });
}

export function getCampusList() {
  return (dispatch) =>
    Api({
      method: "get",
      url: `/institutes/get_campus_list`,
    }).then((response) => {
      if (response.success) {
        dispatch({
          type: GET_CAMPUS_FOR_INSTITUTE,
          payload: {
            campusList: response.campus_list,
          },
        });
      }
      return response;
    });
}

export function createCampus(campusName) {
  return () => Api({
    method: "post",
    url: '/institutes/create_campus',
    data: {
      campus_name: campusName,
    },
  }).then((response) => {
    return response;
  });
}

export function createClassroom(classroomName, campusId) {
  return () => Api({
    method: 'post',
    url: '/institutes/create_classroom',
    data: {
      classroom_name: classroomName,
      campus_id: campusId,
    },
  }).then((response) => {
    return response;
  });
}

export function uploadVerifiedClassroomsAndStudents(
  importType,
  instituteId = ""
) {
  return () =>
    Api({
      method: "post",
      url: `/institutes/upload_verified_classrooms_students${
        instituteId !== null ? `?institute_id=${instituteId}` : ""
      }`,
      data: { success: true, import_type: importType },
    });
}

export function uploadVerifiedTreachers(importType, instituteId = "") {
  return () =>
    Api({
      method: "post",
      url: `/institutes/upload_verified_teachers${
        instituteId !== null ? `?institute_id=${instituteId}` : ""
      }`,
      data: { success: true, import_type: importType },
    });
}

export function updateCurrentStudentData(
  index,
  studentName,
  badgeId,
  phone,
  studentEmail,
  classroomId,
  classroomName,
  studentCampus,
  active,
  fatherName, fatherPhone, dob, fatherEmail, motherName, motherPhone, motherEmail, address, bloodGroup, doj, institutesTagIds, isHostelite
) {
  return (dispatch) => {
    const payload = {
      index,
      data: {
        first_name: studentName,
        phone,
        student_id: badgeId,
        classroom_id: classroomId,
        classroom_name: classroomName,
        campus_name: studentCampus,
        email: studentEmail,
        father_name: fatherName,
        father_phone: fatherPhone,
        dob,
        father_email: fatherEmail,
        mother_name: motherName,
        mother_phone: motherPhone,
        mother_email: motherEmail,
        address,
        blood_group: bloodGroup,
        doj,
        active,
        tag_ids: institutesTagIds,
        is_hostelite: isHostelite,
      },
    };
    dispatch({
      type: UPDATE_STUDENT_DETAILS,
      payload,
    });
  };
}

export function updateClassroomsAndStudents(classroomsAndStudents) {
  return (dispatch) => {
    dispatch({
      type: CLASSROOMS_AND_STUDENTS,
      classroomsAndStudents: { ...classroomsAndStudents },
    });
  };
}

export function getMembershipDetails(payload = {}, instituteId = "") {
  const courseId = payload.courseId || "";
  const campusId = payload.campusId || "";
  const classroomId = payload.classroomId || "";
  const departmentId = payload.departmentId || "";
  const searchString = payload.searchString || "";
  let { activePage } = payload;
  activePage = activePage >= 2 ? activePage - 1 : 0;
  const itemsCountPerPage =
    payload.itemsCountPerPage || MANAGE_INSTITUTE_TOTAL_ITMES_PER_PAGE;
  const isDownload = payload.isDownload || false;
  return (dispatch) =>
    Api({
      method: "get",
      url: `/institutes/get_membership_details?course_id=${courseId}&campus_id=${campusId}&classroom_id=${classroomId}&department_id=${departmentId}&searchString=${searchString}${
        instituteId !== null ? `&institute_id=${instituteId}` : ""
      }&active_page=${activePage}&items_count_per_page=${itemsCountPerPage}&download=${isDownload}`,
    }).then((response) => {
      if (response.success) {
        if (response.student_details_save.length > 0) {
          dispatch({
            type: SAVE_STUDENT_DETAILS,
            studentDetailsSave: response.student_details_save,
          });
        }
        const studentsMembershipIds = [];
        response.student_details.map((student) => {
          studentsMembershipIds.push(student.id);
        });
        dispatch({
          type: MEMBER_DETAILS,
          campuses: response.campuses,
          classrooms: response.classrooms,
          courses: response.courses,
          studentDetails: response.student_details,
          preferences: response.preferences,
          studentDetailsLength: response.student_details_length,
          departments: response.departments,
          isHigherEducationInstitute: response.is_higher_education_institute,
          electives: response.electives,
          studentsMembershipIds,
          canSendBulkCredentials: response.can_send_bulk_credentails,
          institutesTags: response.tags,
          institutesTagGroups: response.tag_groups,
          isAdmin: response.is_admin
        });
      } else {
        dispatch({
          type: PERMISSION_DENIED,
          status: true,
        });
      }
      return response;
    });
}

export function getMembershipDetailsFb() {
  return (dispatch) => 
    Api({
      method: "get",
      url: "/institutes/get_membership_details_fb",
    }).then((response) => {
      if (response.success) {
        dispatch({
          type: INSTITUTE_DETAILS,
          payload: {  
            campuses: response.campuses,
            classrooms: response.classrooms,
            courses: response.courses,
            studentDetailsFb: response.student_details,
            preferences: response.preferences,
            studentDetailsLength: response.student_details_length,
            departments: response.departments,
            isHigherEducationInstitute: response.is_higher_education_institute,
            electives: response.electives,
            institutesTags: response.tags,
            institutesTagGroups: response.tag_groups,
          }
        });
      }
      return response;
    });
}

export function bulkUpdateStudentsTags (studentsIdsList, selectedTag) {
  return (dispatch) => 
    Api({
      method: "post",
      url: "/institutes/bulk_update_student_tags",
      data: {
        students_list: JSON.stringify(studentsIdsList),
        tag: JSON.stringify(selectedTag),
      }
    }).then((response) => {
      dispatch(showToast(response.message));
      return response;
    });
}


export function createNewStudent(
  studentName,
  badgeId,
  phone,
  studentEmail,
  classroomId,
  classroomName,
  studentCampus,
  courseId,
  active,
  fatherName,
  fatherPhone,
  dob,
  fatherEmail,
  motherName,
  motherPhone,
  motherEmail,
  address,
  bloodGroup,
  doj,
  studentElectives,
  activePage,
  institutesTagIds,
  isHostelite,
) {
  const studentElectivesData = studentElectives
    ? JSON.stringify(studentElectives)
    : [];
  return (dispatch) =>
    Api({
      method: "post",
      url: "/institutes/create_new_student",
      data: {
        first_name: studentName,
        phone,
        badge_id: badgeId,
        classroom_id: classroomId,
        classroom_name: classroomName,
        campus_name: studentCampus,
        course_id: courseId,
        email: studentEmail,
        active,
        parent_name: fatherName,
        parent_phone: fatherPhone,
        dob,
        father_email: fatherEmail,
        mother_name: motherName,
        mother_phone: motherPhone,
        mother_email: motherEmail,
        address,
        blood_group: bloodGroup,
        doj,
        studentElectives: studentElectivesData,
        tag_ids: JSON.stringify(institutesTagIds),
        is_hostelite: isHostelite,
      },
    }).then((response) => {
      dispatch(showToast(response.message));
      dispatch(getMembershipDetails({ activePage }));
      return response;
    });
}

export function createNewSubject(
  subjectName,
  subjectCode,
  selectedCourses,
  instituteId
) {
  return (dispatch) =>
    Api({
      method: "post",
      url: "/institutes/create_or_edit_institute_subject",
      data: {
        subject_name: subjectName,
        subject_code: subjectCode,
        courses: JSON.stringify(selectedCourses),
        institute_id: instituteId,
      },
    }).then((response) => {
      dispatch(showToast(response.message));
      return response;
    });
}

export function updateStudentStatus(studentIds, active, searchString) {
  return (dispatch) =>
    Api({
      method: "post",
      url: "/institutes/update_student_status",
      data: { student_ids: JSON.stringify(studentIds), active },
    }).then((response) => {
      dispatch(showToast(response.message));
      dispatch(getMembershipDetails({ searchString }));
      return response;
    });
}

export function updateStudentDetails(
  student,
  studentName,
  badgeId,
  phone,
  studentEmail,
  classroomId,
  courseId,
  active,
  fatherName, fatherPhone, dob, fatherEmail, motherName, motherPhone, motherEmail, address,
  bloodGroup,
  doj,
  studentElectives,
  activePage,
  institutesTagIds,
  isHostelite,
) {
  const studentElectivesData = studentElectives
    ? JSON.stringify(studentElectives)
    : [];
  return (dispatch) =>
    Api({
      method: "post",
      url: "/institutes/update_student_details",
      data: {
        id: student,
        student_name: studentName,
        badge_id: badgeId,
        phone,
        email: studentEmail,
        classroom_id: classroomId,
        course_id: courseId,
        active,
        father_name: fatherName,
        father_phone: fatherPhone,
        dob,
        father_email: fatherEmail,
        mother_name: motherName,
        mother_phone: motherPhone,
        mother_email: motherEmail,
        address,
        blood_group: bloodGroup,
        doj,
        studentElectives: studentElectivesData,
        tag_ids: JSON.stringify(institutesTagIds),
        is_hostelite: isHostelite,
      },
    }).then((response) => {
      dispatch(showToast(response.message));
      dispatch(getMembershipDetails({ activePage }));
      return response;
    });
}

export function updateSubjectDetails(
  subjectId,
  subjectName,
  subjectCode,
  selectedCourses,
  activePage,
  instituteId
) {
  return (dispatch) =>
    Api({
      method: "post",
      url: "/institutes/create_or_edit_institute_subject",
      data: {
        subject_id: subjectId,
        subject_name: subjectName,
        subject_code: subjectCode,
        courses: JSON.stringify(selectedCourses),
        institute_id: instituteId,
      },
    }).then((response) => {
      dispatch(showToast(response.message));
    });
}

export function uploadTeacherDetails(file, importType, instituteId = "") {
  return () =>
    Api({
      method: "post",
      url: `/institutes/upload_teacher_details${
        instituteId !== null ? `?institute_id=${instituteId}` : ""
      }`,
      attachment: file,
      type: "spreadsheet",
      data: { import_type: importType },
    });
}

export function manageTeacherDetails(payload) {
  const teacher_id = payload.teacherId;
  const title = payload.teacherTitle;
  const role = payload.selectedRole.label;
  const name = payload.teacherName;
  const badge_id = payload.teacherBadgeId;
  const active = payload.isActive;
  const email = payload.teacherEmail;
  const institute_id = payload.instituteId;
  const subjects_classrooms = JSON.stringify(payload.subjectClassrooms);
  return (dispatch) =>
    Api({
      method: "post",
      url: "/institutes/create_or_update_institute_teacher_details",
      data: {
        teacher_id,
        badge_id,
        subjects_classrooms,
        active,
        email,
        name,
        title,
        role,
        institute_id,
      },
    }).then((response) => {
      dispatch(showToast(response.message));
      return response;
    });
}

export function updateAdminDetails(first_name, last_name, phone, email) {
  return () =>
    Api({
      method: "post",
      url: "/institutes/update_admin_details",
      data: {
        first_name,
        last_name,
        phone,
        email,
      },
    });
}

export function addZoomAccount(
  zoomApiSecret,
  zoomApiKey,
  zoomSdkKey,
  zoomSdkSecret
) {
  const isValidZoomCredentials =
    zoomApiKey !== HIDDEN_SECRET &&
    zoomApiSecret !== HIDDEN_SECRET &&
    !!zoomApiKey &&
    !!zoomApiSecret;
  const isValidZoomSdk =
    zoomSdkKey !== HIDDEN_SECRET &&
    zoomSdkSecret !== HIDDEN_SECRET &&
    !!zoomSdkKey &&
    !!zoomSdkSecret;
  const apipayload = {
    zoom_api_key: zoomApiKey,
    zoom_api_secret: zoomApiSecret,
  };
  const sdkpayload = {
    zoom_sdk_key: zoomSdkKey,
    zoom_sdk_secret: zoomSdkSecret,
  };
  return (dispatch) =>
    Api({
      method: "post",
      url: "/institutes/add_zoom_account",
      data: {
        zoom_secret: isValidZoomCredentials ? encrypt(apipayload) : "",
        zoom_sdk_secret: isValidZoomSdk ? encrypt(sdkpayload) : "",
      },
    }).then((response) => {
      if (response.success) {
        dispatch(showToast("Update successful!"));
      } else {
        dispatch(
          showToast(
            `Error occurred: ${response.message ? response.message : ""}`
          )
        );
      }
      return response;
    });
}

export function getTeamManagementDetails() {
  return (dispatch) =>
    Api({
      method: "get",
      url: "/institutes/get_team_management_details",
    }).then((response) => {
      if (response.success) {
        dispatch({
          type: GET_TEAM_MANAGEMENT,
          payload: {
            teamManagementDetails: response.team_management_details,
            campuses: response.campuses
          },
        });
      }
      return response;
    });
}

export function getTestGroupDetails() {
  return (dispatch) =>
    Api({
      method: "get",
      url: "/institutes/get_test_group_details",
    }).then((response) => {
      if (response.success) {
        dispatch({
          type: GET_TEST_GROUP_DATA,
          payload: {
            testGroupDetails: response.test_group_details,
            testTypes: response.test_types,
            subjectList: response.subjects,
          },
        });
      }
      return response;
    });
}

export function deleteTeamMember(teamMemberdata) {
  return () =>
    Api({
      method: "post",
      url: "/institutes/destroy_team_member",
      data: {
        member_data: JSON.stringify(teamMemberdata),
      },
    });
}

export function createAndUpdateTeamManagement(teamMemberdata) {
  return (dispatch) =>
    Api({
      method: "post",
      url: "/institutes/create_and_update_team_management",
      data: {
        member_data: JSON.stringify(teamMemberdata),
      },
    }).then((response) => {
      if (response.success) {
        dispatch({
          type: GET_TEAM_MANAGEMENT,
          payload: {
            teamMemberDetails: response.team_member_details,
          },
        });
        window.location.reload();
      }
      dispatch(showToast(response.error_message, 5000));
    });
}

export function createAndUpdateTestGroup(testGroupData) {
  return (dispatch) =>
    Api({
      method: "post",
      url: "/institutes/create_and_update_test_groups",
      data: {
        test_group_data: JSON.stringify(testGroupData),
      },
    }).then((response) => {
      if (response.success) {
        dispatch({
          type: GET_TEST_GROUP_DATA,
          payload: {
            testGroupDetails: response.test_group_details,
            testTypes: response.test_types,
            subjectList: response.subjects,
          },
        });
        return response;
      }
    });
}

export function getTeacherDetails(payload = {}, instituteId = "") {
  const courseId = payload.courseId || "";
  const campusId = payload.campusId || "";
  const classroomId = payload.classroomId || "";
  const departmentId = payload.departmentId || "";
  const searchString = payload.searchString || "";
  let { activePage } = payload;
  activePage = activePage >= 2 ? activePage - 1 : 0;
  const itemsCountPerPage =
    payload.itemsCountPerPage || MANAGE_INSTITUTE_TOTAL_ITMES_PER_PAGE;
  const isDownload = payload.isDownload || false;
  return (dispatch) =>
    Api({
      method: "get",
      url: `/institutes/get_teacher_details?course_id=${courseId}&campus_id=${campusId}&classroom_id=${classroomId}&department_id=${departmentId}&searchString=${searchString}${
        instituteId !== null ? `&institute_id=${instituteId}` : ""
      }&active_page=${activePage}&items_count_per_page=${itemsCountPerPage}&download=${isDownload}`,
    }).then((response) => {
      if (response.success) {
        const teacherMembershipIds = [];
        const payloadData = {
          campuses: response.campuses,
          classrooms: response.classrooms,
          courses: response.courses,
          teacherDetails: response.teacher_details,
          teacherDetailsSave: response.teacher_details_save,
          teacherDetailsLength: response.teacher_details_length,
          departments: response.departments,
          isHigherEducationInstitute: response.is_higher_education_institute,
          subjects: response.subjects,
          teacherMembershipIds,
        };
        dispatch({
          type: TEACHER_DETAILS,
          payload: payloadData,
        });
      } else {
        dispatch({
          type: PERMISSION_DENIED,
          status: true,
        });
      }
    });
}

export function getSubjectDetails(payload = {}) {
  let urlParams = `?active_page=${payload.activePage || 1}`;
  if (payload && payload.searchString) {
    urlParams += `&searchString=${payload.searchString}`;
  }
  if (payload && payload.departmentId) {
    urlParams += `&department_id=${payload.departmentId}`;
  }
  if (payload && payload.courseId) {
    urlParams += `&course_id=${payload.courseId}`;
  }
  if (payload && payload.instituteId) {
    urlParams += `&institute_id=${payload.instituteId}`;
  }
  urlParams += `&items_count_per_page=${
    payload.itemsCountPerPage
      ? payload.itemsCountPerPage
      : MANAGE_INSTITUTE_TOTAL_ITMES_PER_PAGE
  }`;
  urlParams += `&download=${payload.isDownload ? payload.isDownload : false}`;

  return (dispatch) =>
    Api({
      method: "get",
      url: `/institutes/get_institute_subject_data${urlParams}`,
    }).then((response) => {
      if (response.success) {
        dispatch({
          type: SUBJECT_DETAILS,
          subjectData: response.subject_data,
          subjectDetailsLength: response.subject_details_length,
          campuses: response.campuses.map(({ id, name }) => {
            return { label: name, value: id };
          }),
          classrooms: response.classrooms.map(({ id, name }) => {
            return { label: name, value: id };
          }),
          courses: response.courses.map(({ id, name, department_id }) => {
            return { label: name, value: id, department_id };
          }),
          departments: response.departments.map(({ id, name }) => {
            return { label: name, value: id };
          }),
          deptCourses: response.dept_courses,
          subjectDetailsSave: response.subject_details_save,
          isHigherEducationInstitute: response.is_higher_education_institute,
        });
      } else {
        dispatch({
          type: PERMISSION_DENIED,
          status: true,
        });
      }
      return response;
    });
}

export const getCampuses = () => async (dispatch) => {
  const response = await Api({
    method: "get",
    url: "/institutes/get_institute_campuses_details",
  });
  if (response.success) {
    await dispatch({
      type: INSTITUTE_DETAILS,
      payload: {
        campuses: response.campuses,
      },
    });
  }
  if (response.message) {
    await dispatch(showToast(response.message));
  }
};

export function getCampusesClassrooms(instituteId = "") {
  return (dispatch) =>
    Api({
      method: "get",
      url: `/institutes/get_institute_classrooms_details${
        instituteId !== null ? `?institute_id=${instituteId}` : ""
      }`,
    }).then((response) => {
      if (response.success) {
        dispatch({
          type: CAMPUSES_CLASSROOMS,
          campusesClassrooms: response.campuses_and_classrooms,
          departmentCourseClassrooms: response.department_course_classrooms,
          isHigherEducationInstitute: response.is_higher_education_institute,
        });
      } else {
        dispatch({
          type: PERMISSION_DENIED,
          status: true,
        });
      }
      return response;
    });
}

export function activateclassrooms(classroomId) {
  return () =>
    Api({
      method: "post",
      url: "/classrooms/activate_classrooms",
      data: { id: classroomId },
    });
}

export function toggleClassroomActive(classroomId, active) {
  return () =>
    Api({
      method: "post",
      url: "/classrooms/toggle_active",
      data: { id: classroomId, active },
    }).then(() => {
      window.location.reload();
    });
}

export const toggleCampusActive = (id, active) => async (dispatch) => {
  const response = await Api({
    method: "post",
    url: "/classrooms/toggle_campus_active",
    data: { id, active },
  });
  if (response.success) {
    window.location.reload();
  }
  if (response.message) {
    await dispatch(showToast(response.message));
  }
};

export const editCampus = (id, name) => async (dispatch) => {
  const response = await Api({
    method: "post",
    url: "/classrooms/edit_campus",
    data: { campus_id: id, name },
  });
  if (response.success) {
    await dispatch({
      type: INSTITUTE_DETAILS,
      payload: {
        campuses: response.campuses,
      },
    });
  }
  if (response.message) {
    await dispatch(showToast(response.message));
  }
  return response;
};

export function editClassroom(classroomId, name) {
  return (dispatch) =>
    Api({
      method: "post",
      url: "/classrooms/edit_classrooms",
      data: { id: classroomId, name },
    }).then((response) => {
      if (response.success) {
        window.location.reload();
      } else {
        dispatch(showToast("Classroom with same name already exisits"));
      }
    });
}

export function newReferalData(instituteName, personName, phone) {
  return () =>
    Api({
      method: "post",
      url: "/institute_referral/create",
      data: { institute_name: instituteName, person_name: personName, phone },
    });
}

export function showUserDetails() {
  return (dispatch) =>
    Api({
      method: "get",
      url: "/institutes/show_user_details",
    }).then((response) => {
      if (response.success) {
        dispatch({
          type: USER_DETAILS,
          userName: response.name,
          gender: response.gender,
          newUser: response.new_user,
          contentBoardAccess: response.content_board_access,
          feeBoardAccess: response.feeboard_access,
          isDtpAdmin: response.is_institute_dtp_admin,
          isAccountant: response.is_accountant,
          isBusinessDeveloper: response.is_business_developer,
          hasAdmissionBoardAccess: response.has_admission_board_access,
        });
      }
      return response;
    });
}

export function getTestLockOtp() {
  return (dispatch) =>
    Api({
      method: "get",
      url: "/institutes/get_test_lock_otp",
    }).then((response) => {
      if (response.success) {
        dispatch({
          type: TEST_LOCK_OTP,
          otp: response.otp,
        });
      } else {
        dispatch({
          type: PERMISSION_DENIED,
          status: true,
        });
      }
      return response;
    });
}

export function getInstitutesTags() {
  return (dispatch) =>
    Api({
      method: "get",
      url: "/institutes/get_tags",
    }).then((response) => {
      if (response.success) {
        dispatch({
          type: INSTITUTE_DETAILS,
          payload: {
            institutesTags: response.tags,
            institutesTagGroups: response.tag_groups,
          },
        });
      }
      return response;
    });
}

export const editInstitutesTag = (id, name, tagGroupId) => async (dispatch) => {
  const response = await Api({
    method: "post",
    url: "/institutes/edit_institutes_tag",
    data: { tag_id: id, name, tag_group_id: tagGroupId },
  });
  if (response.success) {
    await dispatch({
      type: INSTITUTE_DETAILS,
      payload: {
        institutesTags: response.tags,
        institutesTagGroups: response.tag_groups,
      },
    });
  }
  if (response.message) {
    await dispatch(showToast(response.message));
  }
  return response;
};

export const createNewInstitutesTag = (name, tagGroup) => async (dispatch) => {
  const response = await Api({
    method: "post",
    url: "/institutes/create_new_institute_tag",
    data: { new_tag_name: name, tag_group: tagGroup === null ? 0 : tagGroup.value },
  });
  if (response.success) {
    await dispatch({
      type: INSTITUTE_DETAILS,
      payload: {
        institutesTags: response.tags,
        institutesTagGroups: response.tag_groups,
      },
    });
  }
  if (response.message) {
    await dispatch(showToast(response.message));
  }
  return response;
};

export const createNewInstitutesTagGroup = (name) => async (dispatch) => {
  const response = await Api({
    method: "post",
    url: "/institutes/create_new_institute_tag_group",
    data: { new_tag_group_name: name },
  });
  if (response.success) {
    await dispatch({
      type: INSTITUTE_DETAILS,
      payload: {
        institutesTags: response.tags,
        institutesTagGroups: response.tag_groups,
      },
    });
  }
  if (response.message) {
    await dispatch(showToast(response.message));
  }
  return response;
};

export function updateInstitutesTagGroup (newTagGroupName, tagGroupId) {
  return (dispatch) => 
    Api({
      method: "post",
      url: "/institutes/update_tag_group",
      data: { new_tag_group_name: newTagGroupName, tag_group_id: tagGroupId },
    }).then((response) => {
      if (response.success) {
        dispatch({
          type: INSTITUTE_DETAILS,
          payload: {
            institutesTags: response.tags,
            institutesTagGroups: response.tag_groups,
          },
        })
      }
      dispatch(showToast(response.message));
      return response;
    });
}

export function deleteTag (tagId) {
  return (dispatch) => 
    Api({
      method: "post",
      url: "/institutes/delete_tag",
      data: { tag_id: tagId },
    }).then((response) => {
      if (response.success) {
        dispatch({
          type: INSTITUTE_DETAILS,
          payload: {
            institutesTags: response.tags,
            institutesTagGroups: response.tag_groups,
          },
        })
      }
      dispatch(showToast(response.message));
      return response;
    });
}