import React, { Component } from 'react';
import { connect } from 'react-redux';
import Select from 'react-select';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import * as Sentry from '@sentry/browser';

import Layout from '../layout';

import {
  CLASSROOM_SELECTED, CAMPUS_SELECTED, START_DATE_SELECTED, END_DATE_SELECTED,
} from '../../constants';
import { getAttendanceReport, updateAttendanceFilterData } from '../../actions';

class AttendanceReport extends Component {
  constructor(props) {
    super(props);
    this.state = {
      campusSelected: {},
      classroomSelected: {},
      showLoader: true,
    };
    this.handleChange = this.handleChange.bind(this);
  }

  componentDidMount() {
    this.props.getAttendanceReport().then(() => {
      const {
        campusID, classroomID, campuses, classrooms,
      } = this.props.filterData;
      const campus = campuses.find((c) => c.id === campusID);
      const classroom = classrooms.find((c) => c.id === classroomID);
      const campusSelected = campus ? { value: campusID, label: campus.name } : {};
      const classroomSelected = { value: classroomID, label: classroom.name };
      this.setState({
        campusSelected,
        classroomSelected,
        showLoader: false,
      });
    });
  }

  handleChange(selectedItem, itemType) {
    const { classrooms } = this.props.filterData;
    switch (itemType) {
      case CLASSROOM_SELECTED:
        this.setState({ classroomSelected: selectedItem });
        this.props.updateAttendanceFilterData(selectedItem.value, itemType);
        break;
      case CAMPUS_SELECTED:
        const campusClassrooms = classrooms.filter((classroom) => classroom.campus_id
        === selectedItem.value)[0] || {};
        const classroomSelected = { value: campusClassrooms.id, label: campusClassrooms.name };
        this.setState({ campusSelected: selectedItem, classroomSelected });
        this.props.updateAttendanceFilterData(selectedItem.value, itemType);
        this.props.updateAttendanceFilterData(campusClassrooms.id, CLASSROOM_SELECTED);
        break;
      case START_DATE_SELECTED:
        const startDate = new Date(selectedItem);
        this.setState({ startDate });
        this.props.updateAttendanceFilterData(selectedItem, itemType);
        break;
      case END_DATE_SELECTED:
        const endDate = new Date(selectedItem);
        this.setState({ endDate });
        this.props.updateAttendanceFilterData(selectedItem, itemType);
        break;
      default:
        Sentry.captureMessage(`handleChange ${itemType}`);
    }
    this.props.getAttendanceReport();
  }

  render() {
    if (this.state.showLoader) {
      return (<div />);
    }
    const { classroomSelected, campusSelected } = this.state;
    const {
      startDate, endDate, campuses, classrooms, campusID,
    } = this.props.filterData;
    const { studentData, attendanceData } = this.props.report;
    const campusOptions = campuses.map((campus) => {
      return { value: campus.id, label: campus.name };
    });
    const campusClassrooms = classrooms.filter((classroom) => {
      return classroom.campus_id === parseInt(campusID, 10);
    });
    const classroomOptions = campusClassrooms.map((classroom) => {
      return { value: classroom.id, label: classroom.name };
    });
    const reportDates = attendanceData.map((period) => {
      return { date: new Date(period.date), count: period.data.length };
    });
    const isPeriods = reportDates.some((period) => period.count > 1);
    const startDatePicker = new Date(startDate);
    const endDatePicker = new Date(endDate);
    return (
      <Layout globalHistory={this.props.history} feedback footerShow={false}>

        <div className="row d-flex flex-row" style={{ marginBottom: '2%', backgroundColor: 'white' }}>
          <div style={{ width: '180px', marginLeft: '3%', marginTop: '10px' }}>
            <Select
              value={campusSelected}
              onChange={(selectedItem) => this.handleChange(selectedItem, CAMPUS_SELECTED)}
              options={campusOptions}
              placeholder={campusSelected}
              isSearchable={false}
            />
          </div>
          <div style={{ width: '160px', marginLeft: '3%', marginTop: '10px' }}>
            <Select
              value={classroomSelected}
              onChange={(selectedItem) => this.handleChange(selectedItem, CLASSROOM_SELECTED)}
              options={classroomOptions}
              placeholder={classroomSelected}
              isSearchable={false}
            />
          </div>
          <div style={{ marginLeft: '3%', marginTop: '10px' }}>
            <DatePicker
              selected={startDatePicker}
              selectsStart
              startDate={startDatePicker}
              endDate={endDatePicker}
              onChange={(date) => this.handleChange(date, START_DATE_SELECTED)}
              className="date-height"
            />

            <DatePicker
              selected={endDatePicker}
              selectsEnd
              startDate={startDatePicker}
              endDate={endDatePicker}
              onChange={(date) => this.handleChange(date, END_DATE_SELECTED)}
              maxDate={startDatePicker}
              className="ml-3 date-height"
            />
          </div>
        </div>
        { reportDates.length === 0
          && (
          <div className="row d-flex flex-row" style={{ marginBottom: '2%', backgroundColor: 'white', marginLeft: '5%' }}>
            <h4> No data for given selection range.</h4>
          </div>
          )}
        { reportDates.length > 0 && (
        <div style={{ backgroundColor: '#F8F8F8', marginTop: '10px' }}>
          <div className="table-att">
            <table className="table table-bordered" style={{ borderCollapse: 'collapse', textAlign: 'center' }}>
              <thead>
                <tr>
                  <th className="headcol-1"> Name </th>
                  {reportDates.map((reportDate, idx) => (
                    <th key={idx} colSpan={reportDate.count}>
                      {reportDate.date.getDate()}
                      .
                      {reportDate.date.getMonth() + 1}
                    </th>
                  ))}
                  <th className="headcol">Overall</th>
                </tr>
                {isPeriods && (
                <tr>
                  <th className="headcol-1" />
                  {
                  reportDates.map((reportDate, idx) => {
                    const th = [];
                    for (let i = 0; i < reportDate.count; i++) {
                      if (reportDate.count === 1) {
                        th.push(<th key={i} />);
                      } else {
                        th.push(<th key={i}>{attendanceData[idx].data[i].time}</th>);
                      }
                    }
                    return (
                      <>
                        {th}
                      </>
                    );
                  })
                }
                  <th className="headcol" />
                </tr>
                )}
              </thead>
              <tbody>
                {
                studentData.map((student) => {
                  let present = 0;
                  let count = 0;
                  const td = [<td className="headcol-1" key={`${student.id}_name`}>{student.first_name}</td>];
                  attendanceData.map((att, idx) => {
                    for (let i = 0; i < att.data.length; i++) {
                      count += 1;
                      if (att.data[i].attendance.indexOf(student.id) === -1) {
                        td.push(<td key={`${student.id}_present_${idx}_${i}`} style={{ color: '#FF7676' }}>A</td>);
                      } else {
                        td.push(<td key={`${student.id}_absent_${idx}_${i}`} style={{ color: '#18CBAF' }}>P</td>);
                        present += 1;
                      }
                    }
                  });
                  td.push(<td key={`${student.id}_overall`} className="headcol">
                    {present}
                    /
                    {count}
                  </td>);
                  return (
                    <tr>
                      {td}
                    </tr>
                  );
                })
              }
              </tbody>
            </table>
          </div>

        </div>
        )}
      </Layout>
    );
  }
}

const mapStateToProps = ({ attendance }) => ({
  filterData: attendance.filterData,
  report: attendance.report,
});

export default connect(mapStateToProps, {
  getAttendanceReport,
  updateAttendanceFilterData,
})(AttendanceReport);
