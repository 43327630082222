import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { applyInstitueFilters, showToast } from '../../actions';
import DropDown from './dropDown';
import DateRangePicker from './dateRangePicker';

class ApplyFilters extends PureComponent {
  constructor(props) {
    super(props);
    this.applyFilters = this.applyFilters.bind(this);
    this.filterView = this.filterView.bind(this);
  }

  getClassroomsList() {
    const {
      selectedCampus, selectedSemester, selectedSubject, selectedDepartment, classrooms, isHigherEducation, subjects,
    } = this.props;
    let filteredData = [];
    const campus = selectedCampus && selectedCampus.id;
    const department = selectedDepartment && selectedDepartment.id;
    const semester = selectedSemester && selectedSemester.id;
    let subjectSelected = selectedSubject && selectedSubject.id;
    if (subjectSelected) {
      [subjectSelected] = subjects.filter((subject) => subject.id === selectedSubject.id);
    }
    filteredData = classrooms.filter((classroom) => {
      if (isHigherEducation) {
        return ((!semester && !department && !subjectSelected)
          || ((semester && !subjectSelected) && (classroom.course_id === semester))
          || ((semester && subjectSelected) && (classroom.course_id === semester && subjectSelected.courses.includes(classroom.course_id)))
          || ((!semester && department && !subjectSelected) && (classroom.department_id === department))
          || ((!semester && department && subjectSelected) && (classroom.department_id === department && subjectSelected.departments.includes(classroom.department_id)))
          || (!semester && !department && subjectSelected && (subjectSelected.courses.includes(classroom.course_id) || subjectSelected.departments.includes(classroom.department_id)))
          || ((semester && department && subjectSelected) && (classroom.department_id === department && subjectSelected.departments.includes(classroom.department_id) && classroom.course_id === semester))
        );
      }
      return (!campus || classroom.campus_id === campus);
    });
    return filteredData;
  }

  getSemesterList() {
    const { semesters, selectedDepartment } = this.props;
    const department = selectedDepartment && selectedDepartment.id;
    let filteredData = [];
    filteredData = semesters.filter((semester) => {
      return (!department || semester.department_id === department);
    });
    return filteredData;
  }

  getSubjectList() {
    const { subjects, selectedDepartment, selectedSemester } = this.props;
    const department = selectedDepartment && selectedDepartment.id;
    const semester = selectedSemester && selectedSemester.id;
    let filteredData = [];
    filteredData = subjects.filter((subject) => {
      return ((!semester && !department)
        || (semester && subject.course_id_list.includes(semester))
        || (!semester && department && subject.department_id_list.includes(department))
      );
    });
    return filteredData;
  }

  getTeachersList() {
    const { teachers, selectedClassroom, selectedDepartment } = this.props;
    const classroom = selectedClassroom && selectedClassroom.id;
    const department = selectedDepartment && selectedDepartment.id;
    let filteredData = [];
    filteredData = teachers.filter((teacher) => {
      return (!classroom && !department) || (department && !classroom && teacher.department_ids && teacher.department_ids.includes(department)) || (classroom && teacher.classroom_id === classroom);
    });
    return filteredData;
  }

  filterView() {
    const {
      hideApply, hideClear, inline, defaultDepartment,
    } = this.props;
    return (
      <div style={inline ? { display: 'flex', flex: 1 } : {}}>
        {this.applyFilters()}
        { (!hideApply || !hideClear)
        && (
        <div style={inline ? {
          display: 'flex', alignItems: 'center', marginLeft: 10, flexDirection: 'row-reverse',
        }
          : {
            display: 'flex', width: '100%', justifyContent: 'space-between', marginTop: 70,
          }}
        >
          {!hideClear && (
          <div
            role="presentation"
            className={!inline && 'Button'}
            style={inline ? { margin: '0 10px', cursor: 'pointer' } : { width: 130 }}
            onClick={() => this.props.applyInstitueFilters({
              selectedCampus: {},
              selectedClassroom: null,
              selectedDepartment: defaultDepartment,
              selectedSemester: {},
              selectedSubject: {},
              selectedTeacher: {},
            }).then(() => {
              this.props.applyDataFilters();
              this.props.showToast('Cleared Filters');
            })}
          >
            Clear
          </div>
          )}
          {!hideApply && (
          <div
            role="presentation"
            className="Button"
            style={{ width: 130 }}
            onClick={() => {
              this.props.applyDataFilters();
              // this.props.showToast('Filter Applied');
            }}
          >
            Search
          </div>
          )}
        </div>
        )}
      </div>
    );
  }

  applyFiltersData(data) {
    this.props.applyInstitueFilters(data).then(() => {
      const { applyOnChange } = this.props;
      if (applyOnChange) {
        this.props.applyDataFilters();
      }
    });
  }

  applyFilters() {
    const {
      campuses, isHigherEducation, departments, teachers, hideClassroom, hideDepartment, hideTeacher, hideSubject, inline, hideSemester, semesterRequired, classroomRequired, subjectRequired,
      selectedCampus, selectedClassroom, selectedDepartment, selectedSemester, selectedSubject, selectedTeacher, startDate, endDate, showDatePicker, departmentRequired, teacherRequired,
    } = this.props;
    const filterStyle = inline ? { display: 'flex', justifyContent: 'space-between' } : {
      marginTop: 0, marginBottom: 20, justifyContent: 'space-between', flexFlow: 'wrap',
    };
    const componentStyle = inline ? { marginRight: 10 } : { margin: '40px 0' };
    const classroomList = this.getClassroomsList();
    const teacherList = this.getTeachersList();
    const subjectList = this.getSubjectList();
    if (isHigherEducation) {
      const semesterList = this.getSemesterList();
      return (
        <div style={filterStyle}>
          {!hideDepartment && (
          <div style={componentStyle}>
            {this.renderDropDownValues(selectedDepartment, { ...selectedDepartment }, departments, 'Select Department', (id, name) => this.applyFiltersData({
              selectedDepartment: id ? { id, name } : {},
              selectedSubject: null,
              selectedClassroom: null,
              selectedSemester: {},
              selectedTeacher: {},
            }), departmentRequired, 170)}
          </div>
          )}
          {!hideSemester && (
          <div style={componentStyle}>
            {this.renderDropDownValues(`${selectedDepartment.name}`, { ...selectedSemester }, [...semesterList], 'Select Semester', (id, name) => this.applyFiltersData({
              selectedSemester: id ? { id, name } : {},
              selectedClassroom: null,
              selectedSubject: null,
            }), semesterRequired, 150)}
          </div>
          )}
          {!hideSubject && (
          <div style={componentStyle}>
            {this.renderDropDownValues(`${selectedSemester.name}${selectedDepartment.name}`, { ...selectedSubject }, [...subjectList], 'Select Subject', (id, name) => this.applyFiltersData({
              selectedSubject: id ? { id, name } : {},
              selectedClassroom: null,
            }), subjectRequired, 250)}
          </div>
          )}
          {!hideClassroom && (
          <div style={componentStyle}>
            {this.renderDropDownValues(`${selectedDepartment.name}${selectedSemester.name}${selectedSubject.name}`, { ...selectedClassroom }, [...classroomList], 'Select Section', (id, name) => this.applyFiltersData({ selectedClassroom: id ? { id, name } : {} }), classroomRequired)}
          </div>
          )}
          {!hideTeacher && (
          <div style={componentStyle}>
            {this.renderDropDownValues(`${selectedClassroom.name}${selectedDepartment.id}${selectedTeacher.name}`, { ...selectedTeacher }, [...teacherList], 'Select Teacher', (id, name) => this.applyFiltersData({ selectedTeacher: id ? { id, name } : {} }), teacherRequired)}
          </div>
          )}
          { showDatePicker
            && (
            <div style={componentStyle}>
              <DateRangePicker disabled={!(selectedClassroom && selectedClassroom.id)} startDate={startDate} endDate={endDate} handleChange={(start, end) => this.applyFiltersData({ selectedStartDate: start, selectedEndDate: end })} />
            </div>
            )}
        </div>
      );
    }
    return (
      <div style={filterStyle}>
        {!!campuses.length && (
          <div style={componentStyle}>
            {this.renderDropDownValues(selectedCampus, { ...selectedCampus }, campuses, 'Select Campus', (id, name) => this.applyFiltersData({ selectedCampus: { id, name }, selectedClassroom: null }))}
          </div>
        )}
        {!hideSubject && (
          <div style={componentStyle}>
            {this.renderDropDownValues(selectedSubject, { ...selectedSubject }, [...subjectList], 'Select Subject', (id, name) => this.applyFiltersData({
              selectedSubject: id ? { id, name } : {},
              selectedClassroom: null,
            }), 250)}
          </div>
        )}
        {!hideClassroom && (
        <div style={componentStyle}>
          {this.renderDropDownValues(`${selectedCampus.name}`, { ...selectedClassroom }, classroomList, 'Select Section', (id, name) => this.applyFiltersData({ selectedClassroom: { id, name } }))}
        </div>
        )}
        {!!teachers.length && (
          <div style={componentStyle}>
            {this.renderDropDownValues(`${selectedClassroom.name}${selectedTeacher.name}`, { ...selectedTeacher }, [...teacherList], 'Select Teacher', (id, name) => this.applyFiltersData({ selectedTeacher: id ? { id, name } : {} }))}
          </div>
        )}
        { showDatePicker
          && (
          <div style={componentStyle}>
            <DateRangePicker disabled={!(selectedClassroom && selectedClassroom.id)} startDate={startDate} endDate={endDate} handleChange={(start, end) => this.applyFiltersData({ selectedStartDate: start, selectedEndDate: end })} />
          </div>
          )}
      </div>
    );
  }

  renderDropDownValues(fieldName, fieldValue, dropDownValues, placeholder, updateValue, required, inlineItemWidth = 120) {
    const filterItems = [];
    const {
      width, inline, dropDownColor, ignoreRequired,
    } = this.props;
    for (let i = 0; i < dropDownValues.length; i += 1) {
      filterItems[i] = dropDownValues[i];
    }
    return (
      <div style={{ display: 'flex' }}>
        <DropDown
          defaultText={inline ? placeholder.replace('Select ', '') : placeholder}
          key={`${fieldValue.name}_${fieldName}`}
          selectedItem={fieldValue.id && fieldValue}
          optionsList={[...filterItems]}
          width={inline ? inlineItemWidth : width || 360}
          height={40}
          color="#DDDDDD"
          placeholder={placeholder}
          onValueChange={updateValue}
          dropDownColor={dropDownColor}
        />
        {!ignoreRequired && <div style={{ marginLeft: 10, color: 'red', width: 10 }}>{required ? '*' : ''}</div>}
      </div>
    );
  }

  render() {
    return this.filterView();
  }
}

ApplyFilters.propTypes = {
  selectedCampus: PropTypes.object,
  selectedClassroom: PropTypes.object,
  selectedDepartment: PropTypes.object,
  selectedSemester: PropTypes.object,
  selectedSubject: PropTypes.object,
  selectedTeacher: PropTypes.object,
  campuses: PropTypes.array,
  semesters: PropTypes.array,
  subjects: PropTypes.array,
  departments: PropTypes.array,
  hideApply: PropTypes.bool,
  applyOnChange: PropTypes.bool,
  hideClear: PropTypes.bool,
  width: PropTypes.number,
  hideDepartment: PropTypes.bool,
  hideTeacher: PropTypes.bool,
  hideSemester: PropTypes.bool,
  hideClassroom: PropTypes.bool,
  classrooms: PropTypes.array,
  teachers: PropTypes.array,
  isHigherEducation: PropTypes.bool,
  inline: PropTypes.bool,
  startDate: PropTypes.string,
  endDate: PropTypes.string,
  showDatePicker: PropTypes.bool,
  hideSubject: PropTypes.bool,
  dropDownColor: PropTypes.string,
  defaultDepartment: PropTypes.object,
  semesterRequired: PropTypes.bool,
  classroomRequired: PropTypes.bool,
  departmentRequired: PropTypes.bool,
  teacherRequired: PropTypes.bool,
  subjectRequired: PropTypes.bool,
  ignoreRequired: PropTypes.bool,
};

ApplyFilters.defaultProps = {
  selectedCampus: {},
  selectedDepartment: {},
  selectedSemester: {},
  selectedSubject: {},
  selectedClassroom: {},
  selectedTeacher: {},
  campuses: [],
  semesters: [],
  applyOnChange: false,
  hideTeacher: false,
  hideSemester: false,
  hideClassroom: false,
  subjects: [],
  hideApply: false,
  hideClear: false,
  hideDepartment: false,
  width: 360,
  departments: [],
  classrooms: [],
  teachers: [],
  isHigherEducation: false,
  inline: false,
  startDate: null,
  defaultDepartment: {},
  endDate: new Date(),
  showDatePicker: false,
  hideSubject: false,
  dropDownColor: '1px solid #7C94BA',
  semesterRequired: false,
  classroomRequired: false,
  departmentRequired: false,
  teacherRequired: false,
  subjectRequired: false,
  ignoreRequired: false,
};

export default connect(null, { applyInstitueFilters, showToast })(ApplyFilters);
