import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import MathJax from 'react-mathjax2';
import {
  updateShowStatus, updateQuestionIndex, updateQuizState,
  updateLiveResults, updateAttendeesCount, showToast,
} from '../../actions';
import { nextQuestion } from '../../static/images/next-question.svg';
import { asciiForMathJax } from '../../helpers/util';
import { MATHJAX_SCRIPT } from '../../constants';
import '../../static/tools.css';
import prevQuestionPaper from '../../static/images/prev-question.svg';

const buttonStyle = {
  background: '#9FC771',
  userSelect: 'none',
  width: '50px',
  padding: '5px 10px',
  borderRadius: 0,
  color: 'black',
  fontWeight: 'bold',
  cursor: 'pointer',
};

const BACKGROUND = {
  UNANSWERED: '#FFFFFF',
  CORRECT: '#9EE69EA6',
};

const BORDER = {
  UNANSWERED: '#45454566',
  CORRECT: '#92DB927A',
};

class IntegratedQuizQuestionView extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      textChanges: {
        id: props.question.id,
        question_text: props.question.question_text,
        option_1: props.question.option_1,
        option_2: props.question.option_2,
        option_3: props.question.option_3,
        option_4: props.question.option_4,
        answer: props.question.answer,
        show: props.question.show,
        isLocked: props.question.is_locked,
      },
      quizId: props.question.quiz_id,
      isEditable: false,
    };
    this.handleChange = this.handleChange.bind(this);
    this.updateQuestion = this.updateQuestion.bind(this);
    this.renderOptionDisplay = this.renderOptionDisplay.bind(this);
    this.renderQuestionText = this.renderQuestionText.bind(this);
    this.showQuestion = this.showQuestion.bind(this);
    this.updateQuestionView = this.updateQuestionView.bind(this);
    this.editQuestion = this.editQuestion.bind(this);
    this.updateShowQuestion = this.updateShowQuestion.bind(this);
    this.migrateQuestions = this.migrateQuestions.bind(this);
    this.focus = this.focus.bind(this);
    if (props.question.is_locked) {
      this.props.updateLiveResults(props.question.id, props.question.quiz_id);
    }
  }

  updateShowQuestion() {
    const { textChanges } = this.state;
    const { question } = this.props;
    this.props.updateShowStatus([question.id], textChanges.show, question.quiz_id);
  }

  handleChange(type, value) {
    const { textChanges } = this.state;
    textChanges[type] = value;
    this.setState({ textChanges: { ...textChanges } });
  }

  updateQuestion() {
    const { textChanges } = this.state;
    const { id } = this.props.match.params;
    if (this.isDataPresent(textChanges)) {
      this.handleCloseModal();
      this.props.updateQuizQuestion(id, textChanges);
    }
  }

  updateQuizState(status) {
    const { quizId } = this.state;
    this.props.updateQuizState(quizId, status);
  }

  completeQuiz() {
    const { isCompleted } = this.props;
    return (
      <div
        className="black-white-button"
        role="presentation"
        style={{
          cursor: isCompleted && 'not-allowed', width: '110px',
        }}
        onClick={() => !isCompleted && this.updateQuizState('COMPLETE')}
      >
        {isCompleted ? 'COMPLETED' : 'COMPLETE'}
      </div>
    );
  }

  isDataPresent(textChanges) {
    let optionArray = Array.from(new Set([textChanges.option_1,
      textChanges.option_2, textChanges.option_3, textChanges.option_4]));
    optionArray = optionArray.filter((e) => e);
    if (!textChanges.question_text) {
      this.props.showToast('Question Text Should Be Present');
      return false;
    }
    if (optionArray.length !== 4) {
      this.props.showToast('All four options should be present and it should be unique');
      return false;
    }
    if (!textChanges.answer) {
      this.props.showToast('Atleast one option should be marked as correct');
      return false;
    }
    return true;
  }

  showQuestion() {
    const {
      isQuestionShown, isQuestionLocked, next,
    } = this.props;
    if (!isQuestionShown) {
      return (
        <div
          role="presentation"
          className="black-white-button"
          onClick={() => this.updateShowQuestion()}
        >
          START
        </div>
      );
    }
    if (!isQuestionLocked) {
      return (
        <div
          role="presentation"
          className="black-white-button"
          onClick={() => {
            this.updateResults(0);
          }}
        >
          FINISH
        </div>
      );
    }
    if (!next) {
      return this.completeQuiz();
    }
    return (
      <>
      </>
    );
  }

  updateQuestionView() {
    return (
      <div style={{
        display: 'flex', justifyContent: 'space-between', width: '100%', marginBottom: 40,
      }}
      >
        <div
          role="presentation"
          style={{ ...buttonStyle }}
          onClick={() => { this.updateQuestion(); }}
        >
          Update
        </div>
        <div
          role="presentation"
          style={{ ...buttonStyle }}
          onClick={() => { this.setState({ isEditable: false }); }}
        >
          Cancel
        </div>
      </div>
    );
  }

  editQuestion() {
    return (
      <div
        role="presentation"
        className="Button Primary-Button"
        style={{
          ...buttonStyle,
        }}
        onClick={() => { this.setState({ isEditable: true }); }}
      >
        EDIT
      </div>
    );
  }

  handleChangeQuestion(index) {
    this.props.updateQuestionIndex(index);
    this.updateResults(index);
  }

  updateResults(index) {
    const {
      questionsLocked, currentMeetingQuizQuestions, currentQuizQuestionIndex,
    } = this.props;
    if (index === 0
      || [...questionsLocked].includes(
        currentMeetingQuizQuestions[currentQuizQuestionIndex + index].id,
      )) {
      if (window.ZoomMtg) {
        window.ZoomMtg.getAttendeeslist({
          success(res) {
            this.props.updateAttendeesCount(res && res.result
              && res.result.attendeesList && res.result.attendeesList.length);
          },
        });
      }
      this.props.updateLiveResults(currentMeetingQuizQuestions
        && currentMeetingQuizQuestions[currentQuizQuestionIndex + index].id,
      currentMeetingQuizQuestions
        && currentMeetingQuizQuestions[currentQuizQuestionIndex + index].quiz_id);
    }
  }

  migrateQuestions() {
    const {
      prev, next,
    } = this.props;
    return (
      <div style={{ display: 'flex' }}>
        <div
          role="presentation"
          style={{
            justifyContent: 'center',
            width: '25%',
            cursor: prev && 'pointer',
          }}
          onClick={prev ? () => this.handleChangeQuestion(-1) : () => {}}
        >
          {prev && (
          <img
            src={prevQuestionPaper}
            alt="Previous Question"
          />
          )}
        </div>
        <div style={{
          display: 'flex', justifyContent: 'center', width: '50%',
        }}
        >
          {this.showQuestion()}
        </div>
        <div
          role="presentation"
          style={{
            display: 'flex',
            justifyContent: 'flex-end',
            width: '25%',
            cursor: next && 'pointer',
          }}
          onClick={next ? () => this.handleChangeQuestion(1) : () => {}}
        >
          {next && (
          <img
            src={nextQuestion}
            alt="Next Question"
          />
          )}
        </div>
      </div>
    );
  }

  focus() {
    if (this.refs.textAreaLiveQuizQuestionText) {
      this.refs.textAreaLiveQuizQuestionText.focus();
    }
  }

  renderOptionDisplay(index) {
    const { question } = this.props;
    const isCorrect = index === parseInt(question.answer, 10);
    const type = isCorrect ? 'CORRECT' : 'UNANSWERED';
    const image = question[`image_option_${index}`] && question[`image_option_${index}`].url;
    if (image) {
      return (
        <div style={{
          display: 'flex', background: isCorrect ? '#81FA135C' : '#FFFFFF', marginBottom: 20, padding: '10px 5px',
        }}
        >
          <div style={{ marginRight: 5, color: isCorrect ? '#FFFFFF' : '#454545' }}>
            {index}
            )
          </div>
          <img
            src={image}
            alt=""
            height="150px"
            width="250px"
          />
        </div>
      );
    }
    return (
      <div style={{
        marginBottom: 20,
        border: `1px solid ${BORDER[type]}`,
        padding: '5px 10px',
        fontWeight: type === 'CORRECT' ? 'bold' : 400,
        fontFamily: 'Nunito',
        overflowWrap: 'anywhere',
        background: BACKGROUND[type],
        color: '#454545',
        display: 'flex',
        alignItems: 'center',
      }}
      >
        {this.renderMathjax(`${index})   ${question[`option_${index}`]}`)}
      </div>
    );
  }

  renderMathjax(text) {
    return (
      <MathJax.Context
        input={asciiForMathJax()}
        onError={() => {
          if (MathJax.Hub) {
            MathJax.Hub.Queue(
              MathJax.Hub.Typeset(),
            );
          }
        }}
        script={MATHJAX_SCRIPT}
        options={{
          asciimath2jax: {
            useMathMLspacing: true,
            delimiters: [['$$', '$$']],
            preview: 'none',
          },
        }}
      >
        <MathJax.Text text={text} />
      </MathJax.Context>
    );
  }

  renderQuestionText() {
    const { question } = this.props;
    const { isEditable, textChanges } = this.state;
    if (isEditable) {
      return (
        <textarea
          ref="textAreaLiveQuizQuestionText"
          autoFocus
          type="text"
          value={textChanges.question_text}
          style={{
            fontWeight: '400', display: 'flex', fontSize: '14px', width: '100%', overflow: 'auto', resize: 'none', color: '#454545',
          }}
          onClick={this.focus}
          onChange={(event) => this.handleChange('question_text', event.target.value)}
        />
      );
    }
    return (
      <div style={{
        marginBottom: 20,
        padding: 10,
        overflowWrap: 'anywhere',
        paddingLeft: 0,
      }}
      >
        {this.renderMathjax(question.question_text)}
      </div>
    );
  }

  renderSupportingPicture() {
    const { question } = this.props;
    const image = question.supporting_picture && question.supporting_picture.url;
    if (image) {
      return (
        <img
          src={image}
          alt=""
          style={{ marginBottom: 30, background: '#FFFFFF' }}
          height="150px"
          width="250px"
        />
      );
    }
    return <div />;
  }

  render() {
    const optionArray = [1, 2, 3, 4];
    const { isEditable } = this.state;
    return (
      <>
        <div
          className="frame"
          style={{
            height: 380, overflow: 'auto', padding: 10,
          }}
        >
          {this.renderQuestionText()}
          {this.renderSupportingPicture()}
          {optionArray.map((value) => {
            return this.renderOptionDisplay(value);
          })}
        </div>
        {false && (isEditable ? this.updateQuestionView()
          : (
            <div style={{
              display: 'flex', justifyContent: 'center', marginTop: 30, marginBottom: 15,
            }}
            >
              {this.showQuestion()}
            </div>
          ))}
        <div style={{ marginTop: 20 }}>
          {!isEditable && this.migrateQuestions()}
        </div>
      </>
    );
  }
}

IntegratedQuizQuestionView.propTypes = {
  question: PropTypes.object,
  isQuestionShown: PropTypes.bool,
  isQuestionLocked: PropTypes.bool,
  prev: PropTypes.bool,
  isCompleted: PropTypes.bool,
  next: PropTypes.bool,
  currentMeetingQuizQuestions: PropTypes.object,
  questionsLocked: PropTypes.array,
  currentQuizQuestionIndex: PropTypes.number,
};

IntegratedQuizQuestionView.defaultProps = {
  question: {},
  isQuestionShown: false,
  isQuestionLocked: false,
  isCompleted: false,
  prev: false,
  next: false,
  currentMeetingQuizQuestions: {},
  questionsLocked: [],
  currentQuizQuestionIndex: 0,
};

const mapStateToProps = ({ virtualMeeting }) => ({
  currentQuizQuestionIndex: virtualMeeting.currentQuizQuestionIndex,
  currentMeetingQuizQuestions: virtualMeeting.currentMeetingQuizQuestions,
  questionsLocked: virtualMeeting.questionsLocked,
});

export default connect(mapStateToProps, {
  updateQuestionIndex,
  updateShowStatus,
  updateQuizState,
  showToast,
  updateLiveResults,
  updateAttendeesCount,
})(IntegratedQuizQuestionView);
