import React, { PureComponent } from 'react';
import closeIcon from '../../static/images/common/close.svg';
import arrowLeft from '../../static/images/common/arrow-left.svg';

class MobileHeader extends PureComponent {
  render() {
    const {
      name, children, showBackButton, showCloseButton, backgroundColor, color, headerContext,
    } = this.props;
    let maxHeaderWidth = window.innerWidth - 100;
    if (headerContext || children) {
      maxHeaderWidth -= 40;
    }
    return (
      <div
        className="hide-in-print"
        style={{
          zIndex: 10, display: 'flex', justifyContent: 'space-between', padding: '5px 20px 5px 20px', alignItems: 'center', backgroundColor: backgroundColor || 'white', height: 60, top: 0, width: '100%', position: 'fixed', borderBottom: !backgroundColor && '1px solid #ddd',
        }}
      >
        <div style={{ display: 'flex' }}>
          {(showCloseButton || showBackButton) && <img role="presentation" alt="Icon" src={showCloseButton ? closeIcon : arrowLeft} width="22px" style={{ marginRight: 5, padding: 5 }} onClick={() => this.props.onImageClick()} />}
          <div style={{
            color, fontWeight: 'bold', fontSize: 18, display: 'flex',
          }}
          >
            <div style={{
              overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap', maxWidth: maxHeaderWidth, marginRight: 5,
            }}
            >
              {name}
            </div>
            {headerContext}
          </div>
        </div>
        <div style={{ display: 'flex' }}>
          {children && children}
        </div>
      </div>
    );
  }
}

export default MobileHeader;
