import { SEND_NOTIFICATIONS, GET_NOTIFICATIONS } from '../actions';

const initialState = {
  notifications: [],
  classroomListNotification: [],
};

export default function (state = initialState, action) {
  switch (action.type) {
    case SEND_NOTIFICATIONS: {
      return {
        ...state,
        notifications: action.notifications,
        classroomListNotification: action.classroomListNotification,
      };
    }
    case GET_NOTIFICATIONS: {
      return {
        ...state,
        notifications: action.notifications,
        classroomListNotification: action.classroomListNotification,
      };
    }
    default:
      return state;
  }
}
