import React, { Component } from "react";
import { connect } from "react-redux";
import Layout from "../layout";
import {
  getInstituteDetails,
  updateInstituteDetails,
  updateAdminDetails,
  addZoomAccount,
} from "../../actions";
import Constants from "../../shared/constants";
import Menubar from "../menubar";
import AccessDenied from "../accessDenied";
import "../../styles/instituteOnBoarding.css";
import { HIDDEN_SECRET } from "../../constants";
import WaterMarkUploader from "../modal/waterMarkUploaderModal";
import previewImage from "../../static/images/imageIcon.svg";
import { validateCommaSeperatedEmails } from "../../helpers/util";
import closeMark from "../../static/images/close-mark.svg";
import moment from "moment-timezone";

const alertStyle = {
  color: "red",
  marginBottom: 10,
};
class InstituteOnBoarding extends Component {
  constructor(props) {
    super(props);
    this.state = {
      hoverMenuIndex: false,
      selectedOption: 0,
      logoUploaded: false,
      logoFile: "",
      insttituteName: "",
      insttitutePhone: "",
      instituteEmail: "",
      insttituteAddress: "",
      instituteMailingList: "",
      attendanceMailingList: "",
      financialsMailingList: "",
      waterMarkStatus: "text",
      watermarkText: "",
      watermarkImage: "",
      watermarkImageUploaded: false,
      adminFirstName: "",
      adminLastName: "",
      adminPhone: "",
      adminEmail: "",
      academicYearStartDate: "",
      instituteEdit: false,
      adminEdit: false,
      zoomEdit: false,
      showModal: false,
      validAdminPhone: false,
      showAdminAlert: false,
      validInstitutePhone: false,
      validReportMailingList: false,
      validAttendanceMailingList: false,
      validFinancialsMailingList: false,
      limitedMailingList: false,
      showInstituteAlert: false,
      zoomApiKey: "",
      zoomApiSecret: "",
      zoomSdkKey: "",
      zoomSdkSecret: "",
      isLiveClassThroughZoom: false,
    };
    this.renderContent = this.renderContent.bind(this);
    this.renderInstituteInfo = this.renderInstituteInfo.bind(this);
    this.renderSubInfo = this.renderSubInfo.bind(this);
    this.updateFieldValueChanges = this.updateFieldValueChanges.bind(this);
    this.handleCloseModal = this.handleCloseModal.bind(this);
    this.uploadLogo = this.uploadLogo.bind(this);
  }

  componentDidMount() {
    const query = new URLSearchParams(window.location.search);
    this.props.getInstituteDetails(query.get("institute_id")).then(() => {
      const {
        instituteLogo,
        instituteName,
        phone,
        instituteEmail,
        address,
        watermarkImage,
        watermarkText,
        adminFirstName,
        adminLastName,
        adminPhone,
        adminEmail,
        academicYearStartDate,
        isZoomIntegrated,
        isMeetThroughZoom,
        mailingList,
        attendanceMailingList,
        financialsMailingList,
      } = this.props;
      this.setState({
        hoverMenuIndex: false,
        selectedOption: 0,
        logoUploaded: false,
        logoFile: instituteLogo,
        insttituteName: instituteName,
        insttitutePhone: phone,
        instituteEmail,
        instituteMailingList: mailingList,
        attendanceMailingList,
        financialsMailingList,
        insttituteAddress: address,
        waterMarkStatus: "text",
        watermarkText,
        watermarkImage,
        watermarkImageUploaded: false,
        adminFirstName,
        adminLastName,
        adminPhone,
        adminEmail,
        academicYearStartDate,
        instituteEdit: false,
        adminEdit: false,
        zoomEdit: false,
        showModal: false,
        validAdminPhone: adminPhone && adminPhone.length === 10,
        showAdminAlert: false,
        validInstitutePhone: phone && phone.length === 10,
        validReportMailingList: true,
        validAttendanceMailingList: true,
        validFinancialsMailingList: true,
        limitedMailingList: true,
        showInstituteAlert: false,
        zoomApiKey: isZoomIntegrated ? HIDDEN_SECRET : "",
        zoomApiSecret: isZoomIntegrated ? HIDDEN_SECRET : "",
        zoomSdkKey: isZoomIntegrated ? HIDDEN_SECRET : "",
        zoomSdkSecret: isZoomIntegrated ? HIDDEN_SECRET : "",
        isLiveClassThroughZoom: isMeetThroughZoom || false,
      });
    });
  }

  getLogo() {
    document.getElementById("uploadFile").click();
  }

  handleCloseModal() {
    this.setState({ showModal: false });
  }

  handleFormSubmit(e) {
    e.preventDefault();
  }

  handleLiveClassToggle() {
    const { isLiveClassThroughZoom } = this.state;
    this.setState({ isLiveClassThroughZoom: !isLiveClassThroughZoom });
  }

  updateFieldValueChanges(type, value) {
    if (type === "adminPhone" || type === "insttitutePhone") {
      value = value.replace(" ", "");
      value =
        value && value.match(/^[0-9]*$/g)
          ? value.match(/^[0-9]*$/g).join("")
          : "";
      type === "adminPhone"
        ? this.setState({ validAdminPhone: value.length === 10 })
        : this.setState({ validInstitutePhone: value.length === 10 });
    }
    if (value && type === "instituteMailingList") {
      this.setState({
        validReportMailingList: validateCommaSeperatedEmails(value),
        limitedMailingList: value.split(",").length <= 5,
      });
    }
    if (value && type === "attendanceMailingList") {
      this.setState({
        validAttendanceMailingList: validateCommaSeperatedEmails(value),
        limitedMailingList: value.split(",").length <= 5,
      });
    }
    if (value && type === "financialsMailingList") {
      this.setState({
        validFinancialsMailingList: validateCommaSeperatedEmails(value),
        limitedMailingList: value.split(",").length <= 5,
      });
    }
    this.setState({ [type]: value });
  }

  uploadLogo(event) {
    this.setState({ logoUploaded: true, logoFile: event.target.files[0] });
  }

  incorrectPhoneNumberAlert() {
    return <div style={alertStyle}>The phone number must be length of 10*</div>;
  }

  incorrectMailAlert() {
    return <div style={alertStyle}>Invalid mail id(s)*</div>;
  }

  limitExceededAlert() {
    return <div style={alertStyle}>Maximum of 5 emails can be given*</div>;
  }

  renderInput(type, inputType, isRequired) {
    return (
      <input
        type={inputType}
        value={this.state[type]}
        style={{
          border: "none",
          borderBottom: "1px solid #E4E7EE",
          width: "90%",
          outline: "none",
          margin: "5px 0px 0px 0px",
          fontSize: 16,
        }}
        onChange={(event) =>
          this.updateFieldValueChanges(type, event.target.value)
        }
        required={isRequired}
      />
    );
  }

  renderSubInfo(
    label,
    type,
    isRequired,
    category,
    editable = true,
    inputType = "text"
  ) {
    const { instituteEdit, adminEdit, zoomEdit } = this.state;
    const showInput =
      (category === "Admin" && adminEdit) ||
      (category === "institute" && instituteEdit) ||
      (category === "zoom" && zoomEdit);
    const typeValue = this.state[type]
      ? type === "academicYearStartDate"
        ? moment(this.state[type]).format("DD-MM-YYYY")
        : this.state[type]
      : "-";
    return (
      <div style={{ marginBottom: 30 }}>
        <div className="heading-3">{label}</div>
        {showInput && editable ? (
          ![
            "insttituteAddress",
            "instituteMailingList",
            "attendanceMailingList",
            "financialsMailingList",
          ].includes(type) ? (
            this.renderInput(type, inputType, isRequired)
          ) : (
            <textarea
              value={this.state[type]}
              style={{
                border: "none",
                borderBottom: "1px solid #E4E7EE",
                width: "90%",
                outline: "none",
                marginTop: 5,
              }}
              onChange={(event) =>
                this.updateFieldValueChanges(type, event.target.value)
              }
            />
          )
        ) : (
          <div
            className="content-text"
            style={{ marginTop: 5, width: "100%", wordWrap: "break-word" }}
          >
            {typeValue || "-"}
          </div>
        )}
      </div>
    );
  }

  renderAdminInfo() {
    const {
      adminFirstName,
      adminLastName,
      adminPhone,
      adminEmail,
      validAdminPhone,
      showAdminAlert,
      adminEdit,
      academicYearStartDate,
    } = this.state;
    return (
      <div
        style={{
          width: "100%",
          backgroundColor: "#FFFFFF",
          padding: "5%",
          marginTop: 20,
        }}
      >
        <form onSubmit={this.handleFormSubmit} style={{ display: "flex" }}>
          <div
            style={{ width: "50%", display: "flex", justifyContent: "center" }}
          >
            <div style={{ width: "90%" }}>
              {this.renderSubInfo(
                "First Name",
                "adminFirstName",
                true,
                "Admin"
              )}
              {this.renderSubInfo(
                "Email",
                "adminEmail",
                true,
                "Admin",
                false,
                "email"
              )}
            </div>
          </div>
          <div
            style={{ width: "50%", display: "flex", flexDirection: "column" }}
          >
            <div style={{ width: "90%" }}>
              {this.renderSubInfo("Last Name", "adminLastName", false, "Admin")}
              {this.renderSubInfo("Phone Number", "adminPhone", true, "Admin")}
              {!validAdminPhone &&
                showAdminAlert &&
                this.incorrectPhoneNumberAlert()}
            </div>
            {adminEdit && (
              <button
                type="submit"
                style={{
                  height: 30,
                  marginTop: 40,
                  width: "90%",
                  marginBottom: 15,
                  backgroundColor: "#40A7FF",
                  color: "#FFFFFF",
                  borderRadius: 4,
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  lineHeight: 0,
                  fontSize: 14,
                }}
                onClick={
                  validAdminPhone
                    ? () => {
                        this.props.updateAdminDetails(
                          adminFirstName,
                          adminLastName,
                          adminPhone,
                          adminEmail,
                          academicYearStartDate
                        );
                        this.setState({ adminEdit: false });
                      }
                    : () => this.setState({ showAdminAlert: true })
                }
              >
                Update
              </button>
            )}
          </div>
        </form>
      </div>
    );
  }

  renderInstituteInfo() {
    const {
      instituteEdit,
      waterMarkStatus,
      watermarkText,
      validInstitutePhone,
      validReportMailingList,
      limitedMailingList,
      showModal,
      showInstituteAlert,
      insttituteName,
      logoFile,
      insttitutePhone,
      insttituteAddress,
      instituteMailingList,
      watermarkImage,
      instituteEmail,
      isLiveClassThroughZoom,
      academicYearStartDate,
      attendanceMailingList,
      financialsMailingList,
      validAttendanceMailingList,
      validFinancialsMailingList,
    } = this.state;
    const { instituteLogo } = this.props;
    const logoUrl = instituteLogo && instituteLogo.url ? instituteLogo.url : "";
    const watermarkImageUrl =
      this.props.watermarkImage && this.props.watermarkImage.url
        ? this.props.watermarkImage.url
        : "";
    const isWaterMarkText = waterMarkStatus === "text";
    return (
      <div
        style={{
          width: "100%",
          backgroundColor: "#FFFFFF",
          padding: "5% 5% 1% 5%",
          marginTop: 20,
        }}
      >
        <form onSubmit={this.handleFormSubmit} style={{ display: "flex" }}>
          <div
            style={{ width: "50%", display: "flex", justifyContent: "center" }}
          >
            <div style={{ width: "90%" }}>
              <div className="heading-2">Logo</div>
              <div style={{ height: 150, width: "100%" }}>
                <div style={{ height: "0px", overflow: "hidden" }}>
                  <input
                    id="uploadFile"
                    type="file"
                    name="pic"
                    accept="image/*"
                    onChange={(event) => {
                      if (event.target.files[0].size / 2 ** 20 > 2) {
                        alert(
                          "Image Size is more than 2 MB. Try to compress it and upload again."
                        );
                      } else {
                        this.setState({
                          logoUploaded: true,
                          logoFile: event.target.files[0],
                        });
                      }
                    }}
                  />
                </div>
                <div style={{ cursor: "pointer", height: "100%" }}>
                  <div
                    style={{
                      borderRadius: "4px",
                      border: "solid 1px #cdcdce",
                      height: "100%",
                      display: "flex",
                      justifyContent: "center",
                      padding: 5,
                      position: "relative",
                    }}
                  >
                    {this.state.logoUploaded ? (
                      <img
                        alt=""
                        src={URL.createObjectURL(this.state.logoFile)}
                        style={{ maxWidth: "60%", width: "fit-content" }}
                        height="fit-content"
                      />
                    ) : (
                      <img
                        src={logoUrl || previewImage}
                        width="50px"
                        height="auto"
                        style={{ margin: 50 }}
                      />
                    )}
                    {instituteEdit && (
                      <div
                        role="presentation"
                        style={{
                          height: 35,
                          backgroundColor: "#E4E7EE",
                          width: "100%",
                          position: "absolute",
                          bottom: 0,
                          left: 0,
                          color: "#40A7FF",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                        onClick={this.getLogo}
                      >
                        Upload
                      </div>
                    )}
                    {instituteEdit && (
                      <div
                        role="presentation"
                        style={{
                          position: "absolute",
                          top: 5,
                          right: 10,
                          cursor: instituteEdit ? "pointer" : "default",
                        }}
                        onClick={
                          instituteEdit
                            ? (event) => {
                                event.stopPropagation();
                                event.preventDefault();
                                document.getElementById("uploadFile").value =
                                  null;
                                this.setState({
                                  logoFile: null,
                                  logoUploaded: false,
                                });
                              }
                            : () => {}
                        }
                      >
                        <img src={closeMark} alt="x-mark" />
                      </div>
                    )}
                  </div>
                </div>
              </div>
              <div
                style={{
                  marginTop: 20,
                  display: "flex",
                  justifyContent: "space-between",
                  width: "100%",
                }}
              >
                <div className="heading-2">Watermark </div>
                <WaterMarkUploader
                  showModal={showModal}
                  handleCloseModal={() => this.setState({ showModal: false })}
                  updateFieldValueChanges={this.updateFieldValueChanges}
                />
                <div style={{ display: "flex", height: 25, cursor: "pointer" }}>
                  <div
                    role="presentation"
                    style={{
                      borderRadius: "20px 0px 0px 20px",
                      display: "flex",
                      justifyContent: "center",
                      border: isWaterMarkText
                        ? "1px solid #1794FF"
                        : "1px solid #E4E7EE",
                      backgroundColor: isWaterMarkText ? "#40A7FF" : "#FFFFFF",
                      color: isWaterMarkText ? "#FFFFFF" : "",
                      width: 65,
                    }}
                    onClick={() => this.setState({ waterMarkStatus: "text" })}
                  >
                    Text
                  </div>
                  <div
                    role="presentation"
                    style={{
                      borderRadius: "0px 20px 20px 0px",
                      display: "flex",
                      justifyContent: "center",
                      border: !isWaterMarkText
                        ? "1px solid #1794FF"
                        : "1px solid #E4E7EE",
                      backgroundColor: !isWaterMarkText ? "#40A7FF" : "#FFFFFF",
                      color: !isWaterMarkText ? "#FFFFFF" : "",
                      width: 65,
                    }}
                    onClick={() => this.setState({ waterMarkStatus: "image" })}
                  >
                    Image
                  </div>
                </div>
              </div>
              {waterMarkStatus === "text" && !instituteEdit && (
                <div style={{ cursor: "pointer", marginTop: 30 }}>
                  {watermarkText || "-"}
                </div>
              )}
              {instituteEdit && waterMarkStatus === "text" && (
                <div>
                  <div style={{ display: "flex" }}>
                    {this.renderInput("watermarkText", "text", false)}
                  </div>
                </div>
              )}
              {waterMarkStatus !== "text" ? (
                <div
                  role="presentation"
                  style={{
                    padding: 5,
                    borderRadius: "4px",
                    border: "solid 1px #cdcdce",
                    width: 120,
                    height: 120,
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    position: "relative",
                  }}
                  onClick={() => this.setState({ showModal: !!instituteEdit })}
                >
                  {instituteEdit && (
                    <div
                      role="presentation"
                      style={{
                        position: "absolute",
                        top: 5,
                        right: 10,
                        cursor: instituteEdit ? "pointer" : "default",
                      }}
                      onClick={
                        instituteEdit
                          ? (event) => {
                              event.stopPropagation();
                              event.preventDefault();
                              this.setState({
                                watermarkImage: null,
                                watermarkImageUploaded: false,
                              });
                            }
                          : () => {}
                      }
                    >
                      <img src={closeMark} alt="x-mark" />
                    </div>
                  )}
                  {this.state.watermarkImageUploaded ? (
                    <img
                      src={URL.createObjectURL(this.state.watermarkImage)}
                      width="100px"
                      height="100px"
                    />
                  ) : (
                    <img
                      src={watermarkImageUrl || "/static/images/imageIcon.svg"}
                      width="50px"
                      height="auto"
                      style={{ margin: 50 }}
                    />
                  )}
                </div>
              ) : (
                ""
              )}
              <div style={{ marginTop: 30 }}>
                {this.renderSubInfo(
                  "Institute Name",
                  "insttituteName",
                  true,
                  "institute"
                )}
              </div>
            </div>
          </div>
          <div
            style={{ width: "50%", display: "flex", justifyContent: "center" }}
          >
            <div style={{ width: "90%" }}>
              {this.renderSubInfo(
                "Phone Number",
                "insttitutePhone",
                true,
                "institute"
              )}
              {!validInstitutePhone &&
                showInstituteAlert &&
                this.incorrectPhoneNumberAlert()}
              {this.renderSubInfo(
                "Institute Email",
                "instituteEmail",
                true,
                "institute",
                "email"
              )}
              {this.renderSubInfo(
                "Address",
                "insttituteAddress",
                true,
                "institute"
              )}
              {this.renderSubInfo(
                "Mailing List",
                "instituteMailingList",
                true,
                "institute"
              )}
              {this.renderSubInfo(
                "Attendance Info Mailing List",
                "attendanceMailingList",
                true,
                "institute"
              )}
              {this.renderSubInfo(
                "Financials Mailing List",
                "financialsMailingList",
                true,
                "institute"
              )}
              {this.renderSubInfo(
                "Academic Start Date",
                "academicYearStartDate",
                true,
                "institute",
                true,
                "date"
              )}
              {!validReportMailingList &&
                showInstituteAlert &&
                this.incorrectMailAlert()}
              {!validAttendanceMailingList &&
                showInstituteAlert &&
                this.incorrectMailAlert()}
              {!validFinancialsMailingList &&
                showInstituteAlert &&
                this.incorrectMailAlert()}
              {!limitedMailingList &&
                showInstituteAlert &&
                this.limitExceededAlert()}
              {instituteEdit && (
                <button
                  type="submit"
                  style={{
                    height: 30,
                    marginTop: 40,
                    width: "90%",
                    marginBottom: 15,
                    backgroundColor: "#40A7FF",
                    color: "#FFFFFF",
                    borderRadius: 4,
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    lineHeight: 0,
                    fontSize: 14,
                  }}
                  onClick={
                    validInstitutePhone &&
                    limitedMailingList &&
                    validReportMailingList &&
                    validAttendanceMailingList &&
                    validFinancialsMailingList
                      ? () => {
                          const query = new URLSearchParams(
                            window.location.search
                          );
                          const instituteId = query.get("institute_id");
                          this.props.updateInstituteDetails(
                            instituteId,
                            insttituteName,
                            logoFile,
                            logoFile !== this.props.instituteLogo,
                            insttitutePhone,
                            insttituteAddress,
                            watermarkText,
                            watermarkImage,
                            watermarkImage !== this.props.watermarkImage,
                            instituteEmail,
                            instituteMailingList,
                            attendanceMailingList,
                            financialsMailingList,
                            isLiveClassThroughZoom,
                            academicYearStartDate
                          );
                          this.setState({ instituteEdit: false });
                        }
                      : () => this.setState({ showInstituteAlert: true })
                  }
                >
                  Update
                </button>
              )}
            </div>
          </div>
        </form>
      </div>
    );
  }

  renderZoomInfo() {
    const { zoomEdit, zoomApiSecret, zoomApiKey, zoomSdkKey, zoomSdkSecret } =
      this.state;
    return (
      <>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            marginTop: 30,
          }}
        >
          <div className="heading-1" style={{ color: "#11426C" }}>
            Zoom Info
          </div>
          <div
            role="presentation"
            className="edit-button"
            onClick={() => this.setState({ zoomEdit: !zoomEdit })}
          >
            Edit
          </div>
        </div>
        <div
          style={{
            width: "100%",
            backgroundColor: "#FFFFFF",
            padding: "5%",
            marginTop: 20,
          }}
        >
          <div>
            <form onSubmit={this.handleFormSubmit} style={{ display: "flex" }}>
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <div
                  style={{
                    width: "50%",
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  <div style={{ width: "90%" }}>
                    {this.renderSubInfo(
                      "Zoom Api Key",
                      "zoomApiKey",
                      true,
                      "zoom"
                    )}
                    {this.renderSubInfo(
                      "Zoom Api Secret",
                      "zoomApiSecret",
                      true,
                      "zoom",
                      true
                    )}
                  </div>
                </div>
                <div
                  style={{
                    width: "50%",
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <div style={{ width: "90%" }}>
                    {this.renderSubInfo(
                      "Zoom SDK Key",
                      "zoomSdkKey",
                      false,
                      "zoom"
                    )}
                    {this.renderSubInfo(
                      "Zoom SDK Secret",
                      "zoomSdkSecret",
                      true,
                      "zoom"
                    )}
                  </div>
                  {zoomEdit && (
                    <button
                      type="submit"
                      style={{
                        height: 30,
                        marginTop: 40,
                        width: "90%",
                        marginBottom: 15,
                        backgroundColor: "#40A7FF",
                        color: "#FFFFFF",
                        borderRadius: 4,
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        lineHeight: 0,
                        fontSize: 14,
                      }}
                      onClick={() => {
                        this.props.addZoomAccount(
                          zoomApiSecret,
                          zoomApiKey,
                          zoomSdkKey,
                          zoomSdkSecret
                        );
                        this.setState({ zoomEdit: false });
                      }}
                    >
                      Add
                    </button>
                  )}
                </div>
              </div>
            </form>
          </div>
        </div>
      </>
    );
  }

  renderContent() {
    const { instituteEdit, adminEdit } = this.state;
    return (
      <div
        style={{ padding: "45px 80px 75px 50px", backgroundColor: "#F5F8FA" }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <div className="heading-1" style={{ color: "#11426C" }}>
            Institute Info
          </div>
          <div
            className="edit-button"
            onClick={() => this.setState({ instituteEdit: !instituteEdit })}
          >
            Edit
          </div>
        </div>
        <div>{this.renderInstituteInfo()}</div>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            marginTop: 30,
          }}
        >
          <div className="heading-1" style={{ color: "#11426C" }}>
            Admin Info
          </div>
          <div
            className="edit-button"
            onClick={() => this.setState({ adminEdit: !adminEdit })}
          >
            Edit
          </div>
        </div>
        <div>{this.renderAdminInfo()}</div>
        <div>{this.renderZoomInfo()}</div>
      </div>
    );
  }

  render() {
    const { instituteOnBoardingMenu } = Constants();
    const query = new URLSearchParams(window.location.search);
    const instituteId = query.get("institute_id");
    instituteOnBoardingMenu.forEach((page) => {
      page.route += `${
        instituteId !== null ? `?institute_id=${instituteId}` : ""
      }`;
    });
    const { history } = this.props;
    if (this.props.permissionedDenied) {
      return <AccessDenied />;
    }
    return (
      <Layout globalHistory={history} hideHeader>
        <div style={{ width: "100%", display: "flex" }}>
          <div style={{ width: "20%", padding: 0, zIndex: 1 }}>
            <Menubar
              history={history}
              menuList={instituteOnBoardingMenu}
              selectedOption={0}
              redirectToRoot
            />
          </div>
          <div style={{ width: "80%" }}>{this.renderContent()}</div>
        </div>
      </Layout>
    );
  }
}
const mapStateToProps = ({ institute }) => ({
  watermarkText: institute.watermarkText,
  instituteName: institute.instituteName,
  instituteLogo: institute.instituteLogo,
  watermarkImage: institute.watermarkImage,
  adminFirstName: institute.adminFirstName,
  adminLastName: institute.adminLastName,
  adminEmail: institute.adminEmail,
  academicYearStartDate: institute.academicYearStartDate,
  adminPhone: institute.adminPhone,
  phone: institute.phone,
  address: institute.address,
  mailingList: institute.mailingList,
  attendanceMailingList: institute.attendanceMailingList,
  financialsMailingList: institute.financialsMailingList,
  instituteEmail: institute.instituteEmail,
  permissionedDenied: institute.permissionedDenied,
  isZoomIntegrated: institute.isZoomIntegrated,
  isMeetThroughZoom: institute.isMeetThroughZoom,
});

export default connect(mapStateToProps, {
  updateInstituteDetails,
  updateAdminDetails,
  getInstituteDetails,
  addZoomAccount,
})(InstituteOnBoarding);
