import React, { Component } from 'react';
import { connect } from 'react-redux';
import Select from 'react-select';
import Modal from './baseModal';
import Constants from '../../shared/constants';
import closeMark from '../../static/images/close-mark.svg';

class EditSubjectDetailsModal extends Component {
  constructor(props) {
    super(props);
    const { subject, courses, selectedCourses } = this.props;
    this.state = {
      subjectName: subject ? subject.name : '',
      subjectCode: subject ? subject.subject_code : '',
      subjectId: subject ? subject.id : '',
      selectedCourses: selectedCourses ? selectedCourses.filter((course) => { return course.value !== null; }) : [{ label: '', value: '' }],
      selectedDropdownCourses: [],
      studentCampus: subject.campus_name,
      classroomId: subject.classroom_id || null,
      departmentId: subject.department_id || null,
      courseId: subject.course_id || null,
      showEditModal: false,
      newSubject: subject === 'new',
      showAlert: false,
      isElective: false,
      dropdownCourses: courses,
    };
    this.toggleModal = this.toggleModal.bind(this);
    this.renderEdit = this.renderEdit.bind(this);
    this.removeCourse = this.removeCourse.bind(this);
    this.allowNewSubjectCreation = this.allowNewSubjectCreation.bind(this);
    this.allowUpdateCurrentSubjectDetails = this.allowUpdateCurrentSubjectDetails.bind(this);
  }

  componentDidMount() {
    const { dropdownCourses, selectedCourses } = this.state;
    const courses = dropdownCourses;
    selectedCourses.map((course) => {
      const selectedCourse = courses.filter((dropdownCourse) => { return dropdownCourse.value === course.value; })[0];
      const index = courses.indexOf(selectedCourse);
      courses.splice(index, 1);
    });
    this.setState({ dropdownCourses: courses });
  }

  toggleModal() {
    const { showEditModal } = this.state;
    this.setState({
      showEditModal: !showEditModal,
    });
  }

  allowNewSubjectCreation() {
    const { subjectName, subjectCode, selectedCourses } = this.state;
    this.props.newSubjectDetails(subjectName, subjectCode, selectedCourses);
    this.props.handleCloseModal();
  }

  allowUpdateCurrentSubjectDetails() {
    const {
      subjectId, subjectName, subjectCode, selectedCourses,
    } = this.state;
    this.props.updateCurrentSubjectDetail(subjectId, subjectName, subjectCode, selectedCourses);
    this.props.handleCloseModal();
  }

  handleChange(selectedCourses) {
    this.setState({ selectedCourses });
  }

  handleMultiSelect(key, value) {
    this.setState({ [key]: value });
  }

  removeCourse(course, index) {
    const { selectedCourses, dropdownCourses } = this.state;
    const courses = [...selectedCourses];
    if (index !== -1) {
      courses.splice(index, 1);
      const indexOfBracket = course.label.indexOf('(');
      this.setState({
        selectedCourses: courses,
        dropdownCourses: [...dropdownCourses,
          {
            value: course.value,
            label: indexOfBracket > -1 ? course.label.substring(0, indexOfBracket - 1)
              : course.label,
            isElective: false,
          }],
      });
    }
  }

  addCourses() {
    const {
      selectedCourses, selectedDropdownCourses, dropdownCourses, isElective,
    } = this.state;
    this.setState({ selectedCourses: [...selectedCourses, ...selectedDropdownCourses.map(({ value, label }) => { return { value, label: isElective ? `${label} (E)` : label, isElective }; })] }, () => {
      selectedDropdownCourses.map((course) => {
        const selectedCourse = dropdownCourses.filter((dropdownCourse) => {
          return dropdownCourse.value === course.value;
        })[0];
        const index = dropdownCourses.indexOf(selectedCourse);
        dropdownCourses.splice(index, 1);
      });
      this.setState({ dropdownCourses: [...dropdownCourses], selectedDropdownCourses: [] });
    });
  }

  renderSubInfo(label, type, value, input = false, marginBottom = 20, required = true) {
    return (
      <div style={{ marginBottom }}>
        <div className="heading-3">{label}</div>
        { !input && <div className="content-text" style={{ marginTop: 5 }}>{value || '-' }</div>}
        { input
          && (
          <input
            value={this.state[type]}
            style={{
              border: 'none', borderBottom: '1px solid #E4E7EE', width: '90%', outline: 'none',
            }}
            onChange={(event) => this.setState({ [type]: event.target.value })}
            required={required}
          />
          )}
      </div>
    );
  }

  renderDropDown() {
    const {
      selectedCourses, isElective, dropdownCourses, selectedDropdownCourses,
    } = this.state;
    const { isHigherEducationInstitute } = this.props;
    const customStyles = {
      control: (base, state) => {
        return ({
          ...base,
          marginBottom: 5,
          height: 50,
          width: 250,
          cursor: state.isDisabled ? 'not-allowed' : 'pointer',
          textAlign: 'center',
        });
      },
      valueContainer: (base, state) => {
        return ({
          ...base,
          height: 45,
          paddingLeft: 20,
          overflow: 'scroll',
        });
      },
      option: (base, state) => {
        if (state.data.__isNew__) {
          return ({
            ...base,
            color: Constants().color.primary,
            fontWeight: 'bold',
            textAlign: 'center',
            backgroundColor: '#FFFFF',
            cursor: 'pointer',
            fontSize: 14,
          });
        }
        return ({
          ...base,
          border: '1px solid #F8F8F8',
          color: '#343B40',
          backgroundColor: state.isFocused ? '#DEEBFF' : '#FFFFFF',
        });
      },
    };
    return (
      <div style={{
        display: 'flex', flexDirection: 'column', alignItems: 'flex-start', marginBottom: 30,
      }}
      >
        <div className="heading-3">Courses</div>
        <div style={{
          display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: 440,
        }}
        >
          <Select
            isMulti
            value={selectedDropdownCourses}
            options={dropdownCourses}
            className="basic-multi-select"
            classNamePrefix="select"
            styles={customStyles}
            placeholder="None"
            onChange={(selectedCourse) => this.handleMultiSelect('selectedDropdownCourses', selectedCourse)}
          />
          {isHigherEducationInstitute && (
          <div style={{ display: 'flex', margin: 10 }}>
            <input
              checked={isElective}
              onChange={() => this.setState({ isElective: !isElective })}
              style={{ marginRight: 10, height: 15 }}
              type="checkbox"
            />
            <div>
              Elective
            </div>
          </div>
          )}
          <button
            type="button"
            style={{
              marginTop: 10, width: 100, opacity: (selectedDropdownCourses.length > 0) ? 1 : 0.5, backgroundColor: '#40A7FF', color: '#FFFFFF', display: 'flex', justifyContent: 'center', alignItems: 'center', lineHeight: 0, fontSize: 14,
            }}
            onClick={() => (selectedDropdownCourses.length > 0) && this.addCourses()}
          >
            Add
          </button>
        </div>
        <div style={{ margin: '10px 0px' }}>
          {selectedCourses && selectedCourses.map((course, index) => {
            return (
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <img role="presentation" alt="" src={closeMark} width="8" height="8" style={{ marginRight: 20 }} onClick={() => this.removeCourse(course, index)} />
                <>{course.label}</>
              </div>
            );
          })}
        </div>
        <div />
      </div>
    );
  }

  renderCourseSelection() {
    return (
      <div>
        {this.renderDropDown()}
      </div>
    );
  }

  renderEdit() {
    const { subjectName, subjectCode, newSubject } = this.state;
    const { subject } = this.props;

    if (subject) {
      return (
        <div style={{ padding: 50 }}>
          <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: 30 }}>
            <div className="heading-1">Subject Details</div>
            <div onClick={this.props.handleCloseModal} style={{ cursor: 'pointer' }}>
              <img src={closeMark} alt="x-mark" />
            </div>
          </div>
          <div style={{ display: 'flex' }}>
            <div style={{ width: 300, display: 'flex', justifyContent: 'center' }}>
              <div style={{ width: '100%' }}>
                {this.renderSubInfo('Name', 'subjectName', subjectName, true)}
                {this.renderSubInfo('Subject Code', 'subjectCode', subjectCode, true)}
              </div>
            </div>
            <div style={{
              marginLeft: 50, width: 200, display: 'flex', justifyContent: 'center',
            }}
            >
              <div style={{ width: '100%' }}>
                {this.renderCourseSelection()}
                <div style={{
                  display: 'flex', margin: '70px 0px 0px 70px', width: 370, fontWeight: 700, justifyContent: 'space-between',
                }}
                >
                  <div role="presentation" className="custom-secondary-button" style={{ height: 35, width: 180, border: '1px solid #CDCFD6' }} onClick={() => this.props.handleCloseModal()}>Cancel</div>
                  <button
                    type="submit"
                    style={{
                      width: 180, height: 35, marginBottom: 15, backgroundColor: '#40A7FF', color: '#FFFFFF', borderRadius: 4, display: 'flex', justifyContent: 'center', alignItems: 'center', lineHeight: 0, fontSize: 14,
                    }}
                    onClick={newSubject ? () => this.allowNewSubjectCreation() : () => this.allowUpdateCurrentSubjectDetails()}
                  >
                    { newSubject ? 'Create' : 'Update' }
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    }
  }

  render() {
    return (
      <div>
        <Modal showModal={this.props.showModal} handleCloseModal={this.props.handleCloseModal} height="520px" width="880px">
          <div>
            {this.renderEdit()}
          </div>
        </Modal>
      </div>
    );
  }
}
export default connect()(EditSubjectDetailsModal);
