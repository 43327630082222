import Dexie from 'dexie';
import * as Sentry from '@sentry/browser';

import Api from '../helpers/Api';
import db from '../helpers/db';

export const GET_ALL_CLASSROOMS = 'attendance/GET_ALL_CLASSROOMS';
export const GET_CLASSROOM_STUDENT = 'attendance/GET_CLASSROOM_STUDENT';
export const GET_ATTENDANCE_REPORT = 'attendance/GET_ATTENDANCE_REPORT';
export const UPDATE_FILTER_DATA = 'attendance/UPDATE_FILTER_DATA';
export const GET_TODAY_PERIODS = 'attendance/GET_TODAY_PERIODS';

const RECENT_CLASSROOM_LIMIT = 5;

export const getAllClassrooms = () => async (dispatch, getState) => {
  const data = await Api({
    method: 'get',
    url: '/classrooms/get_active_classrooms_of_institute',
  });
  dispatch({
    type: GET_ALL_CLASSROOMS,
    payload: data.campus_data,
  });
  const dbData = data.campus_data.map((campus) => {
    return { name: Object.keys(campus)[0], classrooms: Object.values(campus)[0] };
  });
  db.campuses.bulkPut(dbData).then(() => {
  }).catch(Dexie.BulkError, (e) => {
    Sentry.captureException(e);
  });
};

export const getClassroomStudent = (payload) => async (dispatch, getState) => {
  const data = await Api({
    method: 'get',
    url: `/students/${payload.id}/get_students_of_classroom?period_id=${payload.period_id}`,
  });
  dispatch({
    type: GET_CLASSROOM_STUDENT,
    payload: {
      studentList: data.students,
      currentAbsentStudent: data.absentees,
    },
  });
  return data;
};

export function submitClassAttendance(studentIDS, classroomID, periodId) {
  return () => Api({
    method: 'post',
    url: '/periods',
    data: {
      student_ids: studentIDS,
      classroom_id: classroomID,
      period_id: periodId,
    },
  }).then((response) => {
    return response;
  });
}

export const getOfflineAllClassroom = () => async (dispatch) => {
  const result = await db.campuses.toArray();
  const campusData = result.map((campus) => {
    return { [campus.name]: campus.classrooms };
  });
  dispatch({
    type: GET_ALL_CLASSROOMS,
    payload: campusData,
  });
};

export const getOfflineClassroomData = (classID) => async (dispatch) => {
  const classroom = await db.classrooms.where({ id: classID }).first();
  dispatch({
    type: GET_CLASSROOM_STUDENT,
    payload: {
      studentList: classroom ? classroom.students : [],
    },
  });
};

export const postOfflineAttendance = () => async () => {
  const collections = await db.offlineAttendance.toArray();
  const result = await Api({
    method: 'post',
    url: '/periods/bulk_create',
    data: {
      attendance: JSON.stringify(collections),
    },
  });
  if (result.success) {
    const ids = collections.map((classroom) => classroom.id);
    await db.offlineAttendance.bulkDelete(ids);
  }
};

export const getAttendanceReport = () => async (dispatch, getState) => {
  const { filterData } = getState().attendance;
  const {
    campusID, classroomID, startDate, endDate,
  } = filterData;
  const data = await Api({
    method: 'get',
    url: `/periods/get_attendance_report?campus_id=${campusID}&classroom_id=${classroomID}&start_date=${startDate}&end_date=${endDate}`,
  });
  dispatch({
    type: GET_ATTENDANCE_REPORT,
    payload: {
      campuses: data.campuses_data,
      classrooms: data.classrooms_data,
      report: { attendanceData: data.attendance_data, studentData: data.student_data },
      filterData: {
        startDate: data.filter_data.start_date,
        endDate: data.filter_data.end_date,
        classroomID: data.filter_data.classroom_id,
        campusID: data.filter_data.campus_id,
      },
    },
  });
  return data;
};

export const updateAttendanceFilterData = (item, itemType) => async (dispatch) => {
  dispatch({
    type: UPDATE_FILTER_DATA,
    payload: {
      item,
      itemType,
    },
  });
};

export const getLastAttendanceSession = () => async (dispatch) => {
  const data = await Api({
    method: 'get',
    url: '/periods/get_attendance_data_for_date',
  });
  dispatch({
    type: GET_TODAY_PERIODS,
    payload: data.today_attendance_data,
  });
  return data;
};

export const addItemToRecentList = (item) => async () => {
  db.transaction('rw', db.recentClassrooms, async () => {
    const date = new Date();
    const classroomCount = await db.recentClassrooms.where({ id: item.id }).count();
    const recordCount = await db.recentClassrooms.count();
    if (recordCount < RECENT_CLASSROOM_LIMIT && classroomCount === 0) {
      db.recentClassrooms.put({
        id: item.id, name: item.name, campus_id: item.campus_id, time: date.getTime(),
      });
    } else if (recordCount === RECENT_CLASSROOM_LIMIT && classroomCount > 0) {
      const collections = await db.recentClassrooms.toArray();
      const lastSavedRecord = collections.reduce((prev, curr) => (prev.time < curr.time ? prev : curr));
      await db.recentClassrooms.delete(lastSavedRecord.id);
      db.recentClassrooms.put({
        id: item.id, name: item.name, campus_id: item.campus_id, time: date.getTime(),
      });
    }
  }).catch((e) => {
    Sentry.captureException(e);
  });
};
