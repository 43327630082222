import React, { PureComponent } from 'react';
import ReactModal from 'react-modal';
import PropTypes from 'prop-types';
import closeUrl from '../../static/images/Close.svg';

class Modal extends PureComponent {
  render() {
    const {
      children, showModal, handleCloseModal, header, height, width,
      minwidth, headerStyle, overrideCloseIcon, isMobile, headerHeight,
      minHeight, style,
    } = this.props;
    const contentStyle = isMobile ? {
      position: 'fixed', top: 'auto', left: 'auto', right: 'auto', height: 'fit-content', width: window.innerWidth, bottom: 0, minHeight: minHeight || 250,
    } : {
      position: 'absolute', top: '50%', left: '50%', height: height || 'auto', width: width || 'auto', right: 'auto', bottom: 'auto', transform: 'translate(-50%, -50%)', overflowY: 'scroll', WebkitOverflowScrolling: 'scroll', minWidth: minwidth || '25%', padding: 10, minHeight: minHeight || height,
    };
    return (
      <ReactModal
        ariaHideApp={false}
        isOpen={showModal}
        contentLabel="onRequestClose Example"
        onRequestClose={handleCloseModal}
        style={{
          overlay: {
            position: 'fixed',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundColor: '#00000080',
            overflowY: 'scroll',
            zIndex: 100,
          },
          content: { ...contentStyle, ...style },
        }}
      >
        {header && (
          <div className="d-flex justify-content-between" style={{ alignItems: 'center', height: headerHeight || '' }}>
            <b style={{
              fontSize: 18, paddingLeft: 0, ...headerStyle, whiteSpace: 'pre-wrap',
            }}
            >
              {header}
            </b>
            {overrideCloseIcon}
            {!overrideCloseIcon && (
            <b
              role="presentation"
              style={{
                height: '10.5px', width: '10.5px', color: '#43464B', float: 'right', cursor: 'pointer', background: `url(${closeUrl})`, marginRight: 10,
              }}
              onClick={() => handleCloseModal()}
            />
            )}
          </div>
        )}
        {children}
      </ReactModal>
    );
  }
}

PropTypes.defaultProps = {
  headerStyle: {},
};

export default Modal;
