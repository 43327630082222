import React, { Component } from 'react';
import { connect } from 'react-redux';
import Cookies from 'universal-cookie';
import { createBrowserHistory } from 'history';
import Modal from './baseModal';
import Constants from '../../shared/constants';
import closeMark from '../../static/images/close-mark.svg';

const history = createBrowserHistory({
  forceRefresh: true,
});

const cookies = new Cookies();

const FILE_UPLOADER_PROGRESS = 'FileUploaderProgressChannel';
class FileUploaderModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      cable: Object.create(null),
      subscription: Object.create(null),
      total: 1,
      currentItem: 0,
      fileName: '',
      errorMessage: '',
    };
    this.renderFileUploaderBody = this.renderFileUploaderBody.bind(this);
    this.handleCloseModal = this.handleCloseModal.bind(this);
  }

  componentDidMount() {
    if (typeof window !== 'undefined') {
      const actionCable = require('actioncable');
      const cable = actionCable.createConsumer(Constants().api.ws_url);
      const { instituteId } = this.props;
      this.setState({ cable });
      const subscription = cable.subscriptions.create({ channel: FILE_UPLOADER_PROGRESS, id: instituteId || cookies.get('user').institute_id }, {
        received: (data) => {
          const { success, route } = this.props;
          this.setState({ currentItem: data.current_item, total: data.total, fileName: data.name ? data.name : '' });
          this.props.handleSuccess(data.success && success);
          if (typeof data.details !== 'undefined') {
            this.handleCloseModal(true, data.details);
          }
          if (!data.success) {
            this.setState({ errorMessage: data.error });
          }
          if ((data.type === 'verify') && (data.total === data.current_item)) {
            this.handleCloseModal(true);
            window.location.replace(route);
          }
        },
      });
      this.setState({ subscription });
    }
  }

  componentWillUnmount() {
    if (typeof window !== 'undefined') {
      const { cable, subscription } = this.state;
      if (cable) {
        cable.subscriptions.remove(subscription);
      }
    }
  }

  handleCloseModal(status = false, data = {}) {
    const { success } = this.props;
    this.setState({
      fileName: '', total: 1, currentItem: 0, errorMessage: '',
    }, () => {
      this.props.handleCloseModal(success && status, data);
    });
  }

  renderFileUploaderBody() {
    const {
      total, currentItem, fileName, errorMessage,
    } = this.state;
    const { success } = this.props;
    return (
      <div style={{
        display: 'flex', flexDirection: 'column', width: '90%', marginLeft: '5%',
      }}
      >
        <div style={{
          display: 'flex', justifyContent: 'space-between', marginBottom: 25, marginTop: 25, width: '100%',
        }}
        >
          <div className="heading-1" style={{ fontWeight: 'bold', fontSize: 18 }}>
            Import
            {this.props.type || 'Student'}
            Details
          </div>
          <div role="presentation" onClick={() => this.props.handleCloseModal(success)} style={{ cursor: 'pointer' }}>
            <img src={closeMark} alt="x-mark" />
          </div>
        </div>
        <div style={{
          border: '1px solid #E4E7EE', height: 60, width: '100%', borderRadius: 5, padding: 5,
        }}
        >
          <div style={{ display: 'flex', justifyContent: 'space-between', fontSize: 12 }}>
            <div style={{ marginLeft: 10 }}>{fileName}</div>
            <div style={{ marginRight: 40 }}>
              {currentItem}
                &nbsp;
              of&nbsp;
              {total}
              &nbsp;
              items
            </div>
          </div>
          <div style={{
            display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%',
          }}
          >
            <div style={{
              height: 5, width: '100%', backgroundColor: '#EFEFEF', marginLeft: 10, marginRight: 10, borderRadius: 2,
            }}
            >
              <div style={{
                width: `${(((currentItem || 0) / (total || 1)) * 100) || 0}%`, height: '100%', backgroundColor: success ? '#20D000' : 'red', borderRadius: 2,
              }}
              />
            </div>
            <div>
              {parseInt((((currentItem || 0) / (total || 1)) * 100), 10) || 0 }
              %
            </div>
          </div>
          { errorMessage && !success && (
          <div style={{ marginTop: 25 }}>
            {errorMessage.map((message) => {
              return (
                <div style={{ marginTop: 10 }}>{message}</div>
              );
            })}
          </div>
          )}
          { success && total === currentItem && <div style={{ marginTop: 25 }}>Successfully imported</div>}
        </div>
      </div>
    );
  }

  render() {
    return (
      <div>
        <Modal showModal={this.props.showModal} handleCloseModal={() => this.handleCloseModal()} height="300px" width="500px">
          <div>
            {this.renderFileUploaderBody()}
          </div>
        </Modal>
      </div>
    );
  }
}
export default connect()(FileUploaderModal);
