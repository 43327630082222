import Api from '../helpers/Api';
import { showToast } from '.';

export const UPDATE_DASHBOARD_DATA = 'virtualMeeting/UPDATE_DASHBOARD_DATA';
export const GET_MEETING_DETAILS = 'virtualMeeting/GET_MEETING_DETAILS';
export const GET_QUIZ_QUESTION_DATA = 'virtualMeeting/GET_QUIZ_QUESTION_DATA';
export const SET_CURRENT_QUIZ_QUESTION = 'virtualMeeting/SET_CURRENT_QUIZ_QUESTION';
export const UPDATE_SHOW_QUESTION_DATA = 'virtualMeeting/UPDATE_SHOW_QUESTION_DATA';
export const ADD_MEETING_URL = 'virtualMeeting/ADD_MEETING_URL';
export const UPDATE_NEW_QUIZ_TO_LIVE_CLASS = 'virtualMeeting/UPDATE_NEW_QUIZ_TO_LIVE_CLASS';
export const TOGGLE_MEETING_STATUS = 'virtualMeeting/TOGGLE_MEETING_STATUS';
export const UPDATE_ATTENDEES_COUNT = 'virtualMeeting/UPDATE_ATTENDEES_COUNT';
export const UPDATE_LIVE_RESULTS = 'virtualMeeting/UPDATE_LIVE_RESULTS';
export const UPDATE_VIRTUAL_MEETING_FILTERS = 'virtualMeeting/UPDATE_VIRTUAL_MEETING_FILTERS';
export const UPDATE_SUBJECTS_FILTER = 'virtualMeeting/UPDATE_SUBJECTS_FILTER';
export const UPDATE_CLASSROOM_FILTER = 'virtualMeeting/UPDATE_CLASSROOM_FILTER';
export const UPDATE_G_AUTH_STATUS = 'virtualMeeting/UPDATE_G_AUTH_STATUS';
export const GET_FEEDBACK_REPORT = '@virtualMeeting/GET_FEEDBACK_REPORT';
export const LOADING_FEEDBACK_REPORT = '@virtualMeeting/LOADING_FEEDBACK_REPORT';
export const UPDATE_FILTERS = '@virtualMeeting/UPDATE_FILTERS';
export const GET_ATTENDACE_REPORTS = '@virtualMeeting/GET_ATTENDACE_REPORTS';
export const LOADING_ATTENDACE_REPORTS = '@virtualMeeting/LOADING_ATTENDACE_REPORTS';
export const MARK_PRESENT = '@virtualMeeting/MARK_PRESENT';
export const UPDATE_DELETED_VIDEO = '@virtualMeeting/UPDATE_DELETED_VIDEO';
export const MARK_ABSENT = '@virtualMeeting/MARK_ABSENT';
export const GET_TEACHER_ATTENDACE = '@virtualMeeting/GET_TEACHER_ATTENDACE';

export const getReport = (date = '', classroom = '', campus = '', department = '', teacher = '') => async (dispatch) => {
  let dateRange = {};
  if (date) {
    dateRange = { start_date: date.startDate, end_date: date.endDate };
    dateRange = date && JSON.stringify(date);
  }
  const filters = `classroom_id=${classroom}&campus_id=${campus}&department_id=${department}&teacher_id=${teacher}&start_date=${date.startDate}&end_date=${date.endDate}`;
  const response = await Api({
    method: 'get',
    url: `/live_classrooms/get_feedback_report?${filters}`,
  });
  if (response.success) {
    dispatch({
      type: GET_FEEDBACK_REPORT,
      payload: {
        feedbacks: response.feedbacks,
        departments: response.departments,
        campuses: response.campuses,
        teachers: response.teachers,
        filterClassrooms: response.filter_classrooms || [],
        teacherFeedbackRating: response.teacher_feedback_rating,
        feedbackWidgetData: response.feedback_widget_data,
        isHigherEducation: response.is_higher_education,
        isAnonymousFeedback: response.is_anonymous_feedback,
        isTeacher: response.is_teacher,
        selectedDepartment: response.selected_department,
        isHod: response.is_hod,
        departmentData: response.department_data,
        isDean: response.is_dean,
      },
    });
  }
  return response;
};

export function handleDeletedVideo(id) {
  return (dispatch) => Api({
    method: 'post',
    url: '/virtual_meetings/delete_uploaded_video',
    data: { id },
  }).then((response) => {
    dispatch({
      type: UPDATE_DELETED_VIDEO,
      payload: { meetingId: id },
    });
    dispatch(showToast('Deleted Successfully!'));
    return response;
  });
}

export function getSubjectData(id) {
  return (dispatch) => Api({
    method: 'get',
    url: `/virtual_meetings/${id}/get_subject_data`,
  }).then((response) => {
    dispatch({
      type: GET_MEETING_DETAILS,
      payload: {
        videoChapters: response.chapters,
        videoCheckpoints: response.checkpoints,
      },
    });
    return response;
  });
}

export function updateVirtualMeetingConcepts(startTime, endTime, id) {
  return () => Api({
    method: 'post',
    url: '/virtual_meetings/update_virtual_meeting_concepts',
    data: {
      start_at: startTime,
      ends_at: endTime,
      id,
    },
  }).then((response) => {
    return response;
  });
}

export function getVideoRecordings(params = {}) {
  return (dispatch) => Api({
    method: 'post',
    url: '/virtual_meetings/get_video_recordings',
    data: params,
  }).then((response) => {
    dispatch({
      type: GET_MEETING_DETAILS,
      payload: {
        meetings: response.virtual_meetings,
      },
    });
    return response;
  });
}

export function getVirtualMeetingList(daysAgo, classroom = '', campus = '', department = '', semester = '') {
  const date = new Date();
  const filters = `classroom_id=${classroom}&campus_id=${campus}&department_id=${department}&course_id=${semester}`;
  date.setDate(date.getDate() - (daysAgo || 0));
  return (dispatch) => Api({
    method: 'get',
    url: `/virtual_meetings?date=${date}&${filters}`,
  }).then((response) => {
    if (response.success) {
      const payload = {
        virtualMeetings: response.virtual_meetings,
        classrooms: response.classrooms,
        showFilters: response.show_filters,
        testTypes: response.test_types,
        subjects: response.subjects,
        filteredSubjects: response.subjects,
        quizzes: response.quizzes,
        semesters: response.semesters,
        campuses: response.campuses,
        filterClassrooms: response.filter_classrooms,
        departments: response.departments,
        userName: response.user_name,
        isZoomIntegrated: response.is_zoom_integrated,
        isHigherEducation: response.is_higher_education,
        recurrenceTypes: response.recurrence_types,
        showQuizQuestion: false,
        emails: response.emails,
        useZoom: response.live_class_through_zoom,
        teachers: response.teachers_data,
        filteredTeachers: response.teachers_data,
        modules: response.modules,
        lectures: response.lectures,
        remainingRecordingVideoStorageInMb: response.remaining_video_storage_space,
      };
      dispatch({
        type: GET_MEETING_DETAILS,
        payload,
      });
      if (response.is_zoom_integrated) {
        dispatch({
          type: ADD_MEETING_URL,
          virtualMeetings: response.virtual_meetings || [],
        });
      }
    }
    return response;
  });
}

export const getDashboardData = () => async (dispatch, getState) => {
  const response = await Api({
    method: 'get',
    url: '/virtual_meetings/get_institute_virtual_meeting_stats',
  });
  if (response.success) {
    const dashBoardData = {
      classesHosted: {
        name: 'CLASSES HOSTED',
        value: response.classes_hosted,
      },
      avgAttendance: {
        name: 'AVG ATTENDANCE',
        value: response.avg_attendance,
      },
      hoursOfTeaching: {
        name: 'HOURS OF TEACHING',
        value: response.hours_of_teaching,
      },
    };
    dispatch({
      type: UPDATE_DASHBOARD_DATA,
      payload: dashBoardData,
    });
  }
};

export function sendFeedback(phone, message, isClassboardProduct = false) {
  return () => Api({
    method: 'post',
    url: '/feedbacks/add_user_product_feedback',
    data: {
      phone,
      feedback_message: message,
      is_classboard_product: isClassboardProduct,
    },
  }).then((response) => {
    return response;
  });
}

export function getMeetingData(id) {
  return (dispatch) => Api({
    method: 'post',
    url: '/virtual_meetings/get_meeting_data',
    data: { id },
  }).then((response) => {
    if (response.success) {
      dispatch({
        type: GET_MEETING_DETAILS,
        payload: {
          meeting: response.meeting,
        },
      });
    }
    return response;
  });
}

export function createLiveQuiz(id) {
  return (dispatch) => Api({
    method: 'post',
    url: '/virtual_meetings/create_live_quiz',
    data: {
      id,
    },
  }).then((response) => {
    if (response.success) {
      dispatch({
        type: UPDATE_NEW_QUIZ_TO_LIVE_CLASS,
        payload: {
          id,
          quizId: response.quiz_id,
          quiz: response.quiz,
        },
      });
    }
    return response;
  });
}

export function createVirtualMeeting(virtualMeetingData, selectedClassroomIds,
  startTime, startDate, endDate, selectedDaysOfWeek) {
  return (dispatch) => Api({
    method: 'post',
    url: '/virtual_meetings',
    data: {
      id: virtualMeetingData.id,
      start_time: startTime,
      start_date: startDate,
      end_date: endDate,
      weekly_days: JSON.stringify(selectedDaysOfWeek),
      classroom_ids: JSON.stringify(selectedClassroomIds),
      title: virtualMeetingData.topic,
      agenda: virtualMeetingData.description,
      duration: virtualMeetingData.duration,
      subject_id: virtualMeetingData.subject.id,
      track: virtualMeetingData.track ? virtualMeetingData.track.name : '',
      recurrence: virtualMeetingData.selectedRecurrence && virtualMeetingData.selectedRecurrence.id,
      quiz_id: virtualMeetingData.quiz && virtualMeetingData.quiz.id,
      teacher_id: virtualMeetingData.teacher && virtualMeetingData.teacher.id,
      email: virtualMeetingData.email && virtualMeetingData.email.name,
      attendance_based_on_quiz_results: virtualMeetingData.attendanceBasedOnQuiz ? virtualMeetingData.attendanceBasedOnQuiz : false,
    },
  }).then((response) => {
    if (response.success) {
      dispatch(showToast('New Meeting Created!'));
    } else {
      dispatch(showToast(`Couldn't create meeting! Please make sure api key and secret are correct. ${response.message ? response.message : ''}`));
    }
    return response;
  });
}

export function updateQuizState(quizId, status) {
  return (dispatch) => Api({
    method: 'post',
    url: '/quiz/complete_quiz',
    data: { quiz_id: quizId, quiz_status: status },
  }).then((response) => {
    dispatch({
      type: GET_MEETING_DETAILS,
      payload: { isCompleted: true },
    });
    return response;
  });
}


export function updateVirtualMeeting(virtualMeetingData, recordingData,
  isRecording, startTime, startDate, selectedClassroomIds, includeFutureOccurences) {
  let payload = {};
  if (isRecording) {
    payload = {
      id: virtualMeetingData.id,
      recording_url: recordingData && recordingData.recordingUrl ? recordingData.recordingUrl : '',
      recording_file_size_in_bytes: recordingData && recordingData.fileSize ? recordingData.fileSize : '',
      chapter_id: recordingData.chapterId,
      checkpoint_ids: recordingData.checkpointIds && recordingData.checkpointIds.join(','),
      is_recording: isRecording,
    };
  } else {
    payload = {
      id: virtualMeetingData.id,
      start_time: startTime,
      start_date: startDate,
      title: virtualMeetingData.topic,
      agenda: virtualMeetingData.description,
      duration: virtualMeetingData.duration,
      subject_id: virtualMeetingData.subject && virtualMeetingData.subject.id,
      track: virtualMeetingData.track ? virtualMeetingData.track.name : '',
      recurrence: !(virtualMeetingData.quiz && virtualMeetingData.quiz.id) && virtualMeetingData.selectedRecurrence && virtualMeetingData.selectedRecurrence.id,
      quiz_id: virtualMeetingData.quiz && virtualMeetingData.quiz.id,
      is_recording: isRecording,
      should_include_future_occurences: includeFutureOccurences,
      classroom_ids: JSON.stringify(selectedClassroomIds),
      module_no: virtualMeetingData.module_no && virtualMeetingData.module_no.id,
      lecture_no: virtualMeetingData.lecture_no && virtualMeetingData.lecture_no.id,
      attendance_based_on_quiz_results: virtualMeetingData.attendanceBasedOnQuiz ? virtualMeetingData.attendanceBasedOnQuiz : false,
    };
  }
  return (dispatch) => Api({
    method: 'post',
    url: '/virtual_meetings/update_virtual_meeting',
    data: payload,
  }).then((response) => {
    if (response.success) {
      dispatch(showToast('Update successful!'));
    } else {
      dispatch(showToast(`Could not update the meeting ${response.message ? response.message : ''}`));
    }
    return response;
  });
}

export function getMeetingQuizData(id) {
  return (dispatch) => Api({
    method: 'get',
    url: `/virtual_meetings/${id}/get_live_quiz_data`,
  }).then((response) => {
    if (response.success) {
      dispatch({
        type: GET_MEETING_DETAILS,
        payload: {
          quizQuestionData: response.quiz_question_data,
          optionList: response.option_list,
          currentQuizQuestionAttemptedStudentsCount: response.attempted_students_count,
          meeting: response.meeting,
        },
      });
      dispatch({
        type: GET_QUIZ_QUESTION_DATA,
        showQuizQuestionOutside: true,
      });
    }
    return response;
  });
}

export function createMeetingSignature(meetConfig, id) {
  return (dispatch) => Api({
    method: 'post',
    url: '/virtual_meetings/create_meeting_signature',
    data: {
      meeting_number: meetConfig.meetingNumber,
      role: meetConfig.role,
      id,
    },
  }).then((response) => {
    if (response.success) {
      dispatch({
        type: GET_MEETING_DETAILS,
        payload: {
          quizQuestionData: response.quiz_question_data,
        },
      });
      dispatch({
        type: GET_QUIZ_QUESTION_DATA,
        showQuizQuestion: true,
      });
    }
    return response;
  });
}

export function getLiveClasses() {
  return (dispatch) => Api({
    method: 'get',
    url: '/virtual_meetings/get_institute_live_classes',
  }).then((response) => {
    dispatch({
      type: GET_MEETING_DETAILS,
      payload: {
        liveClasses: response.live_classes,
        departments: response.departments,
      },
    });
    return response;
  });
}

export function getTimeTable(weeksAgo = 0, department_id = '', teacherId = '', classroomId = '') {
  return (dispatch) => Api({
    method: 'get',
    url: `/live_classrooms/get_time_table?weeks_ago=${weeksAgo}&department_id=${department_id}&teachers_id=${teacherId}&classrooms_id=${classroomId}`,
  }).then((response) => {
    dispatch({
      type: GET_MEETING_DETAILS,
      payload: {
        timeTabledata: response.time_table_data,
        classrooms: response.classrooms,
        startDate: response.start_date,
        endDate: response.end_date,
        zones: response.zones,
        isHigherEducation: response.is_higher_education,
        selectedClassroom: response.selected_classroom,
        teachers: response.teachers,
        departments: response.departments,
      },
    });
    return response;
  });
}

export function getUserInfo() {
  return (dispatch) => Api({
    method: 'get',
    url: '/virtual_meetings/get_user_info',
  }).then((response) => {
    dispatch({
      type: GET_MEETING_DETAILS,
      payload: {
        academicStartDate: response.academic_year_start_date,
        hasDelayedClassAccess: response.has_delayed_class_access,
        user: response.user_data,
        isHigherEducation: response.is_higher_education,
      },
    });
    return response;
  });
}

export function getDelayedClasses(daysAgo = '', teacher = '', threshold = 5) {
  const date = daysAgo;
  const filters = `teacher=${teacher}&threshold=${threshold}`;
  if (date) {
    date.setDate(date.getDate());
  }
  return (dispatch) => Api({
    method: 'get',
    url: `/virtual_meetings/get_delayed_classes?date=${date}&${filters}`,
  }).then((response) => {
    if (response.success) {
      dispatch({
        type: GET_MEETING_DETAILS,
        payload: {
          delayedClasses: response.delayed_classes,
          teachers: response.teachers,
          isHigherEducation: response.is_higher_education,
        },
      });
    }
    return response;
  });
}

export function updateQuizQuestions(quizQuestionData, id) {
  return (dispatch) => new Promise((resolve) => {
    dispatch({
      type: GET_MEETING_DETAILS,
      payload: {
        quizQuestionData,
      },
    });
    dispatch({
      type: GET_QUIZ_QUESTION_DATA,
      showQuizQuestion: true,
      id,
    });
    resolve();
  });
}

export function applyInstitueFilters(filters = {}) {
  return (dispatch) => new Promise((resolve) => {
    dispatch({
      type: UPDATE_VIRTUAL_MEETING_FILTERS,
      filters: filters || {},
    });
    resolve();
  });
}

export function updateShowStatus(questionIds, showQuestion, quizId) {
  return (dispatch) => Api({
    method: 'post',
    url: '/quiz/update_show_question',
    data: {
      question_ids: JSON.stringify(questionIds),
      show_question: showQuestion,
      quiz_id: quizId,
    },
  }).then((response) => {
    dispatch({
      type: UPDATE_SHOW_QUESTION_DATA,
      ids: questionIds,
    });
    return response;
  });
}

export function addRecordingUrl(meetingId, url) {
  return (dispatch) => Api({
    method: 'post',
    url: '/virtual_meetings/add_recordings_to_meeting',
    data: {
      meeting_id: meetingId,
      url,
    },
  }).then((response) => {
    if (response.success) {
      dispatch(showToast('Data Updated Successfully'));
    }
    return response;
  });
}

export function setHostedTime(meetingId) {
  return () => Api({
    method: 'post',
    url: '/virtual_meetings/set_hosted_time',
    data: {
      id: meetingId,
    },
  }).then((response) => {
    return response;
  });
}

export function toggleMeetingStatus(meetingId, isLive) {
  return (dispatch) => Api({
    method: 'post',
    url: '/virtual_meetings/toggle_meeting_status',
    data: {
      id: meetingId,
      is_live: isLive,
    },
  }).then((response) => {
    const payload = {
      meetingId,
    };
    dispatch({
      type: TOGGLE_MEETING_STATUS,
      payload,
    });
    return response;
  });
}

export function hideVirtualMeeting(meetingId) {
  return () => Api({
    method: 'post',
    url: '/virtual_meetings/hide_virtual_meeting',
    data: {
      meeting_id: meetingId,
    },
  }).then((response) => {
    return response;
  });
}

export function updateShowStatusQuizQuestion(status) {
  return (dispatch) => new Promise((resolve) => {
    dispatch({ type: GET_MEETING_DETAILS, payload: { showQuizQuestion: status } });
    resolve();
  });
}

export function updateQuestionIndex(value) {
  return (dispatch) => new Promise((resolve) => {
    dispatch({ type: SET_CURRENT_QUIZ_QUESTION, value });
    resolve();
  });
}

export function updateSubjectsFilter(value) {
  const classroomIds = value.map((classroom) => { return classroom.value; });
  return (dispatch) => new Promise((resolve) => {
    dispatch({ type: UPDATE_SUBJECTS_FILTER, classroomIds });
    resolve();
  });
}

export function updateTeacherFilter(subjectId) {
  return (dispatch) => new Promise((resolve) => {
    dispatch({ type: UPDATE_CLASSROOM_FILTER, subjectId });
    resolve();
  });
}

export function updateAttendeesCount(attendeesCount) {
  return (dispatch) => new Promise((resolve) => {
    dispatch({ type: UPDATE_ATTENDEES_COUNT, payload: { attendeesCount } });
    resolve();
  });
}

export function updateLiveResults(quizQuestionId, quizId) {
  return (dispatch) => Api({
    method: 'post',
    url: '/quiz/update_live_results',
    data: {
      quiz_question_id: quizQuestionId,
      quiz_id: quizId,
    },
  }).then((response) => {
    if (response.success) {
      dispatch({
        type: UPDATE_LIVE_RESULTS,
        payload: {
          optionData: response.option_data,
          optionList: response.option_list,
          currentQuizQuestionAttemptedStudentsCount: response.attempted_students_count,
          questionsLocked: response.locked_questions,
        },
      });
    }
    return response;
  });
}
export function updateGAuthuStatus(status) {
  return (dispatch) => new Promise((resolve) => {
    dispatch({ type: UPDATE_G_AUTH_STATUS, payload: status });
    resolve();
  });
}

export function deleteVirtualMeeting(id, includeFutureOccurences) {
  return (dispatch) => Api({
    method: 'post',
    url: '/virtual_meetings/delete_virtual_meeting',
    data: {
      id,
      should_include_future_occurences: includeFutureOccurences,
    },
  }).then((response) => {
    if (response.success) {
      dispatch(showToast('Deleted Successfully!'));
    } else {
      dispatch(showToast('There was an error when deleting the meeting(s). '));
    }
  });
}

export function endOnGoingMeetings(meetingId, role, id) {
  return (dispatch) => Api({
    method: 'post',
    url: '/virtual_meetings/end_ongoing_meetings',
    data: {
      meeting_number: meetingId,
      role,
      id,
    },
  }).then((response) => {
    if (response.success) {
      dispatch(showToast('Previous meetings ended. Try hosting again.'));
    } else {
      dispatch(showToast('There was an error when ending the meeting(s). '));
    }
  });
}

export const getStudentsReport = (selectedRange, getAttendanceShortage, page = 1, quizId = '', classroom = '', campus = '', department = '', semester = '', subject = '') => async (dispatch, getState) => {
  dispatch({
    type: LOADING_ATTENDACE_REPORTS,
    payload: true,
  });
  const filters = `classroom_id=${classroom}&campus_id=${campus}&department_id=${department}&course_id=${semester}&subject_id=${subject}&page=${page}&quiz_id=${quizId}&get_attendance_shortage=${getAttendanceShortage}&selected_range=${JSON.stringify(selectedRange)}`;

  const response = await Api({
    method: 'get',
    url: `/virtual_meetings/get_attendance_report?${filters}`,
  });
  if (response.success) {
    const {
      success, ...data
    } = response;
    dispatch({
      type: GET_ATTENDACE_REPORTS,
      payload: {
        ...data,
        filterClassrooms: response.filter_classrooms,
        isHigherEducation: response.is_higher_education,
        attendanceDetailsToSave: response.attendance_details_to_save,
      },
    });
  } else {
    dispatch({
      type: LOADING_ATTENDACE_REPORTS,
      payload: false,
    });
  }
};

export const getTeacherAttendance = (selectedRange, department = '') => async (dispatch, getState) => {
  dispatch({
    type: LOADING_ATTENDACE_REPORTS,
    payload: true,
  });
  const filters = `department_id=${department}&selected_range=${JSON.stringify(selectedRange)}`;
  const response = await Api({
    method: 'get',
    url: `/virtual_meetings/get_teachers_attendance?${filters}`,
  });
  if (response.success) {
    dispatch({
      type: GET_ATTENDACE_REPORTS,
      payload: {
        departments: response.departments,
        teacherAttendance: response.teachers_attendance,
        isHigherEducation: response.is_higher_education,
      },
    });
  } else {
    dispatch({
      type: LOADING_ATTENDACE_REPORTS,
      payload: false,
    });
  }
};

export function markPresent(studentId, periodId, date, index, detailIndex) {
  return (dispatch) => {
    dispatch({
      type: LOADING_ATTENDACE_REPORTS,
      payload: false,
    });
    return Api({
      method: 'post',
      url: '/virtual_meetings/mark_student_attendance',
      data: { period_id: periodId, student_id: studentId },
    }).then((response) => {
      if (response.success) {
        const payload = {
          index, detailIndex, date, attendanceId: response.attendance_id,
        };
        dispatch({
          type: MARK_PRESENT,
          payload,
        });
      }
      dispatch(showToast(response.message));
      dispatch({
        type: LOADING_ATTENDACE_REPORTS,
        payload: false,
      });
      return response;
    });
  };
}

export function markAbsent(attendanceId, date, index, detailIndex) {
  return (dispatch) => {
    dispatch({
      type: LOADING_ATTENDACE_REPORTS,
      payload: false,
    });
    return Api({
      method: 'post',
      url: '/virtual_meetings/mark_as_absent',
      data: { attendance_id: attendanceId },
    }).then((response) => {
      if (response.success) {
        const payload = { index, detailIndex, date };
        dispatch({
          type: MARK_ABSENT,
          payload,
        });
      }
      dispatch(showToast(response.message));
      dispatch({
        type: LOADING_ATTENDACE_REPORTS,
        payload: false,
      });
      return response;
    });
  };
}

