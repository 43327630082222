import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import phoneCall from '../../static/images/phone-call.svg';
import email from '../../static/images/email.svg';

class Help extends PureComponent {
  render() {
    return (
      <div style={{
        background: '#FFFFFF', alignItems: 'center', placeContent: 'center', fontFamily: 'Nunito', marginLeft: 10,
      }}
      >
        <div style={{
          display: 'flex', fontSize: 18, alignItems: 'center', color: '#343737', marginTop: 25,
        }}
        >
          <img alt="" className="center" src={phoneCall} width="35px" height="35px" />
          <div style={{ paddingLeft: 40 }}>
            080-471-87111
          </div>
        </div>
        <div style={{
          display: 'flex', fontSize: 18, alignItems: 'center', color: '#343737', marginTop: 35,
        }}
        >
          <img alt="" className="center" src={email} width="35px" height="35px" />
          <div style={{ paddingLeft: 40 }}>
            support@ideaboard.xyz
          </div>
        </div>
      </div>
    );
  }
}

export default connect(null, {})(Help);
