import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import Select from "react-select";
import ReactTooltip from "react-tooltip";
import Modal from "./baseModal";
import {
  createAndUpdateTeamManagement,
  showToast,
  deleteTeamMember,
} from "../../actions";
import showPasswordIcon from "../../static/images/show_password.png";
import hidePasswordIcon from "../../static/images/hide_password.png";
import { validateEmail } from "../../helpers/util";

const TEAM_TYPES = {
  Admin: { value: "Admin", label: "Admin" },
  Teacher: { value: "Teacher", label: "Teacher" },
  Counsellor: { value: "Counsellor", label: "Counsellor" },
  Guardian: { value: "Guardian", label: "Guardian" },
};

const PERMISSIONS = {
  Admin: { value: "Admin", label: "Admin" },
  Finance: { value: "Finance", label: "Finance" },
  Facilities: { value: "Facilities", label: "Facilities" },
  Counsellor: { value: "Counsellor", label: "Counsellor" },
  HR: { value: "HR", label: "HR" },
  Salary: { value: "Salary", label: "Salary" },
  Tagger: { value: "Tagger", label: "Tagger" },
  Institute_DTP_Admin: {
    value: "Institute DTP Admin",
    label: "Institute DTP Admin",
  },
  Paper_Setter: { value: "Paper Setter", label: "Paper Setter" },
  Finance_Admin: { value: "Finance Admin", label: "Finance Admin" },
  DTP: { value: "DTP", label: "DTP" },
  Curator: { value: "Curator", label: "Curator" },
  Analyse_Test: { value: "Analyse Test", label: "Analyse Test" },
  Edit_Concepts: { value: "Edit Concepts", label: "Edit Concepts" },
  Edit_Others_Questions: {
    value: "Edit Others Questions",
    label: "Edit Others Questions",
  },
  DTP_Dashboard: { value: "DTP Dashboard", label: "DTP Dashboard" },
  DTP_Admin: { value: "DTP Admin", label: "DTP Admin" },
  Question_Curator: { value: "Question Curator", label: "Question Curator" },
  Hints_and_Solutions: {
    value: "Hints & Solutions",
    label: "Hints & Solutions",
  },
  Typos: { value: "Typos", label: "Typos" },
  Business_Developer: {
    value: "Business Developer",
    label: "Business Developer",
  },
  Teacher: { value: "Teacher", label: "Teacher" },
  Admission_Manager: { value: "Admission Manager", label: "Admission Manager" },
};

const customDropdownStyles = {
  control: (base) => ({
    ...base,
    height: 35,
    width: 350,
    backgroundColor: "white",
    border: "1px solid #CDCFD6",
  }),
  indicatorsContainer: (base) => ({
    ...base,
    height: 35,
  }),
  valueContainer: (base) => ({
    ...base,
    height: 35,
    overflow: "auto",
  }),
  option: (base, state) => ({
    ...base,
    textAlign: "left",
    color: state.isFocused ? "#FFF" : "#000",
    backgroundColor: state.isFocused && "#3994AE",
  }),
};

class TeamManagementModel extends React.Component {
  constructor(props) {
    super(props);
    const { teamManagementData } = this.props;
    let selectedPermission = null;
    if (teamManagementData && teamManagementData.permissions) {
      selectedPermission = [];
      teamManagementData.permissions.map((permission) => {
        selectedPermission.push({ value: permission, label: permission });
      });
    }
    this.state = {
      data:
        teamManagementData && teamManagementData.id
          ? {
              id: teamManagementData.id,
              first_name: teamManagementData.first_name,
              last_name: teamManagementData.last_name,
              email: teamManagementData.email,
              badge_id: teamManagementData.badge_id,
              type: teamManagementData.user_type,
              permissions: teamManagementData.permissions,
            }
          : {
              email: "",
            },
      selectedType: teamManagementData &&
        teamManagementData.user_type &&
        teamManagementData.user_type && {
          value: teamManagementData.user_type,
          label: teamManagementData.user_type,
        },
      selectedPermission,
      password: "",
      showConfirmPassword: false,
      showConfimationModal: false,
      selectedPermissions: {},
    };
    this.renderContent = this.renderContent.bind(this);
    this.renderDropdown = this.renderDropdown.bind(this);
    this.renderInput = this.renderInput.bind(this);
    this.renderMultiSelect = this.renderMultiSelect.bind(this);
  }

  renderMultiSelect(
    options,
    selectedOptions,
    handleChangeOfMultiSelect,
    placeholder
  ) {
    return (
      <div style={{ width: "100%" }}>
        <Select
          value={selectedOptions}
          onChange={handleChangeOfMultiSelect}
          options={options}
          placeholder={placeholder}
          styles={customDropdownStyles}
          maxMenuHeight={200}
          isMulti
        />
      </div>
    );
  }

  renderDropdown(options, selectedValue, handleChange, placeholder) {
    return (
      <div style={{ marginBottom: 20 }}>
        <Select
          value={selectedValue}
          onChange={handleChange}
          options={options}
          placeholder={placeholder}
          isSearchable={false}
        />
      </div>
    );
  }

  renderInput(
    value = "",
    inputType,
    handleChange = () => {},
    placeholder = ""
  ) {
    return (
      <input
        type={inputType}
        value={value}
        placeholder={placeholder}
        style={{
          border: "none",
          borderBottom: "1px solid #E4E7EE",
          width: "90%",
          margin: "5px 0px 0px 0px",
          fontSize: 16,
          marginBottom: 30,
        }}
        onChange={handleChange}
      />
    );
  }

  renderConfirmationModal() {
    const { showConfimationModal, data } = this.state;
    return (
      <Modal
        showModal={showConfimationModal}
        handleCloseModal={() => this.setState({ showConfimationModal: false })}
        width="500px"
        style={{ padding: 40, zIndex: 5 }}
      >
        <div>Are you sure you want to delete {data.email}?</div>
        <div
          style={{ display: "flex", justifyContent: "flex-end", marginTop: 30 }}
        >
          <div
            className="Button secondary-Button"
            style={{ width: 100, marginRight: 20 }}
            role="presentation"
            onClick={() => {
              this.props.deleteTeamMember(data).then(() => {
                window.location.reload();
              });
              this.props.showToast("Account Deleted");
              this.setState({ showConfimationModal: false });
            }}
          >
            Delete
          </div>
          <div
            style={{ width: 100 }}
            className="Button primary-Button"
            role="presentation"
            onClick={() => {
              this.setState({ showConfimationModal: false });
            }}
          >
            Cancel
          </div>
        </div>
      </Modal>
    );
  }

  renderContent() {
    const {
      data,
      selectedType,
      selectedPermission,
      selectedPermissions,
      password,
      showConfirmPassword,
    } = this.state;
    const { submitButton, isEdit } = this.props;
    return (
      <div
        style={{
          padding: 30,
          paddingLeft: 0,
          color: "#11426C",
          fontSize: "14px",
        }}
      >
        <div>
          First Name:
          {this.renderInput(
            data.first_name,
            "text",
            (event) => {
              this.setState({
                data: { ...data, first_name: event.target.value },
              });
            },
            "First Name"
          )}
        </div>
        <div>
          Last Name:
          {this.renderInput(
            data.last_name,
            "text",
            (event) => {
              this.setState({
                data: { ...data, last_name: event.target.value },
              });
            },
            "Last Name"
          )}
        </div>
        <div
          onClick={() => {
            this.props.showToast(
              "Enter a valid E-mail, password reset instructions will be sent to this mail."
            );
          }}
        >
          Email:
          <div>
            {this.renderInput(
              data.email,
              "email",
              (event) => {
                this.setState({ data: { ...data, email: event.target.value } });
              },
              "Email"
            )}
          </div>
        </div>
        <div>
          Badge Id:
          {this.renderInput(
            data.badge_id,
            "text",
            (event) => {
              this.setState({
                data: { ...data, badge_id: event.target.value },
              });
            },
            "Badge Id"
          )}
        </div>
        <div style={{ width: "350px" }}>
          Type:
          {this.renderDropdown(
            Object.values(TEAM_TYPES),
            selectedType,
            (selectedValue) => {
              this.setState({
                data: {
                  ...data,
                  type: selectedValue.value,
                },
                selectedType: selectedValue,
              });
            },
            "Type"
          )}
        </div>

        <div style={{ marginBottom: "20px" }}>
          Permissions:
          {this.renderMultiSelect(
            Object.values(PERMISSIONS),
            selectedPermission && [...selectedPermission],
            (selectedValue) => {
              const permissionList = [];
              if (selectedValue) {
                selectedValue.map((item) => {
                  permissionList.push(item.value);
                });
              }
              this.setState({
                data: {
                  ...data,
                  permissions: [...permissionList],
                },
                selectedPermission: selectedValue,
              });
            },
            "List choose from"
          )}
        </div>

        <div
          className="Button primary-Button"
          role="presentation"
          onClick={() => {
            if (!data.email) {
              this.props.showToast("Please Add email");
            } else if (!validateEmail(data.email)) {
              this.props.showToast("Use a proper mail id");
            } else if (!data.type) {
              this.props.showToast("Please Select Type");
            } else if (!data.first_name) {
              this.props.showToast("First Name is necessary");
            } else if (!data.badge_id) {
              this.props.showToast("Please Add Badge Id");
            } else {
              this.props.createAndUpdateTeamManagement(data, password);
            }
          }}
        >
          {submitButton}
        </div>
        <div style={{ paddingBottom: "10px" }} />
        {isEdit && (
          <div
            className="Button secondary-Button"
            role="presentation"
            onClick={() => {
              this.setState({ showConfimationModal: true });
            }}
          >
            Delete Account
          </div>
        )}
      </div>
    );
  }

  render() {
    const { visible, onClose, isEdit, header } = this.props;
    return (
      <Modal
        showModal={visible}
        handleCloseModal={onClose}
        width="700px"
        height="90%"
        header={header}
        style={{ padding: 40 }}
      >
        {this.renderContent(isEdit)}
        {this.state.showConfimationModal && this.renderConfirmationModal()}
      </Modal>
    );
  }
}

TeamManagementModel.propTypes = {
  visible: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
  isEdit: PropTypes.bool,
  header: PropTypes.string,
  submitButton: PropTypes.string,
  isEdit: PropTypes.bool,
  refreshOnClose: PropTypes.bool,
};

TeamManagementModel.defaultProps = {
  visible: false,
  isEdit: false,
  header: "Add New Member",
  submitButton: "Add",
  isEdit: false,
  refreshOnClose: false,
};

const mapStateToProps = ({ institute }) => ({
  teamMemberDetails: institute.teamMemberDetails,
  campuses: institute.campuses,
});

export default connect(mapStateToProps, {
  createAndUpdateTeamManagement,
  showToast,
  deleteTeamMember,
})(TeamManagementModel);
