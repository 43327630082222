import { UPDATE_AUTH, UPDATE_PROFILE_PICTURE, LOGOUT } from '../actions';

const initialState = {
  userTypes: [],
  userDetails: {},
  isUpdating: false,
  isAdmin: false,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case UPDATE_AUTH: {
      return { ...state, ...action.payload };
    }

    case UPDATE_PROFILE_PICTURE: {
      return { ...state, ...action.payload };
    }

    case LOGOUT: {
      return initialState;
    }

    default: {
      return state;
    }
  }
}
