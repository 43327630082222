import Api from '../helpers/Api';
import { showToast } from '.';

export const GET_QUESTION_SUGGESTION = '@questionSuggestionsList/GET_QUESTION_SUGGESTION';

export function getQuestionSuggestions(userId = '') {
  return (dispatch) => Api({
    method: 'get',
    url: '/questions/get_question_suggestions',
    data: { user_id: userId },
  }).then((response) => {
    if (response.success) {
      const payload = {
        questionSuggestions: response.question_suggestions,
      };
      dispatch({
        type: GET_QUESTION_SUGGESTION,
        payload,
      });
    }
    return response;
  });
}

export function uploadQuestionSuggestion(id, status, imageFile = []) {
  return (dispatch) => Api({
    method: 'post',
    url: '/questions/upload_question_suggestion',
    attachment: imageFile.length > 1 ? imageFile : imageFile[0],
    type: 'question_image_file',
    data: { id, status },
  }).then((response) => {
    if (response.success) {
      dispatch(getQuestionSuggestions());
      dispatch(showToast(response.message));
    }
    return response;
  });
}
