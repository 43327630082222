import React, { Component } from 'react';
import { connect } from 'react-redux';
import Pagination from 'react-js-pagination';
import PropTypes from 'prop-types';
import Cookies from 'universal-cookie';
import IbLogo from '../../static/images/IB.png';
import Layout from '../layout';
import Constants from '../../shared/constants';
import Loader from '../loading';
import AccessDenied from '../accessDenied';
import {
  getSmsSubscriptionData, rechargeForSMS, showToast, updateSmsCount,
} from '../../actions';
import { isValidNumber } from '../../helpers/util';
import StyleConstants from '../../styles/styles.json';

const PAGE_COUNT = 5;

const headerStyle = {
  fontWeight: 'bold', fontSize: 24, fontFamily: 'Nunito-Sans', marginBottom: 10,
};
const cookies = new Cookies();
const textAreaStyle = {
  resize: 'none', width: 150, outline: 'none', border: 0, margin: 0, padding: 0, minHeight: 10,
};
class SmsSubscription extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showLoader: true,
      page: 1,
    };
    this.recharge = this.recharge.bind(this);
    this.renderPagination = this.renderPagination.bind(this);
  }

  componentDidMount() {
    this.props.getSmsSubscriptionData().then(() => this.setState({ showLoader: false }));
  }

  recharge(amount, quantity) {
    if (quantity <= 0) {
      this.props.showToast('Enter the amount');
      return;
    }
    const { rechargeForSMS } = this.props;
    const user = cookies.get('user');
    const options = {
      key: Constants().razorPayPublicKey,
      amount: Math.ceil(amount * 100),
      name: user.name,
      description: `Buy ${quantity} SMS`,
      image: IbLogo,
      handler(response) {
        rechargeForSMS(response, amount, quantity);
      },
      prefill: {
        name: user.name,
        email: user.email,
        contact: user.phone,
      },
      theme: {
        color: StyleConstants.color.testReportHeader,
      },
    };
    const rzp = new window.Razorpay(options);
    rzp.open();
  }

  renderPagination(dataList) {
    const { page } = this.state;
    if (dataList && dataList.length > 0 && dataList.length > PAGE_COUNT) {
      return (
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <style>
            {`
          .pagination>li.active>a{
            background-color: ${Constants().color.primary} !important;
            color: #FFFFFF !important;
          }
          .pagination > li > a, .pagination > li > span{
            color: ${Constants().color.primary} !important;
          }
          .pagination > li > a:hover{
            background-color: #3d73ad !important;
            color: #FFFFFF !important;
          }
          `}
          </style>
          <Pagination
            activePage={page}
            itemsCountPerPage={PAGE_COUNT}
            totalItemsCount={dataList.length}
            pageRangeDisplayed={5}
            onChange={(pageno) => this.setState({ page: pageno })}
          />
        </div>
      );
    }
    return <div />;
  }

  render() {
    const { showLoader, page } = this.state;
    let activePlans = [];
    const {
      permissionedDenied, costPerSms, smsCountNeeded, smsDetails,
    } = this.props;
    let balance = 0;
    smsDetails.map((data) => {
      balance += parseInt(data.quantity, 10);
      activePlans = [...activePlans, { ...data, balance }];
    });
    activePlans = activePlans.reverse();
    if (showLoader) {
      return <Loader />;
    }
    if (permissionedDenied) {
      return (
        <AccessDenied />
      );
    }
    const tableHeaderStyle = { textAlignLast: 'left' };
    const rowStyle = { textAlignLast: 'left' };
    const minCount = page >= 2 ? (page - 1) * PAGE_COUNT : 0;
    const maxCount = (page) * PAGE_COUNT;
    return (
      <Layout globalHistory={this.props.history}>
        <div style={{ padding: '0 150px', fontFamily: 'Nunito-Sans', fontSize: 18 }}>
          <div style={{ display: 'flex' }}>
            <div style={{ width: '60%', padding: '60px 0px' }}>
              <div style={{ fontWeight: 'bold', marginBottom: 20, fontSize: 20 }}>
                Current SMS Balance:&nbsp;
                {balance}
                {' '}
                SMS
              </div>
              <div style={{ ...headerStyle }}>Transaction History</div>
              <div className="Graph-Card-View" style={{ padding: 20 }}>
                <table className="table table-bordered">
                  <thead>
                    <th style={tableHeaderStyle}>Date</th>
                    <th style={tableHeaderStyle}>Transaction</th>
                    <th style={tableHeaderStyle}>Quantity</th>
                    <th style={tableHeaderStyle}>Balance</th>
                    <th style={tableHeaderStyle}>Notes</th>
                  </thead>
                  {activePlans.slice(minCount, maxCount).map((plan) => {
                    return (
                      <tr>
                        <td style={rowStyle}>{plan.start_date}</td>
                        <td style={rowStyle}>{plan.transaction_type}</td>
                        <td style={{ textAlignLast: 'right' }}>{parseInt(plan.quantity, 10) > 0 ? `+${plan.quantity}` : plan.quantity}</td>
                        <td style={{ textAlignLast: 'right' }}>{plan.balance}</td>
                        <td style={{ whiteSpace: 'pre-wrap', ...rowStyle }}>{plan.notes}</td>
                      </tr>
                    );
                  })}
                </table>
                {this.renderPagination(activePlans)}
              </div>
            </div>
            <div
              style={{
                display: 'flex', flexDirection: 'column', alignItems: 'center', width: '40%', left: '60%', height: global.innerHeight, position: 'fixed', fontSize: 22, fontFamily: 'Nunito-Sans', justifyContent: 'center',
              }}
            >
              <div
                className="Graph-Card-View"
                style={{
                  alignItems: 'center', margin: '120px 0px', padding: 30, background: '#1b1a1a17',
                }}
              >
                <div style={{ margin: '50px 0px', textAlignLast: 'left' }}>
                  <div style={{ marginBottom: 20 }}>
                    Cost Per SMS is &#8377;&nbsp;
                    {costPerSms}
                  </div>
                  <div style={{
                    display: 'flex', width: 'fit-content', alignItems: 'center', marginBottom: 20,
                  }}
                  >
                    <div style={{ marginRight: 20 }}>
                      No of SMS:
                    </div>
                    <input
                      value={smsCountNeeded || ''}
                      placeholder="eg. 100"
                      style={{ ...textAreaStyle, borderBottom: '1px solid #454545', paddingLeft: 10 }}
                      onChange={(event) => {
                        if (isValidNumber(event.target.value) || !event.target.value) {
                          this.props.updateSmsCount(event.target.value || '');
                        }
                      }}
                    />
                  </div>
                  <div style={{ marginBottom: 20 }}>
                    Total : &#8377;&nbsp;
                    {Math.ceil(costPerSms * smsCountNeeded)}
                  </div>
                </div>
                <div
                  role="presentation"
                  className="Button Secondary-Button"
                  style={{
                    background: StyleConstants.color.testReportHeader, padding: '20px 50px', width: 'fit-content', color: StyleConstants.color.white, fontSize: 18, opacity: smsCountNeeded ? 1 : 0.5,
                  }}
                  onClick={() => {
                    this.recharge(Math.ceil(costPerSms * smsCountNeeded), smsCountNeeded);
                  }}
                >
                  Recharge
                </div>
              </div>
            </div>
          </div>
        </div>
      </Layout>
    );
  }
}

SmsSubscription.propTypes = {

  permissionedDenied: PropTypes.bool,
  costPerSms: PropTypes.number,
  remainingSmsCount: PropTypes.number,
  smsDetails: PropTypes.array,
  smsCountNeeded: PropTypes.number,
  smsUsed: PropTypes.number,
};

SmsSubscription.defaultProps = {
  permissionedDenied: true,
  costPerSms: 0,
  remainingSmsCount: 0,
  smsDetails: [],
  smsCountNeeded: '',
  smsUsed: 0,
};

const mapStateToProps = ({ institute }) => ({
  permissionedDenied: institute.permissionedDenied,
  costPerSms: institute.costPerSms,
  remainingSmsCount: institute.remainingSmsCount,
  smsDetails: institute.smsDetails,
  smsCountNeeded: institute.smsCountNeeded,
  smsUsed: institute.smsUsed,
});

export default connect(mapStateToProps, {
  getSmsSubscriptionData, rechargeForSMS, showToast, updateSmsCount,
})(SmsSubscription);
