import Api from '../helpers/Api';

export const UPDATE_TEST_DETAILS = 'testConfiguration/UPDATE_TEST_DETAILS';
export const GET_CHAPTERS_FOR_TEST = 'testConfiguration/GET_CHAPTERS_FOR_TEST';
export const GET_TOPIC_CHAPTER_DISTRIBUTION_FOR_TEST = 'testConfiguration/GET_TOPIC_CHAPTER_DISTRIBUTION_FOR_TEST';
export const UPDATE_TEST_OVERVIEW = 'testConfiguration/UPDATE_TEST_OVERVIEW';
export const UPDATE_TEST_REVIEW_STATUS = 'testConfiguration/UPDATE_TEST_REVIEW_STATUS';

export function updateTestReview(params) {
  return (dispatch) => Api({
    method: 'post',
    url: '/membership_tests/update_test_review_state',
    data: params,
  }).then((response) => {
    if (response.success) {
      dispatch({
        type: UPDATE_TEST_REVIEW_STATUS,
        reviewStatus: response.is_reviewed,
      });
    }
    return response;
  });
}

export function getTestOverview(id) {
  return (dispatch) => Api({
    method: 'get',
    url: `/membership_tests/get_test_overview?id=${id}`,
  }).then((response) => {
    if (response.success) {
      const payload = {
        isTrial: response.is_trial,
        testDetails: response.test_details,
        hasAccess: response.has_access,
        isTestLockedByOtp: response.can_lock_test,
        testOtpType: response.test_otp_type,
        hasQuestionUploadAccess: response.has_question_upload_acccess,
        isTestDataEditable: response.is_test_data_editable,
        classroomList: response.classroom_list,
        selectedTestGroup: response.selected_test_group,
      };
      dispatch({
        type: UPDATE_TEST_OVERVIEW,
        payload,
      });
    }
    return response;
  });
}

export function getTestDetails(id) {
  return (dispatch) => Api({
    method: 'get',
    url: `/membership_tests/get_test_details?id=${id}`,
  }).then((response) => {
    if (response.success) {
      const payload = {
        selectedTestGroup: response.selected_test_group,
        testDetails: response.test_details,
        paperSetters: response.paper_setters,
        subjectList: response.subject_list,
      };
      dispatch({
        type: UPDATE_TEST_DETAILS,
        payload,
      });
    }
    return response;
  });
}

export function updateTestDetails(payload) {
  return (dispatch) => Api({
    method: 'post',
    url: '/membership_tests/update_test_details',
    data: payload,
  }).then((response) => {
    return response;
  });
}

export function updateTestSetCount(payload) {
  return (dispatch) => Api({
    method: 'post',
    url: '/membership_tests/update_test_sets',
    data: payload,
  });
}

export function getTestChapters(id, subjectId, track) {
  return (dispatch) => Api({
    method: 'get',
    url: `/membership_tests/get_test_subjects_chapter_data?id=${id}&subject_id=${subjectId}&track=${track}`,
  }).then((response) => {
    if (response.success) {
      const payload = {
        unitData: response.unit_data,
        selectedChapters: response.selected_chapters,
        approved: response.approved,
        isAssignment: response.is_assignment,
        isTrial: response.is_trial,
      };
      dispatch({
        type: GET_CHAPTERS_FOR_TEST,
        payload,
      });
    }
    return response;
  });
}

export function updateTestChaptersData(payload) {
  return (dispatch) => Api({
    method: 'post',
    url: '/membership_tests/update_test_chapter_data',
    data: payload,
  }).then((response) => {
    return response;
  });
}

export function getTestDistribution(id, subjectId, track) {
  return (dispatch) => Api({
    method: 'get',
    url: `/membership_tests/get_test_distribution_data?id=${id}&subject_id=${subjectId}&track=${track}`,
  }).then((response) => {
    if (response.success) {
      const payload = {
        distribution: response.distribution,
        questionLimit: response.question_limit,
        selectedDistributionRanges: response.selected_ranges,
        isAssignment: response.is_assignment,
      };
      dispatch({
        type: GET_TOPIC_CHAPTER_DISTRIBUTION_FOR_TEST,
        payload,
      });
    }
    return response;
  });
}

export function updateSubjectTrackSelected(payload) {
  return () => Api({
    method: 'post',
    url: '/membership_tests/update_subject_track_preference',
    data: payload,
  }).then((response) => {
    return response;
  });
}

export function generateAutoTest(payload) {
  return () => Api({
    method: 'post',
    url: '/membership_tests/update_test_details_and_generate_test',
    data: payload,
  }).then((response) => {
    return response;
  });
}
