import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import Select, { components } from 'react-select';
import dropDown from '../../static/images/drop-down.svg';

class CustomizedFilter extends PureComponent {
  render() {
    const {
      filterItems, selectedItem, item, minWidth, isClearable, showEmpty,
    } = this.props;
    const length = selectedItem.label ? (selectedItem.label.length * 9) + 100 : (minWidth || item.length * 12);
    const selectBoxStyle = {
      control: (base) => ({
        ...base,
        height: 22,
        cursor: 'pointer',
        border: 'none',
        outline: 'none',
        background: 'transparent',
        boxShadow: '0 !important',
        color: '#454545',
      }),
      option: (base) => ({
        ...base,
        color: '#454545',
        whiteSpace: 'pre-wrap',
      }),
      indicatorSeparator: (provided) => ({
        ...provided,
        width: 0,
      }),
      singleValue: (provided) => ({
        ...provided,
        color: '#112E48',
        fontSize: 16,
        fontWeight: 'bold',
      }),
      dropdownIndicator: (base) => ({
        ...base,
        color: '#112E48',
      }),
    };
    const DropdownIndicator = (data) => {
      return (
        <components.DropdownIndicator {...data}>
          <img src={dropDown} alt="dropdown" height="35px" width="" />
        </components.DropdownIndicator>
      );
    };
    const boxWidth = { width: length, zIndex: 5, cursor: filterItems.length === 0 ? 'not-allowed' : 'pointer' };
    if (filterItems.length > 0 || showEmpty) {
      return (
        <div style={boxWidth}>
          <Select
            key={selectedItem.label}
            value={selectedItem.value && selectedItem}
            components={{ DropdownIndicator }}
            onChange={this.props.selectedFunction}
            options={filterItems}
            isClearable={isClearable}
            isDisabled={filterItems.length === 0}
            placeholder={item}
            styles={selectBoxStyle}
          />
        </div>
      );
    }
    return <div />;
  }
}

CustomizedFilter.propTypes = {
  filterItems: PropTypes.array,
  selectedItem: PropTypes.object,
  item: PropTypes.string,
  minWidth: PropTypes.number,
  showEmpty: PropTypes.bool,
  isClearable: PropTypes.bool,
};

CustomizedFilter.defaultProps = {
  filterItems: [],
  selectedItem: {},
  item: '',
  minWidth: 0,
  showEmpty: false,
  isClearable: true,
};

export default CustomizedFilter;
