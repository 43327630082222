import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Pagination from 'react-js-pagination';
import Loader from '../loading';
import Constants from '../../shared/constants';
import { CustomizedFilter } from '../common';
import { getLiveClasses } from '../../actions';

const PAGE_COUNT = 10;
class ClassBoard extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      page: 1,
      department: {},
      showLoader: true,
    };
    this.showLiveCard = this.showLiveCard.bind(this);
    this.renderPagination = this.renderPagination.bind(this);
    this.renderDropDown = this.renderDropDown.bind(this);
  }

  componentDidMount() {
    this.props.getLiveClasses().then(() => {
      this.setState({ showLoader: false });
    });
  }

  getFilteredClasses() {
    const { liveClasses } = this.props;
    const { department } = this.state;
    const value = department && department.value;
    let filteredData = [];
    filteredData = liveClasses.filter((classes) => {
      return (!value || (classes.department_ids && classes.department_ids.includes(value)));
    });
    return filteredData;
  }

  showLiveCard(classes) {
    const { department } = this.state;
    const { isHigherEducation } = this.props;
    const style = { padding: 20 };
    return (
      <tr>
        {isHigherEducation && !department.value && <td style={style}>{classes.department_name && classes.department_name.join(', ')}</td>}
        {isHigherEducation && <td style={style}>{classes.course_name && classes.course_name.join(', ')}</td>}
        <td style={style}>{classes.subject_name && classes.subject_name.join(', ')}</td>
        {!isHigherEducation && <td style={style}>{classes.classroom_name && classes.classroom_name.join(', ')}</td>}
        <td style={style}>{classes.lecturer_name}</td>
        <td style={{
          padding: 10,
        }}
        >
          <div
            role="presentation"
            className="Button Primary-Button"
            style={{
              cursor: 'pointer', width: 70,
            }}
            onClick={() => { this.props.joinZoomMeeting(classes); }}
          >
            JOIN
          </div>
        </td>
      </tr>
    );
  }

  renderDropDown(filterItems, selectedItem, selectedFunction, item) {
    return (
      <CustomizedFilter
        selectedItem={selectedItem}
        item={item}
        filterItems={filterItems}
        selectedFunction={selectedFunction}
      />
    );
  }

  renderPagination(liveClasses) {
    const { page } = this.state;
    if (liveClasses.length > 0 && liveClasses.length > PAGE_COUNT) {
      return (
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <Pagination
            activePage={page}
            itemsCountPerPage={PAGE_COUNT}
            totalItemsCount={liveClasses.length}
            pageRangeDisplayed={5}
            onChange={(pageNumber) => this.setState({ page: pageNumber })}
          />
        </div>
      );
    }
    return <div />;
  }

  renderData(liveClasses) {
    const { page, department } = this.state;
    const { isHigherEducation } = this.props;
    const style = {
      padding: 20, whiteSpace: 'nowrap', borderRight: '1px solid #DFE3EA', paddingLeft: 20,
    };
    const minCount = page >= 2 ? (page - 1) * PAGE_COUNT : 0;
    const maxCount = (page) * PAGE_COUNT;
    if (liveClasses && liveClasses.length > 0) {
      return (
        <table
          className="table table-hover"
          style={{
            boxShadow: '1px 2px 6px #8B9DAF33', borderRadius: 4, backgroundColor: '#FFFFFF', border: '1px solid #CDCFD6', marginTop: 30,
          }}
        >
          <thead style={{ background: '#F6F8FA', color: '#23394C' }}>
            <tr>
              {isHigherEducation && !department.value && <th style={style}>DEPARTMENT</th>}
              {isHigherEducation && <th style={style}>SEMESTER</th>}
              <th style={style}>SUBJECT</th>
              {!isHigherEducation && <td style={style}>CLASSROOM</td>}
              <th style={style}>{isHigherEducation ? 'LECTURER' : 'TEACHER' }</th>
              <th />
            </tr>
          </thead>
          <tbody>
            {liveClasses.slice(minCount, maxCount).map((classes) => this.showLiveCard(classes))}
          </tbody>
        </table>
      );
    }
    return (
      <div style={{
        display: 'flex', marginTop: 200, justifyContent: 'center', fontSize: 22, margin: '150px 50px', padding: 20, border: '1px solid',
      }}
      >
        {`No classes are currently live ${department.label ? `For ${department.label}` : ''}`}
      </div>
    );
  }

  renderFilter() {
    const { departments } = this.props;
    const { department } = this.state;
    return (
      <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
        {this.renderDropDown(departments, department, (selectedListItem) => this.setState({ department: selectedListItem || {}, page: 1 }), 'Select Department')}
      </div>
    );
  }

  renderHeader() {
    return (
      <div style={{
        fontWeight: 600, fontSize: 24, padding: '0 60px', height: 70, display: 'flex', alignItems: 'center', background: '#FFFFFF', color: Constants().color.primary, boxShadow: '0px 3px 6px #343B4014', position: 'fixed', width: '100%', zIndex: 10,
      }}
      >
        Current Classes
      </div>
    );
  }

  render() {
    const { showLoader } = this.state;
    if (showLoader) {
      return <Loader />;
    }
    const dataList = this.getFilteredClasses();
    return (
      <div style={{ fontFamily: 'Roboto', height: '100%' }}>
        {this.renderHeader()}
        <div style={{ padding: '0px 60px', paddingTop: 90, height: '100%' }}>
          {this.renderFilter()}
          {this.renderData(dataList)}
          {this.renderPagination(dataList)}
        </div>
      </div>
    );
  }
}

ClassBoard.propTypes = {
  liveClasses: PropTypes.array,
  departments: PropTypes.array,
  isHigherEducation: PropTypes.bool,
};

ClassBoard.defaultProps = {
  liveClasses: [],
  departments: [],
  isHigherEducation: false,
};

const mapStateToProps = ({ virtualMeeting }) => ({
  liveClasses: virtualMeeting.liveClasses,
  departments: virtualMeeting.departments,
  isHigherEducation: virtualMeeting.isHigherEducation,
});

export default connect(mapStateToProps, { getLiveClasses })(ClassBoard);
