import Cookies from 'universal-cookie';
import { createBrowserHistory } from 'history';
import Api from '../helpers/Api';
import Constants from '../shared/constants';
import { encrypt } from '../helpers/CryptoHelper';
import { getAccountsDomain, getParentPortalDomain } from '../helpers/util';
import { showToast } from '.';

const history = createBrowserHistory({
  forceRefresh: true,
});

const cookies = new Cookies();
export const UPDATE_AUTH = 'Auth/SET_USER';
export const LOGOUT = 'Auth/LOGOUT';
export const UPDATE_PROFILE_PICTURE = 'Auth/UPDATE_PROFILE_PICTURE';

export const getUserTypes = () => async (dispatch) => {
  const response = await Api({
    method: 'get',
    url: '/authentications/get_user_types',
  });
  if (response.success) {
    if (response.user_types.length) {
      const payload = {
        userDetails: response.user_details,
        userTypes: response.user_types,
        showSmsBalance: response.show_send_sms,
        isAdmin: response.is_admin,
      };
      dispatch({
        type: UPDATE_AUTH,
        payload,
      });
    }
  }
};

export function redirectToParentPortal(badgeId, testId, sampleTestId) {
  return () => {
    const isServer = typeof window === 'undefined';
    if (typeof testId !== 'undefined') {
      const testIdToRedirect = (typeof sampleTestId !== 'undefined') ? sampleTestId : testId;
      if (!isServer) {
        window.open(`${getParentPortalDomain()}/test_report/${testIdToRedirect}?badge_id=${badgeId}`);
      }
    } else if (!isServer) {
      window.open(`${getParentPortalDomain()}?badge_id=${badgeId}`);
    }
  };
}
export function changeUserPassword(payload, passwordConfirmation) {
  return (dispatch) => {
    const message = 'password didnt match';
    if (payload.password !== passwordConfirmation) {
      dispatch(showToast(message));
    } else {
      return Api({
        method: 'post',
        url: '/authentications/change_password',
        data: { secret: encrypt(payload) },
      }).then(() => {
        history.push(`${getAccountsDomain()}/logout`);
      });
    }
    return {};
  };
}
export function verifyUserPassword(payload) {
  return (dispatch) => {
    let isValidPassword = false;
    let message = 'Enter a Valid Password';
    if (payload.password && payload.password.length > 0) {
      return Api({
        method: 'post',
        url: '/authentications/verify_password_for_institutes',
        data: { secret: encrypt(payload) },
      }).then((response) => {
        isValidPassword = response.is_valid_password;
        message = response.message;
        if (!isValidPassword) {
          dispatch(showToast(message));
        }
        return { isPasswordVerified: isValidPassword, resetToken: response.reset_password_token };
      });
    }
    dispatch(showToast(message));
    return { isPasswordVerified: isValidPassword };
  };
}

export function updateImage(removePicture, imageFile) {
  return (dispatch) => {
    dispatch({
      type: UPDATE_AUTH,
      payload: { isUpdating: true },
    });
    const apiData = removePicture ? {
      method: 'post',
      url: '/memberships/update_picture',
      data: { remove_picture: removePicture },
    } : {
      method: 'post',
      url: '/memberships/update_picture',
      attachment: imageFile,
      type: 'user_image',
    };
    return Api({ ...apiData }).then((response) => {
      if (response.success) {
        const payload = { userDetails: response.user_details };
        dispatch({
          type: UPDATE_PROFILE_PICTURE,
          payload,
        });
        dispatch(showToast(response.message));
      }
      dispatch({
        type: UPDATE_AUTH,
        payload: { isUpdating: false },
      });
      return response;
    });
  };
}

export function logout() {
  return (dispatch) => {
    Object.keys(cookies.getAll()).forEach((cookieName) => {
      cookies.remove(cookieName,
        { secure: Constants().cookie.secure, domain: Constants().cookie.domainRegex });
      cookies.remove(cookieName, { path: '/', secure: Constants().cookie.secure });
    });
    localStorage.removeItem('persist:authType');
    dispatch({
      type: LOGOUT,
    });
    const isServer = typeof window === 'undefined';
    if (!isServer) {
      history.push(`${getAccountsDomain()}/logout`);
    }
  };
}
