export const CLASSES = 'All Classes';
export const CAMPUSES = 'All Campuses';
export const TEST_TYPE = 'All TestTypes';
export const SUBJECTS = 'All Subjects';
export const SUBJECT_SELECTED = 'subjectSelected';
export const TEST_TYPE_SELECTED = 'testTypeSelected';
export const TRACK_SELECTED = 'trackSelected';
export const TESTS = 'tests';
export const TEST_SELECTED = 'testSelected';
export const TEACHERS = 'teachers';
export const TEACHER_SELECTED = 'teacherSelected';

export const FILE_TYPE = {
  EXCEL: 1,
  PDF: 2,
};

export const TEST_OVERVIEW_COMPLEXITY = ['', 'Easy', 'Easy', 'Medium', 'Difficult', 'Difficult'];
