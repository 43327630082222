import Api from '../helpers/Api';
import {
  IMAGE_UPDATE_QUICKPOINT,
} from '../constants';

export const GET_LIST = 'quickpoints/GET_LIST';
export const UPDATE_QUICK_POINTS = 'quickpoints/UPDATE';
export const DELETE_QUICK_POINTS = 'quickpoints/DELETE';
export const ORDER_QUICK_POINTS = 'quickpoints/ORDER';
export const UPDATE_PAGE_NUMBER = 'quickpoints/PAGE_NUMBER';
export const CHECKPOINT_CHAPTER_LIST = 'quickpoints/CHECKPOINT_CHAPTER_LIST';
export const UPDATE_LOADER = 'quickpoints/UPDATE_LOADER';

export function getQuickpointList({
  checkpoint, subject, chapter, test,
}) {
  return (dispatch) => {
    dispatch({ type: UPDATE_LOADER, payload: { fetching: true } });
    let url = '/quickpoints?get_all=true';
    url += chapter ? `&chapter_id=${chapter}` : '';
    const additional = {};
    if (chapter) additional.selectedChapter = chapter;
    if (checkpoint) additional.selectedCheckpoint = checkpoint;
    if (subject) additional.selectedSubject = subject;
    if (test) additional.testType = test;
    return Api({
      method: 'get',
      url,
    }).then((response) => {
      dispatch({
        type: GET_LIST,
        payload: response,
        additional,
      });
      return response;
    });
  };
}

export function addQuickpoint({
  title, text, supportingText, supportingPicture,
  configuration: { image_size, show_text, show_image }, checkpoints,
}) {
  return (dispatch) => {
    dispatch({ type: UPDATE_LOADER, payload: { updating: true } });
    return Api({
      method: 'post',
      url: '/quickpoints',
      attachment: supportingPicture,
      type: 'supporting_picture',
      data: {
        title,
        text,
        supporting_text: supportingText,
        checkpoints: JSON.stringify(checkpoints.map(({ value }) => value)),
        configuration: JSON.stringify({ image_size, show_text, show_image }),
      },
    }).then((response) => {
      dispatch({ type: UPDATE_QUICK_POINTS, payload: response });
      return response.success;
    });
  };
}

export function updateQuickpoint({
  editId: id, title, text, supportingText, supportingPicture,
  configuration: { image_size, show_text, show_image }, checkpoints, imageUpdate,
}) {
  return (dispatch) => {
    dispatch({ type: UPDATE_LOADER, payload: { updating: true } });
    let attachment = {};
    const data = {
      title,
      text,
      supporting_text: supportingText,
      checkpoints: JSON.stringify(checkpoints.map(({ value }) => value)),
      configuration: JSON.stringify({ image_size, show_text, show_image }),
      image_update: imageUpdate,
    };
    if (imageUpdate === IMAGE_UPDATE_QUICKPOINT.UPDATED) {
      attachment = {
        attachment: supportingPicture,
        type: 'supporting_picture',
      };
    } else {
      data.supporting_picture = supportingPicture;
    }
    return Api({
      method: 'post',
      url: `/quickpoints/${id}/update`,
      ...attachment,
      data,
    }).then((response) => {
      dispatch({ type: UPDATE_QUICK_POINTS, payload: response });
      return response.success;
    });
  };
}

export function deprecateQuickpoint(id) {
  return (dispatch) => {
    dispatch({ type: UPDATE_LOADER, payload: { updating: true } });
    return Api({
      method: 'post',
      url: `/quickpoints/${id}/deprecate`,
      data: {},
    }).then((response) => {
      if (response.success) {
        dispatch({ type: DELETE_QUICK_POINTS, payload: id });
      } else {
        dispatch({ type: UPDATE_LOADER, payload: { updating: false, error: response.error } });
      }
    });
  };
}

export function getChapterCheckpointList(subjectId, testType) {
  return (dispatch) => {
    dispatch({ type: UPDATE_LOADER, payload: { updating: true } });
    return Api({
      method: 'get',
      url: `/checkpoints/get_list?subject_id=${subjectId}&test_type_id=${testType}`,
    }).then((response) => {
      dispatch({ type: CHECKPOINT_CHAPTER_LIST, payload: response, subjectId });
    });
  };
}

export function getQuickpointById(id) {
  return (dispatch) => {
    dispatch({ type: UPDATE_LOADER, payload: { updating: true } });
    return Api({
      method: 'get',
      url: `/quickpoints/${id}`,
    }).then((response) => {
      if (response.success) {
        dispatch({ type: UPDATE_LOADER, payload: { updating: false, editId: id } });
        return (response.data);
      }
      dispatch({ type: UPDATE_LOADER, payload: { updating: false, error: 'Quickpoint not found!' } });
      return null;
    });
  };
}

export function updatePageNumber(page) {
  return (dispatch) => {
    dispatch({
      type: UPDATE_PAGE_NUMBER,
      payload: page,
    });
  };
}

export function updateCheckpointOrder(orderList) {
  return (dispatch, getState) => {
    dispatch({ type: UPDATE_LOADER, payload: { updating: true } });
    const { selectedChapter } = getState().quickpoints;
    return Api({
      method: 'post',
      url: `/quickpoints/order?chapter_id=${selectedChapter}`,
      data: { quickpoint_ids: JSON.stringify(orderList) },
    }).then((response) => {
      if (response.success) {
        dispatch({ type: ORDER_QUICK_POINTS, payload: { ids: orderList } });
      } else {
        dispatch({ type: UPDATE_LOADER, payload: { updating: false, error: response.error } });
      }
    });
  };
}
