import _ from 'lodash';
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import ReactTooltip from 'react-tooltip';

import ReportError from '../modal/reportError';
import QuestionView from '../questionView';
import Api from '../../helpers/Api';
import { getContentDomain } from '../../helpers/util';
import offlineTags from '../../static/images/offlinetags.svg';
import {
  DIFFICULTY_VALUE_MAPPING, DIFFICULTY, DIFFICULTY_COLOR,
  OUTER_WIDTH_QUESTON_OPTIONS, QUESTION_TYPES,
} from '../../constants';
import Constants from '../../shared/constants';
import { sendEvent } from '../../helpers/Analytics';
import reportError from '../../static/images/questionView/reportError.svg';
import solution from '../../static/images/questionView/solution.svg';
import '../../styles/toggle.css';
import Modal from '../modal/baseModal';

const constants = Constants();
const numericalStyle = {
  fontSize: '12px', marginLeft: 20, fontFamily: 'Nunito', fontWeight: 600, marginBottom: 10, marginRight: 20, width: '100%',
};
const editButton = {
  fontWeight: 'bold', fontSize: 12, width: '100px', color: '#F16F6F', cursor: 'pointer', border: '1px solid #F16F6F', padding: 5, borderRadius: '3px', textAlign: 'center',
};

class QuestionList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      solutionQuestionIds: [],
      selectedComprehensionId: '',
      selectedQuestionId: '',
      topElement: {},
      showSectionModal: false,
    };
    this.scrollRef = React.createRef();
    const { topElement } = this.state;
    Object.keys(props.comprehensionQuestionIndexMapping).map((id) => {
      this[`comprehension-${id}`] = React.createRef();
    });
    if (props.questions.length > 0) {
      const isComprehension = props.questions[0].comprehension_question_id;
      topElement.type = isComprehension ? 'comprehension' : 'question';
      topElement.id = isComprehension || 1;
    }
    this.setState({ topElement: { ...topElement } });
    this.questionSolutionViewed = this.questionSolutionViewed.bind(this);
    this.isPresentInSearch = this.isPresentInSearch.bind(this);
  }

  getElement(type, id) {
    return (this[`${type}-${id}`] && this[`${type}-${id}`].current);
  }

  closeSectionModal() {
    this.setState({ showSectionModal: false, selectedQuestionId: '' });
  }

  questionSolutionViewed(questionId) {
    const { match } = this.props;
    Api({
      method: 'post',
      url: '/user_activity/capture_question_solution_viewed_event',
      data: { question_id: questionId, test_id: match.params.id },
    });
  }

  changeShowSolutionState(questionId) {
    const { solutionQuestionIds } = this.state;
    const { showReviewPage } = this.props;
    const questionIndex = solutionQuestionIds.indexOf(questionId);
    if (questionIndex > -1) {
      solutionQuestionIds.splice(questionIndex, 1);
    } else {
      solutionQuestionIds.push(questionId);
    }
    this.setState({ solutionQuestionIds }, () => {
      if (questionIndex > -1) {
        this.questionSolutionViewed(questionId);
        sendEvent('Assignment', 'ShowSolution', 'Click', { pageType: showReviewPage ? 'REVIEW_PAGE' : 'PICK_QUESTION_PAGE' });
      }
    });
  }

  pickQuestion(question) {
    const { questionIdsLoading, selectedTestGroup } = this.props;
    const buttonWidth = 100;
    if (questionIdsLoading.indexOf(question.id) > -1) {
      return (
        <div className="Button primary-Button mb-3" style={{ width: buttonWidth, backgroundColor: constants.color.primary, color: 'white' }}><i className="fa fa-circle-o-notch fa-spin" /></div>
      );
    }
    return (
      <div
        role="presentation"
        className="Button primary-Button mb-3"
        style={{
          width: buttonWidth, backgroundColor: constants.color.primary, color: 'white', fontWeight: 'bold', padding: 5, fontSize: 12,
        }}
        onClick={() => (selectedTestGroup.is_new_neet_pattern ? this.setState({ showSectionModal: true, selectedQuestionId: question.id }) : this.props.updateQuestions(question, 'addQuestion'))}
      >
        Pick
      </div>
    );
  }

  addOrRemoveQuestion(question) {
    const { isTrial, questionIdsLoading, assignmentQuestionIds } = this.props;
    const buttonWidth = 100;
    if (assignmentQuestionIds.includes(question.id)) {
      return (
        (questionIdsLoading.indexOf(question.id) > -1)
          ? (
            <div
              className="Button btn btn-outline-warning btn-lg mb-3"
              style={{
                width: buttonWidth, border: '1px solid #ff9300', backgroundColor: '#f9e8d2', color: '#ff9300', fontWeight: 'bold', padding: 5, fontSize: 12,
              }}
            >
              <i className="fa fa-circle-o-notch fa-spin" />
            </div>
          )
          : (
            <div
              role="presentation"
              data-tip={isTrial ? 'Trial version does not let you remove questions' : ''}
              className="Button btn-outline-warning btn-lg mb-3"
              style={{
                width: buttonWidth, border: '1px solid #ff9300', backgroundColor: '#f9e8d2', color: '#ff9300', fontWeight: 'bold', padding: 5, fontSize: 12,
              }}
              onClick={() => !isTrial && this.props.updateQuestions(question, 'removeQuestion')}
            >
              Remove
            </div>
          )
      );
    }
    return this.pickQuestion(question);
  }

  displayItems() {
    const items = [];
    let searchResults = 0;
    let innerWidthOfWindow = null;
    const {
      questions, isTrial, isUserSuggestionAllowed, showReviewPage, assignmentQuestionIds,
      questionIdsLoading, numericalQuestionIds, isMobileView, showOnlyQuestionText, isReviewed,
      loadingState, renderMoreQuestions, router, sectionWiseQuestions,
    } = this.props;
    if (typeof window !== 'undefined') {
      innerWidthOfWindow = window.innerWidth;
    }
    items.push(questions.map((question, index) => {
      this[`question-${index + 1}`] = React.createRef();
      const questionSelected = !showReviewPage && assignmentQuestionIds.includes(question.id);
      const isNumericalQuestion = numericalQuestionIds.indexOf(question.id) > -1;
      const showLoader = (questionIdsLoading.indexOf(question.id) > -1);
      const isQuestionSelected = assignmentQuestionIds.includes(question.id);
      const text = isQuestionSelected ? 'Remove Question' : 'Pick Question';
      const color = isQuestionSelected ? '#FFAD31' : constants.color.primary;
      if (!isMobileView && this.isPresentInSearch(question.question_text)) {
        searchResults += 1;
      }

      if (isMobileView) {
        const { solutionQuestionIds } = this.state;
        const { selectedTestGroup } = this.props;
        return (
          <>
            {question.comprehension_question_id && this.renderComprehension(question, question.comprehension_question_id, index + 1)}
            <li className="list-group-item" ref={this[`question-${index + 1}`]} key={`question_id_${question.id}`} style={{ margin: '5px 0px 5px 0px', backgroundColor: questionSelected ? '#e5efdb' : 'white', width: window.innerWidth - 40 }}>
              {showLoader && (
                <div style={{
                  color, top: 0, bottom: 0, left: 0, right: 0, display: 'flex', alignItems: 'center', justifyContent: 'center', position: 'absolute', zIndex: 5, backgroundColor: 'white',
                }}
                >
                  <i className="fa fa-circle-o-notch fa-spin" />
                </div>
              )}
              <div>
                <QuestionView
                  questionSolutionViewed={this.questionSolutionViewed}
                  question={question}
                  isNumericalQuestion={isNumericalQuestion}
                  showOnlyQuestionText={showOnlyQuestionText}
                  showAnswer
                  maxAllowedWidthForOptions={innerWidthOfWindow - OUTER_WIDTH_QUESTON_OPTIONS}
                  showSolution={(solutionQuestionIds.indexOf(question.id) > -1)}
                  index={index}
                  isMobileView
                />
                <div style={{ display: 'flex', padding: '10px 10px 10px 30px' }}>
                  {!isReviewed && (
                    <div
                      role="presentation"
                      style={{
                        color, width: 160, height: 20, display: 'flex', alignItems: 'center', borderRight: '1px solid #ddd'
                      }}
                      onClick={() => {
                        if (isTrial) {
                          alert('Trial version does not let you manually add or remove questions. For full access contact support.');
                          return;
                        }
                        if (selectedTestGroup.is_new_neet_pattern && !isQuestionSelected) {
                          this.setState({ showSectionModal: true, selectedQuestionId: question.id });
                          return;
                        }
                        this.props.updateQuestions(question, isQuestionSelected ? 'removeQuestion' : 'addQuestion');
                      }}
                    >
                      {text}
                    </div>
                  )}
                  <div style={{
                    display: 'flex', justifyContent: 'center', borderRight: '1px solid #ddd', padding: '0px 20px',
                  }}
                  >
                    <img role="presentation" alt="Report Error" src={reportError} width="20px" height="20px" onClick={() => this.props.showReportModal(question.id)} />
                  </div>
                  <div
                    role="presentation"
                    style={{
                      display: 'flex', justifyContent: 'center', borderRight: '1px solid #ddd', padding: '0px 20px',
                    }}
                    onClick={() => this.changeShowSolutionState(question.id)}
                  >
                    <img alt="Solution" src={solution} width="20px" height="20px" />
                  </div>
                </div>
              </div>
            </li>
          </>
        );
      } if ((showReviewPage && this.isPresentInSearch(question.question_text)) || !showReviewPage) {
        return (
          <>
            {question.comprehension_question_id
              && this.renderComprehension(question, question.comprehension_question_id, index + 1)}
            <li className="list-group-item mr-5" ref={this[`question-${index + 1}`]} key={`question_id_${question.id}`} style={{ margin: 2, backgroundColor: questionSelected ? '#e5efdb' : 'white' }}>
              <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <div
                  className="row mt-2"
                  style={numericalStyle}
                >
                  {question.tags.difficulty && (question.tags.difficulty !== 0) && (
                    <div style={{
                      color: DIFFICULTY_COLOR[DIFFICULTY_VALUE_MAPPING[question.tags.difficulty]], borderColor: DIFFICULTY_COLOR[question.tags.difficulty], textAlign: 'center', marginRight: '0.7%', height: '24px', width: '24px', borderStyle: 'solid', borderWidth: '1px', borderRadius: '50%', lineHeight: '22px',
                    }}
                    >
                      {DIFFICULTY[DIFFICULTY_VALUE_MAPPING[question.tags.difficulty]]}
                    </div>
                  )}
                  {question.tags.dates && question.tags.dates.assignment
                    && question.tags.dates.assignment.map((assignmentDate) => (
                      this.renderTags('#FF1493', assignmentDate, 'Assignment', question.id)
                    ))}
                  {question.tags.dates
                    && question.tags.dates.test && question.tags.dates.test.map((dates) => (
                    this.renderTags('#5FB5FF', dates, 'Test', question.id)
                  ))}
                  {(question.tags.previousPapers)
                    && (
                      <>
                        {question.tags.previousPapers.map((type) => (
                          this.renderTags('#A054A0', type, 'previousPaper')
                        ))}
                      </>
                    )}
                  {(question.tags.offline)
                    && (
                      <div
                        data-tip="Question has appeared in Student App (Mentor Board)"
                        style={{
                          width: '13px', height: '24px', borderRadius: '1px', background: `url(${offlineTags})`, opacity: 1,
                        }}
                      />
                    )}
                  {question.question_type === QUESTION_TYPES.NUMERICAL
                    && !isReviewed && this.renderSlider(question.id, isNumericalQuestion)}
                </div>
                {question.comprehension_question_id && this.renderComprehensionId(question.comprehension_question_id, index + 1)}
              </div>
              <div style={{
                display: 'flex', paddingRight: 20, paddingLeft: 20, justifyContent: 'space-between',
              }}
              >
                <div style={{ width: '100%' }}>
                  <QuestionView questionSolutionViewed={this.questionSolutionViewed} question={question} showOnlyQuestionText={showOnlyQuestionText} isNumericalQuestion={isNumericalQuestion} showAnswer maxAllowedWidthForOptions={innerWidthOfWindow - OUTER_WIDTH_QUESTON_OPTIONS} showSolution printPage={false} index={index} pageType={this.props.showReviewPage ? 'REVIEW_PAGE' : 'PICK_QUESTION_PAGE'} />
                </div>
                <div style={{ width: 'fit-content', paddingLeft: 20, alignSelf: 'center' }}>
                  {!_.isEmpty(sectionWiseQuestions) && (
                    <div style={{ textAlign: 'center', padding: '5px 0px' }}>
                      {sectionWiseQuestions.A.includes(question.id) && <div>Section A</div>}
                      {sectionWiseQuestions.B.includes(question.id) && <div>Section B</div>}
                    </div>
                  )}
                  {!isReviewed && this.addOrRemoveQuestion(question)}
                  {isUserSuggestionAllowed || question.can_edit_institute_question
                    ? (
                      <a href={`${getContentDomain()}/questions/edit/${question.id}`} target="_blank" style={{ textDecoration: 'none' }}>
                        <div style={editButton}>
                          {question.can_edit_institute_question ? 'Edit' : 'Report Error'}
                        </div>
                      </a>
                    )
                    : <ReportError questionId={question.id} router={router} />}
                </div>
              </div>
            </li>
          </>
        );
      }
    }));
    if (loadingState) {
      items.push(
        <div className="container m-5 pb-5" style={{ textAlign: 'center', fontSize: '2vw' }}>
          Loading...
          <i className="fa fa-circle-o-notch fa-spin" />
        </div>,
      );
    }
    if (!renderMoreQuestions || (showReviewPage && (searchResults === 0))) {
      items.push(
        <div
          className="m-5"
          style={{
            textAlign: 'center', color: '#ccc', padding: 10, fontSize: 20, fontWeight: 'bold',
          }}
        >
          No more questions.
        </div>,
      );
    }
    return items;
  }

  isPresentInSearch(questionText) {
    const { searchString } = this.props;
    return (questionText.toUpperCase().indexOf(searchString.toUpperCase()) >= 0);
  }

  scrollToComprehensionQuestion(comprehensionId, questionIndex) {
    this.setState({ selectedComprehensionId: comprehensionId, selectedQuestionId: questionIndex });
    this.scrollTo('comprehension', comprehensionId);
  }

  scrollTo(type, id) {
    const element = this.getElement(type, id);
    const { topElement } = this.state;
    const topElementData = this.getElement(topElement.type, topElement.id);
    if (element && topElementData) {
      this.scrollRef.current.scrollTo({
        top: element.offsetTop - (topElementData.offsetTop),
        behavior: 'smooth',
      });
    }
  }

  renderComprehensionId(comprehensionId, questionIndex) {
    return (
      <div
        style={{
          ...numericalStyle,
          fontWeight: 'bold',
          width: 'auto',
          fontSize: 14,
          minWidth: 300,
          display: 'flex',
          justifyContent: 'flex-end',
          cursor: 'pointer',
        }}
        role="presentation"
        onClick={() => {
          this.scrollToComprehensionQuestion(comprehensionId, questionIndex);
        }}
      >
        <b>
          Comprehension ID :&nbsp;&nbsp;
          {comprehensionId}
          &nbsp;&nbsp;
          <b style={{ fontSize: 28, border: 0 }}>&#10554;</b>
        </b>
      </div>
    );
  }

  renderComprehension(question, comprehensionQuestionId, questionIndex) {
    const { comprehensionData, comprehensionQuestionIndexMapping, isMobileView } = this.props;
    const { selectedComprehensionId, selectedQuestionId } = this.state;
    if (comprehensionData[comprehensionQuestionId]
      && comprehensionQuestionIndexMapping[comprehensionQuestionId][0] === questionIndex) {
      return (
        <div
          className="list-group-item mr-5"
          ref={this[`comprehension-${comprehensionQuestionId}`]}
          style={{
            backgroundColor: '#ECECEE', marginTop: 5, marginBottom: 5, borderRadius: 4, padding: '20px 32px 30px', display: 'flex', margin: 2,
          }}
        >
          <div style={{ marginRight: 20, width: '100%' }}>
            <div style={{ fontWeight: 'bold', flexWrap: 'wrap', marginBottom: 10 }}>
              {comprehensionData[comprehensionQuestionId].comprehension_text}
            </div>
            {comprehensionData[comprehensionQuestionId].image_url
              && <img alt="comprehensionSupportingPicture" src={comprehensionData[comprehensionQuestionId].image_url} width="140" height="auto" />}
            <div style={{
              fontWeight: 'bold', color: '#9EA0A5', fontSize: 14, marginTop: 20,
            }}
            >
              The following questions belong to this comprehension (Id :&nbsp;&nbsp;
              <b style={{ fontFamily: 'Nunito', color: (selectedComprehensionId === comprehensionQuestionId) && '#000000' }}>{comprehensionQuestionId}</b>
              )
              - &nbsp;&nbsp;
              {comprehensionQuestionIndexMapping[comprehensionQuestionId].map((id, index) => {
                return (
                  <b
                    style={{
                      fontFamily: (id === selectedQuestionId) && 'Nunito',
                      color: (id === selectedQuestionId) && constants.color.primary,
                      cursor: 'pointer',
                    }}
                    role="presentation"
                    onClick={() => {
                      this.scrollTo('question', id);
                    }}
                  >
                    {index !== 0 && <b>,&nbsp;&nbsp;</b>}
                    {id}
                  </b>
                );
              })}
            </div>
          </div>
          {question.can_edit_institute_question && !isMobileView
            && (
              <a href={`${getContentDomain()}/comprehension_questions/edit/${comprehensionQuestionId}`} target="_blank" style={{ textDecoration: 'none' }}>
                <div style={{ ...editButton, height: 'fit-content' }}>
                  Edit
                </div>
              </a>
            )}
        </div>
      );
    }
    return <div />;
  }

  renderSectionModal() {
    const { questions } = this.props;
    const { showSectionModal, selectedQuestionId } = this.state;
    return (
      <Modal
        showModal={showSectionModal}
        handleCloseModal={() => this.closeSectionModal()}
        header="Select section"
      >
        <div style={{
          flex: 1,
          padding: 15,
          marginTop: 10,
          display: 'flex',
          justifyContent: 'center',
        }}
        >
          <div
            role="presentation"
            className="Button primary-Button mb-3"
            style={{
              width: 100, backgroundColor: constants.color.primary, color: 'white', fontWeight: 'bold', padding: 5, fontSize: 12, marginRight: 15,
            }}
            onClick={() => {
              this.props.updateQuestions(questions.find((question) => question.id === selectedQuestionId), 'addQuestion', 'A');
              this.closeSectionModal();
            }}
          >
            Section A
          </div>
          <div
            role="presentation"
            className="Button primary-Button mb-3"
            style={{
              width: 100, backgroundColor: constants.color.primary, color: 'white', fontWeight: 'bold', padding: 5, fontSize: 12,
            }}
            onClick={() => {
              this.props.updateQuestions(questions.find((question) => question.id === selectedQuestionId), 'addQuestion', 'B');
              this.closeSectionModal();
            }}
          >
            Section B
          </div>
        </div>
      </Modal>
    );
  }

  renderTags(color, text, type) {
    return (
      <div
        data-tip={type}
        style={{
          color, marginRight: '0.7%', borderRadius: '15px', padding: '1px 9px', textAlign: 'center', height: '24px', borderStyle: 'solid', borderWidth: '1px', lineHeight: '22px',
        }}
      >
        {text}
      </div>
    );
  }

  renderSlider(questionId, isNumericalQuestion) {
    return (
      <label style={{
        display: 'flex',
        alignItems: 'center',
        width: 160,
        justifyContent: 'space-between',
        marginLeft: 'auto',
      }}
      >
        <b> Numerical Question </b>
        <div className={`react-toggle ${isNumericalQuestion && 'react-toggle--checked'}`}>
          <div className="react-toggle-track">
            <div className="react-toggle-track-check" />
            <div className="react-toggle-track-x" />
          </div>
          <div className="react-toggle-thumb" />
          <input className="react-toggle-screenreader-only" type="checkbox" style={{ backgroundColor: 'white' }} onClick={() => this.props.toggleNumericalQuestionState(questionId)} />
        </div>
      </label>
    );
  }

  render() {
    const { showPageLoading } = this.props;
    const { showSectionModal } = this.state;
    if (showPageLoading) {
      return (
        <div
          style={{
            left: 0,
            top: 0,
            width: '100%',
            backgroundColor: 'red',
            height: '70%',
            background: "url('/static/images/loader.gif') center no-repeat #fff",
          }}
        />
      );
    }
    return (
      <div ref={this.scrollRef} style={{ height: '100%', overflowY: 'scroll', userSelect: 'none' }}>
        <ReactTooltip />
        <ul id="Menu" className="list-group">
          {this.displayItems()}
          {showSectionModal && this.renderSectionModal()}
        </ul>
      </div>
    );
  }
}

const mapStateToProps = ({ assignment }) => ({
  questions: assignment.questions,
  comprehensionData: assignment.comprehensionData,
  assignmentQuestionIds: assignment.assignmentQuestionIds,
  renderMoreQuestions: assignment.renderMoreQuestions,
  questionIdsLoading: assignment.questionIdsLoading,
  numericalQuestionIds: assignment.numericalQuestionIds,
  sectionWiseQuestions: assignment.sectionWiseQuestions,
  selectedTestGroup: assignment.selectedTestGroup,
  comprehensionQuestionIndexMapping: assignment.comprehensionQuestionIndexMapping,
  isReviewed: assignment.testInfo.is_reviewed,
  isTrial: assignment.isTrial,
  isUserSuggestionAllowed: assignment.isUserSuggestionAllowed,
});

QuestionList.propTypes = {
  comprehensionQuestionIndexMapping: PropTypes.object,
  questions: PropTypes.object,
  router: PropTypes.object,
  renderMoreQuestions: PropTypes.bool,
  numericalQuestionIds: PropTypes.array,
  isMobileView: PropTypes.bool,
  comprehensionData: PropTypes.object,
  isUserSuggestionAllowed: PropTypes.bool,
  isReviewed: PropTypes.bool,
  isTrial: PropTypes.bool,
  showReviewPage: PropTypes.bool,
  loadingState: PropTypes.bool,
  showPageLoading: PropTypes.bool,
  showOnlyQuestionText: PropTypes.bool,
  questionIdsLoading: PropTypes.array,
  assignmentQuestionIds: PropTypes.array,
  searchString: PropTypes.string,
  sectionWiseQuestions: PropTypes.object,
  selectedTestGroup: PropTypes.object,
};

QuestionList.defaultProps = {
  comprehensionQuestionIndexMapping: {},
  numericalQuestionIds: [],
  router: {},
  renderMoreQuestions: false,
  isReviewed: false,
  showOnlyQuestionText: false,
  loadingState: false,
  isMobileView: false,
  isUserSuggestionAllowed: false,
  comprehensionData: {},
  showReviewPage: false,
  questions: {},
  isTrial: false,
  showPageLoading: false,
  questionIdsLoading: [],
  assignmentQuestionIds: [],
  searchString: '',
  sectionWiseQuestions: {},
  selectedTestGroup: {},
};

export default connect(mapStateToProps)(QuestionList);
