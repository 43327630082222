import Api from '../helpers/Api';
import { showToast } from '.';

export const GET_CLASSROOM_TEACHERS = 'classroomTeachers/GET_CLASSROOM_TEACHERS';

export function getClassroomTeacher() {
  return (dispatch) => Api({
    method: 'get',
    url: '/classroom_teachers/get_classroom_teachers',
  }).then((response) => {
    if (response.success) {
      dispatch({
        type: GET_CLASSROOM_TEACHERS,
        payload: {
          campuses: response.campuses,
          classrooms: response.classrooms,
          subjects: response.subjects,
          classroomTeachers: response.classroom_teachers,
          currentClassroomID: response.current_classroom_id,
          allTeachers: response.all_teachers,
        },
      });
    }
    return response;
  });
}

export function getSpecificClassroomTeacher(classroomID) {
  return (dispatch) => Api({
    method: 'get',
    url: `/classroom_teachers/get_specific_classroom_teachers?classroom_id=${classroomID}`,
  }).then((response) => {
    if (response.success) {
      dispatch({
        type: GET_CLASSROOM_TEACHERS,
        payload: {
          classroomTeachers: response.classroom_teachers,
        },
      });
      return response;
    }
  });
}

export function updateClassroomTeacher(data, classroomID) {
  Api({
    method: 'post',
    url: '/classroom_teachers/update_classroom_teachers',
    data: { data: JSON.stringify(data), classroomID },
  }).then((response) => {
    if (response.success) {
      this.props.dispatch(showToast('Classroom Teacher Updated', 5000));
    } else {
      this.props.dispatch(showToast('Update failed', 5000));
    }
  });
}
