import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import TimePicker from 'rc-time-picker';
import 'rc-time-picker/assets/index.css';
import './timePicker.css';

const DeliTimePicker = ({
  className, onChange, value, use12Hours, minuteStep, ...rest
}) => (
  <TimePicker
    {...rest}
    className={className}
    popupClassName={className}
    showSecond={false}
    onChange={onChange}
    hideDisabledOptions
    minuteStep={minuteStep || 5}
    value={value || moment().startOf('day')}
    use12Hours={use12Hours}

  />
);

DeliTimePicker.propTypes = {
  className: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  use12Hours: PropTypes.bool,
  minuteStep: PropTypes.number,
  value: PropTypes.instanceOf(moment),
};

export default DeliTimePicker;
