import axios from 'axios';
import queryString from 'query-string';
import Cookies from 'universal-cookie';
import { createBrowserHistory } from 'history';
import { getHomeDomain, getAccountsDomain } from './util';

import { logout } from '../actions';
import Constants from '../shared/constants';

const history = createBrowserHistory({
  forceRefresh: true,
});

const cookies = new Cookies();
global.token = '';

const environment = process.env.REACT_APP_ENV;

export const getToken = () => {
  if (typeof global.token !== 'undefined' && global.token && global.token !== '') {
    return `bearer ${global.token}`;
  }
  const token = cookies.get('token');
  if (token) {
    return `bearer ${cookies.get('token')}`;
  }
  return '';
};

export const getUser = () => {
  if (typeof global.user !== 'undefined' && global.user && global.user !== '') {
    return global.user;
  }
  const user = cookies.get('user');
  return user || {};
};

const apiHeader = () => {
  let header = { communityAppVersionCode: Constants().communityAppVersionCode };
  header = { ...header, Authorization: getToken() };
  return header;
};

const client = axios.create({
  baseURL: Constants().api.url,
  responseType: Constants().api.responseType,
  timeout: Constants().api.timeout,
  withCredentials: Constants().api.withCredentials,
  headers: apiHeader(),
});

const clearCookies = () => {
  global.token = '';
  cookies.set('token', '', { path: '/', secure: Constants().cookie.secure });
  cookies.set('user', {}, { path: '/', secure: Constants().cookie.secure });
  logout();
};

export const setGlobalData = (token, user) => {
  global.token = token;
  global.user = user;
};

const Api = (options) => {
  const onSuccess = (response) => {
    const isServer = typeof window === 'undefined';
    if (response.status === 403) {
      clearCookies();
    }
    if (!isServer && response.status === 406) {
      if (response.data.redirect_domain) {
        window.location.href = response.data.redirect_domain;
      } else {
        window.location.href = getHomeDomain();
      }
    }
    if (response.status === 203 && !options.disableRedirect) {
      if (!isServer) {
        window.location.replace('/accessDenied');
      }
    }
    if (environment !== 'production') {
      console.log('Request Successful!', 'response');
    }
    return response.data;
  };

  const onError = (error) => {
    if (error.response && error.response.status === 503) {
      if (error.response.data) {
        window.location.replace('/notifyServerMaintenance');
      }
    }
    if (error.response && error.response.status === 401) {
      if (error.response.data && error.response.data.type && error.response.data.type === 'Membership Expired') {
        window.location.replace('/trialExpired');
      } else if (window) {
        // window.location.replace(`${getAccountsDomain()}/logout`);
      }
    }
    if (error.response && error.response.status === 406) {
      if (error.response.data && error.response.data.redirect_domain) {
        window.location.href = error.response.data.redirect_domain;
      } else {
        window.location.href = getHomeDomain();
      }
    }
    if (environment !== 'production') {
      console.log('Request Failed:', error.config);

      if (error.response) {
        // Request was made but server responded with something
        // other than 2xx
        console.log('Status:', error.response.status);
        console.log('Data:', error.response.data);
        console.log('Headers:', error.response.headers);
      } else {
        // Something else happened while setting up the request
        // triggered the error
        console.log('Error Message:', error.message);
      }
    }

    return error.response || error.message;
  };

  const onflyoptions = { headers: apiHeader() };

  const isServer = typeof window === 'undefined';
  if (isServer) {
    return client({ ...options, ...onflyoptions })
      .then(onSuccess)
      .catch(onError);
  }

  if (options.method === 'post') {
    if (options.attachment) {
      const config = { headers: { 'Content-Type': 'multipart/form-data' }, onUploadProgress: options.onUploadProgress };
      const formData = new FormData();
      const attachmentLength = options.attachment.length ? options.attachment.length : 1;
      if (attachmentLength === 1 && (!options.attachment.length || options.type !== 'spreadsheet')) {
        formData.append(options.type, options.attachment);
      } else {
        options.attachment.forEach((file, index) => {
          formData.append(`${options.type}_${index + 1}`, file);
        });
      }
      if (options.data) {
        Object.keys(options.data).forEach((key) => {
          formData.set(key, options.data[key]);
        });
      }
      formData.set(`${options.type}_length`, attachmentLength);
      formData.set('token', getToken());
      formData.set('communityAppVersionCode', Constants().communityAppVersionCode);
      return axios.post(`${Constants().api.url}${options.url}`,
        formData,
        config).then(onSuccess)
        .catch(onError);
    }
    return axios({
      method: options.method,
      url: `${Constants().api.url}${options.url}`,
      data: options.data,
      transformRequest: [function (data) {
        data.token = getToken();
        data.communityAppVersionCode = Constants().communityAppVersionCode;
        return queryString.stringify(data);
      }],
    })
      .then(onSuccess)
      .catch(onError);
  }
  let url = `${Constants().api.url}${options.url}`;
  if (options.url.includes('?')) {
    url += `&token=${getToken()}&communityAppVersionCode=${Constants().communityAppVersionCode}`;
  } else {
    url += `?token=${getToken()}&communityAppVersionCode=${Constants().communityAppVersionCode}`;
  }
  return axios({
    method: options.method,
    url,
  })
    .then(onSuccess)
    .catch(onError);
};

export default Api;
