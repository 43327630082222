import React from 'react';
import PropTypes from 'prop-types';
import Constants from '../../shared/constants';
import mockups from '../../static/images/landingPage/mockups.svg';
import Layout from '../layout';
import GetInvite from '../modal/getInviteModal';
import speed from '../../static/images/landingPage/speed.svg';
import group from '../../static/images/landingPage/group.svg';
import bright from '../../static/images/landingPage/bright.svg';
import uploadCsv from '../../static/images/landingPage/upload_csv.svg';
import studentList from '../../static/images/landingPage/student_list.svg';
import questionList from '../../static/images/landingPage/question_list.svg';
import graphData from '../../static/images/landingPage/graph_data.svg';
import testGraph from '../../static/images/landingPage/test_graphs.svg';
import subjectComparision from '../../static/images/landingPage/subject_comparision.svg';
import stats from '../../static/images/landingPage/stats.svg';
import suman from '../../static/images/landingPage/suman.png';
import weeklyAssignment from '../../static/images/landingPage/weekly_assignment.png';

class LandingPage extends React.PureComponent {
  render() {
    return (
      <Layout globalHistory={this.props.history} feedback footerShow>
        <style jsx>
          {// language=CSS
          `
            .upper-box {
              display: inline-block;
              width: 100%;
              height: auto;
              padding-bottom: 2%;
            }
            .upper-box-1 {
              background: linear-gradient(to bottom, #F2C94C 80%, white 20%);
            }
            .working_hard {
              font-size: 48px;
              font-weight: 900;
              color: #231F20;
              line-height: 1.2;
            }
            .test_name {
              font-size: 30px;
              color: #535353;
              font-weight: normal;
            }
            .header {
              margin-top: 2%;
            }
            p {
              margin-top: 0px;
              margin-bottom: 0px;
            }
            .circularDiv {
              background-color: #F2C94C;
              border-radius: 70%;
              width: 30px;
              height: 30px;
              margin-bottom: 5px;
            }
            .subPoint {
              margin-top: 5%;
              display: -webkit-box;
            }
            .align-middle {
              display: flex;
              align-items: center;
            }
            .subHeader {
              color: #231F20;
              font-size: 22px;
              margin-top: 2%;
              margin-bottom: 0;
            }
            .line-circle {
              width: 100%;
              border: 1px dashed #F2C94C;
            }
            .header-line {
              width: 18%;
              border: 2px solid #F2C94C;
              marginTop: 0;
            }
            .sub-container {
              margin-top: 5%;
              background-color: #ffffff;
              box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.25);
              height: auto;
            }
            .sub-container-box {
              border-right: 1px solid #E5E5E5;
              padding: 5%;
            }
            .image-border {
              border: 1px dashed #F2C94C;
              padding: 5%;
              margin-top: -40px;
            }
            .image-border-paragraph {
               font-size: 15px;
               font-weight: bold;
               text-align: center;
               margin-bottom: 1%;
            }
            .invite-button {
              text-align: center;
              width: 50%;
              height: 60px;
              margin-top: 6%;
            }
            @media only screen and (max-width: 769px) {
              .row {
                padding: 5%;
              }
              p {
                text-align: center;
              }
              .header-line {
                width: 100%;
              }
              .circularDiv {
                display: none;
              }
              .line-circle {
                display: none;
              }
              .sub-container {
                margin-left: 5%;
                width: 90%;
              }
              .sub-container-box {
                border: 0;
                margin-bottom: 20px;
              }
              .working_hard {
                font-size: 45px;
                margin-top: 10%;
              }
              .image-border {
                margin-bottom: 10%;
                border: 0;
              }
              .header {
                display: table;
              }
              .invite-button {
                width: 60%;
              }
              .get-invite {
                display: flex;
              }
            }
          `
}
        </style>
        <div className="row">
          <div className="upper-box upper-box-1" style={{ paddingTop: '6%' }}>
            <div className="row offset-md-1">
              <div className="col-md-5 header">
                <p className="working_hard">
                  Detailed
                  <br />
                  test analysis.
                </p>
                <p className="test_name" style={{ marginTop: '3%' }}>Instant. In-depth. Incredible.</p>
                <div className="get-invite justify-content-center" style={{ marginTop: '5%', marginBottom: '15%' }}>
                  <div className="invite-button">
                    <GetInvite modal={false} bgcolor="#535353" width="100%" color="#F2C94C" product={Constants().products.dashboard} header="Get Invite" />
                  </div>
                </div>
              </div>
              <div className="col-md-6 justify-content-center">
                <img alt="" className="center" src={mockups} width="100%" />
              </div>
            </div>
            <div className="row">
              <div className="col-md-8 offset-md-2">
                <div className="row sub-container">
                  <div className="col-md-4 d-flex flex-column my-auto sub-container-box">
                    <img alt="" className="center" src={speed} style={{ height: 120, marginBottom: '5%' }} />
                    <p style={{ textAlign: 'center', fontSize: 18 }}>Save time and paper</p>
                  </div>
                  <div className="col-md-4 d-flex flex-column my-auto sub-container-box">
                    <img alt="" className="center" src={group} style={{ height: 120, marginBottom: '5%' }} />
                    <p style={{ textAlign: 'center', fontSize: 18 }}>Student performance</p>
                  </div>
                  <div className="col-md-4 d-flex flex-column my-auto" style={{ padding: '5%' }}>
                    <img alt="" className="center" src={bright} style={{ height: 120, marginBottom: '5%' }} />
                    <p style={{ textAlign: 'center', fontSize: 18 }}>Teacher efficiency</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="row" style={{ paddingTop: '5%', backgroundColor: '#ffffff' }}>
          <div className="col-md-11 offset-md-1">
            <p className="subHeader">HOW DOES IT WORK?</p>
            <hr className="header-line" align="left" />
          </div>
          <div className="row offset-md-1">
            <img alt="" className="col-md-2 center" src={uploadCsv} style={{ width: 300 }} />
            <div className="col-md-8 offset-md-1" style={{ marginTop: '3%' }}>
              <p style={{ fontSize: 18, fontWeight: 'bold' }}>+ Upload the Excel/CSV file after test.</p>
              <p style={{ fontSize: 18, marginLeft: '5%' }}>That’s it. We take it from there.</p>
            </div>
          </div>
        </div>
        <div className="row" style={{ paddingTop: '5%', backgroundColor: '#ffffff' }}>
          <div className="col-md-11 offset-md-1" style={{ marginBottom: '4%' }}>
            <p className="subHeader">THE BENEFITS YOU ENJOY</p>
            <hr className="header-line" align="left" />
          </div>
          <div className="col-md-7 offset-md-2 image-border">
            <p className="image-border-paragraph">Make thorough comparisons with a detailed, customisable rank list</p>
            <img alt="" src={studentList} style={{ width: '100%' }} />
          </div>
          <div className="col-md-2 my-auto align-middle" style={{ padding: 0 }}>
            <hr className="line-circle" align="left" />
            <div className="circularDiv" />
          </div>
          <div className="col-md-2 offset-md-1 my-auto align-middle" style={{ padding: 0, marginTop: -40 }}>
            <div className="circularDiv" />
            <hr className="line-circle" align="left" />
          </div>
          <div className="col-md-7 image-border">
            <p className="image-border-paragraph">Identify the topics that students are weak in</p>
            <img alt="" src={questionList} style={{ width: '100%' }} />
          </div>
          <div className="col-md-7 offset-md-2 image-border">
            <p className="image-border-paragraph">See where the batch stands, and which students need attention</p>
            <img alt="" src={graphData} style={{ width: '100%' }} />
          </div>
          <div className="col-md-2 my-auto align-middle" style={{ padding: 0, marginTop: -40 }}>
            <hr className="line-circle" align="left" />
            <div className="circularDiv" />
          </div>

        </div>
        <div className="row" style={{ paddingTop: '4%', backgroundColor: '#ffffff' }}>
          <p className="col-md-12" style={{ textAlign: 'center', fontSize: 22, marginBottom: '1%' }}>And a lot, lot more.</p>
          <div className="col-md-10 offset-md-1">
            <div className="row" style={{ padding: 0 }}>
              <div className="col-md-4" style={{ marginBottom: '5%' }}>
                <img alt="" src={testGraph} style={{ marginLeft: '10%', width: '80%' }} />
                <p style={{ textAlign: 'center', fontSize: 16 }}>PERFORMANCE TRENDS</p>
              </div>
              <div className="col-md-4" style={{ marginBottom: '5%' }}>
                <img alt="" src={subjectComparision} style={{ marginLeft: '10%', width: '80%' }} />
                <p style={{ textAlign: 'center', fontSize: 16 }}>INTRA-SUBJECT COMPARISION</p>
              </div>
              <div className="col-md-4" style={{ marginBottom: '5%' }}>
                <img alt="" src={stats} style={{ marginLeft: '10%', width: '80%' }} />
                <p style={{ textAlign: 'center', fontSize: 16 }}>STATS ON SPECIFIC GROUP</p>
              </div>
            </div>
          </div>
        </div>
        <div
          className="row"
          style={{
            backgroundColor: '#535353', borderBottom: '1px solid black', paddingBottom: '4%', paddingTop: '4%',
          }}
        >
          <div className="col-md-2 offset-md-2 d-flex">
            <img alt="" className="mx-auto center" src={suman} style={{ width: '70%', objectFit: 'contain' }} />
          </div>
          <div className="col-md-6" style={{ marginTop: '2%' }}>
            <p style={{ color: '#ffffff', fontSize: 18 }}>“I can tell the teachers directly what to focus on, without dozens of Excel sheets and long presentations.”</p>
            <p style={{
              fontWeight: 'bold', fontSize: 18, marginTop: '1%', color: '#ffffff',
            }}
            >
              <b>Bramaiah Manam</b>
              , Dean, Vision NEET Academy
            </p>
          </div>
        </div>
        <div className="row" style={{ marginBottom: '8%', marginTop: '8%' }}>
          <div className="col-md-5 offset-md-1">
            <img
              alt=""
              src={weeklyAssignment}
              style={{
                width: '100%', objectFit: 'contain', boxShadow: '0px 5px 10px rgba(0, 0, 0, 0.25)', borderRadius: 25,
              }}
            />
          </div>
          <div className="col-md-4 offset-md-1 justify-content-center" style={{ fontSize: 20, marginTop: '5%' }}>
            <p
              className="mx-auto"
              style={{
                color: '#535353', fontWeight: 'bold', fontSize: 30, width: '80%', textAlign: 'center', marginBottom: '8%',
              }}
            >
              It’s time to get more out of your exams.
            </p>
            <div className="row mt-5">
              <div className="col-md-4 offset-md-1 btn mx-auto">
                <GetInvite modal={false} bgcolor="#535353" color="#F2C94C" width="45%" width="100%" product={Constants().products.dashboard} header="Get Invite" />
              </div>
            </div>
          </div>
        </div>
      </Layout>
    );
  }
}

export default LandingPage;
