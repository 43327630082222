import devConfig from './development.json';
import prodConfig from './production.json';
import stagingConfig from './staging.json';
import baseConfig from './base.json';

export default () => {
  if (process.env.REACT_APP_ENV === 'production') {
    return { ...baseConfig, ...prodConfig };
  } if (process.env.REACT_APP_ENV === 'staging') {
    return { ...baseConfig, ...stagingConfig };
  }
  return { ...baseConfig, ...devConfig };
};
