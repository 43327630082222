export const IMAGE_SIZE = {
  SMALL: 1,
  MEDIUM: 2,
  LARGE: 3,
};

export const MOBILE_SCREEN_SIZE = {
  WIDTH: 350,
  HEIGHT: 550,
};

export const STATE_TYPE = {
  supportingPicture: 'supportixng_picture_size',
  hintSupportingPicture: 'hint_supporting_picture_size',
  solutionSupportingPicture: 'solution_supporting_picture_size',
  option1: 'image_option_1_size',
  option2: 'image_option_2_size',
  option3: 'image_option_3_size',
  option4: 'image_option_4_size',
};
