import React, { PureComponent } from 'react';
import HttpsRedirect from 'react-https-redirect';
import * as Sentry from '@sentry/browser';
import Cookies from 'universal-cookie';
import RouteNavigation from './components/routeNavigation';
import constants from './shared/constants';
import './App.css';
import '../node_modules/react-vis/dist/style.css';

const Constants = constants();
const cookies = new Cookies();

class App extends PureComponent {
  componentDidMount() {
    const userProfile = cookies.get('user');
    const publicPaths = ['/community', '/instituteLandingPage', '/landingPage', '/login', '/mobile', '/plans', '/privacy', '/studentReport', '/terms', '/studentDashboard'];
    if (!userProfile && !(publicPaths.indexOf(window.location.pathname) > -1)) {
      window.location.replace('/login');
    }
  }

  render() {
    const userProfile = cookies.get('user');
    Sentry.init({ dsn: Constants.sentryConnectionUri });
    if (Constants.cookie.secure) {
      return (
        <HttpsRedirect>
          <RouteNavigation userProfile={userProfile} />
        </HttpsRedirect>
      );
    }
    return (
      <RouteNavigation userProfile={userProfile} />
    );
  }
}

export default App;
