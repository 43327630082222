import React from "react";
import { exportComponentAsJPEG } from "react-component-export-image";
import moment from "moment";
import Ceal from "../../static/images/Ceal.jpg";
import Signature from "../../static/images/Signature.jpg";
import { isMobileDevice } from "../../helpers/util";

const IS_MOBILE = isMobileDevice();

class NOCFormat1 extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
    this.componentRef = React.createRef();
  }
  renderFormat() {
    const { fullName, address, poi, phoneNumber, proofNumber } =
      this.props.data;
    return (
      <div
        style={{
          width: !IS_MOBILE && 793,
          height: !IS_MOBILE && 1123,
          padding: 20,
        }}
        ref={this.componentRef}
      >
        <div
          style={{
            fontSize: 40,
            fontWeight: "bolder",
            color: "brown",
            textAlign: "center",
            borderBottom: "5px solid brown",
          }}
        >
          BLR ENVISION ACADEMY LLP
        </div>
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <div>Reliance Jio Infocomm Limited</div>
          <div style={{ textAlign: "right", marginRight: 20 }}>
            Date : {moment().format("DD/MM/YYYY")}
          </div>
        </div>
        <div style={{ fontWeight: 400 }}>
          Transfer of Cellular Phone Connection
        </div>
        <div style={{ fontSize: 14, marginTop: 10 }}>
          Declaration by the Transferee (Individual)
        </div>
        <div style={{ marginTop: 10 }}>
          <div>To,</div>
          <div>CE Head,</div>
          <div>Reliance Jio Infocomm Limited</div>
        </div>
        <div style={{ marginTop: 20 }}>
          <div>Sir/ Madam,</div>
          <div>
            I <strong>{fullName} </strong>residing in <strong>{address}</strong>{" "}
            bearing{" "}
            <strong>
              {poi.replace("_", " ")} {proofNumber}{" "}
            </strong>
            wish to transfer my cellular connection having mobile number{" "}
            <strong>{phoneNumber} </strong>
            transferred in my name.
          </div>
        </div>
        <div style={{ marginTop: 20 }}>
          I undertake to pay all the dues against mobile connection including
          all pending dues pertaining to the period prior to the date of
          transfer, if any. I agree to pay the cost of Re Issuance of Sim as per
          today&rsquo;s Tariff (For Sim Exchange, if required) I understand that
          the transfer of mobile will be affected as a new connection and the
          Reliance Jio Infocomm Limited rules for the same will be binding on
          me. I undertake to indemnify Reliance Jio Infocomm Limited against any
          claims or disputes arising out of the transfer of the said connection,
          including but not restricted to legal cases, claims for damages etc. I
          therefore request you to transfer the said mobile connection from the
          name of <strong>Blr Envision Academy LLP</strong> to my name i.e. Mr
          /Ms <strong>{fullName}</strong>
        </div>
        <div style={{ marginTop: 10 }}>
          Kindly do the needful and oblige. Yours truly,
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            marginRight: 20,
            marginTop: 50,
          }}
        >
          <div>Signature of transferee</div>
          <div>Date:</div> <div>Place:</div>
        </div>
        <div style={{ borderBottom: "1px solid grey" }}>
          Name and Address of the transferee:
        </div>
        <div>
          <strong>Declaration by transferor (Company)</strong>
        </div>
        <div>
          I/We <strong>Blr Envision Academy LLP</strong> hereby declare that we
          have no objection for transfer of mobile number{" "}
          <strong>{phoneNumber} </strong>to Mr./Mrs./Ms{" "}
          <strong>{fullName} </strong>
          that was issued to our students for educational purposes .<br /> We
          have handed over the simcard and we hereby surrender all our rights as
          a subscriber to Reliance Jio Infocomm Limited. We have settled all
          dues against this connection and would like to get the refund/transfer
          deposit paid by us.
        </div>
        <div style={{ display: "flex", alignItems: "center" }}>
          <div>
            <img src={Signature} width={"230px"} height={"110px"}></img>
          </div>
          <div style={{ marginLeft: 20 }}>
            <img src={Ceal} width={"100px"} height={"100px"}></img>
          </div>
        </div>
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <div>Authorized Signatory : Idamakanti Lakshmireddy</div>
          <div>Date: {moment().format("DD/MM/YYYY")}</div>
          <div> Bengaluru</div>
        </div>
        <div>
          <strong>BLR Envision Academy LLP</strong>
        </div>
        <div>4,9th B Main,Muni Reddy Layout Banaswadi&nbsp;</div>
        <div>Bangalore 560043</div>
      </div>
    );
  }
  render() {
    return (
      <div>
        {this.renderFormat()}
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <button
            style={{ marginTop: 20 }}
            onClick={() => {
              this.componentRef.current.style.width = "793px";
              this.componentRef.current.style.height = "1123px";
              exportComponentAsJPEG(this.componentRef);
            }}
          >
            Download
          </button>
        </div>
      </div>
    );
  }
}
export default NOCFormat1;
