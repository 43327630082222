import React, { Component } from 'react';
import PropTypes from 'prop-types';
import './styles.css';
import fb from '../../static/images/fb.png';
import twitter from '../../static/images/twitter.png';
import linkedin from '../../static/images/linkedin.png';
import Constants from '../../shared/constants';

class Footer extends Component {
  renderIcon(image) {
    return (
      <img alt="" className="mr-3" src={image} width="30" height="30" />
    );
  }

  render() {
    const { children } = this.props;
    const year = 2018;
    let content;
    if (children) {
      content = children;
    } else {
      content = (
        <div>
          &copy;
          {year}
        </div>
      );
    }
    return (
      <div className="footer" style={{ fontFamily: 'Montserrat' }}>
        <div className="row mt-5 mb-5">
          <div className="col" style={{ marginLeft: '10%' }}>
            <p style={{ width: '323px' }}>
              Ideaboard Technologies
              <br />
              001, Platinum Castle
              <br />
              3rd Main Road, OMBR Layout,
              <br />
              Bengaluru - 560043
            </p>
            {content}
          </div>
          <div className="col" style={{ marginLeft: '10%' }}>
            <p>
              Got more questions?
              <br />
              Call us at
              <span style={{ color: 'white' }}><b>{Constants().contact}</b></span>
              <br />
              or write to
              <span style={{ color: 'white' }}><b>{Constants().email}</b></span>
              <br />
            </p>
            {this.renderIcon(fb)}
            {this.renderIcon(twitter)}
            {this.renderIcon(linkedin)}
            <a href="https://play.google.com/store/apps/details?id=com.ideaboard&utm_source=Website&utm_campaign=Header&pcampaignid=MKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1"><img alt="Get it on Google Play" src="https://play.google.com/intl/en_us/badges/images/generic/en_badge_web_generic.png" width="160px" /></a>
          </div>
        </div>
      </div>
    );
  }
}

Footer.propTypes = {
  children: PropTypes.node,
};
Footer.defaultProps = {
  children: null,

};

export default Footer;
