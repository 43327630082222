import Api from '../helpers/Api';
import { TEST_OVERVIEW } from '.';
import { jsonToQueryString } from '../helpers/util';

export const UPDATE_ZONE_DETAILS = 'instituteProgress/UPDATE_ZONE_DETAILS';

export function getInstituteProgress(filters) {
  return (dispatch) => Api({
    method: 'get',
    url: `/institute_insights/get_zone_stats${jsonToQueryString(filters)}`,
  }).then((response) => {
    if (response.success) {
      dispatch({
        type: UPDATE_ZONE_DETAILS,
        payload:
        {
          data: response.data,
          testTypeName: response.test_type_name,
          isSampleData: response.is_sample_data,
          hasAccessToParentPortal: response.has_access_to_parent_portal ? response.has_access_to_parent_portal : true,
        },
      });
      dispatch({
        type: TEST_OVERVIEW,
        payload: {
          testType: response.test_type,
          campuses: response.campuses,
          classrooms: response.classrooms,
        },
      });
    }
    return response;
  });
}
