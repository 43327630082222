import {
  GET_MEETING_DETAILS, TOGGLE_MEETING_STATUS,
  GET_QUIZ_QUESTION_DATA, SET_CURRENT_QUIZ_QUESTION,
  UPDATE_SHOW_QUESTION_DATA, ADD_MEETING_URL,
  UPDATE_NEW_QUIZ_TO_LIVE_CLASS,
  UPDATE_SUBJECTS_FILTER, UPDATE_VIRTUAL_MEETING_FILTERS,
  UPDATE_ATTENDEES_COUNT, UPDATE_LIVE_RESULTS,
  UPDATE_DASHBOARD_DATA, UPDATE_G_AUTH_STATUS,
  UPDATE_CLASSROOM_FILTER, UPDATE_DELETED_VIDEO,
  GET_FEEDBACK_REPORT, UPDATE_FILTERS,
  GET_ATTENDACE_REPORTS, MARK_PRESENT, MARK_ABSENT, LOADING_ATTENDACE_REPORTS,
} from '../actions';

import { TRACKS } from '../constants';

const initialState = {
  total_items: 0,
  attendances: [],
  videoChapters: [],
  remainingRecordingVideoStorageInMb: 0,
  videoCheckpoints: [],
  table_header: [],
  start_date: null,
  end_date: new Date(),
  selectedCampus: {},
  selectedDepartment: {},
  selectedClassroom: null,
  selectedSemester: {},
  selectedTeacher: {},
  selectedStartDate: null,
  selectedEndDate: null,
  loading: false,
  teacherAttendance: [],
  page: 1,
  total_page: 0,
  per_page: 20,
  total_feedbacks: 0,
  isAnonymousFeedback: true,
  departmentData: [],
  feedbacks: [],
  isHigherEducation: false,
  departments: [],
  campuses: [],
  teacherFeedbackRating: [],
  timeTabledata: {},
  feedbackWidgetData: {},
  filterClassrooms: [],
  semesters: [],
  teachers: [],
  selectedSemester: {},
  dashBoardData: {},
  gAuthStatus: false,
  isZoomIntegrated: false,
  academicStartDate: '',
  zones: [],
  startDate: '',
  endDate: '',
  currentQuizQuestionIndex: 0,
  useZoom: false,
  showQuizQuestionOutside: true,
  user: {},
  delayedClasses: [],
  selectedSubject: {},
  liveClasses: [],
  showFilters: false,
  isCompleted: false,
  showQuizQuestion: false,
  virtualMeetings: [],
  classrooms: [],
  testTypes: [],
  subjects: [],
  meeting: {},
  recordingUrl: {},
  tracks: TRACKS,
  quizQuestionData: {},
  currentMeetingQuizQuestions: [],
  updatedCount: 0,
  selectedClassrooms: [],
  questionsShown: [],
  questionsLocked: [],
  meetingDetails: '',
  quizzes: [],
  liveMeetingId: '',
  liveMeetTime: '',
  emails: [],
  filteredSubjects: [],
  filteredTeachers: [],
  optionData: {},
  optionList: {},
  modules: [],
  lectures: [],
  currentQuizQuestionAttemptedStudentsCount: 0,
};

function markPresent(attendanceData, response) {
  const attendances = attendanceData;
  const dateDetails = attendances[response.index].period_details[response.date];
  dateDetails.attendance = 'P';
  dateDetails.attendance_id = response.attendanceId;
  attendances[response.index].period_details[response.date] = dateDetails;
  let total = attendances[response.index].Total;
  const index = total.indexOf('/');
  total = (parseInt(total.substring(0, index), 10) + 1) + total.substring(index);
  attendances[response.index].Total = total;
  attendances[response.index].Percentage = parseInt((parseInt(total.substring(0, index), 10)
  / parseInt(total.substring(index + 1), 10)) * 100, 10);
  return { attendances: [...attendances] };
}

function markAbsent(attendanceData, response) {
  const attendances = attendanceData;
  const dateDetails = attendances[response.index].period_details[response.date];
  dateDetails.attendance = 'A';
  dateDetails.attendance_id = null;
  attendances[response.index].period_details[response.date] = dateDetails;
  let total = attendances[response.index].Total;
  const index = total.indexOf('/');
  total = (parseInt(total.substring(0, index), 10) - 1) + total.substring(index);
  attendances[response.index].Total = total;
  attendances[response.index].Percentage = parseInt((parseInt(total.substring(0, index), 10)
  / parseInt(total.substring(index + 1), 10)) * 100, 10);
  return { attendances: [...attendances] };
}


export default function (state = initialState, action) {
  const newState = { ...state };
  newState.updatedCount += 1;
  switch (action.type) {
    case GET_FEEDBACK_REPORT: {
      return { ...state, ...action.payload, loading: false };
    }
    case UPDATE_FILTERS: {
      Object.keys(action.filters).map((type) => {
        newState[type] = action.filters[type];
      });
      return { ...state, ...newState, page: initialState.page };
    }
    case GET_MEETING_DETAILS: {
      return { ...state, ...action.payload };
    }
    case UPDATE_DASHBOARD_DATA: {
      return { ...state, dashBoardData: action.payload };
    }
    case UPDATE_G_AUTH_STATUS: {
      return { ...state, gAuthStatus: action.payload };
    }
    case GET_QUIZ_QUESTION_DATA: {
      newState.currentMeetingQuizQuestions = newState.quizQuestionData || [];
      if (newState.currentMeetingQuizQuestions.length > 0) {
        newState.showQuizQuestion = !action.showQuizQuestionOutside;
        newState.currentMeetingQuizQuestions.map((quizQuestion) => {
          newState.isCompleted = quizQuestion.is_completed;
          if (quizQuestion.show) {
            newState.questionsShown = [...newState.questionsShown, quizQuestion.id];
          }
          if (quizQuestion.is_locked) {
            newState.questionsLocked = [...newState.questionsLocked, quizQuestion.id];
          }
        });
      }
      newState.questionsShown = [...newState.questionsShown];
      newState.questionsLocked = [...newState.questionsLocked];
      return { ...newState };
    }
    case ADD_MEETING_URL: {
      action.virtualMeetings.map((meeting) => {
        newState.recordingUrl[meeting.id] = meeting.recording_url;
      });
      return newState;
    }
    case UPDATE_DELETED_VIDEO: {
      const { meetingId } = action.payload;
      newState.recordingUrl = { ...newState.recordingUrl, ...newState.recordingUrl[meetingId] = '' };
      return { ...newState };
    }
    case UPDATE_SHOW_QUESTION_DATA: {
      newState.updatedCount -= 1;
      newState.questionsShown = [...(newState.questionsShown || []), ...(action.ids)];
      newState.questionsShown = [...newState.questionsShown];
      return newState;
    }
    case SET_CURRENT_QUIZ_QUESTION: {
      newState.updatedCount -= 1;
      newState.currentQuizQuestionIndex += action.value;
      return newState;
    }
    case TOGGLE_MEETING_STATUS: {
      const { meetingId } = action.payload;
      newState.liveMeetingId = meetingId;
      return newState;
    }
    case UPDATE_VIRTUAL_MEETING_FILTERS: {
      Object.keys(action.filters).map((type) => {
        newState[type] = action.filters[type];
      });
      return { ...newState };
    }
    case UPDATE_NEW_QUIZ_TO_LIVE_CLASS: {
      const { quizId, quiz } = action.payload;
      const index = newState.virtualMeetings.findIndex((virtualMeeting) => virtualMeeting.id
      === action.payload.id);
      newState.virtualMeetings[index].quiz_id = quizId;
      newState.quizzes.push(quiz);
      return newState;
    }
    case UPDATE_SUBJECTS_FILTER: {
      newState.filteredSubjects = [];
      newState.filteredSubjects = (newState.subjects.filter((subject) => (subject.classrooms_ids.filter((classroomId) => {
        return (action.classroomIds).indexOf(classroomId) !== -1;
      })).length > 0));
      return { ...newState };
    }
    case UPDATE_CLASSROOM_FILTER: {
      newState.filteredTeachers = [];
      newState.filteredTeachers = newState.teachers.filter((teacher) => {
        return teacher.subject_ids.includes(action.subjectId);
      });
      return { ...newState };
    }
    case UPDATE_ATTENDEES_COUNT: {
      const { attendeesCount } = action.payload;
      newState.updatedCount -= 1;
      newState.attendeesCount = attendeesCount;
      return { ...newState };
    }
    case UPDATE_LIVE_RESULTS: {
      newState.optionData = action.payload.optionData;
      Object.keys(action.payload.optionList).map((type) => {
        newState.optionList[type] = action.payload.optionList[type];
      });
      newState.currentQuizQuestionAttemptedStudentsCount = action.payload.currentQuizQuestionAttemptedStudentsCount;
      newState.questionsLocked = action.payload.questionsLocked;
      return { ...newState };
    }
    case GET_ATTENDACE_REPORTS: {
      return { ...state, ...action.payload, loading: false };
    }
    case MARK_PRESENT: {
      return {
        ...state, ...action.payload, ...markPresent(state.attendances, action.payload), loading: false,
      };
    }
    case MARK_ABSENT: {
      return {
        ...state, ...action.payload, ...markAbsent(state.attendances, action.payload), loading: false,
      };
    }
    case LOADING_ATTENDACE_REPORTS:
      return { ...state, loading: action.payload };
    case UPDATE_FILTERS: {
      const newState = {};
      Object.keys(action.filters).map((type) => {
        newState[type] = action.filters[type];
      });
      return { ...state, ...newState, page: initialState.page };
    }
    default:
      return state;
  }
}

