import React, { useState } from 'react';
import DatePicker from "react-datepicker";
import moment from "moment";

const CustomInput = (props) => {
  return (
    <input
      onClick={props.onClick}
      type="text"
      readOnly
      style={{ alignSelf: 'center', padding: 10 }}
      {...props}
      value={props.text || props.value}
    />
  )
}

const DateRangePicker = ({ startDate = new Date(), endDate = null, handleChange }) => {
  const onChange = (dates) => {
    const [start, end] = dates;
    handleChange(start, end);
  };
  return (
    <DatePicker
      customInput={<CustomInput text={(startDate && endDate) ? `${moment(startDate).format("DD/MM/YY")} - ${moment(endDate).format("DD/MM/YY")}` : moment(startDate).format("DD/MM/YY")}/>}
      selected={startDate}
      onChange={onChange}
      startDate={startDate}
      endDate={endDate}
      selectsRange
      maxDate={new Date()}
    />
  );
}

export default DateRangePicker;