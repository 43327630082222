import React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { getApplicantDetails, createApplicantNotes } from "../../actions";
import AddApplicant from "./addApplicant";
import Loader from "../loading";
import Modal from "react-responsive-modal";

const BLOOD_GROUP = {
  "O POSITIVE": 0,
  "O NEGATIVE": 1,
  "A POSITIVE": 2,
  "A NEGATIVE": 3,
  "B POSITIVE": 4,
  "B NEGATIVE": 5,
  "AB POSITIVE": 6,
  "AB NEGATIVE": 7,
};

const APPLICANT_STATUS = {
  LEAD: 0,
  INTERESTED: 1,
  "ADVANCE PAID": 2,
  ADMITTED: 3,
  CANCELED: 4,
  "NOT INTERESTED": 5,
  JUNK: 6,
};

class Applicant extends React.Component {
  constructor(props) {
    super(props);
    this.state = { showModal: false, newNotes: "", showNotesModal: false };
  }

  componentDidMount() {
    this.props.getApplicantDetails(this.props.applicantId);
  }

  renderEditAndNotes() {
    return (
      <div style={{ display: "flex" }}>
        <div
          className="Button primary-Button"
          style={{ width: 150 }}
          onClick={() => this.setState({ showModal: true })}
        >
          Edit
        </div>
        <div
          className="Button primary-Button"
          style={{ width: 150, marginLeft: 30 }}
          onClick={() => this.setState({ showNotesModal: true })}
        >
          Notes
        </div>
      </div>
    );
  }

  renderApplicantProfile() {
    const { applicant, permanentAddress, currentAddress } = this.props;
    return (
      <div style={{ margin: 30 }}>
        <table style={{ width: "100%" }}>
          <style>{`
          .profile-category {
                margin-bottom: 10px;
                font-size: 16px;
           }
          .td {
            width: 50%;
          }
          `}</style>
          <tbody>
            <tr>
              <td>
                <div className="profile-category">
                  <span className="heading-1">Application ID: </span>
                  {applicant.application_id || "-"}
                </div>
              </td>
              <td>
                <div className="profile-category">
                  <span className="heading-1">Name: </span>
                  {applicant.name}
                </div>
              </td>
            </tr>
            <tr>
              <td>
                <div className="profile-category">
                  <span className="heading-1">Personal Phone: </span>
                  {applicant.phone || "-"}
                </div>
              </td>
              <td>
                <div className="profile-category">
                  <span className="heading-1">Program: </span>
                  {applicant.program || "-"}
                </div>
              </td>
            </tr>
            <tr>
              <td>
                <div className="profile-category">
                  <span className="heading-1">Father's Phone: </span>
                  {applicant.father_phone || "-"}
                </div>
              </td>
              <td>
                <div className="profile-category">
                  <span className="heading-1">Father's Name: </span>
                  {applicant.father_name || "-"}
                </div>
              </td>
            </tr>
            <tr>
              <td>
                <div className="profile-category">
                  <span className="heading-1">Tag: </span>
                  {applicant.tag_names
                    ? applicant.tag_names
                        .map((tag) => {
                          return tag;
                        })
                        .join(", ")
                    : "-"}
                </div>
              </td>
              <td>
                <div className="profile-category">
                  <span className="heading-1">Father's email: </span>
                  {applicant.father_email || "-"}
                </div>
              </td>
            </tr>
            <tr>
              <td>
                <div className="profile-category">
                  <span className="heading-1">Mother's Name: </span>
                  {applicant.mother_name || "-"}
                </div>
              </td>
              <td>
                <div className="profile-category">
                  <span className="heading-1">Mother's Phone: </span>
                  {applicant.mother_phone || "-"}
                </div>
              </td>
            </tr>
            <tr>
              <td>
                <div className="profile-category">
                  <span className="heading-1">Mother's email: </span>
                  {applicant.mother_email || "-"}
                </div>
              </td>
              <td>
                <div className="profile-category">
                  <span className="heading-1">Personal Email: </span>
                  {applicant.email || "-"}
                </div>
              </td>
            </tr>
            <tr>
              <td>
                <div className="profile-category">
                  <span className="heading-1">Date of Birth: </span>
                  {applicant.dob || "-"}
                </div>
              </td>
              <td>
                <div className="profile-category">
                  <span className="heading-1">Gender: </span>
                  {applicant.gender ? "FEMALE" : "MALE"}
                </div>
              </td>
            </tr>
            <tr>
              <td>
                <div className="profile-category">
                  <span className="heading-1">Previous Exam Score: </span>
                  {applicant.previous_exam_score || "-"}
                </div>
              </td>
              <td>
                <div className="profile-category">
                  <span className="heading-1">Campus: </span>
                  {applicant.campus_name}
                </div>
              </td>
            </tr>
            <tr>
              <td>
                <div className="profile-category">
                  <span className="heading-1">Blood Group: </span>
                  {applicant.blood_group || "-"}
                </div>
              </td>
              <td>
                <div className="profile-category">
                  <span className="heading-1">Status: </span>
                  {applicant.status || "-"}
                </div>
              </td>
            </tr>
            <tr>
              <td>
                <div className="profile-category">
                  <span className="heading-1">Permanent Address: </span>
                  {permanentAddress?.line_one ? (
                    <div style={{ display: "flex", flexDirection: "column" }}>
                      <span>{permanentAddress.line_one}</span>
                      <span>{permanentAddress.line_two}</span>
                      <span>{permanentAddress.city}</span>
                      <span>{permanentAddress.pin_code}</span>
                    </div>
                  ) : (
                    "-"
                  )}
                </div>
              </td>
              <td>
                <div className="profile-category">
                  <span className="heading-1">Current Address: </span>
                  {currentAddress?.line_one ? (
                    <div style={{ display: "flex", flexDirection: "column" }}>
                      <span>{currentAddress.line_one}</span>
                      <span>{currentAddress.line_two}</span>
                      <span>{currentAddress.city}</span>
                      <span>{currentAddress.pin_code}</span>
                    </div>
                  ) : (
                    "-"
                  )}
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    );
  }

  renderApplicantForm() {
    const { applicant, campuses, tags, permanentAddress, currentAddress } =
      this.props;
    return (
      <AddApplicant
        showModal={this.state.showModal}
        handleCloseModal={() => this.setState({ showModal: false })}
        applicant={applicant}
        edit={true}
        selectedCampus={campuses.map((campus) => {
          if (campus.value === applicant.campus_id) return campus;
        })}
        selectedTags={tags.map((tag) => {
          if (applicant.tag_ids.includes(tag.value)) {
            return tag;
          }
        })}
        selectedBloodGroup={Object.keys(BLOOD_GROUP).map((group) => {
          if (
            applicant.blood_group && applicant.blood_group
              .toUpperCase()
              .replace("_", " ") === group
          ) {
            return { label: group, value: BLOOD_GROUP[group] };
          }
        })}
        selectedApplicantStatus={{
          label: applicant.status,
          value: APPLICANT_STATUS[applicant.status],
        }}
        selectedGender={
          applicant.gender
            ? { label: "FEMALE", value: true }
            : { label: "MALE", value: false }
        }
        permanentAddress={permanentAddress}
        currentAddress={currentAddress}
      />
    );
  }

  renderNotesModal() {
    const { showNotesModal, newNotes } = this.state;
    const { applicant } = this.props;
    return (
      <Modal
        open={showNotesModal}
        onClose={() => {
          this.setState({ showNotesModal: false });
        }}
        styles={{ modal: { height: "50vh", width: "60vw" } }}
      >
        <div style={{ padding: "30px 15px", height: "100%" }}>
          <div className="heading-1">Applicant Notes:</div>
          <textarea
            defaultValue={newNotes}
            onChange={(event) =>
              this.setState({ newNotes: event.target.value })
            }
            placeholder="Enter notes for applicant"
            style={{ height: "60%", width: "100%", marginTop: 15 }}
          ></textarea>
          <div style={{ float: "right", marginTop: 15, display: "flex" }}>
            <div
              className="Button secondary-Button"
              style={{ width: 100 }}
              onClick={() => this.setState({ showNotesModal: false })}
            >
              Cancel
            </div>
            <div
              className="Button primary-Button"
              style={{ width: 100, marginLeft: 15 }}
              onClick={async () => {
                await this.props.createApplicantNotes(applicant.id, newNotes);
                this.setState({ showNotesModal: false });
              }}
            >
              Update
            </div>
          </div>
        </div>
      </Modal>
    );
  }

  renderNotes() {
    const { notes } = this.props;
    return (
      <div>
        <div style={{ fontSize: 20 }}>Notes:</div>
        <div>
          {notes.map((note) => {
            return <div style={{ margin: 10 }}>{note.notes}</div>;
          })}
        </div>
      </div>
    );
  }

  render() {
    const { showModal, showNotesModal } = this.state;
    if (this.props.isLoading) {
      return <Loader />;
    }
    return (
      <div style={{ marginLeft: "20%", padding: 30 }}>
        {this.renderEditAndNotes()}
        {this.renderApplicantProfile()}
        {this.renderNotes()}
        {showModal && this.renderApplicantForm()}
        {showNotesModal && this.renderNotesModal()}
      </div>
    );
  }
}

Applicant.propTypes = {
  applicant: PropTypes.object,
};

Applicant.defaultProps = {
  applicant: {},
};
const mapStateToProps = ({ admissionBoard }) => ({
  applicant: admissionBoard.applicant,
  campuses: admissionBoard.campuses,
  tags: admissionBoard.tags,
  isLoading: admissionBoard.isLoading,
  notes: admissionBoard.notes,
  permanentAddress: admissionBoard.permanentAddress,
  currentAddress: admissionBoard.currentAddress,
});

export default connect(mapStateToProps, {
  getApplicantDetails,
  createApplicantNotes,
})(Applicant);
