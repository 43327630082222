import React from 'react';
import { connect } from 'react-redux';
import Modal from './baseModal';
import './referalModal.css';
import Constants from '../../shared/constants';
import closeMark from '../../static/images/close-mark.svg';

class TermsForReferalModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showTermsAndConditions: false,
    };
    this.handleCloseModal = this.handleCloseModal.bind(this);
  }

  handleCloseModal() {
    const { showTermsAndConditions } = this.state;
    this.setState({
      showTermsAndConditions: !showTermsAndConditions,
    });
  }

  showModalView() {
    return (
      <div style={{ backgroundColor: '#FFFFF', width: '100%' }}>
        <div style={{ margin: 30 }}>
          <div style={{
            display: 'flex', justifyContent: 'space-between', marginBottom: 25, marginTop: 25,
          }}
          >
            <div className="nunito-sans-header" style={{ fontWeight: 'bold', fontSize: 18 }}>Terms & Conditions</div>
            <div role="presentation" onClick={this.handleCloseModal} style={{ cursor: 'pointer' }}>
              <img src={closeMark} alt="x-mark" />
            </div>
          </div>
          <ul className="nunito-body" style={{ listStyleType: 'disc', fontSize: 14, color: '#43464B' }}>
            <li>  IdeaBoard  ‘Referral’ Program allows registered IB users to earn up to Rs.10,000.</li>
            <li> For every referral, there will be a 10% commission on the deal amount up to Rs.10,000.</li>
            <li> IB reserves the right to modify or terminate this offer at any time without prior notice. We also reserve the right to deny a claim to the offer at its sole discretion or misuse of the offer.</li>
            <li>
              IB’s services shall be governed by the terms and conditions declared at
              <a href={Constants().terms_url} target="_blank">
              &nbsp;  {Constants().terms_url}
              </a>
            </li>
            <li>
              In case of any query regarding the offer, please email us at &nbsp;
              {Constants().email}
              &nbsp; or call at &nbsp;
              {Constants().contact}
            </li>
          </ul>
        </div>
      </div>
    );
  }

  render() {
    const { showTermsAndConditions } = this.state;
    return (
      <div>
        <Modal showModal={showTermsAndConditions} handleCloseModal={this.handleCloseModal} width="500px" minwidth="none">
          {this.showModalView()}
        </Modal>
        <div
          role="presentation"
          style={{
            display: 'flex', justifyContent: 'center', alignItems: 'center', color: '#FFFFFF', fontSize: 10, cursor: 'pointer',
          }}
          onClick={() => this.setState({ showTermsAndConditions: true })}
        >
          * T&C Apply
        </div>
      </div>
    );
  }
}

export default connect()(TermsForReferalModal);
