import Api from '../helpers/Api';
import { showToast } from '.';

export const GET_RETAG_TOPICS = 'retag/GET_RETAG_TOPICS';
export const GET_RETAG_QUESTIONS = 'retag/GET_RETAG_QUESTIONS';
export const UPDATE_RETAG_INDEX = 'retag/UPDATE_RETAG_INDEX';
export const HIGHLIGHT_SELECTED_TOPIC = 'retag/HIGHLIGHT_SELECTED_TOPIC';
export const UPDATE_RETAG_COMPLEXITY = 'retag/UPDATE_RETAG_COMPLEXITY';

export const getRetagTopics = () => async (dispatch, getState) => {
  const response = await Api({
    method: 'get',
    url: '/question_tagging/get_topics_to_retag',
  });
  dispatch({
    type: GET_RETAG_TOPICS,
    payload: response.success ? response.topics : [],
  });
  return Promise.resolve();
};

export const getQuestionsToRetag = (chapterId) => async (dispatch, getState) => {
  const response = await Api({
    method: 'get',
    url: `/question_tagging/${chapterId}/GET_RETAG_QUESTIONS_to_retag`,
  });
  dispatch({
    type: GET_RETAG_QUESTIONS,
    payload: response.success ? response : {},
  });
  return response;
};

export const updateRetagIndex = (offset) => async (dispatch) => {
  dispatch({
    type: UPDATE_RETAG_INDEX,
    payload: offset,
  });
};
export const updateHighlightTopic = (isHighlight) => async (dispatch) => {
  dispatch({
    type: HIGHLIGHT_SELECTED_TOPIC,
    payload: { isHighlight },
  });
};

export const updateRetagComplexity = (index, complexity,
  previousComplexity, timeSpent, scope) => async (dispatch, getState) => {
  const { retagQuestions: { questions } } = getState();
  dispatch({
    type: UPDATE_RETAG_COMPLEXITY,
    payload: { index, complexity },
  });
  scope.forceUpdate();
  const response = await Api({
    method: 'post',
    url: `/question_tagging/${questions[index].id}/update_question_complexity`,
    data: { complexity, timeSpent },
  });
  if (response.success) {
    // dispatch(updateIndex(1));
  } else {
    dispatch(showToast('Failed to update! Please try again!'));
    dispatch({
      type: UPDATE_RETAG_COMPLEXITY,
      payload: { index, previousComplexity },
    });
  }
  return Promise.resolve();
};

export const updateQuestionMapping = (checkpointId, questionId, duration) => async (dispatch) => {
  const response = await Api({
    method: 'post',
    url: `/question_tagging/${questionId}/update_question_checkpoint_mapping2`,
    data: { checkpoint_id: checkpointId, duration },
  });
  if (response.success) {
    dispatch(showToast('Update Successful!'));
    dispatch(updateHighlightTopic(false));
    // dispatch(updateIndex(1));
  } else {
    dispatch(showToast('Failed to update! Please try again!'));
  }
};
