import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Select from 'react-select';
import Modal from './baseModal';
import { createAndUpdateTestGroup, showToast } from '../../actions';

const TEST_GROUP_TYPES = {
  TEST: { value: 'test', label: 'Test' },
  ASSIGNMENT: { value: 'assignment', label: 'Assignment' },
};

class TestGroupModal extends React.Component {
  constructor(props) {
    super(props);
    const { testGroupData, testTypes } = this.props;
    this.state = {
      data: testGroupData && testGroupData.id ? {
        id: testGroupData.id,
        name: testGroupData.name,
        is_test: testGroupData.is_test,
        test_type_id: testGroupData.test_type_id,
        question_count: testGroupData.question_count || {},
      } : {
        question_count: {}, name: '', is_test: true, test_type_id: 0,
      },
      selectedTestType: testTypes.find((item) => item.id === testGroupData.test_type_id) || { group_mapping: {} },
    };
    this.renderContent = this.renderContent.bind(this);
    this.renderDropdown = this.renderDropdown.bind(this);
    this.renderInput = this.renderInput.bind(this);
    this.renderTable = this.renderTable.bind(this);
  }

  renderDropdown(options, selectedValue, handleChange, placeholder) {
    return (
      <div
        style={{ marginBottom: 30 }}
      >
        <Select
          value={selectedValue && selectedValue.label ? selectedValue : null}
          onChange={handleChange}
          options={options}
          placeholder={placeholder}
          isSearchable={false}
          maxMenuHeight={200}
        />
      </div>
    );
  }

  renderInput(value = '', inputType = 'text', handleChange = () => {}, placeholder = '') {
    return (
      <input
        type={inputType}
        value={value}
        placeholder={placeholder}
        style={{
          border: 'none', borderBottom: '1px solid #E4E7EE', width: '90%', margin: '5px 0px 0px 0px', fontSize: 16, marginBottom: 30,
        }}
        onChange={handleChange}
      />
    );
  }

  renderContent() {
    const {
      testTypes, submitButton,
    } = this.props;
    const { data, selectedTestType } = this.state;
    return (
      <div style={{ padding: 30, paddingLeft: 0 }}>
        {this.renderInput(data.name, 'text', (event) => {
          this.setState({ data: { ...data, name: event.target.value } });
        }, 'Name')}
        {this.renderDropdown(
          testTypes, selectedTestType, (selectedValue) => {
            this.setState({ selectedTestType: selectedValue, data: { ...data, test_type_id: selectedValue.value, question_count: {} } });
          }, 'Test Type',
        )}
        {this.renderDropdown(
          Object.values(TEST_GROUP_TYPES), data.is_test ? TEST_GROUP_TYPES.TEST : TEST_GROUP_TYPES.ASSIGNMENT, (selectedValue) => {
            this.setState({ data: { ...data, is_test: selectedValue.value === 'test' } });
          }, 'Test Group Type',
        )}
        {this.renderTable()}
        <div
          className="Button primary-Button"
          role="presentation"
          onClick={() => {
            if (!data.name) {
              this.props.showToast('Please Add Test Group Name');
            } else if (!data.test_type_id) {
              this.props.showToast('Please Select Test Type');
            } else if (!(data.question_count && Object.keys(data.question_count).length > 0)) {
              this.props.showToast('Atleast One subject should be selected');
            } else {
              this.props.createAndUpdateTestGroup(data).then(() => {
                window.location.reload();
              });
            }
          }}
        >
          {submitButton}
        </div>
      </div>
    );
  }

  renderQuestionCount(subjectId) {
    const { subjectList } = this.props;
    const { data, selectedTestType } = this.state;
    const subjectName = selectedTestType && selectedTestType.group_mapping[subjectId.toString()];
    if (subjectList[subjectId].toLowerCase() === 'zoology' || !subjectName) {
      return <div />;
    }
    return (
      <tr>
        <td style={{ textTransform: 'capitalize' }}>{subjectName}</td>
        <td>
          {this.renderInput(data.question_count[subjectName] || 0, 'number', (event) => {
            if (event.target.value > 0) {
              this.setState({
                data: { ...data, question_count: { ...data.question_count, [subjectName]: parseInt(event.target.value, 10) } },
              });
            } else {
              const questionCount = data.question_count;
              delete questionCount[subjectName];
              this.setState({ data: { ...data, question_count: questionCount } });
            }
          })}

        </td>
      </tr>
    );
  }

  renderTable() {
    const { selectedTestType } = this.state;
    const { subjectList } = this.props;
    if (!selectedTestType.label) {
      return <div />;
    }
    return (
      <table>
        <thead>
          <tr style={{ textAlign: 'center' }}>
            <th>
              Subjects
            </th>
            <th>
              Question Count
            </th>
          </tr>
        </thead>
        <tbody>
          {Object.keys(subjectList).map((subjectId) => this.renderQuestionCount(subjectId))}
        </tbody>
      </table>
    );
  }

  render() {
    const {
      visible, onClose, isEdit, header,
    } = this.props;
    return (
      <Modal showModal={visible} handleCloseModal={onClose} width="700px" header={header} style={{ padding: 40 }}>
        {this.renderContent(isEdit)}
      </Modal>
    );
  }
}

TestGroupModal.propTypes = {
  visible: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
  isEdit: PropTypes.bool,
  header: PropTypes.string,
  submitButton: PropTypes.string,
  testGroupId: PropTypes.number,
  testGroupData: PropTypes.object,
  testTypes: PropTypes.array,
  testGroupDetails: PropTypes.array,
  subjectList: PropTypes.object,
  refreshOnClose: PropTypes.bool,
};

TestGroupModal.defaultProps = {
  visible: false,
  isEdit: false,
  header: 'Create Test Group',
  submitButton: 'Create',
  testGroupId: 0,
  testGroupData: {},
  testTypes: [],
  testGroupDetails: [],
  subjectList: {},
  refreshOnClose: false,
};

const mapStateToProps = ({ institute }) => ({
  testGroupDetails: institute.testGroupDetails,
  testTypes: institute.testTypes,
  subjectList: institute.subjectList,
});

export default connect(mapStateToProps,
  { createAndUpdateTestGroup, showToast })(TestGroupModal);
