import Api from "../helpers/Api";
import { showToast } from ".";

export const DEAL_STATUS = {
  NEW: 0,
  PENDING_APPROVAL: 1,
  SENT: 3,
  WON: 4,
  LOST: 5,
  JUNK: 6,
};

export const UPDATE_BDE_TOOLS_DETAILS = "bdeTools/UPDATE_BDE_TOOLS_DETAILS";
export const UPDATE_BDE_TOOLS_PAYMENTS = "bdeTools/UPDATE_BDE_TOOLS_PAYMENTS";
export const UPDATE_BDE_TOOLS_INSTITUTE = "bdeTools/UPDATE_BDE_TOOLS_INSTITUTE";
export const UPDATE_BDE_TOOLS_DEALS_AND_SUBSCRIPTIONS =
  "bdeTools/UPDATE_BDE_TOOLS_DEALS_AND_SUBSCRIPTIONS";

export const showLoader = (show) => async (dispatch) => {
  await dispatch({
    type: UPDATE_BDE_TOOLS_DETAILS,
    payload: {
      isLoading: show,
    },
  });
};

export const getBdeDashboardSummary = () => async (dispatch) => {
  await dispatch(showLoader(true));
  const response = await Api({
    method: "get",
    url: "/crm_new/get_bde_dashboard_summary",
  });
  if (response.success) {
    await dispatch({
      type: UPDATE_BDE_TOOLS_DETAILS,
      payload: {
        dashboardSummary: {
          activeInstitutesCount: response.active_institutes,
          activeStudentsCount: response.active_students,
          dealsCount: response.deals,
          dealsWonCount: response.deals_won,
          dealsPendingCount: response.deals_paused,
          testsCount: response.tests,
          pausedInstitutesCount: response.paused_institutes,
          aboutToExpireDealsCount: response.about_to_expire_deals,
          expiredDealsCount: response.expired_deals,
          institutesWithLessQuestions: response.institutes_with_less_questions,
        },
      },
    });
  }
  await dispatch(showLoader(false));
};

export const getBdeDashboardDetails = (type) => async (dispatch) => {
  await dispatch({
    type: UPDATE_BDE_TOOLS_DETAILS,
    payload: {
      isDashboardMoadalDataLoading: true,
    },
  });
  const response = await Api({
    method: "get",
    url: `/crm_new/get_dashboard_details?type=${type}`,
  });
  if (response.success) {
    await dispatch({
      type: UPDATE_BDE_TOOLS_DETAILS,
      payload: {
        dashboardData: response.dashboard_data,
      },
    });
  }
  await dispatch({
    type: UPDATE_BDE_TOOLS_DETAILS,
    payload: {
      isDashboardMoadalDataLoading: false,
    },
  });
  return response;
};

export const getInstitutesList = () => async (dispatch) => {
  await dispatch(showLoader(true));
  const response = await Api({
    method: "get",
    url: "/crm_new/get_institutes_list",
  });
  if (response.success) {
    await dispatch({
      type: UPDATE_BDE_TOOLS_DETAILS,
      payload: {
        institutesList: response.institutes,
      },
    });
  }
  await dispatch(showLoader(false));
};

export const getInstitute = (instituteId) => async (dispatch) => {
  await dispatch(showLoader(true));
  const response = await Api({
    method: "get",
    url: `/crm_new/get_institute_details?institute_id=${instituteId}`,
  });
  if (response.success) {
    await dispatch({
      type: UPDATE_BDE_TOOLS_DETAILS,
      payload: {
        products: response.products,
        institute: {
          ...response.institute,
          subscriptions: response.subscriptions,
          campuses: response.campuses,
          tests_count: response.tests_count,
          questions_count: response.questions_count,
          remaining_question_count: response.remaining_question_count,
          active_students_count: response.active_students_count,
          payments: response.payments,
          paymentsDue: response.dues,
          paymentsDueDate: response.due_date,
          deals: {
            active_deals: response.active_deals,
            other_deals: response.other_deals,
          },
          admin_emails: response.admin_emails,
        },
      },
    });
  }
  await dispatch(showLoader(false));
};

export const editInstituteDetails =
  (updatedInstituteDetails, instituteId) => async (dispatch) => {
    await dispatch(showLoader(true));
    const response = await Api({
      method: "post",
      url: "/crm_new/update_institute_details",
      data: {
        institute_id: instituteId,
        student_count: updatedInstituteDetails.student_count,
        phone: updatedInstituteDetails.phone,
        contact_email: updatedInstituteDetails.contact_email,
        contact_name: updatedInstituteDetails.contact_name,
      },
    });
    if (response.success) {
      await dispatch({
        type: UPDATE_BDE_TOOLS_INSTITUTE,
        payload: {
          contact_name: response.institute_data.contact_name,
          contact_email: response.institute_data.contact_email,
          phone: response.institute_data.phone,
          student_count: response.institute_data.student_count,
        },
      });
      dispatch(showToast("Institute Updated Successfully"));
    } else {
      dispatch(showToast("An error occured while updating Institute"));
    }
    await dispatch(showLoader(false));
  };

export const toggleSubscriptionStatus =
  (dealId, newStatus) => async (dispatch) => {
    await dispatch(showLoader(true));
    const response = await Api({
      method: "post",
      url: `/crm_new/edit_deal_status`,
      data: {
        deal_id: dealId,
        status: newStatus,
      },
    });
    if (response.success) {
      await dispatch({
        type: UPDATE_BDE_TOOLS_DEALS_AND_SUBSCRIPTIONS,
        payload: {
          active_deals: response.active_deals,
          other_deals: response.other_deals,
          subscriptions: response.subscriptions,
        },
      });
    }
    dispatch(showToast(response.message));
    await dispatch(showLoader(false));
  };

export const createPayment = (newPayment) => async (dispatch) => {
  await dispatch(showLoader(true));
  const response = await Api({
    method: "post",
    url: `/crm_new/create_payments`,
    data: {
      deal_id: newPayment.dealId,
      amount: newPayment.paymentAmount,
      next_payment_amount: newPayment.nextPaymentAmount,
      next_payment_date: newPayment.nextPaymentDate,
      payment_mode: newPayment.paymentMode,
      created_at: newPayment.paymentDate,
      supporting_info: newPayment.comments,
      transaction_id: newPayment.transactionId,
      invoice_id: newPayment.invoiceId,
    },
  });
  if (response.success) {
    await dispatch({
      type: UPDATE_BDE_TOOLS_PAYMENTS,
      payload: {
        payments: response.payments,
        dues: response.dues,
        due_date: response.due_date,
      },
    });
    dispatch(showToast(response.message));
  } else {
    dispatch(showToast(response.message));
  }
  await dispatch(showLoader(false));
};

export const editDeal = (editDeal) => async (dispatch) => {
  await dispatch(showLoader(true));
  const response = await Api({
    method: "post",
    url: "/crm_new/edit_deal",
    data: {
      deal_id: editDeal.deal_id,
      next_payment_due_date: editDeal.next_payment_due_date,
      next_payment_amount: editDeal.next_payment_amount,
      product_name: editDeal.product_name,
      status: editDeal.status,
      quantity: editDeal.quantity,
      discount: editDeal.discount,
      subscription_start_date: editDeal.subscription_start_date,
      subscription_end_date: editDeal.subscription_end_date,
    },
  });
  if (response.success) {
    await dispatch({
      type: UPDATE_BDE_TOOLS_DEALS_AND_SUBSCRIPTIONS,
      payload: {
        active_deals: response.active_deals,
        other_deals: response.other_deals,
        subscriptions: response.subscriptions,
      },
    });
    dispatch(showToast("Deal Updated Successfully"));
  } else {
    dispatch(showToast("An error occured while updating deals"));
  }
  await dispatch(showLoader(false));
};

export const createDeal = (newDeals) => async (dispatch) => {
  await dispatch(showLoader(true));
  const response = await Api({
    method: "post",
    url: `/crm_new/create_deals`,
    data: {
      deals: JSON.stringify(newDeals),
    },
  });
  if (response.success) {
    await dispatch({
      type: UPDATE_BDE_TOOLS_DEALS_AND_SUBSCRIPTIONS,
      payload: {
        active_deals: response.active_deals,
        other_deals: response.other_deals,
        subscriptions: response.subscriptions,
      },
    });
    dispatch(showToast(response.message));
  } else {
    dispatch(showToast(response.message));
  }
  await dispatch(showLoader(false));
};
