import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import chevronLeft from '../../static/images/Chevron-Left.svg';
import chevronRight from '../../static/images/Chevron-Right.svg';
import { MAX_DAYS_GOING_BACK, MAX_DAYS_GOING_FRONT } from '../../constants';

class Navigator extends PureComponent {
  constructor(props) {
    super(props);
    this.goToPreviousDay = this.goToPreviousDay.bind(this);
    this.goToNextDay = this.goToNextDay.bind(this);
  }

  goToPreviousDay() {
    let { daysAgo } = this.props;
    if (daysAgo !== MAX_DAYS_GOING_BACK) {
      daysAgo += 1;
      this.props.reloadPage(daysAgo);
    }
  }

  goToNextDay() {
    let { daysAgo } = this.props;
    if (daysAgo !== MAX_DAYS_GOING_FRONT) {
      daysAgo -= 1;
      this.props.reloadPage(daysAgo);
    }
  }

  render() {
    const { daysAgo, text } = this.props;
    return (
      <div style={{
        display: 'flex', justifyContent: 'center', alignItems: 'center', height: '50%', width: 125,
      }}
      >
        <div style={{ justifyContent: 'flex-start', width: '25%' }}>
          <img
            role="presentation"
            src={chevronLeft}
            alt="Black Left Arrow"
            style={{ cursor: 'pointer', display: 'flex', justifyContent: 'center' }}
            onClick={() => this.goToPreviousDay()}
          />
        </div>
        <div
          role="presentation"
          style={{
            display: 'flex', justifyContent: 'center', alignItems: 'center', width: '50%', fontFamily: 'Roboto', fontSize: 14, cursor: 'pointer', padding: '0px 5px', float: 'left',
          }}
          onClick={() => daysAgo && this.props.reloadPage(0)}
        >
          <div
            role="presentation"
            style={{
              display: 'flex', justifyContent: 'center', alignItems: 'center', width: '50%', fontFamily: 'Roboto', fontSize: 14, padding: '0px 5px', float: 'left', whiteSpace: 'nowrap', opacity: daysAgo ? 1 : 0.5, userSelect: !daysAgo && 'none', cursor: daysAgo ? 'pointer' : 'not-allowed',
            }}
          >
            {text}
          </div>
        </div>
        <div style={{ display: 'flex', justifyContent: 'flex-end', width: '25%' }}>
          <img
            role="presentation"
            src={chevronRight}
            alt="Black Right Arrow"
            style={{ cursor: daysAgo !== MAX_DAYS_GOING_FRONT ? 'pointer' : 'not-allowed', display: 'flex', justifyContent: 'center' }}
            onClick={() => this.goToNextDay()}
          />
        </div>
      </div>
    );
  }
}

Navigator.propTypes = {
  daysAgo: PropTypes.number,
  text: PropTypes.string,
};

Navigator.defaultProps = {
  daysAgo: 0,
  text: 'Today',
};

export default connect(null, {})(Navigator);
