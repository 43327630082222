import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import ReactTooltip from 'react-tooltip';
import ReactPlayer from 'react-player';
import Cookies from 'universal-cookie';
import Pagination from 'react-js-pagination';
import Loading from '../loading';
import MobileHeader from '../mobileHeader';
import ProgressBar from '../progressBar';
import TestAssignmentModal from '../modal/testAssignmentModal';
import UploadResultProgress from '../uploadResultProgress';
import UploadResultError from '../uploadResultError';
import TestAssignmentEditView from '../mobileAssignmentTestCreationView';
import { PAGETYPE, UpdateStatus } from '../../constants';
import {
  getAssignmentList, updateTestAttribute, generateOtp, updateTestPublishState, archiveTest,
  uploadStudentResults, updateAssignmentModal,
} from '../../actions';
import MenuBar from '../menubar';
import { sendEvent } from '../../helpers/Analytics';
import Modal from '../modal/baseModal';
import Constants from '../../shared/constants';
import { UUID, getInsightsDomain, isMobileDevice } from '../../helpers/util';
import TestListFilterModal from '../modal/testListFilterModal';
import search from '../../static/images/search.svg';
import closeMark from '../../static/images/close-mark.svg';
import '../home/home.css';
import Layout from '../layout';
import upload from '../../static/images/upload.svg';
import download from '../../static/images/download.svg';
import grid from '../../static/images/grid.svg';
import gridSelected from '../../static/images/grid-selected.svg';
import lists from '../../static/images/list.svg';
import listSelected from '../../static/images/list-selected.svg';
import moreVertical from '../../static/images/more-vertical.svg';
import moreVerticalLighter from '../../static/images/more-vertical-lighter.svg';

const MENU_DATA = {
  ASSIGNMENT: { label: 'Assignments', route: '/list/assignment' },
  TEST: { label: 'Tests', route: '/list/test' },
};

const cookies = new Cookies();
const RESULT_UPDATE_STATUS_CHANNEL = 'ResultUpdateStatusChannel';
const IS_MOBILE = isMobileDevice();
const PAGE_COUNT = 8;

class AllAssignments extends React.Component {
  constructor(props) {
    super(props);
    this.scrollRef = React.createRef();
    this.state = {
      showCreateView: false,
      showPageLoading: true,
      showFilter: false,
      selectedTestId: '',
      showPublishTestModal: false,
      searchAssignmentString: '',
      status: -1,
      isTestPage: props.isTestPage,
      filterCount: 0,
      cable: Object.create(null),
      subscription: Object.create(null),
      testID: 0,
      showVideoModal: false,
      showCompleted: false,
      page: 1,
      filterApplied: false,
      availableTestGroups: [],
      statuses: { Completed: {}, 'In Progress': {} },
      appliedStatus: 'In Progress',
      appliedTestType: '',
      appliedTestGroups: [],
      listView: true,
      showMoreOptionsId: null,
      showArchived: false,
      showArchiveModal: null,
      subscriptionStatus: true, 

    };
    this.closeFilter = this.closeFilter.bind(this);
    this.togglePage = this.togglePage.bind(this);
    this.renderClickableListView = this.renderClickableListView.bind(this);
    this.searchStringChange = this.searchStringChange.bind(this);
    this.handleCloseModal = this.handleCloseModal.bind(this);
    this.uploadStudentResult = this.uploadStudentResult.bind(this);
    this.reOrderList = this.reOrderList.bind(this);
    this.getSearchResults = this.getSearchResults.bind(this);
    this.renderListGroup = this.renderListGroup.bind(this);
    this.renderVideoModal = this.renderVideoModal.bind(this);
    this.applyTestFilters = this.applyTestFilters.bind(this);
    this.renderTestCard = this.renderTestCard.bind(this);
    this.groupDataForTestListFilter = this.groupDataForTestListFilter.bind(this);
    this.renderTableView = this.renderTableView.bind(this);
    this.closeArchiveModal = this.closeArchiveModal.bind(this);
    this.closeComponents = this.closeComponents.bind(this);
    this.renderPagination = this.renderPagination.bind(this);
  }

  componentDidMount() {
    const { instituteName, type } = this.props;
    const { isTestPage } = this.state;
    this.props.getAssignmentList(isTestPage).then((response) => {
      this.setState(() => ({ showPageLoading: false, subscriptionStatus: response.data.success }));
      sendEvent('Assignment', 'List', 'Opened', { instituteName });
      let showVideoModal = false;
      const { isSignUpRedirect } = this.props;
      if (isSignUpRedirect === 'true') {
        showVideoModal = cookies.get('videoDisplayed') === undefined;
      }
      if (typeof window !== 'undefined') {
        const actionCable = require('actioncable');
        const cable = actionCable.createConsumer(Constants().api.ws_url);
        this.setState({ cable });
        const subscription = cable.subscriptions.create({ channel: RESULT_UPDATE_STATUS_CHANNEL }, {
          received: (data) => {
            this.props.updateTestAttribute(data.test_id, data.status).then(() => this.forceUpdate());
          },
        });
        this.groupDataForTestListFilter(isTestPage);
        this.setState({ subscription, showVideoModal });
      }
    });
  }

  componentWillUnmount() {
    if (typeof window !== 'undefined') {
      const { cable, subscription } = this.state;
      if (cable && cable.subscriptions) {
        cable.subscriptions.remove(subscription);
      }
    }
  }

  getStudentResultFile() {
    document.getElementById('uploadStudentResultFile').click();
  }

  getSearchResults(list) {
    const searchResultList = [];
    const { searchAssignmentString } = this.state;
    list.forEach((assignment) => {
      if (assignment.name.toUpperCase().indexOf(searchAssignmentString.toUpperCase()) >= 0) {
        searchResultList.push(assignment);
      }
    });
    return searchResultList;
  }

  togglePage(isTestPage) {
    this.props.history.push(isTestPage ? '/list/test' : '/list/assignment');
    this.setState({ showPageLoading: true, isTestPage });
    this.props.getAssignmentList(isTestPage).then(() => {
      this.setState(() => ({ showPageLoading: false }));
    });
  }

  reOrderList(list) {
    const { showArchived } = this.state;
    const orderedList = {
      reviewed: [],
      unReviewed: [],
    };
    list.forEach((assignment) => {
      if (assignment.archived === showArchived) {
        if (assignment.review) {
          orderedList.reviewed.push(assignment);
        } else {
          orderedList.unReviewed.push(assignment);
        }
      }
    });
    return orderedList;
  }

  closeArchiveModal(assignmentId, isTestPage) {
    this.setState({ showArchiveModal: null }, () => this.props.archiveTest(assignmentId,
      isTestPage));
  }

  closeComponents(event) {
    if (event && (event.target.className !== 'more-option')) {
      this.setState({ showOptionsId: null }, () => document.removeEventListener('click', this.closeComponents));
    }
  }

  showComponents(event, testId) {
    event.preventDefault();
    this.setState({ showOptionsId: testId }, () => {
      document.addEventListener('click', this.closeComponents);
    });
  }

  handleCloseModal() {
    this.props.updateAssignmentModal(false);
  }

  uploadResult(event, testID, status) {
    this.props.updateAssignmentModal(true);
    this.setState({ testID, status });
    event.stopPropagation();
  }

  uploadStudentResult(event) {
    const { testID } = this.state;
    const fileList = event.target.files;
    const files = Object.values(fileList);
    const sendFiles = files.map((file) => {
      return new File([file], `${UUID()}.xlsx`, { type: file.type });
    });
    this.props.uploadStudentResults(sendFiles, testID);
  }

  goToDashboard(event, id) {
    this.props.history.push(`/test_overview/${id}`);
    event.stopPropagation();
  }

  searchStringChange(e) {
    sendEvent('Search', 'AssignmentSearchString', 'Update', { name: e.target.value });
    this.setState({ searchAssignmentString: e.target.value });
  }

  editAssignment(assignment, isTestPage) {
    const { id } = assignment;
    const { isTrial, history } = this.props;
    sendEvent('Assignment', 'EditAssignment', 'Click', { id });
    if (isTestPage) {
      if (!assignment.summary.question_count) {
        history.push(`/test_config/${id}`);
      } else {
        history.push(`/test_creation_overview/${id}`);
      }
    } else if (IS_MOBILE || Object.keys(assignment.summary.track_question_count).length > 1) {
      history.push(`/test_creation_overview/${id}`);
    } else if (!assignment.summary.chapters
      || Object.keys(assignment.summary.chapters).length === 0) {
      history.push(`/test_chapter_selection/${id}?track=1`);
    } else {
      const pageType = isTrial ? PAGETYPE.REVIEW : PAGETYPE.PICKQUESTION;
      history.push(`/new_assignment/${id}?page_type=${pageType}&track=1`);
    }
    this.setState({ showPageLoading: true });
  }

  goToUploadResult(id) {
    const { isTrial, history } = this.props;
    history.push(`/upload_student_result/${id}`);
  }

  printAssignment(id) {
    sendEvent('Assignment', 'PrintAssignment', 'Click', { id });
    window.open(`/print_assignment/${id}`);
  }

  applyTestFilters(course, testGroups, showArchived) {
    const { filterCount } = this.state;
    this.setState({
      filterApplied: true,
      appliedTestType: course,
      appliedTestGroups: testGroups,
      showArchived,
      filterCount: filterCount + 1,
    });
  }

  groupDataForTestListFilter(isTestPage) {
    const { assignmentsList, testsList } = this.props;
    const list = isTestPage ? testsList : assignmentsList;

    let availableTestGroups = [];
    const statuses = { Completed: {}, 'In Progress': {} };

    list.forEach((listItem) => {
      const currentStatus = listItem.review ? 'Completed' : 'In Progress';
      const testGroupsData = statuses[currentStatus][listItem.test_type_id];

      let currentTestGroupIds = [listItem.test_group_id];

      if (testGroupsData) {
        if (testGroupsData.testGroupIds.includes(listItem.test_group_id)) {
          currentTestGroupIds = testGroupsData.testGroupIds;
        } else {
          currentTestGroupIds = [...testGroupsData.testGroupIds, listItem.test_group_id];
        }
      }
      availableTestGroups[listItem.test_group_id] = {
        testGroupName: listItem.test_group_name,
        testTypeId: listItem.test_type_id,
        status: (listItem.review) ? 'Completed' : 'In Progress',
      };
      statuses[listItem.review ? 'Completed' : 'In Progress'][listItem.test_type_id] = {
        testTypeName: listItem.test_type_name,
        testGroupIds: currentTestGroupIds,
      };
    });
    availableTestGroups = availableTestGroups.filter((n) => n);
    this.setState({ availableTestGroups, statuses });
  }

  closeFilter(resetFilters) {
    if (resetFilters) {
      this.setState({
        showFilter: false, appliedStatus: 'In Progress', appliedTestType: '', appliedTestGroups: [], filterApplied: false,
      });
      return;
    }
    this.setState({ showFilter: false });
  }

  renderFilterTags() {
    const {
      appliedStatus, appliedTestType, appliedTestGroups, statuses,
      availableTestGroups, filterApplied,
    } = this.state;
    return (
      <div style={{ display: 'flex', flexFlow: 'wrap' }}>
        {(filterApplied && appliedTestType) ? this.renderAppliedTestFilters(statuses[appliedStatus][appliedTestType].testTypeName) : '' }
        {(filterApplied && appliedTestGroups.length > 0)
        && appliedTestGroups.map((group) => this.renderAppliedTestFilters(
          availableTestGroups[group].testGroupName,
        ))}
      </div>
    );
  }

  renderAlert() {
    const { alertMessage, remainingQuestionCount } = this.props;
    return (
      <div style={{
        backgroundColor: alertMessage ? '#FF7657' : '#373b41', height: 50, display: 'flex', justifyContent: 'center', alignItems: 'center', color: alertMessage ? 'white' : 'gold', fontWeight: 'bold', letterSpacing: 1.5, fontSize: IS_MOBILE && 14,
      }}
      >
        {alertMessage || `Question Remaining: ${remainingQuestionCount.toLocaleString()}`}
      </div>
    );
  }

  renderHeader() {
    const { alertMessage, remainingQuestionCount, smsBalance } = this.props;
    const { isTestPage } = this.state;
    return (
      <div style={{
        marginLeft: '20%', width: '80%', position: 'fixed', display: 'flex', fontWeight: 600, zIndex: 1000, alignItems: 'center', boxShadow: '0px 3px 6px #343B4014', background: '#FFFFFF', color: Constants().color.primary, height: 70, fontSize: 22, justifyContent: 'center',
      }}
      >
        <div style={{
          display: 'flex', alignItems: 'center', width: '80%', justifyContent: 'space-between',
        }}
        >
          <div>{isTestPage ? 'Test List' : 'Assignment List'}</div>
          <div>
            <div style={{ fontSize: 16 }}>{alertMessage || `Question Remaining:  ${remainingQuestionCount.toLocaleString()}`}</div>
            <div style={{ fontSize: 16 }}>{alertMessage || `SMS Balance:  ${(smsBalance || 0).toLocaleString()}`}</div>
          </div>
        </div>
      </div>
    );
  }

  renderWeb(isTestPage) {
    const {
      classroomList, assignmentsList, testsList, testGroupList,
      previousYearPaperList, hasAccess,
      subjects, assignmentQuestionLimit, isTestLockedByOtp, testOtpType,
      isTrial, subjectMapping, showTestModal, history, type,
    } = this.props;
    const {
      status, showVideoModal, searchAssignmentString,
      showPublishTestModal, showCompleted, appliedStatus,
      appliedTestType, appliedTestGroups, statuses, availableTestGroups,
      listView, showArchived, filterCount, showPageLoading,
    } = this.state;

    const dataType = type && type.toUpperCase();
    const selected = {
      borderBottom: `3px solid ${Constants().color.primary}`, color: Constants().color.primary, fontSize: 20, opacity: 1, height: 60, paddingTop: 15, cursor: 'pointer',
    };
    const unselected = {
      fontSize: 20, color: '#c4c4c4', opacity: 1, height: 60, paddingTop: 15, cursor: 'pointer',
    };
    const list = isTestPage ? testsList : assignmentsList;
    const searchInProgress = (searchAssignmentString.length > 0);
    const types = Object.keys(MENU_DATA);
    const index = types.indexOf(dataType);
    let header = 'Upload Results';
    let availableScreenWidth = 0;
    let availableScreenHeight = 0;
    const searchResultList = this.getSearchResults(list);
    const orderedList = this.reOrderList(searchResultList);

    if (typeof window !== 'undefined') {
      availableScreenWidth = window.innerWidth;
      availableScreenHeight = (window.innerHeight - 120);
    }

    const widthOfBox = availableScreenWidth - ((availableScreenWidth * 20) / 100);
    const widthOfCard = 260;
    const excessScreenWidth = (widthOfBox % widthOfCard);
    const maxNumberofCards = parseInt((widthOfBox / widthOfCard), 10);
    const additionalWidthPerCard = (excessScreenWidth / maxNumberofCards);

    if (status === UpdateStatus.PROCESS) {
      header = 'Processing Result';
    } else if (status === UpdateStatus.ERROR) {
      header = 'Error';
    }
    if (showPageLoading) {
      return (
        <Layout hideHeader globalHistory={this.props.history}>
          <MenuBar
            history={history}
            isQuestionBoard
            menuList={Object.values(MENU_DATA)}
            selectedOption={index}
            redirectToRoot
          />
          {this.renderHeader()}
          <div style={{ marginLeft: '20%' }}>
            <Loading />
          </div>
        </Layout>
      );
    }
    return (
      <Layout hideHeader globalHistory={this.props.history}>
        <MenuBar
          history={history}
          isQuestionBoard
          menuList={Object.values(MENU_DATA)}
          selectedOption={index}
          redirectToRoot
        />

        {this.renderHeader(isTestPage)}
        <div style={{ marginLeft: '20%' }}>
          {showPublishTestModal && this.renderPublishTestModal()}
          <div
            style={{
              position: 'fixed', top: 70, left: '20%', zIndex: 2, backgroundColor: '#FFFFFF', display: 'flex', justifyContent: 'center', width: '80%',
            }}
          >
            <div style={{
              display: 'flex', width: '80%', fontWeight: 20,
            }}
            >
              <div
                role="presentation"
                style={showCompleted ? unselected : selected}
                onClick={() => {
                  this.setState({ showCompleted: false, appliedStatus: 'In Progress', page: 1 });
                  window.scrollTo(0, 0);
                }}
              >
                Progress
              </div>
              <div
                role="presentation"
                className="ml-5"
                style={showCompleted ? selected : unselected}
                onClick={() => {
                  this.setState({ showCompleted: true, appliedStatus: 'Completed', page: 1 });
                  window.scrollTo(0, 0);
                }}
              >
                Completed
              </div>
            </div>
          </div>
          <div className="container" style={{ paddingTop: 150 }}>

            <Modal showModal={showTestModal} handleCloseModal={this.handleCloseModal} header={header} top="25%">
              {this.renderTestModal()}
            </Modal>
            <Modal
              headerStyle={{ fontSize: 28, paddingLeft: 10, fontWeight: 800 }}
              showModal={showVideoModal}
              handleCloseModal={() => {
                cookies.set('videoDisplayed', true);
                this.setState({ showVideoModal: false });
              }}
              header="Learn how to create a test in 2 minutes."
              top="25%"
              overrideCloseIcon={(
                <div
                  role="presentation"
                  className="secondary_button"
                  style={{ width: 110, fontWeight: 600 }}
                  onClick={() => {
                    sendEvent('Assignment', 'WatchLater', 'Click');
                    cookies.set('videoDisplayed', true);
                    this.setState({ showVideoModal: false });
                  }}
                >
                  Watch Later
                </div>
            )}
            >
              {this.renderVideoModal()}
            </Modal>
            <div style={{
              display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: 30,
            }}
            >
              <div className="nunito-body" style={{ display: 'flex' }}>
                <div>
                  <TestAssignmentModal
                    globalHistory={history}
                    isTestPage={isTestPage}
                    isTestDataEditable
                    name=""
                    selectedClassroomList={[]}
                    selectedTestGroup=""
                    classroomList={classroomList}
                    testGroupList={testGroupList}
                    previousYearPaperList={previousYearPaperList}
                    subjectList={isTestPage ? false : subjects}
                    subjectMapping={subjectMapping}
                    homePage
                    isTrial={isTrial}
                    hasAccess={hasAccess || !isTestPage}
                    assignmentQuestionLimit={assignmentQuestionLimit}
                    inactive={isTestPage && testGroupList.length === 0}
                    isTestLockedByOtp={isTestLockedByOtp}
                    testOtpType={testOtpType}
                  />
                </div>
                <div style={{
                  display: 'flex', justifyContent: 'space-between', width: 480, border: '1px solid #9EA0A5', borderRadius: 4, height: 40, marginLeft: 15,
                }}
                >
                  <div style={{ paddingLeft: 15, display: 'flex', alignItems: 'center' }}>
                    <img alt="" src={search} width="16" height="16" />
                  </div>
                  <div style={{ width: 460, display: 'flex', justifyContent: 'space-between' }}>
                    <div style={{ width: '100%', display: 'flex', alignItems: 'center' }}>
                      <input
                        className="input-field"
                        id="Search"
                        onChange={this.searchStringChange}
                        type="text"
                        placeholder={(isTestPage) ? 'Search tests by name...' : 'Search assignments by name...'}
                        value={searchAssignmentString}
                        style={{
                          width: '100%', border: 'none', marginBottom: 0, height: 35,
                        }}
                        autoFocus
                      />
                    </div>
                    <div
                      role="presentation"
                      style={{
                        width: 25, display: (searchInProgress) ? 'flex' : 'none', alignItems: 'center', cursor: 'pointer',
                      }}
                      onClick={() => this.setState({ searchAssignmentString: '' })}
                    >
                      <img alt="" src={closeMark} width="16" height="16" />
                    </div>
                  </div>
                </div>
              </div>
              <div style={{ display: 'flex' }}>
                <div style={{ opacity: (list.length > 0) ? '1' : '.5', cursor: (list.length > 0) ? 'pointer' : 'not-allowed', marginRight: 10 }}>
                  <div style={{ pointerEvents: (list.length > 0) ? '' : 'none' }}>
                    <TestListFilterModal
                      key={filterCount}
                      testGroups={[...(availableTestGroups || [])]}
                      statuses={statuses}
                      applyTestFilters={this.applyTestFilters}
                      appliedStatus={appliedStatus}
                      appliedTestType={appliedTestType}
                      appliedTestGroups={appliedTestGroups}
                      showArchived={showArchived}
                      isTestPage={isTestPage}
                    />
                  </div>
                </div>
                <div style={{
                  display: 'flex', width: 100, justifyContent: 'space-between', marginRight: 10, border: '1px solid #9EA0A5', borderRadius: 4, height: 38,
                }}
                >
                  <div
                    role="presentation"
                    style={{
                      borderRight: '1px solid #9EA0A5', width: '50%', display: 'flex', justifyContent: 'center', alignItems: 'center', cursor: 'pointer',
                    }}
                    onClick={() => this.setState({ listView: false, page: 1 })}
                  >
                    <img alt="" src={listView ? grid : gridSelected} height="18" />
                  </div>
                  <div
                    role="presentation"
                    style={{
                      width: '50%', display: 'flex', justifyContent: 'center', alignItems: 'center', cursor: 'pointer',
                    }}
                    onClick={() => this.setState({ listView: true, page: 1 })}
                  >
                    <img alt="" src={listView ? listSelected : lists} height="18" />
                  </div>
                </div>
              </div>
            </div>
            <div>{this.renderFilterTags()}</div>
            {(appliedStatus === 'In Progress') && !listView && this.renderListGroup(orderedList.unReviewed, additionalWidthPerCard, false, isTestPage)}
            {((appliedStatus === 'Completed') || showCompleted || (searchInProgress && orderedList.reviewed.length > 0)) && !listView && this.renderListGroup(orderedList.reviewed, additionalWidthPerCard, true, isTestPage)}
            { (orderedList.unReviewed.length === 0 && orderedList.reviewed.length === 0
              && !listView) && this.renderSearchNotFound(isTestPage)}
            <div>
              {listView && this.renderTableView(orderedList.unReviewed,
                orderedList.reviewed, isTestPage)}
            </div>
          </div>
        </div>
      </Layout>
    );
  }

  renderArchiveModal(isTestPage) {
    const { showArchiveModal } = this.state;
    return (
      <Modal showModal={showArchiveModal} handleCloseModal={() => this.setState({ showArchiveModal: null })} width="460px" minHeight="60px" isMobile={IS_MOBILE}>
        <div style={{ padding: 15 }}>
          {this.renderArchiveCard(showArchiveModal, isTestPage)}
        </div>
      </Modal>
    );
  }

  renderMobile(isTestPage) {
    const {
      testsList, assignmentsList, testGroupList,
      previousYearPaperList, testTypeList, filteredClassroomList,
      classroomSearchString, assignmentQuestionLimit, hasAccess, subjects,
      isTrial, subjectMapping, history, match,
    } = this.props;
    const {
      showCreateView, filterApplied, appliedStatus,
    } = this.state;
    if (showCreateView) {
      return (
        <TestAssignmentEditView
          history={history}
          match={match}
          closeCreateView={() => this.setState({ showCreateView: false })}
          homePage
          isTestPage={isTestPage}
          isTrial={isTrial}
          isTestDataEditable
          name=""
          selectedClassroomList={[]}
          selectedTestGroup=""
          testTypeList={testTypeList}
          classroomList={filteredClassroomList}
          classroomSearchString={classroomSearchString}
          testGroupList={testGroupList}
          previousYearPaperList={previousYearPaperList}
          subjectList={isTestPage ? false : subjects}
          subjectMapping={subjectMapping}
          hasAccess={hasAccess || !isTestPage}
          assignmentQuestionLimit={assignmentQuestionLimit}
        />
      );
    }
    const selected = {
      borderBottom: '3px solid #FFFFFF', color: '#FFFFFF', fontSize: 20, opacity: 1, height: 60, paddingTop: 15, fontWeight: 'bold', width: '50%', display: 'flex', justifyContent: 'center', cursor: 'pointer',
    };
    const unselected = {
      fontSize: 20, color: '#c4c4c4', opacity: 1, height: 60, paddingTop: 15, width: '50%', display: 'flex', justifyContent: 'center', cursor: 'pointer',
    };
    let availableScreenWidth = 0;
    let availableScreenHeight = 0;
    if (typeof window !== 'undefined') {
      availableScreenWidth = window.innerWidth;
      availableScreenHeight = window.innerHeight;
    }
    const widthOfCard = 260;
    const additionalWidthPerCard = availableScreenWidth - widthOfCard - 20;
    const list = isTestPage ? testsList : assignmentsList;
    const orderedList = this.reOrderList(list);
    return (
      <div>
        <MobileHeader
          name="Question Board"
          color="white"
          backgroundColor={Constants().color.primary}
        >
          <img
            role="presentation"
            alt=""
            src={search}
            width="30px"
            height="30px"
            style={{ padding: 5 }}
            onClick={() => this.setState({ showFilter: true })}
          />
        </MobileHeader>
        <div style={{
          position: 'fixed', left: 0, right: 0, zIndex: 2, top: 60,
        }}
        >
          <div style={{
            backgroundColor: Constants().color.primary, height: 60, display: 'flex', paddingLeft: '10%', paddingRight: '10%',
          }}
          >
            <div
              role="presentation"
              style={isTestPage ? unselected : selected}
              onClick={() => this.togglePage(false)}
            >
              Assignments
            </div>
            <div
              role="presentation"
              style={isTestPage ? selected : unselected}
              onClick={() => this.togglePage(true)}
            >
              Tests
            </div>
          </div>
          {this.renderAlert()}
        </div>

        <div
          className="nunito-body"
          style={{
            backgroundColor: Constants().color.backgroundColor, marginTop: 160, padding: '0% 0%', overflowX: 'scroll',
          }}
        >
          <div style={{ margin: 20, paddingBottom: 40 }}>
            {this.renderFilterTags()}
            {(!filterApplied || appliedStatus === 'In Progress') && this.renderListGroup(orderedList.unReviewed, additionalWidthPerCard, true, isTestPage)}
            {(!filterApplied || appliedStatus === 'Completed') && this.renderListGroup(orderedList.reviewed, additionalWidthPerCard, true, isTestPage)}
          </div>
          <div
            role="presentation"
            onClick={() => {
              this.setState({ showCreateView: true });
            }}
            style={{
              display: 'flex', alignItems: 'center', justifyContent: 'center', color: 'white', backgroundColor: Constants().color.primary, position: 'fixed', bottom: 0, height: 50, width: '100%', fontSize: 16, fontWeight: 'bold',
            }}
          >
            New
            {isTestPage ? 'Test' : 'Assignment'}
          </div>
        </div>
        {this.renderArchiveModal(isTestPage)}
      </div>
    );
  }

  renderTestButton(test) {
    const { testOtp, hasAccess } = this.props;
    const showOTP = hasAccess && test.has_test_otp && test.is_online
    && test.show_test && !test.is_published;
    const buttonWidth = showOTP ? 130 : 220;
    return (
      <div style={{ display: 'flex', justifyContent: 'center', fontSize: 16 }}>
        {showOTP
          && (
          <div
            role="presentation"
            className="tertiary_button mr-3"
            style={{
              width: 80, fontSize: 16, height: 35, alignSelf: 'center',
            }}
            onClick={(event) => {
              this.props.generateOtp(test.id);
              event.stopPropagation();
            }}
          >
            {testOtp[test.id] ? testOtp[test.id] : 'Get OTP'}
          </div>
          )}
        {test.summary.status !== UpdateStatus.PROCESS && this.renderTestResult(test, buttonWidth)}
      </div>
    );
  }

  renderTestCard(assignment, additionalWidthPerCard, clickableCard, isTestPage) {
    const {
      appliedTestGroups, appliedTestType, filterApplied,
    } = this.state;
    if (!filterApplied || appliedTestGroups.includes(assignment.test_group_id)
    || (!appliedTestType && appliedTestGroups.length === 0)
    || (!isTestPage && assignment.test_type_id === appliedTestType)
    || (isTestPage && assignment.test_type_id === appliedTestType
      && appliedTestGroups.length === 0)) {
      return (
        <div key={assignment.id} data-tip={assignment.name}>
          { (clickableCard) ? this.renderClickableListView(assignment,
            additionalWidthPerCard, isTestPage) : this.renderWebListView(assignment,
            additionalWidthPerCard, isTestPage) }
        </div>
      );
    }
    return <div />;
  }

  renderListGroup(list, additionalWidthPerCard, clickableCard, isTestPage) {
    const { page } = this.state;
    if (list.length === 0) {
      return this.renderSearchNotFound(isTestPage);
    }
    const minCount = page >= 2 ? (page - 1) * PAGE_COUNT : 0;
    const maxCount = (page) * PAGE_COUNT;
    return (
      <div className="nunito-body">
        <div style={{ display: 'flex', flexWrap: 'wrap', marginBottom: (!clickableCard) ? 15 : 0 }}>
          {!IS_MOBILE && <ReactTooltip effect="solid" />}
          {list.slice(minCount, maxCount).map((assignment) => this.renderTestCard(assignment,
            additionalWidthPerCard, clickableCard, isTestPage))}
        </div>
        {this.renderPagination(list)}
      </div>
    );
  }

  renderTestList(assignment, isTestPage) {
    const {
      showMoreOptionsId, showOptionsId, showCompleted,
    } = this.state;
    const isCurrentCard = showMoreOptionsId === assignment.id;
    const showOptions = showOptionsId === assignment.id;
    const { first_approval_present: firstApprovalTime } = assignment;
    const allowArchive = !firstApprovalTime && !assignment.review;
    return (
      <tr
        onMouseOver={() => this.setState({ showMoreOptionsId: assignment.id })}
        onFocus={() => this.setState({ showMoreOptionsId: assignment.id })}
        onBlur={() => this.setState({ showMoreOptionsId: assignment.id })}
        onMouseOut={() => this.setState({ showMoreOptionsId: null })}
        style={{ fontSize: '14px', height: 50 }}
      >
        <td
          role="presentation"
          style={{
            textAlign: 'left', paddingLeft: 20, verticalAlign: 'middle', color: assignment.review ? '#40A7FF' : '', cursor: assignment.review ? 'pointer' : 'default', overflowWrap: 'anywhere',
          }}
        >
          {assignment.review ? (
            <div
              role="presentation"
              onClick={() => this.editAssignment(assignment, isTestPage)}
            >
              {assignment.name}
            </div>
          )
            : (
              <div>
                {assignment.name}
              </div>
            )}
        </td>
        <td style={{ paddingLeft: 20, verticalAlign: 'middle' }}>{assignment.date}</td>
        <td style={{ paddingLeft: 20, verticalAlign: 'middle' }}>
          <div style={{ display: 'flex' }}>
            {assignment.question_count}
            {!assignment.review && assignment.total_question_count && (
            <div>
            &thinsp; /
              &nbsp;
              {assignment.total_question_count}
            </div>
            )}
                &thinsp; questions
          </div>
        </td>
        {!assignment.is_published ? (showCompleted && <td style={{ paddingRight: 10, verticalAlign: 'middle', textAlign: 'center' }}>{assignment.active_student_count}</td>):(showCompleted && <td style={{ paddingRight: 10, verticalAlign: 'middle', textAlign: 'center' }}>NA</td>)}
        <td style={{
          verticalAlign: 'middle', display: 'flex', alignItems: 'center', justifyContent: 'center', border: 'none',
        }}
        >
          {!assignment.review
              && (
              <div style={{
                display: 'flex', justifyContent: 'center', cursor: assignment.archived ? 'not-allowed' : 'pointer', opacity: assignment.archived ? 0.5 : 1,
              }}
              >
                <div
                  role="presentation"
                  className="secondary_button"
                  style={{
                    height: 35, width: 220, marginTop: 10, marginBottom: 10, marginRight: allowArchive ? 0 : 10, pointerEvents: assignment.archived ? 'none' : '',
                  }}
                  onClick={() => this.editAssignment(assignment, isTestPage)}
                >
                  Continue
                </div>
              </div>
              )}
          {isTestPage && assignment.review
              && (
              <div style={{ margin: '10px 0px', marginRight: allowArchive ? 0 : 10 }}>
                {this.renderTestButton(assignment)}
              </div>
              )}
          {!isTestPage && assignment.review
              && (
              <div
                role="presentation"
                className="secondary_button"
                style={{
                  height: 35, width: 220, marginTop: 10, marginBottom: 10, marginRight: allowArchive ? 0 : 10, pointerEvents: assignment.archived ? 'none' : '',
                }}
                onClick={() => this.editAssignment(assignment, isTestPage)}
              >
                View Test
              </div>
              )}
          {allowArchive && (
          <div
            role="presentation"
            style={{ cursor: 'pointer', marginLeft: 10 }}
            onClick={(event) => this.showComponents(event, assignment.id)}
          >
            <img alt="" src={isCurrentCard || showOptions ? moreVertical : moreVerticalLighter} width="5px" />
          </div>
          )}
          {!IS_MOBILE && this.renderArchiveCard(assignment.id, isTestPage)}
        </td>
      </tr>
    );
  }

  renderSearchNotFound(isTestPage) {
    return (
      <div style={{
        display: 'flex', justifyContent: 'center', alignItems: 'center', color: '#CDCFD6', height: 60, backgroundColor: '#FFFFFF', fontSize: 18, border: '1px solid #CDCFD6', borderRadius: 4,
      }}
      >
        No&nbsp;
        {isTestPage ? 'Tests' : 'Assignments' }
        &nbsp;found
      </div>
    );
  }

  renderTableView(inProgress, completed, isTestPage) {
    const {
      appliedTestGroups, appliedTestType, filterApplied,
      showCompleted, searchAssignmentString, page,
    } = this.state;
    const searchInProgress = (searchAssignmentString.length > 0);
    const dataList = showCompleted
      ? completed.filter((assignment) => {
        return (!filterApplied
        || appliedTestGroups.includes(assignment.test_group_id)
      || (!appliedTestType && appliedTestGroups.length === 0)
      || (!isTestPage && assignment.test_type_id === appliedTestType)
      || (isTestPage && assignment.test_type_id === appliedTestType
        && appliedTestGroups.length === 0))
        || (searchInProgress && completed.length > 0);
      })
      : inProgress.filter((assignment) => {
        return !filterApplied
        || appliedTestGroups.includes(assignment.test_group_id)
      || (!appliedTestType && appliedTestGroups.length === 0)
      || (!isTestPage && assignment.test_type_id === appliedTestType)
      || (isTestPage && assignment.test_type_id === appliedTestType
        && appliedTestGroups.length === 0);
      });

    if (!dataList || (dataList && dataList.length === 0)) {
      return this.renderSearchNotFound(isTestPage);
    }

    const minCount = page >= 2 ? (page - 1) * PAGE_COUNT : 0;
    const maxCount = (page) * PAGE_COUNT;

    return (
      <div>
        <table
          className="table table-hover"
          style={{
            boxShadow: '1px 2px 6px #8B9DAF33', borderRadius: 4, backgroundColor: '#FFFFFF', border: '1px solid #CDCFD6',
          }}
        >
          <thead>
            <tr
              className="table-header"
              style={{
                fontSize: '18px', fontWeight: '700', height: 60, color: '#11426C', paddingLeft: 20,
              }}
            >
              <th style={{
                width: '35%', borderRight: '1px solid #DFE3EA', paddingLeft: 20,
              }}
              >
                Name
              </th>
              <th style={{ width: '15%', borderRight: '1px solid #DFE3EA', paddingLeft: 20 }}>Date</th>
              <th style={{ width: '20%', borderRight: '1px solid #DFE3EA', paddingLeft: 20 }}>Questions</th>
              {showCompleted && (<th style={{ width: '20%', borderRight: '1px solid #DFE3EA', paddingLeft: 20}}>ActiveCount</th>)}
              <th style={{ width: '30%', borderRight: '1px solid #DFE3EA', textAlign: 'center' }}>Action</th>
            </tr>
          </thead>
          <tbody>
            {dataList.slice(minCount, maxCount).map((assignment) => this.renderTestList(assignment,
              isTestPage))}
          </tbody>
        </table>
        {this.renderPagination(dataList)}
      </div>
    );
  }

  renderPagination(dataList) {
    const { page } = this.state;
    if (dataList && dataList.length > 0 && dataList.length > PAGE_COUNT) {
      return (
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <style>
            {`
          .pagination>li.active>a{
            background-color: ${Constants().color.primary} !important;
            color: #FFFFFF !important;
          }
          .pagination > li > a, .pagination > li > span{
            color: ${Constants().color.primary} !important;
          }
          .pagination > li > a:hover{
            background-color: #3d73ad !important;
            color: #FFFFFF !important;
          }
          `}
          </style>
          <Pagination
            activePage={page}
            itemsCountPerPage={PAGE_COUNT}
            totalItemsCount={dataList.length}
            pageRangeDisplayed={5}
            onChange={(pageno) => this.setState({ page: pageno })}
          />
        </div>
      );
    }
    return <div />;
  }

  renderPublishTestModal() {
    const { selectedTestId, showPublishTestModal } = this.state;
    return (
      <Modal showModal={showPublishTestModal} handleCloseModal={() => this.setState({ showPublishTestModal: false })} header="Publish Test" top="25%">
        <div style={{ padding: 20 }}>
          <div> Once you publish the solutions to your students, you cannot unpublish. Are you sure you want to proceed? </div>
          <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: 20 }}>
            <div
              role="presentation"
              className="secondary_button"
              style={{ width: '49%' }}
              onClick={() => this.setState({ showPublishTestModal: false })}
            >
              Close
            </div>
            <div
              role="presentation"
              className="primary_button"
              style={{ width: '49%' }}
              onClick={() => {
                this.props.updateTestPublishState(selectedTestId);
                this.setState({ showPublishTestModal: false });
              }}
            >
              Publish
            </div>
          </div>
        </div>
      </Modal>
    );
  }

  renderAppliedTestFilters(filters) {
    return (
      <div style={{
        height: 30, backgroundColor: '#7E8398', color: '#FFFFFF', borderRadius: 4, width: 'fit-content', display: 'flex', justifyContent: 'center', alignItems: 'center', padding: 10, marginRight: 10, marginBottom: 10,
      }}
      >
        {filters}
      </div>
    );
  }

  renderVideoModal() {
    const { instituteName } = this.props;
    return (
      <ReactPlayer
        url={Constants().videoUrl}
        style={{ minWidth: 1000, minHeight: 500 }}
        controls="true"
        onStart={() => { sendEvent('DemoVideo', 'Started', 'log', { instituteName }); }}
        onEnded={() => { sendEvent('DemoVideo', 'WatchedFully', 'log'); }}
        onPause={(response) => { sendEvent('DemoVideo', 'VideoPaused', { seconds: Math.round(response.seconds) }); }}
      />
    );
  }

  renderTestModal() {
    const { status, testID } = this.state;
    if (status === UpdateStatus.PROCESS) {
      return (
        <UploadResultProgress id={testID} />
      );
    } if (status === UpdateStatus.ERROR) {
      return (
        <UploadResultError id={testID} />
      );
    }
    return (
      <div className="d-flex flex-column justify-content-center" style={{ marginRight: global.innerWidth * 0.1, marginLeft: global.innerWidth * 0.1 }}>
        <div
          role="presentation"
          style={{ textAlign: 'center' }}
          onClick={this.getStudentResultFile}
        >
          <img alt="" src={upload} width="50px" />
          <p> Supported .xlsx </p>
          <p style={{ fontSize: '12px', color: '#cdcdce' }}>Drag or browse the Excel file to upload.</p>
        </div>
        <div style={{ height: '0px', width: '0px', overflow: 'hidden' }}>
          <input id="uploadStudentResultFile" type="file" name="result" accept=".xlsx" multiple="true" onChange={this.uploadStudentResult} />
        </div>
        <div>
          <a download="student_result.xlsx" target="_blank" href="/static/images/student_result.xlsx" style={{ width: '100%' }}>
            <div className="btn btn-outline-primary btn-block" style={{ fontSize: '14px', padding: 10 }}>
              <img alt="" src={download} style={{ width: 20, marginRight: 20, marginTop: -3 }} />
              Download
            </div>
          </a>
        </div>
      </div>
    );
  }

  renderWebListView(assignment, additionalWidthPerCard, isTestPage) {
    return (
      <li
        key={assignment.id}
        onFocus={() => this.setState({ showMoreOptionsId: assignment.id })}
        onBlur={() => this.setState({ showMoreOptionsId: assignment.id })}
        onMouseOver={() => this.setState({ showMoreOptionsId: assignment.id })}
        onMouseOut={() => this.setState({ showMoreOptionsId: null })}
        style={{
          display: 'flex', justifyContent: 'center', alignItems: 'center', backgroundColor: Constants().primaryBackgroundColor, minHeight: 85, width: (245 + additionalWidthPerCard), marginRight: 15, marginBottom: 15, boxShadow: '0px 3px 6px #343B4014', borderRadius: 4, cursor: (assignment.review) ? 'pointer' : 'default',
        }}
      >
        <div style={{ display: 'flex', flexDirection: 'column', padding: '20px 10px' }}>
          <div>
            {this.renderAssignmentName(assignment)}
            <div className="d-flex flex-row" style={{ fontSize: 12, marginTop: 5, color: '#aaa' }}>
              {assignment.date}
              {assignment.question_count}
              {!assignment.review && assignment.total_question_count && (
              <div>
                /
                &nbsp;
                {assignment.total_question_count}
              </div>
              )}
                    &thinsp; questions
              {(assignment.summary.status === UpdateStatus.PROCESS) && (
              <div
                role="presentation"
                className="ml-5"
                onClick={(e) => this.uploadResult(e, assignment.id, UpdateStatus.PROCESS)}
              >
                <div style={{ color: Constants().color.success }}>Processing Result</div>
              </div>
              )}
              {(assignment.summary.status === UpdateStatus.ERROR) && (
              <div
                role="presentation"
                className="ml-5"
                onClick={(e) => this.uploadResult(e, assignment.id, UpdateStatus.ERROR)}
              >
                <div style={{ color: Constants().color.error }}>Errors</div>
              </div>
              )}
            </div>
            {!assignment.review
              && (
              <div style={{
                display: 'flex', justifyContent: 'center', cursor: assignment.archived ? 'not-allowed' : 'pointer', opacity: assignment.archived ? 0.5 : 1,
              }}
              >
                <div
                  role="presentation"
                  className="secondary_button"
                  style={{
                    height: 35, width: 220, marginTop: 10, marginBottom: 10, pointerEvents: assignment.archived ? 'none' : '',
                  }}
                  onClick={() => this.editAssignment(assignment, isTestPage)}
                >
                  Continue
                </div>
              </div>
              )}
          </div>
          {isTestPage && assignment.review
            && (
            <div style={{ margin: '10px 0px' }}>
              {this.renderTestButton(assignment)}
            </div>
            )}
        </div>
      </li>
    );
  }

  renderAssignmentName(assignment, isTestPage) {
    const { showMoreOptionsId, showOptionsId } = this.state;
    const isCurrentCard = showMoreOptionsId === assignment.id;
    const showOptions = showOptionsId === assignment.id;
    const { first_approval_present: firstApprovalTime } = assignment;
    const allowArchive = !firstApprovalTime && !assignment.review;
    return (
      <div style={{ marginTop: 5, display: 'flex', justifyContent: 'space-between' }}>
        <div style={{
          width: allowArchive ? 160 : 180, fontWeight: 'bold', color: Constants().primaryHeaderTextColor, fontSize: 14, overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap',
        }}
        >
          {assignment.name}
        </div>
        {allowArchive
          && (
          <>
            <div
              role="presentation"
              className="more-option"
              style={{ cursor: 'pointer' }}
              onClick={IS_MOBILE ? (event) => {
                event.stopPropagation();
                this.setState({ showArchiveModal: assignment.id });
              } : (event) => this.showComponents(event, assignment.id)}
            >
              <img alt="" src={isCurrentCard || showOptions ? moreVertical : moreVerticalLighter} width="5px" />
            </div>
            {!IS_MOBILE && this.renderArchiveCard(assignment.id, isTestPage)}
          </>
          )}
      </div>
    );
  }

  renderArchiveCard(assignmentId, isTestPage) {
    const {
      showArchived, showOptionsId, listView,
    } = this.state;
    const archiveCardText = showArchived ? 'Unarchive' : 'Archive';
    if (IS_MOBILE) {
      return (
        <div
          role="presentation"
          className="secondary_button"
          onClick={() => this.closeArchiveModal(assignmentId, isTestPage)}
        >
          {archiveCardText}
        </div>
      );
    }
    const showOptions = showOptionsId === assignmentId;
    const archiveStyle = {
      width: 150, height: 50, position: 'absolute', backgroundColor: '#FFFFFF', borderRadius: 4, boxShadow: '1px 4px 6px rgba(69, 69, 69, .3)', cursor: 'pointer', paddingLeft: 20, color: Constants().primaryHeaderTextColor, display: showOptions ? 'flex' : 'none', paddingTop: 12, margin: listView ? '0px 0px 0px 30px' : '30px 0px 0px 70px', zIndex: 2,
    };
    return (
      <div
        role="presentation"
        style={archiveStyle}
        onClick={() => this.closeArchiveModal(assignmentId, isTestPage)}
      >
        {archiveCardText}
      </div>
    );
  }

  renderClickableListView(assignment, additionalWidthPerCard, isTestPage) {
    return (
      <div
        role="presentation"
        onClick={() => this.editAssignment(assignment, isTestPage)}
      >
        {IS_MOBILE && this.renderMobileListView(assignment, additionalWidthPerCard, isTestPage)}
        {!IS_MOBILE && this.renderWebListView(assignment, additionalWidthPerCard, isTestPage)}
      </div>
    );
  }

  renderMobileListView(assignment, additionalWidthPerCard) {
    const cardWidth = 245 + additionalWidthPerCard;
    return (
      <li
        key={assignment.id}
        style={{
          display: 'flex', flexDirection: 'column', backgroundColor: Constants().primaryBackgroundColor, minHeight: 85, width: cardWidth, marginBottom: 15, boxShadow: '0px 3px 6px #343B4014', borderRadius: 4, padding: 20,
        }}
      >
        {this.renderAssignmentName(assignment)}
        <div style={{
          display: 'flex', justifyContent: 'space-between', fontSize: 12, marginTop: 5, marginBottom: 10, color: '#aaa',
        }}
        >
          <div>{assignment.date}</div>
          <div style={{ display: 'flex' }}>
            {assignment.question_count}
            {!assignment.review && assignment.total_question_count && (
            <div>
              /
              &nbsp;
              {assignment.total_question_count}
            </div>
            )}
            &thinsp; questions
          </div>
        </div>
        {!assignment.review && assignment.total_question_count
          && (
          <ProgressBar
            height={5}
            width={cardWidth - 40}
            fillPercentage={`${((assignment.question_count / assignment.total_question_count) * 100)}%`}
          />
          )}
      </li>
    );
  }

  renderShowViewResult(test, showResultButton, buttonWidth) {
    return (
      <div
        role="presentation"
        className="secondary_button"
        onClick={() => window.open(`${getInsightsDomain()}/highlights/${test.id}`)}
        style={{
          alignSelf: 'center', width: buttonWidth,
        }}
      >
        View Result
      </div>
    );
  }

  renderShowUploadResult(test, buttonWidth) {
    return (
      <div
        role="presentation"
        className="secondary_button"
        onClick={(event) => this.uploadResult(event, test.id, UpdateStatus.UNUPLOAD)}
        style={{ alignSelf: 'center', width: buttonWidth }}
      >
        Upload Result
      </div>
    );
  }

  renderTestResult(test, buttonWidth) {
    const { listView } = this.state;
    const { testTaken, uploadPermission } = this.props;
    const showResultButton = testTaken.filter((obj) => obj.id === test.id)[0];
    if (!test.is_published) {
      return (
        <div
          role="presentation"
          className="secondary_button"
          style={{ alignSelf: 'center', width: buttonWidth }}
          onClick={(event) => {
            this.setState({ selectedTestId: test.id, showPublishTestModal: true });
            event.stopPropagation();
          }}
        >
          Publish Test
        </div>
      );
    }

    if (test.is_online || showResultButton.test_taken) {
      return (
        <div>
          {this.renderShowViewResult(test, showResultButton, buttonWidth)}
          {/* {listView && <ReactTooltip />} */}
        </div>
      );
    }
    if (uploadPermission && !test.is_online) {
      return this.renderShowUploadResult(test, buttonWidth);
    }
    return <div />;
  }

  render() {
    const {
      showFilter, showPageLoading, availableTestGroups, filterCount,
      statuses, appliedStatus, appliedTestType, appliedTestGroups, showArchived, isTestPage, subscriptionStatus
    } = this.state;
    if (IS_MOBILE === '') {
      return <div />;
    }
    if (!subscriptionStatus) {
      return (
        <div
          style={{
            textAlign: "center",
            padding: "20% 0",
            fontSize: 20,
            fontWeight: "bold",
          }}
        >
          Your account has been paused <br />
          For Re-Activation kindly Contact us on +91-8431904427
        </div>
      );
    }
    if (IS_MOBILE) {
      if (showPageLoading) {
        return <Loading />;
      }
      if (showFilter) {
        return (
          <TestListFilterModal
            key={filterCount}
            isMobile
            statuses={statuses}
            isTestPage={isTestPage}
            testGroups={[...(availableTestGroups || [])]}
            appliedStatus={appliedStatus}
            showArchived={showArchived}
            appliedTestType={appliedTestType}
            appliedTestGroups={appliedTestGroups}
            closeFilter={this.closeFilter}
            applyTestFilters={this.applyTestFilters}
          />
        );
      }
      return this.renderMobile(isTestPage);
    }
    return this.renderWeb(isTestPage);
  }
}

AllAssignments.propTypes = {
  instituteName: PropTypes.string,
  isSignUpRedirect: PropTypes.bool,
  testOtp: PropTypes.object,
  hasAccess: PropTypes.bool,
  uploadPermission: PropTypes.bool,
  testTaken: PropTypes.array,
  testsList: PropTypes.array,
  assignmentsList: PropTypes.array,
  testGroupList: PropTypes.array,
  previousYearPaperList: PropTypes.array,
  testTypeList: PropTypes.array,
  filteredClassroomList: PropTypes.array,
  classroomSearchString: PropTypes.string,
  assignmentQuestionLimit: PropTypes.number,
  subjects: PropTypes.array,
  subjectMapping: PropTypes.array,
  isTrial: PropTypes.bool,
  isTestLockedByOtp: PropTypes.bool,
  testOtpType: PropTypes.string,
  classroomList: PropTypes.array,
  remainingQuestionCount: PropTypes.number,
  showTestModal: PropTypes.bool,
  isTestPage: PropTypes.bool,
  alertMessage: PropTypes.string,
  match: PropTypes.object,
  smsBalance: PropTypes.number,
};

AllAssignments.defaultProps = {
  instituteName: '',
  isSignUpRedirect: false,
  testOtp: {},
  hasAccess: false,
  uploadPermission: false,
  testTaken: [],
  testsList: [],
  assignmentsList: [],
  testGroupList: [],
  previousYearPaperList: [],
  testTypeList: [],
  filteredClassroomList: [],
  classroomSearchString: [],
  assignmentQuestionLimit: 0,
  subjects: [],
  subjectMapping: [],
  isTestPage: false,
  isTrial: true,
  isTestLockedByOtp: false,
  testOtpType: null,
  showTestModal: false,
  alertMessage: '',
  smsBalance: 0,
  remainingQuestionCount: 0,
  classroomList: [],
  match: { params: {} },
};

const mapStateToProps = ({ home, assignment, institute }) => ({
  instituteName: institute.instituteName,
  phone: institute.phone,
  testOtp: home.testOtp,
  assignmentsList: home.assignmentsList,
  testsList: home.testsList,
  testTaken: home.testTaken,
  testTypeList: home.testTypeList,
  classroomList: home.classroomList,
  filteredClassroomList: home.filteredClassroomList,
  classroomSearchString: home.classroomSearchString,
  testGroupList: home.testGroupList,
  previousYearPaperList: home.previousYearPaperList,
  created: home.created,
  message: home.message,
  hasAccess: home.hasAccess,
  subjects: home.subjects,
  subjectMapping: home.subjectMapping,
  showTestModal: assignment.showModal,
  smsBalance: home.smsBalance,
  assignmentQuestionLimit: home.assignmentQuestionLimit,
  uploadPermission: home.uploadPermission,
  isTrial: home.isTrial,
  remainingQuestionCount: home.remainingQuestionCount,
  alertMessage: home.alertMessage,
  isTestLockedByOtp: home.isTestLockedByOtp,
  testOtpType: home.testOtpType,
  studentLimit: assignment.studentLimit,
  status: home.status,
});

export default connect(mapStateToProps, {
  getAssignmentList,
  updateTestAttribute,
  generateOtp,
  updateTestPublishState,
  archiveTest,
  uploadStudentResults,
  updateAssignmentModal,
})(AllAssignments);
