import React, { PureComponent } from 'react';
import { createBrowserHistory } from 'history';
import { connect } from 'react-redux';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { updateHistory } from '../actions';
import Home from './home';
import AllAssignment from './allAssignments';
import imageResize from './imageResize';
import TestCreationOverview from './testCreationOverview';
import studentAttendance from './studentAttendance';
import attendanceSession from './attendanceSession';
import attendanceReport from './attendanceReport';
import smsSubscritions from './smsSubscriptions';
import quickpoints from './quickpoints';
import testChapterSelection from './testChapterSelection';
import tagComplexity from './tagComplexity';
import distributionSelection from './distributionSelection';
import instituteLandingPage from './instituteLandingPage';
import feedback from './feedback';
import allClass from './allClass';
import endMeeting from './endMeeting';
import retagQuestions from './retagQuestions';
import studentDashboard from './studentDashboard';
import NewAssignment from './newAssignment';
import notifyServerMaintenance from './notifyServerMaintenance';
import landingPage from './landingPage';
import accessDenied from './accessDenied';
import sortQuestion from './sortQuestion';
import getQuestions from './getQuestions';
import trialExpired from './trialExpired';
import testConfig from './testConfig';
import printAssignment from './printAssignment';
import classReport from './classReport';
import quiz from './quiz';
import campusAndClassrooms from './campusAndClassrooms';
import quizBoard from './quizBoard';
import classBoard from './classBoard';
import teacherDetails from './teacherDetails';
import quizQuestionData from './quizQuestionData';
import conductQuiz from './conductQuiz';
import instituteOnBoarding from './instituteOnBoarding';
import filesBoard from './filesBoard';
import subjectDetails from './subjectDetails';
import studentDetails from './studentDetails';
import testGroupCreation from './testGroupCreation';
import teamManagement from './teamManagement';
import classroomTeachers from './classroomTeachers';
import ChangePassword from './changePassword';
import questionSuggestionImport from './questionSuggestionImport';
import questionSuggestionsList from './questionSuggestionsList';
import testLockOtp from './testLockOtp';
import '../styles/base.css';
import BdeTools from "./bdeTools";
import InstitutesTags from './institutesTags';
import NOC from "./noc";
import AdmissionBoard from './admissionBoard'
import HrBoard from './hrBoard';

const history = createBrowserHistory({
  forceRefresh: true,
});
class RouteNavigation extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      screenHeight: '',
    };
  }

  componentDidMount() {
    this.props.updateHistory(history);
    this.setState({ screenHeight: global.innerHeight });
    window.addEventListener('resize', this.resize);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.resize);
  }

  render() {
    const { screenHeight } = this.state;
    return (
      <Router history={history}>
        <div className="App" style={{ minHeight: screenHeight, height: '100% !important' }}>
          <div className="App">
            <Switch>
              <Route exact path="/" component={Home} />
              <Route path="/login" component={instituteLandingPage} />
              <Route path="/list/:type" render={(props) => <AllAssignment {...props} />} />
              <Route path="/test_creation_overview/:id" component={TestCreationOverview} />
              <Route path="/test_chapter_selection/:id" component={testChapterSelection} />
              <Route path="/distribution_selection/:id" component={distributionSelection} />
              <Route path="/new_assignment/:id" render={(props) => <NewAssignment {...props} />} />
              <Route path="/test_config/:id" component={testConfig} />
              <Route path="/print_assignment/:id" component={printAssignment} />
              <Route path="/quiz/:type/:id" component={quiz} />
              <Route path="/quiz_question/:id" component={quizQuestionData} />
              <Route path="/quizboard" component={quizBoard} />
              <Route path="/conduct_quiz/:type/:id" component={conductQuiz} />
              <Route path="/classboard/:type" component={classBoard} />
              <Route path="/classReport" component={classReport} />
              <Route path="/files_board" component={filesBoard} />
              <Route path="/institute/settings" component={instituteOnBoarding} />
              <Route path="/sms_subscriptions" component={smsSubscritions} />
              <Route path="/institute/teachers" component={teacherDetails} />
              <Route path="/institute/classrooms" component={campusAndClassrooms} />
              <Route path="/institute/subjects" component={subjectDetails} />
              <Route path="/institute/test_group_creation" component={testGroupCreation} />
              <Route path="/institute/team_management" component={teamManagement} />
              <Route path="/institute/institutes_tags" component={InstitutesTags} />
              <Route path="/files_board" component={filesBoard} />
              <Route path="/institute/students" component={studentDetails} />
              <Route path="/question_suggestion_import" component={questionSuggestionImport} />
              <Route path="/question_suggestions_list" component={questionSuggestionsList} />
              <Route path="/test_otp" component={testLockOtp} />
              <Route path="/landing_page" component={landingPage} />
              <Route path="/trial_expired" component={trialExpired} />
              <Route path="/access_denied" component={accessDenied} />
              <Route path="/feedback" component={feedback} />
              <Route path="/notify_server_maintenance" component={notifyServerMaintenance} />
              <Route path="/get_questions_from_ids" component={getQuestions} />
              <Route path="/end_meeting" component={endMeeting} />
              <Route path="/sort_questions/:id" component={sortQuestion} />
              <Route path="/assign_teachers_to_classroom" component={classroomTeachers} />
              <Route path="/retag_questions/:id" component={retagQuestions} />
              <Route path="/student_dashboard" component={studentDashboard} />
              <Route path="/image_resize" component={imageResize} />
              <Route path="/attendance/get_all_classes" component={allClass} />
              <Route path="/quickpoints" component={quickpoints} />
              <Route path="/attendance/report" component={attendanceReport} />
              <Route path="/attendance_session" component={attendanceSession} />
              <Route path="/attendance/:id/get_student_list" component={studentAttendance} />
              <Route path="/tag_complexity/:id" component={tagComplexity} />
              <Route path="/change_password" component={ChangePassword} />
              <Route path="/admission_board/:type" component={AdmissionBoard}/>
              <Route path="/admission_board" component={AdmissionBoard}/>
              <Route path="/bde_tools/:type/:id" component={BdeTools} />
              <Route path="/bde_tools/:type" component={BdeTools} />
              <Route path="/bde_tools" component={BdeTools} />
              <Route path="/hr_board/:type/:id" component={HrBoard} />
              <Route path="/hr_board/:type" component={HrBoard} />
              <Route path="/hr_board" component={HrBoard} />
              <Route path="/" component={accessDenied} />
              {/* to prevent white screen on wrong urls */}
            </Switch>
          </div>
        </div>
      </Router>
    );
  }
}

RouteNavigation.propTypes = {
};

RouteNavigation.defaultProps = {
};

const mapStateToProps = ({ }) => ({

});

export default connect(mapStateToProps, { updateHistory })(RouteNavigation);
